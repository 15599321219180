import { css } from "@emotion/core";
import { csx } from "src/util/csx";

const avatarSizes = { sizeXL: 2.75, sizeL: 2.375, sizeM: 2, sizeS: 1.5, sizeXS: 1 };

export const Avatar = csx(
  [
    css`
      white-space: nowrap;
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: var(--border-radius-round);
      line-height: 1;
      font-weight: var(--font-weight-body-semiBold);
      overflow: hidden;

      color: var(--text-6);
      background-color: var(--lightGrey-3);

      /* default styles */
      width: ${avatarSizes.sizeS}rem;
      height: ${avatarSizes.sizeS}rem;
      font-size: var(--font-size-helper);
      transition: background-color ease 0.2s;
      & > * {
        flex: 0 0 auto;
      }
    `
  ],
  {
    shadow: css`
      box-shadow: var(--box-shadow-kanbanCard);
    `,
    sizeXL: css`
      width: ${avatarSizes.sizeXL}rem;
      height: ${avatarSizes.sizeXL}rem;
      font-size: var(--font-size-body);
      & > svg {
        /* Used for placeholder  */
        width: ${avatarSizes.sizeS}rem;
        height: ${avatarSizes.sizeS}rem;
      }
    `,
    sizeL: css`
      width: ${avatarSizes.sizeL}rem;
      height: ${avatarSizes.sizeL}rem;
      font-size: var(--font-size-body);
      & > svg {
        width: ${avatarSizes.sizeS}rem;
        height: ${avatarSizes.sizeS}rem;
      }
    `,
    sizeM: css`
      width: ${avatarSizes.sizeM}rem;
      height: ${avatarSizes.sizeM}rem;
      font-size: var(--font-size-helper);
      & > svg {
        width: ${avatarSizes.sizeXS}rem;
        height: ${avatarSizes.sizeXS}rem;
      }
    `,
    sizeS: css`
      /* default size */
      width: ${avatarSizes.sizeS}rem;
      height: ${avatarSizes.sizeS}rem;
      font-size: var(--font-size-helper);
      & > svg {
        width: ${avatarSizes.sizeXS}rem;
        height: ${avatarSizes.sizeXS}rem;
      }
    `,
    sizeXS: css`
      width: ${avatarSizes.sizeXS}rem;
      height: ${avatarSizes.sizeXS}rem;
      font-size: var(--font-size-label);
      & > svg {
        width: ${avatarSizes.sizeXS}rem;
        height: ${avatarSizes.sizeXS}rem;
      }
    `,
    lightBlue: css`
      color: var(--blue-5);
      background-color: var(--lightBlue-2);
    `,
    blue: css`
      color: white;
      background-color: var(--blue-2);
    `
  }
);
