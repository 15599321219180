import { css } from "@emotion/core";
import * as React from "react";
import { ChangeEvent, FC } from "react";
import { TextField } from "src/App/Forms/Components";
import { TeamWorkflowGet_teamWorkflow_triggers_publishedVariables } from "src/App/Settings/Workflows/typings/TeamWorkflowGet";
import { AbsoluteTooltip, UnderlineText } from "src/components";
import { OptionMap, PopOver } from "src/components/PopOver";
import { WorkflowPublishedVariableType } from "src/globalTypes";
import { Typo } from "src/styling/primitives/typography";
import { EditableMatcherValueContainer, PublishedVariable, SelectTrigger, StepActionIndex, useWorkflow } from "./";

export enum WorfklowAliasTypes {
  STRING = "string",
  STRINGVARIABLE = "stringVariable"
}

const pattern = /(\${.*?})/gm;
export const splitByVars = (text: string) => text.split(pattern).filter(item => item);

export const segmentsFromString = (text: string) => {
  return splitByVars(text).map(segment =>
    segment.startsWith("${")
      ? {
          content: segment.replace(/[${}]/g, ""),
          type: WorfklowAliasTypes.STRINGVARIABLE
        }
      : {
          content: segment,
          type: WorfklowAliasTypes.STRING
        }
  );
};

export const variableColors = [
  css`
    background-color: var(--lightBlue-1);
  `,
  css`
    background-color: var(--flesh-2);
  `,
  css`
    background-color: var(--pink-1);
  `,
  css`
    background-color: var(--green-1);
  `,
  css`
    background-color: var(--yellow-1);
  `,
  css`
    background-color: var(--red-1);
  `,
  css`
    background-color: var(--violet-1);
  `
];
export const variableFillColors = [
  css`
    fill: var(--lightBlue-1);
  `,
  css`
    fill: var(--flesh-2);
  `,
  css`
    fill: var(--pink-1);
  `,
  css`
    fill: var(--green-1);
  `,
  css`
    fill: var(--yellow-1);
  `,
  css`
    fill: var(--red-1);
  `,
  css`
    fill: var(--violet-1);
  `
];

export const ViewVariable: FC<{ value: string | null; emptyLabel?: string }> = ({ value, emptyLabel }) => {
  const isTruncated = value && value.length > 35;
  const truncatedValue = isTruncated ? value && value.substr(0, 35) : value;
  const segments = (text: string) => segmentsFromString(text);
  const { getColorCodeForVar } = useWorkflow();
  return (
    <>
      <UnderlineText
        css={[
          css`
            padding-bottom: 2px;
          `
        ]}
      >
        {truncatedValue ? (
          segments(truncatedValue).map(segment => {
            switch (segment.type) {
              case WorfklowAliasTypes.STRINGVARIABLE:
                return (
                  <span
                    css={[
                      css`
                        padding: 0px 4px;
                        background-color: var(--lightGrey-2);
                        border-radius: var(--border-radius-s);
                      `,
                      variableColors[getColorCodeForVar(segment.content)]
                    ]}
                  >
                    {segment.content}
                  </span>
                );
              case WorfklowAliasTypes.STRING:
              default:
                return <span>{segment.content}</span>;
            }
          })
        ) : (
          <span
            css={[
              css`
                padding: 0px 4px;
                background-color: var(--lightGrey-1);
                border-radius: var(--border-radius-s);
                color: var(--lightGrey-4);
              `
            ]}
          >
            {emptyLabel ? emptyLabel : "undefined"}
          </span>
        )}
        {isTruncated && "..."}
      </UnderlineText>
    </>
  );
};

const headlinesByVarType: Record<WorkflowPublishedVariableType, string> = {
  TYPE_BOOLEAN: "Select",
  TYPE_CONVERSATION_ID: "Select conversation",
  TYPE_DATE: "Select",
  TYPE_PROJECT_ID: "Select project",
  TYPE_REQUEST_ID: "Select request",
  TYPE_STRING: "Select",
  TYPE_TEAM_ID: "Select team",
  TYPE_UNSPECIFIED: "Select",
  TYPE_USER_ID: "Select user",
  TYPE_EMAIL: "Select user",
  TYPE_NUMBER: "Select"
};

export enum VariableSelectState {
  INPUT = "input",
  FORCEINPUT = "forceInput",
  FIXEDVALUE = "fixedValue",
  SINGLEOPTIONSELECT = "singleOptionSelect",
  REGULARSELECT = "regularSelect"
}

export const determineVariableSelectState = (
  value: string | null,
  variables: PublishedVariable[] | null
): VariableSelectState => {
  if (!variables || variables.length === 0) return VariableSelectState.INPUT;
  else if (!value && variables.length === 1) return VariableSelectState.FORCEINPUT;
  else if (value && variables.length === 1) {
    if (!!variables.find(variable => variable.templateString === value)) return VariableSelectState.FIXEDVALUE;
    else return VariableSelectState.SINGLEOPTIONSELECT;
  }
  // variables.length > 1
  else return VariableSelectState.REGULARSELECT;
};

export const SelectVariable: React.FC<{
  index: StepActionIndex;
  type: WorkflowPublishedVariableType;
  value: string | null;
  onChange(e: string): void;
  onBlur?(e: React.ChangeEvent<HTMLElement>): void;
}> = props => {
  const { getWorkflowVariables } = useWorkflow();
  const variables = getWorkflowVariables(props.index)?.filter(v => v.type === props.type) ?? [];
  const value = !!props.value?.length ? props.value : null;
  const options = variables.map(
    variable =>
      ({
        id: variable.templateString,
        name: variable.name,
        labelNode: <InlineVariable name={variable.name} />
      } as OptionMap)
  );

  const headline = headlinesByVarType[props.type];

  const variableSelectState = determineVariableSelectState(value, variables);

  if (variableSelectState === VariableSelectState.FORCEINPUT) {
    // the value is undefined and there is only 1 variable suggestion
    // Programmatically fire the onChange -> value is defined at next re-render
    props.onChange(variables.find(variable => variable.name === options[0].name)?.templateString ?? "");
  }

  switch (variableSelectState) {
    case VariableSelectState.INPUT:
      // Always return an input if there are no variables
      return (
        <EditableMatcherValueContainer
          css={css`
            width: 100%;
          `}
        >
          <TextField
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              props.onChange(e.target.value);
            }}
            value={value ?? ""}
            placeholder="Enter an id"
            css={css`
              & textarea,
              & textarea:hover {
                border-bottom: 0px solid !important;
                font-size: var(--font-size-body);
              }
            `}
          />
        </EditableMatcherValueContainer>
      );
    case VariableSelectState.FIXEDVALUE:
      // value matches single existing variable
      return (
        <PopOver.Menu
          trigger={
            <SelectTrigger>
              <InlineVariable name={(value ?? "").replace(/[${}]/g, "")} />
            </SelectTrigger>
          }
          onSelect={props.onChange}
          selected={value}
          options={[
            {
              headline,
              options
            }
          ]}
        />
      );
    case VariableSelectState.SINGLEOPTIONSELECT:
      // value does not match any variable (probably broken workflow or template)
      // Make user pick available option and overwrite "broken" value
      return (
        <PopOver.Menu
          trigger={
            <SelectTrigger>
              <InlineVariable name={(value ?? "").replace(/[${}]/g, "")} />
            </SelectTrigger>
          }
          onSelect={props.onChange}
          selected={value}
          options={[
            {
              headline,
              options
            }
          ]}
        />
      );
    case VariableSelectState.REGULARSELECT:
    default:
      // there are n available variables
      // it is irrelevant whether or not the value matches one
      const selectedVariable = variables.find(variable => variable.templateString === value);
      return (
        <PopOver.Menu
          trigger={
            <SelectTrigger>
              {value ? <InlineVariable name={selectedVariable?.name ?? value.replace(/[${}]/g, "")} /> : headline}
            </SelectTrigger>
          }
          onSelect={props.onChange}
          selected={selectedVariable?.templateString}
          options={[
            {
              headline,
              options
            }
          ]}
        />
      );
  }
};

/**
 *
 * @param text content to render
 * @returns a static box without different formik
 * @deprecated in the future use ViewVariable & EditVariable components
 */
export const VariablesAsComponents: FC<{ text: string | null }> = ({ text }) => {
  const { getColorCodeForVar } = useWorkflow();
  if (!text) {
    return (
      <span
        css={[
          css`
            padding: 0px 4px;
            background-color: var(--lightGrey-1);
            border-radius: var(--border-radius-s);
            color: var(--lightGrey-4);
          `
        ]}
      >
        undefined
      </span>
    );
  }
  const segments = segmentsFromString(text);
  return (
    <>
      {" "}
      <UnderlineText
        css={[
          css`
            padding-bottom: 2px;
          `
        ]}
      >
        {segments.map(segment => {
          switch (segment.type) {
            case WorfklowAliasTypes.STRINGVARIABLE:
              return (
                <span
                  css={[
                    css`
                      padding: 0px 4px;
                      background-color: var(--lightGrey-2);
                      border-radius: var(--border-radius-s);
                    `,

                    variableColors[getColorCodeForVar(segment.content)]
                  ]}
                >
                  {segment.content}
                </span>
              );
            case WorfklowAliasTypes.STRING:
            default:
              return <span>{segment.content}</span>;
          }
        })}
      </UnderlineText>{" "}
    </>
  );
};

const variableStyle = css`
  padding: 0 var(--space-1-rem);
  border: 1px solid var(--lightGrey-2);
  border-radius: var(--border-radius-s);
`;

const VariableDisplayLine: FC<{ variable: TeamWorkflowGet_teamWorkflow_triggers_publishedVariables }> = ({
  variable
}) => (
  <div
    css={css`
      display: flex;
      & p {
        line-height: 1.2;
      }
    `}
    key={variable.name}
  >
    <Typo.Body
      light
      sizeS
      css={[
        variableStyle,
        css`
          width: auto;
        `
      ]}
    >
      {variable.name}
    </Typo.Body>
    <Typo.Body
      light
      sizeS
      css={css`
        width: auto;
        margin-left: var(--space-1-rem);
      `}
    >
      - {variable.editable ? variable.value : variable.description}
    </Typo.Body>
  </div>
);

export const PublishedVariables: React.FC<{
  variables: TeamWorkflowGet_teamWorkflow_triggers_publishedVariables[];
  expanded: boolean;
}> = props => {
  if (props.variables.length === 0) return null;

  const collapsedVars = {
    firstThree: [...props.variables].splice(0, 3),
    more: [...props.variables].splice(3, props.variables.length - 3)
  };

  const sortedVarsEditable = {
    immutable: props.variables.filter(variable => !variable.editable),
    editable: props.variables.filter(variable => variable.editable)
  };

  return props.expanded ? (
    <div
      css={[
        css`
          padding: var(--space-6-rem) 0;
          & > * + * {
            margin-top: var(--space-4-rem);
          }
        `
      ]}
    >
      {!!sortedVarsEditable.immutable.length && (
        <div
          css={[
            css`
              & > * + * {
                margin-top: var(--space-1-rem);
              }
            `
          ]}
        >
          <Typo.Body light sizeS>
            Defines {sortedVarsEditable.immutable.length} fixed variable{sortedVarsEditable.immutable.length > 1 && "s"}
            :{" "}
          </Typo.Body>
          {sortedVarsEditable.immutable.map(variable => (
            <VariableDisplayLine variable={variable} />
          ))}
        </div>
      )}
      {!!sortedVarsEditable.editable.length && (
        <div
          css={[
            css`
              & > * + * {
                margin-top: var(--space-1-rem);
              }
            `
          ]}
        >
          <Typo.Body light sizeS>
            And {sortedVarsEditable.editable.length} editable variable{sortedVarsEditable.editable.length > 1 && "s"}:{" "}
          </Typo.Body>
          {sortedVarsEditable.editable.map(variable => (
            <VariableDisplayLine variable={variable} />
          ))}
        </div>
      )}
    </div>
  ) : (
    <Typo.Body light sizeS>
      <span>
        Defines {props.variables.length} variable{props.variables.length > 1 && "s"}:{" "}
      </span>
      <span
        css={css`
          & > * + * {
            margin-left: var(--space-1-rem);
          }
        `}
      >
        {collapsedVars.firstThree.map(publishedVar => (
          <span key={publishedVar.name} title={publishedVar.description} css={variableStyle}>
            {publishedVar.name}
          </span>
        ))}
        {collapsedVars.more.length === 1 && (
          <span title={collapsedVars.more[0].description} css={variableStyle}>
            {collapsedVars.more[0].name}
          </span>
        )}
      </span>
      {collapsedVars.more.length > 1 && (
        <>
          <span> and </span>
          <AbsoluteTooltip
            css={[
              css`
                width: auto;
                display: inline;
              `
            ]}
            // otherwise will cut off for e.g. "7 more" in trigger at top of page
            placement="bottom"
            content={
              <>
                {collapsedVars.more.map(publishedVar => (
                  <span key={publishedVar.name}>
                    {publishedVar.name}
                    <br />
                  </span>
                ))}
              </>
            }
            zIndex="high"
          >
            <UnderlineText>{collapsedVars.more.length} more</UnderlineText>
          </AbsoluteTooltip>
        </>
      )}
    </Typo.Body>
  );
};

/** display variable, with color, as inline span from variable _name_ */
export const InlineVariable: React.FC<{
  name: string;
}> = props => {
  const { getColorCodeForVar } = useWorkflow();
  return (
    <span
      css={[
        css`
          padding: 0 4px;
          background-color: var(--lightGrey-2);
          border-radius: var(--border-radius-s);
        `,
        variableColors[getColorCodeForVar(props.name)]
      ]}
    >
      {props.name}
    </span>
  );
};

export const SelectSubworkflow: React.FC<{
  index: StepActionIndex;
  value: string | null;
  onChange(subflowName: string): void;
}> = props => {
  const { getSubflowNames } = useWorkflow();
  const options =
    getSubflowNames(props.index)?.map(subflowName => ({
      id: subflowName,
      name: subflowName
    })) ?? [];
  return (
    <PopOver.Menu
      trigger={<SelectTrigger>{props.value ?? "Select subworkflow"}</SelectTrigger>}
      onSelect={props.onChange}
      selected={props.value}
      options={options}
    />
  );
};
