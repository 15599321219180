import * as React from "react";
import { useEffect } from "react";

export function DidMount(props: { callback(): void }) {
  useEffect(() => {
    props.callback();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
  return <></>;
}
