import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { PureQueryOptions } from "apollo-client";
import { isEqual, throttle } from "lodash";
import * as React from "react";
import { useMemo } from "react";
import { ListOnScrollProps, VariableSizeList } from "react-window";
import { CondensedRequestListItem } from "src/App/Requests/ListView/CondensedRequestListItem";
import { setRequestListURLParam } from "src/App/Requests/ListView/Filters/urlParamHelpers";
import { KanbanBoard } from "src/App/Requests/ListView/KanbanBoard";
import { RequestListState, useRequestList } from "src/App/Requests/ListView/Provider";
import { RequestCard } from "src/App/Requests/ListView/RequestCard";
import { ListComponentProps } from "src/App/Requests/ListView/REQUEST_LIST_GET";
import { RequestListFragment } from "src/App/Requests/ListView/typings/RequestListFragment";
import { useSearchDialog } from "src/App/Requests/Search/Dialog";
import { LoadingBar } from "src/components";
import { EmptyState } from "src/components/EmptyState";
import { ViewType } from "src/globalTypes";
import { Toast } from "src/portals/Toast";
import { TextLink } from "src/styling/primitives";
import { csx } from "src/util/csx";

/**
 * Default dimensions for react-window positioning
 */
const REQ_LIST_MIN_WIDTH = 724;
const REQ_LIST_MIN_HEIGHT = 0;
const REQ_CARD_HEIGHT = 142; // height + 1rem margin bottom
const REQ_CONDENSED_HEIGHT = 57;
const REQ_CONDENSED_EXPANDED_HEIGHT = 96;

export const ListViewWrapper = csx(
  [
    css`
      position: relative;
      width: 100%;
      overflow: hidden;
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
    `
  ],
  {}
);

const ListOverlay = styled.div`
  position: fixed;
  background: var(--white);
  width: calc(100vw - 20rem);
  opacity: 0;
  top: 0;
  bottom: 0;
  animation: fadeOut 600ms ease-out;
  pointer-events: none;
`;

/**
 * Use dynamic width / height dimensions of an element by id
 * Accepts min width / height values
 */
function useDynamicDimensions(
  ref: React.MutableRefObject<HTMLElement | null>,
  minWidth: number,
  minHeight: number
): { width: number; height: number } {
  const [dimensions, setDimensions] = React.useState({ width: minWidth, height: minHeight });

  React.useEffect(() => {
    const handleResize = throttle(() => {
      if (ref.current) {
        setDimensions({
          height: Math.max(minHeight, ref.current.offsetHeight),
          width: Math.max(minWidth, ref.current.offsetWidth)
        });
      }
    }, 50);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [minWidth, minHeight, ref]);
  return dimensions;
}

/**
 * This hook tells us when the requests being displayed have diverged in layout.
 * This could happen due to request order change caused by some mutation
 * or if some condensed list item has been expanded, etc.
 * This hook returns the index of the request where the change originated and
 * a ref that can be used with something like VariableSizeList to programatically
 * call some redraw fn.
 * It is authored very procedurally to keep calculation times and thus, rerenders down.
 * This is also why it checks the expansion condition first and returns early
 * as there can be potentially thousands of requests and checking for divergence there
 * might get very expensive.
 */
function useShouldRerenderVariableSizeList(
  expandedRequests: RequestListState["expandedRequests"],
  requests: RequestListFragment[]
): [number, React.RefObject<VariableSizeList>] {
  const condensedItemsListRef = React.useRef<VariableSizeList>(null);
  const previouslyExpandedRequests = React.useRef<typeof expandedRequests>(expandedRequests);
  const previouslyRenderedRequests = React.useRef<typeof requests>(requests);

  // type [requestId: string, isExpanded: boolean][]
  const prev = Object.entries(previouslyExpandedRequests.current);
  // type [requestId: string, isExpanded: boolean][]
  const curr = Object.entries(expandedRequests);

  // we use prev.find with isEqual as expandedRequests has the shape {requestId: boolean}
  // which means when a request is collapsed, it is still in expandedRequests
  // but with it's state set to false instead of being removed from expandedRequests
  const diff = curr.filter(c => !prev.find(p => isEqual(p, c))).map(([id]) => id);

  // get the index of the first request that is in the diff
  // we only need the first as we re-render everthing starting from this
  const firstReqWithExpansionStateChange = requests.findIndex(request => diff.includes(request.id));

  // make current expandedRequests the previous for next run of this hook
  previouslyExpandedRequests.current = expandedRequests;

  // return only if a changed request was found
  // otherwise try the next strategy to figure out if we need to re-render
  if (firstReqWithExpansionStateChange > -1) {
    return [firstReqWithExpansionStateChange, condensedItemsListRef];
  }

  // check when the previous requests and current requests diverge and report that index
  // check them 1:1 i.e., index 0 in prev with index 0 in currently shown requests
  // this needs to be done as request ordering changes need to cause a VariableSizeList repaint
  // interacting with request actions ("pills") can cause request ordering and/or state changes
  for (let i = 0; i < Math.min(previouslyRenderedRequests.current.length, requests.length); i++) {
    // we use isEqual and not just compare on request.id since request ids might be same
    // but say the request category might have changed thus growing that card and hence requiring
    // a repaint of the VariableSizeList
    if (!isEqual(requests[i], previouslyRenderedRequests.current[i])) {
      // explanation for this is below the return statement
      const expandedRequestIds = Object.entries(expandedRequests)
        .filter(([_, isExpanded]) => isExpanded)
        .map(([id]) => id);
      const indexOfFirstExpandedRequest = requests.findIndex(request => expandedRequestIds.includes(request.id));
      // make current requests the previous for next run of this hook
      previouslyRenderedRequests.current = requests;

      // we do a Math.min so that we always start rendering from the earlier expanded request
      // since their expanded height needs to be maintained correctly when a request moves from above an expanded
      // one to below it
      // for e.g., when we take an action on an unread request and it gets marked as read and moves below all unread requests
      return [Math.min(i, indexOfFirstExpandedRequest <= 0 ? 0 : indexOfFirstExpandedRequest), condensedItemsListRef];
    }
  }

  return [-1, condensedItemsListRef];
}

const CardList: React.ComponentType<ListComponentProps> = props => {
  const [requestList, requestListDispatch] = useRequestList();
  const requests = requestList.response.data?.requestListPage.requests;

  const updateVisiblePills = (requestId: string, pillId: string): void => {
    requestListDispatch({
      type: "TOGGLE_VISIBLE_PILLS",
      requestId,
      pillId
    });
  };

  const itemData: IRequestCardListItemProps["data"] = {
    requestUpdateMap: props.requestUpdateMap,
    queriesToRefetch: props.queriesToRefetch,
    expandedRequests: requestList.state.expandedRequests,
    visiblePills: requestList.state.visiblePills,
    updateVisiblePills
  };

  // scroll restoration data for the FixedSizeList and VariableSizeList
  const locationKey = props.location?.key ?? "";
  // update scroll offset on location key & virtual list key change
  const initialScrollOffset = useMemo(() => Number(sessionStorage.getItem(locationKey) || 0), [locationKey]);
  const storeScrollOffset = (scroll: ListOnScrollProps) => {
    if (props.location?.key) {
      sessionStorage.setItem(props.location.key, String(scroll.scrollOffset));
    }
  };

  /** key to track request list changes */
  const requestListKey = useMemo(() => {
    return (requests || []).reduce((a, c) => a + c.id, "");
  }, [requests]);

  // dynamically calc list wrapper dimension for absolutely positioning windowed list
  const listDimensions = useDynamicDimensions(props.listDimensionsRef, REQ_LIST_MIN_WIDTH, REQ_LIST_MIN_HEIGHT);
  const [rerenderFromIndex, condensedItemsListRef] = useShouldRerenderVariableSizeList(
    requestList.state.expandedRequests,
    requests ?? []
  );

  // this should be run on every render hence the lack of a dependency array
  React.useLayoutEffect(() => {
    if (requestList.state.viewType === ViewType.CONDENSED && rerenderFromIndex > -1) {
      condensedItemsListRef.current?.resetAfterIndex(rerenderFromIndex);
    }
  });

  return (
    <ListViewWrapper className="list-view-wrapper">
      {requestList.response.error && <Toast message={requestList.response.error.message} kind="error" />}
      {!requestList.response.loading && (
        <div
          css={css`
            /* DO NOT DELETE THIS LINE!! WILL CAUSE BIG PROBLEMS IN SAFARI */
            flex: 1 1 auto;
            overflow-y: auto;
          `}
        >
          {requests && requests.length > 0 && requestList.state.viewType === ViewType.CLASSIC && (
            <VariableSizeList
              height={listDimensions.height}
              width={"100%"}
              itemCount={requests.length}
              itemSize={index => {
                // This creates necessary spacing before first element due to absolute positioning
                // after the last element a margin could be used to extend the container without any issues.
                // See RequestRowWrapper
                if (index === 0) return REQ_CARD_HEIGHT + requestList.filterBarHeight;
                // add some extra height for pagination bar
                if (requestList.shouldDisplayPagination && index === requests.length - 1) return REQ_CARD_HEIGHT + 50;
                return REQ_CARD_HEIGHT;
              }}
              itemData={itemData}
              initialScrollOffset={initialScrollOffset}
              onScroll={storeScrollOffset}
            >
              {RequestCardWindowListItem}
            </VariableSizeList>
          )}

          {requests && requests.length > 0 && requestList.state.viewType === ViewType.CONDENSED && (
            <VariableSizeList
              ref={condensedItemsListRef}
              height={listDimensions.height}
              width={"100%"}
              itemCount={requests.length}
              itemSize={index => {
                if (index === 0)
                  return !!requestList.state.expandedRequests[requests[index].id]
                    ? REQ_CONDENSED_EXPANDED_HEIGHT + requestList.filterBarHeight
                    : REQ_CONDENSED_HEIGHT + requestList.filterBarHeight;
                return !!requestList.state.expandedRequests[requests[index].id]
                  ? REQ_CONDENSED_EXPANDED_HEIGHT
                  : REQ_CONDENSED_HEIGHT;
              }}
              itemData={itemData}
              initialScrollOffset={initialScrollOffset}
              onScroll={storeScrollOffset}
            >
              {CondensedRequestWindowListItem}
            </VariableSizeList>
          )}
        </div>
      )}
      {requestListKey && <ListOverlay key={requestListKey} />}
    </ListViewWrapper>
  );
};

interface IRequestCardListItemProps {
  index: number;
  style?: React.CSSProperties;
  data: {
    requestUpdateMap: ListComponentProps["requestUpdateMap"];
    updateVisiblePills(requestId: string, pillId: string): void;
    expandedRequests?: RequestListState["expandedRequests"];
    visiblePills?: RequestListState["visiblePills"];
    queriesToRefetch?: Array<string | PureQueryOptions>;
  };
}
/**
 * RequestCard list item to plug into react-window
 */
function RequestCardWindowListItem(props: IRequestCardListItemProps) {
  const [requestList] = useRequestList();
  const request = requestList.response.data?.requestListPage.requests[props.index];
  if (!request) return null;

  return (
    <RequestCard
      request={request}
      requestUpdates={props.data.requestUpdateMap.get(request.id)}
      queriesToRefetch={props.data.queriesToRefetch}
      style={props.style}
      updateVisiblePills={props.data.updateVisiblePills}
    />
  );
}
/**
 * Condensed request list item to plug into react-window
 */
function CondensedRequestWindowListItem(props: IRequestCardListItemProps) {
  const [requestList] = useRequestList();
  const request = requestList.response.data?.requestListPage.requests[props.index];
  if (!request) return null;
  const isExpanded = (props.data.expandedRequests ?? {})[request.id];

  return (
    <CondensedRequestListItem
      request={request}
      requestUpdates={props.data.requestUpdateMap.get(request.id)}
      queriesToRefetch={props.data.queriesToRefetch}
      style={props.style}
      updateVisiblePills={props.data.updateVisiblePills}
      isExpanded={isExpanded}
    />
  );
}

export const ListViewContainer: React.FC<ListComponentProps> = props => {
  const searchDialog = useSearchDialog();
  const [requestList] = useRequestList();

  if (requestList.response.loading) {
    return (
      <LoadingBar
        css={css`
          z-index: var(--z-highest);
        `}
      />
    );
  }

  const requests = requestList.response.data?.requestListPage.requests;

  // no requests for the current view after user applied filters via UI
  if (!requests?.length && !requestList.isSearchView && requestList.haveFiltersDivergedForView) {
    return (
      <EmptyState
        variant="error"
        title="Oh no"
        subtitle={
          <div>
            There are no requests that match your selected filters.
            <br />
            Try changing them or{" "}
            <TextLink
              onClick={() => {
                setRequestListURLParam({
                  type: "clear_all_filters",
                  analytics: {
                    listViewType: requestList.listViewType
                  }
                });
              }}
            >
              clear all filters
            </TextLink>
          </div>
        }
      />
    );
  }

  // no requests to show due to search not returning any results
  if (!requests?.length && requestList.isSearchView) {
    return (
      <EmptyState
        variant="error"
        title="Oh no"
        subtitle={
          <div>
            There are no results that match your search.
            <br />
            <TextLink
              onClick={() => {
                searchDialog.open();
              }}
            >
              Search again
            </TextLink>
          </div>
        }
      />
    );
  }

  // no requests for a given view in general
  if (!requests?.length) {
    return props.emptyState ? (
      <>{props.emptyState}</>
    ) : (
      <EmptyState title="Nothing to see here" subtitle="There are no requests on this view." spacing="loose" />
    );
  }

  return requestList.state.viewType === ViewType.CLASSIC || requestList.state.viewType === ViewType.CONDENSED ? (
    <CardList {...props} />
  ) : requestList.state.viewType === ViewType.KANBAN ? (
    <KanbanBoard {...props} />
  ) : null;
};
