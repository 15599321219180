import { EmptyState, LoadingBar } from "src/components";
import React, { useRef } from "react";

import { FilterRequestsBar } from "./Filters/FilterBar";
import { HelmetComponent } from "src/App/Root/HelmetComponent";
import { RequestListProvider } from "./Provider";
import { RequestListUpdates } from "./RequestListUpdates";
import { RequestListView } from "src/globalTypes";
import { RequestlistPagination } from "./Pagination";
import { RouteComponentProps } from "src/util/router";
import { Views } from "./typings/Views";
import { css } from "@emotion/core";
import { deserializeUrlParams } from "./Filters/urlParamHelpers";
import gql from "graphql-tag";
import { routeData } from "src/App/Root/RouteData";
import { useQuery } from "react-apollo";

export const VIEWS = gql`
  query Views {
    views {
      id
      isDefault
      listViewType
      name
      filters
      orderBy
      viewType
    }
  }
`;

export const ViewContainer = (
  props: RouteComponentProps<{
    view_id: string;
  }>
) => {
  const viewsResponse = useQuery<Views>(VIEWS);
  const view = viewsResponse.data?.views.find(v => v.id === props.view_id) ?? null;
  const listDimensionsRef = useRef<HTMLDivElement | null>(null);

  // load view before loading request
  if (!view || !props.location) {
    return viewsResponse.loading ? (
      <LoadingBar />
    ) : view === null ? (
      <EmptyState title="Sorry, you are not able to load this view" />
    ) : null;
  }

  const params = deserializeUrlParams(props.location, {
    filters: view.filters,
    orderBy: view.orderBy
  });

  return (
    <RequestListProvider
      listViewType={RequestListView.VIEW}
      viewId={view.id}
      filtersFromView={params.filtersFromView}
      filtersFromUrl={params.filtersFromUrl}
      orderByKey={params.orderByKey}
      pageNumber={params.pageNumber}
      shouldPaginate
      filterBarHeight={80}
    >
      <HelmetComponent
        path={`/${routeData.requests.pathname}/${view.id}`}
        title={view.name}
        description={`Your saved view ${view.name}`}
      />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex-grow: 1;
        `}
        ref={listDimensionsRef}
      >
        <FilterRequestsBar />
        <RequestListUpdates listDimensionsRef={listDimensionsRef} />
        <RequestlistPagination />
      </div>
    </RequestListProvider>
  );
};
