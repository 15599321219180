import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { mdiDotsHorizontal, mdiOpenInNew } from "@mdi/js";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useMutation } from "react-apollo";
import { getAuthToken } from "src/App/Forms/helpers";
import { ExportAnswersButton } from "src/App/Forms/Manager/ExportAnswers";
import {
  FormSubmittedEventDetails,
  FormSubmittedEventDetails_submittedForm_schema_fieldsList
} from "src/App/Forms/typings/FormSubmittedEventDetails";
import { BannerWrapper, MessageBox, TimelineEvent } from "src/App/Requests/components";
import { REQUEST_GET } from "src/App/Requests/DetailView/REQUEST_GET";
import { formatMeta } from "src/App/Requests/DetailView/Timeline/Comment";
import {
  RequestGet_request,
  RequestGet_request_events_data_CommentAddedEvent,
  RequestGet_request_events_data_FormSubmittedEvent
} from "src/App/Requests/DetailView/typings/RequestGet";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { FeatureFlags, useFeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { EventProps } from "src/App/Timeline/Universal";
import { UserAvatar, UserName } from "src/App/User";
import { ReactComponent as BackLogo } from "src/assets/logos/Back.svg";
import {
  Button,
  CleanUnderlineButton,
  Col,
  DateTime,
  Dialog,
  LoadingBar,
  MaterialIcon,
  MessageText,
  Row,
  SimpleTooltip,
  SquareButton
} from "src/components";
import { PopOver } from "src/components/PopOver";
import { UserInfo } from "src/fragments/typings/UserInfo";
import { RequestChannels } from "src/globalTypes";
import { Modal } from "src/portals/Modal";
import { Typo } from "src/styling/primitives/typography";
import { FormAnswersModal } from "./Manager/AnswersModal";
import { FormPreview } from "./Manager/PreviewModal";
import { FormSentDetails } from "./typings/FormSentDetails";
import { FormToggleAutomatic, FormToggleAutomaticVariables } from "./typings/FormToggleAutomatic";
import { RequestDeleteFormMessage, RequestDeleteFormMessageVariables } from "./typings/RequestDeleteFormMessage";

const FormWrapper = styled.div`
  padding: 0.5rem 0.75rem;
  background: var(--lightGrey-1);
  border-radius: 0.5rem;
`;
const FormWrapperItalic = styled(FormWrapper)`
  font-style: italic;
`;

const FORM_TOGGLE_AUTOMATIC = gql`
  mutation FormToggleAutomatic($requestId: ID!, $formId: ID!, $automatic: Boolean!) {
    formToggleAutomatic(requestId: $requestId, formId: $formId, automatic: $automatic) {
      code
      success
      message
      request {
        id
        blacklistedFormAssociations {
          requestId
          formId
        }
      }
    }
  }
`;

const InlineTextBackLogo = () => (
  <>
    <BackLogo
      css={css`
        height: 1em;
        margin: 0 0.25em -0.25em 0;
      `}
    />
    Back
  </>
);

const AutoFormBanner: React.FC<{
  request: RequestGet_request;
  formSentDetails: FormSentDetails;
  fromBot: boolean;
}> = props => {
  const { emitSnack } = useSnack();

  const [toggleFormAutomatic, toggleFormAutomaticResponse] = useMutation<
    FormToggleAutomatic,
    FormToggleAutomaticVariables
  >(FORM_TOGGLE_AUTOMATIC, {
    onCompleted: res => {
      if (res.formToggleAutomatic.success) {
        emitSnack({
          type: "info",
          message: "Automatic form response disabled"
        });
      } else if (res.formToggleAutomatic.success === false) {
        emitSnack({
          type: "mutationError",
          message: res.formToggleAutomatic.message
        });
      }
    }
  });
  const autoFormDisabled = props.request.blacklistedFormAssociations.some(
    b => b.requestId === props.request.id && b.formId === props.formSentDetails.schema.id
  );
  return autoFormDisabled ? null : (
    <>
      {toggleFormAutomaticResponse.loading && <LoadingBar />}
      <BannerWrapper>
        {props.fromBot ? (
          <>
            This form was automatically sent by <InlineTextBackLogo />
          </>
        ) : (
          "This form will be sent automatically for similar future requests."
        )}{" "}
        <CleanUnderlineButton
          onClick={() =>
            toggleFormAutomatic({
              variables: {
                requestId: props.request.id,
                formId: props.formSentDetails.schema.id,
                automatic: false
              }
            })
          }
          disabled={toggleFormAutomaticResponse.loading}
          css={css`
            font-weight: var(--font-weight-body-regular);
            color: currentcolor;
          `}
        >
          Turn OFF automatic sending
        </CleanUnderlineButton>
      </BannerWrapper>
    </>
  );
};

export function FormSent(props: {
  text: string;
  form: FormSentDetails;
  author: UserInfo;
  showPreviewButton?: boolean;
}) {
  const [showPreview, setShowPreview] = useState(false);
  const authToken = getAuthToken();

  return (
    <>
      <Col>
        <Typo.Body
          css={css`
            margin: 0 0 0.75rem;
          `}
        >
          <UserName bold user={props.author} />
          &nbsp;sent the form&nbsp;<b>{props.form.schema.displayName}</b>
        </Typo.Body>
        <FormWrapperItalic>
          <MessageText text={props.text} />
        </FormWrapperItalic>
        <Row
          css={css`
            justify-content: flex-end;
          `}
        >
          {props.showPreviewButton && (
            <Button variant="ghost" onClick={() => setShowPreview(true)}>
              <MaterialIcon path={mdiOpenInNew} size={1.125} margin="0 0.25rem 0 0" />
              Preview form
            </Button>
          )}
          {!props.showPreviewButton && !props.form.submitTime && (
            <a
              href={`/response/${props.form.id}?${authToken && `auth_token=${authToken}`}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Button variant="ghost">
                <MaterialIcon path={mdiOpenInNew} size={1} margin="0 0.375rem 0 0" />
                Answer
              </Button>
            </a>
          )}
        </Row>
      </Col>

      <FormPreviewModal formId={props.form.id} dismiss={() => setShowPreview(false)} isOpen={showPreview} />
    </>
  );
}

enum FormMenuActions {
  DELETE = "Delete form",
  TOGGLE_AUTO = "Turn on automatic form response"
}

const formMenuOptions = [FormMenuActions.DELETE, FormMenuActions.TOGGLE_AUTO];

const FormContextMenu: React.FC<{
  commentId: string;
  request: RequestGet_request;
  formSentDetails: FormSentDetails;
}> = props => {
  const { hasFeatureFlags } = useFeatureFlags();
  const { emitSnack } = useSnack();

  const [toggleFormAutomatic, toggleFormAutomaticResponse] = useMutation<
    FormToggleAutomatic,
    FormToggleAutomaticVariables
  >(FORM_TOGGLE_AUTOMATIC, {
    onCompleted: res => {
      if (res.formToggleAutomatic.success) {
        emitSnack({
          type: "info",
          message: "Automatic form response enabled"
        });
      } else if (res.formToggleAutomatic.success === false) {
        emitSnack({
          type: "mutationError",
          message: res.formToggleAutomatic.message
        });
      }
    }
  });
  const autoFormDisabled = props.request.blacklistedFormAssociations.some(
    b => b.requestId === props.request.id && b.formId === props.formSentDetails.schema.id
  );
  const [deleteFormMessage, deleteFormMessageResponse] = useMutation<
    RequestDeleteFormMessage,
    RequestDeleteFormMessageVariables
  >(
    gql`
      mutation RequestDeleteFormMessage($requestId: ID!, $commentId: ID!) {
        requestDeleteComment(requestId: $requestId, commentId: $commentId) {
          code
          success
          message
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: REQUEST_GET,
          variables: {
            id: props.request.id
          }
        }
      ],
      variables: {
        requestId: props.request.id,
        commentId: props.commentId
      },
      onCompleted: res => {
        if (res.requestDeleteComment.success === false) {
          emitSnack({
            type: "mutationError",
            message: res.requestDeleteComment.message
          });
        }
      }
    }
  );
  return (
    <div>
      <PopOver.Menu
        trigger={
          <SquareButton>
            <MaterialIcon path={mdiDotsHorizontal} size={1.125} />
          </SquareButton>
        }
        options={formMenuOptions.filter(action => {
          if (action === FormMenuActions.TOGGLE_AUTO) {
            return hasFeatureFlags(FeatureFlags.AUTOFORM) && autoFormDisabled;
          }
          return true;
        })}
        disabled={formMenuOptions.filter(action => {
          if (action === FormMenuActions.TOGGLE_AUTO) {
            return toggleFormAutomaticResponse.loading;
          }
          if (action === FormMenuActions.DELETE) {
            return deleteFormMessageResponse.loading;
          }
          return false;
        })}
        selected={[]}
        onSelect={(action: FormMenuActions) => {
          switch (action) {
            case FormMenuActions.TOGGLE_AUTO:
              return toggleFormAutomatic({
                variables: {
                  requestId: props.request.id,
                  formId: props.formSentDetails.schema.id,
                  automatic: true
                }
              });
            case FormMenuActions.DELETE:
              return deleteFormMessage();
          }
        }}
      />
    </div>
  );
};

export const TimelineFormMessage: React.FC<
  EventProps & {
    data: RequestGet_request_events_data_CommentAddedEvent;
    fromBot: boolean;
  }
> = props => {
  const { hasFeatureFlags } = useFeatureFlags();
  const { currentUser } = useCurrentUser();
  if (!props.data.form) return null;
  return (
    <TimelineEvent
      alignLeft={props.data.author.id === props.request.requester.id}
      avatar={<UserAvatar sizeL user={props.data.author} />}
      footer={
        <SimpleTooltip
          label={formatMeta({
            author: props.data.author,
            request: props.request,
            timestamp: props.event.timestamp,
            channel: props.data.channel,
            mentionsOnly: props.data.mentionsOnly,
            publicComment: props.data.publicComment
          })}
        >
          <Row align="center">
            <Typo.Body medium>
              <UserName user={props.data.author} />
            </Typo.Body>
            {","}&nbsp;
            <DateTime timestamp={props.event.timestamp} />
          </Row>
        </SimpleTooltip>
      }
      contextMenu={
        <FormContextMenu request={props.request} commentId={props.data.commentId} formSentDetails={props.data.form} />
      }
    >
      <MessageBox>
        <div>
          <FormSent
            text={props.data.text}
            form={props.data.form}
            author={props.data.author}
            showPreviewButton={currentUser?.id !== props.request.requester.id}
          />
          {props.data.deleted && (
            <Typo.Body light>
              <i>This note was deleted</i>
            </Typo.Body>
          )}
        </div>
        {hasFeatureFlags(FeatureFlags.AUTOFORM) && (
          <AutoFormBanner fromBot={props.fromBot} request={props.request} formSentDetails={props.data.form} />
        )}
      </MessageBox>
    </TimelineEvent>
  );
};

function FormPreviewModal(props: { formId: string; dismiss(): void; isOpen: boolean }) {
  return (
    <Modal isOpen={props.isOpen} onDismiss={props.dismiss}>
      <Dialog large onClose={props.dismiss}>
        <FormPreview formId={props.formId} />
      </Dialog>
    </Modal>
  );
}

export function FormSubmitted(props: { formSubmittedEvent: FormSubmittedEventDetails }) {
  const [showAnswers, setShowAnswers] = useState(false);
  const answered = props.formSubmittedEvent.submittedForm.schema.fieldsList.filter(fieldResponded).length;
  const total = props.formSubmittedEvent.submittedForm.schema.fieldsList.length;
  return (
    <>
      <Col>
        <Typo.Body>
          <UserName user={props.formSubmittedEvent.submittedForm.submitter ?? undefined} bold />
          &nbsp;completed the form&nbsp;{props.formSubmittedEvent.submittedForm.schema.displayName}
        </Typo.Body>
        <FormWrapper>
          📊 {((100 * answered) / total).toFixed(0)}% ({answered} out of {total}) questions answered
        </FormWrapper>
        <Row justify="flex-end" margin="0.25rem 0 0 0">
          <ExportAnswersButton submissions={[props.formSubmittedEvent.submittedForm]} label="Download" />
          <Button variant="ghost" onClick={() => setShowAnswers(true)}>
            <MaterialIcon path={mdiOpenInNew} size={1.125} margin="0 0.25rem 0 0" />
            View answers
          </Button>
        </Row>
      </Col>

      <FormAnswersModal
        isOpen={showAnswers}
        formId={props.formSubmittedEvent.submittedForm.id}
        dismiss={() => setShowAnswers(false)}
      />
    </>
  );
}

function fieldResponded(field: FormSubmittedEventDetails_submittedForm_schema_fieldsList): boolean {
  switch (field.__typename) {
    case "DropdownField": {
      return field.selected !== null;
    }
    case "TextField": {
      return field.value !== null && field.value.trim().length !== 0;
    }
    case "AttachmentsField": {
      return field.attachment !== null;
    }
    default: {
      throw new Error("Unknown field type");
    }
  }
}

export const FormSubmittedMessage: React.FC<
  EventProps & {
    data: RequestGet_request_events_data_FormSubmittedEvent;
  }
> = props => {
  if (!props.data.submittedForm.submitter) return null;
  return (
    <>
      <TimelineEvent
        alignLeft={props.request.requester.id === props.data.submittedForm.submitter?.id}
        avatar={<UserAvatar sizeL user={props.data.submittedForm.submitter} />}
        footer={
          <SimpleTooltip
            label={formatMeta({
              author: props.data.submittedForm.submitter,
              request: props.request,
              timestamp: props.event.timestamp,
              channel: RequestChannels.WEB
            })}
          >
            <Row align="center">
              <Typo.Body medium>
                <UserName user={props.data.submittedForm.submitter} />
              </Typo.Body>
              {","}&nbsp;
              <DateTime timestamp={props.event.timestamp} />
            </Row>
          </SimpleTooltip>
        }
      >
        <MessageBox>
          <FormSubmitted formSubmittedEvent={props.data} />
        </MessageBox>
      </TimelineEvent>
    </>
  );
};
