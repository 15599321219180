import { ErrorMessage, Field, FieldProps, Formik } from "formik";
import * as React from "react";
import { defaultTeamCategories } from "src/App/Settings/defaultCategories";
import {
  BigButton,
  CancelButton,
  Col,
  FormikFieldGroup,
  FormikFieldInputs,
  FormikInput,
  LoadingBar,
  Row,
  SubmitButton
} from "src/components";
import { Form } from "src/components/Fields/FormWrappers";
import { FormikMultiSelect } from "src/components/Fields/MultiSelect";
import { SingleSelect } from "src/components/Fields/Select";
import { TeamPrivacy } from "src/globalTypes";
import { kebab } from "src/util/formatters";
import { navigate } from "src/util/router";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import { ITeamInitProps } from "./Container";
import { GSettingsTeamInitGet } from "./typings/GSettingsTeamInitGet";

export const TEAM_NAME_KEY = "BACK_NEW_TEAM_NAME";
export const TEAM_SLUG_KEY = "BACK_NEW_TEAM_SLUG";
export const TEAM_PRIVACY_KEY = "BACK_NEW_TEAM_PRIVACY";
export const TEAM_CATEGORIES_KEY = "BACK_NEW_TEAM_CATEGORIES";
export const TEAM_INTERNAL_KEY = "BACK_NEW_TEAM_INTERNAL";

/**
 * Temporarily stores the provided team name, team slug, team privacy and team categories in the session storage for later use.
 *
 * @param name The team name that should be stored in the session storage
 * @param slug The team slug that should be stored in the session storage
 * @param privacy The team privacy setting that should be stored in the session storage
 * @param categories The list of category IDs that should be preselected on the add categories step
 */
export const setTeamInfoInStorage = (
  name: string,
  slug: string,
  privacy: TeamPrivacy,
  internal: boolean,
  categories?: { id: string; name: string }[]
) => {
  window.sessionStorage.setItem(TEAM_NAME_KEY, name);
  window.sessionStorage.setItem(TEAM_SLUG_KEY, slug);
  window.sessionStorage.setItem(TEAM_PRIVACY_KEY, privacy);
  window.sessionStorage.setItem(TEAM_INTERNAL_KEY, JSON.stringify(internal));
  if (categories) {
    window.sessionStorage.setItem(TEAM_CATEGORIES_KEY, JSON.stringify(categories));
  } else {
    window.sessionStorage.removeItem(TEAM_CATEGORIES_KEY);
  }
};

/**
 * Checks if all required team information exists in the session storage currently.
 *
 * @returns True if team information is stored in session storage, false otherwise
 */
export const hasTeamInfoInStorage = () =>
  !!window.sessionStorage.getItem(TEAM_NAME_KEY) &&
  !!window.sessionStorage.getItem(TEAM_PRIVACY_KEY) &&
  !!window.sessionStorage.getItem(TEAM_SLUG_KEY) &&
  !!window.sessionStorage.getItem(TEAM_INTERNAL_KEY);

/**
 * Retrieves the team information that's stored in the session storage.
 *
 * @returns An object containing the team `categoryIds`, `name`, `privacy` and `slug` values from the session storage
 */
export const getTeamInfoFromStorage = () => ({
  categories: window.sessionStorage.getItem(TEAM_CATEGORIES_KEY),
  name: window.sessionStorage.getItem(TEAM_NAME_KEY),
  privacy: window.sessionStorage.getItem(TEAM_PRIVACY_KEY) as TeamPrivacy,
  slug: window.sessionStorage.getItem(TEAM_SLUG_KEY),
  internal: JSON.parse(window.sessionStorage.getItem(TEAM_INTERNAL_KEY) ?? "false")
});

/**
 * Removes all team information from the session storage.
 */
export const removeTeamInfoFromStorage = () =>
  [TEAM_NAME_KEY, TEAM_PRIVACY_KEY, TEAM_SLUG_KEY, TEAM_CATEGORIES_KEY].forEach(key =>
    window.sessionStorage.removeItem(key)
  );

const TeamInitFormSchema = yup.object().shape({
  name: yup.string().required("Required"),
  privacy: yup.string().required("Required"),
  slackChannels: yup.array(
    yup.object().shape({
      label: yup.string(),
      value: yup.string()
    })
  ),
  slug: yup
    .string()
    .matches(/^[a-z|\d|-]*$/, "Must be all lowercase alphanumeric characters with no spaces (hyphens allowed)")
    .matches(/[^-]$/, "Must not end with a hyphen")
    .matches(/^[^-]/, "Must not start with a hyphen")
    .required("Required"),
  categories: yup
    .array(
      yup
        .object()
        .shape({
          name: yup.string(),
          id: yup.string()
        })
        .required()
    )
    .required("Required")
});

export const TeamInitForm: React.ComponentType<ITeamInitProps & GSettingsTeamInitGet> = props => (
  <Formik
    onSubmit={payload => {
      setTeamInfoInStorage(payload.name, payload.slug, payload.privacy, payload.internal, payload.categories);
      if (props.onSubmit) {
        props.onSubmit(payload);
      }
    }}
    initialValues={{
      name: "",
      privacy: TeamPrivacy.PRIVATE,
      internal: false,
      slackChannels: [],
      slug: "",
      categories: []
    }}
    validationSchema={TeamInitFormSchema}
    render={form => (
      <Form onSubmit={form.handleSubmit} padding="0">
        <Col>
          <FormikFieldGroup.Container legend="Team type">
            <SingleSelect
              name="template"
              placeholder="Select..."
              isSearchable={false}
              options={defaultTeamCategories.map(teamCats => ({
                label: teamCats.teamType,
                value: teamCats.categories.map(name => ({ id: uuidv4(), name }))
              }))}
              onChange={data => {
                if (data && !("length" in data)) {
                  form.setFieldValue("categories", data.value);
                }
              }}
            />
            <FormikFieldGroup.Errors>
              <ErrorMessage name="categories" />
            </FormikFieldGroup.Errors>
          </FormikFieldGroup.Container>
          <Field
            name="name"
            render={(fieldProps: FieldProps) => (
              <FormikInput
                {...fieldProps}
                field={{
                  ...fieldProps.field,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    form.setFieldValue("name", e.target.value);
                    form.setFieldValue("slug", kebab(e.target.value));
                  }
                }}
                label={"Team name"}
              />
            )}
          />
          <Row>
            <Field name="slug" component={FormikInput} label={"Team email"} />
            {props.currentUser && props.currentUser.organization && (
              <Row
                margin="2.3rem 0 0 0.5rem"
                fontSize="0.875rem"
              >{`@${props.currentUser.organization.slug}.${process.env.REACT_APP_EMAIL_DOMAIN}`}</Row>
            )}
          </Row>
          <FormikFieldGroup.Container legend="Team visibility">
            <FormikFieldInputs.Checkbox name="internal" label="Internal only" />
            <FormikFieldGroup.HelpText>
              Internal-only teams are not visible to employees in Slack, MS Teams, and Google Chat
            </FormikFieldGroup.HelpText>
            <FormikFieldGroup.Errors>
              <ErrorMessage name="internal" />
            </FormikFieldGroup.Errors>
          </FormikFieldGroup.Container>
          {props.currentUser?.organization.isSlackInstalled && (
            <>
              <Field
                name="slackChannels"
                render={(fieldProps: FieldProps) => (
                  <FormikMultiSelect
                    {...fieldProps}
                    label="Associated slack channels"
                    helperText="Requests from these channels will be automatically assigned to this team"
                    options={(props.slackChannels || []).map(channel => ({
                      disabled: !!channel.isAssigned,
                      label: channel.slackName,
                      value: channel.slackId
                    }))}
                  />
                )}
              />
              <Row margin="0.5rem 0" />
            </>
          )}
          <Row justify="flex-end">
            {!form.isSubmitting && (
              <>
                {props.skippable && (
                  <CancelButton onClick={() => (props.redirect ? navigate(props.redirect) : null)}>
                    {props.skipText || "Cancel"}
                  </CancelButton>
                )}
                {props.bigButton && (
                  <BigButton type="submit" disabled={!form.isValid}>
                    {props.submitText || "Create team"}
                  </BigButton>
                )}
                {!props.bigButton && <SubmitButton>{props.submitText || "Create team"}</SubmitButton>}
              </>
            )}
            {form.isSubmitting && <LoadingBar />}
          </Row>
        </Col>
      </Form>
    )}
  />
);
