import * as React from "react";

import { AbsoluteTooltip, Button, Col, Dialog, EmptyState, Input, MaterialIcon, SquareButton } from "src/components";
import { Dispatch, FC, useEffect, useReducer, useState } from "react";
import {
  SavedReplyAction,
  SavedReplyState,
  initialSavedReplyState,
  savedRepliesReducer
} from "src/App/KB/Detail/savedRepliesReducer";
import { SavedReplyList, SavedReplyListVariables, SavedReplyList_savedReplyList_attachments } from "src/App/SavedReplies/typings/SavedReplyList";

import { Bookmark } from "src/svg/icons/Bookmark";
import { Icon } from "src/components/Icon";
import { Link } from "@reach/router";
import { Modal } from "src/portals/Modal";
import { Table } from "src/components/Table";
import { TeamInfo } from "src/fragments/typings/TeamInfo";
import { Typo } from "src/styling/primitives/typography";
import { backMarkdownToText } from "src/util/formatters";
import { css } from "@emotion/core";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { mdiPaperclip } from "@mdi/js";
import { TFileAction } from "src/App/Attachments/reducer";

interface SavedReplyPickerProps {
  savedReplyState: SavedReplyState;
  savedReplyDispatch: Dispatch<SavedReplyAction>;
  channel: "outreach" | "request";
  requestId?: string;
  team: TeamInfo;
  filesDispatch?: Dispatch<TFileAction>;
}

export const SavedReplyButton: React.FC<{
  selectedTeam: TeamInfo;
  channel: "outreach" | "request";
  onSelect: (reply: { title: string; body: string; attachments: SavedReplyList_savedReplyList_attachments[] }) => void;
  requestId?: string;
}> = props => {
  const { onSelect } = props;
  const [savedReplyState, savedReplyDispatch] = useReducer(savedRepliesReducer, initialSavedReplyState);
  const { savedReply } = savedReplyState;
  

  useEffect(() => {
    if (savedReply) {
      onSelect(savedReply);
      savedReplyDispatch({
        type: "CLEAR_SAVED_REPLY"
      });
    }
  }, [onSelect, savedReply]);
  return (
    <SavedReplyPicker
      savedReplyState={savedReplyState}
      savedReplyDispatch={savedReplyDispatch}
      team={props.selectedTeam}
      channel={props.channel}
      requestId={props.requestId}
    />
  );
};

export const SavedReplyPicker: FC<SavedReplyPickerProps> = props => {
  const onClose = (showSavedReplyModal: boolean) =>
    props.savedReplyDispatch({
      type: "TOGGLE_SAVED_REPLY_MODAL",
      showSavedReplyModal: showSavedReplyModal
    });
  return (
    <>
      <AbsoluteTooltip placement="top" content="Use a saved reply">
        <SquareButton onClick={() => onClose(true)}>
          <Icon>
            <Bookmark />
          </Icon>
        </SquareButton>
      </AbsoluteTooltip>
      <Modal isOpen={props.savedReplyState.showSavedReplyModal} onDismiss={() => onClose(false)} alignTop>
        <Dialog large onClose={() => onClose(false)} title="Select a saved reply">
          <SavedReplyModalContent {...props} onDismiss={() => onClose(false)} />
        </Dialog>
      </Modal>
    </>
  );
};

export const SAVED_REPLY_FRAGMENT = gql`
  fragment SavedReplyFragment on SavedReply {
    id
    teamId
    title
    body
    createTime
    updateTime
    attachments {
      id
      displayName
      size
    }
  }
`;

const SavedReplyModalContent: FC<SavedReplyPickerProps & { onDismiss(): void }> = props => {
  const [tableFilter, setTableFilter] = useState<string | null>(null);
  const savedReplyListResponse = useQuery<SavedReplyList, SavedReplyListVariables>(
    gql`
      query SavedReplyList($teamId: ID!) {
        savedReplyList(teamId: $teamId) {
          ...SavedReplyFragment
        }
      }
      ${SAVED_REPLY_FRAGMENT}
    `,
    {
      skip: !props.team.id,
      variables: {
        teamId: props.team.id ?? ""
      }
    }
  );

  const teamHasSavedReplies = !!savedReplyListResponse.data?.savedReplyList?.length;
  const savedReplies = (savedReplyListResponse.data?.savedReplyList ?? [])
    .filter(reply => {
      if (!tableFilter) return reply;
      else {
        const lowerFilter = tableFilter.toLowerCase();
        const lowerTitle = reply.title.toLowerCase();
        const lowerBody = reply.body.toLowerCase();

        return lowerTitle.includes(lowerFilter) || lowerBody.includes(lowerFilter);
      }
    })
    .sort((a, b) => a.title.localeCompare(b.title));
  return (
    <>
      <Col flex="1 1 auto">
        {!teamHasSavedReplies && !savedReplyListResponse.loading && (
          <EmptyState
            title={`No saved replies found for ${props.team.name}`}
            subtitle={
              <div>
                <div>
                  To create a new one, enter the <strong>saved replies</strong> section in settings
                </div>
                <Link to={`/settings/saved-replies/${props.team.slug}`}>
                  <Button
                    variant="primary"
                    css={css`
                      margin: var(--space-4-rem) auto 0 auto;
                    `}
                  >
                    Go to settings
                  </Button>
                </Link>
              </div>
            }
            spacing="tight"
            variant="info"
          />
        )}
        {teamHasSavedReplies && (
          <>
            <Input
              autoFocus
              placeholder={"Start typing to search in the team's saved replies"}
              onChange={e => {
                setTableFilter(e.target.value);
              }}
            />
            {!!savedReplies.length ? (
              <Table
                hasClickableRows
                css={css`
                  margin-top: var(--space-5-px);
                `}
              >
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Content</th>
                    <th css={css`
                      text-align: center`}>
                      Attachments
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {savedReplies.map(savedReply => {
                    const body = backMarkdownToText(savedReply.body);
                    return (
                      <tr
                        key={savedReply.id}
                        onClick={() => {
                          props.savedReplyDispatch({
                            type: "SELECT_SAVED_REPLY",
                            savedReply,
                            channel: props.channel,
                            requestId: props.requestId
                          });
                          props.filesDispatch?.({
                            type: "ADD_EXISTING_ATTACHMENTS",
                            attachments: savedReply.attachments
                          });
                          props.onDismiss();
                        }}
                        title={savedReply.body}
                      >
                        <td>
                          <div
                            css={css`
                              display: flex;
                              align-items: center;

                              & * + * {
                                margin-left: 0.375rem;
                              }
                            `}
                          >
                            <span>{savedReply.title}</span>
                          </div>
                        </td>

                        <td>{body}</td>
                        <td
                          css={css`
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          `}
                        >
                          {savedReply.attachments.length === 0 && "--"}
                          {savedReply.attachments.length > 0 && (
                            <>
                              {savedReply.attachments.length} <MaterialIcon path={mdiPaperclip} size={0.875} />
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <Typo.Body
                css={css`
                  margin: var(--space-5-rem) auto;
                `}
              >
                We could not find any saved replies matching your search in <b>{props.team.name}</b>.
              </Typo.Body>
            )}
          </>
        )}
      </Col>
    </>
  );
};
