import { css } from "@emotion/core";
import { NetworkStatus, PureQueryOptions } from "apollo-client";
import { Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import React from "react";
import { useMutation, useQuery } from "react-apollo";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { SETTINGS_TEAM_LIST_GET } from "src/App/Settings/Teams/Overview";
import { GSettingsTeamListGet } from "src/App/Settings/Teams/typings/GSettingsTeamListGet";
import { Dialog, FormikInput, SubmitButton } from "src/components";
import { SingleSelect } from "src/components/Fields/Select";
import { Modal } from "src/portals/Modal";
import { Toast } from "src/portals/Toast";
import { justify, row } from "src/styling/primitives";
import * as yup from "yup";
import { ProjectCreate, ProjectCreateVariables } from "./typings/ProjectCreate";

const PROJECT_CREATE = gql`
  mutation ProjectCreate($params: ProjectParameters!) {
    projectCreate(params: $params) {
      success
      code
      message
      object {
        id
      }
    }
  }
`;

const projectParamsSchema = yup.object().shape({
  title: yup.string().max(50, "Maximum 50 chararcters allowed").required(),
  teamId: yup.string().required()
});

export const CreateProjectModal: React.FC<{
  isShown: boolean;
  onClose(): void;
  queriesToRefetch: Array<string | PureQueryOptions>;
}> = ({ isShown, onClose, queriesToRefetch }) => {
  const { emitSnack } = useSnack();

  const teamListResponse = useQuery<GSettingsTeamListGet>(SETTINGS_TEAM_LIST_GET);
  const teamListOptions =
    teamListResponse.data?.teamList
      .filter(t => t.userIds.includes(teamListResponse.data?.currentUser?.id ?? ""))
      .map(t => ({ label: t.name, value: t.id })) ?? [];
  const [projectCreate, projectCreateResponse] = useMutation<ProjectCreate, ProjectCreateVariables>(PROJECT_CREATE, {
    refetchQueries: queriesToRefetch
  });
  const loading = teamListResponse.loading || projectCreateResponse.loading;
  const dismiss = onClose;

  return (
    <>
      {projectCreateResponse.data?.projectCreate.success === false && (
        <Toast kind="mutationError" message={projectCreateResponse.data?.projectCreate.message} />
      )}

      <Modal isOpen={isShown} onDismiss={dismiss}>
        <Dialog medium loading={loading} title="Create project" onClose={dismiss}>
          <Formik
            validationSchema={projectParamsSchema}
            onSubmit={params => {
              projectCreate({
                variables: { params },
                update: (_cache, response) => {
                  if (response.data?.projectCreate.success) {
                    emitSnack({
                      message: `Project "${params.title}" created.`,
                      type: "info"
                    });
                    dismiss();
                  }
                }
              });
            }}
            initialValues={{
              title: "",
              teamId: teamListOptions[0]?.value
            }}
          >
            {form => (
              <Form>
                <Field autoFocus autoComplete="off" label="Title" name="title" component={FormikInput} />
                <SingleSelect
                  name="teamId"
                  label="Team"
                  defaultValue={teamListOptions.find(t => t.value === form.values.teamId)}
                  options={teamListOptions}
                  onChange={o => {
                    if (o && "value" in o) form.setFieldValue("teamId", o.value);
                  }}
                  isDisabled={teamListResponse.networkStatus !== NetworkStatus.ready}
                  isSearchable={false}
                />
                <div
                  css={[
                    row,
                    justify.end,
                    css`
                      margin-top: 2rem;
                    `
                  ]}
                >
                  <SubmitButton disabled={!form.isValid}>Create project</SubmitButton>
                </div>
              </Form>
            )}
          </Formik>
        </Dialog>
      </Modal>
    </>
  );
};
