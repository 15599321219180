import { css } from "@emotion/core";
import { mdiCalendarRange } from "@mdi/js";
import { parseISO } from "date-fns";
import { ErrorMessage, Field, FieldProps, Formik } from "formik";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useMutation } from "react-apollo";
import { formatDateStringInUTC } from "src/App/Analytics/formatDate";
import { REQUEST_GET } from "src/App/Requests/DetailView/REQUEST_GET";
import { RequestListFragment } from "src/App/Requests/ListView/typings/RequestListFragment";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { REQUEST_LIST_COUNTS } from "src/App/Sidebar/RequestListCounts";
import { Button, Dialog, FormikFieldGroup, FormikInput, Input, MaterialIcon, Row, SimpleTooltip } from "src/components";
import { DatePicker } from "src/components/DayPicker";
import { Form } from "src/components/Fields/FormWrappers";
import { SingleSelect } from "src/components/Fields/Select";
import { GenericSvgFromString } from "src/components/Icon";
import { WorkflowParameterType, WorkflowVariableInput } from "src/globalTypes";
import { Modal } from "src/portals/Modal";
import { Typo } from "src/styling/primitives/typography";
import * as yup from "yup";
import {
  TeamWorkflows_team_workflows,
  TeamWorkflows_team_workflows_triggers_trigger_WorkflowTriggerManual,
  TeamWorkflows_team_workflows_triggers_trigger_WorkflowTriggerManual_parameters
} from "./typings/TeamWorkflows";
import { WorkflowCreateRunForTeam, WorkflowCreateRunForTeamVariables } from "./typings/WorkflowCreateRunForTeam";

type ManualTrigger = TeamWorkflows_team_workflows_triggers_trigger_WorkflowTriggerManual;
type ManualWorkflow = TeamWorkflows_team_workflows & { manualTrigger: ManualTrigger };

const ParameterInputModal: React.FC<{
  isOpen: boolean;
  workflow: ManualWorkflow;
  onSubmit(variables: WorkflowVariableInput[]): void;
  onDismiss(): void;
}> = props => {
  const validationSchemaFromParameters = (
    parameters: TeamWorkflows_team_workflows_triggers_trigger_WorkflowTriggerManual_parameters[]
  ) => {
    const yupSchemaForType = (type: WorkflowParameterType) => {
      switch (type) {
        case WorkflowParameterType.NUMBER:
          return yup.number();
        case WorkflowParameterType.DATE:
          return yup.date();
        case WorkflowParameterType.EMAIL:
          return yup.string().email();
        default:
          return yup.string();
      }
    };
    const schema = Object.fromEntries(parameters.map(p => [p.name, yupSchemaForType(p.type).required()]));

    return yup.object().shape(schema);
  };

  const parameters = props.workflow.manualTrigger.parameters || [];

  const initialValues = Object.fromEntries(parameters.map(p => [p.name, ""]));

  return (
    <Modal isOpen={props.isOpen} onDismiss={() => props.onDismiss()}>
      <Dialog medium onClose={() => props.onDismiss()}>
        <Row>
          <Typo.Body sizeXXL>Run workflow {props.workflow.name}</Typo.Body>
        </Row>
        {parameters.length > 0 && (
          <Row margin="var(--space-1-rem) 0 var(--space-6-rem) 0">
            This workflow requires additional input when triggered manually. Please provide all the required information
            to continue.
          </Row>
        )}
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchemaFromParameters(parameters)}
          onSubmit={(payload, actions) => {
            const variables = Object.entries(payload).map<WorkflowVariableInput>(([key, value]) => ({
              name: key,
              value: value.toString()
            }));
            props.onSubmit(variables);
            actions.setSubmitting(false);
          }}
        >
          {form => (
            <Form padding="0">
              {parameters.map(p => (
                <ParameterInputComponent parameter={p} key={p.name} />
              ))}
              <div
                css={css`
                  display: flex;
                  justify-content: flex-end;
                  & > * + * {
                    margin-left: var(--space-2-rem);
                  }
                `}
              >
                <Button size="large" onClick={() => props.onDismiss()}>
                  Cancel
                </Button>
                <Button
                  size="large"
                  variant="primary"
                  onClick={() => form.handleSubmit()}
                  disabled={form.isSubmitting || !form.isValid}
                >
                  Run
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Modal>
  );
};

const ParameterInputComponent: React.FC<{
  parameter: TeamWorkflows_team_workflows_triggers_trigger_WorkflowTriggerManual_parameters;
}> = props => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  if (props.parameter.type === WorkflowParameterType.NUMBER) {
    return (
      <Field
        name={props.parameter.name}
        type="number"
        placeholder="e.g. 12.34"
        component={FormikInput}
        label={props.parameter.description}
      />
    );
  }

  if (props.parameter.type === WorkflowParameterType.DROPDOWN) {
    return (
      <FormikFieldGroup.Container legend={props.parameter.description}>
        <div
          css={css`
            display: flex;
            flex-grow: 1;
            flex-shrink: 1;
            & > * + * {
              margin-left: var(--space-2-rem);
            }
          `}
        >
          <Field name={props.parameter.name}>
            {(formikFieldProps: FieldProps) => (
              <SingleSelect
                name={props.parameter.name}
                placeholder="Select one option"
                options={props.parameter.options?.map(o => ({ label: o, value: o }))}
                onChange={e => {
                  if (e && "value" in e) {
                    formikFieldProps.form.setFieldValue(props.parameter.name, e.value);
                  }
                }}
                isDisabled={false}
                isSearchable={false}
                showError={false}
              />
            )}
          </Field>
        </div>
        <FormikFieldGroup.Errors>
          <ErrorMessage name={props.parameter.name} />
        </FormikFieldGroup.Errors>
      </FormikFieldGroup.Container>
    );
  }

  if (props.parameter.type === WorkflowParameterType.DATE) {
    return (
      <FormikFieldGroup.Container legend={props.parameter.description}>
        <div
          css={css`
            display: flex;
            flex-grow: 1;
            flex-shrink: 1;
            & > * + * {
              margin-left: var(--space-2-rem);
            }
          `}
        >
          <Field name={props.parameter.name}>
            {(formikFieldProps: FieldProps) => (
              <>
                <Input
                  {...formikFieldProps.field}
                  type="date"
                  disabled={true}
                  css={css`
                    margin: 0 var(--space-2-rem) 0 0;
                  `}
                />
                <DatePicker
                  placement="bottom-start"
                  button={
                    <Button
                      css={css`
                        height: 100%;
                      `}
                      variant="primary"
                      onClick={() => setDatePickerOpen(true)}
                    >
                      <MaterialIcon
                        path={mdiCalendarRange}
                        size={1.25}
                        css={css`
                          margin-right: var(--space-2-rem);
                        `}
                      />
                      Select date
                    </Button>
                  }
                  numberOfMonths={1}
                  value={parseISO(formikFieldProps.form.values[props.parameter.name])}
                  onChange={day => {
                    formikFieldProps.form.setFieldValue(props.parameter.name, day ? formatDateStringInUTC(day) : null);
                    setDatePickerOpen(false);
                  }}
                  isOpen={datePickerOpen}
                  onClickAway={() => setDatePickerOpen(false)}
                  onClose={() => setDatePickerOpen(false)}
                />
              </>
            )}
          </Field>
        </div>
        <FormikFieldGroup.Errors>
          <ErrorMessage name={props.parameter.name} />
        </FormikFieldGroup.Errors>
      </FormikFieldGroup.Container>
    );
  }

  return <Field name={props.parameter.name} component={FormikInput} label={props.parameter.description} />;
};

export const TeamWorkflowsSection: React.FC<{
  request: RequestListFragment;
  /* workflows that have a manual trigger */
  teamManualWorkflows: TeamWorkflows_team_workflows[];
}> = props => {
  const { emitSnack } = useSnack();
  const [currentWorkflowId, setCurrentWorkflowId] = useState<string | null>(null);

  const [runWorkflowMutation] = useMutation<WorkflowCreateRunForTeam, WorkflowCreateRunForTeamVariables>(
    gql`
      mutation WorkflowCreateRunForTeam($teamId: ID!, $workflowId: ID!, $variables: [WorkflowVariableInput!]) {
        workflowCreateRunForTeam(teamId: $teamId, workflowId: $workflowId, variables: $variables) {
          code
          success
          message
        }
      }
    `,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: REQUEST_GET,
          variables: {
            id: props.request.id
          }
        },
        {
          query: REQUEST_LIST_COUNTS
        }
      ]
    }
  );

  // Extract the manual trigger from each workflow to simplify access
  const workflows: Array<ManualWorkflow> = props.teamManualWorkflows.map(w => {
    const manualTrigger = w.triggers.find(t => t.trigger.__typename === "WorkflowTriggerManual")
      ?.trigger as ManualTrigger;
    return {
      ...w,
      manualTrigger
    };
  });

  const runWorkflow = async ({
    workflow,
    variables
  }: {
    workflow: TeamWorkflows_team_workflows;
    variables: WorkflowVariableInput[];
  }) => {
    const res = await runWorkflowMutation({
      variables: {
        teamId: props.request.team.id,
        workflowId: workflow.id,
        variables: [
          // Variables should always be snake cased, not kebab cased
          { name: "request", value: props.request.id ?? "" },
          // Kebab-cased variables (with `-`) should be deprecated once this is deployed and all workflows are changed
          { name: "request.requester-id", value: props.request.requester.id ?? "" },
          { name: "request.assignee-id", value: props.request.assignee?.id ?? "" },
          { name: "request.project-id", value: props.request.project?.id ?? "" },
          // Snake cased variables are the way to go
          { name: "request.requester", value: props.request.requester.id ?? "" },
          { name: "request.requester_id", value: props.request.requester.id ?? "" },
          { name: "request.assignee_id", value: props.request.assignee?.id ?? "" },
          { name: "request.project_id", value: props.request.project?.id ?? "" },
          ...variables
        ]
      }
    });
    if (res.data && !res.data.workflowCreateRunForTeam.success) {
      emitSnack({
        message: res.data.workflowCreateRunForTeam.message,
        type: "mutationError"
      });
    } else {
      emitSnack({
        message: `${workflow.name} run created`,
        type: "info"
      });
    }
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        & > * + * {
          margin-top: var(--space-4-px);
        }
      `}
    >
      {[...workflows]
        .sort((w1, w2) => {
          return w1.name.localeCompare(w2.name);
        })
        .map(w => (
          <>
            <SimpleTooltip key={w.id} label={w.description}>
              <Button
                onClick={() =>
                  !!w.manualTrigger.parameters?.length
                    ? setCurrentWorkflowId(w.id)
                    : runWorkflow({ workflow: w, variables: [] })
                }
                variant="primary"
                css={css`
                  width: 100%;
                  justify-content: flex-start;
                `}
              >
                <GenericSvgFromString
                  css={css`
                    max-height: 20px;
                    height: 20px;
                    width: 20px;
                    margin-right: var(--space-2-px);

                    & > svg path {
                      fill: var(--blue-3);
                    }
                  `}
                  svg={w.icon}
                />
                <span>Run {w.name}</span>
              </Button>
            </SimpleTooltip>
            {!!w.manualTrigger.parameters?.length && (
              <ParameterInputModal
                workflow={w}
                isOpen={w.id === currentWorkflowId}
                onDismiss={() => setCurrentWorkflowId(null)}
                onSubmit={async variables => {
                  await runWorkflow({ workflow: w, variables: variables });
                  setCurrentWorkflowId(null);
                }}
              />
            )}
          </>
        ))}
    </div>
  );
};
