import * as React from "react";
import { ApolloProvider } from "react-apollo";
import * as ReactDOM from "react-dom";
import { apolloClient } from "src/util/apollo/client";
import { RootWrapper } from "./App/Root/Wrapper";
import { trackUser } from "./util/analytics";
import { trackPushNotif } from "./util/services/pushNotifications";
// polyfills
require("@ungap/url-search-params");
require("object.fromentries").shim();
require("array.prototype.flatmap").shim();

/* external assets (like icons for slack) */
require("src/assets/external");

// if logged on, track current user to segment
trackUser();
trackPushNotif();

const App = () => (
  <ApolloProvider client={apolloClient}>
    <RootWrapper />
  </ApolloProvider>
);

ReactDOM.render(<App />, document.getElementById("root"));
