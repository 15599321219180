import { differenceInDays } from "date-fns";
import gql from "graphql-tag";
import * as React from "react";
import { useQuery } from "react-apollo";
import { BaseLayout, Button, Row } from "src/components";
import { Badge } from "src/components/Badges";
import { Table } from "src/components/Table";
import { SubscriptionStatus } from "src/globalTypes";
import { Typo } from "src/styling/primitives/typography";
import { formatShortDate } from "src/util/formatters";
import { useCheckoutOpener, usePortalOpener } from "./hooks";
import { Subscription } from "./typings/Subscription";

export const SUBSCRIPTION = gql`
  query Subscription {
    subscription {
      id
      status
      createTime
      startTime
      trialStart
      trialEnd
      hasPaymentSource
    }
  }
`;

export function SubscriptionPage() {
  const subscriptionResponse = useQuery<Subscription>(SUBSCRIPTION);
  const [openPortal, isPortalLoading] = usePortalOpener();
  const [openCheckout, isCheckoutLoading] = useCheckoutOpener();
  const loading = isPortalLoading || isCheckoutLoading || subscriptionResponse.loading;
  const canManageSubscription = !!subscriptionResponse.data?.subscription?.hasPaymentSource;
  return (
    <BaseLayout
      headline={"Subscription details"}
      subline={
        subscriptionResponse.data &&
        subscriptionResponse.data.subscription && (
          <>
            {subscriptionResponse.data.subscription.status !== SubscriptionStatus.STATUS_IN_TRIAL && (
              <Typo.Body>
                Here you can see the details of your current subscription. If you want to update your billing details
                click on <b>Manage subscription</b>
              </Typo.Body>
            )}
            {subscriptionResponse.data.subscription.status === SubscriptionStatus.STATUS_IN_TRIAL && (
              <>
                <Typo.Body>
                  You have currently{" "}
                  <b>
                    {differenceInDays(
                      new Date(subscriptionResponse.data.subscription.trialEnd ?? new Date()),
                      new Date()
                    ) + 1}{" "}
                    days
                  </b>{" "}
                  left from your 14 day trial.
                </Typo.Body>
                {!canManageSubscription && (
                  <Typo.Body>
                    To make sure you can continue using Back afterwards you need to{" "}
                    <b>Provide your payment information.</b>
                  </Typo.Body>
                )}
                {canManageSubscription && (
                  <Typo.Body>
                    You've already provided us your payment information, which will be charged only once the trial
                    period is over.
                    <br />
                    In the meantime you can always <b>Manage your subscription</b>
                  </Typo.Body>
                )}
              </>
            )}
          </>
        )
      }
      isLoading={loading}
    >
      {subscriptionResponse && subscriptionResponse.data && (
        <>
          {subscriptionResponse.data.subscription.status !== SubscriptionStatus.STATUS_IN_TRIAL && (
            <Table>
              <thead>
                <tr>
                  <th>Plan</th>
                  <th>Status</th>
                  <th>Created at</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Growth Plan</td>
                  <td>
                    <SubscriptionStatusBadge
                      status={subscriptionResponse.data.subscription.status}
                      hasPaymentSource={!!subscriptionResponse.data.subscription.hasPaymentSource}
                    />
                  </td>
                  <td>{formatShortDate(subscriptionResponse.data.subscription.startTime ?? new Date())}</td>
                  <td>
                    <Row justify="flex-end">
                      <Button onClick={() => openPortal()}>Manage subscription</Button>
                    </Row>
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
          {subscriptionResponse.data.subscription.status === SubscriptionStatus.STATUS_IN_TRIAL && (
            <Table>
              <thead>
                <tr>
                  <th>Plan</th>
                  <th>Status</th>
                  <th>Created at</th>
                  <th>Trial days left</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Growth Plan</td>
                  <td>
                    <SubscriptionStatusBadge
                      status={subscriptionResponse.data.subscription.status}
                      hasPaymentSource={!!subscriptionResponse.data.subscription.hasPaymentSource}
                    />
                  </td>
                  <td>{formatShortDate(subscriptionResponse.data.subscription.trialStart ?? new Date())}</td>
                  <td>
                    {differenceInDays(
                      new Date(subscriptionResponse.data.subscription.trialEnd ?? new Date()),
                      Date.now()
                    ) + 1}{" "}
                    days
                  </td>
                  <td>
                    <Row justify="flex-end">
                      {canManageSubscription ? (
                        <Button onClick={() => openPortal()}>Manage subscription</Button>
                      ) : (
                        <Button onClick={() => openCheckout()}>Provide payment information</Button>
                      )}
                    </Row>
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
        </>
      )}
    </BaseLayout>
  );
}

function SubscriptionStatusBadge(props: { status: SubscriptionStatus; hasPaymentSource: boolean }) {
  switch (props.status) {
    case SubscriptionStatus.STATUS_ACTIVE:
      return <Badge success>Active</Badge>;
    case SubscriptionStatus.STATUS_IN_TRIAL:
      return props.hasPaymentSource ? <Badge success>In Trial (Activated)</Badge> : <Badge unread>In Trial</Badge>;
    case SubscriptionStatus.STATUS_CANCELLED:
      return <Badge neutral>Cancelled</Badge>;
    case SubscriptionStatus.STATUS_DELETED:
      return <Badge neutral>Unresolved</Badge>;
    case SubscriptionStatus.STATUS_UNSPECIFIED:
      return <Badge neutral>Unresolved</Badge>;
    default:
      throw new Error("Unknown subscription status");
  }
}
