import { css } from "@emotion/core";
import gql from "graphql-tag";
import React, { RefObject } from "react";
import { useMutation, useQuery } from "react-apollo";
import { PopperProps } from "react-popper";
import { PROJECT_DETAIL } from "src/App/Projects/DetailPage";
import { PROJECT_INFO_FRAGMENT } from "src/App/Projects/ProjectFragment";
import { projectStatusMap } from "src/App/Projects/Status";
import { ProjectInfoFragment } from "src/App/Projects/typings/ProjectInfoFragment";
import { ChangeProjectProjectList } from "./typings/ChangeProjectProjectList";
import { REQUEST_LIST_GET } from "src/App/Requests/ListView/REQUEST_LIST_GET";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { PillButton } from "src/components";
import { PopOver } from "src/components/PopOver";
import { RequestListView } from "src/globalTypes";
import { Toast } from "src/portals/Toast";
import { navigate } from "src/util/router";
import { RequestChangeProject, RequestChangeProjectVariables } from "./typings/RequestChangeProject";

const PROJECT_LIST = gql`
  query ChangeProjectProjectList {
    requestList(query: { filters: "type=project" }) {
      id
      title
      status
    }
  }
`;

const REQUEST_CHANGE_PROJECT = gql`
  mutation RequestChangeProject($requestId: ID!, $projectId: ID) {
    requestChangeProject(requestId: $requestId, projectId: $projectId) {
      code
      message
      success
      request {
        id
        project {
          ...ProjectInfoFragment
        }
      }
    }
  }
  ${PROJECT_INFO_FRAGMENT}
`;

export function ChangeProjectButton(props: {
  requestId: string;
  project: ProjectInfoFragment | null;
  menuPlacement?: PopperProps["placement"];
  buttonRef?: RefObject<HTMLButtonElement>;
  onClickAway?(e: React.ChangeEvent<{}>): void;
  onAssign?(): void;
  sizeL?: boolean;
  trigger?: JSX.Element;
}) {
  const { emitSnack } = useSnack();

  const projectsResponse = useQuery<ChangeProjectProjectList>(PROJECT_LIST, {
    fetchPolicy: "network-only"
  });

  const queriesToRefetch = props.project
    ? [
        {
          query: PROJECT_DETAIL,
          variables: {
            id: props.project.id
          }
        },
        {
          query: REQUEST_LIST_GET,
          variables: {
            view: RequestListView.REQUESTS,
            query: { project: [props.project.id] }
          }
        }
      ]
    : undefined;

  const [requestChangeProject, requestChangeProjectResponse] = useMutation<
    RequestChangeProject,
    RequestChangeProjectVariables
  >(REQUEST_CHANGE_PROJECT, {
    awaitRefetchQueries: true,
    refetchQueries: queriesToRefetch
  });

  const handleChangeProject = (currentProjectId?: string, newProjectId?: string) => {
    const currentProjectTitle = projectsResponse.data?.requestList.find(({ id }) => id === currentProjectId)?.title;
    const newProjectTitle = projectsResponse.data?.requestList.find(({ id }) => id === newProjectId)?.title;
    requestChangeProject({
      variables: {
        requestId: props.requestId,
        projectId: newProjectId ?? null
      },
      update: (_, res) => {
        if (res.data?.requestChangeProject.success) {
          if (currentProjectId && newProjectId) {
            emitSnack({
              type: "info",
              message: `Request moved from "${currentProjectTitle}" to "${newProjectTitle}"`
            });
          } else if (currentProjectId) {
            emitSnack({
              type: "info",
              message: `Request removed from "${currentProjectTitle}"`
            });
          } else if (newProjectId) {
            emitSnack({
              type: "info",
              message: `Request added to "${newProjectTitle}"`
            });
          }
        }
      }
    });
  };

  /* projects w/ status open + current selected project */
  const openProjects =
    projectsResponse.data?.requestList
      .filter(p => p.status === projectStatusMap.open || p.id === props.project?.id)
      .sort((a, b) => a.title.localeCompare(b.title)) ?? [];

  return (
    <>
      {requestChangeProjectResponse.data?.requestChangeProject.success === false && (
        <Toast kind="mutationError" message={requestChangeProjectResponse.data.requestChangeProject.message} />
      )}
      <PopOver.Menu
        onClickAway={props.onClickAway}
        trigger={
          props.trigger ? (
            <>{props.trigger}</>
          ) : (
            <PillButton
              data-intercom-target="Request project"
              ref={props.buttonRef}
              disabled={requestChangeProjectResponse.loading}
              title={props.project?.title}
              css={css`
                margin: 0 0.25rem;
              `}
            >
              <>📁 {props.project?.title ?? "No project"}</>
            </PillButton>
          )
        }
        selected={props.project?.id}
        options={
          openProjects.length === 0
            ? [
                {
                  headline: "No projects open",
                  options: [
                    {
                      id: "null",
                      name: "Create new project",
                      onClick: e => {
                        e.stopPropagation();
                        navigate("/projects");
                      }
                    }
                  ]
                }
              ]
            : [
                {
                  headline: props.project?.id ? "Change project" : "Set project",
                  options: openProjects.map(project => ({
                    id: project.id,
                    name: project.title
                  }))
                }
              ]
        }
        labelForRemoveOption="Remove from project"
        onSelect={(id: string) => {
          if (id === props.project?.id) {
            // deselect
            handleChangeProject(props.project?.id);
          } else {
            handleChangeProject(props.project?.id, id);
          }
          props.onAssign?.();
        }}
        onRemove={() => {
          handleChangeProject(props.project?.id);
          props.onAssign?.();
        }}
      />
    </>
  );
}
