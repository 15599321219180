import { css } from "@emotion/core";
import { format } from "date-fns";
import gql from "graphql-tag";
import React from "react";
import { useQuery } from "react-apollo";
import { ChangeRequester } from "src/App/Requests/Actions/ChangeRequester";
import { UserComponentFields } from "src/App/User";
import { LoadingBar } from "src/components";
import { Typo } from "src/styling/primitives/typography";
import { Subheading } from "./Component";
import { OktaProfileInfo_oktaUser } from "./typings/OktaProfileInfo";
import { UserProfileInfo, UserProfileInfoVariables } from "./typings/UserProfileInfo";
import { OktaRequesterProfile } from "./OktaRequesterProfile";

export const RequesterProfile: React.FC<{
  requestId: string;
  requester: UserComponentFields;
  disabled: boolean;
  oktaProfile: OktaProfileInfo_oktaUser | null;
}> = ({ requestId, requester, disabled, oktaProfile }) => {
  const userResponse = useQuery<UserProfileInfo, UserProfileInfoVariables>(
    gql`
      query UserProfileInfo($id: ID!) {
        user(id: $id) {
          id
          name
          email
          profile {
            name
            gender
            activityStatus
            position
            hireDate
            officeName
            departmentName
            vacationDayBalance
            supervisor {
              name
              email
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      variables: {
        id: requester.id
      }
    }
  );

  const userProfile = userResponse.data?.user.profile;
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;

        & * {
          line-height: var(--line-height-normal);
        }

        & > * + * {
          margin-top: var(--space-5-rem);
        }
        & > * > *:first-child {
          margin-bottom: var(--space-1-rem);
        }
      `}
    >
      <div>
        <Subheading>Requester</Subheading>
        <ChangeRequester requestId={requestId} currentRequester={requester} disabled={disabled} />
      </div>
      {userResponse.loading && <LoadingBar />}
      {userProfile && (
        <>
          {userProfile.position && (
            <div>
              <Subheading>Role and Department</Subheading>
              <Typo.Body>{userProfile.position}</Typo.Body>
              <Typo.Body>{userProfile.departmentName}</Typo.Body>
            </div>
          )}
          {userProfile.supervisor && userProfile.supervisor.name && (
            <div>
              <Subheading>Reports to</Subheading>
              <Typo.Body>{userProfile.supervisor.name}</Typo.Body>
            </div>
          )}
          {userProfile.officeName && (
            <div>
              <Subheading>Location</Subheading>
              <Typo.Body>{userProfile.officeName}</Typo.Body>
            </div>
          )}
          {userProfile.hireDate && (
            <div>
              <Subheading>Employee since</Subheading>
              <Typo.Body>{format(new Date(userProfile.hireDate), "MMMM do, yyyy")}</Typo.Body>
            </div>
          )}
          <div>
            <Subheading>Contact details</Subheading>
            <Typo.Body>{requester.email}</Typo.Body>
          </div>
        </>
      )}
      {oktaProfile && <OktaRequesterProfile user={oktaProfile} />}
    </div>
  );
};
