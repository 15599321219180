import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { mdiDotsHorizontal } from "@mdi/js";
import * as React from "react";
import { UserAvatar, UserName } from "src/App/User";
import { DisableButton, MaterialIcon, Menu, MenuItem, Row, SquareButton } from "src/components";
import { PopOver } from "src/components/PopOver";
import { Table } from "src/components/Table";
import { Tooltip } from "src/components/Tooltip";
import { TeamPrivacy } from "src/globalTypes";
import { navigate } from "src/util/router";
import { GSettingsTeamListGet } from "./typings/GSettingsTeamListGet";

export const TeamMembers = styled.span`
  cursor: default;
  border-bottom: 1px dotted var(--text-6);
`;

export const TeamMembersItem = styled.div`
  display: flex;
  flex: 0 0 auto;
  line-height: 1.5;
  color: var(--text-6);
  padding: 0.375rem 1rem;
  font-size: 0.875rem;
`;

export const TeamTable: React.ComponentType<GSettingsTeamListGet> = props => (
  <Table>
    <thead>
      <tr>
        <th className="name">Name</th>
        <th className="email">Team email</th>
        <th className="member">Members</th>
        <th className="more" />
      </tr>
    </thead>
    <tbody>
      {props.teamList &&
        props.teamList.map((team, i) => {
          const notAllowedToEdit =
            team.privacy === TeamPrivacy.PRIVATE &&
            team.userIds.length > 0 &&
            !team.userIds.includes(props.currentUser?.id ?? "");
          return (
            <tr key={i} className="team" data-testid="team-loaded">
              <td className="name">
                <Row align="center">{team.name}</Row>
              </td>
              <td className="email">{team.emailAddresses.to}</td>
              <td className="members">
                <PopOver.Blank
                  key={i}
                  placement="bottom-start"
                  hover={true}
                  button={
                    <TeamMembers>
                      {team.memberCount} {team.memberCount === 1 ? "member" : "members"}
                    </TeamMembers>
                  }
                >
                  <Menu>
                    {team.userIds.map(userId => (
                      <TeamMembersItem key={userId}>
                        <UserAvatar
                          sizeS
                          userId={userId}
                          css={css`
                            margin-right: var(--space-2-rem);
                          `}
                        />
                        <UserName userId={userId} you={false} />
                      </TeamMembersItem>
                    ))}
                  </Menu>
                </PopOver.Blank>
              </td>
              <td className="more">
                <Row justify="flex-end">
                  <PopOver.Blank
                    disabled={notAllowedToEdit}
                    placement="bottom-end"
                    button={
                      <Tooltip
                        hideArrow
                        target={
                          <DisableButton disabled={notAllowedToEdit}>
                            <SquareButton
                              data-testid="edit-team-button"
                              disabled={notAllowedToEdit}
                              size="small"
                              onClick={e => e.preventDefault()}
                            >
                              <MaterialIcon path={mdiDotsHorizontal} size={1.125} />
                            </SquareButton>
                          </DisableButton>
                        }
                      >
                        {notAllowedToEdit && " You can't edit this team because you're not a member"}
                      </Tooltip>
                    }
                  >
                    <Menu>
                      <MenuItem
                        data-testid="team-settings-item"
                        onClick={() => navigate(`/settings/teams/${team.id}/edit`)}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        data-testid="team-settings-item"
                        onClick={() => navigate(`/settings/teams/${team.id}/manage`)}
                      >
                        Manage members
                      </MenuItem>
                    </Menu>
                  </PopOver.Blank>
                </Row>
              </td>
            </tr>
          );
        })}
    </tbody>
  </Table>
);
