import gql from "graphql-tag";
import * as React from "react";
import { Mutation } from "react-apollo";
import { Toast } from "src/portals/Toast";
import { IUserUpdateProps, SETTINGS_USER_GET } from "./Container";
import { UserUpdateForm } from "./Form";
import { GSettingsUserGet } from "./typings/GSettingsUserGet";
import { GSettingsUserUpdate, GSettingsUserUpdateVariables } from "./typings/GSettingsUserUpdate";

const SETTINGS_USER_UPDATE = gql`
  mutation GSettingsUserUpdate($params: UpUser!) {
    userUpdate(params: $params) {
      code
      message
      success
      user {
        id
      }
    }
  }
`;

/**
 * Component to handle user update mutation and submission logic
 */
export const UserUpdateSubmission: React.ComponentType<GSettingsUserGet & IUserUpdateProps> = props => (
  <Mutation<GSettingsUserUpdate, GSettingsUserUpdateVariables>
    mutation={SETTINGS_USER_UPDATE}
    refetchQueries={[
      {
        query: SETTINGS_USER_GET,
        variables: {
          id: props.user.id
        }
      }
    ]}
  >
    {(userUpdate, response) => {
      return (
        <>
          <UserUpdateForm {...props} userUpdate={userUpdate} />
          {response.data && !response.data.userUpdate.success && (
            <Toast message={response.data.userUpdate.message} kind="mutationError" />
          )}
        </>
      );
    }}
  </Mutation>
);
