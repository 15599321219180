import { css } from "@emotion/core";
import { fontWeights } from "src/styling/typography";
import { csx } from "src/util/csx";

export const Badge = csx(
  [
    css`
      border-radius: var(--border-radius-xs);
      box-sizing: border-box;
      height: 1.125rem;
      padding: 4px 6px;
      font-size: var(--font-size-label);
      line-height: 1;
      text-transform: uppercase;
      font-weight: ${fontWeights.bold};
      letter-spacing: 0.05em;
      color: var(--text-6);
      background: var(--white);
      min-width: fit-content;
    `
  ],
  {
    unread: css`
      color: var(--yellow-9);
      background: var(--yellow-2);
    `,
    success: css`
      color: var(--green-8);
      background: var(--green-2);
    `,
    neutral: css`
      color: var(--text-5);
      background: var(--lightGrey-2);
    `,
    warning: css`
      background: var(--flesh-2);
      color: var(--red-7);
    `,
    info: css`
      background: var(--lightBlue-2);
      color: var(--blue-4);
    `,
    icon: css`
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
      padding: 2px;
    `,
    noTextTransform: css`
      text-transform: none;
    `,
    inert: css`
      cursor: default;
    `
  },
  "span"
);
