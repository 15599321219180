import { parseISO } from "date-fns";
import * as React from "react";
import { useContext } from "react";
import { formatDateStringInUTC } from "src/App/Analytics/formatDate";
import { DatePicker } from "src/components/DayPicker";
import { ActionsContext } from "./Provider";

export const ChangeDueDatePicker: React.FC<{
  button: JSX.Element;
  requestId: string;
  dueAt: ScalarDateTime | null;
  isOpen?: boolean;
  onClickAway?(e: React.ChangeEvent<{}>): void;
  onClose?(): void;
}> = props => {
  const {
    handlers: { changeDueDate }
  } = useContext(ActionsContext);

  return (
    <DatePicker
      placement="bottom-start"
      button={props.button}
      numberOfMonths={1}
      value={props.dueAt ? parseISO(props.dueAt) : null}
      onChange={day => changeDueDate(props.requestId, day ? formatDateStringInUTC(day) : null)}
      isOpen={props.isOpen}
      onClickAway={props.onClickAway}
      onClose={props.onClose}
    />
  );
};
