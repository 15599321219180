import * as React from "react";
import { css } from "@emotion/core";
import { ExternalDocumentSourceKind } from "src/globalTypes";
import confluenceSvg from "src/assets/logos/integrations/Confluence.svg";
import notionSvg from "src/assets/logos/Notion.svg";
import sharepointSvg from "src/assets/logos/integrations/Sharepoint.svg";
import { csx } from "src/util/csx";
import { reportDevError } from "src/util";

const Logo = csx(
  [
    css`
      display: inline-block;
      height: 1rem;
      flex-shrink: 0;
    `
  ],
  {},
  "img"
);

const ConfluenceLogo = <Logo src={confluenceSvg} alt="Confluence" />;
const NotionLogo = <Logo src={notionSvg} alt="Notion" />;
const SharepointLogo = <Logo src={sharepointSvg} alt="Sharepoint" />;

export interface IDocument {
  source: "internal" | "notion" | "confluence" | "external";
  external?: { source: { kind: ExternalDocumentSourceKind } } | null;
}

export const DocumentLogoForSourceKind: React.FC<{ kind: ExternalDocumentSourceKind }> = ({ kind }) => {
  switch (kind) {
    case ExternalDocumentSourceKind.EXTERNAL_SOURCE_KIND_CONFLUENCE:
      return ConfluenceLogo;
    case ExternalDocumentSourceKind.EXTERNAL_SOURCE_KIND_NOTION:
      return NotionLogo;
    case ExternalDocumentSourceKind.EXTERNAL_SOURCE_KIND_SHAREPOINT:
      return SharepointLogo;
    default:
      reportDevError(`unexpected external document source kind: ${kind}`);
      return null;
  }
};

export const DocumentLogo: React.FC<{ doc: IDocument }> = ({ doc }) => {
  switch (doc.source) {
    case "internal":
      return null;
    case "confluence":
      return ConfluenceLogo;
    case "notion":
      return NotionLogo;
    case "external":
      return (
        <DocumentLogoForSourceKind
          kind={doc.external?.source.kind ?? ExternalDocumentSourceKind.EXTERNAL_SOURCE_KIND_UNSPECIFIED}
        />
      );
  }
};
