import styled from "@emotion/styled";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { Button, Row } from "src/components";

const Motion = styled(motion.div)`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
`;

export function ConfirmButton(props: {
  action?: string;
  cancel?: string;
  confirm?: string;
  disabled?: boolean;
  onClick(): void;
}) {
  const [confirmState, setConfirmState] = useState<"initial" | "unconfirmed" | "confirmed">("initial");
  return (
    <Row align="center" overflow="hidden">
      <AnimatePresence initial={false}>
        {confirmState === "initial" && (
          <Motion
            key="action"
            transition={{ duration: 0.15 }}
            initial={{ x: "100%", opacity: 1 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: "100%", opacity: 0, zIndex: -1 }}
          >
            <Button disabled={props.disabled} onClick={() => setConfirmState("unconfirmed")}>
              {props.action || "Remove"}
            </Button>
          </Motion>
        )}
        {(confirmState === "unconfirmed" || confirmState === "confirmed") && (
          <Motion
            key="confirm"
            transition={{ duration: 0.15 }}
            initial={{ x: "-100%", opacity: 1 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: "-100%", opacity: 0, zIndex: -1 }}
          >
            <Button onClick={() => setConfirmState("initial")}>{props.cancel || "Cancel"}</Button>
            <Button
              disabled={confirmState === "confirmed"}
              onClick={() => {
                setConfirmState("confirmed");
                props.onClick();
              }}
              variant="red"
              margin="0 0 0 0.5rem"
            >
              {props.confirm || "Confirm"}
            </Button>
          </Motion>
        )}
      </AnimatePresence>
    </Row>
  );
}
