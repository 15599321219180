import { css } from "@emotion/core";
import { mdiChevronLeft } from "@mdi/js";
import { motion } from "framer-motion";
import React from "react";
import { Button, LoadingBar, MaterialIcon } from "src/components";
import { layout } from "src/styling/primitives";
import { Typo } from "src/styling/primitives/typography";
import { isString } from "src/util";
import { csx } from "src/util/csx";
import { navigate } from "src/util/router";

const BaseLayoutWrapper = csx(
  [
    layout.page,
    css`
      width: 100%;
      min-width: initial;
      overflow: visible;
      box-sizing: border-box;
      padding: var(--space-7-rem);
    `
  ],
  {
    fullHeight: css`
      min-height: 100%;
      height: auto;
    `
  }
);
export const BaseLayout: React.FC<React.PropsWithChildren<{
  headline?: React.ReactNode;
  subline?: React.ReactNode;
  stickyHeader?: React.ReactNode;
  button?: React.ReactNode;
  isLoading?: boolean;
  backLink?: BackLink;
  fullHeight?: boolean;
}>> = props => (
  <div
    css={[
      css`
        width: 100%;
        max-height: 100%;
        overflow: auto;
      `,
      props.fullHeight &&
        css`
          height: 100%;
        `
    ]}
  >
    <BaseLayoutWrapper fullHeight={props.fullHeight}>
      {props.isLoading && <LoadingBar />}
      <motion.div
        css={css`
          /* flex-shrink prevents the header from collapsing in safai */
          flex-shrink: 0;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;

          & > * + * {
            margin-left: var(--space-4-rem);
          }
        `}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.1 }}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            flex-grow: 1;
          `}
        >
          {props.backLink && (
            <BackLink
              {...props.backLink}
              css={[
                css`
                  margin-bottom: var(--space-3-rem);
                `
              ]}
            />
          )}
          {props.headline && (
            <>
              {typeof props.headline === "string" ? (
                <Typo.Body
                  sizeXXL
                  css={css`
                    margin-bottom: var(--space-2-rem);
                  `}
                  data-testid="baselayout-headline"
                >
                  {props.headline}
                </Typo.Body>
              ) : (
                <div
                  css={css`
                    margin-bottom: var(--space-2-rem);
                  `}
                >
                  {props.headline}
                </div>
              )}
            </>
          )}

          {isString(props.subline) ? (
            <Typo.Body data-testid="baselayout-subline">{props.subline}</Typo.Body>
          ) : (
            <div
              css={css`
                display: flex;
                align-items: flex-start;
              `}
            >
              {props.subline}
            </div>
          )}
        </div>
        {props.button && (
          <div
            css={css`
              display: flex;
              flex-shrink: 0;

              & > * + * {
                margin-left: var(--space-3-rem);
              }
            `}
          >
            {props.button}
          </div>
        )}
      </motion.div>
      <motion.div
        css={[
          css`
            width: 100%;
            display: flex;
            flex-direction: column;
          `,
          (props.headline || props.subline) &&
            css`
              margin-top: 2.5rem;
            `,
          props.fullHeight &&
            css`
              flex-grow: 2;
            `
        ]}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.1 }}
      >
        {props.stickyHeader && (
          <div
            css={css`
              display: flex;
              position: sticky;
              top: 0;
              margin: calc(-1 * var(--space-7-px)) 0 calc(-1 * var(--space-4-px));
              padding: var(--space-7-px) 0 var(--space-4-px);
              background: var(--white);
              box-shadow: 0 var(--space-2-px) var(--space-4-px) var(--white);
              z-index: var(--z-high);
            `}
          >
            {props.stickyHeader}
          </div>
        )}
        {!props.isLoading && props.children}
      </motion.div>
    </BaseLayoutWrapper>
  </div>
);

interface BackLink {
  path: string;
  label?: string;
  className?: string;
}

export const BackLink: React.FC<BackLink> = ({ label, path, className }) => {
  return (
    <Button
      role="link"
      variant="secondary"
      size="small"
      onClick={() => {
        navigate(path);
      }}
      css={css`
        height: var(--space-5-rem);
        width: fit-content;
        box-sizing: border-box;
        padding: 0 3px;
        text-align: center;
      `}
      className={className}
      data-testid="baselayout-back-link"
    >
      <MaterialIcon path={mdiChevronLeft} size={1.125} />{" "}
      {!!label && (
        <span
          css={[
            css`
              padding-right: var(--space-2-rem);
            `
          ]}
        >
          {label}
        </span>
      )}
    </Button>
  );
};
