import styled from "@emotion/styled";
import { range } from "lodash";
import * as React from "react";

const Wrapper = styled.div<{ margin?: string }>`
  display: flex;
  justify-content: center;
  margin: ${p => p.margin || "2rem 0 0 0"};
`;

const StepBullet = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.25rem;
  margin: 0.1rem;
  background-color: var(--border);

  &.active {
    background-color: var(--blue-1);
  }
`;

interface IStepIndicator {
  stepCount: number;
  currentStep: number;
  margin?: string;
}

export const StepIndicator: React.ComponentType<IStepIndicator> = props => (
  <Wrapper margin={props.margin}>
    {range(props.stepCount).map((index: number) => (
      <StepBullet key={`step-${index + 1}`} className={index + 1 === props.currentStep ? "active" : ""} />
    ))}
  </Wrapper>
);
