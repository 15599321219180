import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { fontWeights } from "src/styling/typography";
import { csx } from "src/util/csx";

export const FormWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-width: 28rem;
  background: var(--white);
`;

export const Form = styled.form<{ padding?: string }>`
  padding: ${p => (p.padding ? p.padding : "2rem 3rem")};

  & .heading {
    border-bottom: 1px solid var(--border);
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    > div {
      padding-left: 0;
    }
  }

  & .close {
    color: var(--border);
  }

  & .select-container {
    width: 100%;
  }
`;

export const FormSurface = styled.div<{
  padding?: string;
}>`
  background: var(--white);
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 0 0 1.375rem rgba(0, 0, 0, 0.04);
  border: 1px solid var(--border);
  font-size: 0.875rem;
  padding: ${p => (p.padding ? p.padding : "3.5rem")};
  width: 30rem;
`;

export const FormLabel = csx(
  [
    css`
      display: inline-block;
      color: var(--text-6);
      font-size: var(--font-size-body);
      font-weight: var(--font-weight-body-medium);
      margin: 0 0 var(--space-1-rem);
    `
  ],
  {},
  "label"
);

export const FormHeading = styled.div`
  font-size: 1.75rem;
  font-weight: ${() => fontWeights.bold};
  line-height: 1.75rem;
  margin-bottom: 0.25rem;
`;

export const FormSubheading = styled.div`
  margin: 1rem 0;
  font-size: 1rem;
  color: var(--text-6);
`;

export const FormRule = styled.div`
  height: 1px;
  margin: 0.5rem 0;
  background-color: var(--border);
`;
