import { css } from "@emotion/core";
import { mdiAccountPlus } from "@mdi/js";
import React, { ForwardRefExoticComponent, useContext } from "react";
import { ActionsContext } from "src/App/Requests/Actions/Provider";
import { RequestListFragment } from "src/App/Requests/ListView/typings/RequestListFragment";
import { UserAvatar, UserName } from "src/App/User";
import { Pill } from "src/components/PillButton";
import { PopOver } from "src/components/PopOver";
import { RequestTeamInfo } from "src/fragments/typings/RequestTeamInfo";
import { Typo } from "src/styling/primitives/typography";

export const AssignExpertButton: ForwardRefExoticComponent<{
  request: RequestListFragment;
  team: RequestTeamInfo;
  isMenuOpen?: boolean;
  enableOptionHotKeys?: boolean;
  showText?: boolean;
  margin?: string;
  condensed?: boolean;
  ref?: React.Ref<HTMLButtonElement>;
  onClickAway?(e: React.ChangeEvent): void;
  onClose?(): void;
  onAssign?(): void;
}> = React.forwardRef((props, ref) => {
  const { request, showText, condensed = false, onClickAway, isMenuOpen, enableOptionHotKeys, onClose } = props;
  const { handlers, optionsByTeam } = useContext(ActionsContext);
  const isRequestAssigned = !!request.assignee;
  const options = optionsByTeam.assignExpert?.(props.team) ?? [];

  return (
    <PopOver.Menu
      isOpen={isMenuOpen}
      enableOptionHotKeys={enableOptionHotKeys}
      onClose={onClose}
      onClickAway={onClickAway}
      options={options}
      selected={request.assignee?.id}
      onSelect={(assigneeId: string | null) => {
        handlers.assignExpert(request.id, assigneeId, request.assignee?.id ?? null);
      }}
      onRemove={() => {
        handlers.assignExpert(request.id, null, request.assignee?.id ?? null);
      }}
      labelForRemoveOption="Unassign"
      trigger={
        isRequestAssigned ? (
          <Pill._Button
            ref={ref}
            condensed={condensed}
            data-testid="request-assignee"
            data-intercom-target="Request assignee: Assigned"
            // Needed to display assignee avatar correctly in Safari
            css={[
              css`
                padding-top: 0;
                padding-bottom: 0;
                padding-left: 0;
              `,
              (condensed || !showText) &&
                css`
                  padding: 0;
                `
            ]}
          >
            <UserAvatar
              sizeM={!condensed}
              sizeS={condensed}
              userId={request.assignee?.id}
              user={request.assignee ?? undefined}
              hoverable={true}
            />
            {showText && (
              <Typo.Body
                ellipsis
                css={css`
                  margin-left: var(--space-1-rem);
                `}
              >
                <UserName user={request.assignee || undefined} />
              </Typo.Body>
            )}
          </Pill._Button>
        ) : (
          <Pill._Button
            ref={ref}
            condensed={condensed}
            data-testid="request-assignee"
            data-intercom-target="Request assignee: Unassigned"
            // Needed to display assignee avatar correctly in Safari
            css={css`
              padding-top: 0;
              padding-bottom: 0;
            `}
          >
            <Pill._Icon iconPath={mdiAccountPlus} />
            {showText && <span>Unassigned</span>}
          </Pill._Button>
        )
      }
    />
  );
});
