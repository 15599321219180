import gql from "graphql-tag";
import * as React from "react";
import { Query } from "react-apollo";
import { LoadingBar } from "src/components";
import { FormWrapper } from "src/components/Fields/FormWrappers";
import { Toast } from "src/portals/Toast";
import { TeamAddSubmission } from "./Submission";
import { GSettingsTeamAddGet } from "./typings/GSettingsTeamAddGet";

export const SETTINGS_TEAM_ADD_GET = gql`
  query GSettingsTeamAddGet($id: ID!) {
    userList2 {
      users {
        id
        name
        email
        joinTime
      }
    }
    team(id: $id) {
      id
      name
      slug
      privacy
    }
    currentUser {
      id
      email
      name
    }
  }
`;

export interface ITeamAddProps {
  /**
   * Team.id uuid
   */
  team_id: string;
  /**
   * path to redirect after succesful update
   */
  redirect: string;
  title?: string;
  subtitle?: string;
  noClose?: boolean;
  skippable?: boolean;
  skipText?: string;
  submitText?: string;
  bigButton?: boolean;
  preselectCurrentUser?: boolean;
}

/**
 * Container for editing team details
 * Queries team fields to edit
 */
export const TeamAddContainer: React.ComponentType<ITeamAddProps> = props => (
  <FormWrapper data-testid="add-team">
    <Query<GSettingsTeamAddGet, {}> query={SETTINGS_TEAM_ADD_GET} variables={{ id: props.team_id }}>
      {({ data, error, loading }) => {
        if (loading) {
          return <LoadingBar />;
        }
        if (error) {
          return <Toast message={error.message} kind="error" />;
        }
        if (data) {
          return (
            <TeamAddSubmission {...props} userList2={data.userList2} currentUser={data.currentUser} team={data.team} />
          );
        }
        return null;
      }}
    </Query>
  </FormWrapper>
);
