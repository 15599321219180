import { css } from "@emotion/core";
import { mdiInformation } from "@mdi/js";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { useMutation, useQuery } from "react-apollo";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { SETTINGS_TEAM_ADD_SEND } from "src/App/Settings/Teams/Add/Submission";
import {
  GSettingsTeamAddSend,
  GSettingsTeamAddSendVariables
} from "src/App/Settings/Teams/Add/typings/GSettingsTeamAddSend";
import { EXPERT_LIST_GET } from "src/App/Settings/Users/Overview";
import {
  CancelButton,
  Dialog,
  FormikFieldGroup,
  InfoBanner,
  Input,
  LoadingBar,
  MaterialIcon,
  Row,
  SubmitButton
} from "src/components";
import { FormikMultiSelect, MultiSelectValue } from "src/components/Fields/MultiSelect";
import { Typo } from "src/styling/primitives/typography";
import { isString } from "src/util";
import { RouteComponentProps } from "src/util/router";
import * as yup from "yup";
import { UserInviteFlowData } from "./typings/UserInviteFlowData";

const schema = yup.object().shape({
  users: yup
    .array(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().email(`Not a valid email address`),
        __isNew__: yup.boolean()
      })
    )
    .nullable(true)
    .required(`User's email address is a required field`),
  team: yup.string().required()
});

export type UserInviteProps = RouteComponentProps<{ onClose(): void }>;
export const USER_INVITE_FLOW_DATA = gql`
  query UserInviteFlowData {
    userList2(query: { expertsOnly: true }) {
      users {
        id
        name
        email
      }
    }
    teamList {
      id
      name
      userIds
    }
    currentUser {
      id
    }
  }
`;

export const UserInvite: React.FunctionComponent<UserInviteProps> = props => {
  const { data, loading } = useQuery<UserInviteFlowData>(USER_INVITE_FLOW_DATA, {
    fetchPolicy: "network-only"
  });
  const { emitSnack } = useSnack();

  const [inviteMembers, inviteMembersResponse] = useMutation<GSettingsTeamAddSend, GSettingsTeamAddSendVariables>(
    SETTINGS_TEAM_ADD_SEND,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: EXPERT_LIST_GET }]
    }
  );
  if (!data) return null;
  const ownTeams = data.teamList.filter(team => team.userIds.includes(data.currentUser?.id ?? ""));

  return (
    <>
      {loading && <LoadingBar />}
      <Dialog medium title="Invite users" onClose={props.onClose}>
        <Formik
          initialValues={{ users: [], team: "" }}
          onSubmit={payload => {
            return inviteMembers({
              variables: {
                params: {
                  emails: payload.users.map((e: MultiSelectValue) => e.value),
                  teamId: payload.team
                }
              },
              update(_cache, response) {
                if (response?.data?.teamAdd.success) {
                  emitSnack({
                    message: `${payload.users.length} user${payload.users.length > 1 ? "s" : ""} invited`,
                    type: "info"
                  });
                } else {
                  emitSnack({
                    message:
                      response.data?.teamAdd.message ??
                      `Error inviting ${payload.users.length} user${payload.users.length > 1 ? "s" : ""}`,
                    type: "mutationError"
                  });
                }
                props?.onClose && props.onClose();
              }
            });
          }}
          validationSchema={schema}
        >
          {form => {
            return (
              <Form>
                <FormikFieldGroup.Container legend="Invite via email">
                  <Field name="users">
                    {(formikFieldProps: FieldProps) => {
                      return (
                        <>
                          <div
                            css={css`
                              margin-bottom: var(--space-4-px);
                            `}
                          >
                            <FormikMultiSelect
                              {...formikFieldProps}
                              options={[]}
                              defaultValue={[]}
                              isClearable={false}
                              isCreatable={true}
                              showError={false}
                              noOptionsText="Enter an email address"
                            />
                            <FormikFieldGroup.Errors>
                              {/* If this is an array with multiple error objects, all their values are the same */}
                              <ErrorMessage name="users">
                                {(msg: string | Array<{ value: string }>) => (
                                  <div>{isString(msg) ? msg : msg[0].value}</div>
                                )}
                              </ErrorMessage>
                            </FormikFieldGroup.Errors>
                          </div>
                        </>
                      );
                    }}
                  </Field>
                </FormikFieldGroup.Container>
                <FormikFieldGroup.Container legend="Add to team">
                  <Field name="team">
                    {(formikFieldProps: FieldProps) => (
                      <div
                        css={css`
                          display: grid;
                          grid-template-columns: 50% 50%;
                          margin-bottom: var(--space-4-px);
                        `}
                      >
                        {ownTeams.map(team => {
                          const checked = form.values.team === team.id;
                          return (
                            <React.Fragment key={team.id}>
                              <label
                                css={css`
                                  justify-self: start;
                                  display: flex;
                                  justify-content: flex-start;
                                  align-items: center;
                                  cursor: pointer;
                                  max-width: 100%;
                                `}
                                onClick={() => formikFieldProps.form.setFieldValue("team", team.id)}
                                tabIndex={0}
                                onKeyDown={e => {
                                  if (e.key === "Enter" || e.key === " ") {
                                    formikFieldProps.form.setFieldValue("team", team.id);
                                  }
                                }}
                              >
                                <Input
                                  type="radio"
                                  value={team.id}
                                  checked={checked}
                                  readOnly
                                  css={css`
                                    flex: 0 0 auto;
                                    margin: 0 var(--space-2-rem) 0;
                                    min-height: 0;
                                  `}
                                />
                                <Typo.Body ellipsis>{team.name}</Typo.Body>
                              </label>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    )}
                  </Field>
                </FormikFieldGroup.Container>{" "}
                <InfoBanner
                  css={css`
                    margin: var(--space-6-px) 0;
                  `}
                >
                  <MaterialIcon size={1.125} path={mdiInformation} />
                  <span>
                    Once they’ve joined the organization, you can add them to more teams in the team settings.
                  </span>
                </InfoBanner>
                <Row
                  css={css`
                    justify-content: flex-end;
                  `}
                >
                  <CancelButton onClick={props.onClose}>Cancel</CancelButton>
                  <SubmitButton disabled={!form.isValid || form.isSubmitting || inviteMembersResponse.loading}>
                    Invite user
                  </SubmitButton>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};
