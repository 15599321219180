import { css } from "@emotion/core";
import styled from "@emotion/styled";
import * as React from "react";
import { FeatureFlags, useFeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import { MultiValueLabel, MultiValueRemove } from "src/App/Settings/Teams/Add/Form";
import { CountBadge } from "src/App/Sidebar/CountBadge";
import { getUserList } from "src/App/User/getUserList";
import { AsyncCreateableMultiSelect } from "src/components/Fields/Select";
import { fontSizes } from "src/styling/typography";
import * as yup from "yup";

const SelectWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-self: normal;

  & .select__control.select__control--is-focused {
    border: 0.0625rem solid var(--text-6);
  }

  & .select__menu {
    margin: 0;
  }

  & .select__menu-notice--no-options {
    text-align: left;
    color: var(--text-6);
  }

  & .select__menu-notice--no-options::after {
    content: "Enter a valid email address";
    color: var(--text-3);
    float: right;
  }

  & .select__option {
    cursor: pointer;
  }

  & .select__multi-value {
    border-radius: 0.0625rem;
  }

  & .select__multi-value__label {
    font-size: ${fontSizes.default}rem;
  }

  & .select__multi-value__remove {
    background: none;
  }
`;

const SelectedCountBadge = styled(CountBadge)`
  color: var(--blue-5);
  background: var(--lightBlue-2);
`;

function isValidNewOption(value: string) {
  return yup.string().required().email().isValidSync(value);
}

export type SelectUserValue =
  | {
      email: string;
      new: true;
    }
  | {
      id: string;
      name: string;
      email: string;
    };

interface SelectUserOption {
  label: string;
  value: SelectUserValue;
}

export function SelectCreateUsers(props: {
  /* filter these from available options */
  filterUsers: SelectUserValue[];
  selectedUsers: SelectUserValue[];
  setSelectedUsers(selectedUsers: SelectUserValue[]): void;
  onBlur?(): void;
  shouldHideSelectedUserCount?: boolean;
  shouldCloseMenuOnSelect?: boolean;
  "data-testid"?: string;
}) {
  const { hasFeatureFlags } = useFeatureFlags();
  const listInternalOnly = hasFeatureFlags(FeatureFlags.LISTINTERNALONLY);
  return (
    <SelectWrapper key={props.filterUsers.join()} data-testid={props["data-testid"]}>
      <AsyncCreateableMultiSelect<SelectUserOption>
        className="select-cc"
        closeMenuOnSelect={props.shouldCloseMenuOnSelect ?? false}
        onBlur={props.onBlur}
        autoFocus={true}
        components={{
          DropdownIndicator: null,
          MultiValueLabel,
          MultiValueRemove,
          ClearIndicator: () =>
            props.selectedUsers.length < 1 || props.shouldHideSelectedUserCount ? null : (
              <div
                css={[
                  css`
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  `
                ]}
              >
                <SelectedCountBadge>{props.selectedUsers.length}</SelectedCountBadge>
              </div>
            )
        }}
        placeholder="Enter an email address or select a user"
        noLabel={true}
        isMulti={true}
        value={props.selectedUsers.map(user => ({
          label: "name" in user ? `${user.name} (${user.email})` : user.email,
          value: user
        }))}
        defaultOptions={true}
        loadOptions={async (value: string) => {
          return getUserList(value, false, listInternalOnly).then(userList =>
            Promise.resolve(
              userList.users
                .filter(user => !props.filterUsers.find(f => f.email === user.email))
                .filter(user => !props.selectedUsers.find(f => f.email === user.email))
                .map(user => ({
                  label: user.name ? `${user.name} (${user.email})` : `${user.email}`,
                  value: {
                    name: user.name,
                    email: user.email,
                    id: user.id
                  }
                }))
            )
          );
        }}
        isValidNewOption={isValidNewOption}
        noOptionsMessage={({ inputValue }) => {
          return !inputValue ? null : inputValue;
        }}
        getNewOptionData={value => ({
          display: value,
          label: isValidNewOption(value)
            ? `Press Enter to add: ${value}`
            : "Enter an email address or select a user...",
          value: {
            email: value,
            new: true
          }
        })}
        createOptionPosition="first"
        onCreateOption={value => {
          props.setSelectedUsers(
            props.selectedUsers.concat([
              {
                email: value,
                new: true
              }
            ])
          );
        }}
        onChange={data => {
          if (Array.isArray(data)) {
            props.setSelectedUsers(data.map(d => d.value));
          } else if (data === null) {
            props.setSelectedUsers([]);
          }
        }}
      />
    </SelectWrapper>
  );
}
