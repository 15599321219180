import styled from "@emotion/styled";
import { mdiMagnify } from "@mdi/js";
import * as React from "react";
import { FC } from "react";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { MaterialIcon } from "src/components";
import { Modal } from "src/portals/Modal";
import { dialog } from "src/styling/effects";
import { handleSubmit } from "src/util";
import { navigate } from "src/util/router";

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  background: var(--white);
  width: 35rem;
  padding: 0 1rem;
  color: var(--text-6);
  ${dialog.shadow}
  border: 1px solid var(--border);
  border-radius: 0.25rem;

  & .icon {
    color: var(--text-2);
  }

  & ::placeholder {
    color: var(--text-2);
  }
`;

const Input = styled.input`
  flex: 1 0 auto;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 1rem 0.5rem;
  outline: none;
  border: none;
  color: var(--text-6);
`;

const SearchDialogContext = React.createContext<
  | {
      open(): void;
    }
  | undefined
>(undefined);

export function useSearchDialog() {
  const value = React.useContext(SearchDialogContext);
  if (!value) {
    throw new Error("useSearch must be used within SearchDialogProvider");
  }
  return value;
}

export const SearchDialogProvider: FC<{}> = props => {
  const [state, set] = React.useState(false);
  const { currentUser } = useCurrentUser();

  React.useEffect(() => {
    if (currentUser) {
      const handleKeydown = (e: KeyboardEvent) => {
        if (e.metaKey && e.key === "k") {
          e.preventDefault();
          set(!state);
        }
      };
      document.addEventListener("keydown", handleKeydown);
      return () => {
        document.removeEventListener("keydown", handleKeydown);
      };
    }
  }, [currentUser, state]);

  const searchParams = new URLSearchParams(window.location.search);
  const filters = new URLSearchParams(searchParams.get("filters") ?? "");
  const queryString = filters.get("fts") ?? "";

  return (
    <SearchDialogContext.Provider
      value={{
        open: () => set(true)
      }}
    >
      {props.children}
      <Modal isOpen={state} alignTop onDismiss={() => set(false)}>
        <form
          onSubmit={handleSubmit<{ query: string }>(data => {
            if (data.query) {
              const urlParams = new URLSearchParams();
              const searchQueryParams = new URLSearchParams();
              searchQueryParams.set("fts", data.query);
              urlParams.set("filters", searchQueryParams.toString());
              navigate(`/search?${urlParams.toString()}`);
              set(false);
            }
          })}
        >
          <InputWrapper>
            <MaterialIcon path={mdiMagnify} size={1.25} />
            <Input
              type="text"
              name="query"
              defaultValue={queryString}
              placeholder="Searching in requests, replies, internal notes, and approval messages"
              autoComplete="off"
              autoFocus
              onFocus={e => {
                const val = e.target.value;
                e.target.value = "";
                e.target.value = val;
              }}
            />
          </InputWrapper>
        </form>
      </Modal>
    </SearchDialogContext.Provider>
  );
};
