import { createChangeEmitter } from "change-emitter";
import { addWeeks, addYears } from "date-fns";
import gql from "graphql-tag";
import { trackNotifClicked, trackNotifPermission } from "src/util/analytics";
import { apolloClient } from "src/util/apollo/client";
import { PushNotificationsActivate, PushNotificationsActivateVariables } from "./typings/PushNotificationsActivate";
import {
  PushNotificationsDeactivate,
  PushNotificationsDeactivateVariables
} from "./typings/PushNotificationsDeactivate";

/**
 *  Get user permission for push notifications through browser
 * */
async function askActivatePushNotifications() {
  switch (Notification.permission) {
    case "granted":
    case "denied":
      return;
    case "default":
      const result = await askNotificationsPermission();
      trackNotifPermission(result);
      if (result !== "granted") {
        return;
      }
      break;
    default:
      throw new Error(`unhandled notification permission: ${Notification.permission}`);
  }
}

/** Wrapper fn as Safari 12 uses callback style (although we currently don't support Safari w/o PushManager) */
function askNotificationsPermission(): Promise<NotificationPermission> {
  return new Promise(function (resolve, reject) {
    const permissionResult = Notification.requestPermission(result => {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  });
}

const ACTIVATE_PUSH_NOTIFICATIONS = gql`
  mutation PushNotificationsActivate($subscription: PushSubscription!) {
    pushNotificationsActivate(subscription: $subscription) {
      code
      message
      success
    }
  }
`;

async function activatePushSubcription() {
  if (!("Notification" in window)) {
    throw new Error("browser does not support system notifications");
  } else if (!("serviceWorker" in navigator)) {
    throw new Error("browser does not support service workers");
  } else if (!("PushManager" in window)) {
    throw new Error("browser does not support web push");
  } else if (Notification.permission !== "granted") {
    throw new Error(`notification permission was '${Notification.permission}' not 'granted'`);
  }

  let sw = await navigator.serviceWorker.getRegistration("/");
  if (!sw) {
    sw = await navigator.serviceWorker.register("/notifications-service-worker.js");
  }

  await navigator.serviceWorker.ready;
  const sub = await sw.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY ?? "")
  });

  const keys = JSON.parse(JSON.stringify(sub)).keys;
  await apolloClient.mutate<PushNotificationsActivate, PushNotificationsActivateVariables>({
    mutation: ACTIVATE_PUSH_NOTIFICATIONS,
    variables: {
      subscription: {
        endpoint: sub.endpoint,
        p256dh: keys.p256dh,
        auth: keys.auth,
        expirationTime: sub.expirationTime
      }
    }
  });
}

const userPushEndpointKey = (userId: string) => `isDisabledUntil:${userId.substring(0, 4)}`;
async function refreshPushSubscription(userId: string) {
  if (!("serviceWorker" in navigator) || !("PushManager" in window)) return;

  const registration = await navigator.serviceWorker.getRegistration();
  if (!registration) return;

  const subscription = await registration.pushManager.getSubscription();
  if (!subscription) return;
  if (subscription.endpoint === localStorage.getItem(userPushEndpointKey(userId))) return;

  const keys = JSON.parse(JSON.stringify(subscription)).keys;
  await apolloClient.mutate<PushNotificationsActivate, PushNotificationsActivateVariables>({
    mutation: ACTIVATE_PUSH_NOTIFICATIONS,
    variables: {
      subscription: {
        endpoint: subscription.endpoint,
        p256dh: keys.p256dh,
        auth: keys.auth,
        expirationTime: subscription.expirationTime
      }
    }
  });
  localStorage.setItem(userPushEndpointKey(userId), subscription.endpoint);
}

const DEACTIVATE_PUSH_NOTIFICATIONS = gql`
  mutation PushNotificationsDeactivate($endpoint: String!) {
    pushNotificationsDeactivate(endpoint: $endpoint) {
      code
      message
      success
    }
  }
`;

/** Unsubscribe the browser from push notifications and delete the subscription from the backend.*/
async function deactivatePush() {
  if (!("serviceWorker" in navigator) || !("PushManager" in window)) {
    return;
  }

  const registration = await navigator.serviceWorker.getRegistration();
  if (!registration) {
    return;
  }

  const subscription = await registration.pushManager.getSubscription();
  if (!subscription) {
    return;
  }

  await subscription.unsubscribe();
  await apolloClient.mutate<PushNotificationsDeactivate, PushNotificationsDeactivateVariables>({
    mutation: DEACTIVATE_PUSH_NOTIFICATIONS,
    variables: {
      endpoint: subscription.endpoint
    }
  });
}

/**
 * urlBase64ToUint8Array
 *
 * @param base64String a public vapid key
 */
function urlBase64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function trackPushNotif() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.addEventListener("message", (event: { data: { message: string; url: string } }) => {
      if (event && event.data && event.data.message && event.data.message === "notificationclick") {
        trackNotifClicked(event.data.url);
      }
    });
  }
}

const userBrowserPushDisabledKey = (userId: string) => `isDisabledUntil:${userId.substring(0, 4)}`;

const globalPushChangeEmitter = createChangeEmitter<{ type: "pushSettingDidChange" }>();

export const browserPushSettings = (userId?: string) => {
  if (!userId) return;
  const isDisabledUntilString = localStorage.getItem(userBrowserPushDisabledKey(userId)) ?? null;
  const isDisabledUntil = isDisabledUntilString ? Date.parse(isDisabledUntilString) : null;
  const isDisabled = !isDisabledUntil ? false : Date.now() < isDisabledUntil;
  return {
    listen: globalPushChangeEmitter.listen,
    isDisabled,
    enable: async () => {
      if (!("Notification" in window)) return;
      await askActivatePushNotifications();
      if (Notification.permission === "granted") {
        await activatePushSubcription();
        globalPushChangeEmitter.emit({ type: "pushSettingDidChange" });
      }
      localStorage.removeItem(userBrowserPushDisabledKey(userId));
    },
    disable: async () => {
      await deactivatePush();
      localStorage.setItem(userBrowserPushDisabledKey(userId), addYears(Date.now(), 99).toJSON());
    },
    dismiss: () => {
      localStorage.setItem(userBrowserPushDisabledKey(userId), addYears(Date.now(), 99).toJSON());
      globalPushChangeEmitter.emit({ type: "pushSettingDidChange" });
    },
    snooze: () => {
      localStorage.setItem(userBrowserPushDisabledKey(userId), addWeeks(Date.now(), 1).toJSON());
      globalPushChangeEmitter.emit({ type: "pushSettingDidChange" });
    },
    onLogout: async () => {
      await deactivatePush();
    },
    onLogin: async () => {
      if (!("Notification" in window)) return;
      if (!isDisabled && Notification.permission === "granted") {
        await activatePushSubcription();
      }
    },
    onPageLoad: async () => {
      if (!("Notification" in window)) return;
      if (!isDisabled && Notification.permission === "granted") {
        await refreshPushSubscription(userId);
      }
    }
  };
};
