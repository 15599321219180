import { ErrorMessage, Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { useMutation } from "react-apollo";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { Dialog, FormikFieldGroup, FormikInput, LoadingBar, SubmitButton } from "src/components";
import { Modal } from "src/portals/Modal";
import { justify, row } from "src/styling/primitives";
import { reportDevError } from "src/util";
import * as yup from "yup";
import { INTEGRATIONS_LIST_GET } from "../Overview";
import { IntegrationAddPersonio, IntegrationAddPersonioVariables } from "./typings/IntegrationAddPersonio";

export const PERSONIO_ADD_INTEGRATION = gql`
  mutation IntegrationAddPersonio($config: IntegrationHRISConfig!) {
    integrationAddHRIS(config: $config) {
      code
      message
      success
    }
  }
`;

const validationSchema = yup.object().shape({
  clientId: yup.string().required("You must enter a Client ID to connect Personio"),
  clientSecret: yup.string().required("You must enter a Client Secret to connect Personio")
});

export const PersonioInstallModal = (props: {
  onDismiss(): void;
  onSuccess(): void;
  organizationId: string;
  isOpen: boolean;
}) => {
  const { emitSnack } = useSnack();

  const [configurePersonio, response] = useMutation<IntegrationAddPersonio, IntegrationAddPersonioVariables>(
    PERSONIO_ADD_INTEGRATION,
    {
      onCompleted: data => {
        if (data.integrationAddHRIS.success) {
          props.onSuccess();
        } else if (data.integrationAddHRIS.success === false) {
          emitSnack({
            type: "mutationError",
            message: data.integrationAddHRIS.message
          });
        }
      },
      refetchQueries: [{ query: INTEGRATIONS_LIST_GET }]
    }
  );
  return (
    <Modal isOpen={props.isOpen} onDismiss={props.onDismiss}>
      <Dialog title="Connect Personio" onClose={props.onDismiss} medium>
        {response.loading && <LoadingBar />}
        <Formik
          initialValues={{
            clientId: "",
            clientSecret: ""
          }}
          validationSchema={validationSchema}
          onSubmit={payload => {
            !!payload.clientId && !!payload.clientSecret
              ? configurePersonio({
                  variables: {
                    config: {
                      personio: {
                        credentials: `client_id: ${payload.clientId}\nclient_secret: ${payload.clientSecret}`
                      }
                    }
                  }
                })
              : reportDevError("Personio credentials invalid");
          }}
          render={form => (
            <Form>
              <FormikFieldGroup.Container legend="Client ID">
                <Field
                  autoFocus
                  name="clientId"
                  component={FormikInput}
                  placeholder="e.g. NjlkQWU1PDc1ODE3ODQ5ZTc#DFc4ZWQw"
                  hideErrorLabel
                />
                <FormikFieldGroup.Errors>
                  <ErrorMessage name="clientId" />
                </FormikFieldGroup.Errors>
              </FormikFieldGroup.Container>
              <FormikFieldGroup.Container legend="Client Secret">
                <Field name="clientSecret" component={FormikInput} hideErrorLabel type="password" />
                <FormikFieldGroup.Errors>
                  <ErrorMessage name="clientSecret" />
                </FormikFieldGroup.Errors>
              </FormikFieldGroup.Container>

              <div css={[row, justify.end]}>
                <SubmitButton disabled={response.loading || !form.isValid}>Submit</SubmitButton>
              </div>
            </Form>
          )}
        />
      </Dialog>
    </Modal>
  );
};
