import gql from "graphql-tag";
import { PROJECT_INFO_FRAGMENT } from "src/App/Projects/ProjectFragment";
import { CUSTOM_STATUS_FRAGMENT } from "src/fragments/CustomStatusFragment";
import { USER_INFO_FRAGMENT } from "src/fragments/UserInfoFragment";

export const REQUEST_LIST_FRAGMENT = gql`
  fragment RequestListFragment on Request {
    id
    title
    channel
    requestedAt
    updateTime
    dueAt
    priority
    category {
      id
      name
    }
    project {
      ...ProjectInfoFragment
    }
    requester {
      id
      name
      email
    }
    assignee {
      ...UserInfo
    }
    team {
      id
    }
    isUnread
    sla {
      minutesToFirstResponse
      minutesToResolution
    }
    slaStatus {
      slaId
      remainingTimeToFirstResponse {
        remainingTimeMinutes
        ticking
      }
      remainingTimeToResolution {
        remainingTimeMinutes
        ticking
      }
    }
    customStatus {
      ...CustomStatusFragment
    }
  }
  ${PROJECT_INFO_FRAGMENT}
  ${USER_INFO_FRAGMENT}
  ${CUSTOM_STATUS_FRAGMENT}
`;
