import { css } from "@emotion/core";
import { mdiPlus, mdiPaperclip } from "@mdi/js";
import { captureException } from "@sentry/browser";
import gql from "graphql-tag";
import * as React from "react";
import { useQuery } from "react-apollo";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { CurrentUser_currentUser_teams } from "src/App/Root/providers/typings/CurrentUser";
import {
  BaseLayout,
  Button,
  CleanUnderlineButton,
  DateTime,
  EmptyState,
  LoadingBar,
  MaterialIcon,
  Tooltip
} from "src/components";
import { PopOver } from "src/components/PopOver";
import { Table } from "src/components/Table";
import { Typo } from "src/styling/primitives/typography";
import { backMarkdownToText, fileSize } from "src/util/formatters";
import { navigate, useMatch } from "src/util/router";
import { CreateSavedReplyModal, DeleteSavedReplyModal, EditSavedReplyModal } from "./Actions";
import { SavedReplyList, SavedReplyListVariables } from "./typings/SavedReplyList";
import { useFeatureFlags, FeatureFlags } from "../Root/providers/FeatureFlagProvider";

export const SavedRepliesPage: React.FC = () => {
  const { currentUser } = useCurrentUser();
  const params = useMatch<{ team_slug: string }>("/settings/saved-replies/:team_slug/*");
  if (!currentUser) return <LoadingBar />;
  const teamSlug = params?.team_slug ?? currentUser.teams[0].slug;
  const selectedTeam = currentUser.teams.find(t => t.slug === teamSlug);
  if (!selectedTeam) {
    captureException(new Error("Invalid team slug"));
    return <div>Invalid team slug</div>;
  }
  return <SavedRepliesContainer selectedTeam={selectedTeam} teamList={currentUser.teams} />;
};

const SAVED_REPLIES = gql`
  query SavedReplyList($teamId: ID!) {
    savedReplyList(teamId: $teamId) {
      id
      title
      body
      createTime
      updateTime
      attachments {
        id
        displayName
        size
      }
    }
  }
`;

const SavedRepliesContainer: React.FC<{
  teamList: CurrentUser_currentUser_teams[];
  selectedTeam: CurrentUser_currentUser_teams;
}> = props => {
  const { hasFeatureFlags } = useFeatureFlags();
  const hasAttachmentsFeatureFlag = hasFeatureFlags(FeatureFlags.SAVED_REPLIES_ATTACHMENTS);
  const savedReplyListResponse = useQuery<SavedReplyList, SavedReplyListVariables>(SAVED_REPLIES, {
    variables: {
      teamId: props.selectedTeam.id
    }
  });
  const sortedSavedReplyList = !savedReplyListResponse.data
    ? null
    : [...savedReplyListResponse.data.savedReplyList].sort((a, b) => a.title.localeCompare(b.title));
  const actionParams = useMatch<{ reply_id: string; action: "edit" | "delete" }>(
    "/settings/saved-replies/:team_id/:action/:reply_id"
  );
  const selectedReplyId = actionParams?.reply_id ?? null;
  const selectedReply = savedReplyListResponse.data?.savedReplyList.find(r => r.id === selectedReplyId) ?? null;
  const showCreate = !!useMatch("/settings/saved-replies/:team_id/create");

  return (
    <BaseLayout
      isLoading={savedReplyListResponse.loading}
      headline="Saved replies"
      subline={
        <Typo.Body>
          Create, edit, and delete saved replies for the team
          <PopOver.Menu
            options={props.teamList.map(t => ({ id: t.slug, name: t.name }))}
            onSelect={(teamSlug: string) => {
              navigate(`/settings/saved-replies/${teamSlug}`, { replace: true });
            }}
            selected={props.selectedTeam.slug}
            trigger={
              <CleanUnderlineButton
                css={css`
                  margin-left: var(--space-1-rem);
                `}
              >
                {props.selectedTeam.name}
              </CleanUnderlineButton>
            }
          />
          . Use saved replies to write, personalize, and send answers to common questions while keeping your messages
          personal.
        </Typo.Body>
      }
      button={
        <Button
          variant="secondary"
          onClick={() => navigate(`/settings/saved-replies/${props.selectedTeam.slug}/create`)}
        >
          <MaterialIcon path={mdiPlus} size={1.125} margin="0 0.25rem 0 0" />
          Add saved reply
        </Button>
      }
    >
      <CreateSavedReplyModal
        isOpen={showCreate}
        team={props.selectedTeam}
        refetchQueries={[
          {
            query: SAVED_REPLIES,
            variables: savedReplyListResponse.variables
          }
        ]}
        hasAttachmentsFeatureFlag={hasAttachmentsFeatureFlag}
      />
      <EditSavedReplyModal
        isOpen={actionParams?.action === "edit"}
        team={props.selectedTeam}
        selectedReply={selectedReply}
        refetchQueries={[
          {
            query: SAVED_REPLIES,
            variables: savedReplyListResponse.variables
          }
        ]}
        hasAttachmentsFeatureFlag={hasAttachmentsFeatureFlag}
      />
      <DeleteSavedReplyModal
        isOpen={actionParams?.action === "delete"}
        team={props.selectedTeam}
        selectedReply={selectedReply}
        refetchQueries={[
          {
            query: SAVED_REPLIES,
            variables: savedReplyListResponse.variables
          }
        ]}
      />
      {sortedSavedReplyList?.length === 0 && (
        <EmptyState title="No saved replies yet" subtitle="Add some new saved replies to get started" />
      )}
      {!!sortedSavedReplyList?.length && (
        <Table hasClickableRows>
          <thead>
            <tr>
              <th>Title</th>
              <th>Content</th>
              {hasAttachmentsFeatureFlag && (
                <th
                  css={css`
                    text-align: center;
                  `}
                >
                  Attachments
                </th>
              )}
              <th
                css={css`
                  text-align: right;
                `}
              >
                Date created
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedSavedReplyList?.map(reply => {
              const body = backMarkdownToText(reply.body);
              return (
                <tr
                  key={reply.id}
                  onClick={() => navigate(`/settings/saved-replies/${props.selectedTeam.slug}/edit/${reply.id}`)}
                >
                  <td>{reply.title}</td>

                  <td title={body}>{body}</td>
                  {hasAttachmentsFeatureFlag && (
                    <td
                      css={css`
                        text-align: center;
                      `}
                    >
                      {reply.attachments.length === 0 && "--"}
                      {reply.attachments.length > 0 && (
                        <Tooltip
                          target={
                            <div
                              css={[
                                css`
                                  display: flex;
                                  align-items: center;
                                `
                              ]}
                            >
                              {reply.attachments.length} <MaterialIcon path={mdiPaperclip} size={0.875} />
                            </div>
                          }
                        >
                          {reply.attachments.map(attachment => (
                            <div>
                              {attachment.displayName} ({fileSize(attachment.size)})
                            </div>
                          ))}
                        </Tooltip>
                      )}
                    </td>
                  )}
                  <td
                    css={css`
                      text-align: right;
                    `}
                  >
                    <DateTime timestamp={reply.createTime} tooltip={true} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </BaseLayout>
  );
};
