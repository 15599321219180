import { css } from "@emotion/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AssignExpertButton } from "src/App/Requests/Actions/AssignExpertButton";
import { ChangeDueDatePicker } from "src/App/Requests/Actions/ChangeDueDatePicker";
import { ChangeProjectButton } from "src/App/Requests/Actions/ChangeProjectButton";
import { ActionsContext } from "src/App/Requests/Actions/Provider";
import { priorityLabels, RequestPriorityButton } from "src/App/Requests/Actions/RequestPriorityButton";
import { ActionEnum, actionMenuLabels, notificationMessage, requestDetailPath } from "src/App/Requests/ListView";
import { RequestUpdates } from "src/App/Requests/ListView/RequestCard";
import { SlaStatusBadge } from "src/App/Requests/ListView/SlaStatusBadge";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { UserName } from "src/App/User";
import { ConditionalTooltip, Hide } from "src/components";
import { Badge } from "src/components/Badges";
import { Pill } from "src/components/PillButton";
import { OptionMap, PopOver } from "src/components/PopOver";
import { ColorVariantsUnion, Status } from "src/components/StatusIndicator";
import { AbsoluteTooltip } from "src/components/Tooltip";
import { RequestPriority, RequestStatus, TeamPrivacy } from "src/globalTypes";
import { Toast } from "src/portals/Toast";
import { Card } from "src/styling/primitives/Card";
import { Typo } from "src/styling/primitives/typography";
import { formatDueDate, formatTitle } from "src/util/formatters";
import { Link } from "src/util/router";
import { useRequestList } from "./Provider";
import { RequestListItemProps } from "./RequestCard";

const cardStyles = css`
  width: 22.85vw;
  max-width: 25rem;
  min-width: 20rem;

  & > * + * {
    margin-top: var(--space-3-px);
  }
`;

export const KanbanRequestListItem: React.FC<RequestListItemProps> = props => {
  const { currentUser } = useCurrentUser();
  const [requestList] = useRequestList();
  const { handlers, options, optionsByTeam } = useContext(ActionsContext);
  const { emitSnack } = useSnack();

  const { request, requestUpdates, updateVisiblePills } = props;

  const pillRefs = {
    [ActionEnum.ASSIGNEE]: useRef<HTMLButtonElement>(null),
    [ActionEnum.CATEGORY]: useRef<HTMLButtonElement>(null),
    [ActionEnum.DUEDATE]: useRef<HTMLButtonElement>(null),
    [ActionEnum.PROJECT]: useRef<HTMLButtonElement>(null),
    [ActionEnum.PRIORITY]: useRef<HTMLButtonElement>(null)
  };
  const [lastVisiblePills, setLastVisiblePills] = useState<{ [pillId: string]: boolean }>({});
  const visiblePills = requestList.state.visiblePills[request.id];
  useEffect(() => {
    // diff list of visible pills and trigger a click if needed
    const visiblePillIds = visiblePills ? Object.keys(visiblePills).filter(pillId => visiblePills[pillId]) : [];
    const lastVisiblePillIds =
      lastVisiblePills && Object.keys(lastVisiblePills).filter(pillId => lastVisiblePills[pillId]);
    if (visiblePills && Object.keys(visiblePills).length && visiblePillIds.join("-") !== lastVisiblePillIds.join("-")) {
      // quick check if the selection changed
      const pillToTrigger =
        visiblePillIds &&
        !!visiblePillIds.length &&
        (visiblePillIds.find(pill => lastVisiblePillIds && !lastVisiblePillIds.includes(pill)) as ActionEnum);

      if (pillToTrigger && pillRefs[pillToTrigger] && pillRefs[pillToTrigger].current) {
        pillRefs[pillToTrigger].current?.click();
      }

      setLastVisiblePills(visiblePills);
    }
  }, [visiblePills, lastVisiblePills, setLastVisiblePills, pillRefs]);

  const removePillFromVisiblePills = (pillId: ActionEnum) => {
    visiblePills && !!visiblePills[pillId] && updateVisiblePills && updateVisiblePills(request.id, pillId);
  };

  if (!(request && currentUser)) return null;

  const requestTeam = requestList.response.data?.teamList.find(t => t.id === request.team.id);
  if (!requestTeam) {
    return <Toast kind="mutationError" message="Team is not defined" />;
  }
  const noAccess = requestTeam.privacy === TeamPrivacy.PRIVATE && !requestTeam.userIds.includes(currentUser?.id ?? "");

  const hideCategoryPill =
    !(visiblePills && visiblePills[ActionEnum.CATEGORY]) && !(request.category && request.category.id);
  const hideDueDatePill = !(visiblePills && visiblePills[ActionEnum.DUEDATE]) && !request.dueAt;
  const hideProjectPill = !(visiblePills && visiblePills[ActionEnum.PROJECT]) && !request.project;

  const hidePriorityPill =
    !(visiblePills && visiblePills[ActionEnum.PRIORITY]) && request.priority === RequestPriority.PRIORITY_UNSPECIFIED;

  const formattedDueDate = request.dueAt ? formatDueDate(request.dueAt) : "";
  const showActions = !noAccess && (hideCategoryPill || hideDueDatePill || hideProjectPill || hidePriorityPill);

  const isUrgent =
    (formattedDueDate === "today" || formattedDueDate.includes("ago") || formattedDueDate.includes("yesterday")) &&
    request &&
    request.customStatus.step !== RequestStatus.RESOLVED;
  const unassigned = request.assignee === null;
  const assignedToMe = !!(currentUser && request.assignee && currentUser.id === request.assignee.id);
  const unreadMessage = requestUpdates ? notificationMessage({ requestUpdates, assignedToMe, unassigned }) : null;
  const requestCurrentCustomStatus = request.customStatus;
  return (
    <ConditionalTooltip
      tooltipText={
        noAccess
          ? "You can't access this request because it's assigned to a private team you're not a member of."
          : undefined
      }
    >
      <Link
        to={!noAccess ? requestDetailPath(props.request.id) : ""}
        state={{
          requestListViewId: requestList.viewId
        }}
      >
        <Card sizeL hasInteractions={!noAccess} css={cardStyles}>
          <Typo.Body sizeL bold={!!props.requestUpdates?.unread} ellipsis title={formatTitle(request.title)}>
            {formatTitle(request.title)}
          </Typo.Body>
          <Typo.Body
            ellipsis
            light
            css={css`
              margin-top: 6px;
            `}
          >
            By&nbsp;
            <UserName user={request.requester} />
          </Typo.Body>
          {(noAccess || requestUpdates || (request.sla && request.slaStatus)) && (
            <div
              css={css`
                display: flex;
                & > * + * {
                  margin-left: var(--space-2-px);
                }
              `}
            >
              {noAccess && <Badge warning>no access</Badge>}
              {request.sla && request.slaStatus && <SlaStatusBadge sla={request.sla} slaStatus={request.slaStatus} />}
              {requestUpdates && (
                <div>
                  <RequestUpdates requestUpdates={requestUpdates} assignedToMe={assignedToMe} unassigned={unassigned} />
                </div>
              )}
            </div>
          )}{" "}
          <div
            css={css`
              display: flex;
            `}
          >
            <div
              css={css`
                display: flex;
                & > * + * {
                  margin-left: var(--space-2-px);
                }
              `}
            >
              <AbsoluteTooltip placement="top" content={<span>{`Status: ${request.customStatus?.name}`}</span>}>
                <PopOver.Menu
                  options={optionsByTeam.changeCustomStatus?.(requestTeam) ?? []}
                  selected={requestCurrentCustomStatus.id}
                  onSelect={(customStatusId: string) => handlers.changeCustomStatus(request.id, customStatusId)}
                  trigger={
                    <Status.Indicator
                      condensed
                      requestStatus={requestCurrentCustomStatus.step}
                      customColor={requestCurrentCustomStatus.color as ColorVariantsUnion}
                      data-intercom-target="Request custom status button"
                    />
                  }
                />
              </AbsoluteTooltip>
              <AbsoluteTooltip
                placement="top"
                content={<span>{`Assignee: ${request.assignee?.name || "Unassigned"}`}</span>}
              >
                <AssignExpertButton {...props} team={requestTeam} condensed={true} showText={false} />
              </AbsoluteTooltip>
              <Hide when={hidePriorityPill} style={hidePriorityPill ? { marginLeft: 0 } : {}}>
                <AbsoluteTooltip
                  placement="top"
                  content={<span>{`Priority: ${priorityLabels[request.priority]}`}</span>}
                >
                  <RequestPriorityButton
                    buttonRef={pillRefs[ActionEnum.PRIORITY]}
                    requestId={request.id}
                    priority={request.priority}
                    condensed={true}
                    showText={false}
                    onUpdate={() => {
                      removePillFromVisiblePills(ActionEnum.PRIORITY);
                    }}
                  />
                </AbsoluteTooltip>
              </Hide>
            </div>
            <div
              css={css`
                display: flex;
                flex: 1 0 auto;
                justify-content: flex-end;
                & > * + * {
                  margin-left: var(--space-2-px);
                }
              `}
            >
              <AbsoluteTooltip placement="top" content={<span>{`Team: ${requestTeam.name}`}</span>}>
                <PopOver.Menu
                  options={options.changeTeam ?? []}
                  selected={request.team.id}
                  onSelect={(teamId: string) => {
                    handlers.changeTeam(request.id, teamId, requestTeam);
                  }}
                  trigger={
                    <Pill.Team condensed data-testid="request-team" showText={false} teamName={requestTeam.name} />
                  }
                />
              </AbsoluteTooltip>
              <Hide when={hideCategoryPill} style={hideCategoryPill ? { marginLeft: 0 } : {}}>
                <AbsoluteTooltip placement="top" content={<span>{`Category: ${request.category?.name}`}</span>}>
                  <PopOver.Menu
                    options={optionsByTeam.assignCategory?.(requestTeam) ?? []}
                    selected={request.category?.id}
                    onSelect={(categoryId: string | null) =>
                      handlers.assignCategory(request.id, categoryId, request.category?.id ?? null, () => void 0)
                    }
                    onRemove={() =>
                      handlers.assignCategory(request.id, null, request.category?.id ?? null, () => void 0)
                    }
                    labelForRemoveOption="Remove category"
                    trigger={
                      <Pill.Category
                        condensed
                        data-testid="request-category"
                        categoryName={request.category?.name}
                        showText={false}
                        ref={pillRefs[ActionEnum.CATEGORY]}
                      />
                    }
                  />
                </AbsoluteTooltip>
              </Hide>
              <Hide when={hideProjectPill} style={hideProjectPill ? { marginLeft: 0 } : {}}>
                <AbsoluteTooltip placement="top" content={<span>{`Project: ${request.project?.title}`}</span>}>
                  <ChangeProjectButton
                    menuPlacement="bottom-start"
                    requestId={request.id}
                    onClickAway={e => {
                      e.stopPropagation();
                      removePillFromVisiblePills(ActionEnum.PROJECT);
                    }}
                    onAssign={() => {
                      removePillFromVisiblePills(ActionEnum.PROJECT);
                    }}
                    project={request.project}
                    trigger={
                      <Pill.Project
                        condensed
                        projectName={request.project?.title}
                        showText={false}
                        ref={pillRefs[ActionEnum.PROJECT]}
                      />
                    }
                  />
                </AbsoluteTooltip>
              </Hide>
              <Hide when={hideDueDatePill} style={hideDueDatePill ? { marginLeft: 0 } : {}}>
                <AbsoluteTooltip placement="top" content={<span>{`Due date: ${formattedDueDate}`}</span>}>
                  <ChangeDueDatePicker
                    button={
                      <Pill.DueDate
                        ref={pillRefs[ActionEnum.DUEDATE]}
                        showText={false}
                        condensed
                        dueDate={request.dueAt}
                        isUrgent={isUrgent}
                      />
                    }
                    dueAt={request.dueAt}
                    requestId={request.id}
                    onClickAway={e => {
                      e.stopPropagation();
                      removePillFromVisiblePills(ActionEnum.DUEDATE);
                    }}
                  />
                </AbsoluteTooltip>
              </Hide>
              {showActions && (
                <AbsoluteTooltip placement="top" content={<span>More actions</span>}>
                  <PopOver.Menu
                    options={[
                      {
                        headline: "Actions",
                        options: [
                          !(request.category && request.category.id) && {
                            id: ActionEnum.CATEGORY,
                            name: actionMenuLabels[ActionEnum.CATEGORY],
                            onClick: () => {
                              updateVisiblePills(request.id, ActionEnum.CATEGORY);
                            }
                          },
                          request.priority === RequestPriority.PRIORITY_UNSPECIFIED && {
                            id: ActionEnum.PRIORITY,
                            name: actionMenuLabels[ActionEnum.PRIORITY],
                            onClick: () => {
                              updateVisiblePills(request.id, ActionEnum.PRIORITY);
                            }
                          },
                          !request.dueAt && {
                            id: ActionEnum.DUEDATE,
                            name: actionMenuLabels[ActionEnum.DUEDATE],
                            onClick: () => {
                              updateVisiblePills(request.id, ActionEnum.DUEDATE);
                            }
                          },
                          !request.project && {
                            id: ActionEnum.PROJECT,
                            name: actionMenuLabels[ActionEnum.PROJECT],
                            onClick: () => {
                              updateVisiblePills(request.id, ActionEnum.PROJECT);
                            }
                          }
                        ].filter(Boolean) as OptionMap[]
                      }
                    ]}
                    trigger={<Pill.ContextMenu condensed />}
                  />
                </AbsoluteTooltip>
              )}
            </div>
          </div>
        </Card>
      </Link>
    </ConditionalTooltip>
  );
};
