import { css } from "@emotion/core";
import { mdiMagnify, mdiPlus } from "@mdi/js";
import { compareDesc, format } from "date-fns";
import gql from "graphql-tag";
import * as React from "react";
import { FC, useState } from "react";
import { useQuery } from "react-apollo";
import { createImageListFromAttachments, ImageViewer } from "src/App/Attachments/ImageViewer";
import { AttachmentItem } from "src/App/Attachments/Item";
import { RequestLink } from "src/App/Dashboard/components";
import { KBMarkdownView } from "src/App/KB/Detail/MarkdownView";
import { Pagination, setUrlPageNumber } from "src/App/Requests/ListView/Pagination";
import { UserAvatar } from "src/App/User";
import {
  Badge,
  BaseLayout,
  Button,
  CleanUnderlineButton,
  DateTime,
  Dialog,
  EmptyState,
  IconAndText,
  LoadingBar,
  MaterialIcon,
  Row,
  SimpleTooltip,
  SquareButton,
  Table
} from "src/components";
import { SearchDialog } from "src/components/SearchDialog";
import { ATTACHMENT_FRAGMENT } from "src/fragments/AttachmentFragment";
import { USER_INFO_FRAGMENT } from "src/fragments/UserInfoFragment";
import { Modal } from "src/portals/Modal";
import { HR, TextLink } from "src/styling/primitives";
import { Typo } from "src/styling/primitives/typography";
import zIndices from "src/styling/tokens/z-indices.json";
import { ReplyArrow } from "src/svg/icons/ReplyArrow";
import { Users } from "src/svg/icons/Users";
import { formatAsSemanticList } from "src/util";
import { trackConversationDetailOpened } from "src/util/analytics";
import { csx } from "src/util/csx";
import { navigate, useMatch, useSearchParams } from "src/util/router";
import { ConversationCreateModal } from "./Create";
import { pluralize } from "src/util/formatters";
import {
  OutreachConversationList,
  OutreachConversationListVariables,
  OutreachConversationList_conversationList_conversations
} from "./typings/OutreachConversationList";
import { OutreachFilter, useConversationListFilter } from "./Filter";

export enum ConversationStatuses {
  SENT = "SENT",
  REPLIED = "REPLIED"
}

const ConversationBadge: FC<{ status: ConversationStatuses }> = ({ status }) => {
  switch (status) {
    case ConversationStatuses.SENT:
    default:
      return <Badge neutral>{ConversationStatuses.SENT}</Badge>;
    case ConversationStatuses.REPLIED:
      return <Badge unread>{ConversationStatuses.REPLIED}</Badge>;
  }
};

const EmailHeaderRow = csx([
  css`
    display: flex;
    &:not(:first-child) {
      margin-top: var(--space-2-rem);
    }
    & > *:first-child {
      flex: 0 0 var(--space-8-rem);
    }
  `
]);

const OUTREACH_CONVERSATIONS_LIST = gql`
  query OutreachConversationList($query: ConversationListQuery!) {
    conversationList(query: $query) {
      conversations {
        id
        author {
          ...UserInfo
        }
        team {
          id
          name
          slug
          emailAddresses {
            from
          }
        }
        subject
        body
        toUsers {
          ...UserInfo
        }
        ccUsers {
          ...UserInfo
        }
        createTime
        attachments {
          ...AttachmentFragment
        }
        requests {
          id
          requestedAt
          requester {
            ...UserInfo
          }
        }
      }
      pageNumber
      count
    }
  }
  ${USER_INFO_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
`;

const formatNameAndEmail = (user: { name: string; email: string }) => {
  return !!user.name.length ? `${user.name} <${user.email}>` : user.email;
};

const conversationDate = css`
  & > th {
    text-align: left;
    padding: var(--space-5-rem) 0 var(--space-2-rem);
  }
`;

const OutreachRow: FC<{ conv: OutreachConversationList_conversationList_conversations }> = ({ conv }) => {
  const status = !!conv.requests.length ? ConversationStatuses.REPLIED : ConversationStatuses.SENT;

  return (
    <tr
      key={conv.id}
      data-testid="outreach-list-table-item"
      onClick={() => {
        trackConversationDetailOpened({ conversationId: conv.id });
        navigate(`/outreach/${conv.id}/preview${window.location.search}`);
      }}
    >
      <td>
        <SimpleTooltip alignment={"center"} label={conv.subject}>
          <Typo.Body ellipsis>{conv.subject}</Typo.Body>
        </SimpleTooltip>
      </td>
      <td
        css={[
          css`
            text-align: center;
          `
        ]}
      >
        <SimpleTooltip
          label={
            status === ConversationStatuses.REPLIED
              ? "One or more recipients replied to the conversation"
              : "Message was sent to all recipients"
          }
        >
          <ConversationBadge status={status} />
        </SimpleTooltip>
      </td>
      <td>
        <SimpleTooltip
          alignment={"center"}
          label={
            <>
              {conv.toUsers.length > 1 ? "Bcc:" : "To:"} <br />
              {formatAsSemanticList({
                array: conv.toUsers.map(user => formatNameAndEmail(user)),
                asBlock: true
              })}
              {!!conv.ccUsers.length && (
                <>
                  <br />
                  <br />
                  CCs: <br />
                  {formatAsSemanticList({
                    array: conv.ccUsers.map(user => formatNameAndEmail(user)),
                    asBlock: true
                  })}
                </>
              )}
            </>
          }
        >
          <Typo.Body light ellipsis>
            {conv.toUsers.length > 1 ? "Bcc:" : "To:"} {formatNameAndEmail(conv.toUsers[0])}
            {conv.toUsers.length - 1 > 0 ? ` and ${conv.toUsers.length - 1 + (conv.ccUsers.length ?? 0)} more` : ""}
          </Typo.Body>
        </SimpleTooltip>
      </td>
      <td>
        <DateTime timestamp={conv.createTime} tooltip={true} />
      </td>
      <td>
        <SimpleTooltip alignment={"center"} label={`Sent from team email: ${conv.team.emailAddresses.from}`}>
          <IconAndText icon={<Users />} label={conv.team.name} />
        </SimpleTooltip>
      </td>
      <td
        css={[
          css`
            text-align: right;
            > div {
              display: inline-block;
            }
          `
        ]}
      >
        <SimpleTooltip alignment={"center"} label={`Sent by: ${conv.author.name}`}>
          <UserAvatar sizeS user={conv.author} />
        </SimpleTooltip>
      </td>
    </tr>
  );
};

export const OutreachConversationsList: FC = () => {
  const shouldShowCreateModal = !!useMatch("/outreach/create");
  // Use the usual pattern even if there is only one modal type
  // Can be extended in the future for e.g. conversation deletion
  const params = useMatch<{ conversationId: string; modal: "preview" }>("/outreach/:conversationId/:modal");
  const [searchParams, setSearchParam] = useSearchParams();
  const page = searchParams.get("page");
  const ftsFilter = searchParams.get("fts");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const currentPageNumber = page ? parseInt(page) : 1;
  const pageSize = 100;
  const { data, loading, variables } = useQuery<OutreachConversationList, OutreachConversationListVariables>(
    OUTREACH_CONVERSATIONS_LIST,
    {
      variables: {
        query: {
          pageNumber: currentPageNumber - 1,
          pageSize,
          ...(ftsFilter ? { filters: { fts: { value: ftsFilter } } } : {})
        }
      }
    }
  );
  const onDismissSearch = () => navigate(`/outreach${window.location.search}`);
  const [filterConversations, isFiltered] = useConversationListFilter();
  const allConversations = data?.conversationList.conversations ?? [];
  const conversationsList = filterConversations(allConversations).sort((a, b) =>
    compareDesc(new Date(a.createTime), new Date(b.createTime))
  );
  const conversation = (data?.conversationList.conversations || []).find(conv => conv.id === params?.conversationId);
  const getConversationMonth = (createTime: string) => format(new Date(createTime), "MMMM yyyy");

  if (loading) return <LoadingBar />;

  return (
    <>
      <BaseLayout
        headline="Outreach"
        subline={
          <Row
            align="center"
            css={css`
              margin-top: 1rem;
            `}
          >
            {ftsFilter ? (
              <>
                Found {pluralize(conversationsList.length, "message", "messages")} for {`"`}
                <CleanUnderlineButton onClick={() => setIsSearchOpen(true)}>{ftsFilter}</CleanUnderlineButton>
                {`"`}
              </>
            ) : (
              <>{pluralize(data?.conversationList.count ?? 0, "open message", "open messages")}</>
            )}
            <OutreachFilter conversationlist={allConversations} />
          </Row>
        }
        button={
          <div>
            <Button
              variant="secondary"
              data-testid="outreach-start-message"
              onClick={() => navigate(`/outreach/create${window.location.search}`)}
            >
              <MaterialIcon
                path={mdiPlus}
                size={1.125}
                css={[
                  css`
                    margin: 0 0.25rem 0 0;
                  `
                ]}
              />
              Send new message
            </Button>
            <div
              css={css`
                display: flex;
                justify-content: flex-end;
                margin: 1rem 0 0;
              `}
            >
              <SquareButton variant="secondary" size="small" onClick={() => setIsSearchOpen(true)}>
                <MaterialIcon path={mdiMagnify} size={1.125} />
              </SquareButton>
            </div>
          </div>
        }
      >
        {isSearchOpen && (
          <SearchDialog
            onDismiss={() => setIsSearchOpen(false)}
            isOpen={isSearchOpen}
            placeholder="Searching in subject and body of all messages"
            queryString={ftsFilter ?? ""}
            onSubmit={query => {
              setSearchParam("fts", [query]);
              setIsSearchOpen(false);
            }}
          />
        )}
        <div data-testid="outreach-list">
          {conversationsList.length === 0 && (
            <EmptyState
              spacing="loose"
              variant="error"
              title={ftsFilter || isFiltered ? "Oh no" : "No messages yet"}
              subtitle={
                ftsFilter || isFiltered ? (
                  <div>
                    There are no results that match your search.
                    {ftsFilter && (
                      <>
                        <br />
                        <TextLink onClick={() => setIsSearchOpen(true)}>Search again</TextLink>
                      </>
                    )}
                  </div>
                ) : (
                  <div>
                    Go ahead and{" "}
                    <TextLink onClick={() => navigate(`/outreach/create${window.location.search}`)}>reach out</TextLink>
                    !
                  </div>
                )
              }
            />
          )}
          {conversationsList.length > 0 && (
            <>
              <Table
                hasClickableRows
                css={[
                  css`
                    td {
                      box-sizing: border-box;
                      height: var(--space-8-rem);
                      > * {
                        vertical-align: middle;
                      }
                    }
                  `
                ]}
              >
                <tbody>
                  {conversationsList.map((conv, i, conversations) => {
                    const isDifferentMonth =
                      i > 0 &&
                      getConversationMonth(conv.createTime) !== getConversationMonth(conversations[i - 1].createTime);
                    return (
                      <>
                        {(i === 0 || isDifferentMonth) && (
                          <tr css={conversationDate}>
                            <th
                              css={css`
                                &:hover {
                                  background-color: var(--white);
                                }
                              `}
                            >
                              {getConversationMonth(conv.createTime)}
                            </th>
                          </tr>
                        )}
                        <OutreachRow conv={conv} />
                      </>
                    );
                  })}
                </tbody>
              </Table>
              <Pagination
                pageNumber={currentPageNumber}
                totalPages={Math.ceil((data?.conversationList.count ?? 0) / (pageSize ?? Infinity))}
                shouldDisplayPagination={(data?.conversationList.count ?? 0) > pageSize}
                onPageNumberChange={setUrlPageNumber}
              />
            </>
          )}
        </div>
      </BaseLayout>
      <ConversationCreateModal
        isOpen={shouldShowCreateModal}
        onDismiss={onDismissSearch}
        refetchQueries={[
          {
            query: OUTREACH_CONVERSATIONS_LIST,
            variables
          }
        ]}
      />
      <Modal isOpen={!!conversation} onDismiss={onDismissSearch}>
        {!!conversation && (
          <Dialog
            data-testid="outreach-message-detail-modal"
            large
            title={
              <div
                css={[
                  css`
                    display: flex;
                    align-items: center;
                    & > * + * {
                      margin-left: var(--space-4-rem);
                    }
                  `
                ]}
              >
                <SimpleTooltip
                  zIndex={zIndices.highest.value}
                  alignment={"center"}
                  label={`Sent by: ${conversation.author.name}`}
                >
                  <UserAvatar sizeS user={conversation.author} />
                </SimpleTooltip>
                <span>{conversation.subject}</span>
              </div>
            }
            onClose={onDismissSearch}
          >
            <div
              css={[
                css`
                  & > * + * {
                    margin-top: var(--space-5-rem);
                  }
                `
              ]}
            >
              <div>
                <EmailHeaderRow>
                  <Typo.Body bold>From:</Typo.Body>
                  <Typo.Body>{`${conversation.team.name} <${conversation.team.emailAddresses.from}>`}</Typo.Body>
                </EmailHeaderRow>
                <EmailHeaderRow>
                  <Typo.Body bold>{conversation.toUsers.length > 1 ? "Bcc:" : "To:"}</Typo.Body>
                  <Typo.Body>
                    {" "}
                    {formatAsSemanticList({
                      array: conversation.toUsers.map(user => formatNameAndEmail(user))
                    })}
                  </Typo.Body>
                </EmailHeaderRow>
                {!!conversation.ccUsers.length && (
                  <EmailHeaderRow>
                    <Typo.Body bold>Cc:</Typo.Body>
                    <Typo.Body>
                      {" "}
                      {formatAsSemanticList({
                        array: conversation.ccUsers.map(user => formatNameAndEmail(user))
                      })}
                    </Typo.Body>
                  </EmailHeaderRow>
                )}
                <EmailHeaderRow>
                  <Typo.Body bold>Sent:</Typo.Body>
                  <Typo.Body>
                    {format(new Date(conversation.createTime), "MMMM do, yyyy")} at{" "}
                    {format(new Date(conversation.createTime), "H:ss")}
                  </Typo.Body>
                </EmailHeaderRow>
              </div>
              <HR />
              <Typo.Body
                css={[
                  css`
                    white-space: pre-wrap;
                  `
                ]}
              >
                <KBMarkdownView md={conversation.body} />
              </Typo.Body>
              {!!conversation.requests.length && (
                <div data-testid="outreach-message-detail-replies">
                  <IconAndText
                    icon={<ReplyArrow />}
                    label={
                      <>
                        {conversation.requests.length > 1
                          ? `${conversation.requests.length} recipients replied: `
                          : "One recipient replied: "}
                        {conversation.requests.map((r, idx) => (
                          <>
                            <RequestLink
                              id={r.id}
                              title={`${r.requester.name ?? r.requester.email} - ${format(
                                new Date(r.requestedAt),
                                "MMMM do"
                              )}`}
                              noMaxWidth
                            />
                            {idx < conversation.requests.length - 1 && ", "}
                          </>
                        ))}
                      </>
                    }
                  />
                </div>
              )}
              {!!conversation.attachments?.length && (
                <div>
                  <ImageViewer imagesList={createImageListFromAttachments(conversation.attachments, null)}>
                    {dispatch => (
                      <>
                        <Typo.Body bold>Attachments:</Typo.Body>
                        {conversation.attachments.map(attachment => (
                          <AttachmentItem
                            key={attachment.id}
                            attachment={attachment}
                            imageViewerDispatch={dispatch}
                            authToken={null}
                          />
                        ))}
                      </>
                    )}
                  </ImageViewer>
                </div>
              )}
            </div>
          </Dialog>
        )}
      </Modal>
    </>
  );
};
