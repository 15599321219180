import { captureException } from "@sentry/browser";
import "formdata-polyfill";
import * as React from "react";
import { ComponentProps, JSXElementConstructor, ReactNode } from "react";
import { apolloEmitter } from "./apollo/emitter";

export function handleSubmit<T extends { [key: string]: FormDataEntryValue }>(
  handler: (data: T) => void
): (event: React.FormEvent<HTMLFormElement>) => void {
  return event => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const data: { [key: string]: FormDataEntryValue } = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    handler(data as T);
  };
}

/** return typed keys array - for gotchas see: https://stackoverflow.com/a/55012175/3056284 */
export function keys<O>(o: O) {
  return Object.keys(o) as (keyof O)[];
}

// authTokenContext returns an apollo query context including the provided auth
// token. If no token is provided, an undefined context is returned.
export function authTokenContext(authToken?: string | null | undefined) {
  if (!authToken) {
    return undefined;
  }

  return {
    headers: {
      "X-AuthToken": authToken
    }
  };
}

/**
 * Return url search param
 */
export function searchParam(param: string) {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(param);
}

/**
 * Checking for string type helper (mainly for PopOverMenu)
 */
export function isString(val: ReactNode): val is string {
  return typeof val === "string";
}

/**
 * Formats and array of strings as a comma separated List
 */
export function formatAsSemanticList(args: { array: Array<string>; asBlock?: boolean }): Array<string> {
  if (args.asBlock) {
    return args.array.map((el, idx) => `${el}${idx + 1 !== args.array.length ? `\n` : ""}`);
  }
  return args.array.map((el, idx) => `${el}${idx + 1 !== args.array.length ? ", " : ""}`);
}

// from: https://github.com/typescript-cheatsheets/react-typescript-cheatsheet/blob/master/docs/advanced/guides/extract-props.md
// infers component props
// e.g. type ButtonProps = ApparentComponentProps<typeof Button>;
export type ApparentComponentProps<
  C extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>
> = C extends JSXElementConstructor<infer P> ? JSX.LibraryManagedAttributes<C, P> : ComponentProps<C>;

/** Catch Firefox bug https://stackoverflow.com/questions/18877643/error-in-local-storage-ns-error-file-corrupted-firefox */
export const localStorageSetItemSafe = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    if (e.name === "NS_ERROR_FILE_CORRUPTED") {
      captureException(e);
      apolloEmitter.emit({
        type: "mutationError",
        message:
          "Your browser is returning a NS_ERROR_FILE_CORRUPTED error. If you have any trouble viewing the page, please try loading the original link from a different browser or contact support"
      });
    }
  }
};

class DeveloperError extends Error {
  errorData: object | undefined;
  constructor(message?: string, errorData?: object) {
    super(message);
    this.name = "DeveloperError";
    this.errorData = errorData;
  }
}

/**
 * Useful for reporting unexpected runtime errors & conditions
 * which might escape compiler and should not crash app
 */
export const reportDevError = (message: string, errorData?: object) => {
  if (process.env.NODE_ENV === "production") {
    captureException(new DeveloperError(message, errorData));
  } else {
    throw new DeveloperError(message, errorData);
  }
};

/**
 * Helper function for filtering arrays and asserting it's elements as non-null
 * Necessary because typescript does not provide type guarantee after filering
 **/
export const filterNullsFromArray = <E extends object>(array: (E | null)[]): E[] => {
  const nonNullArray: E[] = array
    .filter(e => e !== null)
    .map(e => {
      if (e === null) throw new Error("e cannot be null here, since we just filtered all nulls");
      return e;
    });
  return nonNullArray;
};

// safely open new tab
export const openNewTab = (url: string) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

/**
 * Generate csv download
 * @param filename
 * @param csvString
 * @param tracking - required since not possible on backend
 */
export function generateDownload(filename: string, csvString: string): void {
  const a = document.createElement("a");
  a.id = "download";
  a.download = filename;
  a.href = URL.createObjectURL(new Blob([csvString], { type: "text/csv;charset=utf-8;" }));
  a.click();
}
