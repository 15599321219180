type email = string;
export interface IInputState {
  inputKey: number;
  inputType: "COMMENTS" | "NOTES" | "APPROVALS" | "FORMS";
  mentionUserIds: email[];
  externals: email[];
  externalsIncludePreviousComments: boolean;
  text: string;
}

export type TInputAction =
  | {
      type: "SET_INPUT_TYPE";
      inputType: IInputState["inputType"];
    }
  | {
      type: "SET_INPUT_TEXT";
      text: IInputState["text"];
    }
  | {
      type: "SET_MENTION_USER_IDS";
      mentionUserIds: IInputState["mentionUserIds"];
    }
  | {
      type: "SET_EXTERNALS";
      externals: email[];
    }
  | {
      type: "SET_EXTERNALS_INCLUDE_PREVIOUS_COMMENTS";
      externalsIncludePreviousComments: boolean;
    }
  | {
      type: "CLEAR_INPUT";
    };

export const initialInputState = (): IInputState => ({
  inputKey: Date.now(),
  inputType: "COMMENTS",
  mentionUserIds: [],
  externals: [],
  externalsIncludePreviousComments: false,
  text: ""
});

export function inputReducer(state: IInputState, action: TInputAction) {
  switch (action.type) {
    case "SET_INPUT_TYPE":
      return {
        ...state,
        inputType: action.inputType
      };
    case "SET_INPUT_TEXT":
      return {
        ...state,
        text: action.text
      };
    case "SET_MENTION_USER_IDS":
      return {
        ...state,
        mentionUserIds: action.mentionUserIds
      };
    case "SET_EXTERNALS":
      return {
        ...state,
        externals: action.externals
      };
    case "SET_EXTERNALS_INCLUDE_PREVIOUS_COMMENTS":
      return {
        ...state,
        externalsIncludePreviousComments: action.externalsIncludePreviousComments
      };
    case "CLEAR_INPUT":
      return initialInputState();
    default:
      return state;
  }
}
