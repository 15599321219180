import { css } from "@emotion/core";
import { mdiInformation, mdiPlus } from "@mdi/js";
import gql from "graphql-tag";
import * as React from "react";
import { useQuery } from "react-apollo";
import { AttachmentItemWrapper } from "src/App/Attachments/Item";
import { BestPracticeSharing } from "src/App/BestPractices/BestPracticeSharing";
import {
  documentCreateRoute,
  documentDeleteRoute,
  documentEditRoute,
  documentPreviewRoute,
  documentsOverviewRoute,
  DOCUMENTS_LIST_GET
} from "src/App/KB";
import { DocumentDeleteContainer } from "src/App/KB/CreateUpdate/Container";
import { DocumentCreateUpdateModal } from "src/App/KB/CreateUpdate/Modal";
import { KBMarkdownView } from "src/App/KB/Detail/MarkdownView";
import { KBDocsTable } from "src/App/KB/Docs";
import { AddModal } from "src/App/KB/ExternalSourceDocument/Add";
import { LogTable, useSuggestionsGroupedByQueryId } from "src/App/KB/Log";
import { Playground } from "src/App/KB/Playground";
import { GDocumentsListGet, GDocumentsListGet_documentList } from "src/App/KB/typings/GDocumentsListGet";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { FeatureFlags, useFeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import { routeData } from "src/App/Root/RouteData";
import {
  BaseLayout,
  Button,
  ButtonBar,
  Dialog,
  InfoBanner,
  MaterialIcon,
  TypeformModal,
  UnderlineButton
} from "src/components";
import { AttachmentList } from "src/components/Attachments";
import { OptionMap, PopOver } from "src/components/PopOver";
import { Tabs } from "src/components/Tabs";
import { DocumentSource, ExternalDocumentSourceKind } from "src/globalTypes";
import { Modal } from "src/portals/Modal";
import { Toast } from "src/portals/Toast";
import { Typo } from "src/styling/primitives/typography";
import { trackBestPracticeSharingIntent } from "src/util/analytics";
import { Link, navigate, useMatch } from "src/util/router";
import { SelectKBWorkflow } from "./CreateUpdate/Form";
import { ExternalDocumentLink } from "./ExternalSourceDocument";
import { Targeting } from "./Targeting";
import { Integrations, Integrations_externalDocumentSources } from "./typings/Integrations";

type CurrentTab = "articles" | "log" | "playground";

const TopBar: React.FC<{
  currentTab: CurrentTab;
  logLength: number;
  showPlayground: boolean;
}> = props => {
  return (
    <div>
      <div
        css={css`
          display: flex;
          padding-bottom: var(--space-4-rem);

          & > * + * {
            margin-left: var(--space-4-rem);
          }
        `}
      >
        <Link
          to={`/${routeData.knowledge.pathname}`}
          css={css`
            display: inline-flex;
            text-decoration: none;
          `}
        >
          <UnderlineButton selected={props.currentTab === "articles"}>Articles</UnderlineButton>
        </Link>
        <Link
          to={`/${routeData.knowledge.pathname}/log`}
          css={css`
            display: inline-flex;
            text-decoration: none;
          `}
        >
          <UnderlineButton selected={props.currentTab === "log"}>Auto-Answer Log</UnderlineButton>
        </Link>
        {props.showPlayground && (
          <Link
            to="/knowledge/playground"
            css={css`
              display: inline-flex;
              text-decoration: none;
            `}
          >
            <UnderlineButton selected={props.currentTab === "playground"}>Auto-Answer Playground</UnderlineButton>
          </Link>
        )}
      </div>
      {props.currentTab === "articles" && (
        <Typo.Body>
          Add your policies or answers to questions that are asked regularly or import existing pages from Notion or
          Confluence. Back will then suggest them automatically when employees ask questions on Slack, MS Teams or
          Google Chat.
        </Typo.Body>
      )}
      {props.currentTab === "log" && (
        <Typo.Body>
          {props.logLength === 1 && (
            <>
              In the last 30 days, we suggested answers to <strong>1 question</strong>.{" "}
            </>
          )}
          {props.logLength > 1 && (
            <>
              In the last 30 days, we suggested answers to <strong>{props.logLength} questions</strong>.{" "}
            </>
          )}
          When you see new repeating questions that could be answered automatically, we recommend creating new knowledge
          answers matching those questions. This will enable our bot to suggest them automatically.
        </Typo.Body>
      )}
      {props.currentTab === "playground" && (
        <Typo.Body>Ask a question to see which answers would be automatically suggested by Back and why</Typo.Body>
      )}
    </div>
  );
};

function findExternalDocSourceWithKind(
  sources: Integrations_externalDocumentSources[],
  kind: ExternalDocumentSourceKind
): Integrations_externalDocumentSources | undefined {
  return sources.find(s => s.kind === kind);
}

/**
 * Section that contains kb docs, log, playground, and crud modals
 */
export const KBSection: React.FC<{}> = () => {
  const params = useMatch<{ documentId: string; modal: "edit" | "delete" | "preview" | "share" | "confluence" }>(
    `/${routeData.knowledge.pathname}/:documentId/:modal`
  );

  const integrations = useQuery<Integrations>(gql`
    query Integrations {
      integrationHRIS {
        kind
      }
      externalDocumentSources {
        id
        kind
      }
    }
  `);

  const hasExternalDocsIntegration = !!integrations.data?.externalDocumentSources.length;
  const { hasFeatureFlags } = useFeatureFlags();
  const isKBTesterEnabled = hasFeatureFlags(FeatureFlags.KBTESTER);

  const shouldShowCreateModal = !!useMatch("/knowledge/create");
  const shouldShowAddConfluence = !!useMatch(
    `/${routeData.knowledge.pathname}/${routeData.knowledge.subpaths?.addConfluence.pathname}`
  );
  const shouldShowAddNotion = !!useMatch(
    `/${routeData.knowledge.pathname}/${routeData.knowledge.subpaths?.addNotion.pathname}`
  );
  const shouldShowAddSharepoint = !!useMatch(
    `/${routeData.knowledge.pathname}/${routeData.knowledge.subpaths?.addSharepoint.pathname}`
  );

  const shouldShowLogs = !!useMatch("/knowledge/log/*");
  const shouldShowPlayground = !!useMatch("/knowledge/playground") && isKBTesterEnabled;
  const currentTab: CurrentTab = shouldShowLogs ? "log" : shouldShowPlayground ? "playground" : "articles";

  const documentListResponse = useQuery<GDocumentsListGet>(DOCUMENTS_LIST_GET);
  const { currentUser } = useCurrentUser();
  const document = (documentListResponse.data?.documentList || []).find(
    doc => doc.id === decodeURIComponent(params?.documentId ?? "")
  );

  const { groupedSuggestions, groupedSuggestionsIsLoading } = useSuggestionsGroupedByQueryId();

  const hasIntegrationHRIS = !!integrations.data?.integrationHRIS?.kind;

  const isCurrentTabLoading =
    (currentTab === "articles" && documentListResponse.loading) ||
    (currentTab === "log" && groupedSuggestionsIsLoading) ||
    (currentTab === "playground" && false); /* playground doesn't need to load */

  // For now we only assume a single external document source of each kind,
  // it's therefore fine to use Array.find() here.
  const confluenceExternalDocSource = findExternalDocSourceWithKind(
    integrations.data?.externalDocumentSources ?? [],
    ExternalDocumentSourceKind.EXTERNAL_SOURCE_KIND_CONFLUENCE
  );
  const notionExternalDocSource = findExternalDocSourceWithKind(
    integrations.data?.externalDocumentSources ?? [],
    ExternalDocumentSourceKind.EXTERNAL_SOURCE_KIND_NOTION
  );
  const sharepointExternalDocSource = findExternalDocSourceWithKind(
    integrations.data?.externalDocumentSources ?? [],
    ExternalDocumentSourceKind.EXTERNAL_SOURCE_KIND_SHAREPOINT
  );

  const externalDocSourceOptions: Array<OptionMap | undefined> = [
    notionExternalDocSource && {
      id: "notion",
      name: "Add from Notion",
      intercomId: "add-from-notion",
      onClick: () => navigate(`/${routeData.knowledge.pathname}/${routeData.knowledge.subpaths?.addNotion.pathname}`)
    },
    confluenceExternalDocSource && {
      id: "confluence",
      name: "Add from Confluence",
      intercomId: "add-from-confluence",
      onClick: () =>
        navigate(`/${routeData.knowledge.pathname}/${routeData.knowledge.subpaths?.addConfluence.pathname}`)
    },
    sharepointExternalDocSource && {
      id: "sharepoint",
      name: "Add from Sharepoint",
      intercomId: "add-from-sharepoint",
      onClick: () =>
        navigate(`/${routeData.knowledge.pathname}/${routeData.knowledge.subpaths?.addSharepoint.pathname}`)
    }
  ];
  const filteredExternalDocSourceOptions = externalDocSourceOptions.filter(
    (e: OptionMap | undefined): e is OptionMap => !!e
  );

  return (
    <BaseLayout
      headline="Knowledge"
      subline={
        <TopBar
          currentTab={currentTab}
          logLength={groupedSuggestions?.length ?? -1}
          showPlayground={isKBTesterEnabled}
        />
      }
      button={
        <>
          {hasFeatureFlags(FeatureFlags.EXTERNALDOCS) &&
            hasExternalDocsIntegration &&
            externalDocSourceOptions &&
            externalDocSourceOptions.length > 0 && (
              <PopOver.Menu
                trigger={
                  <Button variant="primary" data-intercom-target="knowledge-add-answer-button">
                    <span>Add from...</span>
                  </Button>
                }
                options={filteredExternalDocSourceOptions}
              />
            )}
          <Button
            variant="secondary"
            onClick={() => navigate(documentCreateRoute)}
            data-intercom-target="knowledge-add-answer-button"
          >
            <MaterialIcon path={mdiPlus} size={1.125} margin="0 0.25rem 0 0" />
            <span>Add answer</span>
          </Button>
          {confluenceExternalDocSource && (
            <AddModal
              isOpen={shouldShowAddConfluence}
              onDismiss={() => navigate(documentsOverviewRoute)}
              source={confluenceExternalDocSource}
            />
          )}
          {notionExternalDocSource && (
            <AddModal
              isOpen={shouldShowAddNotion}
              onDismiss={() => navigate(documentsOverviewRoute)}
              source={notionExternalDocSource}
            />
          )}
          {sharepointExternalDocSource && (
            <AddModal
              isOpen={shouldShowAddSharepoint}
              onDismiss={() => navigate(documentsOverviewRoute)}
              source={sharepointExternalDocSource}
            />
          )}
        </>
      }
      isLoading={isCurrentTabLoading}
    >
      <>
        {documentListResponse.error && <Toast kind="error" message={documentListResponse.error.message} />}
        {currentTab === "articles" && documentListResponse.data && (
          <KBDocsTable documentList={documentListResponse.data.documentList} />
        )}
        {currentTab === "log" && <LogTable groupedSuggestions={groupedSuggestions} />}
        {currentTab === "playground" && <Playground />}
        <Modal
          isOpen={params?.modal === "preview"}
          onDismiss={() => {
            navigate(documentsOverviewRoute);
          }}
        >
          {document && (
            <Dialog
              large
              title={document.title || "Untitled document"}
              onClose={() => {
                navigate(documentsOverviewRoute);
              }}
            >
              <div
                css={[
                  css`
                    & > * + * {
                      margin-top: var(--space-6-rem);
                    }
                  `
                ]}
              >
                <Tabs
                  transitionHeight
                  items={[
                    {
                      headline: "Content",
                      content: (
                        <Typo.Body
                          css={[
                            css`
                              // limit the modal extend to maximum available height
                              max-height: calc(100vh - 434px);
                              overflow: auto;
                            `
                          ]}
                        >
                          {document.source === DocumentSource.internal && <KBMarkdownView md={document.content} />}
                          {document.url && (
                            <ExternalDocumentLink
                              doc={
                                document as GDocumentsListGet_documentList & {
                                  url: string;
                                } /* TS doesn't infer that 🤷 */
                              }
                            />
                          )}
                        </Typo.Body>
                      )
                    },
                    {
                      shouldNotDisplay: !!document.external,
                      headline: "Attachments",
                      content: document.attachments.length ? (
                        <AttachmentList attachments={document.attachments} />
                      ) : (
                        <AttachmentItemWrapper>
                          <Typo.Body
                            css={css`
                              text-align: center;
                            `}
                          >
                            There are currently no attachments
                          </Typo.Body>
                        </AttachmentItemWrapper>
                      )
                    },
                    {
                      headline: "Targeting",
                      content: (
                        <div
                          css={[
                            css`
                              & > * + * {
                                margin-top: var(--space-2-rem);
                              }
                            `
                          ]}
                        >
                          <div>
                            {!hasIntegrationHRIS && (
                              <InfoBanner
                                css={css`
                                  margin-bottom: var(--space-3-rem);
                                `}
                              >
                                <MaterialIcon path={mdiInformation} size={1.125} />
                                <Typo.Body>
                                  You need to connect an HR system on the Integrations page in order to be able to set
                                  up targeting for answers.
                                </Typo.Body>
                              </InfoBanner>
                            )}
                            <Targeting hrisFilter={document?.hrisFilter ?? undefined} />
                          </div>
                        </div>
                      )
                    },
                    {
                      shouldNotDisplay: !hasFeatureFlags(FeatureFlags.WORKFLOWS),
                      headline: "Workflows",
                      dataIntercomTarget: "workflows",
                      content: <SelectKBWorkflow mode="view" value={document.workflowId ?? null} />
                    }
                  ]}
                />
                {!document.external && (
                  <BestPracticeSharing
                    onClick={() => {
                      trackBestPracticeSharingIntent({ document_id: document.id });
                      navigate(`/${routeData.knowledge.pathname}/${document.id}/share`);
                    }}
                  />
                )}
                <ButtonBar>
                  <Button
                    data-testid="team-settings-item"
                    size="large"
                    onClick={() => navigate(documentDeleteRoute(document.id))}
                  >
                    Remove
                  </Button>
                  <Button
                    data-testid="team-settings-item"
                    data-intercom-target="kb-edit-answer"
                    variant="secondary"
                    size="large"
                    onClick={() => navigate(documentEditRoute(document.id))}
                  >
                    Edit answer
                  </Button>
                </ButtonBar>
              </div>
            </Dialog>
          )}
        </Modal>
        <DocumentCreateUpdateModal
          isOpen={params?.modal === "edit" || shouldShowCreateModal}
          onDismiss={() => navigate(documentsOverviewRoute)}
          onSuccess={() => navigate(documentsOverviewRoute)}
          onShareClick={() => {
            document && trackBestPracticeSharingIntent({ document_id: document.id });
            document && navigate(`/${routeData.knowledge.pathname}/${document.id}/share`);
          }}
          type={shouldShowCreateModal ? "create" : "edit"}
          document={document}
        />
        {document && (
          <DocumentDeleteContainer
            document={document}
            isOpen={params?.modal === "delete"}
            onCancel={() => navigate(documentPreviewRoute(document.id))}
            onFinish={() => navigate(documentsOverviewRoute)}
          />
        )}
        <TypeformModal
          isShown={params?.modal === "share"}
          onClose={() => navigate(documentsOverviewRoute)}
          title={`Share the policy "${document?.title}"`}
          hiddenFields={{
            type: "policy",
            organization_name: currentUser?.organization.name ?? "",
            document_id: document?.id ?? "",
            user_id: currentUser?.id ?? ""
          }}
          formId="l99KV5ff"
        />
      </>
    </BaseLayout>
  );
};
