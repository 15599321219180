import { routeData } from "src/App/Root/RouteData";
import { GRequestListUpdated_requestListUpdated } from "./typings/GRequestListUpdated";

/**
 * Determine the notification text to display
 * Based on the request updates object
 */
export function notificationMessage(args: {
  requestUpdates: GRequestListUpdated_requestListUpdated;
  assignedToMe: boolean;
  unassigned: boolean;
}) {
  const { approvals, mentions, hasPendingTasks } = args.requestUpdates;
  if (approvals && mentions) {
    return "Unread approvals, mentions";
  } else if (approvals) {
    return "Unread approvals";
  } else if (mentions) {
    return "Unread mentions";
  } else if (hasPendingTasks) {
    return "Action needed";
  } else if (args.requestUpdates.isNew) {
    return "New request";
  } else if ((args.assignedToMe || args.unassigned) && args.requestUpdates.unread) {
    return "Updated request";
  } else {
    return null;
  }
}
export enum ActionEnum {
  ASSIGNEE = "Assignee",
  CATEGORY = "Category",
  PRIORITY = "Priority",
  DUEDATE = "Due date",
  PROJECT = "Project"
}

export const actionMenuLabels = {
  [ActionEnum.ASSIGNEE]: "Set assignee",
  [ActionEnum.CATEGORY]: "Add category",
  [ActionEnum.PRIORITY]: "Set priority",
  [ActionEnum.DUEDATE]: "Set due date",
  [ActionEnum.PROJECT]: "Add to project"
};

/* helps persist filter url params */
export const requestDetailPath = (requestId: string) =>
  `/${routeData.request.pathname}/${requestId}${window.location.search}`;
