import * as React from "react";
import { useEffect } from "react";
import { track404 } from "src/util/analytics";
import { RouteComponentProps } from "src/util/router";
import { Col, Row } from ".";
import { FormSurface } from "./Fields/FormWrappers";

export const PageNotFound = (props: RouteComponentProps) => {
  useEffect(() => {
    if (props.location) {
      track404(props.location.pathname);
    }
  }, [props.location]);
  return (
    <Row flex="1 0 auto" justify="center">
      <Col padding="4rem 0 0 0">
        <FormSurface padding="1rem 1.5rem">
          <h2>Sorry about that</h2>
          <p>We couldn't find the page you requested 😞</p>
        </FormSurface>
      </Col>
    </Row>
  );
};
