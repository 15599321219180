import * as React from "react";
import zIndices from "src/styling/tokens/z-indices.json";
import { formatLongDate, formatTime } from "src/util/formatters";
import { SimpleTooltip } from ".";

interface IDateTime {
  timestamp: Date | string;
  tooltip?: boolean;
}

export const DateTime: React.ComponentType<IDateTime> = props => {
  const dateWrapper = <span>{formatTime(props.timestamp)}</span>;
  return props.tooltip ? (
    <SimpleTooltip
      label={formatLongDate(props.timestamp)}
      cursor="default"
      children={dateWrapper}
      zIndex={zIndices.higher.value}
    />
  ) : (
    dateWrapper
  );
};
