import { SnackbarProps } from "@material-ui/core/Snackbar";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { mdiClose } from "@mdi/js";
import { InjectedNotistackProps, SnackbarProvider, withSnackbar } from "notistack";
import * as React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { MaterialIcon } from "src/components";
import { Toast } from "src/portals/Toast";
import { apolloEmitter, TSnack } from "src/util/apollo/emitter";

/**
 * Component to display errors and network status from "apolloEmitter"
 * Shows one snack per message string
 */
const ApolloSnackbar = withSnackbar(props => {
  const [errorSnack, setErrorSnack] = useState("");
  const [networkOffline, setNetworkOffline] = useState(false);
  useEffect(() => {
    return apolloEmitter.listen(event => {
      switch (event.type) {
        case "error":
          setErrorSnack(event.message);
          break;
        case "network":
          setNetworkOffline(event.offline);
          break;
      }
    });
  }, []);
  return (
    <>
      {errorSnack && <Toast kind="error" message={errorSnack} />}
      {networkOffline && <Toast kind="custom" variant="warning" message={"Network error. You may be offline."} />}
    </>
  );
});

const SnackContext = createContext({
  emitSnack: (() => {
    //
  }) as React.Dispatch<TSnack>
});

/**
 * Exposes emitSnack to display snack messages
 */
export const useSnack = () => {
  const context = useContext(SnackContext);
  if (!context) {
    throw new Error("useCurrentUser must be called from SnackContext child");
  }
  return context;
};

/**
 * Provider to handle SnackContext state
 * Routes events through notistack
 */
const SnackContextProvider = withSnackbar<
  InjectedNotistackProps & {
    emitSnackTestMock?: () => void;
  }
>(props => {
  const [snackEvent, setEmitSnack] = useState<TSnack>();
  const emitSnack = props.emitSnackTestMock ?? setEmitSnack;
  useEffect(() => {
    if (snackEvent && snackEvent.type === "info") {
      props.enqueueSnackbar(snackEvent.message, {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        },
        variant: "success",
        ...(snackEvent.deduplicateKey && {
          preventDuplicate: true,
          key: snackEvent.deduplicateKey
        })
      });
    } else if (snackEvent && snackEvent.type === "mutationError") {
      props.enqueueSnackbar(
        process.env.ENV === "production" ? "Sorry, there was an error submitting." : snackEvent.message,
        {
          action: <MaterialIcon path={mdiClose} size={1.25} onClick={() => true} />,
          anchorOrigin: {
            horizontal: "right",
            vertical: "top"
          },
          persist: true,
          variant: "error",
          ...(snackEvent.deduplicateKey && {
            preventDuplicate: true,
            key: snackEvent.deduplicateKey
          })
        }
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [snackEvent]);
  return (
    <SnackContext.Provider value={{ emitSnack }}>
      <ApolloSnackbar />
      {props.children}
    </SnackContext.Provider>
  );
});

/**
 * Main snack provider
 * Contains
 *  - notistack SnackbarProvider
 *  - SnackProvider to expose emitSnack
 *  - ApolloSnack to handle apollo errors / network status
 */
export const SnackProvider = (props: {
  children?: React.ReactNode;
  options?: Partial<SnackbarProps>;
  emitSnackTestMock?: () => void;
}) => {
  return (
    <SnackbarProvider
      {...props.options}
      maxSnack={3}
      classes={{ variantError: "snack error", variantSuccess: "snack success", variantWarning: "snack warning" }}
      action={[<MaterialIcon key={0} path={mdiClose} size={1.25} data-testid="snack-dismiss" />]}
      iconVariant={
        ({
          error: <></>,
          info: <></>,
          success: <></>,
          warning: <></>
        } as unknown) as React.ComponentType<SvgIconProps>
      }
      autoHideDuration={3000}
    >
      <SnackContextProvider emitSnackTestMock={props.emitSnackTestMock}>{props.children}</SnackContextProvider>
    </SnackbarProvider>
  );
};
