import * as React from "react";

import { Button, IconAndText } from "src/components";

import { FC } from "react";
import { Hand } from "src/svg/icons/Hand";
import { Icon } from "src/components/Icon";
import { Share } from "src/svg/icons/Share";
import { css } from "@emotion/core";

/**
 * This is a rather "stupid" component, that simply renders the sharing ui and a
 * button with no functionality. To use the Typeform sharing modal, you need to
 * pass a function to onShare.
 * @param onClick - control the typeform modal further up in the component tree
 * @param className - bypass css
 */

export const BestPracticeSharing: FC<{
  onClick(): void;
  className?: string;
}> = props => (
  <div
    className={props.className}
    css={css`
      padding: var(--space-3-rem) var(--space-2-rem);
      border-radius: var(--border-radius-s);
      background-color: var(--lightBlue-1);
      margin: var(--space-6-rem) 0;
      align-items: center;
      display: flex;
      & > * + * {
        margin-left: var(--space-2-rem);
      }
    `}
  >
    <IconAndText
      icon={<Hand />}
      label="Share your answer with other forward-thinking teams by contributing it to our best practices"
    />
    <Button variant="primary" onClick={props.onClick}>
      <Icon
        css={css`
          margin-right: var(--space-2-rem);
        `}
      >
        <Share />
      </Icon>
      Share
    </Button>
  </div>
);
