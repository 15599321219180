import { reportDevError } from "src/util";

export enum FileStatus {
  UNSET = -1,
  PROCESSING,
  UPLOADING,
  UPLOADED,
  MAX_SIZE_EXCEEDED,
  FAILED
}

export interface FileInfo {
  id: string; // unique identifier of this FileInfo
  name: string;
  sizeBytes: number;
  attachmentId?: string; // backend attachment ID
}

export type LocalFileInfo = FileInfo & {
  isLocal: true;
  status: FileStatus;
  uploadPercent: number;
  mimeType: string;
};

export type RemoteFileInfo = FileInfo & {
  isRemote: true;
  status: FileStatus.UPLOADED;
  uploadPercent: 100;
};

export interface IFiles {
  files: (LocalFileInfo | RemoteFileInfo)[];
  /**
   * Prevent reducer from accepting more than one file
   * Requires controlling file input as well, e.g. passing flag for useFileInput
   * & disabling button
   * TODO: add flag for DropZone component
   * & find way to contain complexity behind single hook/component
   */
  preventMultiple?: boolean;
}

export type TFileAction =
  | {
      type: "ADD_FILES";
      files: (LocalFileInfo | RemoteFileInfo)[];
    }
  | {
      type: "ADD_EXISTING_ATTACHMENTS";
      attachments: { id: string; displayName: string; size: number }[];
    }
  | {
      type: "CLEAR_FILES";
    }
  | {
      type: "REMOVE_FILE";
      id: string;
    }
  | {
      type: "SET_FILE_STATE";
      id: string;
      status: FileStatus;
      message?: string;
    }
  | {
      type: "SET_FILE_UPLOAD_PERCENT";
      id: string;
      /**
       * percent uploaded
       */
      uploadPercent: number;
    }
  | {
      type: "SET_ATTACHMENT_ID";
      /**
       * backend id
       */
      attachmentId: string;
      id: string;
    };

/**
 * Reducer for handling file(s) states, e.g. processing, uploading, too large, failed
 */
export function filesReducer(state: IFiles, action: TFileAction): IFiles {
  switch (action.type) {
    case "ADD_FILES":
      if (state.preventMultiple && state.files.length > 0) {
        reportDevError("Multiple files not allowed -- please control input");
        return state;
      }
      return {
        ...state,
        files: [...state.files, ...action.files]
      };
    case "ADD_EXISTING_ATTACHMENTS":
      if (state.preventMultiple && state.files.length > 0) {
        reportDevError("Multiple files not allowed -- please control input");
        return state;
      }
      return {
        ...state,
        files: [
          ...state.files,
          ...action.attachments.map(
            x =>
              ({
                isRemote: true,
                id: x.id,
                attachmentId: x.id,
                name: x.displayName,
                sizeBytes: x.size,
                status: FileStatus.UPLOADED,
                uploadPercent: 100
              } as RemoteFileInfo)
          )
        ]
      };
    case "CLEAR_FILES":
      return {
        ...state,
        files: []
      };
    case "REMOVE_FILE":
      return {
        ...state,
        files: state.files.filter(file => file.id !== action.id)
      };
    case "SET_FILE_STATE":
      return {
        ...state,
        files: updateArrayItemWithId(
          state.files,
          action.id,
          x =>
            ({
              ...x,
              status: action.status
            } as LocalFileInfo)
        )
      };
    case "SET_FILE_UPLOAD_PERCENT":
      return {
        ...state,
        files: updateArrayItemWithId(
          state.files,
          action.id,
          x =>
            ({
              ...x,
              uploadPercent: action.uploadPercent
            } as LocalFileInfo)
        )
      };
    case "SET_ATTACHMENT_ID":
      return {
        ...state,
        files: updateArrayItemWithId(state.files, action.id, x => ({
          ...x,
          attachmentId: action.attachmentId
        }))
      };
    default:
      return state;
  }
}

/**
 * Helper to update item in array
 */
function updateArrayItemWithId<T extends { id: string }>(arr: T[], id: string, update: (item: T) => T) {
  return arr.map(item => (item.id === id ? update(item) : item));
}
