import { css } from "@emotion/core";
import { mdiLock } from "@mdi/js";
import { isEqual } from "lodash";
import * as React from "react";
import { MessageBox, RequestAttachmentsList, TimelineEvent } from "src/App/Requests/components";
import { EventContextPopMenu } from "src/App/Requests/DetailView/EventContextPopMenu";
import {
  RequestGet_request_author,
  RequestGet_request_events_commentAddedEvent,
  RequestGet_request_requester,
  RequestGet_request_team
} from "src/App/Requests/DetailView/typings/RequestGet";
import { UserAvatar, UserName } from "src/App/User";
import { DateTime, MaterialIcon, MessageText, Row, SimpleTooltip } from "src/components";
import { RequestChannels } from "src/globalTypes";
import { Typo } from "src/styling/primitives/typography";
import { formatLongDate } from "src/util/formatters";

export interface INoteComponent {
  requestId: string;
  id: string;
  text: string;
  deleted: boolean;
  attachmentsList?: RequestGet_request_events_commentAddedEvent["attachmentsList"];
  onAttachmentDelete: (noteId: string, attachmentId: string) => void;
  author: RequestGet_request_author;
  requester: RequestGet_request_requester;
  timestamp: ScalarDateTime;
  team: RequestGet_request_team;
  channel?: RequestChannels;
  hasHtml?: boolean;
}

export const NoteComponent = React.memo((props: INoteComponent) => {
  const emptyNote = props.text === "\u200B" || !props.text || !props.text.length;
  const showNote = !emptyNote || props.deleted || (props.attachmentsList && !!props.attachmentsList.length);
  const isRequester = props.author.id === props.requester.id;

  return !showNote ? null : (
    <TimelineEvent
      alignLeft={isRequester}
      data-testid="request-note"
      avatar={<UserAvatar sizeL user={props.author} />}
      footer={
        <div
          data-testid="request-note-info"
          css={css`
            // used for ellipses of long emails
            max-width: 100%;
          `}
        >
          <SimpleTooltip
            label={
              <span>
                Sent on <b>{formatLongDate(props.timestamp)}</b> <br />
                Only you and experts in <b>{props.team.name}</b> can see this. It is not visible to the requester.
              </span>
            }
          >
            <Row
              css={css`
                align-items: center;
                > * {
                  // used for ellipses of long emails
                  flex: 0 0 auto;
                }
              `}
            >
              <Typo.Body
                medium
                ellipsis
                css={css`
                  // used for ellipses of long emails
                  flex: 0 1 auto;
                `}
              >
                <UserName user={props.author} bold={true} />
              </Typo.Body>
              {","}&nbsp;
              <DateTime timestamp={props.timestamp} />
              <MaterialIcon path={mdiLock} size={1} />
            </Row>
          </SimpleTooltip>
        </div>
      }
      contextMenu={
        !props.deleted && (
          <EventContextPopMenu
            requestId={props.requestId}
            commentId={props.id}
            eventType="note"
            hasHtml={props.hasHtml}
            eventText={props.text}
            team={props.team}
          />
        )
      }
    >
      <MessageBox yellow>
        {!emptyNote && (
          <Typo.Body>
            <MessageText text={props.text} />
          </Typo.Body>
        )}

        {props.deleted && (
          <Typo.Body light>
            <i>This note was deleted</i>
          </Typo.Body>
        )}
        {!props.deleted && props.attachmentsList?.length ? (
          <RequestAttachmentsList
            attachmentsList={props.attachmentsList}
            onAttachmentDelete={attachmentId => {
              props.onAttachmentDelete(props.id, attachmentId);
            }}
            authToken={null}
            isNote
          />
        ) : null}
      </MessageBox>
    </TimelineEvent>
  );
}, isEqual);
