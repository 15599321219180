import { css } from "@emotion/core";
import * as React from "react";
import { ConfirmDialogModal } from "src/components";
import { ExternalDocumentSourceDocumentSyncType } from "src/globalTypes";
import { Typo } from "src/styling/primitives/typography";
import { reportDevError } from "src/util";
import { useExternalDocumentsList } from ".";
import { GDocumentsListGet_documentList } from "../typings/GDocumentsListGet";

export const UnsyncExternalDocument: React.FC<{
  document: GDocumentsListGet_documentList;
  isOpen: boolean;
  onCancel(): void;
  onFinish(): void;
}> = props => {
  const { externalDocumentsResponse, setDocSyncType, setDocSyncTypeLoading } = useExternalDocumentsList();

  const externalDocument = props.document.external;
  if (!externalDocument) {
    reportDevError("No external document");
    return null;
  }

  const externalSource = externalDocumentsResponse.data?.externalDocumentSources.find(
    s => s.id === externalDocument.source.id
  );

  if (externalDocumentsResponse.data && !externalSource) {
    reportDevError("No external source");
  }

  const syncedDocument = externalSource?.synchronizedDocuments.find(
    ({ document }) => document.id === externalDocument.id
  );

  // child pages are not listed under synchronizedDocuments
  const isProbablyChildPage = !!externalSource?.synchronizedDocuments && !syncedDocument;

  return (
    <ConfirmDialogModal
      isOpen={props.isOpen}
      medium
      loading={externalDocumentsResponse.loading ?? setDocSyncTypeLoading}
      text={{
        cancel: isProbablyChildPage ? "Go back" : "Cancel",
        confirm: isProbablyChildPage ? undefined : "Confirm",
        heading: props.document.title
      }}
      handleCancel={props.onCancel}
      handleConfirm={async () => {
        props.onFinish();
        await setDocSyncType(
          externalDocument.source.id,
          externalDocument.id,
          ExternalDocumentSourceDocumentSyncType.DOCUMENT_SYNC_TYPE_UNSPECIFIED
        );
      }}
    >
      {isProbablyChildPage && (
        <Typo.Body>
          It looks like this is the sub-page of another page. Please remove the parent page to unsynchronize "
          {props.document.title}"
        </Typo.Body>
      )}
      {syncedDocument && (
        <div
          css={css`
            & > * + * {
              margin-top: var(--space-2-rem);
            }
          `}
        >
          <Typo.Body>
            Are you sure you want to remove this document from the list of synchronized documents? It may take a few
            moments for the list of synchronized documents to update.
          </Typo.Body>
        </div>
      )}
    </ConfirmDialogModal>
  );
};
