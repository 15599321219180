import { mdiInformation } from "@mdi/js";
import * as React from "react";
import { documentPreviewRoute, documentsOverviewRoute } from "src/App/KB";
import { GDocumentsListGet_documentList } from "src/App/KB/typings/GDocumentsListGet";
import { Dialog, InfoBanner, MaterialIcon } from "src/components";
import { Modal } from "src/portals/Modal";
import { navigate } from "src/util/router";
import { DocumentCreateUpdateContainer } from "./Container";

export interface IDocumentCreateUpdateModalProps {
  type: "edit" | "create";
  isOpen: boolean;
  onSuccess: () => void;
  onDismiss: () => void;
  onShareClick?: () => void;
  document?: GDocumentsListGet_documentList;
  text?: string;
  commentId?: string;
}

export const DocumentCreateUpdateModal: React.FunctionComponent<IDocumentCreateUpdateModalProps> = props => (
  <Modal onDismiss={props.onDismiss} isOpen={props.isOpen}>
    <Dialog
      shouldWarnBeforeClose
      title={props.type === "edit" ? "Edit answer" : "Add answer"}
      large
      subtitle={
        props.type === "edit" ? (
          ""
        ) : (
          <InfoBanner>
            <MaterialIcon path={mdiInformation} size={1.125} />
            Save answers that you use on a regular basis. When sent manually, we will learn from it and send them
            automatically whenever appropriate.
          </InfoBanner>
        )
      }
      onClose={props.onDismiss}
    >
      {props.type === "edit" && props.document && (
        <DocumentCreateUpdateContainer
          document={props.document}
          onSuccess={props.onSuccess}
          onShareClick={props.onShareClick}
          onCancel={() => props.document && navigate(documentPreviewRoute(props.document.id))}
        />
      )}
      {props.type === "create" && !props.document && (
        <DocumentCreateUpdateContainer
          onSuccess={props.onSuccess}
          text={props.text}
          commentId={props.commentId}
          onShareClick={props.onShareClick}
          onCancel={() => navigate(documentsOverviewRoute)}
        />
      )}
    </Dialog>
  </Modal>
);
