import { css } from "@emotion/core";
import { csx } from "src/util/csx";

export const Table = csx(
  [
    css`
      width: 100%;
      border-collapse: collapse;
      color: var(--text-6);

      & thead {
        text-align: left;
      }

      & thead tr,
      & tbody tr:not(:last-child) {
        border-bottom: var(--border-lightGrey);
      }
      & th {
        font-size: var(--font-size-subheading);
        line-height: var(--font-size-subheading);
        font-weight: var(--font-weight-body-semiBold);
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: var(--text-3);
        padding: 0.875rem 0;
      }
      & td {
        padding-top: var(--space-2-px);
        padding-bottom: var(--space-2-px);
        max-width: 10rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        & span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      & th + th,
      & td + td {
        padding-left: var(--space-2-rem);
      }
      & tr td:first-child {
        font-weight: var(--font-weight-body-medium);
      }
    `
  ],
  {
    noCellEllipsis: css`
      & tbody tr td {
        white-space: normal;
      }
    `,
    hasClickableRows: css`
      & tbody tr {
        &:hover {
          cursor: pointer;
          background-color: var(--lightGrey-1);
        }
        & > th {
          &:hover {
            cursor: default;
          }
        }
      }
      & thead tr th:first-of-type,
      & tbody tr td:first-of-type {
        padding-left: var(--space-2-rem);
      }
      & thead tr th:last-of-type,
      & tbody tr td:last-of-type {
        padding-right: var(--space-2-rem);
      }
    `
  },
  "table"
);
