import styled from "@emotion/styled";
import { Field, Formik } from "formik";
import * as React from "react";
import { MutationFunction } from "react-apollo";
import { CancelButton, Col, FormikInput, LoadingBar, Row, SubmitButton } from "src/components";
import * as yup from "yup";
import { IUserUpdateProps } from "./Container";
import { GSettingsUserGet } from "./typings/GSettingsUserGet";
import { GSettingsUserUpdate, GSettingsUserUpdateVariables } from "./typings/GSettingsUserUpdate";

const Form = styled.form`
  & .heading {
    border: 1px solid var(--border);
  }

  & .close {
    color: var(--border);
  }
`;
/**
 * Validation rules
 */
const FormSchema = yup.object().shape({
  email: yup
    .string()
    .email("Must be a valid email")
    .required("Required"),
  name: yup.string().required("Required")
});

/**
 * Submission handler
 * @param userUpdate apollo mutation fn
 * @param redirect url path to redirect after submission
 * @param params mutation variable object
 */
const submitUserUpdateMutation = (
  userUpdate: MutationFunction<GSettingsUserUpdate, GSettingsUserUpdateVariables>,
  params: GSettingsUserUpdateVariables["params"],
  onSuccess: () => void
) => {
  userUpdate({
    update: (cache, response) => {
      if (response.data && response.data.userUpdate.success) {
        onSuccess();
      }
    },
    variables: {
      params
    }
  });
};

/**
 * Form component for editing user details
 */
export const UserUpdateForm: React.ComponentType<GSettingsUserGet &
  IUserUpdateProps & {
    userUpdate: MutationFunction<GSettingsUserUpdate, GSettingsUserUpdateVariables>;
  }> = props => (
  <Formik
    enableReinitialize={true}
    onSubmit={payload =>
      submitUserUpdateMutation(
        props.userUpdate,
        {
          ...payload,
          id: props.user.id
        },
        props.onFinish
      )
    }
    initialValues={{
      email: props.user.email,
      name: props.user.name
    }}
    validationSchema={FormSchema}
    render={form => (
      <Form onSubmit={form.handleSubmit} data-testid="user-update-form">
        <Col>
          <Field name="name" component={FormikInput} label={"Name"} />
          <Field name="email" component={FormikInput} label={"Email address"} />
          <Row justify="flex-end">
            {!form.isSubmitting && (
              <>
                <CancelButton onClick={props.onFinish} />
                <SubmitButton>Save changes</SubmitButton>
              </>
            )}
            {form.isSubmitting && <LoadingBar />}
          </Row>
        </Col>
      </Form>
    )}
  />
);
