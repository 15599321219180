import gql from "graphql-tag";
import { intersection } from "lodash";
import * as React from "react";
import { createContext, useContext } from "react";
import { Query } from "react-apollo";
import { useLoginStatus } from "src/hooks/loginStatus";
import { Toast } from "src/portals/Toast";
import { apolloClient } from "src/util/apollo/client";
import { GFeatureFlags } from "./typings/GFeatureFlags";

/**
 * The actual feature flags that are used to show/hide specific features.
 */

export enum FeatureFlags {
  ANALYTICSEXPORT = "AnalyticsExport",
  AUTOFORM = "AutoForms",
  CHANGEREQUESTER = "ChangeRequester",
  CUSTOMREQUESTSTATUS = "CustomRequestStatus",
  EXTERNALDOCS = "ExternalDocs",
  HIDEEXPERTANALYTICS = "HideExpertAnalytics",
  INTERCOMENABLED = "IntercomEnabled",
  LISTINTERNALONLY = "ListInternalOnly",
  JIRA = "Jira",
  KBTESTER = "KBTester",
  MANUALTASKCREATE = "ManualTaskCreate",
  OKTA = "Okta",
  RICHTEXTREPLIES = "RichTextReplies",
  SAVED_REPLIES_ATTACHMENTS = "SavedRepliesAttachments",
  SLA = "SLA",
  SSO = "SSO",
  SWAPPUBLICREPLY = "SwapPublicReply",
  WORKDAY = "Workday",
  WORKFLOWS = "Workflows",
  WORKFLOWBUILDER = "WorkflowBuilder",
  WORKFLOWBUILDEREDITSTEPS = "WorkflowBuilderEditSteps"
}

export const FEATURE_FLAGS = gql`
  query GFeatureFlags {
    featureFlags {
      id
    }
  }
`;

/**
 * Secret function enable feature flags from the console
 */
// tslint:disable-next-line:no-string-literal
// tslint:disable-next-line:no-explicit-any
(window as any)["__updateFeatureFlags"] = (flags: string[]) => {
  apolloClient.writeQuery({
    data: {
      featureFlags: flags.map(id => ({
        __typename: "FeatureFlag",
        id
      }))
    },
    query: FEATURE_FLAGS
  });
};

type FeatureFlag = string;

/**
 * @property featureFlags An array containing the current user's feature flags
 * @property hasFeatureFlags A function that checks if the current user has the
 * provided feature flag
 */
export interface IFeatureFlagContext {
  featureFlags: FeatureFlag[];
  hasFeatureFlags(...flags: FeatureFlag[]): boolean;
  isContextInitialised: boolean;
}

/**
 * The feature flag context that all child components will be provided with.
 * @implements Context<IFeatureFlagContext>
 */
const FeatureFlagContext = createContext<IFeatureFlagContext>({
  featureFlags: [],
  hasFeatureFlags: () => false,
  isContextInitialised: false
});

/**
 * Exposes emitSnack to display snack messages
 */
export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagContext);
  if (!context) {
    throw new Error("useFeatureFlags must be called from FeatureFlagContext child");
  }
  return context;
};

export interface IFeatureFlagProvider {
  children?: React.ReactNode;
}

/**
 * A component that provides all its child components with the current user's
 * feature flag context, if possible. If the feature flag list cannot be fetched
 * from the backend, it will default to an empty list and an appropirate error
 * toast will be displayed.
 * @param props The feature flag provider's props
 */
export const FeatureFlagProvider = (props: IFeatureFlagProvider) => {
  const isLoggedIn = useLoginStatus();
  return (
    <Query<GFeatureFlags> query={FEATURE_FLAGS} fetchPolicy="cache-first">
      {({ data, loading, error }) => {
        const context: IFeatureFlagContext = {
          featureFlags: data && data.featureFlags ? data.featureFlags.map(flag => flag.id) : [],
          hasFeatureFlags: (...flags) => intersection(context.featureFlags, flags).length > 0,
          isContextInitialised: !!(data && data.featureFlags && !loading && !error)
        };
        return (
          <FeatureFlagContext.Provider value={context}>
            {props.children}
            {!loading && error && isLoggedIn && <Toast kind="custom" message="Some features may not be available." />}
          </FeatureFlagContext.Provider>
        );
      }}
    </Query>
  );
};

/** Static provider for testing */
export const StaticFeatureFlagProvider: React.ComponentType<{
  featureFlags: string[];
}> = props => {
  const context: IFeatureFlagContext = {
    featureFlags: props.featureFlags,
    hasFeatureFlags: (...flags) => intersection(context.featureFlags, flags).length > 0,
    isContextInitialised: !!props.featureFlags
  };
  return <FeatureFlagContext.Provider value={context}>{props.children}</FeatureFlagContext.Provider>;
};
