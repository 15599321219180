import gql from "graphql-tag";
import { apolloClient } from "src/util/apollo/client";
import { GAutocompleteUserList, GAutocompleteUserListVariables } from "./typings/GAutocompleteUserList";

const USER_LIST = gql`
  query GAutocompleteUserList($query: UserListQuery) {
    userList2(query: $query, first: 10) {
      users {
        id
        name
        email
        joinTime
        profile {
          name
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export const getUserList = async (
  value: string,
  expertsOnly = false,
  internalOnly = false
): Promise<GAutocompleteUserList["userList2"]> => {
  const response = await apolloClient.query<GAutocompleteUserList, GAutocompleteUserListVariables>({
    fetchPolicy: "network-only",
    query: USER_LIST,
    variables: {
      query: { filterNameOrEmail: value, expertsOnly }
    }
  });
  if (internalOnly) {
    return {
      ...response.data.userList2,
      users: response.data.userList2.users.filter(u => !!u.profile)
    };
  }
  return response.data.userList2;
};
