import gql from "graphql-tag";
import * as React from "react";
import { Query } from "react-apollo";
import { REQUEST_LIST_COUNTS } from "src/App/Sidebar/RequestListCounts";
import { GRequestMarkRead, GRequestMarkReadVariables } from "./typings/GRequestMarkRead";

const REQUEST_MARK_READ = gql`
  query GRequestMarkRead($requestId: ID!) {
    requestMarkRead(requestId: $requestId) {
      code
      success
      message
    }
  }
`;

/** Move this into a hook? */
export const MarkRead = (props: { requestId: string }) => (
  <Query<GRequestMarkRead, GRequestMarkReadVariables>
    query={REQUEST_MARK_READ}
    variables={{ requestId: props.requestId }}
    fetchPolicy="network-only"
  >
    {response =>
      response.data && response.data.requestMarkRead && response.data.requestMarkRead.success ? (
        <Query query={REQUEST_LIST_COUNTS} fetchPolicy="network-only">
          {() => null}
        </Query>
      ) : null
    }
  </Query>
);
