import { css } from "@emotion/core";
import { mdiLibraryBooks } from "@mdi/js";
import gql from "graphql-tag";
import { debounce } from "lodash";
import * as React from "react";
import { useCallback, useState } from "react";
import { useQuery } from "react-apollo";
import { DOCUMENT_FRAGMENT } from "src/App/KB";
import { sortedDocumentList } from "src/App/KB/Docs";
import {
  AbsoluteTooltip,
  Col,
  Dialog,
  EmptyState,
  Input,
  LoadingBar,
  MaterialIcon,
  SquareButton
} from "src/components";
import { Table } from "src/components/Table";
import { DocumentSource } from "src/globalTypes";
import { Modal } from "src/portals/Modal";
import { Toast } from "src/portals/Toast";
import { Typo } from "src/styling/primitives/typography";
import { backMarkdownToText } from "src/util/formatters";
import { DocumentAction, DocumentState } from "./reducer";
import { DocumentPickerList } from "./typings/DocumentPickerList";
import { DocumentSearch, DocumentSearchVariables } from "./typings/DocumentSearch";
import { DocumentLogo } from "src/App/KB/DocumentLogos";

export const DocumentPicker = (props: {
  documentState: DocumentState;
  documentDispatch: React.Dispatch<DocumentAction>;
  requestId: string;
}) => {
  return (
    <>
      <DocumentModal
        isOpen={props.documentState.showDocumentModal}
        dismiss={() =>
          props.documentDispatch({
            type: "TOGGLE_DOCUMENT_MODAL",
            showDocumentModal: false
          })
        }
        documentsDispatch={props.documentDispatch}
        documentsState={props.documentState}
        requestId={props.requestId}
      />
      <AbsoluteTooltip placement="top" content="Attach an answer">
        <SquareButton
          onClick={() =>
            props.documentDispatch({
              type: "TOGGLE_DOCUMENT_MODAL",
              showDocumentModal: true
            })
          }
        >
          <MaterialIcon path={mdiLibraryBooks} size={1.125} />
        </SquareButton>
      </AbsoluteTooltip>
    </>
  );
};

const DocumentModal = (props: {
  dismiss(): void;
  isOpen: boolean;
  documentsState: DocumentState;
  documentsDispatch: React.Dispatch<DocumentAction>;
  requestId: string;
}) => {
  const documentListResponse = useQuery<DocumentPickerList>(gql`
    query DocumentPickerList {
      documentList {
        ...DocumentFragment
      }
    }
    ${DOCUMENT_FRAGMENT}
  `);
  const [searchQuery, privateSetSearchQuery] = useState("");
  const documentSearchResponse = useQuery<DocumentSearch, DocumentSearchVariables>(
    gql`
      query DocumentSearch($query: String!) {
        documentSearch(query: $query) {
          ...DocumentFragment
        }
      }
      ${DOCUMENT_FRAGMENT}
    `,
    {
      fetchPolicy: "network-only",
      skip: searchQuery.length < 2,
      variables: {
        query: searchQuery
      }
    }
  );
  const setSearchQuery = useCallback(
    debounce((query: string) => privateSetSearchQuery(query), 200),
    []
  );

  const documents = documentSearchResponse.data?.documentSearch ?? documentListResponse.data?.documentList ?? null;

  return (
    <Modal isOpen={props.isOpen} onDismiss={props.dismiss} alignTop>
      <Dialog large onClose={props.dismiss} title="Select an answer">
        <Col flex="1 1 auto">
          {documentSearchResponse.error && <Toast kind="error" message={documentSearchResponse.error.message} />}
          {documentSearchResponse.loading && <LoadingBar />}
          <Input
            autoFocus
            placeholder="Start typing to search in your answers"
            onChange={e => {
              setSearchQuery(e.target.value);
            }}
          />
          {/* only show after results loaded and none found */}
          {!documentSearchResponse.loading &&
            documentSearchResponse.data &&
            !documentSearchResponse.data.documentSearch?.length && (
              <EmptyState
                title="No answers found"
                subtitle={
                  <>
                    To attach new answers add some in the <strong>Knowledge</strong> section
                  </>
                }
                spacing="tight"
                variant="info"
              />
            )}
          {/* checking props.isOpen is a quickfix for preventing calling backMarkdownToText for each document, on every render. eventually we need a better approach for handling modals in general */}
          {props.isOpen && documents && documents.length > 0 && (
            <Table
              hasClickableRows
              css={css`
                margin-top: var(--space-5-px);
              `}
            >
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Content</th>
                </tr>
              </thead>
              <tbody>
                {sortedDocumentList(documents).map((doc, i) => {
                  const content = backMarkdownToText(doc.content);
                  return (
                    <tr
                      key={i}
                      onClick={() => {
                        props.documentsDispatch({
                          type: "SELECT_DOCUMENT",
                          document: doc,
                          requestId: props.requestId
                        });
                        props.dismiss();
                      }}
                    >
                      <td>
                        <div
                          css={css`
                            display: flex;
                            align-items: center;

                            & * + * {
                              margin-left: 0.375rem;
                            }
                          `}
                        >
                          <DocumentLogo doc={doc} />
                          <span>{doc.title}</span>
                        </div>
                      </td>

                      {doc.source !== DocumentSource.confluence && <td title={content}>{content}</td>}
                      {doc.source === DocumentSource.confluence && doc.url && (
                        <td>
                          <Typo.ExternalLink
                            onClick={e => e.stopPropagation()}
                            href={doc.url}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            View page in Confluence
                          </Typo.ExternalLink>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </Col>
      </Dialog>
    </Modal>
  );
};
