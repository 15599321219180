import * as React from "react";

import { Button, ButtonBar } from "src/components";
import { CardState, useWorkflow, wfCardStyle } from ".";
import { FeatureFlags, useFeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import { Form, Formik } from "formik";
import {
  HRISOnboardingTriggerCard,
  KnowledgeAnswerTriggerCard,
  ManualTriggerCard,
  RecurringTriggerCard,
  RequestCategoryChangedTriggerCard,
  RequestCommentAddedTriggerCard,
  RequestCreatedTriggerCard,
  RequestStatusChangedTriggerCard,
  RequestTeamChangedTriggerCard
} from "src/App/Settings/Workflows/DetailView/Triggers";
import { TriggerInput, TriggerInputMap, TriggerKey } from "src/globalTypes";

import { Card } from "src/styling/primitives/Card";
import { FC } from "react";
import { PublishedVariables } from "./Variables";
import { TeamWorkflowGet_teamWorkflow_triggers_publishedVariables } from "src/App/Settings/Workflows/typings/TeamWorkflowGet";
import { css } from "@emotion/core";

export const TriggerCard: FC<{
  trigger: TriggerInput;
  submit(payload: TriggerInput): void;
  toggleExpanded(): void;
  cardState: CardState;
  publishedVariables: TeamWorkflowGet_teamWorkflow_triggers_publishedVariables[];
}> = ({ trigger, submit, toggleExpanded, cardState, publishedVariables }) => {
  const { hasFeatureFlags } = useFeatureFlags();
  const { requiresCustomization } = useWorkflow();
  return (
    <Formik
      enableReinitialize
      initialValues={trigger}
      onSubmit={payload => submit(payload)}
      render={triggerForm => (
        <Form>
          <Card
            sizeM
            hasInteractions={
              cardState === "collapsed" &&
              hasFeatureFlags(FeatureFlags.WORKFLOWBUILDEREDITSTEPS) &&
              !requiresCustomization
            }
            hasExtraPadding={cardState === "expanded"}
            onClick={() => {
              cardState === "collapsed" && toggleExpanded();
            }}
            css={[
              wfCardStyle,
              cardState === "disabled" &&
                css`
                  opacity: 0.4;
                `
            ]}
          >
            <TriggerFieldComponents
              trigger={triggerForm.values}
              expanded={cardState === "expanded"}
              onChange={({ map }) => {
                triggerForm.setFieldValue("map", map);
              }}
            />
            <PublishedVariables variables={publishedVariables} expanded={cardState === "expanded"} />
            {cardState === "expanded" && (
              <ButtonBar>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    triggerForm.resetForm();
                    toggleExpanded();
                  }}
                >
                  Cancel
                </Button>
                <Button type="button" variant="primary" disabled={!triggerForm.dirty} onClick={triggerForm.submitForm}>
                  Apply changes
                </Button>
              </ButtonBar>
            )}
          </Card>
        </Form>
      )}
    />
  );
};

const TriggerFieldComponents: React.FC<{
  trigger: TriggerInput;
  expanded: boolean;
  onChange(trigger: TriggerInput): void;
}> = ({ trigger: { key, map, publishedVariables }, expanded, onChange }) => {
  const handleChange = <K extends TriggerKey>(triggerMap: Partial<TriggerInputMap[K]>) =>
    onChange({
      key,
      map: {
        [key]: triggerMap
      },
      publishedVariables
    });
  switch (key) {
    case TriggerKey.WorkflowTriggerHRISOnboarding: {
      const trigger = map[key];
      if (!trigger) return null;
      return <HRISOnboardingTriggerCard trigger={trigger} expanded={expanded} onChange={handleChange} />;
    }
    case TriggerKey.WorkflowTriggerKnowledgeAnswer: {
      const trigger = map[key];
      if (!trigger) return null;
      return <KnowledgeAnswerTriggerCard trigger={trigger} expanded={expanded} onChange={handleChange} />;
    }
    case TriggerKey.WorkflowTriggerManual: {
      const trigger = map[key];
      if (!trigger) return null;
      return <ManualTriggerCard trigger={trigger} expanded={expanded} onChange={handleChange} />;
    }
    case TriggerKey.WorkflowTriggerRecurring: {
      const trigger = map[key];
      if (!trigger) return null;
      return <RecurringTriggerCard trigger={trigger} expanded={expanded} onChange={handleChange} />;
    }
    case TriggerKey.WorkflowTriggerRequestCategoryChanged: {
      const trigger = map[key];
      if (!trigger) return null;
      return <RequestCategoryChangedTriggerCard trigger={trigger} expanded={expanded} onChange={handleChange} />;
    }
    case TriggerKey.WorkflowTriggerRequestCommentAdded: {
      const trigger = map[key];
      if (!trigger) return null;
      return <RequestCommentAddedTriggerCard trigger={trigger} expanded={expanded} onChange={handleChange} />;
    }
    case TriggerKey.WorkflowTriggerRequestCreated: {
      const trigger = map[key];
      if (!trigger) return null;
      return <RequestCreatedTriggerCard trigger={trigger} expanded={expanded} onChange={handleChange} />;
    }
    case TriggerKey.WorkflowTriggerRequestStatusChanged: {
      const trigger = map[key];
      if (!trigger) return null;
      return <RequestStatusChangedTriggerCard trigger={trigger} expanded={expanded} onChange={handleChange} />;
    }
    case TriggerKey.WorkflowTriggerRequestTeamChanged: {
      const trigger = map[key];
      if (!trigger) return null;
      return <RequestTeamChangedTriggerCard trigger={trigger} expanded={expanded} onChange={handleChange} />;
    }
  }
};
