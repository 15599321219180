import gql from "graphql-tag";

export const CATEGORY_MATCHER_FRAGMENT = gql`
  fragment CategoryMatcher on CategoryMatcher {
    categoryId
    name
  }
`;

export const DATE_MATCHER_FRAGMENT = gql`
  fragment DateMatcher on DateMatcher {
    date
  }
`;

export const DOCUMENT_MATCHER_FRAGMENT = gql`
  fragment DocumentMatcher on DocumentMatcher {
    docPk
    title
  }
`;

export const FORM_MATCHER_FRAGMENT = gql`
  fragment FormMatcher on FormMatcher {
    formId
    name
  }
`;

export const PRIORITY_MATCHER_FRAGMENT = gql`
  fragment PriorityMatcher on PriorityMatcher {
    priority
  }
`;

export const STATUS_MATCHER_FRAGMENT = gql`
  fragment StatusMatcher on StatusMatcher {
    statusId
    name
  }
`;

export const TEAM_MATCHER_FRAGMENT = gql`
  fragment TeamMatcher on TeamMatcher {
    teamId
    slug
  }
`;

export const USER_MATCHER_FRAGMENT = gql`
  fragment UserMatcher on UserMatcher {
    userId
    email
    role
    userTypeSelection
  }
`;
