import { css } from "@emotion/core";
import { mdiInformation } from "@mdi/js";
import * as React from "react";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { BaseLayout, Col, InfoBanner, MaterialIcon } from "src/components";
import { Typo } from "src/styling/primitives/typography";
import { getPrefKey, HotKeysContext } from "src/util/services/hotkeys";

export const HotKeysOverview: React.FC = () => {
  const { currentUser } = useCurrentUser();
  const { state, dispatch } = React.useContext(HotKeysContext);
  const { emitSnack } = useSnack();

  const localStoragePrefKey = getPrefKey(currentUser?.organization.id ?? "<unknown org>");

  return (
    <BaseLayout
      headline="Keyboard shortcuts"
      subline={
        <Typo.Body>Click below to enable or disable keyboard shortcuts for yourself in this organization</Typo.Body>
      }
      isLoading={state.meta.enabled === null}
    >
      <Col
        css={css`
          & > * + * {
            margin-top: var(--space-3-px);
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <label>
            <input
              name="hotkeys-preference"
              type="radio"
              checked={state.meta.enabled ?? false}
              value="enable"
              onChange={() => {
                localStorage.setItem(localStoragePrefKey, "true");
                dispatch({ type: "ENABLE_ALL_HOTKEYS" });
                emitSnack({
                  message: "Keyboard shortcuts enabled. Press ? to view help",
                  type: "info"
                });
              }}
            />{" "}
            Enable shortcuts
          </label>
          <label>
            <input
              name="hotkeys-preference"
              type="radio"
              checked={!state.meta.enabled}
              value="disable"
              onChange={() => {
                localStorage.removeItem(localStoragePrefKey);
                dispatch({ type: "DISABLE_ALL_HOTKEYS" });
                emitSnack({
                  message: "Keyboard shortcuts disabled",
                  type: "info"
                });
              }}
            />{" "}
            Disable shortcuts
          </label>
        </div>
        {state.meta.enabled && (
          <InfoBanner
            css={css`
              width: 35%;
            `}
          >
            <MaterialIcon path={mdiInformation} size={1.125} />
            <Typo.Body>Press ? to view enabled keyboard shortcuts</Typo.Body>
          </InfoBanner>
        )}
      </Col>
    </BaseLayout>
  );
};
