import { css } from "@emotion/core";
import * as React from "react";
import { csx } from "src/util/csx";
import { sanitizeSvg } from "src/util/formatters";

/* 

  The icon svgs need to follow a certain format to be able to have all functionality. 
  The svg tag needs to be cleaned up and cannot have any additional attributes.

  To generate the jsx files, simply run the 'yarn icons' script for all icons in the directory 'src/assets/icons'.
  It will clean up the svg code, convert the svg content to jsx and create an exported module around it.
  
 */

export const Icon = csx(
  [
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      & > svg {
        height: 1rem;
        width: 1rem;
        * {
          fill: currentColor;
        }
      }
    `
  ],
  {
    inline: css`
      display: inline-flex;
    `,
    sizeS: css`
      & > svg {
        height: 0.75rem;
        width: 0.75rem;
        max-width: 0.75rem;
      }
    `,
    sizeM: css`
      & > svg {
        height: 1rem;
        width: 1rem;
        max-width: 1rem;
      }
    `,
    sizeL: css`
      & > svg {
        height: 1.125rem;
        width: 1.125rem;
        max-width: 1.125rem;
      }
    `,
    narrow: css`
      & > svg {
        width: auto;
      }
    `
  }
);

/** Sanitizes and displays icon from svg string using Icon component */
export const IconFromString: React.FC<
  {
    svg: string;
    className?: string;
  } & React.ComponentProps<typeof Icon>
> = props => {
  const [sanitizedSvg] = React.useState(sanitizeSvg(props.svg));
  return <Icon className={props.className} dangerouslySetInnerHTML={{ __html: sanitizedSvg }} {...props} />;
};

/** Sanitizes and displays svg from svg string using div */
export const GenericSvgFromString: React.FC<{
  svg: string;
  className?: string;
}> = props => {
  const [sanitizedSvg] = React.useState(sanitizeSvg(props.svg));
  return <div className={props.className} dangerouslySetInnerHTML={{ __html: sanitizedSvg }} />;
};
