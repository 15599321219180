import gql from "graphql-tag";
import { USER_INFO_FRAGMENT } from "src/fragments/UserInfoFragment";
export const FORM_SUBMITTED_EVENT_DETAILS = gql`
  fragment FormSubmittedEventDetails on FormSubmittedEvent {
    submittedForm {
      id
      submitter {
        ...UserInfo
      }
      submitTime
      schema {
        id
        pk
        revision
        displayName
        fieldsList {
          ... on TextField {
            __typename
            textFieldType
            displayName
            value
            required
          }
          ... on DropdownField {
            __typename
            displayName
            optionsList
            selected
            required
          }
          ... on AttachmentsField {
            __typename
            displayName
            required
            attachment {
              id
              displayName
              size
              receiveTime
            }
          }
        }
      }
    }
  }
  ${USER_INFO_FRAGMENT}
`;
