import gql from "graphql-tag";
// moved to fix
// ReferenceError: Cannot access 'USER_INFO_FRAGMENT' before initialization
export const USER_INFO_FRAGMENT = gql`
  fragment UserInfo on User {
    id
    name
    email
    joinTime
  }
`;
