import gql from "graphql-tag";
// moved to fix
// ReferenceError: Cannot access 'USER_INFO_FRAGMENT' before initialization
export const ATTACHMENT_FRAGMENT = gql`
  fragment AttachmentFragment on Attachment {
    id
    displayName
    size
    deleteTime
  }
`;
