import { css } from "@emotion/core";
import { mdiChevronRight, mdiGestureTap, mdiInformation, mdiPowerSettings, mdiSettings } from "@mdi/js";
import gql from "graphql-tag";
import { groupBy } from "lodash";
import * as React from "react";
import { useEffect } from "react";
import { useMutation, useQuery } from "react-apollo";
import { BestPracticeSharing } from "src/App/BestPractices/BestPracticeSharing";
import { BestPracticeComponent } from "src/App/BestPractices/DetailView";
import { HelmetComponent } from "src/App/Root/HelmetComponent";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { FeatureFlags, useFeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { CurrentUser_currentUser_teams } from "src/App/Root/providers/typings/CurrentUser";
import { routeData } from "src/App/Root/RouteData";
import {
  BaseLayout,
  Button,
  CleanUnderlineButton,
  CleanUnderlineHeadlineButton,
  Col,
  ConfirmDialogModal,
  DateTime,
  EmptyState,
  InfoBanner,
  MaterialIcon,
  Row,
  TypeformModal
} from "src/components";
import { Badge } from "src/components/Badges";
import { GenericSvgFromString, Icon } from "src/components/Icon";
import { PopOver } from "src/components/PopOver";
import { TeamWorkflowStatus, WorkflowRunState } from "src/globalTypes";
import { Card } from "src/styling/primitives/Card";
import { Typo } from "src/styling/primitives/typography";
import { CheckCircle } from "src/svg/icons/CheckCircle";
import { Document } from "src/svg/icons/Document";
import { HelpBubble } from "src/svg/icons/HelpBubble";
import { WarningCircle } from "src/svg/icons/WarningCircle";
import { trackBestPracticeSharingIntent, trackWorkflowConfigNotCompleted } from "src/util/analytics";
import { csx } from "src/util/csx";
import { backMarkdownToHtml, markdownWithEmbeddedHtmlToHtml } from "src/util/formatters";
import { Link, navigate, useMatch, useUrlState } from "src/util/router";
import { AddWorkflowToTeam, AddWorkflowToTeamVariables } from "./typings/AddWorkflowToTeam";
import { RemoveWorkflowFromTeam, RemoveWorkflowFromTeamVariables } from "./typings/RemoveWorkflowFromTeam";
import {
  WorkflowsGetList,
  WorkflowsGetListVariables,
  WorkflowsGetList_teamWorkflows
} from "./typings/WorkflowsGetList";

type WorkflowsGroupedByStatus = {
  [key in TeamWorkflowStatus]: WorkflowsGetList_teamWorkflows[];
};

const WORKFLOWS_GET_LIST = gql`
  query WorkflowsGetList($teamId: ID!) {
    teamWorkflows(teamId: $teamId) {
      id
      pk
      name
      description
      status
      enabled
      requiresCustomization
      icon
      triggersDescription
      stepsDescription
      typeformId
      createTime
      lastAuthor {
        id
        name
        email
      }
      lastCompletedRunTime
      lastCompletedRunStatus
      linkedEntities {
        forms {
          id
          displayName
        }
        documents {
          id
          title
        }
      }
    }
  }
`;

const ComponentCard = csx([
  css`
    padding: 0 var(--space-3-rem);
    border-radius: var(--border-radius-s);
    box-shadow: var(--box-shadow-worfkflowLinkCard);
    display: flex;
    align-items: center;
    margin-top: var(--space-2-rem);
    &:first-of-type {
      margin-top: var(--space-3-rem);
    }
  `
]);
/**
 * Used for old urls coming from WF gallery with eg '/workflow/:id'
 * @returns null
 */
export const WorkflowsRedirectRoutingComponent: React.FC = () => {
  const { currentUser } = useCurrentUser();
  const { hasFeatureFlags } = useFeatureFlags();
  // workflow id passed from website wf gallery
  const workflowId = useMatch<{ workflow_id: string }>(`/${routeData.workflow.pathname}/:workflow_id`)?.workflow_id;

  useEffect(() => {
    if (currentUser && workflowId) {
      const firstTeamSlug = currentUser?.teams[0].slug;
      if (hasFeatureFlags(FeatureFlags.WORKFLOWBUILDER)) {
        // Redirect to worflow builder '/workflows/:slug/:workflowId'
        navigate(`/${routeData.workflows.pathname}/${firstTeamSlug}/${workflowId}`, {
          replace: true
        });
      } else {
        // redirect to '/workflows/:slug/' with workflowId pre-selected
        navigate(`/${routeData.workflows.pathname}/${firstTeamSlug}?selectedWorkflowId=${workflowId}`, {
          replace: true
        });
      }
    }
  }, [currentUser, workflowId, hasFeatureFlags]);

  return null;
};

export const WorkflowsOverviewPage: React.FC<{}> = props => {
  const { currentUser } = useCurrentUser();

  const teamSlug = useMatch<{ team_slug: string }>(`/${routeData.workflows.pathname}/:team_slug`)?.team_slug;
  const selectedTeam = currentUser?.teams.find(t => t.slug === teamSlug) ?? currentUser?.teams[0];
  if (!selectedTeam) return null;

  if (currentUser?.teams.length === 0) {
    return (
      <BaseLayout fullHeight headline="Workflows" subline={null}>
        <EmptyState title="Please join a team to see available workflows for it." variant="info"></EmptyState>
      </BaseLayout>
    );
  }

  return <WorkflowsOverview selectedTeam={selectedTeam} />;
};

const WorkflowsOverview: React.FC<{
  selectedTeam: CurrentUser_currentUser_teams;
}> = ({ selectedTeam }) => {
  const { hasFeatureFlags } = useFeatureFlags();
  const { currentUser } = useCurrentUser();

  // for old UI redirect
  const [selectedWorkflowId] = useUrlState("selectedWorkflowId", "");
  const { data, loading } = useQuery<WorkflowsGetList, WorkflowsGetListVariables>(WORKFLOWS_GET_LIST, {
    fetchPolicy: "network-only",
    variables: {
      teamId: selectedTeam.id
    }
  });

  // for old UI
  const [selectedWorkflow, setSelectedWorkflow] = React.useState<WorkflowsGetList_teamWorkflows | null>(null);

  const workflowsReponse = data?.teamWorkflows ?? ([] as WorkflowsGetList_teamWorkflows[]);

  useEffect(() => {
    if (workflowsReponse.length) {
      const selectedWF = workflowsReponse.find(w => w.id === selectedWorkflowId);
      if (!!selectedWF) {
        // select wf from gallery
        setSelectedWorkflow(selectedWF);
        // clean out url param
        navigate(`/${routeData.workflows.pathname}/${selectedTeam.slug}`, {
          replace: true
        });
      }
    }
  }, [workflowsReponse, selectedWorkflowId, selectedTeam]);

  const workflowsGroupedByStatus = (groupBy(workflowsReponse ?? [], "status") as unknown) as WorkflowsGroupedByStatus;
  // show section headings if more than one section is visible
  const showSectionHeadings =
    Object.values(workflowsGroupedByStatus)
      .map(w => (w ?? []).length)
      .filter(l => l !== 0).length > 1;

  const workflowsEnabled = workflowsReponse.filter(w => w.enabled) ?? [];
  const workflowsDisabled = workflowsReponse.filter(w => !w.enabled && !w.requiresCustomization) ?? [];
  const workflowTemplates = workflowsReponse.filter(w => !w.enabled && w.requiresCustomization) ?? [];

  return (
    <BaseLayout
      fullHeight
      isLoading={loading}
      headline={
        !hasFeatureFlags(FeatureFlags.WORKFLOWBUILDER) ? (
          "Workflows"
        ) : (
          <div
            css={[
              css`
                display: flex;
              `
            ]}
          >
            <Typo.Body sizeXXL>
              Workflows for
              {(currentUser?.teams.length ?? 0) > 1 ? (
                <PopOver.Menu
                  options={currentUser?.teams.map(t => ({ id: t.slug, name: t.name })) ?? []}
                  onSelect={(teamSlug: string) => {
                    navigate(`/${routeData.workflows.pathname}/${teamSlug}`, { replace: true });
                    setSelectedWorkflow(null);
                  }}
                  selected={selectedTeam.slug}
                  trigger={<CleanUnderlineHeadlineButton>{selectedTeam.name}</CleanUnderlineHeadlineButton>}
                />
              ) : (
                ` ${selectedTeam.name}`
              )}
            </Typo.Body>
          </div>
        )
      }
      subline={
        <div
          css={[
            css`
              display: flex;
            `
          ]}
        >
          {!hasFeatureFlags(FeatureFlags.WORKFLOWBUILDER) ? (
            <>
              <Typo.Body>Set up workflows for the team </Typo.Body>
              <PopOver.Menu
                options={currentUser?.teams.map(t => ({ id: t.slug, name: t.name })) ?? []}
                onSelect={(teamSlug: string) => {
                  navigate(`/${routeData.workflows.pathname}/${teamSlug}`, { replace: true });
                  setSelectedWorkflow(null);
                }}
                selected={selectedTeam.slug}
                trigger={
                  <CleanUnderlineButton
                    css={css`
                      margin-left: var(--space-1-rem);
                    `}
                  >
                    {selectedTeam.name}
                  </CleanUnderlineButton>
                }
              />
            </>
          ) : (
            <Typo.Body>Edit and manage workflows to automate tasks and orchestrate processes</Typo.Body>
          )}
        </div>
      }
    >
      <HelmetComponent
        title={`Workflows for ${selectedTeam.name}`}
        description={`All enabled and inactive workflows of the team ${selectedTeam.name}`}
      />
      {!hasFeatureFlags(FeatureFlags.WORKFLOWBUILDER) ? (
        <div
          data-testid="workflow-gallery-loaded"
          css={css`
            width: 100%;
            height: 100%;
            display: flex;
            & > div {
              border-radius: var(--border-radius-l);
              padding: var(--space-4-px);
              width: 50%;
            }

            & > * + * {
              margin-left: var(--space-4-px);
            }
          `}
        >
          <div
            css={[
              css`
                background-color: var(--lightGrey-1);
              `,
              showSectionHeadings &&
                css`
                  & > * + * {
                    margin-top: var(--space-5-px);
                  }
                `
            ]}
          >
            <WorkflowSection
              status={TeamWorkflowStatus.ENABLED}
              team={selectedTeam}
              workflowsGroupedByStatus={workflowsGroupedByStatus}
              setSelectedWorkflow={setSelectedWorkflow}
              selectedWorkflow={selectedWorkflow}
              showSectionHeadings={showSectionHeadings}
            />

            <WorkflowSection
              status={TeamWorkflowStatus.INPROGRESS}
              team={selectedTeam}
              workflowsGroupedByStatus={workflowsGroupedByStatus}
              setSelectedWorkflow={setSelectedWorkflow}
              selectedWorkflow={selectedWorkflow}
              showSectionHeadings={showSectionHeadings}
            />

            <WorkflowSection
              status={TeamWorkflowStatus.DISABLED}
              team={selectedTeam}
              workflowsGroupedByStatus={workflowsGroupedByStatus}
              setSelectedWorkflow={setSelectedWorkflow}
              selectedWorkflow={selectedWorkflow}
              showSectionHeadings={showSectionHeadings}
            />
          </div>
          <div>
            <WorkflowDetail workflow={selectedWorkflow} selectedTeam={selectedTeam} />
          </div>
        </div>
      ) : (
        <div
          data-testid="workflow-gallery-loaded"
          css={css`
            width: 100%;
            height: 100%;
            background-color: var(--lightGrey-1);
            flex-grow: 1;
            & > div {
              border-radius: var(--border-radius-l);
              padding: var(--space-4-px);
            }
            & > * + * {
              margin-top: var(--space-5-px);
            }
          `}
        >
          {workflowsEnabled.length > 0 && (
            <div
              css={css`
                & > * + * {
                  margin-top: var(--space-3-px);
                }
              `}
            >
              <WorkflowSectionHeading status={TeamWorkflowStatus.ENABLED} />
              <div
                css={css`
                  & > * + * {
                    margin-top: var(--space-2-px);
                  }
                `}
              >
                {workflowsEnabled.map(w => (
                  <WorkflowCard workflow={w} team={selectedTeam} />
                ))}
              </div>
            </div>
          )}
          {workflowsDisabled.length > 0 && (
            <div
              css={css`
                & > * + * {
                  margin-top: var(--space-3-px);
                }
              `}
            >
              <WorkflowSectionHeading status={TeamWorkflowStatus.DISABLED} />
              <div
                css={css`
                  & > * + * {
                    margin-top: var(--space-2-px);
                  }
                `}
              >
                {workflowsDisabled.map(w => (
                  <WorkflowCard workflow={w} team={selectedTeam} />
                ))}
              </div>
            </div>
          )}
          {workflowTemplates.length > 0 && (
            <div
              css={css`
                & > * + * {
                  margin-top: var(--space-3-px);
                }
              `}
            >
              <Typo.SectionHeading bold>Templates</Typo.SectionHeading>
              <div
                css={css`
                  & > * + * {
                    margin-top: var(--space-2-px);
                  }
                `}
              >
                {workflowTemplates.map(w => (
                  <WorkflowCard workflow={w} team={selectedTeam} />
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </BaseLayout>
  );
};

// @deprecated
const WorkflowSection: React.FC<{
  status: TeamWorkflowStatus;
  workflowsGroupedByStatus: WorkflowsGroupedByStatus;
  selectedWorkflow: WorkflowsGetList_teamWorkflows | null;
  setSelectedWorkflow: React.Dispatch<React.SetStateAction<WorkflowsGetList_teamWorkflows | null>>;
  showSectionHeadings: boolean;
  team: {
    slug: string;
  };
}> = ({ status, workflowsGroupedByStatus, selectedWorkflow, setSelectedWorkflow, showSectionHeadings, team }) => {
  return !!(workflowsGroupedByStatus[status] ?? []).length ? (
    <div
      css={css`
        & > * + * {
          margin-top: var(--space-3-px);
        }
      `}
    >
      {showSectionHeadings && <WorkflowSectionHeading status={status} />}
      <div
        css={css`
          & > * + * {
            margin-top: var(--space-2-px);
          }
        `}
      >
        {(workflowsGroupedByStatus[status] ?? []).map(workflow => {
          return (
            <Card
              sizeM
              key={workflow.id}
              onClick={() => setSelectedWorkflow(workflow)}
              hasInteractions
              selected={selectedWorkflow?.id === workflow.id}
              css={css`
                display: flex;
                align-items: center;

                & > * + * {
                  margin-left: var(--space-3-px);
                }
              `}
            >
              <GenericSvgFromString
                css={css`
                  background: var(${getStatusColorVar(status)});
                  height: 32px;
                  min-width: 32px;
                  width: 32px;
                  border-radius: var(--border-radius-s);
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  & > svg path {
                    fill: var(${getStatusIconFillColor(status)});
                  }
                `}
                svg={workflow.icon}
              />
              <div
                css={css`
                  flex-grow: 2;
                  overflow: auto; // triggers a width calculation thus making flex-grow and ellipsis work properly
                `}
              >
                <Typo.Body medium ellipsis>
                  {workflow.name}
                </Typo.Body>
                <Typo.Body ellipsis>{workflow.description}</Typo.Body>
              </div>
              <MaterialIcon path={mdiChevronRight} size={1.125} />
            </Card>
          );
        })}
      </div>
    </div>
  ) : null;
};

export const WorkflowCard: React.FC<{
  team: {
    slug: string;
  };
  workflow: {
    id: string;
    name: string;
    description: string;
    enabled: boolean;
    icon: string;
    lastAuthor: null | {
      name: string;
    };
    createTime: string | null;
    lastCompletedRunTime: string | null;
    lastCompletedRunStatus: WorkflowRunState;
  };
}> = props => {
  return (
    <Link
      to={`/${routeData.workflows.pathname}/${props.team.slug}/${props.workflow.id}`}
      css={css`
        display: block;
      `}
    >
      <Card
        sizeM
        hasInteractions
        css={css`
          display: flex;
          align-items: center;

          & > * + * {
            margin-left: var(--space-3-px);
          }
        `}
      >
        <GenericSvgFromString
          css={css`
            background: var(
              ${getStatusColorVar(props.workflow.enabled ? TeamWorkflowStatus.ENABLED : TeamWorkflowStatus.DISABLED)}
            );
            height: 32px;
            min-width: 32px;
            width: 32px;
            border-radius: var(--border-radius-s);
            display: flex;
            align-items: center;
            justify-content: center;

            & > svg path {
              fill: var(
                ${getStatusIconFillColor(
                  props.workflow.enabled ? TeamWorkflowStatus.ENABLED : TeamWorkflowStatus.DISABLED
                )}
              );
            }
          `}
          svg={props.workflow.icon}
        />
        <div
          css={css`
            flex-grow: 2;
            overflow: auto; // triggers a width calculation thus making flex-grow and ellipsis work properly
          `}
        >
          <Typo.Body medium ellipsis>
            {props.workflow.name}
          </Typo.Body>
          <div
            css={[
              css`
                margin-top: var(--space-1-px);
                & > p {
                  max-height: 1.2rem;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
              `
            ]}
            dangerouslySetInnerHTML={{
              __html:
                // sanitized
                backMarkdownToHtml(!!props.workflow.description.length ? props.workflow.description : "No description")
            }}
          />
          {props.workflow.enabled && (
            <div
              css={[
                css`
                  margin-top: var(--space-2-px);
                  display: flex;
                  & > * + * {
                    margin-left: var(--space-5-px);
                  }
                `
              ]}
            >
              {props.workflow.lastAuthor && (
                <Typo.Body sizeS ellipsis light>
                  <b>Last updated by: </b>
                  {props.workflow.lastAuthor.name}
                </Typo.Body>
              )}
              {props.workflow.createTime && (
                <Typo.Body sizeS ellipsis light>
                  <b>Last published: </b>
                  <DateTime timestamp={props.workflow.createTime} />
                </Typo.Body>
              )}
              {props.workflow.lastCompletedRunTime &&
                (props.workflow.lastCompletedRunStatus === WorkflowRunState.RUN_STATE_SUCCEEDED ||
                  props.workflow.lastCompletedRunStatus === WorkflowRunState.RUN_STATE_FAILED) && (
                  <Typo.Body
                    sizeS
                    ellipsis
                    light
                    css={css`
                      & > * + * {
                        margin-left: var(--space-1-rem);
                      }
                    `}
                  >
                    <b>Last completed run: </b>
                    {props.workflow.lastCompletedRunStatus === WorkflowRunState.RUN_STATE_SUCCEEDED && (
                      <Icon
                        sizeS
                        css={[
                          css`
                            display: inline-block;
                            vertical-align: text-top;
                            color: var(--green-6);
                          `
                        ]}
                      >
                        <CheckCircle />
                      </Icon>
                    )}
                    {props.workflow.lastCompletedRunStatus === WorkflowRunState.RUN_STATE_FAILED && (
                      <Icon
                        sizeS
                        css={[
                          css`
                            display: inline-block;
                            vertical-align: text-top;
                            color: var(--red-6);
                          `
                        ]}
                      >
                        <WarningCircle />
                      </Icon>
                    )}
                    <DateTime timestamp={props.workflow.lastCompletedRunTime} />
                  </Typo.Body>
                )}
            </div>
          )}
        </div>
        <MaterialIcon path={mdiChevronRight} size={1.125} />
      </Card>
    </Link>
  );
};

export const WorkflowStatusIcon: React.FC<{ status: TeamWorkflowStatus }> = ({ status }) => (
  <span
    css={css`
      height: 10px;
      width: 10px;
      flex-shrink: 0;
      background-color: var(${getStatusColorVar(status)});
      border-radius: var(--border-radius-round);
    `}
  />
);

const WorkflowSectionHeading: React.FC<{ status: TeamWorkflowStatus }> = ({ status }) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;

        & > * + * {
          margin-left: var(--space-2-px);
        }
      `}
    >
      <WorkflowStatusIcon status={status} />
      <Typo.SectionHeading bold>{getStatusText(status)}</Typo.SectionHeading>
    </div>
  );
};

const WorkflowDetail: React.FC<{
  workflow: WorkflowsGetList_teamWorkflows | null;
  selectedTeam: {
    id: string;
    slug: string;
    name: string;
  };
}> = ({ workflow, selectedTeam }) => {
  const { currentUser } = useCurrentUser();
  const { emitSnack } = useSnack();

  const [typeformModalIsShown, setTypeformModalIsShown] = React.useState<boolean>(false);
  const [sharingTypeformModalIsShown, setSharingTypeformModalIsShown] = React.useState<boolean>(false);
  const [disableDialogIsShown, setDisableDialogIsShown] = React.useState<boolean>(false);
  const [addWorkflowToTeam] = useMutation<AddWorkflowToTeam, AddWorkflowToTeamVariables>(
    gql`
      mutation AddWorkflowToTeam($teamId: ID!, $workflowId: ID!) {
        workflowAddToTeam(teamId: $teamId, workflowId: $workflowId) {
          code
          success
          message
        }
      }
    `,
    {
      refetchQueries: [{ query: WORKFLOWS_GET_LIST }],
      awaitRefetchQueries: true
    }
  );
  const [removeWorkflowFromTeam, removeWorkflowFromTeamResponse] = useMutation<
    RemoveWorkflowFromTeam,
    RemoveWorkflowFromTeamVariables
  >(
    gql`
      mutation RemoveWorkflowFromTeam($teamId: ID!, $workflowId: ID!) {
        workflowRemoveFromTeam(teamId: $teamId, workflowId: $workflowId) {
          code
          success
          message
        }
      }
    `,
    {
      refetchQueries: [{ query: WORKFLOWS_GET_LIST }],
      awaitRefetchQueries: true
    }
  );

  if (!workflow) {
    return (
      <EmptyState
        css={css`
          height: 100%;
        `}
        title="No workflow selected"
        subtitle="Select a workflow from the list to see what it does and activate it"
        variant="info"
      />
    );
  }

  return (
    <>
      <TypeformModal
        isShown={typeformModalIsShown}
        onTypeformSubmit={() => {
          if (workflow.status === TeamWorkflowStatus.ENABLED) {
            // remove workflow and then add it again to "change" it
            return removeWorkflowFromTeam({
              variables: {
                teamId: selectedTeam.id,
                workflowId: workflow?.id // this should always be non-null as there's no way via the UI to trigger this without a selected workflow
              },
              update(_cache, response) {
                if (response.data?.workflowRemoveFromTeam.success) {
                  addWorkflowToTeam({
                    variables: {
                      teamId: selectedTeam.id,
                      workflowId: workflow?.id // this should always be non-null as there's no way via the UI to trigger this without a selected workflow
                    },
                    update(_cache, response) {
                      if (response.data?.workflowAddToTeam.success) {
                        emitSnack({
                          message: `Workflow ${workflow?.name ?? ""} settings changed`,
                          type: "info"
                        });
                      } else {
                        emitSnack({
                          message:
                            response.data?.workflowAddToTeam?.message ??
                            `Error changing workflow ${workflow?.name ?? ""}`,
                          type: "mutationError"
                        });
                      }
                      setTypeformModalIsShown(false);
                    }
                  });
                } else {
                  emitSnack({
                    message:
                      response.data?.workflowRemoveFromTeam?.message ??
                      `Error changing workflow ${workflow?.name ?? ""}`,
                    type: "mutationError"
                  });
                }
                setTypeformModalIsShown(false);
              }
            });
          }

          if (workflow.status === TeamWorkflowStatus.DISABLED) {
            // add workflow to "enable" it i.e., move it to "processing"
            return addWorkflowToTeam({
              variables: {
                teamId: selectedTeam.id,
                workflowId: workflow?.id // this should always be non-null as there's no way via the UI to trigger this without a selected workflow
              },
              update(_cache, response) {
                if (response.data?.workflowAddToTeam.success) {
                  emitSnack({
                    message: `Request to enable workflow ${workflow?.name ?? ""} submitted`,
                    type: "info"
                  });
                } else {
                  emitSnack({
                    message:
                      response.data?.workflowAddToTeam?.message ?? `Error enabling workflow ${workflow?.name ?? ""}`,
                    type: "mutationError"
                  });
                }
                setTypeformModalIsShown(false);
              }
            });
          }
          // unreachable state via UI interactions in MVP but here for completeness
          setTypeformModalIsShown(false);
        }}
        onClose={() => {
          // track users that drop out of successfully enabling a workflow
          if (workflow.status === TeamWorkflowStatus.DISABLED) {
            trackWorkflowConfigNotCompleted(workflow, selectedTeam, currentUser);
          }
          setTypeformModalIsShown(false);
        }}
        title={`Configure ${workflow.name}`}
        formId={workflow.typeformId}
        hiddenFields={{
          teamid: selectedTeam.id,
          teamslug: selectedTeam.slug,
          orgid: currentUser?.organization.id ?? "",
          orgslug: currentUser?.organization.slug ?? "",
          userid: currentUser?.id ?? "",
          workflowid: workflow.id,
          workflowname: workflow.name
        }}
      />
      <ConfirmDialogModal
        isOpen={disableDialogIsShown}
        medium
        text={{
          cancel: "Cancel",
          heading: `Disable workflow ${workflow.name}`,
          confirm: "Disable workflow"
        }}
        handleCancel={() => setDisableDialogIsShown(false)}
        submittingConfirm={removeWorkflowFromTeamResponse.loading}
        handleConfirm={() => {
          return removeWorkflowFromTeam({
            variables: {
              teamId: selectedTeam.id,
              workflowId: workflow?.id // this should always be non-null as there's no way via the UI to trigger this without a selected workflow
            },
            update(_cache, response) {
              if (response.data?.workflowRemoveFromTeam.success) {
                emitSnack({
                  message: `Workflow ${workflow?.name ?? ""} disabled`,
                  type: "info"
                });
              } else {
                emitSnack({
                  message:
                    response.data?.workflowRemoveFromTeam?.message ??
                    `Error disabling workflow ${workflow?.name ?? ""}`,
                  type: "mutationError"
                });
              }
              setDisableDialogIsShown(false);
            }
          });
        }}
      >
        <Typo.Body>Are you sure you want to disable this workflow?</Typo.Body>
      </ConfirmDialogModal>
      <Col
        css={css`
          & > * + * {
            margin-top: var(--space-6-px);
          }
        `}
      >
        <Row>
          <Col
            css={css`
              flex-shrink: 1;

              & > * + * {
                margin-top: var(--space-5-px);
              }
            `}
          >
            <Col
              css={css`
                & > * + * {
                  margin-top: var(--space-1-px);
                }
              `}
            >
              <Row
                css={css`
                  align-items: center;
                  & > * + * {
                    margin-left: var(--space-2-px);
                  }
                `}
              >
                <Typo.Body sizeXL>{workflow.name}</Typo.Body>{" "}
                <Badge
                  success={workflow.status === TeamWorkflowStatus.ENABLED}
                  neutral={workflow.status === TeamWorkflowStatus.DISABLED}
                  unread={workflow.status === TeamWorkflowStatus.INPROGRESS}
                >
                  {getStatusText(workflow.status)}
                </Badge>
              </Row>
              <Row>
                <Typo.Body>{workflow.description}</Typo.Body>
              </Row>
            </Col>
            <Row>
              {workflow.status === TeamWorkflowStatus.DISABLED && (
                <Button
                  variant="primary"
                  onClick={() => setTypeformModalIsShown(true)}
                  css={css`
                    & > * + * {
                      margin-left: var(--space-2-px);
                    }
                  `}
                >
                  <MaterialIcon path={mdiPowerSettings} size={1.125} />
                  <span>Enable workflow</span>
                </Button>
              )}
              {workflow.status === TeamWorkflowStatus.ENABLED && (
                <>
                  <Button
                    variant="secondary"
                    onClick={() => setDisableDialogIsShown(true)}
                    css={css`
                      & > * + * {
                        margin-left: var(--space-2-px);
                      }
                    `}
                  >
                    <MaterialIcon path={mdiPowerSettings} size={1.125} />
                    <span>Disable workflow</span>
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => setTypeformModalIsShown(true)}
                    css={css`
                      margin-left: var(--space-1-px);

                      & > * + * {
                        margin-left: var(--space-2-px);
                      }
                    `}
                  >
                    <MaterialIcon path={mdiSettings} size={1.125} />
                    <span>Change settings</span>
                  </Button>
                </>
              )}
              {workflow.status === TeamWorkflowStatus.INPROGRESS && (
                <InfoBanner>
                  <MaterialIcon path={mdiInformation} size={1.125} />
                  <p>
                    This workflow is currently being processed. Once ready, you shall see its status change to enabled.
                  </p>
                </InfoBanner>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col
            css={css`
              flex-shrink: 1;
            `}
          >
            <Typo.Body bold>Triggers</Typo.Body>
            <Row
              css={css`
                margin-top: var(--space-1-px);

                & > * + * {
                  margin-left: var(--space-1-px);
                }
              `}
            >
              <MaterialIcon path={mdiGestureTap} size={1.125} />
              <div
                dangerouslySetInnerHTML={{ __html: markdownWithEmbeddedHtmlToHtml(workflow.triggersDescription) }}
              ></div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col
            css={css`
              flex-shrink: 1;
              & > * + * {
                margin-top: var(--space-1-px);
              }
            `}
          >
            <Typo.Body bold>Actions</Typo.Body>
            <div dangerouslySetInnerHTML={{ __html: markdownWithEmbeddedHtmlToHtml(workflow.stepsDescription) }}></div>
          </Col>
        </Row>
        {(!!workflow.linkedEntities.forms.length || !!workflow.linkedEntities.documents.length) && (
          <div>
            <Typo.Body bold>Components</Typo.Body>
            {workflow.linkedEntities.forms.map(form => (
              <Link to="/forms" title="Go to forms page" key={form.id}>
                <ComponentCard>
                  <BestPracticeComponent icon={<HelpBubble />} title={form.displayName} type="Form" />
                </ComponentCard>
              </Link>
            ))}
            {workflow.linkedEntities.documents.map(document => (
              <Link to="/knowledge" title="Go to knowledge page" key={document.id}>
                <ComponentCard>
                  <BestPracticeComponent icon={<Document />} title={document.title} type="Document" />
                </ComponentCard>
              </Link>
            ))}
          </div>
        )}
        <BestPracticeSharing
          onClick={() => {
            trackBestPracticeSharingIntent({ workflow_id: workflow.id });
            setSharingTypeformModalIsShown(true);
          }}
        />
      </Col>
      <TypeformModal
        isShown={sharingTypeformModalIsShown}
        onClose={() => setSharingTypeformModalIsShown(false)}
        title={`Share the process "${workflow.name}"`}
        hiddenFields={{
          type: "policy",
          organization_name: currentUser?.organization.name ?? "",
          workflow_id: workflow.id,
          user_id: currentUser?.id ?? ""
        }}
        formId="l99KV5ff"
      />
    </>
  );
};

export function getStatusText(status: TeamWorkflowStatus): string {
  switch (status) {
    case TeamWorkflowStatus.ENABLED:
      return "Enabled";
    case TeamWorkflowStatus.DISABLED:
      return "Disabled";
    case TeamWorkflowStatus.INPROGRESS:
      return "Processing";
    default:
      return "Unknown status";
  }
}

function getStatusColorVar(status: TeamWorkflowStatus): string {
  switch (status) {
    case TeamWorkflowStatus.ENABLED:
      return "--green-6";
    case TeamWorkflowStatus.INPROGRESS:
      return "--yellow-6";
    case TeamWorkflowStatus.DISABLED:
      return "--lightGrey-4";
    default:
      return "--lightGrey-4";
  }
}

function getStatusIconFillColor(status: TeamWorkflowStatus): string {
  switch (status) {
    case TeamWorkflowStatus.ENABLED:
      return "--green-8";
    case TeamWorkflowStatus.INPROGRESS:
      return "--yellow-8";
    case TeamWorkflowStatus.DISABLED:
      return "--text-4";
    default:
      return "--text-4";
  }
}
