import { css } from "@emotion/core";
import { isString } from "lodash";
import * as React from "react";
import { FC, PropsWithChildren, ReactNode } from "react";
import { teamInsightsSection } from "src/App/Dashboard/Container";
import { ReactComponent as ArrowDown } from "src/assets/arrows/ArrowDown.svg";
import { ReactComponent as ArrowStraight } from "src/assets/arrows/ArrowStraight.svg";
import { ReactComponent as ArrowUp } from "src/assets/arrows/ArrowUp.svg";
import { PopOver } from "src/components/PopOver";
import { slideInLeftRight } from "src/styling/animations/jsTokens";
import { Typo } from "src/styling/primitives/typography";
import { trackRequestOpenedFromTeamInsights } from "src/util/analytics";
import { csx } from "src/util/csx";

interface BarChartItem {
  label: React.ReactNode; // label as string or for the status with icon
  maxCount: number;
  count: number;
  subCount?: number;
  blue?: true;
  grey?: true;
  title?: string;
}

export const BarChartWithLabel: React.FC<BarChartItem> = ({
  label,
  maxCount,
  count,
  subCount = 0,
  blue = false,
  grey = false,
  title
}) => (
  <div
    css={css`
      display: flex;
      align-items: top;
    `}
  >
    <div
      title={title}
      css={css`
        width: 9rem;
      `}
    >
      {isString(label) ? <Typo.Body ellipsis>{label}</Typo.Body> : label}
    </div>
    <div
      css={css`
        flex: 1 0 auto;
        display: flex;
        align-items: center;
        margin-left: var(--space-3-rem);
        overflow: hidden;
        position: relative;
      `}
    >
      <div
        css={css`
          width: 100%;
          overflow: hidden;
        `}
      >
        <div
          css={css`
            width: calc(((100% - 3rem) / ${maxCount}) * (${count} + ${subCount}));
            padding-right: 3rem;
            position: relative;
          ${slideInLeftRight}
            /* animate the value with the moving bars */
            &::after {
              white-space: nowrap;
              position: absolute;
              right: 0;
              top: 2px;
              width: var(--space-7-rem);
              content: "${!!subCount ? count + " / " + subCount : count}";
            }
          `}
        >
          <div
            css={css`
              overflow: hidden;
              border-radius: var(--border-radius-s);
              display: flex;
            `}
          >
            <Bar
              css={css`
                width: ${(100 / (count + subCount)) * count}%;
              `}
              blue={blue}
              grey={grey}
            />
            {!!subCount && (
              <Bar
                lightBlue={blue}
                css={css`
                  width: ${(100 / (count + subCount)) * subCount}%;
                `}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Bar = csx(
  [
    css`
      min-width: var(--space-1-px);
      height: var(--space-5-rem);
    `
  ],
  {
    blue: css`
      background-color: var(--lightBlue-4);
    `,
    lightBlue: css`
      background-color: var(--lightBlue-2);
    `,
    grey: css`
      background-color: var(--text-5);
    `
  }
);

const ColorIndicator = csx(
  [
    css`
      width: var(--space-4-rem);
      height: var(--space-4-rem);
      border-radius: var(--border-radius-s);
      margin-right: var(--space-2-rem);
    `
  ],
  {
    blue: css`
      background-color: var(--lightBlue-4);
    `,
    lightBlue: css`
      background-color: var(--lightBlue-2);
    `,
    darkGreen: css`
      background-color: var(--green-6);
    `,
    green: css`
      background-color: var(--green-4);
    `,
    lightGreen: css`
      background-color: var(--green-2);
    `
  }
);

export const LegendItem: React.FC<PropsWithChildren<{
  blue?: boolean;
  lightBlue?: boolean;
  darkGreen?: boolean;
  green?: boolean;
  lightGreen?: boolean;
}>> = ({ blue = false, lightBlue = false, darkGreen = false, green = false, lightGreen = false, children }) => (
  <div
    css={css`
      display: flex;
      align-items: center;
    `}
  >
    <ColorIndicator blue={blue} lightBlue={lightBlue} darkGreen={darkGreen} green={green} lightGreen={lightGreen} />
    {children}
  </div>
);

type RequestLinkType = { id: string; title: string };
export const RequestLink: React.FC<RequestLinkType & { onClick?(): void; noMaxWidth?: boolean }> = ({
  id,
  title,
  onClick,
  noMaxWidth
}) => {
  return (
    <Typo.ExternalLink
      css={[
        css`
          padding: 0;
          display: inline-block;
          max-width: 12rem;
          vertical-align: middle;
        `,
        noMaxWidth &&
          css`
            max-width: none;
          `
      ]}
      ellipsis
      medium
      href={`/request/${id}`}
      onClick={onClick}
    >
      {title}
    </Typo.ExternalLink>
  );
};

export const LinkListOrPopOver: React.FC<{
  text1: string;
  text2?: string;
  teamInsightsSection: teamInsightsSection;
  items: Array<RequestLinkType>;
}> = ({ text1, text2, teamInsightsSection, items }) => {
  if (!items.length)
    return (
      <>
        {text1} 0 requests {text2}
      </>
    );
  // for 5 and more requests show pop over with links
  if (items.length > 4) {
    return (
      <>
        {text1}
        <PopOver.Menu
          options={items.map(i => ({
            id: i.id,
            name: i.title,
            linkAttrs: {
              href: `/request/${i.id}`
            },
            onClick: id => trackRequestOpenedFromTeamInsights(teamInsightsSection)
          }))}
          trigger={<Typo.TextLink medium>{items.length} Requests</Typo.TextLink>}
        />
        {text2}
      </>
    );
  } else {
    // for up to 4 requests render a semantic link list
    const requests = [...items];
    const lastRequest = requests.pop();
    return (
      <>
        {text1} {items.length} request{items.length > 1 && "s"}
        {!!text2 && " " + text2}:{" "}
        {requests.length ? (
          <>
            {requests.map((request, idx) => {
              return (
                <React.Fragment key={request.id}>
                  <RequestLink {...request} onClick={() => trackRequestOpenedFromTeamInsights(teamInsightsSection)} />
                  {idx < requests.length - 1 && ", "}
                </React.Fragment>
              );
            })}{" "}
            and{" "}
            <RequestLink
              {...(lastRequest as RequestLinkType)}
              onClick={() => trackRequestOpenedFromTeamInsights(teamInsightsSection)}
            />
          </>
        ) : (
          <RequestLink
            {...(lastRequest as RequestLinkType)}
            onClick={() => trackRequestOpenedFromTeamInsights(teamInsightsSection)}
          />
        )}
      </>
    );
  }
};

const calcIncreaseDecreasePercentage = (numerator: number, denominator: number) =>
  Math.floor((numerator / denominator) * 100 - 100);

export const HistoricalDevelopemnt: FC<{
  label: ReactNode;
  currentValueMinutes: number;
  pastValueMinutes: number;
}> = ({ label, currentValueMinutes, pastValueMinutes }) => {
  const noData = !currentValueMinutes && !pastValueMinutes;
  if (noData) {
    return (
      <div>
        <div
          css={css`
            display: flex;
            align-items: flex-end;
            padding-bottom: var(--space-1-rem);
          `}
        >
          <Typo.Body
            sizeXL
            medium
            css={css`
              line-height: 22px;
            `}
          >
            -
          </Typo.Body>
        </div>
        {isString(label) ? <Typo.Body ellipsis>{label}</Typo.Body> : label}
      </div>
    );
  } else if (!currentValueMinutes) {
    return (
      <div>
        <div
          css={css`
            display: flex;
            align-items: flex-end;
            padding-bottom: var(--space-1-rem);
          `}
        >
          <Typo.Body
            sizeXL
            medium
            css={css`
              line-height: 22px;
            `}
          >
            N/A
          </Typo.Body>
          <Typo.Body
            sizeS
            medium
            css={css`
              padding: 0 var(--space-1-rem);
              & > *:first-child {
                padding-right: 2px;
              }
            `}
          >
            -- %
          </Typo.Body>
        </div>
        {isString(label) ? <Typo.Body ellipsis>{label}</Typo.Body> : label}
      </div>
    );
  } else {
    const noPastData = !pastValueMinutes;
    const developmentPercentage = calcIncreaseDecreasePercentage(pastValueMinutes, currentValueMinutes);

    const percentageSign =
      developmentPercentage === null
        ? null
        : Math.sign(developmentPercentage) === -1
        ? "negative"
        : Math.sign(developmentPercentage) === 1
        ? "positive"
        : "neutral";

    return (
      <div>
        <div
          css={css`
            display: flex;
            align-items: flex-end;
            padding-bottom: var(--space-1-rem);
          `}
        >
          <Typo.Body
            sizeXL
            medium
            css={css`
              line-height: 22px;
            `}
          >
            {noData
              ? "-"
              : currentValueMinutes < 60
              ? Math.floor(currentValueMinutes) + "m"
              : Math.floor(currentValueMinutes / 60) + "h"}
          </Typo.Body>
          {percentageSign === "negative" && (
            <Typo.Body
              sizeS
              medium
              css={[
                css`
                  padding: 0 var(--space-1-rem);
                  & > *:first-child {
                    padding-right: 2px;
                  }
                `,
                !noPastData &&
                  css`
                    color: var(--green-7);
                  `
              ]}
            >
              {!noPastData && <ArrowDown />}
              {noPastData ? "-" : Math.abs(developmentPercentage) + "%"}
            </Typo.Body>
          )}
          {percentageSign === "positive" && (
            <Typo.Body
              sizeS
              medium
              css={[
                css`
                  padding: 0 var(--space-1-rem);
                  & > *:first-child {
                    margin-right: 2px;
                  }
                `,
                !noPastData &&
                  css`
                    color: var(--red-7);
                  `
              ]}
            >
              {!noPastData && <ArrowUp />}
              {noPastData ? "-" : developmentPercentage + "%"}
            </Typo.Body>
          )}
          {percentageSign === "neutral" && (
            <Typo.Body
              sizeS
              medium
              css={css`
                padding: 0 var(--space-1-rem);
                color: var(--text-3);
                & > *:first-child {
                  padding-right: 2px;
                }
              `}
            >
              {!noPastData && <ArrowStraight />}
              {noPastData ? "-" : developmentPercentage + "%"}
            </Typo.Body>
          )}
        </div>
        {isString(label) ? <Typo.Body ellipsis>{label}</Typo.Body> : label}
      </div>
    );
  }
};
