import { css } from "@emotion/core";
import { isEqual, pick } from "lodash";
import * as React from "react";
import { ReactNode } from "react";
import { Col, DateTime, Row, SimpleTooltip } from "src/components";
import { stackOrder } from "src/styling/layout";
import { Typo } from "src/styling/primitives/typography";
import { csx } from "src/util/csx";

const Update = csx([
  css`
    margin-bottom: var(--space-6-rem);
    &::before {
      content: "";
      z-index: ${stackOrder.default};
      display: block;
      height: 1px;
      width: 100%;
      background-color: var(--border);
      transform: translateY(var(--space-3-rem));
    }
  `
]);

export interface IUpdateComponent {
  authorId: string;
  requesterId: string;
  children?: ReactNode;
  timestamp: ScalarDateTime;
  tooltip?: JSX.Element;
}
export const UpdateComponent = React.memo(
  (props: IUpdateComponent) => {
    const EventComponent = (
      <Col
        css={css`
          background: var(--white);
          z-index: var(--z-lowest);
          padding: 0 var(--space-4-rem);
          & > * + * {
            margin-top: var(--space-1-rem);
          }
        `}
      >
        <div data-testid="request-timeline-update">{props.children}</div>
        <Row data-testid="request-comment-info" justify="center">
          <Typo.Body light data-testid="request-comment-info">
            <DateTime timestamp={props.timestamp} tooltip={!props.tooltip} />
          </Typo.Body>
        </Row>
      </Col>
    );

    return (
      <Update>
        <div
          css={css`
            display: flex;
            justify-content: center;
            & > *:first-child {
              /*  needed if there is for example a tooltip or popper inside */
              z-index: var(--z-lowest);
            }
          `}
        >
          {props.tooltip ? (
            <SimpleTooltip label={props.tooltip} maxWidth="auto">
              {EventComponent}
            </SimpleTooltip>
          ) : (
            EventComponent
          )}
        </div>
      </Update>
    );
  },
  (prevProps, nextProps) => {
    const prev = pick(prevProps, "authorId", "timestamp");
    const next = pick(nextProps, "authorId", "timestamp");
    return isEqual(prev, next);
  }
);
