import * as React from "react";

import { BaseLayout, Button, SimpleTooltip } from "src/components";
import { FeatureFlags, useFeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import { ExternalDocumentSourceKind, IntegrationHRISKind, IntegrationSSOStatus } from "src/globalTypes";
import { MSTeamsInfoModal, MSTeamsInstallModal } from "./MSTeams/Modal";
import { NotionConfirmationModal, NotionInstallModal } from "src/App/Settings/Integrations/Notion/Modal";
import { ReactNode, useState } from "react";
import { SlackConfirmationModal, SlackInstallModal, initiateSlackInstall } from "./Slack/Modals";
import { useMutation, useQuery, MutationTuple } from "react-apollo";

import { Badge } from "src/components/Badges";
import { BambooHRInstallModal } from "./BambooHR/Modal";
import BambooSVG from "src/assets/logos/integrations/BambooHR.svg";
import { ConfigureSSO } from "./typings/ConfigureSSO";
import { ConfluenceInstallModal } from "./Confluence/Modal";
import ConfluenceSVG from "src/assets/logos/integrations/Confluence.svg";
import EmailSVG from "src/assets/logos/integrations/Email.svg";
import { EnableSSOModal } from "./SSO/Modal";
import { GChatInstallModal } from "./GChat/Modal";
import GoogleChatSVG from "src/assets/logos/integrations/GoogleChat.svg";
import { HiBobInstallModal } from "./HiBob/Modal";
import HiBobSVG from "src/assets/logos/integrations/hibob.svg";
import WorkdaySVG from "src/assets/logos/integrations/workday.svg";
import { MergeInstallModal } from "./Merge/Modal";
import { IntegrationDeleteHRIS } from "./typings/IntegrationDeleteHRIS";
import {
  IntegrationSyncExternalDocumentSource,
  IntegrationSyncExternalDocumentSourceVariables
} from "./typings/IntegrationSyncExternalDocumentSource";
import {
  IntegrationDeleteExternalDocumentSource,
  IntegrationDeleteExternalDocumentSourceVariables
} from "./typings/IntegrationDeleteExternalDocumentSource";
import { IntegrationDeleteSSO } from "src/App/Settings/Integrations/typings/IntegrationDeleteSSO";
import { IntegrationDeleteOkta } from "src/App/Settings/Integrations/typings/IntegrationDeleteOkta";
import { IntegrationsListGet, IntegrationsListGet_externalDocumentSources } from "./typings/IntegrationsListGet";
import JiraSVG from "src/assets/logos/integrations/Jira.svg";
import MSTeamsSVG from "src/assets/logos/integrations/MSTeams.svg";
import { Note } from "src/components/Notes";
import NotionSVG from "src/assets/logos/integrations/Notion.svg";
import { PersonioInstallModal } from "./Personio/Modal";
import PersonioSVG from "src/assets/logos/integrations/Personio.svg";
import SSOSVG from "src/assets/logos/integrations/SSO.svg";
import { ShowMorePopMenu } from "src/components/PopOver/ShowMorePopMenu";
import SlackSVG from "src/assets/logos/integrations/Slack.svg";
import { Table } from "src/components/Table";
import { Toast } from "src/portals/Toast";
import { Typo } from "src/styling/primitives/typography";
import { css } from "@emotion/core";
import { csx } from "src/util/csx";
import { formatLongDate } from "src/util/formatters";
import gql from "graphql-tag";
import { parse } from "qs";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { OktaInstallModal } from "./Okta/Modal";
import OktaSVG from "src/assets/logos/integrations/OktaSquare.svg";
import {
  InstallModal as Microsoft365InstallModal,
  InstalledModal as Microsoft365InstalledModal
} from "./Microsoft365/Modal";
import SharepointSVG from "src/assets/logos/integrations/Sharepoint.svg";

/**
 * Integrations
 */
export const INTEGRATIONS_LIST_GET = gql`
  query IntegrationsListGet {
    integrationHRIS {
      id
      kind
      lastSyncTime
      lastSuccessfulSyncTime
      lastSyncErrorMessage
    }
    integrationSSO {
      createTime
      syncTime
      status
      oauthProvider
    }
    integrationMSTeams {
      teamsList {
        teamId
        name
        createTime
      }
    }
    integrationJira {
      linkedProjects {
        createTime
      }
    }
    externalDocumentSources {
      id
      kind
      lastSyncTime
      lastSuccessfulSyncTime
      lastSyncErrorMessage
    }
    integrationOkta {
      domain
    }
  }
`;

const INTEGRATION_DELETE_HRIS = gql`
  mutation IntegrationDeleteHRIS {
    integrationDeleteHRIS {
      code
      message
      success
    }
  }
`;

const INTEGRATION_SYNC_EXTERNAL_DOCUMENT_SOURCE = gql`
  mutation IntegrationSyncExternalDocumentSource($id: ID!) {
    externalDocumentSourceSync(sourceId: $id) {
      code
      message
      success
    }
  }
`;

const INTEGRATION_DELETE_EXTERNAL_DOCUMENT_SOURCE = gql`
  mutation IntegrationDeleteExternalDocumentSource($id: ID!) {
    externalDocumentSourceDelete(sourceId: $id) {
      code
      message
      success
    }
  }
`;

const INTEGRATION_DELETE_SSO = gql`
  mutation IntegrationDeleteSSO {
    integrationDeleteSSO {
      code
      message
      success
    }
  }
`;

const INTEGRATION_DELETE_OKTA = gql`
  mutation IntegrationDeleteOkta {
    integrationDeleteOkta {
      code
      message
      success
    }
  }
`;

const Logo = csx(
  [
    css`
      vertical-align: middle;
      width: 100%;
    `
  ],
  {},
  "img"
);

const IntegrationStatus: React.FC<{
  active?: boolean;
  connected?: boolean;
  text?: string;
  lastSync?: string | null;
  lastSuccessfulSync?: string | null;
  lastSyncErrorMessage?: string;
}> = props => {
  if (!props.active) {
    if (!!props.text) {
      return <Badge info>{props.text}</Badge>;
    }
    return <Badge unread>Coming soon</Badge>;
  }
  if (!props.connected) {
    return <Badge neutral>Not connected</Badge>;
  }
  const lastSyncDate = props.lastSuccessfulSync || props.lastSync;

  const isSyncFailing = !!props.lastSyncErrorMessage;

  if (!lastSyncDate) {
    return <Badge success>Connected</Badge>;
  }

  if (isSyncFailing) {
    const tooltip = `Error during last sync: "${props.lastSyncErrorMessage}". Last successful sync on ${formatLongDate(
      new Date(lastSyncDate)
    )}`;
    return (
      <SimpleTooltip label={tooltip}>
        <Badge warning>Failing</Badge>
      </SimpleTooltip>
    );
  }

  const tooltip = `Last successful sync on ${formatLongDate(new Date(lastSyncDate))}`;
  return (
    <SimpleTooltip label={tooltip}>
      <Badge success>Connected</Badge>
    </SimpleTooltip>
  );
};

type IntegrationRowProps = {
  logo: string;
  title: string;
  description: string;
  badge?: ReactNode;
  interactionElement?: ReactNode;
};

const IntegrationRow: React.FC<IntegrationRowProps> = props => (
  <tr>
    <td
      css={css`
        vertical-align: middle;
        width: 2.5rem;
      `}
    >
      <Logo src={props.logo} alt={props.title} />
    </td>
    <td
      css={css`
        width: 8rem;
        white-space: normal;
      `}
    >
      <Typo.Body medium>{props.title}</Typo.Body>
    </td>
    <td
      css={css`
        width: auto;
        overflow: auto;
        white-space: normal;
        line-height: var(--line-height-normal);
        padding-right: var(--space-6-rem);
      `}
    >
      {props.description}
    </td>
    <td
      css={css`
        width: 5rem;
        padding-right: var(--space-6-rem);
        color: inherit;
      `}
    >
      {props.badge}
    </td>
    <td
      css={css`
        width: 5rem;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          overflow: auto;
        `}
      >
        {props.interactionElement}
      </div>
    </td>
  </tr>
);

const DocumentSourceIntegrationRow: React.FC<{
  integration: IntegrationState["integration"];
  logo: string;
  title: string;
  description: string;
  source: IntegrationsListGet_externalDocumentSources | undefined;
  syncMutation: MutationTuple<IntegrationSyncExternalDocumentSource, IntegrationSyncExternalDocumentSourceVariables>[0];
  deleteMutation: MutationTuple<
    IntegrationDeleteExternalDocumentSource,
    IntegrationDeleteExternalDocumentSourceVariables
  >[0];
  setActiveIntegration: (state: IntegrationState) => void;
}> = ({ integration, logo, title, description, source, syncMutation, deleteMutation, setActiveIntegration }) => {
  return (
    <IntegrationRow
      logo={logo}
      title={title}
      description={description}
      badge={
        <IntegrationStatus
          active={true}
          connected={!!source}
          lastSync={source?.lastSyncTime}
          lastSuccessfulSync={source?.lastSuccessfulSyncTime}
          lastSyncErrorMessage={source?.lastSyncErrorMessage ?? ""}
        />
      }
      interactionElement={
        !!source ? (
          <ShowMorePopMenu
            options={[
              {
                id: "synchronize",
                name: "Synchronize now",
                testId: "team-settings-item",
                onClick: () => {
                  syncMutation({ variables: { id: source.id } });
                }
              },
              {
                id: "disable",
                name: "Disable",
                testId: "team-settings-item",
                onClick: () => {
                  deleteMutation({ variables: { id: source.id } });
                }
              }
            ]}
          />
        ) : (
          <Button onClick={() => setActiveIntegration({ integration, action: "install" })}>Connect</Button>
        )
      }
    />
  );
};

const tableSubheading = css`
  & > th {
    text-align: left;
    padding: var(--space-5-rem) 0 var(--space-2-rem);
  }
`;

type IntegrationState = {
  integration?:
    | "slack"
    | "personio"
    | "bamboohr"
    | "confluence"
    | "msteams"
    | "sso"
    | "hibob"
    | "workday"
    | "googlechat"
    | "notion"
    | "okta"
    | "sharepoint";
  action?: "install" | "confirmation" | "manage";
};

/**
 * Team overview list / managment page
 */
export const IntegrationOverview: React.ComponentType<IntegrationState> = props => {
  const { emitSnack } = useSnack();

  const { currentUser } = useCurrentUser();
  const { hasFeatureFlags } = useFeatureFlags();
  const [activeIntegration, setActiveIntegration] = useState<IntegrationState>({
    integration: props.integration,
    action: props.action
  });
  const { data, loading, error } = useQuery<IntegrationsListGet>(INTEGRATIONS_LIST_GET, {
    fetchPolicy: "network-only"
  });

  const [configureSSO] = useMutation<ConfigureSSO>(
    gql`
      mutation ConfigureSSO {
        integrationConfigureSSO {
          code
          message
          success
          redirect {
            url
          }
        }
      }
    `
  );
  const [deleteSSO] = useMutation<IntegrationDeleteSSO>(INTEGRATION_DELETE_SSO, {
    onCompleted: data => {
      if (data.integrationDeleteSSO.success) {
        emitSnack({ message: `SSO integration deleted`, type: "info" });
      } else {
        emitSnack({ message: `error: ${data.integrationDeleteSSO.message}`, type: "info" });
      }
    },
    awaitRefetchQueries: true,
    refetchQueries: [{ query: INTEGRATIONS_LIST_GET }]
  });

  const navigateToSSOConfig = async () => {
    const { data } = await configureSSO();
    if (data?.integrationConfigureSSO.redirect?.url) {
      window.location.href = data.integrationConfigureSSO.redirect.url;
    } else if (data?.integrationConfigureSSO.success === false) {
      emitSnack({
        type: "mutationError",
        message: `Sorry, we encountered an error: "${data?.integrationConfigureSSO.message}".`
      });
    }
  };

  const [disableHRIS] = useMutation<IntegrationDeleteHRIS>(INTEGRATION_DELETE_HRIS, {
    onCompleted: data => {
      if (data.integrationDeleteHRIS.success) {
        emitSnack({ message: `HRIS integration disabled!`, type: "info" });
      }
    },
    awaitRefetchQueries: true,
    refetchQueries: [{ query: INTEGRATIONS_LIST_GET }]
  });

  const [synchronizeExternalDocumentSource] = useMutation<
    IntegrationSyncExternalDocumentSource,
    IntegrationSyncExternalDocumentSourceVariables
  >(INTEGRATION_SYNC_EXTERNAL_DOCUMENT_SOURCE, {
    onCompleted: data => {
      if (data.externalDocumentSourceSync.success) {
        emitSnack({ message: `Background synchronization started`, type: "info" });
      }
    }
  });
  const [disableExternalDocumentSource] = useMutation<
    IntegrationDeleteExternalDocumentSource,
    IntegrationDeleteExternalDocumentSourceVariables
  >(INTEGRATION_DELETE_EXTERNAL_DOCUMENT_SOURCE, {
    onCompleted: data => {
      if (data.externalDocumentSourceDelete.success) {
        emitSnack({ message: `Integration disabled!`, type: "info" });
      }
    },
    awaitRefetchQueries: true,
    refetchQueries: [{ query: INTEGRATIONS_LIST_GET }]
  });
  const [deleteOkta] = useMutation<IntegrationDeleteOkta, {}>(INTEGRATION_DELETE_OKTA, {
    onCompleted: data => {
      if (data.integrationDeleteOkta.success) {
        emitSnack({ message: `Okta integration disabled`, type: "info" });
      }
    },
    awaitRefetchQueries: true,
    refetchQueries: [{ query: INTEGRATIONS_LIST_GET }]
  });

  const urlParams = parse(window.location.search.substring(1));
  const hibobIntegration = data?.integrationHRIS?.kind === IntegrationHRISKind.HIBOB ? data.integrationHRIS : null;
  const personioIntegration =
    data?.integrationHRIS?.kind === IntegrationHRISKind.PERSONIO ? data.integrationHRIS : null;
  const bambooIntegration = data?.integrationHRIS?.kind === IntegrationHRISKind.BAMBOOHR ? data.integrationHRIS : null;
  const workdayIntegration = data?.integrationHRIS?.kind === IntegrationHRISKind.WORKDAY ? data.integrationHRIS : null;
  const isJiraConnected = !!data?.integrationJira?.linkedProjects?.length;

  // For now we only assume a single external document source of each kind,
  // it's therefore fine to use Array.find() here.
  const confluenceExternalDocSource = data?.externalDocumentSources.find(
    s => s.kind === ExternalDocumentSourceKind.EXTERNAL_SOURCE_KIND_CONFLUENCE
  );
  const notionExternalDocSource = data?.externalDocumentSources.find(
    s => s.kind === ExternalDocumentSourceKind.EXTERNAL_SOURCE_KIND_NOTION
  );
  const sharepointExternalDocSource = data?.externalDocumentSources.find(
    s => s.kind === ExternalDocumentSourceKind.EXTERNAL_SOURCE_KIND_SHAREPOINT
  );

  return (
    <>
      <BaseLayout
        headline={"Integrations"}
        subline={
          "Make the most of Back by connecting it to other tools for communication, employee data (HRIS), and systems of record"
        }
        isLoading={loading}
      >
        {error && <Toast message={error.message} kind="error" />}
        {!error && data && (
          <>
            <Table
              css={css`
                margin-bottom: var(--space-6-rem);

                & td {
                  white-space: normal;
                  overflow: auto;
                }
              `}
            >
              <tbody>
                <tr css={tableSubheading}>
                  <th colSpan={5}>Communication channels</th>
                </tr>
                <IntegrationRow
                  logo={EmailSVG}
                  title="Email"
                  description="Forward your shared inbox or email group to Back to transform all internal and external emails into Back requests"
                  badge={<IntegrationStatus active={true} connected={true} />}
                  interactionElement={
                    <ShowMorePopMenu
                      options={[
                        {
                          id: "support",
                          name: "More information",
                          linkAttrs: {
                            href: "http://support.back.ee/en/articles/4823259-receiving-requests-in-back-via-email",
                            target: "_blank"
                          }
                        }
                      ]}
                    />
                  }
                />
                <IntegrationRow
                  logo={SlackSVG}
                  title="Slack"
                  description="Connect to Slack to enable your employees to create requests and get help directly from there"
                  badge={<IntegrationStatus active={true} connected={!!currentUser?.organization.isSlackInstalled} />}
                  interactionElement={
                    !currentUser?.organization.isSlackInstalled ? (
                      <Button onClick={() => setActiveIntegration({ integration: "slack", action: "install" })}>
                        Connect
                      </Button>
                    ) : (
                      <ShowMorePopMenu
                        options={[
                          {
                            id: "reconnect",
                            name: "Reconnect",
                            testId: "team-settings-item",
                            onClick: () =>
                              initiateSlackInstall(currentUser?.organization.id ?? "err", currentUser?.id ?? "err")
                          }
                        ]}
                      />
                    )
                  }
                />
                <IntegrationRow
                  logo={MSTeamsSVG}
                  title="MS Teams"
                  description="Install the MS Teams Back app to enable your employees to create requests and get help directly from there"
                  badge={<IntegrationStatus active={true} connected={!!data.integrationMSTeams?.teamsList?.length} />}
                  interactionElement={
                    !data.integrationMSTeams ? (
                      <Button onClick={() => setActiveIntegration({ integration: "msteams", action: "manage" })}>
                        Connect
                      </Button>
                    ) : (
                      <ShowMorePopMenu
                        options={[
                          {
                            id: "manage",
                            name: "Manage",
                            testId: "team-settings-item",
                            onClick: () => setActiveIntegration({ integration: "msteams", action: "manage" })
                          }
                        ]}
                      />
                    )
                  }
                />
                <IntegrationRow
                  logo={GoogleChatSVG}
                  title="Google Chat"
                  description="Install the Google Chat Back app to enable your employees to create requests and get help directly from there"
                  badge={<IntegrationStatus active={false} />}
                />

                <tr css={tableSubheading}>
                  <th colSpan={5}>HR systems</th>
                </tr>
                <IntegrationRow
                  logo={PersonioSVG}
                  title="Personio"
                  description="Leverage your existing employee data in Personio to easily get all relevant information about the requester"
                  badge={
                    <IntegrationStatus
                      active={true}
                      connected={!!personioIntegration}
                      lastSync={personioIntegration?.lastSyncTime}
                      lastSuccessfulSync={personioIntegration?.lastSuccessfulSyncTime}
                      lastSyncErrorMessage={personioIntegration?.lastSyncErrorMessage ?? ""}
                    />
                  }
                  interactionElement={
                    personioIntegration === null ? (
                      <Button onClick={() => setActiveIntegration({ integration: "personio", action: "install" })}>
                        Connect
                      </Button>
                    ) : (
                      <ShowMorePopMenu
                        options={[
                          {
                            id: "disable",
                            name: "Disable",
                            testId: "team-settings-item",
                            onClick: () => {
                              if (data.integrationHRIS?.kind === IntegrationHRISKind.PERSONIO) {
                                disableHRIS();
                              }
                            }
                          }
                        ]}
                      />
                    )
                  }
                />
                <IntegrationRow
                  logo={BambooSVG}
                  title="BambooHR"
                  description="Leverage your existing employee data in BambooHR to easily get all relevant information about the requester"
                  badge={
                    <IntegrationStatus
                      active={true}
                      connected={!!bambooIntegration}
                      lastSync={bambooIntegration?.lastSyncTime}
                      lastSuccessfulSync={bambooIntegration?.lastSuccessfulSyncTime}
                      lastSyncErrorMessage={bambooIntegration?.lastSyncErrorMessage ?? ""}
                    />
                  }
                  interactionElement={
                    bambooIntegration === null ? (
                      <Button onClick={() => setActiveIntegration({ integration: "bamboohr", action: "install" })}>
                        Connect
                      </Button>
                    ) : (
                      <ShowMorePopMenu
                        options={[
                          {
                            id: "disable",
                            name: "Disable",
                            testId: "team-settings-item",
                            onClick: () => {
                              if (data.integrationHRIS?.kind === IntegrationHRISKind.BAMBOOHR) {
                                disableHRIS();
                              }
                            }
                          }
                        ]}
                      />
                    )
                  }
                />
                <IntegrationRow
                  logo={HiBobSVG}
                  title="HiBob"
                  description="Leverage your existing employee data in HiBob to easily get all relevant information about the requester"
                  badge={
                    <IntegrationStatus
                      active={true}
                      connected={!!hibobIntegration}
                      lastSync={hibobIntegration?.lastSyncTime}
                      lastSuccessfulSync={hibobIntegration?.lastSuccessfulSyncTime}
                      lastSyncErrorMessage={hibobIntegration?.lastSyncErrorMessage ?? ""}
                    />
                  }
                  interactionElement={
                    !hibobIntegration ? (
                      <Button onClick={() => setActiveIntegration({ integration: "hibob", action: "install" })}>
                        Connect
                      </Button>
                    ) : (
                      <ShowMorePopMenu
                        options={[
                          {
                            id: "disable",
                            name: "Disable",
                            testId: "team-settings-item",
                            onClick: () => hibobIntegration && disableHRIS()
                          }
                        ]}
                      />
                    )
                  }
                />
                <IntegrationRow
                  logo={WorkdaySVG}
                  title="Workday"
                  description="Leverage your existing employee data in Workday to easily get all relevant information about the requester"
                  badge={
                    <IntegrationStatus
                      active={hasFeatureFlags(FeatureFlags.WORKDAY)}
                      text="Talk to us"
                      connected={!!workdayIntegration}
                      lastSync={workdayIntegration?.lastSyncTime}
                      lastSuccessfulSync={workdayIntegration?.lastSuccessfulSyncTime}
                      lastSyncErrorMessage={workdayIntegration?.lastSyncErrorMessage ?? ""}
                    />
                  }
                  interactionElement={
                    hasFeatureFlags(FeatureFlags.WORKDAY) &&
                    (!workdayIntegration ? (
                      <Button onClick={() => setActiveIntegration({ integration: "workday", action: "install" })}>
                        Connect
                      </Button>
                    ) : (
                      <ShowMorePopMenu
                        options={[
                          { id: "disable", name: "Disable", onClick: () => workdayIntegration && disableHRIS() }
                        ]}
                      />
                    ))
                  }
                />
                <tr css={tableSubheading}>
                  <th colSpan={5}>Knowledge and policies</th>
                </tr>
                <DocumentSourceIntegrationRow
                  integration="confluence"
                  logo={ConfluenceSVG}
                  title="Atlassian Confluence"
                  description="Connect with your existing knowledge in Confluence to surface it to both the employee and the expert whenever needed"
                  source={confluenceExternalDocSource}
                  syncMutation={synchronizeExternalDocumentSource}
                  deleteMutation={disableExternalDocumentSource}
                  setActiveIntegration={setActiveIntegration}
                />
                <DocumentSourceIntegrationRow
                  integration="notion"
                  logo={NotionSVG}
                  title="Notion"
                  description="Use your Notion databases with pages to share existing knowledge with employees"
                  source={notionExternalDocSource}
                  syncMutation={synchronizeExternalDocumentSource}
                  deleteMutation={disableExternalDocumentSource}
                  setActiveIntegration={setActiveIntegration}
                />
                <DocumentSourceIntegrationRow
                  integration="sharepoint"
                  logo={SharepointSVG}
                  title="Sharepoint"
                  description="Import Sharepoint pages into your knowledge base."
                  source={sharepointExternalDocSource}
                  syncMutation={synchronizeExternalDocumentSource}
                  deleteMutation={disableExternalDocumentSource}
                  setActiveIntegration={setActiveIntegration}
                />
                <tr css={tableSubheading}>
                  <th colSpan={5}>IT Ticketing and Security</th>
                </tr>
                <IntegrationRow
                  logo={JiraSVG}
                  title="Atlassian Jira (SD)"
                  description="Forward your requests from Back to Jira or Jira SD and keep replies and note in sync"
                  badge={
                    <IntegrationStatus
                      text={isJiraConnected ? "Connected" : "Talk to us"}
                      connected={isJiraConnected}
                      active={isJiraConnected}
                    />
                  }
                  interactionElement={
                    !isJiraConnected && (
                      <ShowMorePopMenu
                        options={[
                          {
                            id: "support",
                            name: "More information",
                            linkAttrs: {
                              href: "https://support.back.ee/en/articles/4915505-integrate-back-with-jira-and-jira-sd",
                              target: "_blank"
                            }
                          }
                        ]}
                      />
                    )
                  }
                />

                <tr css={tableSubheading}>
                  <th colSpan={5}>Security and Account management</th>
                </tr>
                <IntegrationRow
                  logo={SSOSVG}
                  title="Single Sign-On"
                  description="Enable Single Sign-On to allow experts to use an existing identity provider (Okta, Google OAuth, OneLogin, and more) to log in to Back (Available with enterprise plan only)"
                  badge={
                    <IntegrationStatus
                      connected={data.integrationSSO?.status === IntegrationSSOStatus.ENABLED}
                      text="Talk to us"
                      active={hasFeatureFlags(FeatureFlags.SSO)}
                    />
                  }
                  interactionElement={
                    hasFeatureFlags(FeatureFlags.SSO) &&
                    (!data.integrationSSO ? (
                      <Button onClick={() => setActiveIntegration({ integration: "sso", action: "install" })}>
                        Connect
                      </Button>
                    ) : data.integrationSSO.status === IntegrationSSOStatus.DISABLED ? (
                      // the integration was created but is disabled, so the user should enable it from the WorkOS config page
                      <Button onClick={navigateToSSOConfig}>Connect</Button>
                    ) : data.integrationSSO.oauthProvider ? (
                      <ShowMorePopMenu
                        options={[
                          {
                            id: "delete",
                            name: "Delete",
                            testId: "team-settings-item",
                            onClick: () => deleteSSO()
                          }
                        ]}
                      />
                    ) : (
                      <ShowMorePopMenu
                        options={[
                          {
                            id: "configure",
                            name: "Configure",
                            testId: "team-settings-item",
                            onClick: navigateToSSOConfig
                          }
                        ]}
                      />
                    ))
                  }
                />

                <IntegrationRow
                  logo={OktaSVG}
                  title="Okta"
                  description="Manage Okta user accounts directly from inside Back"
                  badge={
                    <IntegrationStatus
                      connected={!!data.integrationOkta}
                      text="Talk to us"
                      active={hasFeatureFlags(FeatureFlags.OKTA)}
                    />
                  }
                  interactionElement={
                    hasFeatureFlags(FeatureFlags.OKTA) &&
                    (!data.integrationOkta ? (
                      <Button onClick={() => setActiveIntegration({ integration: "okta", action: "install" })}>
                        Connect
                      </Button>
                    ) : (
                      <ShowMorePopMenu
                        options={[
                          {
                            id: "delete",
                            name: "Delete",
                            onClick: () => deleteOkta()
                          }
                        ]}
                      />
                    ))
                  }
                />
              </tbody>
            </Table>
            <Note title="🤔 Anything missing?">
              Let us know if you’re using any other tools that you’d like to see integrated with Back via our{" "}
              <Typo.ExternalLink href="https://portal.productboard.com/backhq/1-back-s-upcoming-features/tabs/1-thinking-about-it">
                roadmap portal
              </Typo.ExternalLink>
              .
            </Note>
          </>
        )}
      </BaseLayout>
      {data && !loading && (
        <SlackInstallModal
          onDismiss={() => setActiveIntegration({ integration: undefined, action: undefined })}
          organizationId={currentUser?.organization.id ?? "err"}
          userId={currentUser?.id ?? "err"}
          isOpen={activeIntegration.integration === "slack" && activeIntegration.action === "install"}
        />
      )}
      {data && !loading && (
        <SlackConfirmationModal
          error={urlParams.error}
          onDismiss={() => setActiveIntegration({ integration: undefined, action: undefined })}
          isOpen={activeIntegration.integration === "slack" && activeIntegration.action === "confirmation"}
        />
      )}
      {data && (
        <PersonioInstallModal
          onSuccess={() => {
            emitSnack({ message: `Personio integration enabled!`, type: "info" });
            setActiveIntegration({ integration: undefined, action: undefined });
          }}
          isOpen={activeIntegration.integration === "personio"}
          onDismiss={() => setActiveIntegration({ integration: undefined, action: undefined })}
          organizationId={currentUser?.organization.id ?? "err"}
        />
      )}
      {data && (
        <BambooHRInstallModal
          isOpen={activeIntegration.integration === "bamboohr" && activeIntegration.action === "install"}
          onDismiss={() => setActiveIntegration({ integration: undefined, action: undefined })}
          organizationId={currentUser?.organization.id ?? "err"}
        />
      )}
      {activeIntegration.integration === "bamboohr" &&
        activeIntegration.action === "confirmation" &&
        (urlParams.success === "true" ? (
          <Toast message="BambooHR successfully configured!" kind="custom" variant="success" />
        ) : (
          <Toast message={urlParams.error} kind="error" />
        ))}
      {data && (
        <HiBobInstallModal
          onSuccess={() => {
            emitSnack({ message: `HiBob integration enabled!`, type: "info" });
            setActiveIntegration({ integration: undefined, action: undefined });
          }}
          isOpen={activeIntegration.integration === "hibob"}
          onDismiss={() => setActiveIntegration({ integration: undefined, action: undefined })}
        />
      )}
      {data && (
        <MergeInstallModal
          provider="workday"
          onSuccess={() => {
            emitSnack({ message: `Workday integration enabled!`, type: "info" });
            setActiveIntegration({ integration: undefined, action: undefined });
          }}
          isOpen={activeIntegration.integration === "workday"}
          onDismiss={() => setActiveIntegration({ integration: undefined, action: undefined })}
        />
      )}
      {data && (
        <ConfluenceInstallModal
          onSuccess={() => {
            emitSnack({ message: `Confluence integration enabled!`, type: "info" });
            setActiveIntegration({ integration: undefined, action: undefined });
          }}
          isOpen={activeIntegration.integration === "confluence"}
          onDismiss={() => setActiveIntegration({ integration: undefined, action: undefined })}
          organizationId={currentUser?.organization.id ?? "err"}
        />
      )}

      {data && (
        <NotionInstallModal
          onSuccess={() => {
            emitSnack({ message: `Notion integration enabled!`, type: "info" });
            setActiveIntegration({ integration: undefined, action: undefined });
          }}
          isOpen={activeIntegration.integration === "notion"}
          onDismiss={() => setActiveIntegration({ integration: undefined, action: undefined })}
        />
      )}
      {data && !loading && (
        <NotionConfirmationModal
          error={urlParams.error}
          onDismiss={() => setActiveIntegration({ integration: undefined, action: undefined })}
          isOpen={activeIntegration.integration === "notion" && activeIntegration.action === "confirmation"}
        />
      )}

      {activeIntegration.integration === "msteams" && activeIntegration.action === "manage" && (
        <MSTeamsInfoModal
          integration={data?.integrationMSTeams || null}
          isOpen={activeIntegration.integration === "msteams" && activeIntegration.action === "manage"}
          onDismiss={() => setActiveIntegration({ integration: undefined, action: undefined })}
        />
      )}

      {activeIntegration.integration === "msteams" && activeIntegration.action === "install" && (
        <MSTeamsInstallModal
          onSuccess={() => {
            emitSnack({ message: `Microsoft Teams integration enabled!`, type: "info" });

            // We do not close the window here like for other integrations,
            // because the MS Teams SDK does some asynchronous work which will
            // eventually close the window but which we can't follow, so the
            // component needs to stay mounted.
          }}
          isOpen={activeIntegration.integration === "msteams" && activeIntegration.action === "install"}
          onDismiss={() => setActiveIntegration({ integration: undefined, action: undefined })}
        />
      )}

      {activeIntegration.integration === "googlechat" && activeIntegration.action === "install" && (
        <GChatInstallModal
          onSuccess={() => {
            emitSnack({ message: `Google Chat integration enabled!`, type: "info" });
          }}
          isOpen={activeIntegration.integration === "googlechat" && activeIntegration.action === "install"}
          onDismiss={() => setActiveIntegration({ integration: undefined, action: undefined })}
        />
      )}

      <EnableSSOModal
        onSuccess={() => {
          emitSnack({ message: `SSO enabled!`, type: "info" });
          setActiveIntegration({ integration: undefined, action: undefined });
        }}
        isOpen={activeIntegration.integration === "sso" && activeIntegration.action === "install"}
        onDismiss={() => setActiveIntegration({ integration: undefined, action: undefined })}
      />

      {activeIntegration.integration === "okta" && activeIntegration.action === "install" && (
        <OktaInstallModal
          onSuccess={() => {
            emitSnack({ message: `Okta integration enabled!`, type: "info" });
            setActiveIntegration({ integration: undefined, action: undefined });
          }}
          isOpen={true}
          onDismiss={() => setActiveIntegration({ integration: undefined, action: undefined })}
        />
      )}

      {activeIntegration.integration === "sharepoint" && activeIntegration.action === "install" && (
        <Microsoft365InstallModal
          isOpen={true}
          onDismiss={() => setActiveIntegration({ integration: undefined, action: undefined })}
        />
      )}

      {activeIntegration.integration === "sharepoint" && activeIntegration.action === "confirmation" && (
        <Microsoft365InstalledModal
          isOpen={true}
          onDismiss={() => setActiveIntegration({ integration: undefined, action: undefined })}
        />
      )}
    </>
  );
};
