import * as React from "react";

import {
  BaseLayout,
  Button,
  CancelButton,
  Col,
  Dialog,
  EmptyState,
  InfoBanner,
  LoadingBar,
  MaterialIcon,
  Row,
  SubmitButton
} from "src/components";
import { BestPracticeCompanyType, BestPracticeType } from "src/globalTypes";
import {
  BestPracticeGet,
  BestPracticeGetVariables,
  BestPracticeGet_bestPractice,
  BestPracticeGet_bestPractice_documents,
  BestPracticeGet_bestPractice_forms
} from "./typings/BestPracticeGet";
import { BestPracticeInstall, BestPracticeInstallVariables } from "./typings/BestPracticeInstall";
import { FC, useState } from "react";
import { GenericSvgFromString, Icon } from "src/components/Icon";
import { RouteComponentProps, navigate } from "src/util/router";
import { bestPracticeRequiredIntegrations, bestPracticeTeamTypes } from "src/App/BestPractices/enumTranslations";
import { mdiInformation, mdiPlus } from "@mdi/js";
import { useMutation, useQuery } from "react-apollo";

import { Card } from "src/styling/primitives/Card";
import { Document } from "src/svg/icons/Document";
import { Flag } from "src/svg/icons/Flag";
import { Flow } from "src/svg/icons/Flow";
import { Folder } from "src/svg/icons/Folder";
import { FormPreview } from "src/App/Forms/Manager/PreviewModal";
import { HelpBubble } from "src/svg/icons/HelpBubble";
import { KBMarkdownView } from "src/App/KB/Detail/MarkdownView";
import { Modal } from "src/portals/Modal";
import { SingleSelect } from "src/components/Fields/Select";
import { Toast } from "src/portals/Toast";
import { Tools } from "src/svg/icons/Tools";
import { Typo } from "src/styling/primitives/typography";
import { Users } from "src/svg/icons/Users";
import { css } from "@emotion/core";
import gql from "graphql-tag";
import { markdownWithEmbeddedHtmlToHtml } from "src/util/formatters/mrkdwn";
import { motion } from "framer-motion";
import { trackBPAttemptToInstall } from "src/util/analytics";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { useSnack } from "src/App/Root/providers/SnackProvider";

const BEST_PRACTICE = gql`
  query BestPracticeGet($id: ID!) {
    bestPractice(id: $id) {
      id
      name
      color
      icon
      useCase
      summary
      description
      type
      company {
        name
        headcount {
          low
          high
        }
        type
        icon
      }
      teamsInvolved
      requiredIntegrations
      workflows {
        icon
        name
      }
      documents {
        title
        content
      }
      forms {
        displayName
        description
        fieldsList {
          ... on FormFieldCommon {
            __typename
            displayName
            required
          }
          ... on TextField {
            textFieldType
            value
          }
          ... on DropdownField {
            optionsList
            selected
          }
          ... on AttachmentsField {
            attachment {
              id
              displayName
              size
            }
          }
        }
      }
    }
  }
`;

const InstallBestPracticeModal: React.FC<{
  isShown: boolean;
  onClose: () => void;
  title: string;
  bestPractice: BestPracticeGet_bestPractice;
}> = ({ isShown, onClose, title, bestPractice }) => {
  const { currentUser } = useCurrentUser();
  const { emitSnack } = useSnack();

  const firstTeam = currentUser?.teams[0] ?? { id: "<invalid-team-id>", name: "<invalid team>" };
  const [teamId, setTeamId] = React.useState<string>(firstTeam.id);

  // set default selected team from current user team list
  React.useEffect(() => {
    if (currentUser) {
      setTeamId(currentUser.teams[0].id);
    }
  }, [currentUser]);

  const [installed, setInstalled] = React.useState<boolean>(false);
  const [installBestPracticeComponents, installBestPracticeComponentsResponse] = useMutation<
    BestPracticeInstall,
    BestPracticeInstallVariables
  >(gql`
    mutation BestPracticeInstall($teamId: ID!, $bestPracticeId: ID!) {
      bestPracticeInstall(teamId: $teamId, bestPracticeId: $bestPracticeId) {
        code
        success
        message
        workflows {
          id
          name
        }
        forms {
          id
          displayName
        }
        documents {
          id
          title
        }
      }
    }
  `);

  return (
    <>
      {installBestPracticeComponentsResponse.loading && <LoadingBar />}
      <Modal onDismiss={onClose} isOpen={isShown}>
        <Dialog
          loading={false}
          medium
          title={installed ? "Success!" : title}
          subtitle={
            installed ? (
              <Typo.Body>
                The following components were added to the team{" "}
                <i>{(currentUser?.teams ?? []).find(t => t.id === teamId)?.name ?? "<unknown team>"}</i>
              </Typo.Body>
            ) : undefined
          }
          onClose={onClose}
          data-testid="bestpractices-install-dialog"
        >
          <Col
            css={css`
              & > * + * {
                margin-top: var(--space-5-px);
              }
            `}
          >
            {!installed && (
              <>
                <div
                  css={css`
                    & > * + * {
                      margin-top: var(--space-2-px);
                    }
                  `}
                >
                  <Typo.Body bold>Team</Typo.Body>
                  <SingleSelect
                    name="Team"
                    placeholder="Select a team"
                    defaultValue={{ label: firstTeam?.name, value: firstTeam?.id }}
                    options={(currentUser?.teams ?? []).map(t => ({ label: t.name, value: t.id }))}
                    onChange={e => {
                      if (e && "value" in e) {
                        setTeamId(e.value);
                      }
                    }}
                    isDisabled={false}
                    isSearchable={false}
                    showError={false}
                  />
                </div>
                <div
                  css={css`
                    & > * + * {
                      margin-top: var(--space-2-px);
                    }
                  `}
                >
                  <Typo.Body bold>Components</Typo.Body>
                  <div
                    css={css`
                      & > * + * {
                        border-top: var(--border-lightGrey);
                      }
                      margin-top: 0;
                    `}
                  >
                    {bestPractice.workflows.map((w, i) => (
                      <BestPracticeComponent
                        key={`workflow-${i}`}
                        icon={<Flow />}
                        title={w.name ?? ""}
                        type="Workflow"
                      />
                    ))}

                    {bestPractice.forms.map((f, i) => (
                      <BestPracticeComponent
                        key={`form-${i}`}
                        icon={<HelpBubble />}
                        title={f.displayName ?? ""}
                        type="Form"
                      />
                    ))}

                    {bestPractice.documents.map((d, i) => (
                      <BestPracticeComponent
                        key={`document-${i}`}
                        icon={<Document />}
                        title={d.title ?? ""}
                        type="Document"
                      />
                    ))}
                  </div>
                </div>
              </>
            )}
            {installed && (
              <div
                css={css`
                  & > * + * {
                    margin-top: var(--space-3-px);
                  }
                `}
              >
                {!!installBestPracticeComponentsResponse.data?.bestPracticeInstall.workflows?.length && (
                  <InfoBanner>
                    <MaterialIcon path={mdiInformation} size={1.125} />
                    <Typo.Body>Note that workflows have to be enabled to put them to use</Typo.Body>
                  </InfoBanner>
                )}
                <div
                  css={css`
                    & > * + * {
                      margin-top: var(--space-2-px);
                    }
                  `}
                >
                  {(installBestPracticeComponentsResponse.data?.bestPracticeInstall.workflows ?? []).map(w => (
                    <Card sizeS hasInteractions key={w.id} onClick={() => navigate(`/workflow/${w.id}`)}>
                      <BestPracticeComponent narrow={true} icon={<Flow />} title={w.name ?? ""} type="Workflow" />
                    </Card>
                  ))}

                  {(installBestPracticeComponentsResponse.data?.bestPracticeInstall.forms ?? []).map(f => (
                    <Card sizeS hasInteractions key={f.id} onClick={() => navigate("/forms")}>
                      <BestPracticeComponent
                        narrow={true}
                        icon={<HelpBubble />}
                        title={f.displayName ?? ""}
                        type="Form"
                      />
                    </Card>
                  ))}

                  {(installBestPracticeComponentsResponse.data?.bestPracticeInstall.documents ?? []).map(d => (
                    <Card sizeS hasInteractions key={d.id} onClick={() => navigate("/knowledge")}>
                      <BestPracticeComponent narrow={true} icon={<Document />} title={d.title ?? ""} type="Document" />
                    </Card>
                  ))}
                </div>
              </div>
            )}

            <Row
              css={css`
                justify-content: flex-end;
                margin: var(--space-6-rem) 0 0 0;
              `}
            >
              {installed && (
                <Button
                  variant="secondary"
                  onClick={() => {
                    setInstalled(false);
                    onClose();
                  }}
                  disabled={installBestPracticeComponentsResponse.loading}
                >
                  Close
                </Button>
              )}
              {!installed && (
                <>
                  <CancelButton
                    onClick={onClose}
                    disabled={installBestPracticeComponentsResponse.loading}
                    data-testid="bestpractices-install-dialog-cancel"
                  >
                    Cancel
                  </CancelButton>
                  <Button
                    onClick={async () => {
                      const response = await installBestPracticeComponents({
                        variables: {
                          teamId: teamId,
                          bestPracticeId: bestPractice.id
                        }
                      });

                      if (response.data?.bestPracticeInstall.success) {
                        emitSnack({
                          message: `Best practice installed`,
                          type: "info"
                        });
                        setInstalled(true);
                      } else {
                        emitSnack({
                          message: response.data?.bestPracticeInstall?.message ?? `Error installing best practice`,
                          type: "mutationError"
                        });
                        setInstalled(false);
                        onClose();
                      }
                    }}
                    variant="primary"
                    size="large"
                    disabled={installBestPracticeComponentsResponse.loading}
                  >
                    Add components
                  </Button>
                </>
              )}
            </Row>
          </Col>
        </Dialog>
      </Modal>
    </>
  );
};

const BestPracticeDocPreviewModal: FC<{
  isOpen: boolean;
  onClose(): void;
  document: BestPracticeGet_bestPractice_documents | null;
}> = ({ isOpen, onClose, document }) => (
  <Modal isOpen={isOpen} onDismiss={onClose}>
    {document && (
      <Dialog
        large
        title={document.title}
        onClose={onClose}
        css={[
          css`
            // limit the modal extend to maximum available height (100% of screen minus 3rem padding)
            max-height: calc(100vh - 6rem);
            overflow: auto;
          `
        ]}
      >
        <Typo.Body>
          <KBMarkdownView md={document.content} />
        </Typo.Body>
      </Dialog>
    )}
  </Modal>
);

const BestPracticeFormPreviewModal: FC<{
  isOpen: boolean;
  onClose(): void;
  form: BestPracticeGet_bestPractice_forms | null;
}> = ({ isOpen, onClose, form }) => (
  <Modal isOpen={isOpen} onDismiss={onClose}>
    {form && (
      <Dialog
        large
        onClose={onClose}
        css={[
          css`
            // limit the modal extend to maximum available height (100% of screen minus 3rem padding)
            max-height: calc(100vh - 6rem);
            overflow: auto;
          `
        ]}
      >
        <FormPreview schema={form} />
      </Dialog>
    )}
  </Modal>
);

export const BestPracticeDetail: React.FC<RouteComponentProps<{ best_practice_id: string }>> = ({
  best_practice_id: bestPracticeId
}) => {
  const bestPracticeResponse = useQuery<BestPracticeGet, BestPracticeGetVariables>(BEST_PRACTICE, {
    variables: { id: bestPracticeId ?? "<invalid-best-practice-id>" }
  });
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [formDetailModal, setFormDetailModal] = useState<BestPracticeGet_bestPractice_forms | null>(null);
  const [documentDetailModal, setDocumentDetailModal] = useState<BestPracticeGet_bestPractice_documents | null>(null);

  if (bestPracticeResponse.loading) {
    return <LoadingBar />;
  }

  if (bestPracticeResponse.error) {
    return <Toast message={bestPracticeResponse.error.message} kind="error" />;
  }

  if (!bestPracticeResponse.data) {
    return (
      <BaseLayout
        backLink={{ label: "Best practices", path: "/best-practices" }}
        fullHeight={true}
        headline="Best practice"
        subline={null}
      >
        <EmptyState title="No best practice found with given id" variant="info" />
      </BaseLayout>
    );
  }

  return (
    <>
      <InstallBestPracticeModal
        bestPractice={bestPracticeResponse.data.bestPractice}
        isShown={isModalShown}
        onClose={() => setIsModalShown(false)}
        title={`Use ${getBestPracticeTypeLabel(bestPracticeResponse.data.bestPractice.type).toLowerCase()}`}
      />
      <Row
        css={css`
          min-width: var(--dimension-general-maxWidth);
          max-width: var(--dimension-general-maxWidth);
          margin: 0 auto;
        `}
      >
        <BaseLayout
          fullHeight
          backLink={{ label: "Best practices", path: "/best-practices" }}
          headline={bestPracticeResponse.data.bestPractice.name}
          subline={bestPracticeResponse.data.bestPractice.summary}
        >
          <Col
            css={css`
              & > * + * {
                margin-top: var(--space-5-px);
              }
            `}
          >
            <div
              css={css`
                & > * + * {
                  margin-top: var(--space-3-px);
                }
              `}
            >
              <Typo.Body bold sizeL>
                Description
              </Typo.Body>
              <div
                dangerouslySetInnerHTML={{
                  __html: markdownWithEmbeddedHtmlToHtml(bestPracticeResponse.data.bestPractice.description)
                }}
                css={css`
                  & > ul {
                    margin: 0;
                    padding: 0 14px;
                  }
                `}
                data-testid="bestpractices-description"
              ></div>
            </div>

            <div
              css={css`
                & > * + * {
                  margin-top: var(--space-3-px);
                }
              `}
            >
              <Typo.Body bold sizeL>
                Components
              </Typo.Body>
              <div
                css={css`
                  & > * + * {
                    border-top: var(--border-lightGrey);
                  }
                  margin-top: 0;
                `}
              >
                {bestPracticeResponse.data.bestPractice.workflows.map((w, i) => (
                  <BestPracticeComponent key={`workflow-${i}`} icon={<Flow />} title={w.name ?? ""} type="Workflow" />
                ))}

                {bestPracticeResponse.data.bestPractice.forms.map((f, i) => (
                  <div key={`form-${i}`}>
                    <BestPracticeComponent
                      onClick={() => setFormDetailModal(f)}
                      icon={<HelpBubble />}
                      title={f.displayName ?? ""}
                      type="Form"
                    />
                  </div>
                ))}

                {bestPracticeResponse.data.bestPractice.documents.map((d, i) => (
                  <div key={`document-${i}`}>
                    <BestPracticeComponent
                      onClick={() => setDocumentDetailModal(d)}
                      icon={<Document />}
                      title={d.title ?? ""}
                      type="Document"
                    />
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </BaseLayout>
        <BestPracticeDocPreviewModal
          isOpen={!!documentDetailModal}
          onClose={() => setDocumentDetailModal(null)}
          document={documentDetailModal}
        />
        <BestPracticeFormPreviewModal
          isOpen={!!formDetailModal}
          onClose={() => setFormDetailModal(null)}
          form={formDetailModal}
        />
        {/* Sidepanel */}
        <motion.div
          css={css`
            min-width: 320px;
            border-left: var(--border-lightGrey);
            padding: var(--space-6-px);
            padding-top: var(--space-8-px);
            overflow: auto;
          `}
          initial={{ opacity: 0, x: 80 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 80 }}
          transition={{ easings: "easeIn", duration: 0.2 }}
        >
          <Col
            css={css`
              & > * + * {
                margin-top: var(--space-5-px);
              }
            `}
          >
            <SubmitButton
              onClick={() => {
                setIsModalShown(true);
                trackBPAttemptToInstall(bestPracticeResponse.data?.bestPractice.id ?? "<invalid-best-practice-id>");
              }}
              data-testid="bestpractices-use-button"
            >
              <MaterialIcon path={mdiPlus} size={1.125} />
              <span>
                Use this {getBestPracticeTypeLabel(bestPracticeResponse.data.bestPractice.type).toLowerCase()}
              </span>
            </SubmitButton>

            <SidepanelItem heading="Type">
              <Icon>
                <Folder />
              </Icon>
              <Typo.Body>{getBestPracticeTypeLabel(bestPracticeResponse.data.bestPractice.type)}</Typo.Body>
            </SidepanelItem>

            <SidepanelItem heading="Suitable for">
              <Icon>
                <Users />
              </Icon>
              <Typo.Body>
                {bestPracticeResponse.data.bestPractice.teamsInvolved
                  .map(teamType => bestPracticeTeamTypes[teamType])
                  .join(", ")}
              </Typo.Body>
            </SidepanelItem>

            <SidepanelItem heading="Integrations">
              <Icon>
                <Tools />
              </Icon>
              <Typo.Body>
                {(bestPracticeResponse.data.bestPractice.requiredIntegrations ?? [])
                  .map(integration => bestPracticeRequiredIntegrations[integration])
                  .join(", ")}
              </Typo.Body>
            </SidepanelItem>

            <SidepanelItem heading="Used by">
              <Row
                css={css`
                  & > * + * {
                    margin-left: var(--space-3-px);
                  }
                `}
              >
                <div
                  css={css`
                    width: 34px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: var(--lightGrey-2);
                    border-radius: var(--border-radius-s);
                  `}
                >
                  <Icon>
                    {bestPracticeResponse.data.bestPractice.company.icon ? (
                      <GenericSvgFromString svg={bestPracticeResponse.data.bestPractice.company.icon} />
                    ) : (
                      <Flag />
                    )}
                  </Icon>
                </div>
                <div>
                  <Typo.Body medium>
                    {bestPracticeResponse.data.bestPractice.company.name ?? "Companies with"}
                  </Typo.Body>
                  {bestPracticeResponse.data.bestPractice.company.headcount && (
                    <Typo.Body>
                      {bestPracticeResponse.data.bestPractice.company.headcount.low} -{" "}
                      {bestPracticeResponse.data.bestPractice.company.headcount.high} employees
                    </Typo.Body>
                  )}
                  <Typo.Body>
                    {bestPracticeResponse.data.bestPractice.company.type ===
                    BestPracticeCompanyType.COMPANY_TYPE_DISTRIBUTED
                      ? "Distributed"
                      : bestPracticeResponse.data.bestPractice.company.type ===
                        BestPracticeCompanyType.COMPANY_TYPE_ONSITE
                      ? "On-site"
                      : bestPracticeResponse.data.bestPractice.company.type ===
                        BestPracticeCompanyType.COMPANY_TYPE_REMOTE
                      ? "Remote"
                      : ""}
                  </Typo.Body>
                </div>
              </Row>
            </SidepanelItem>
          </Col>
        </motion.div>
      </Row>
    </>
  );
};

const SidepanelItem: React.FC<{ heading: string }> = ({ heading, children }) => {
  return (
    <div
      css={css`
        & > * + * {
          margin-top: var(--space-3-px);
        }
      `}
    >
      <Typo.Body lighter medium>
        {heading}
      </Typo.Body>
      <div
        css={css`
          display: flex;
          & > * + * {
            margin-left: var(--space-2-px);
          }
        `}
      >
        {children}
      </div>
    </div>
  );
};

export const BestPracticeComponent: React.FC<{
  icon: React.ReactNode;
  title: string;
  type: "Document" | "Form" | "Workflow";
  narrow?: boolean;
  onClick?(): void;
}> = ({ icon, title, type, narrow = false, onClick }) => {
  const hasPreviewLink = !!onClick;
  return (
    <div
      css={[
        css`
          display: flex;
          align-items: center;
          flex: 1 0 auto;
          & > * + * {
            padding-left: var(--space-2-px);
          }
        `,
        hasPreviewLink &&
          css`
            cursor: pointer;
            & > p:first-of-type {
              text-decoration: underline;
            }
          `,
        !narrow &&
          css`
            padding: var(--space-3-px) 0;
          `
      ]}
      data-testid="bestpractices-component"
      onClick={() => onClick && onClick()}
    >
      <Icon>{icon}</Icon>
      <Typo.Body
        ellipsis
        css={[
          css`
            flex-grow: 2;
          `
        ]}
      >
        {title}
      </Typo.Body>
      <Typo.Body light>{type}</Typo.Body>
    </div>
  );
};

function getBestPracticeTypeLabel(type: BestPracticeType): string {
  return type === BestPracticeType.BEST_PRACTICE_TYPE_FORM
    ? "Form"
    : type === BestPracticeType.BEST_PRACTICE_TYPE_POLICY
    ? "Policy"
    : type === BestPracticeType.BEST_PRACTICE_TYPE_PROCESS
    ? "Process"
    : "Best practice";
}
