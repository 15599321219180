import { css } from "@emotion/core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import React from "react";
import { useMutation } from "react-apollo";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { Dialog, FormikFieldGroup, FormikFieldInputs, FormikInput, LoadingBar, SubmitButton } from "src/components";
import { Modal } from "src/portals/Modal";
import { justify, row } from "src/styling/primitives";
import { Typo } from "src/styling/primitives/typography";
import * as yup from "yup";
import { INTEGRATIONS_LIST_GET } from "../Overview";
import { ExternalDocsAddConfluence, ExternalDocsAddConfluenceVariables } from "./typings/ExternalDocsAddConfluence";

const EXTERNAL_DOCS_ADD_CONFLUENCE = gql`
  mutation ExternalDocsAddConfluence($params: ExternalDocumentSourceCreateParametersConfluence!) {
    externalDocumentSourceCreate(params: { confluence: $params }) {
      code
      message
      success
    }
  }
`;

const validationSchema = yup.object().shape({
  endpoint: yup
    .string()
    .url(
      "Please enter a full URL of your Confluence endpoint, e.g. https://acme.atlassian.net/wiki/ or https://confluence.acmecorp.com/."
    )
    .required("You need to specify your Confluence Endpoint."),
  username: yup.string().required("You need to provide your Confluence Username."),
  apiKey: yup.string().required()
});

export const ConfluenceInstallModal = (props: {
  onDismiss(): void;
  onSuccess(): void;
  organizationId: string;
  isOpen: boolean;
}) => {
  const { emitSnack } = useSnack();

  const [addConfluence, response] = useMutation<ExternalDocsAddConfluence, ExternalDocsAddConfluenceVariables>(
    EXTERNAL_DOCS_ADD_CONFLUENCE,
    {
      onCompleted: data => {
        if (data.externalDocumentSourceCreate.success) {
          props.onSuccess();
        } else if (data.externalDocumentSourceCreate.success === false) {
          emitSnack({
            type: "mutationError",
            message: data.externalDocumentSourceCreate.message
          });
        }
      },
      refetchQueries: [{ query: INTEGRATIONS_LIST_GET }]
    }
  );

  return (
    <Modal isOpen={props.isOpen} onDismiss={props.onDismiss}>
      <Dialog medium title="Connect Confluence" onClose={props.onDismiss}>
        {response.loading && <LoadingBar />}
        <Formik
          initialValues={{
            endpoint: "",
            username: "",
            apiKey: "",
            version: "cloud"
          }}
          validationSchema={validationSchema}
          onSubmit={payload =>
            addConfluence({
              variables: {
                params: {
                  apiKey: payload.apiKey,
                  endpoint: payload.endpoint,
                  username: payload.username
                }
              }
            })
          }
          render={form => (
            <Form>
              <FormikFieldGroup.Container legend="Confluence Version">
                <FormikFieldInputs.Radio name="version" value="cloud">
                  <strong>Cloud</strong>
                </FormikFieldInputs.Radio>
                <FormikFieldInputs.Radio name="version" value="onpremise">
                  <strong>On-premise</strong>
                </FormikFieldInputs.Radio>

                <FormikFieldGroup.HelpText
                  css={css`
                    margin-top: var(--space-2-rem);
                    margin-bottom: var(--space-4-rem);

                    strong {
                      font-weight: var(--font-weight-body-medium);
                    }
                  `}
                >
                  Choose <strong>Cloud</strong> if the URL you're using to access Confluence looks like
                  https://acme.atlassian.net/wiki/. Otherwise choose <strong>On-premise</strong>.
                </FormikFieldGroup.HelpText>
              </FormikFieldGroup.Container>
              <FormikFieldGroup.Container
                legend="Endpoint"
                css={css`
                  & > * + * {
                    margin-top: var(--space-2-rem);
                  }
                `}
              >
                <Field
                  name="endpoint"
                  component={FormikInput}
                  hideErrorLabel
                  placeholder={
                    form.values.version === "cloud"
                      ? "e.g. https://acme.atlassian.net/wiki/"
                      : "e.g. https://confluence.acme.corp//"
                  }
                />
                <FormikFieldGroup.HelpText>
                  URL through which you access your Confluence instance, including the initial https://.
                </FormikFieldGroup.HelpText>
                <FormikFieldGroup.Errors>
                  <ErrorMessage name="endpoint" />
                </FormikFieldGroup.Errors>
              </FormikFieldGroup.Container>
              <FormikFieldGroup.Container
                legend="Username"
                css={css`
                  & > * + * {
                    margin-top: var(--space-2-rem);
                  }
                `}
              >
                <Field
                  name="username"
                  component={FormikInput}
                  hideErrorLabel
                  placeholder={form.values.version === "cloud" ? "e.g. jane.doe@acme.corp" : "e.g. jane.doe"}
                />
                <FormikFieldGroup.HelpText>
                  {form.values.version === "cloud" ? (
                    "Your username is usually the same as your email address that you use to log in including the domain, e.g. jane.doe@acme.corp."
                  ) : (
                    <>
                      Your username is usually the part of your email address before '@', e.g. jane.doe. See{" "}
                      <Typo.ExternalLink href="https://app.intercom.com/a/apps/gvsyarjc/articles/articles/3935063/show">
                        our support article
                      </Typo.ExternalLink>{" "}
                      on how to find the username.
                    </>
                  )}
                </FormikFieldGroup.HelpText>
                <FormikFieldGroup.Errors>
                  <ErrorMessage name="username" />
                </FormikFieldGroup.Errors>
              </FormikFieldGroup.Container>
              <FormikFieldGroup.Container
                legend={form.values.version === "cloud" ? "API token" : "Password"}
                css={css`
                  & > * + * {
                    margin-top: var(--space-2-rem);
                  }
                `}
              >
                <Field name="apiKey" component={FormikInput} type="password" hideErrorLabel />
                <FormikFieldGroup.HelpText>
                  {form.values.version === "cloud" ? (
                    <>
                      Use the API token created in your{" "}
                      <Typo.ExternalLink href="https://id.atlassian.com/manage-profile/security/api-tokens">
                        Atlassian account
                      </Typo.ExternalLink>
                      .
                    </>
                  ) : (
                    "Use the account password that you normally use to log in."
                  )}
                </FormikFieldGroup.HelpText>
                <FormikFieldGroup.Errors>
                  <ErrorMessage
                    name="apiKey"
                    render={() =>
                      `You need to provide ${form.values.version === "cloud" ? "an API token" : "a password"}.`
                    }
                  />
                </FormikFieldGroup.Errors>
              </FormikFieldGroup.Container>

              <div css={[row, justify.end]}>
                <SubmitButton disabled={response.loading || !form.isValid}>Submit</SubmitButton>
              </div>
            </Form>
          )}
        />
      </Dialog>
    </Modal>
  );
};
