import { PureQueryOptions } from "apollo-client";
import merge from "lodash/merge";
import * as React from "react";
import { createContext, FC, useContext, useReducer } from "react";
import { QueryResult, useQuery } from "react-apollo";
import { REQUEST_LIST_COUNTS } from "src/App/Sidebar/RequestListCounts";
import { OrderByKey, RequestListView, ViewType } from "src/globalTypes";
import { keys } from "src/util";
import { trackRequestCardExpandedCollapsed, trackViewChanged, trackViewFiltered } from "src/util/analytics";
import { sortList } from "./Filters";
import { FilterMap } from "./Filters/urlParamHelpers";
import { REQUEST_LIST_GET } from "./REQUEST_LIST_GET";
import { RequestListGet, RequestListGetVariables } from "./typings/RequestListGet";

export interface RequestListState {
  viewType: ViewType;
  expandedRequests: {
    [requestId: string]: boolean;
  };
  visiblePills: {
    [requestId: string]: {
      [pillId: string]: boolean;
    };
  };
}

interface RequestListContext {
  viewId: string;
  listViewType: RequestListView;
  response: QueryResult<RequestListGet, RequestListGetVariables>;
  queriesToRefetch: Array<string | PureQueryOptions>;
  state: RequestListState;
  orderByKey: OrderByKey;
  filters: FilterMap;
  filtersFromView: FilterMap;
  filtersFromUrl: FilterMap;
  serializeFilters(): string;
  haveFiltersDivergedForView: boolean;
  filterBarHeight: number;
  shouldDisplayPagination: boolean;
  isSearchView: boolean;
}

type RequestListAction =
  | {
      type: "LOAD_VIEW";
      viewId: string;
    }
  | {
      type: "SET_VIEW_TYPE";
      viewId: string;
      viewType: ViewType;
    }
  | {
      type: "TOGGLE_EXPANDED_REQUEST";
      requestId: string;
      userInitiated: boolean;
    }
  | {
      type: "TOGGLE_VISIBLE_PILLS";
      requestId: string;
      pillId: string;
    };

const State = createContext<RequestListContext | undefined>(undefined);
const Dispatch = createContext<React.Dispatch<RequestListAction> | undefined>(undefined);

function reducer(state: RequestListState, action: RequestListAction) {
  switch (action.type) {
    case "LOAD_VIEW":
      return {
        viewType: getViewFromStorage(action.viewId) ?? ViewType.CLASSIC,
        expandedRequests: {},
        visiblePills: {}
      };
    case "SET_VIEW_TYPE":
      setViewInStorage({ [action.viewId]: action.viewType });
      trackViewChanged(action.viewType);
      return {
        ...state,
        viewType: action.viewType
      };
    case "TOGGLE_EXPANDED_REQUEST":
      const shouldExpand = !state.expandedRequests[action.requestId];
      trackRequestCardExpandedCollapsed(shouldExpand, {
        requestId: action.requestId,
        userInitiated: action.userInitiated
      });
      return {
        ...state,
        expandedRequests: {
          ...state.expandedRequests,
          [action.requestId]: shouldExpand
        }
      };
    case "TOGGLE_VISIBLE_PILLS":
      return {
        ...state,
        visiblePills: {
          ...state.visiblePills,
          [action.requestId]: {
            ...state.visiblePills[action.requestId],
            [action.pillId]:
              state.visiblePills[action.requestId] && !!state.visiblePills[action.requestId][action.pillId]
                ? !state.visiblePills[action.requestId][action.pillId]
                : true
          }
        }
      };
  }
}

export const RequestListProvider: FC<{
  viewId: string;
  listViewType: RequestListView;
  filtersFromView: FilterMap;
  filtersFromUrl: FilterMap;
  orderByKey: OrderByKey;
  pageNumber: number;
  shouldPaginate: boolean;
  filterBarHeight?: number;
}> = props => {
  const [state, dispatch] = useReducer(reducer, {
    viewType: getViewFromStorage(props.viewId) ?? ViewType.CLASSIC,
    expandedRequests: {},
    visiblePills: {}
  });

  React.useEffect(() => {
    dispatch({
      type: "LOAD_VIEW",
      viewId: props.viewId
    });
  }, [props.viewId]);

  const filters: FilterMap = {};
  // mutate filters
  merge(filters, props.filtersFromUrl);
  merge(filters, props.filtersFromView);

  const haveFiltersDivergedForView = Object.entries(props.filtersFromUrl)
    .map(([k, f]) => f?.length ?? 0)
    .some(n => n > 0);
  const serializeFilters = () => serialize(filters);

  const sort = sortList.find(s => s.key === props.orderByKey)?.sort ?? sortList[0].sort;
  const query = {
    filters: serializeFilters(),
    pageNumber: props.shouldPaginate ? props.pageNumber - 1 : null,
    pageSize: props.shouldPaginate ? 100 : null,
    sort: sort
  };

  const response = useQuery<RequestListGet, RequestListGetVariables>(REQUEST_LIST_GET, {
    fetchPolicy: "network-only",
    variables: {
      view: RequestListView.VIEW,
      query
    }
  });

  const queriesToRefetch = [
    {
      query: REQUEST_LIST_GET,
      variables: {
        view: RequestListView.VIEW,
        query
      }
    },
    {
      query: REQUEST_LIST_COUNTS
    }
  ];

  return (
    <State.Provider
      value={{
        viewId: props.viewId,
        response,
        queriesToRefetch,
        state,
        orderByKey: props.orderByKey,
        filters,
        filtersFromView: props.filtersFromView,
        filtersFromUrl: props.filtersFromUrl,
        haveFiltersDivergedForView,
        serializeFilters,
        filterBarHeight: props.filterBarHeight ?? 0,
        shouldDisplayPagination:
          props.shouldPaginate &&
          (response.data?.requestListPage.count ?? 0) > (response.variables.query.pageSize ?? 0),
        listViewType: props.listViewType,
        isSearchView: !!filters.fts?.length
      }}
    >
      <Dispatch.Provider value={dispatch}>
        {props.children}
        <AccumulatedTracking />
      </Dispatch.Provider>
    </State.Provider>
  );
};

export function useRequestList() {
  const state = useContext(State);
  const dispatch = useContext(Dispatch);
  if (!state || !dispatch) {
    throw new Error("useRequestList must be called from RequestListProvider child");
  }
  return [state, dispatch] as const;
}

export function serialize(filters: FilterMap): string {
  const transformedState = Object.entries(filters).flatMap(([key, values]) => values?.map(v => [key, v]) ?? []);
  const urlFilterParams = new URLSearchParams(transformedState);
  return urlFilterParams.toString();
}

function getViewFromStorage(viewId: string): ViewType | null {
  const stored = localStorage.getItem("viewSetting");
  return stored !== null && JSON.parse(stored)[viewId] ? JSON.parse(stored)[viewId] : null;
}

function setViewInStorage(setting: { [id: string]: ViewType }) {
  const stored = localStorage.getItem("viewSetting");
  localStorage.setItem("viewSetting", JSON.stringify({ ...JSON.parse(stored ?? "{}"), ...setting }));
}

/* Track accumulated changes in the request list */
function AccumulatedTracking() {
  const [requestList] = useRequestList();

  /* track accumulated filter counts */
  const {
    filtersFromUrl,
    haveFiltersDivergedForView,
    listViewType,
    state: { viewType }
  } = requestList;
  React.useEffect(() => {
    const shouldTrack =
      haveFiltersDivergedForView &&
      keys(filtersFromUrl).reduce((acc, filterType) => acc + (filtersFromUrl[filterType]?.length ?? 0), 0) > 0;
    if (shouldTrack) {
      trackViewFiltered(
        listViewType,
        {
          assignee: filtersFromUrl.assignee?.length,
          category: filtersFromUrl.category?.length,
          status: filtersFromUrl.status?.length,
          requester: filtersFromUrl.requester?.length,
          team: filtersFromUrl.team?.length,
          priority: filtersFromUrl.priority?.length,
          customStatus: filtersFromUrl.customStatus?.length
        },
        null,
        viewType
      );
    }
  }, [filtersFromUrl, haveFiltersDivergedForView, listViewType, viewType]);
  return null;
}
