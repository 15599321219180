import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { mdiCheck, mdiChevronLeft, mdiClose } from "@mdi/js";
import { PureQueryOptions } from "apollo-client";
import { formatISO9075 } from "date-fns";
import gql from "graphql-tag";
import random from "lodash/random";
import sortBy from "lodash/sortBy";
import * as React from "react";
import { memo, useContext, useEffect, useReducer, useRef, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import rough from "roughjs/dist/rough.umd";
import { InlineFormModal } from "src/App/Forms/Manager/Modal";
import { formReducer, initialFormState } from "src/App/Forms/Manager/reducer";
import { ProjectNames } from "./typings/ProjectNames";
import { AssignExpertButton } from "src/App/Requests/Actions/AssignExpertButton";
import { ChangeDueDatePicker } from "src/App/Requests/Actions/ChangeDueDatePicker";
import { ChangeProjectButton } from "src/App/Requests/Actions/ChangeProjectButton";
import { ActionsContext } from "src/App/Requests/Actions/Provider";
import { priorityLabels, RequestPriorityButton } from "src/App/Requests/Actions/RequestPriorityButton";
import { PollingRequestData } from "src/App/Requests/DetailView/Container";
import { REQUEST_GET } from "src/App/Requests/DetailView/REQUEST_GET";
import { Views } from "src/App/Requests/ListView/typings/Views";
import { VIEWS } from "src/App/Requests/ListView/ViewContainer";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { FeatureFlags, useFeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { routeData } from "src/App/Root/RouteData";
import { ManualTaskBanner, TaskCompletedSuccessAnimation } from "src/App/Tasks/ManualTaskBanner";
import { UserName } from "src/App/User";
import { ReactComponent as Jira } from "src/assets/logos/Jira.svg";
import {
  AbsoluteTooltip,
  Col,
  DateTime,
  EllipsisText,
  EmptyState,
  Icon,
  LoadingBar,
  MaterialIcon,
  Row,
  SimpleTooltip,
  SquareButton,
  Tooltip,
  UnderlineButton
} from "src/components";
import { AnimatedStack } from "src/components/animations/AnimatedStack";
import { Pill } from "src/components/PillButton";
import { PopOver } from "src/components/PopOver";
import { colorVariantMappings, ColorVariantsUnion, Status } from "src/components/StatusIndicator";
import { ChannelType, RequestChannels, RequestStatus, TeamWorkflowStatus } from "src/globalTypes";
import { Toast } from "src/portals/Toast";
import { fog } from "src/styling/effects";
import { stackOrder } from "src/styling/layout";
import { fontSize } from "src/styling/primitives";
import { Typo } from "src/styling/primitives/typography";
import zIndices from "src/styling/tokens/z-indices.json";
import { fontWeights } from "src/styling/typography";
import { Flash } from "src/svg/icons/Flash";
import { reportDevError } from "src/util";
import { csx } from "src/util/csx";
import { formatDueDate, formatTitle } from "src/util/formatters";
import { navigate } from "src/util/router";
import { useHotKeys } from "src/util/services/hotkeys";
import { AssignCCs } from "./AssignCCs";
import { InputBar } from "./InputBar/Container";
import { MarkRead } from "./MarkRead";
import { RequesterProfile } from "./RequesterProfile";
import { DisabledInteractionWrapper, JiraModal, JiraNote } from "./RequestNotes/JiraNote";
import { OktaPopupMenu, useOktaProfile } from "./Okta";
import { ManualTaskModal } from "./RequestNotes/ManualTaskModal";
import { SLAItem, SLATabDotIcon } from "./SLA/components";
import { SLAType } from "./SLA/utils";
import { TeamWorkflowsSection } from "./TeamWorkflows";
import { Timeline } from "./Timeline/Component";
import { JiraProjects } from "./typings/JiraProjects";
import { RequestGetVariables, RequestGet_request, RequestGet_request_customStatus } from "./typings/RequestGet";
import { RequestLinkJiraIssue, RequestLinkJiraIssueVariables } from "./typings/RequestLinkJiraIssue";
import { RequestTitleChange, RequestTitleChangeVariables } from "./typings/RequestTitleChange";
import { RequestUnlinkJiraIssue, RequestUnlinkJiraIssueVariables } from "./typings/RequestUnlinkJiraIssue";
import { TeamWorkflows, TeamWorkflowsVariables } from "./typings/TeamWorkflows";
import { DeleteCommentAttachment, DeleteCommentAttachmentVariables } from "./typings/DeleteCommentAttachment";
import { DeleteNoteAttachment, DeleteNoteAttachmentVariables } from "./typings/DeleteNoteAttachment";
import { AttachmentDeleteConfirm } from "./AttachmentDeleteConfirm";

const RequestDetail = csx([
  css`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: 0.875rem;
    height: 100%;
    width: 56.25vw;
    min-width: var(--dimension-general-minWidth);
    max-width: 51.25rem;
    padding: var(--space-7-rem);
    @media print {
      order: 3;
      width: 100%;
      max-width: initial;
      padding: 0;
    }
  `
]);

const Header = csx([
  css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: var(--space-1-px);
    z-index: var(--z-low);
    position: relative;

    &:before {
      /*  absolte position is needed to have gradient overlay the
          following elements despite flex height calculation
       */
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      position: absolute;
      pointer-events: none;
      ${fog.bottom.shadow}
    }

    & > * + * {
      margin-top: var(--space-3-px);
    }
  `
]);

const PrintHeader = csx([
  css`
    display: none;
    @media print {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: var(--space-1-px);
    }
  `
]);

const Requester = styled.span`
  line-height: 1.5;
  margin: 0 0.5rem 0 0;
`;

const Author = csx(
  [
    css`
      line-height: 1.5;
      color: rgb(var(--text-4-raw));
    `
  ],
  {},
  "span"
);

const Wrapper = csx(
  [
    css`
      display: flex;
      flex: 1;
      overflow: hidden;
    `
  ],
  {}
);

const Main = csx(
  [
    css`
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      justify-content: space-between;
      /* Max width is needed to prevent long texts somewhere further down the dom tree from breaking the layout.
      Don't remove. Just don't. */
      max-width: 100%;

      @media print {
        position: absolute;
        left: 0;
        width: 100%;
      }
    `
  ],
  {}
);

const RequestSidebar = csx(
  [
    css`
      max-width: 20rem;
      min-width: 17.5rem;
      width: 21.875vw;
      padding-top: var(--space-5-rem);
      overflow-y: auto;

      @media screen {
        border-left: 1px var(--lightGrey-2) solid;
      }
      @media print {
        order: 2;
        width: 100%;
        max-width: 100%;
        border-bottom: 1px solid var(--box);
        padding-top: 0;
      }
    `
  ],
  {}
);

export const Subheading = csx(
  [
    css`
      font-weight: ${fontWeights.bold};
      line-height: 1.5;
      color: var(--text-2);
    `,
    fontSize.S
  ],
  {
    mediumFont: css`
      font-size: 1rem;
    `,

    darkText: css`
      color: var(--text-6);
    `
  }
);

export const PillIcon = styled.span<{ padding?: string }>`
  margin-right: 0.125rem;
  padding: ${p => p.padding || "0"};
`;

const Scribble = csx(
  [
    css`
      position: absolute;
      width: 0;
      height: 40px;
      top: -4px;
      left: -30px;
      z-index: -1;
      transition: width 0.2s ease-out;
    `
  ],
  {},
  "canvas"
);

const TitleContainer = csx(
  [
    css`
      position: relative;
      display: flex;
      z-index: ${stackOrder.default};
      font-family: var(--font-family-body);
      font-size: var(--font-size-display-small);
      font-weight: var(--font-weight-body-medium);
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: calc(100% - 2rem);
      border: none;
      border-bottom: 1px solid transparent;
      padding-bottom: 2px;
    `
  ],
  {
    editable: css`
      &:hover {
        cursor: pointer;
        border-bottom: 1px solid var(--text-6);
      }
    `
  }
);

const PillContainer = csx(
  [
    css`
      & > * + * {
        margin-top: var(--space-2-rem);
        display: inline-block;
      }
    `
  ],
  {
    disabled: css`
      & > * + * {
        /* disable the pill which is always the second child */
        pointer-events: none;
        opacity: 0.6;
      }
    `
  }
);

const TitleEditorUnderlay = csx([
  css`
    background-color: var(--overlayBackground);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    z-index: var(--z-low);
  `
]);

const Title: React.FC<{
  step: RequestStatus;
  strikethroughColor: RequestGet_request_customStatus["color"];
  requestId: string;
  title: string;
  queriesToRefetch?: Array<string | PureQueryOptions>;
}> = ({ step, strikethroughColor, requestId, title = "", queriesToRefetch }) => {
  const { emitSnack } = useSnack();

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState(title);
  const [changeRequestTitle, changeRequestTitleResponse] = useMutation<RequestTitleChange, RequestTitleChangeVariables>(
    gql`
      mutation RequestTitleChange($requestId: ID!, $title: String!) {
        requestChangeTitle(requestId: $requestId, title: $title) {
          code
          success
          message
        }
      }
    `,
    {
      awaitRefetchQueries: true,
      refetchQueries: queriesToRefetch,
      update: (_, res) => {
        if (res.data?.requestChangeTitle.success) {
          emitSnack({
            type: "info",
            message: "Title changed successfully!"
          });
        } else {
          emitSnack({
            type: "mutationError",
            message: res.data?.requestChangeTitle.message ?? "Could not change title!"
          });
        }
      }
    }
  );

  // draws the green paint like strike through request title when
  // the request is resolved
  useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current.style.width = "0";
      const ctx = canvasRef.current.getContext("2d");
      if (ctx) {
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      }
    }
    const timeout = window.setTimeout(() => {
      if (step === RequestStatus.RESOLVED && canvasRef.current) {
        const rc = rough.canvas(canvasRef.current);
        const options = {
          roughness: 5,
          stroke:
            colorVariantMappings[strikethroughColor as ColorVariantsUnion]?.bgActive ??
            colorVariantMappings.green.bgActive,
          strokeWidth: 50
        };

        const maxDelta = 2;
        let prevX = 30;
        let prevY = 80;
        let y = prevY;
        for (let x = prevX; x < 270; x += random(1, 5)) {
          y = y + random(-maxDelta, maxDelta);
          rc.line(prevX, prevY, x, y, options);
          prevX = x;
          prevY = y;
        }
        canvasRef.current.style.width = "calc(100% + 30px + 30px)";
      }
    }, 250);

    return () => window.clearTimeout(timeout);
  }, [step, strikethroughColor]);

  // this is used to configure the no., of rows shown by the textarea as the browser
  // is awesome at splitting things correctly into lines.
  const noOfLinesInTitle = title.split("\n").length + 1;

  return (
    <>
      {changeRequestTitleResponse.loading && <LoadingBar />}
      {isEditingTitle && <TitleEditorUnderlay onClick={() => setIsEditingTitle(false)} />}
      <div
        css={css`
          position: relative;
          width: 100%;
        `}
      >
        <TitleContainer onClick={() => setIsEditingTitle(true)} editable data-intercom-target="Request title">
          <EllipsisText>{formatTitle(title)} </EllipsisText>
          <Scribble ref={canvasRef} />
        </TitleContainer>
        {isEditingTitle && (
          <div
            css={css`
              background: var(--white);
              position: absolute;
              display: flex;
              width: 100%;
              border: none;
              border-bottom: 0.0625rem solid var(--text-6);
              border-radius: 0.25rem 0.25rem 0 0;
              box-shadow: var(--box-shadow-card);
              padding: 0.5rem;
              top: -0.3rem;
              left: -0.45rem;
              z-index: var(--z-low);
            `}
          >
            <textarea
              css={css`
                width: 100%;
                font-size: var(--font-size-display-small);
                resize: none;
                border: none;
                font-family: var(--font-family-body);
                padding: 0;
              `}
              value={textAreaValue}
              onChange={e => setTextAreaValue(e.target.value)}
              rows={Math.min(noOfLinesInTitle, 5)}
            ></textarea>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                padding-left: 0.5rem;
                & > button:first-of-type {
                  margin-bottom: 0.25rem;
                }
              `}
            >
              <SquareButton
                onClick={() => {
                  setIsEditingTitle(false);
                  changeRequestTitle({
                    variables: {
                      requestId,
                      title: textAreaValue
                    }
                  });
                }}
                size="small"
                variant="primary"
              >
                <MaterialIcon path={mdiCheck} size={1} />
              </SquareButton>
              <SquareButton onClick={() => setIsEditingTitle(false)} size="small" variant="secondary">
                <MaterialIcon path={mdiClose} size={1} />
              </SquareButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const channelInfo = (request: RequestGet_request): JSX.Element => {
  switch (request.channel) {
    case RequestChannels.SLACK:
      if (request.origin && request.origin.channel.type === ChannelType.PUBLIC) {
        return (
          <span data-intercom-target="Request channel: Slack Public">{`in #${request.origin.channel.name} on Slack`}</span>
        );
      }
      return <span data-intercom-target="Request channel: Slack">via Slack</span>;
    case RequestChannels.EMAIL:
      return <span data-intercom-target="Request channel: Email">via Email</span>;
    case RequestChannels.MSTEAMS:
      return <span data-intercom-target="Request channel: MS Teams">via MS Teams</span>;
    case RequestChannels.GCHAT:
      return <span data-intercom-target="Request channel: Google Chat">via Google Chat</span>;
    case RequestChannels.WORKFLOWS:
      return <span data-intercom-target="Request channel: Workflows">via Workflows</span>;
    case RequestChannels.JIRA:
      return <span data-intercom-target="Request channel: Jira">via Jira</span>;
    case RequestChannels.CONVERSATIONS:
      return <span data-intercom-target="Request channel: Outreach">via Outreach</span>;
    case RequestChannels.WEB:
      return <span data-intercom-target="Request channel: Web">via Web</span>;
    case RequestChannels.REQUEST_CHANNEL_UNKNOWN:
    case RequestChannels.UNSETREQUESTCHANNELS:
      reportDevError("unset channel for request " + request.id);
      return <span data-intercom-target="Request channel: Web">via Web</span>;
  }
};

const PROJECT_LIST = gql`
  query ProjectNames {
    requestList(query: { filters: "type=project" }) {
      id
      title
    }
  }
`;

export const RequestComponent: React.ComponentType<PollingRequestData> = memo(props => {
  const { hasFeatureFlags } = useFeatureFlags();
  const { handlers, options, optionsByTeam } = useContext(ActionsContext);
  const { currentUser } = useCurrentUser();
  const { emitSnack } = useSnack();

  const viewsResponse = useQuery<Views>(VIEWS);
  const projectsResponse = useQuery<ProjectNames>(PROJECT_LIST);
  const requestListViewOrProjectId: string =
    window.history.state?.requestListViewId ?? routeData.requests.subpaths?.me.pathname ?? "";

  const requestListView = viewsResponse.data?.views.find(v => v.id === requestListViewOrProjectId);
  const project = projectsResponse.data?.requestList.find(p => p.id === requestListViewOrProjectId);

  const backButtonProps = {
    name: project ? `Project ${project?.title}` : `Requests: ${requestListView?.name || "Assigned to me"}`,
    path: project
      ? `/${routeData.projects.pathname}/${requestListViewOrProjectId}`
      : `/${routeData.requests.pathname}/${requestListViewOrProjectId}${window.location.search}`
  };

  const returnToRequestsOverview = () => {
    navigate(backButtonProps.path);
  };

  const [shownActionDropdown, setShownActionDropdown] = useState<
    "status" | "priority" | "category" | "assignee" | "dueDate" | "team" | null
  >(null);

  useHotKeys({
    name: "Return to requests overview",
    group: "request detail",
    keys: "r",
    handler: returnToRequestsOverview
  });
  useHotKeys({
    name: "Set request status",
    group: "request detail",
    keys: "s",
    handler: () => {
      setShownActionDropdown(c => (c === "status" ? null : "status"));
    }
  });
  useHotKeys({
    name: "Assign request a priority",
    group: "request detail",
    keys: "p",
    handler: () => {
      setShownActionDropdown(c => (c === "priority" ? null : "priority"));
    }
  });
  useHotKeys({
    name: "Assign request to an expert",
    group: "request detail",
    keys: "a",
    handler: () => {
      setShownActionDropdown(c => (c === "assignee" ? null : "assignee"));
    }
  });
  useHotKeys({
    name: "Assign request to a team",
    group: "request detail",
    keys: "t",
    handler: () => {
      setShownActionDropdown(c => (c === "team" ? null : "team"));
    }
  });
  useHotKeys({
    name: "Set request category",
    group: "request detail",
    keys: "c",
    handler: () => {
      setShownActionDropdown(c => (c === "category" ? null : "category"));
    }
  });
  useHotKeys({
    name: "Set request due date",
    group: "request detail",
    keys: "d",
    handler: () => {
      setShownActionDropdown(c => (c === "dueDate" ? null : "dueDate"));
    }
  });

  useEffect(() => {
    // if user is not in team redirect to list view
    // NB: request team data only available after optismtic update
    // server returns null for no access request
    const isNotMemberOfNewTeam =
      currentUser?.id &&
      props.requestResponse?.data &&
      !props.requestResponse.data.request.team.userIds.includes(currentUser.id);
    if (!props.requestResponse?.loading && isNotMemberOfNewTeam) {
      navigate("/view/all");
    }
  }, [props.requestResponse, currentUser]);

  // Make SLA times in SLA tab "tick"
  const { startPolling, stopPolling } = props.requestResponse;
  useEffect(() => {
    startPolling(60000);
    return () => stopPolling();
  }, [startPolling, stopPolling]);

  const slaStatus = props.requestResponse.data?.request?.slaStatus;
  const timelineRef = useRef(null);
  const inputBarRef = useRef<HTMLDivElement>(null);
  const [formState, formDispatch] = useReducer(formReducer, initialFormState);
  const [selectedSidebarView, setSidebarView] = useState<"INFO" | "REQUESTER" | "SLA" | "WORKFLOWS">("INFO");
  const [jiraModalOpen, setJiraModalOpen] = useState<boolean>(false);
  const [createManualTaskModalOpen, setCreateManualTaskModalOpen] = useState<boolean>(false);

  // load workflows here so we can check whether to display sidebar button & define team id dep
  const teamWorkflowsResponse = useQuery<TeamWorkflows, TeamWorkflowsVariables>(
    gql`
      query TeamWorkflows($id: ID!) {
        team(id: $id) {
          workflows {
            id
            name
            description
            icon
            status
            triggers {
              trigger {
                ... on WorkflowTriggerManual {
                  parameters {
                    name
                    description
                    type
                    options
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      skip: !props.requestResponse.data?.request.team.id,
      fetchPolicy: "network-only",
      variables: {
        id: props.requestResponse.data?.request.team.id ?? ""
      }
    }
  );

  // check if teams linked to jira project
  const jiraProjectsResponse = useQuery<JiraProjects>(
    gql`
      query JiraProjects {
        integrationJira {
          linkedProjects {
            teamId
            createTime
            project {
              baseUrl
              key
              description
              name
            }
          }
        }
      }
    `,
    {
      skip: !hasFeatureFlags(FeatureFlags.JIRA)
    }
  );
  const linkedJiraProject = jiraProjectsResponse?.data?.integrationJira?.linkedProjects.find(
    project => project.teamId === props.requestResponse.data?.request.team.id
  );
  const [requestLinkJiraIssue, requestLinkJiraIssueResponse] = useMutation<
    RequestLinkJiraIssue,
    RequestLinkJiraIssueVariables
  >(
    gql`
      mutation RequestLinkJiraIssue($requestId: ID!) {
        requestLinkJiraIssue(requestId: $requestId) {
          code
          message
          success
          request {
            jiraLinkedIssue {
              key
              url
            }
          }
        }
      }
    `,
    {
      onCompleted: data => {
        if (data.requestLinkJiraIssue.success) {
          emitSnack({
            message: `Request successfully linked to the Jira issue ${data.requestLinkJiraIssue.request?.jiraLinkedIssue?.key}.`,
            type: "info"
          });
        } else {
          emitSnack({
            message: data.requestLinkJiraIssue.message,
            type: "mutationError"
          });
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: REQUEST_GET,
          variables: {
            id: props.requestId
          } as RequestGetVariables
        }
      ]
    }
  );
  const [requestUnLinkJiraIssue, requestUnlinkJiraIssueResponse] = useMutation<
    RequestUnlinkJiraIssue,
    RequestUnlinkJiraIssueVariables
  >(
    gql`
      mutation RequestUnlinkJiraIssue($requestId: ID!) {
        requestUnlinkJiraIssue(requestId: $requestId) {
          code
          message
          success
        }
      }
    `,
    {
      onCompleted: data => {
        if (data.requestUnlinkJiraIssue.success) {
          emitSnack({
            message: "Request successfully unlinked from Jira issue.",
            type: "info"
          });
        } else {
          emitSnack({
            message: data.requestUnlinkJiraIssue.message,
            type: "mutationError"
          });
        }
      },
      refetchQueries: [
        {
          query: REQUEST_GET,
          variables: {
            id: props.requestId
          } as RequestGetVariables
        }
      ]
    }
  );

  const [pendingCommentAttachmentDeletion, setPendingCommentAttachmentDeletion] = useState<
    DeleteCommentAttachmentVariables | undefined
  >(undefined);
  const [deleteCommentAttachment, deleteCommentAttachmentResponse] = useMutation<
    DeleteCommentAttachment,
    DeleteCommentAttachmentVariables
  >(
    gql`
      mutation DeleteCommentAttachment($commentId: ID!, $attachmentId: ID!) {
        commentAttachmentDelete(params: { commentId: $commentId, attachmentId: $attachmentId }) {
          code
          message
          success
        }
      }
    `,
    {
      onCompleted: () => {
        setPendingCommentAttachmentDeletion(undefined);
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: REQUEST_GET,
          variables: { id: props.requestId }
        }
      ]
    }
  );

  const [pendingNoteAttachmentDeletion, setPendingNoteAttachmentDeletion] = useState<
    DeleteNoteAttachmentVariables | undefined
  >(undefined);
  const [deleteNoteAttachment, deleteNoteAttachmentResponse] = useMutation<
    DeleteNoteAttachment,
    DeleteNoteAttachmentVariables
  >(
    gql`
      mutation DeleteNoteAttachment($noteId: ID!, $attachmentId: ID!) {
        noteAttachmentDelete(params: { noteId: $noteId, attachmentId: $attachmentId }) {
          code
          message
          success
        }
      }
    `,
    {
      onCompleted: () => {
        setPendingNoteAttachmentDeletion(undefined);
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: REQUEST_GET,
          variables: { id: props.requestId }
        }
      ]
    }
  );

  const linkedJiraIssue = props.requestResponse.data?.request.jiraLinkedIssue;
  const shouldShowLinkToJiraButton = hasFeatureFlags(FeatureFlags.JIRA) && !!linkedJiraProject && !linkedJiraIssue;
  const oktaUserProfile = useOktaProfile(props.requestResponse.data?.request.requester.email);

  const manuallyTriggeredWorkflows = (teamWorkflowsResponse.data?.team.workflows ?? []).filter(
    workflow =>
      workflow.status === TeamWorkflowStatus.ENABLED &&
      workflow.triggers.some(w => w.trigger.__typename === "WorkflowTriggerManual")
  );

  // render loading bar until request response was successful
  if (props.requestResponse.loading) {
    return <LoadingBar />;
  }

  const formattedDueDate =
    props.requestResponse.data && props.requestResponse.data.request && props.requestResponse.data.request.dueAt
      ? formatDueDate(new Date(props.requestResponse.data.request.dueAt))
      : "";
  const isUrgent =
    (formattedDueDate === "today" || formattedDueDate.includes("ago") || formattedDueDate.includes("yesterday")) &&
    props.requestResponse.data &&
    props.requestResponse.data.request &&
    props.requestResponse.data.request.customStatus.step !== RequestStatus.RESOLVED;

  const customStatuses = props.requestResponse.data?.request?.team?.customStatuses;
  const requestCurrentCustomStatus = props.requestResponse.data?.request?.customStatus;
  return (
    <Row
      css={css`
        justify-content: center;
        flex: 1 1 auto;
        @media print {
          flex-direction: column;
          padding: var(--space-3-rem);
        }
      `}
    >
      {(props.requestResponse.data && props.requestResponse.loading) ||
        requestLinkJiraIssueResponse.loading ||
        (requestUnlinkJiraIssueResponse.loading && <LoadingBar />)}
      {props.requestResponse.data && props.requestResponse.error && (
        <Toast message={props.requestResponse.data && props.requestResponse.error.message} kind="error" />
      )}
      {props.requestResponse.error && props.requestResponse.error.message.includes("7 PERMISSION_DENIED") && (
        <EmptyState
          title="Sorry: Unable to access this request"
          subtitle="You may be attempting to view a request from another team"
        />
      )}
      {!!(props.requestResponse.data && props.requestResponse.data.request && currentUser) && (
        <>
          <PrintHeader>
            <Typo.Body
              sizeS
              light
              css={css`
                margin-bottom: var(--space-5-rem);
              `}
            >
              Exported from Back - {formatISO9075(new Date())}
            </Typo.Body>
            <Typo.Body sizeXXL bold>
              {props.requestResponse.data?.request.title}
            </Typo.Body>
            <Typo.Body>
              <Requester>
                <UserName userId={props.requestResponse.data.request.requester.id} />
                &nbsp;requested&nbsp;
                <DateTime timestamp={props.requestResponse.data.request.requestedAt} />
                &nbsp;
                {channelInfo(props.requestResponse.data.request)}
              </Requester>
              {props.requestResponse.data.request.author &&
                props.requestResponse.data.request.author.id !== props.requestResponse.data.request.requester.id && (
                  <Author>
                    (Submitted by <UserName user={props.requestResponse.data.request.author} />)
                  </Author>
                )}
            </Typo.Body>
          </PrintHeader>
          <RequestDetail>
            <Header className="print-hidden">
              <div
                data-intercom-target="Request meta"
                data-testid="request-meta"
                css={css`
                  display: flex;
                  align-items: center;

                  & > * + * {
                    margin-left: var(--space-2-rem);
                  }
                `}
              >
                <SimpleTooltip zIndex={zIndices.highest.value} label={backButtonProps.name}>
                  <SquareButton
                    className="print-hidden"
                    variant="secondary"
                    onClick={returnToRequestsOverview}
                    size="small"
                  >
                    <MaterialIcon path={mdiChevronLeft} size={1.125} />
                  </SquareButton>
                </SimpleTooltip>

                {shouldShowLinkToJiraButton && linkedJiraProject && (
                  <>
                    <SimpleTooltip zIndex={zIndices.higher.value} label="Link to Jira">
                      <SquareButton
                        className="print-hidden"
                        variant="secondary"
                        onClick={() => setJiraModalOpen(true)}
                        size="small"
                      >
                        <Jira
                          css={css`
                            width: var(--space-3-rem);
                          `}
                        />
                      </SquareButton>
                    </SimpleTooltip>
                    <JiraModal
                      isOpen={jiraModalOpen}
                      onDismiss={() => setJiraModalOpen(false)}
                      onConfirm={() => {
                        requestLinkJiraIssue({
                          variables: { requestId: props.requestId }
                        });
                        setJiraModalOpen(false);
                      }}
                      linkedJiraProject={linkedJiraProject}
                    />
                  </>
                )}

                {hasFeatureFlags(FeatureFlags.OKTA) && (
                  <OktaPopupMenu
                    oktaDomain={oktaUserProfile.data?.integrationOkta?.domain}
                    oktaProfile={oktaUserProfile.data?.oktaUser}
                  />
                )}

                {hasFeatureFlags(FeatureFlags.MANUALTASKCREATE) && (
                  <>
                    <SimpleTooltip zIndex={zIndices.higher.value} label="Add New Task">
                      <SquareButton
                        className="print-hidden"
                        variant="secondary"
                        onClick={() => {
                          setCreateManualTaskModalOpen(true);
                        }}
                        size="small"
                      >
                        <Icon sizeS>
                          <Flash />
                        </Icon>
                      </SquareButton>
                    </SimpleTooltip>
                    <ManualTaskModal
                      requestId={props.requestId}
                      isOpen={createManualTaskModalOpen}
                      onCloseManualTaskModal={() => setCreateManualTaskModalOpen(false)}
                    />
                  </>
                )}

                {pendingCommentAttachmentDeletion && (
                  <AttachmentDeleteConfirm
                    deleting={deleteCommentAttachmentResponse.loading}
                    onConfirm={() => {
                      deleteCommentAttachment({ variables: pendingCommentAttachmentDeletion });
                    }}
                    onCancel={() => {
                      setPendingCommentAttachmentDeletion(undefined);
                    }}
                  />
                )}

                {pendingNoteAttachmentDeletion && (
                  <AttachmentDeleteConfirm
                    deleting={deleteNoteAttachmentResponse.loading}
                    onConfirm={() => {
                      deleteNoteAttachment({ variables: pendingNoteAttachmentDeletion });
                    }}
                    onCancel={() => {
                      setPendingNoteAttachmentDeletion(undefined);
                    }}
                  />
                )}

                <Typo.Body>
                  <Requester>
                    <UserName
                      userId={props.requestResponse.data.request.requester.id}
                      tooltip={true}
                      copyToClipboard={true}
                    />
                    &nbsp;requested&nbsp;
                    <DateTime timestamp={props.requestResponse.data.request.requestedAt} tooltip={true} />
                    &nbsp;
                    {channelInfo(props.requestResponse.data.request)}
                  </Requester>
                  {props.requestResponse.data.request.author &&
                    props.requestResponse.data.request.author.id !==
                      props.requestResponse.data.request.requester.id && (
                      <SimpleTooltip
                        label={
                          <>
                            <UserName
                              userId={props.requestResponse.data.request.author.id}
                              user={props.requestResponse.data.request.author}
                            />{" "}
                            submitted this request to Back on behalf of the requester,{" "}
                            <UserName userId={props.requestResponse.data.request.requester.id} />.
                          </>
                        }
                      >
                        <Author data-intercom-target="Request author (on-behalf only)">
                          (Submitted by{" "}
                          <UserName
                            userId={props.requestResponse.data.request.author.id}
                            user={props.requestResponse.data.request.author}
                          />
                          )
                        </Author>
                      </SimpleTooltip>
                    )}
                </Typo.Body>
              </div>
              <Title
                step={props.requestResponse.data.request.customStatus.step}
                strikethroughColor={props.requestResponse.data.request.customStatus.color}
                title={props.requestResponse.data?.request.title}
                requestId={props.requestId}
                queriesToRefetch={[
                  {
                    query: REQUEST_GET,
                    variables: {
                      id: props.requestId
                    }
                  }
                ]}
              />
              {!!linkedJiraIssue && (
                <AnimatedStack>
                  <JiraNote
                    requestId={props.requestId}
                    linkedJiraIssue={linkedJiraIssue}
                    onUnlink={() => requestUnLinkJiraIssue({ variables: { requestId: props.requestId } })}
                  />
                </AnimatedStack>
              )}
              <TaskCompletedSuccessAnimation>
                {triggerSuccessAnimation => (
                  <AnimatedStack>
                    {sortBy(props.requestResponse.data?.request.tasks, t => t.createTime).map((task, _, allTasks) => (
                      <ManualTaskBanner
                        key={task.createTime}
                        task={task}
                        tasksShown={allTasks.length}
                        onSuccess={triggerSuccessAnimation}
                      />
                    ))}
                  </AnimatedStack>
                )}
              </TaskCompletedSuccessAnimation>
            </Header>
            <MarkRead key={props.requestResponse.data.request.events.length} requestId={props.requestId} />
            <Wrapper>
              <Main data-testid="request-loaded">
                <Timeline
                  onCommentAttachmentDelete={(commentId, attachmentId) => {
                    setPendingCommentAttachmentDeletion({ commentId, attachmentId });
                  }}
                  onNoteAttachmentDelete={(noteId, attachmentId) => {
                    setPendingNoteAttachmentDeletion({ noteId, attachmentId });
                  }}
                  {...props}
                />
                <InputBar
                  request={props.requestResponse.data.request}
                  timelineRef={timelineRef}
                  inputBarRef={inputBarRef}
                  formState={formState}
                  formDispatch={formDispatch}
                />

                <InlineFormModal
                  teamId={props.requestResponse.data.request.team.id}
                  state={formState}
                  dispatch={formDispatch}
                  dismiss={() => {
                    formDispatch({
                      type: "SET_MODAL",
                      modal: null
                    });
                    // remove search params
                    navigate(window.location.pathname);
                  }}
                />
              </Main>
            </Wrapper>
          </RequestDetail>
          <RequestSidebar data-intercom-target="Request side panel">
            <div
              css={css`
                padding: var(--space-4-rem) var(--space-6-rem);
                @media print {
                  padding: var(--space-3-rem) 0 var(--space-5-rem);
                }
              `}
            >
              <Row
                className="print-hidden"
                margin="0 0 1.5rem 0"
                css={css`
                  & > * + * {
                    margin-left: var(--space-2-rem) !important;
                  }
                `}
              >
                <UnderlineButton
                  selected={selectedSidebarView === "INFO"}
                  onClick={() => {
                    setSidebarView("INFO");
                  }}
                >
                  Info
                </UnderlineButton>
                <UnderlineButton
                  selected={selectedSidebarView === "REQUESTER"}
                  onClick={() => setSidebarView("REQUESTER")}
                >
                  Requester
                </UnderlineButton>
                {hasFeatureFlags(FeatureFlags.SLA) && slaStatus && props.requestResponse.data.request.sla && (
                  <div
                    css={css`
                      display: flex;
                      align-items: center;

                      &:hover {
                        cursor: pointer;
                      }
                    `}
                    onClick={() => {
                      setSidebarView("SLA");
                    }}
                  >
                    <UnderlineButton selected={selectedSidebarView === "SLA"}>SLA</UnderlineButton>
                    {props.requestResponse.data.request.sla && slaStatus && (
                      <SLATabDotIcon
                        css={css`
                          margin-left: 0.1875rem;
                        `}
                        sla={{
                          minutesToFirstResponse: props.requestResponse.data.request.sla.minutesToFirstResponse,
                          minutesToResolution: props.requestResponse.data.request.sla.minutesToResolution
                        }}
                        slaStatus={slaStatus}
                        slaTabSelected={selectedSidebarView === "SLA"}
                      />
                    )}
                  </div>
                )}
                {hasFeatureFlags(FeatureFlags.WORKFLOWS) && !!manuallyTriggeredWorkflows.length && (
                  <UnderlineButton
                    selected={selectedSidebarView === "WORKFLOWS"}
                    onClick={() => setSidebarView("WORKFLOWS")}
                  >
                    Workflows
                  </UnderlineButton>
                )}
              </Row>
              <Col
                css={css`
                  & > * + * {
                    margin-top: var(--space-5-rem);
                  }
                  @media print {
                    flex-wrap: wrap;
                    flex-direction: row;
                    & > * {
                      margin-top: var(--space-2-rem);
                      margin-right: var(--space-5-rem);
                      flex: 0 0 auto;
                    }
                  }
                `}
              >
                {selectedSidebarView === "INFO" && (
                  <>
                    <PillContainer disabled={!!linkedJiraIssue}>
                      <DisabledInteractionWrapper disabled={!!linkedJiraIssue}>
                        <Subheading>Status</Subheading>
                      </DisabledInteractionWrapper>
                      <Tooltip
                        placement="top"
                        target={
                          <PopOver.Menu
                            isOpen={shownActionDropdown === "status"}
                            enableOptionHotKeys={shownActionDropdown === "status"}
                            onClose={() => setShownActionDropdown(null)}
                            options={[
                              {
                                headline: "set status",
                                // all the ! coercion since all this stuff is rendered only when
                                // props.requestResponse.data.request is available and backend guarantees
                                // that the request object will always have .team.customStatuses and .customStatus
                                // TODO: Untangle this component's control flow to make this stuff unnecessary
                                options: (customStatuses ?? []).map(({ id, name, step, color }) => ({
                                  id,
                                  name,
                                  testId: "request-status-item",
                                  icon: (
                                    <Status.Indicator
                                      plain={true}
                                      requestStatus={step}
                                      customColor={color as ColorVariantsUnion}
                                      hasInteractions={false}
                                    />
                                  )
                                }))
                              }
                            ]}
                            selected={requestCurrentCustomStatus?.id}
                            onSelect={(statusId: string) => {
                              handlers.changeCustomStatus(props.requestId, statusId);
                            }}
                            trigger={
                              requestCurrentCustomStatus ? (
                                <Status.Indicator
                                  wide
                                  requestStatus={requestCurrentCustomStatus.step}
                                  statusText={requestCurrentCustomStatus.name}
                                  customColor={requestCurrentCustomStatus.color as ColorVariantsUnion}
                                  data-testid="request-status-button"
                                  data-intercom-target="Request custom status button"
                                />
                              ) : (
                                <div />
                              )
                            }
                          />
                        }
                      >
                        <span>{`Status: ${props.requestResponse.data.request.customStatus?.name}`}</span>
                      </Tooltip>
                    </PillContainer>

                    <PillContainer disabled={!!linkedJiraIssue}>
                      <DisabledInteractionWrapper disabled={!!linkedJiraIssue}>
                        <Subheading>Assigned to</Subheading>
                      </DisabledInteractionWrapper>
                      <AbsoluteTooltip
                        placement="top"
                        content={
                          <span>{`Assignee: ${
                            props.requestResponse.data.request.assignee?.name || "Unassigned"
                          }`}</span>
                        }
                      >
                        <AssignExpertButton
                          isMenuOpen={shownActionDropdown === "assignee"}
                          enableOptionHotKeys={shownActionDropdown === "assignee"}
                          onClose={() => setShownActionDropdown(null)}
                          request={props.requestResponse.data.request}
                          team={props.requestResponse.data.request.team}
                          showText={true}
                          margin="0"
                        />
                      </AbsoluteTooltip>
                    </PillContainer>

                    <PillContainer disabled={!!linkedJiraIssue}>
                      <DisabledInteractionWrapper disabled={!!linkedJiraIssue}>
                        <Subheading>Team</Subheading>
                      </DisabledInteractionWrapper>
                      <AbsoluteTooltip
                        placement="top"
                        content={<span>{`Team: ${props.requestResponse.data.request.team.name}`}</span>}
                      >
                        <PopOver.Menu
                          isOpen={shownActionDropdown === "team"}
                          enableOptionHotKeys={shownActionDropdown === "team"}
                          onClose={() => setShownActionDropdown(null)}
                          options={options.changeTeam ?? []}
                          selected={props.requestResponse.data?.request.team.id}
                          onSelect={(teamId: string) => {
                            props.requestResponse.data &&
                              handlers.changeTeam(props.requestId, teamId, props.requestResponse.data.request.team);
                          }}
                          trigger={
                            <Pill.Team
                              data-intercom-target="Request assigned team"
                              teamName={props.requestResponse.data.request.team.name}
                            />
                          }
                        />
                      </AbsoluteTooltip>
                    </PillContainer>

                    <PillContainer disabled={!!linkedJiraIssue}>
                      <DisabledInteractionWrapper disabled={!!linkedJiraIssue}>
                        <Subheading>Category</Subheading>
                      </DisabledInteractionWrapper>
                      <AbsoluteTooltip
                        placement="top"
                        content={
                          <span>{`Category: ${
                            (props.requestResponse.data.request.category &&
                              props.requestResponse.data.request.category.name) ||
                            "No category"
                          }`}</span>
                        }
                      >
                        <PopOver.Menu
                          isOpen={shownActionDropdown === "category"}
                          enableOptionHotKeys={shownActionDropdown === "category"}
                          onClose={() => setShownActionDropdown(null)}
                          options={optionsByTeam.assignCategory?.(props.requestResponse.data.request.team) ?? []}
                          selected={props.requestResponse.data.request.category?.id}
                          onSelect={(categoryId: string | null) =>
                            props.requestResponse.data &&
                            handlers.assignCategory(
                              props.requestId,
                              categoryId,
                              props.requestResponse.data.request.category?.id ?? null,
                              () => void 0
                            )
                          }
                          onRemove={() =>
                            props.requestResponse.data &&
                            handlers.assignCategory(
                              props.requestId,
                              null,
                              props.requestResponse.data.request.category?.id ?? null,
                              () => void 0
                            )
                          }
                          labelForRemoveOption="Remove category"
                          trigger={
                            <Pill.Category
                              data-testid="request-category"
                              data-intercom-target="Request category"
                              categoryName={props.requestResponse.data?.request?.category?.name}
                            />
                          }
                        />
                      </AbsoluteTooltip>
                    </PillContainer>

                    <PillContainer>
                      <Subheading>Priority</Subheading>
                      <AbsoluteTooltip
                        placement="top"
                        content={
                          <span>{`Priority: ${priorityLabels[props.requestResponse.data.request.priority]}`}</span>
                        }
                      >
                        <RequestPriorityButton
                          isMenuOpen={shownActionDropdown === "priority"}
                          enableOptionHotKeys={shownActionDropdown === "priority"}
                          onUpdate={() => setShownActionDropdown(null)}
                          requestId={props.requestResponse.data.request.id}
                          priority={props.requestResponse.data.request.priority}
                        />
                      </AbsoluteTooltip>
                    </PillContainer>

                    <PillContainer>
                      <Subheading>Project</Subheading>
                      <AbsoluteTooltip
                        placement="top"
                        content={
                          <span>{`Project: ${
                            props.requestResponse.data.request?.project?.title || `No project`
                          }`}</span>
                        }
                      >
                        <ChangeProjectButton
                          menuPlacement="bottom-start"
                          requestId={props.requestId}
                          project={props.requestResponse.data.request.project}
                          trigger={<Pill.Project projectName={props.requestResponse.data.request?.project?.title} />}
                        />
                      </AbsoluteTooltip>
                    </PillContainer>

                    <PillContainer>
                      <Subheading>Due date</Subheading>
                      <AbsoluteTooltip
                        placement="top"
                        content={
                          <span>
                            {`Due date: ${props.requestResponse.data.request.dueAt ? formattedDueDate : `No due date`}`}
                          </span>
                        }
                      >
                        <ChangeDueDatePicker
                          isOpen={shownActionDropdown === "dueDate"}
                          onClose={() => setShownActionDropdown(null)}
                          button={
                            <Pill.DueDate
                              data-intercom-target="Request due date"
                              dueDate={props.requestResponse.data.request.dueAt}
                              isUrgent={isUrgent}
                            />
                          }
                          dueAt={props.requestResponse.data.request.dueAt}
                          requestId={props.requestId}
                        />
                      </AbsoluteTooltip>
                    </PillContainer>
                    <PillContainer>
                      <AssignCCs requestId={props.requestId} requestResponse={props.requestResponse} />
                    </PillContainer>
                  </>
                )}
                {selectedSidebarView === "REQUESTER" && props.requestResponse.data.request && (
                  <RequesterProfile
                    requestId={props.requestResponse.data.request.id}
                    requester={props.requestResponse.data.request.requester}
                    oktaProfile={oktaUserProfile.data?.oktaUser ?? null}
                    disabled={!!linkedJiraIssue}
                  />
                )}
                {selectedSidebarView === "SLA" && (
                  <div
                    data-intercom-target="SLA tab"
                    css={css`
                      & > * + * {
                        margin-top: 1.5rem;
                      }
                    `}
                  >
                    {slaStatus?.remainingTimeToFirstResponse &&
                      props.requestResponse.data.request.sla?.minutesToFirstResponse && (
                        <SLAItem
                          slaType={SLAType.FirstResponse}
                          goalInMin={props.requestResponse.data.request.sla.minutesToFirstResponse}
                          timeLeftInMin={slaStatus.remainingTimeToFirstResponse.remainingTimeMinutes}
                          ticking={slaStatus.remainingTimeToFirstResponse.ticking}
                          requestStatus={props.requestResponse.data.request.customStatus.step}
                        />
                      )}
                    {slaStatus?.remainingTimeToResolution &&
                      props.requestResponse.data.request.sla?.minutesToResolution && (
                        <SLAItem
                          slaType={SLAType.RequestResolution}
                          goalInMin={props.requestResponse.data.request.sla.minutesToResolution}
                          timeLeftInMin={slaStatus.remainingTimeToResolution.remainingTimeMinutes}
                          ticking={slaStatus.remainingTimeToResolution.ticking}
                          requestStatus={props.requestResponse.data.request.customStatus.step}
                        />
                      )}
                  </div>
                )}
                {selectedSidebarView === "WORKFLOWS" && !!teamWorkflowsResponse.data?.team.workflows.length && (
                  <TeamWorkflowsSection
                    request={props.requestResponse.data.request}
                    teamManualWorkflows={manuallyTriggeredWorkflows}
                  />
                )}
              </Col>
            </div>
          </RequestSidebar>
        </>
      )}
    </Row>
  );
});
