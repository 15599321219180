import gql from "graphql-tag";
import * as React from "react";
import { useMutation } from "react-apollo";
import { AuthWrapper } from "src/App/Auth/Components";
import { Button, Col, Dialog, LoadingBar, Text } from "src/components";
import { Toast } from "src/portals/Toast";
import { navigate, WindowLocation } from "src/util/router";
import { StartPasswordRecoveryForm } from "./Form";
import { UserStartPasswordRecovery, UserStartPasswordRecoveryVariables } from "./typings/UserStartPasswordRecovery";

const USER_START_PASSWORD_RECOVERY = gql`
  mutation UserStartPasswordRecovery($params: UserStartPasswordRecoveryParams!) {
    userStartPasswordRecovery(params: $params) {
      code
      message
      success
      invalid
    }
  }
`;

export function StartPasswordRecoveryContainer(props: {
  location?: WindowLocation<{
    loginEmail?: string;
  }>;
}) {
  const [startPasswordRecovery, startPasswordRecoveryResponse] = useMutation<
    UserStartPasswordRecovery,
    UserStartPasswordRecoveryVariables
  >(USER_START_PASSWORD_RECOVERY);

  const success =
    startPasswordRecoveryResponse.data && startPasswordRecoveryResponse.data.userStartPasswordRecovery.success;
  const errorMessage =
    startPasswordRecoveryResponse.data &&
    !startPasswordRecoveryResponse.data.userStartPasswordRecovery.success &&
    (startPasswordRecoveryResponse.data.userStartPasswordRecovery.message || "Unknown error");

  const loginEmail = props.location?.state?.loginEmail ?? "";

  return (
    <AuthWrapper>
      <Dialog small title="Forgot your password?" noClose={true}>
        {!success && (
          <StartPasswordRecoveryForm
            loading={startPasswordRecoveryResponse.loading}
            loginEmail={loginEmail}
            startPasswordRecovery={startPasswordRecovery}
          />
        )}
        {success && (
          <Col>
            <Text lineHeight="1.5">
              Thanks! You will receive an email with instructions for how to reset your password in a few minutes.
            </Text>
            <Button variant="primary" margin="2rem 0 0" size="large" onClick={() => navigate("/login")}>
              Back to sign in
            </Button>
          </Col>
        )}
        {startPasswordRecoveryResponse.loading && <LoadingBar />}
        {errorMessage && <Toast message={errorMessage} kind="mutationError" />}
      </Dialog>
    </AuthWrapper>
  );
}
