import { Node } from "@tiptap/core";
import { Mention, MentionOptions } from "@tiptap/extension-mention";
import { Editor, NodeViewWrapper, ReactNodeViewRenderer, ReactRenderer } from "@tiptap/react";
import { SuggestionKeyDownProps, SuggestionProps } from "@tiptap/suggestion";
import * as React from "react";
import { TiptapDropdown } from "src/components/RichEditor/Dropdown";
import tippy, { Instance, Props } from "tippy.js";
import { InlineVariable } from "./Variables";

interface PubVarAttrs {
  id: string;
}

interface PubVarSuggestionItem {
  name: string;
}

const NodeView: React.FC<{
  updateAttributes(attrs: PubVarAttrs): void;
  node: {
    attrs: PubVarAttrs;
  };
}> = props => {
  return (
    <NodeViewWrapper>
      <InlineVariable name={props.node.attrs.id} />
    </NodeViewWrapper>
  );
};

const VariableMention = Mention.extend({
  addNodeView() {
    return ReactNodeViewRenderer(NodeView);
  }
}) as Node<MentionOptions>;

interface VariableSuggestionsListProps extends Omit<SuggestionProps, "items"> {
  items?: PubVarSuggestionItem[];
  attrs: PubVarAttrs;
}

export class VariableSuggestionsList extends React.Component<VariableSuggestionsListProps, { selectedIndex: number }> {
  constructor(props: VariableSuggestionsListProps) {
    super(props);

    this.state = {
      selectedIndex: 0
    };
  }

  componentDidUpdate(oldProps: VariableSuggestionsListProps) {
    if (this.props.items !== oldProps.items) {
      this.setState({
        selectedIndex: 0
      });
    }
  }

  /* called imperatively by suggestion extension */
  onKeyDown({ event }: SuggestionKeyDownProps) {
    // prevent from closing modal
    if (event.key === "Escape") {
      event.stopPropagation();
    }

    if (!this.props.items) return false;

    if (event.key === "ArrowUp") {
      this.setState({
        selectedIndex: (this.state.selectedIndex + this.props.items.length - 1) % this.props.items.length
      });
      return true;
    }

    if (event.key === "ArrowDown") {
      this.setState({
        selectedIndex: (this.state.selectedIndex + 1) % this.props.items.length
      });
      return true;
    }

    if (event.key === "Enter") {
      const id = this.props.items?.[this.state.selectedIndex].name;
      this.props.command({ id });
      return true;
    }

    return false;
  }

  render() {
    if (!this.props.items) return null;
    return (
      <TiptapDropdown.Menu>
        {this.props.items?.map((item, i) => (
          <TiptapDropdown.Item
            selected={this.state.selectedIndex === i}
            onClick={() => this.props.command({ id: item.name })}
          >
            <InlineVariable name={item.name} />
          </TiptapDropdown.Item>
        ))}
      </TiptapDropdown.Menu>
    );
  }
}

/**
 * Suggest variables on typing "$"
 */
export const VariableSuggestion = (variables: PubVarSuggestionItem[]) =>
  VariableMention.configure({
    suggestion: {
      char: "$",
      command: ({ editor, range, props }) => {
        editor.chain().focus().replaceRange(range, "mention", props).insertContent("").run();
      },
      items: query => {
        const queriedVariables = query === "" ? variables : variables.filter(v => v.name.includes(query));
        return queriedVariables.slice(0, 10);
      },
      // This is not a react render function, but rather gets passed thru the Suggestion
      // render callback, which hooks it into the prosemirror-state update callback.
      // Various lifecycle methods are called (based on the state changes in prosemirror exposed
      // through the prosemirror Plugin interface) and these in turn imperatively update the
      // React component's props passed in as the `component` tiptap-ReactRenderer instance
      render: () => {
        let component: ReactRenderer | null = null;
        let popup: Instance<Props>[] | null = null;

        return {
          onStart: props => {
            component = new ReactRenderer((VariableSuggestionsList as unknown) as React.Component, {
              // there is a type mismatch here apparently
              editor: (props.editor as unknown) as Editor,
              props
            });
            popup = tippy("body", {
              getReferenceClientRect: props.clientRect,
              appendTo: () => document.body,
              // here we are rendering the component
              content: component?.element,
              showOnCreate: true,
              interactive: true,
              trigger: "manual",
              placement: "bottom-start"
            });
          },
          onUpdate(props) {
            component?.updateProps(props);

            popup?.map(p => {
              p.setProps({
                getReferenceClientRect: props.clientRect
              });
            });
          },
          onKeyDown(props) {
            if (!component?.ref) return false;
            const ref = component.ref as VariableSuggestionsList;
            return ref.onKeyDown(props);
          },
          onExit() {
            if (popup) {
              popup.map(p => p.destroy());
            }
            component?.destroy();
          }
        };
      }
    }
  });
