import * as React from "react";
import { Col } from "src/components";
import { RouteComponentProps } from "src/util/router";
import { EmployeeUI } from "./Form";

export function ExternalFormPage(props: RouteComponentProps<{ form_id: string }>) {
  return (
    <Col flex="1 0 auto" overflow="auto">
      <EmployeeUI formId={props.form_id ?? ""} />
    </Col>
  );
}
