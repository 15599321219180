import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { mdiArrowRight } from "@mdi/js";
import { format } from "date-fns";
import { Form, Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import gql from "graphql-tag";
import { debounce } from "lodash";
import * as React from "react";
import { FC, useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import FormSwooshGreen from "src/App/Forms/Employee/FormSwooshGreen.svg";
import FormSwooshYellow from "src/App/Forms/Employee/FormSwooshYellow.svg";
import { FormGet, FormGetVariables, FormGet_form } from "src/App/Forms/Employee/typings/FormGet";
import { FormSubmit, FormSubmitVariables } from "src/App/Forms/Employee/typings/FormSubmit";
import { FormUpdate, FormUpdateVariables } from "src/App/Forms/Employee/typings/FormUpdate";
import { resolveFieldType } from "src/App/Forms/helpers";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { ICommonStyleProps, LineBreak, LoadingBar, MaterialIcon, Row, Text } from "src/components";
import { USER_INFO_FRAGMENT } from "src/fragments/UserInfoFragment";
import { FieldType, FormDataParams, ResponseValueParams } from "src/globalTypes";
import { grpc } from "src/grpc";
import { Typo } from "src/styling/primitives/typography";
import { reportDevError } from "src/util";
import { csx } from "src/util/csx";
import { formatTime } from "src/util/formatters";
import { FieldInterface } from "./Fields";

export const ContainerWithBackground = csx(
  [
    css`
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      min-height: 100%;
      background-image: url(${FormSwooshGreen}), url(${FormSwooshYellow});
      background-position: left top, right top;
      background-repeat: no-repeat;
      background-size: auto 100vh;
      background-attachment: fixed;
    `
  ],
  {
    centerContent: css`
      align-items: center;
      justify-content: center;
      background-size: auto;
      background-position: left top, right bottom;
    `
  }
);

const HeadingXL = csx([
  css`
    color: var(--text-6);
    font-family: var(--font-family-display);
    line-height: var(--line-height-dense);
    font-size: var(--font-size-display-xLarge);
    letter-spacing: -1%;
  `
]);

export const FormButton = styled.button<ICommonStyleProps>`
  display: flex;
  align-items: center;
  margin: ${p => p.margin || "0 0 0 0.5rem"};
  padding: ${p => p.padding || "0.75rem"};
  font-size: ${p => p.fontSize || "0.875rem"};
  color: var(--lightGrey-1);
  background: var(--text-6);
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;

  &:active {
    outline: none;
  }

  &:hover {
    background: var(--text-6);
  }

  &[disabled],
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const Saved = csx(
  [
    css`
      display: flex;
      align-items: center;
      position: absolute;
      top: var(--space-5-rem);
      right: var(--space-7-rem);
      font-size: 0.875rem;
      color: var(--text-6);
      opacity: 0.5;
      z-index: var(--z-high);
    `
  ],
  {
    hasErrror: css`
      color: var(--red-6);
    `
  }
);

const ErrorBanner: FC<{ isVisible: boolean; errorMessage: string }> = ({ isVisible, errorMessage }) => (
  <AnimatePresence>
    {isVisible && (
      <motion.div initial={{ height: 0 }} animate={{ height: "auto" }} exit={{ height: 0 }}>
        <div
          css={[
            css`
              background-color: rgba(var(--red-1-raw), 0.6);
              padding: var(--space-3-rem) var(--space-5-rem);
            `
          ]}
        >
          <Typo.Body
            css={[
              css`
                width: 36rem;
                margin: 0 auto;
                color: var(--red-7);
              `
            ]}
          >
            {errorMessage}
          </Typo.Body>
        </div>
      </motion.div>
    )}
  </AnimatePresence>
);

const FORM_UPDATE = gql`
  mutation FormUpdate($params: FormDataParams!) {
    formUpdate(params: $params) {
      code
      success
      message
      form {
        updateTime
      }
    }
  }
`;

function DynamicTimestamp(props: { timestamp: Date | string }) {
  const [formattedTime, setFormattedTime] = useState<string>(formatTime(props.timestamp));
  useEffect(() => {
    const interval = setInterval(() => {
      setFormattedTime(formatTime(props.timestamp));
    }, 1000);
    return () => clearInterval(interval);
  }, [props.timestamp]);
  return <>{formattedTime}</>;
}

const generateInputValues = (values: FormikResponseValue[]): ResponseValueParams[] => {
  return values.map(field => {
    if (field.fieldType === FieldType.ATTACHMENT_FIELD) {
      return { fieldType: FieldType.ATTACHMENT_FIELD, attachmentId: field.attachment?.id ?? "" };
    } else if (field.fieldType === FieldType.TEXT_FIELD) {
      return { fieldType: FieldType.TEXT_FIELD, value: field.value?.toString().trim() ?? "" };
    } else return field;
  });
};

function SaveData(props: { params: FormUpdateVariables["params"] }) {
  const [formUpdate, formUpdateResponse] = useMutation<FormUpdate, FormUpdateVariables>(FORM_UPDATE);
  const update = useCallback(
    debounce(
      (params: FormDataParams) =>
        formUpdate({
          variables: { params }
        }),
      2000
    ),
    []
  );
  useEffect(() => {
    update(props.params);
  }, [props.params, update]);
  return formUpdateResponse.loading ? (
    <Saved>Saving...</Saved>
  ) : formUpdateResponse.data?.formUpdate.form?.updateTime ? (
    <Saved>
      Saved <DynamicTimestamp timestamp={formUpdateResponse.data.formUpdate.form.updateTime} />
    </Saved>
  ) : formUpdateResponse.error ? (
    <Saved hasErrror>There was an error saving your answers. Please reload the page and try again</Saved>
  ) : null;
}

export const FORM_GET = gql`
  query FormGet($id: ID!) {
    form(id: $id) {
      id
      submitTime
      schema {
        id
        pk
        displayName
        description
        createTime
        updateTime
        deleteTime
        fieldsList {
          ... on FormFieldCommon {
            __typename
            displayName
            required
          }
          ... on TextField {
            textFieldType
            value
          }
          ... on DropdownField {
            optionsList
            selected
          }
          ... on AttachmentsField {
            attachment {
              id
              displayName
              size
              receiveTime
            }
          }
        }
      }
    }
  }
`;

const FORM_SUBMIT = gql`
  mutation FormSubmit($params: FormDataParams!) {
    formSubmit(params: $params) {
      code
      message
      success
    }
  }
`;

export function EmployeeUI(props: { formId: string }) {
  const { emitSnack } = useSnack();
  const formResponse = useQuery<FormGet, FormGetVariables>(FORM_GET, {
    variables: {
      id: props.formId
    }
  });

  const [selected, setSelected] = useState(0);

  const [formSubmit, formSubmitResponse] = useMutation<FormSubmit, FormSubmitVariables>(FORM_SUBMIT);

  const isJustSubmitted = formSubmitResponse.data && formSubmitResponse.data.formSubmit.success;
  const isSubmitted = !!(formResponse.data && formResponse.data.form && formResponse.data.form.submitTime);

  if (formResponse.loading) {
    return <LoadingBar />;
  }

  // When the form was just submitted
  if (isJustSubmitted) {
    return (
      <ContainerWithBackground centerContent>
        <HeadingXL>Thank you!</HeadingXL>
        <Typo.Body
          css={css`
            font-size: 1.5rem;
            width: 30rem;
            text-align: center;
            margin: 2rem 0;
          `}
        >
          Your form has been sent successfully!
        </Typo.Body>
      </ContainerWithBackground>
    );
  }

  // When the form no longer exists (or never existed at all)
  if (!formResponse.data || !formResponse.data.form) {
    return (
      <ContainerWithBackground centerContent>
        <HeadingXL>Well, this is unfortunate!</HeadingXL>
        <Typo.Body
          css={css`
            font-size: 1.5rem;
            width: 30rem;
            text-align: center;
            margin: 2rem 0;
          `}
        >
          Looks like this form was deleted and is no longer available! But no worries, someone will get in touch with
          you soon!
        </Typo.Body>
      </ContainerWithBackground>
    );
  }

  if (formResponse && formResponse.data && formResponse.data.form) {
    const schema = formResponse.data.form.schema;

    return (
      <Formik
        isInitialValid={true}
        initialValues={initialValues(formResponse.data.form)}
        onSubmit={async (formValues, bag) => {
          const res = await formSubmit({
            variables: {
              params: {
                id: props.formId,
                values: generateInputValues(formValues)
              }
            }
          });
          if (res.data?.formSubmit.success === false) {
            if (res.data.formSubmit.code === grpc.status.INVALID_ARGUMENT) {
              emitSnack({
                type: "mutationError",
                message: "Sorry, there was an internal error processing your form"
              });
              reportDevError("Form value does not conform to backend validation", formValues.values);
            } else {
              emitSnack({
                type: "mutationError",
                message: res.data.formSubmit.message
              });
            }
          }
          bag.setSubmitting(false);
        }}
      >
        {({ values, isSubmitting, errors, touched }) => {
          return (
            <Form
              className={isSubmitted ? "submitted" : ""}
              css={[
                css`
                  display: flex;
                  flex-grow: 1;
                `
              ]}
            >
              <ContainerWithBackground>
                {!isSubmitting && !isSubmitted && (
                  <SaveData
                    params={{
                      id: props.formId,
                      values: generateInputValues(values)
                    }}
                  />
                )}
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    justify-content: space-between;
                    /* needed to create full-width scroll area w/ scrollbar on top*/
                    /*  overflow-x: hidden; */
                    overflow: auto;
                  `}
                >
                  <div
                    css={css`
                      margin: var(--space-7-rem) auto;
                      width: 36rem;

                      & > * + * {
                        margin-top: calc(var(--space-7-px) * 2);
                      }
                    `}
                  >
                    <div
                      css={css`
                        & > * + * {
                          margin-top: var(--space-3-rem);
                        }
                      `}
                    >
                      <HeadingXL>{schema.displayName}</HeadingXL>
                      <Typo.Body sizeXL>{schema.description}</Typo.Body>
                    </div>
                    {schema.fieldsList.map((field, i) => (
                      <div key={i}>
                        <Row>
                          <Text
                            fontSize="0.875rem"
                            fontWeight="600"
                            css={css`
                              color: var(--text-2);
                            `}
                          >
                            QUESTION {i + 1}/{schema.fieldsList.length}
                          </Text>
                        </Row>
                        <Row margin="0.5rem 0 1.5rem 0">
                          <Text fontSize="1.25rem" lineHeight="1.5">
                            <LineBreak text={field.displayName} asterisk={field.required} />
                          </Text>
                        </Row>
                        <FieldInterface
                          field={{
                            index: i,
                            fieldType: resolveFieldType(field.__typename),
                            required: field.required,
                            textFieldType: "textFieldType" in field ? field.textFieldType : undefined,
                            optionsList: "optionsList" in field ? field.optionsList : undefined
                          }}
                          response={values[i]}
                          isFocused={selected === i}
                          isSubmitted={isSubmitted}
                          onFocus={e => {
                            setSelected(i);
                            e.target.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                          }}
                          onComplete={() => {
                            setSelected(i + 1);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <div
                    css={[
                      css`
                        margin-top: var(--space-6-rem);
                        z-index: var(--z-low);
                      `
                    ]}
                  >
                    {formSubmitResponse.loading && <LoadingBar />}
                    <ErrorBanner
                      isVisible={
                        !!Object.keys(errors).length &&
                        Object.keys(errors).some(err => Object.keys(touched).includes(err))
                      }
                      errorMessage="The form is not valid. Please see the error messages above."
                    />
                    <div
                      css={css`
                        padding: var(--space-7-rem) 0;
                        width: 36rem;
                        margin: 0 auto;
                      `}
                    >
                      {!isSubmitted && (
                        <FormButton
                          key={selected} // force component to re-render as autoFocus works only when a component is mounted
                          type="submit"
                          margin="0"
                          fontSize="1.125rem"
                          autoFocus={selected === schema.fieldsList.length}
                        >
                          Send form
                          <MaterialIcon path={mdiArrowRight} size={1.25} margin="0 0 0 0.75rem" />
                        </FormButton>
                      )}
                      {isSubmitted && (
                        <strong>
                          You've already submitted this form on{" "}
                          {formResponse.data?.form?.submitTime &&
                            format(new Date(formResponse.data.form.submitTime), "MMMM do")}{" "}
                          and your answers can no longer be updated.
                        </strong>
                      )}
                    </div>
                  </div>
                </div>
              </ContainerWithBackground>
            </Form>
          );
        }}
      </Formik>
    );
  }

  return null;
}

export interface DropdownResponse {
  fieldType: FieldType.DROPDOWN_FIELD;
  selected: number | null;
}

export interface TextfieldResponse {
  fieldType: FieldType.TEXT_FIELD;
  value: string;
}

export interface AttachmentFieldResponse {
  fieldType: FieldType.ATTACHMENT_FIELD;
  attachment: { id: string; displayName: string; size: number } | null;
}

export type FormikResponseValue = TextfieldResponse | DropdownResponse | AttachmentFieldResponse;

/** Shape form values into formik values */
function initialValues(form: FormGet_form): FormikResponseValue[] {
  return form.schema.fieldsList.map(f => {
    if (f.__typename === "DropdownField") {
      return {
        fieldType: FieldType.DROPDOWN_FIELD,
        selected: f.selected
      };
    } else if (f.__typename === "TextField") {
      return {
        fieldType: FieldType.TEXT_FIELD,
        value: f.value || ""
      };
    } else if (f.__typename === "AttachmentsField") {
      return {
        fieldType: FieldType.ATTACHMENT_FIELD,
        attachment: f.attachment || null
      };
    } else {
      return {
        fieldType: FieldType.TEXT_FIELD,
        value: ""
      };
    }
  });
}
