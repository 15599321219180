import { css } from "@emotion/core";
import gql from "graphql-tag";
import * as React from "react";
import { useMutation } from "react-apollo";
import { DOCUMENTS_LIST_GET } from "src/App/KB";
import { DocumentCreateUpdateForm } from "src/App/KB/CreateUpdate/Form";
import { UnsyncExternalDocument } from "src/App/KB/ExternalSourceDocument/Unsync";
import { GDocumentsListGet_documentList } from "src/App/KB/typings/GDocumentsListGet";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { ConfirmDialogModal } from "src/components";
import { csx } from "src/util/csx";
import { navigate } from "src/util/router";

const Wrapper = csx([
  css`
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background: var(--white);
  `
]);

const Description = csx([
  css`
    font-size: var(--font-size-body);
    line-height: var(--line-height-normal);
    margin-top: -1rem;
  `
]);

export const DOCUMENT_DELETE = gql`
  mutation GDocumentDelete($id: ID!) {
    documentDelete(id: $id) {
      code
      message
      success
    }
  }
`;

export interface IDocumentCreateUpdateProps {
  onSuccess: () => void;
  onCancel: () => void;
  onShareClick?: () => void;
  document?: GDocumentsListGet_documentList;
  text?: string;
  commentId?: string;
}

export interface IDocumentDeleteProps {
  isOpen: boolean;
  document: GDocumentsListGet_documentList;
  onFinish(): void;
  onCancel(): void;
}

/**
 * Container for creating and editing documents
 * If documentId is provided it renders the edit view, otherwise it renders the create view
 */
export const DocumentCreateUpdateContainer: React.FunctionComponent<IDocumentCreateUpdateProps> = props => {
  if (!props.document?.id) {
    return (
      <Wrapper>
        <DocumentCreateUpdateForm {...props} submit="Add answer" />
      </Wrapper>
    );
  }
  return <Wrapper>{props.document && <DocumentCreateUpdateForm {...props} submit="Save changes" />}</Wrapper>;
};

export const DocumentDeleteContainer: React.FC<IDocumentDeleteProps> = props => {
  if (props.document.external) {
    return (
      <UnsyncExternalDocument
        isOpen={props.isOpen}
        document={props.document}
        onCancel={props.onCancel}
        onFinish={props.onFinish}
      />
    );
  }
  return (
    <DeleteDocumentModal
      document={props.document}
      isOpen={props.isOpen}
      onCancel={props.onCancel}
      onFinish={props.onFinish}
    />
  );
};

const DeleteDocumentModal: React.FC<IDocumentDeleteProps> = props => {
  const { emitSnack } = useSnack();

  const [deleteDocument, { loading }] = useMutation(DOCUMENT_DELETE, {
    refetchQueries: [{ query: DOCUMENTS_LIST_GET }]
  });

  return (
    <ConfirmDialogModal
      isOpen={props.isOpen}
      medium
      loading={loading}
      text={{
        cancel: "Cancel",
        confirm: "Delete answer",
        heading: `Delete answer`
      }}
      handleCancel={props.onCancel}
      handleConfirm={async () => {
        const response = await deleteDocument({ variables: { id: props.document.id } });
        if (response.data?.documentDelete.success) {
          navigate("/knowledge");
          emitSnack({
            message: `Answer ${props.document.title} deleted`,
            type: "info"
          });
        } else if (response.data?.documentDelete.success === false) {
          navigate("/knowledge");
          emitSnack({
            message: response.data.documentDelete.message,
            type: "mutationError"
          });
        }
      }}
    >
      <Description>
        Are you sure you want to delete the answer "<em>{props.document.title}</em>"?
      </Description>
    </ConfirmDialogModal>
  );
};
