import { css } from "@emotion/core";
import { ErrorMessage, FieldProps } from "formik";
import * as React from "react";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { MultiValueProps } from "react-select/src/components/MultiValue";
import { OptionProps } from "react-select/src/components/Option";
import { StylesConfig } from "react-select/src/styles";
import { ValueType } from "react-select/src/types";
import { ReactComponent as DropdownIcon } from "src/assets/DropdownIndicator.svg";
import { ReactComponent as CloseX } from "src/assets/material/close-24px.svg";
import { body, icon } from "src/styling/primitives";
import { __rawColorValues } from "src/styling/tokens/__colors";
import { fontFamily, fontSizes } from "src/styling/typography";
import { csx } from "src/util/csx";
import { EllipsisText } from "..";
import { FormLabel } from "./FormWrappers";
import { ErrorLabel, FormikField, HelperText } from "./index";

export interface FormikMultiSelectProps {
  label?: string;
  helperText?: string;
  placeHolder?: string;
  noOptionsText?: string;
  options: MultiSelectValue[];
  defaultValue?: MultiSelectValue[];
  isCreatable?: boolean;
  isClearable?: boolean;
  showError?: boolean;
  disabled?: boolean;
}

export interface MultiSelectValue {
  value: string;
  label: string;
  disabled?: boolean;
}

/**
 * Multi select component that wraps react-select for Formik
 */
export const sharedMultiSelectStylz: StylesConfig = {
  control: (styles: React.CSSProperties, state) => ({
    ...styles,
    border: state.isFocused ? `1px solid ${__rawColorValues.text_6}` : `1px solid ${__rawColorValues.lightGrey_4}`,
    borderRadius: "var(--border-radius-s)",
    boxShadow: "none",
    ":hover": {
      boxShadow: "none",
      border: `1px solid ${__rawColorValues.text_6}`
    }
  }),
  valueContainer: (styles: React.CSSProperties) => ({
    ...styles,
    padding: "0.375rem 0.5rem",
    cursor: "text"
  }),
  input: () => ({
    color: __rawColorValues.text_6,
    fontSize: `${fontSizes.default}rem`,
    lineHeight: 1,
    fontFamily: fontFamily.body,
    padding: "0.375rem 0.1875rem 0.375rem 0.5rem",
    "& input": {
      font: "inherit"
    }
  }),
  multiValue: (styles: React.CSSProperties) => ({
    ...styles,
    padding: "0",
    backgroundColor: __rawColorValues.lightGrey_2,
    borderRadius: "var(--border-radius-xs)",
    cursor: "default"
  }),
  multiValueLabel: (styles: React.CSSProperties) => ({
    ...styles,
    fontSize: `${fontSizes.default}rem`,
    padding: "0.375rem 0.1875rem 0.375rem 0.5rem",
    paddingLeft: "0.5rem",
    lineHeight: 1,
    color: __rawColorValues.text_6
  }),
  multiValueRemove: () => ({
    padding: "0.125rem 0.1875rem",
    borderTopRightRadius: "var(--border-radius-xs)",
    borderButtonRightRadius: "var(--border-radius-xs)",
    cursor: "pointer",
    svg: {
      verticalAlign: "text-bottom"
    },
    "svg path:not([fill])": {
      fill: __rawColorValues.text_3
    },
    ":hover": {
      backgroundColor: __rawColorValues.lightGrey_3,
      "svg path:not([fill])": {
        fill: __rawColorValues.text_6
      }
    }
  }),
  indicatorsContainer: () => ({
    cursor: "pointer",
    display: "flex",
    alignItems: "center"
  }),
  menu: styles => ({
    ...styles,
    padding: "0.375rem 0"
  }),
  menuList: styles => ({
    ...styles,
    paddingTop: 0,
    paddingBottom: 0
  }),
  noOptionsMessage: styles => ({
    ...styles,
    color: __rawColorValues.lightGrey_4,
    fontSize: `${fontSizes.default}rem`,
    lineHeight: 1,
    fontFamily: fontFamily.body
  })
};

const MultiValueRemove = (props: MultiValueProps<MultiSelectValue>) => (
  <components.MultiValueRemove {...props}>
    <CloseX css={[icon.M]} />
  </components.MultiValueRemove>
);

const DropdownIndicator = () => (
  <div
    css={css`
      padding: 0 1rem;
      svg {
        vertical-align: middle;
      }
    `}
  >
    <DropdownIcon />
  </div>
);

const IndicatorSeparator = () => <React.Fragment />;

const CustomOptionLabel = csx(
  [
    body.regular,
    css`
      display: flex;
      justify-content: space-between;
      padding: 0.375rem 1rem;
      cursor: pointer;
      span {
        font-size: 0.875rem;
        color: inherit;
      }
      span:last-of-type {
        display: none;
        color: var(--text-3);
      }
    `
  ],
  {
    isFocused: css`
      background-color: var(--lightGrey-2);
      span:last-of-type {
        display: inline;
      }
    `,
    isDisabled: css`
      cursor: not-allowed;
      > span {
        color: var(--lightGrey-4) !important;
      }
      &:hover,
      &:focus {
        background-color: transparent;
      }
    `
  }
);

const CustomOption = (props: OptionProps<MultiSelectValue>) => (
  <CustomOptionLabel
    ref={props.innerRef}
    isDisabled={props.data.disabled}
    isFocused={props.isFocused || props.isSelected}
    {...props.innerProps}
  >
    <EllipsisText>{props.data.label}</EllipsisText>

    <span
      css={css`
        flex-shrink: 0;
      `}
    >
      {props.data.disabled ? "" : "Press return to add"}
    </span>
  </CustomOptionLabel>
);

const customComponents = {
  MultiValueRemove,
  DropdownIndicator,
  IndicatorSeparator,
  Option: CustomOption
};

export function FormikMultiSelect(props: FormikMultiSelectProps & FieldProps) {
  const {
    field,
    form,
    helperText = "",
    placeHolder = "",
    noOptionsText = "No options available",
    label,
    options,
    defaultValue,
    isCreatable = false,
    isClearable = true,
    showError = true
  } = props;
  const error = form.touched[field.name] && !!form.errors[field.name];

  return (
    <FormikField error={error}>
      {label && <FormLabel htmlFor={field.name}>{label}</FormLabel>}
      {isCreatable ? (
        <CreatableSelect
          className={`select__${field.name}`}
          classNamePrefix="react-select"
          isMulti={true}
          inputId={field.name}
          isOptionDisabled={option => !!option.disabled}
          styles={sharedMultiSelectStylz}
          onBlur={field.onBlur}
          options={options}
          defaultValue={defaultValue}
          isClearable={isClearable}
          onChange={(data: ValueType<MultiSelectValue>) => form.setFieldValue(field.name, data)}
          components={customComponents}
          placeholder={placeHolder}
          menuPlacement={"auto"}
          noOptionsMessage={() => noOptionsText}
          // remove 'Create '+ label
          formatCreateLabel={(label: string) => label}
          value={field.value}
          isDisabled={props.disabled}
        />
      ) : (
        <Select
          value={field.value}
          className={`select__${field.name}`}
          classNamePrefix="react-select"
          isMulti={true}
          inputId={field.name}
          isOptionDisabled={option => !!option.disabled}
          styles={sharedMultiSelectStylz}
          onBlur={field.onBlur}
          options={options}
          defaultValue={defaultValue}
          onChange={(data: ValueType<MultiSelectValue>) => form.setFieldValue(field.name, data)}
          isClearable={isClearable}
          components={customComponents}
          placeholder={placeHolder}
          menuPlacement={"auto"}
          isDisabled={props.disabled}
        />
      )}

      {helperText && <HelperText>{helperText}</HelperText>}
      {showError && (
        <ErrorLabel margin="0.25rem 0 0.25rem">
          <ErrorMessage
            name={field.name}
            render={msg => {
              if (Array.isArray(msg)) {
                return msg.reduce((acc, m) => {
                  if (m && acc.indexOf(m.name) < 0) {
                    acc.push(m.name);
                  }
                  return acc;
                }, []);
              } else return msg;
            }}
          />
        </ErrorLabel>
      )}
    </FormikField>
  );
}
