import styled from "@emotion/styled";
import { mdiEmail, mdiSend, mdiSlack, mdiWeb } from "@mdi/js";
import { Icon } from "@mdi/react";
import { IconProps } from "@mdi/react/dist/IconProps";
import * as React from "react";
import { MouseEventHandler } from "react-select/src/types";
import { RequestChannels } from "src/globalTypes";
import { ICommonStyleProps } from ".";

const MaterialIconWrapper = styled.div<
  { onClick?: MouseEventHandler; size: number; pointerCursor?: boolean } & ICommonStyleProps
>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  margin: ${p => p.margin || "0"};
  padding: ${p => p.padding || "0"};
  width: ${p => p.size}rem;
  height: ${p => p.size}rem;
  cursor: ${p => (p.onClick || p.pointerCursor) && "pointer"};
  color: inherit;

  & svg {
    width: ${p => p.size}rem;
    height: ${p => p.size}rem;

    & path {
      fill: currentColor;
    }
  }
`;

/**
 * Wraps mdi icon to fix some sizing issues
 */
export const MaterialIcon: React.ComponentType<
  {
    size: number;
    onClick?: MouseEventHandler;
    pointerCursor?: boolean;
  } & Omit<IconProps, "color"> &
    ICommonStyleProps
> = props => (
  <MaterialIconWrapper {...props} className={`icon ${props.className ?? ""}`}>
    <Icon path={props.path} size={props.size} />
  </MaterialIconWrapper>
);

/**
 * Show a suitable icon for each request channel
 */
export const ChannelIcon: React.ComponentType<
  {
    channel: RequestChannels;
    size: number;
  } & Partial<IconProps> &
    ICommonStyleProps
> = props => {
  switch (props.channel) {
    case RequestChannels.UNSETREQUESTCHANNELS:
      return <MaterialIcon path={mdiSend} {...props} />;
    case RequestChannels.WEB:
      return <MaterialIcon path={mdiWeb} {...props} />;
    case RequestChannels.SLACK:
      return <MaterialIcon path={mdiSlack} {...props} />;
    case RequestChannels.EMAIL:
      return <MaterialIcon path={mdiEmail} {...props} />;
    case RequestChannels.MSTEAMS:
    case RequestChannels.WORKFLOWS:
    case RequestChannels.REQUEST_CHANNEL_UNKNOWN:
      return null;
    default:
      throw new Error("Unknown request channel for icon");
  }
};
