import gql from "graphql-tag";
import * as React from "react";
import { useMutation } from "react-apollo";
import { REQUEST_GET } from "src/App/Requests/DetailView/REQUEST_GET";
import { LoadingBar, SubmitButton } from "src/components";
import { ChannelType, InteractionType, ReplyType } from "src/globalTypes";
import { Toast } from "src/portals/Toast";
import { RequestGet_request } from "../typings/RequestGet";
import { ReplyButtonWithPopoverMenu } from "./PublicReplyButton";
import { GRequestAddCommentVariables } from "./typings/GRequestAddComment";

const REQUEST_ADD_COMMENT = gql`
  mutation GRequestAddComment($params: RequestCommentParameters!) {
    requestAddComment(params: $params) {
      code
      success
      message
    }
  }
`;

export interface PostCommentButtonProps {
  text: string;
  params: GRequestAddCommentVariables["params"];
  requesterId: string;
  hasAttachments: boolean;
  origin?: RequestGet_request["origin"];
  disabled?: boolean;
  submitHandlerRef: React.MutableRefObject<() => void>;
  onSuccess(): void;
}

/**
 * Button to submit a comment
 * Contains mutation, loading, error handling logic
 */
export function PostCommentButton(props: PostCommentButtonProps) {
  const [postComment, { loading, data }] = useMutation(REQUEST_ADD_COMMENT, {
    update: (_cache, response) => {
      if (response.data && response.data.requestAddComment.success) {
        props.onSuccess();
      }
    },
    refetchQueries: [
      {
        query: REQUEST_GET,
        variables: {
          id: props.params.requestId
        }
      }
    ]
  });

  const showReplyBtnWithMenu =
    (props.origin &&
      props.origin.channel &&
      (props.origin.interactionType === InteractionType.INTERACTIVE_MESSAGE ||
        props.origin.interactionType === InteractionType.MESSAGE_ACTION) &&
      props.origin.channel.type === ChannelType.PUBLIC) ||
    false;

  const submitHandler = () =>
    postComment({
      variables: {
        params: {
          ...props.params,
          replyType: ReplyType.DEFAULT
        }
      }
    });
  props.submitHandlerRef.current = submitHandler;

  return (
    <>
      {showReplyBtnWithMenu ? (
        <ReplyButtonWithPopoverMenu {...props} postComment={postComment} />
      ) : (
        <SubmitButton
          variant="green"
          size="medium"
          disabled={props.disabled || loading}
          margin="0 0 0 .25rem"
          data-intercom-target="Send reply"
          onClick={() => {
            submitHandler();
          }}
          data-testid="submit-reply-button"
        >
          {props.text}
        </SubmitButton>
      )}
      {loading && <LoadingBar />}

      {data && !data.requestAddComment.success && (
        <Toast kind="mutationError" message={data.requestAddComment.message} />
      )}
    </>
  );
}
