import { DocumentFragment } from "src/App/KB/typings/DocumentFragment";
import { trackDocumentAttached } from "src/util/analytics";

export interface DocumentState {
  showDocumentModal: boolean;
  document: DocumentFragment | null;
}

export const initialDocumentState: DocumentState = {
  document: null,
  showDocumentModal: false
};

export type DocumentAction =
  | {
      type: "SELECT_DOCUMENT";
      document: DocumentFragment;
      requestId: string;
    }
  | {
      type: "CLEAR_DOCUMENT";
    }
  | {
      type: "TOGGLE_DOCUMENT_MODAL";
      showDocumentModal: boolean;
    };

export function documentsReducer(state: DocumentState, action: DocumentAction): DocumentState {
  switch (action.type) {
    case "SELECT_DOCUMENT":
      trackDocumentAttached(action.document, action.requestId);
      return {
        ...state,
        document: action.document
      };
    case "CLEAR_DOCUMENT":
      return {
        ...state,
        document: null
      };
    case "TOGGLE_DOCUMENT_MODAL":
      return {
        ...state,
        showDocumentModal: action.showDocumentModal
      };
  }
}
