import { ContentBlock, ContentState } from "draft-js";
import { getEntityRanges } from "draft-js-utils";

// draft-js-utils types converted from Flow to Typescript, from
// https://github.com/sstur/draft-js-utils/blob/fe6eb9853679e2040ca3ac7bf270156079ab35db/packages/draft-js-utils/src/getEntityRanges.js
type Style = Set<string>;
type StyleRange = [string, Style];
type EntityRange = [string | undefined, StyleRange[]];

/**
 * Format draft js editor state into plaintext
 * Based off: https://github.com/sstur/draft-js-utils/blob/master/packages/draft-js-export-markdown/src/stateToMarkdown.js
 */
export function formatContent(contentState: ContentState) {
  const blocks = contentState.getBlockMap().toArray();
  return blocks.map(renderBlockContent).join("\n");

  function renderBlockContent(block: ContentBlock): string {
    const blockText = block.getText();
    const charMetaList = block.getCharacterList();
    const entityPieces: EntityRange[] = getEntityRanges(blockText, charMetaList);
    return entityPieces
      .map(([entityKey, stylePieces]) => {
        const content = stylePieces.map(([text]) => text).join("");
        const entity = entityKey ? contentState.getEntity(entityKey) : null;
        if (entity && entity.getType() === "mention") {
          return `[${content}]`;
        }
        if (entity && entity.getType() === "+mention") {
          return `[${content}]`;
        }
        return content;
      })
      .join("");
  }
}

/**
 * Extract entities from draft-js editor state of specific type
 */
export function extractEntities<E extends object>(
  contentState: ContentState,
  entityMatchType: string,
  entityDataType: string
): E[] {
  return contentState
    .getBlockMap()
    .toArray()
    .reduce((acc, block) => {
      const blockText = block.getText();
      const charMetaList = block.getCharacterList();
      const entityPieces = getEntityRanges(blockText, charMetaList);
      const entityData = entityPieces
        .map(([entityKey]: [string]) => {
          const entity = entityKey ? contentState.getEntity(entityKey) : null;
          if (entity && entity.getType() === entityMatchType) {
            return entity.getData()[entityDataType];
          }
          return undefined;
        })
        .filter((b?: E) => !!b);
      return acc.concat(entityData);
    }, []);
}
