import * as React from "react";

import { CommentHtmlUrl, CommentHtmlUrlVariables } from "src/App/Requests/DetailView/typings/CommentHtmlUrl";
import { ConfirmDialogModal, MaterialIcon, SquareButton } from "src/components";
import { FC, useState } from "react";
import { NoteHtmlUrl, NoteHtmlUrlVariables } from "src/App/Requests/DetailView/typings/NoteHtmlUrl";
import { OptionMap, PopOver } from "src/components/PopOver";
import { RequestDeleteComment, RequestDeleteCommentVariables } from "./typings/RequestDeleteComment";
import { useMutation, useQuery } from "react-apollo";

import { DocumentCreateUpdateModal } from "src/App/KB/CreateUpdate/Modal";
import { REQUEST_GET } from "src/App/Requests/DetailView/REQUEST_GET";
import { RequestGet_request_team } from "./typings/RequestGet";
import gql from "graphql-tag";
import { mdiDotsHorizontal } from "@mdi/js";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { useSnack } from "src/App/Root/providers/SnackProvider";

interface IEventContextPopMenu {
  requestId: string;
  commentId: string;
  eventType: "comment" | "note";
  eventText?: string;
  hasForm?: boolean;
  hasHtml?: boolean;
  team: RequestGet_request_team;
}

export const EventContextPopMenu: FC<IEventContextPopMenu> = props => {
  const { emitSnack } = useSnack();

  const [documentDialogVisible, setDocumentDialogVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const { currentUser } = useCurrentUser();
  const isComment = props.eventType === "comment";
  const [deleteComment, deleteCommentResponse] = useMutation<RequestDeleteComment, RequestDeleteCommentVariables>(
    gql`
      mutation RequestDeleteComment($requestId: ID!, $commentId: ID!, $isTypeNote: Boolean) {
        requestDeleteComment(requestId: $requestId, commentId: $commentId, isTypeNote: $isTypeNote) {
          code
          success
          message
        }
      }
    `,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: REQUEST_GET,
          variables: {
            id: props.requestId
          }
        }
      ],
      variables: {
        requestId: props.requestId,
        commentId: props.commentId,
        isTypeNote: !isComment
      },
      onCompleted: res => {
        if (res.requestDeleteComment.success) {
          setDeleteDialogVisible(false);
        } else {
          emitSnack({
            type: "mutationError",
            message: res.requestDeleteComment.message
          });
        }
      }
    }
  );
  const commentHtmlUrlResponse = useQuery<CommentHtmlUrl, CommentHtmlUrlVariables>(
    gql`
      query CommentHtmlUrl($id: ID!) {
        commentHtmlUrl(id: $id)
      }
    `,
    {
      fetchPolicy: "network-only",
      skip: !isComment || !props.hasHtml,
      variables: {
        id: props.commentId
      }
    }
  );
  const noteHtmlUrlResponse = useQuery<NoteHtmlUrl, NoteHtmlUrlVariables>(
    gql`
      query NoteHtmlUrl($id: ID!) {
        noteHtmlUrl(id: $id)
      }
    `,
    {
      fetchPolicy: "network-only",
      skip: isComment || !props.hasHtml,
      variables: {
        id: props.commentId
      }
    }
  );
  if (currentUser && props.team.userIds.includes(currentUser.id)) {
    return (
      <div data-testid="event-context-menu">
        <PopOver.Menu
          options={
            [
              !props.hasForm &&
                !!props.eventText && {
                  id: "Add as answer",
                  name: "Add as answer",
                  onClick: () => setDocumentDialogVisible(true)
                },
              !props.hasForm &&
                !!props.eventText && {
                  id: "Add as saved reply",
                  name: "Add as saved reply",
                  linkAttrs: {
                    to: `/settings/saved-replies/${props.team?.slug}/create?body=${encodeURIComponent(props.eventText)}`
                  }
                },
              props.hasHtml &&
                (commentHtmlUrlResponse.loading || noteHtmlUrlResponse.loading
                  ? {
                      name: "Loading…",
                      id: "Loading",
                      onClick: () => false,
                      testId: "event-context-menu-show-original-html"
                    }
                  : {
                      name: "Show original HTML",
                      id: "Show original HTML",
                      testId: "event-context-menu-show-original-html",
                      linkAttrs: {
                        target: "_blank",
                        href: commentHtmlUrlResponse.data?.commentHtmlUrl || noteHtmlUrlResponse.data?.noteHtmlUrl
                      }
                    })
            ].filter(Boolean) as Array<OptionMap>
          }
          trigger={
            <SquareButton data-testid="event-context-menu-button">
              <MaterialIcon path={mdiDotsHorizontal} size={1.125} />
            </SquareButton>
          }
          onRemove={() => setDeleteDialogVisible(true)}
          labelForRemoveOption={`Delete ${props.eventType}`}
        />

        <DocumentCreateUpdateModal
          isOpen={documentDialogVisible}
          onDismiss={() => setDocumentDialogVisible(false)}
          onSuccess={() => setDocumentDialogVisible(false)}
          text={props.eventText}
          type="create"
          commentId={props.commentId}
        />
        <ConfirmDialogModal
          isOpen={deleteDialogVisible}
          loading={deleteCommentResponse.loading}
          submittingConfirm={deleteCommentResponse.loading}
          text={{
            cancel: "No thanks, cancel",
            confirm: "Yes, delete it!",
            heading: `Delete ${props.eventType}`
          }}
          handleCancel={() => setDeleteDialogVisible(false)}
          handleConfirm={() => deleteComment()}
        >
          <p>Are you sure you want to delete this {props.eventType}?</p>
          {props.hasForm && (
            <em>
              <strong>Note:</strong> The form will no longer be available to the requester
            </em>
          )}
        </ConfirmDialogModal>
      </div>
    );
  } else return null;
};
