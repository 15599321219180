import { css } from "@emotion/core";
import * as React from "react";
import { Icon } from "src/components";
import { Typo } from "src/styling/primitives/typography";
import { isString } from "src/util";

interface IconAndText {
  icon: React.ReactNode;
  label: React.ReactNode;
  className?: string;
}

/**
 * Returns an Icon on the left witrh a text nextr to it
 * @param icon Icon component (most likely our own icons)
 * @param label ReactNode for the Text with Link/PopOver
 */
export const IconAndText: React.FC<IconAndText> = ({ icon, label, className }) => (
  <div
    className={className}
    css={css`
      &,
      & * {
        /*  prevent cutting large characters top / bottom */
        line-height: 1.25rem;
      }
    `}
  >
    <Icon
      css={css`
        padding-top: 2px;
        margin-right: var(--space-2-rem);
        float: left;
      `}
    >
      {icon}
    </Icon>
    {isString(label) ? <Typo.Body>{label}</Typo.Body> : label}
  </div>
);
