import { css } from "@emotion/core";
import gql from "graphql-tag";
import * as React from "react";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import { SETTINGS_TEAM_LIST_GET } from "src/App/Settings/Teams/Overview";
import { SETTINGS_TEAM_GET } from "src/App/Settings/Teams/Update/Container";
import { GSettingsTeamGet, GSettingsTeamGetVariables } from "src/App/Settings/Teams/Update/typings/GSettingsTeamGet";
import { UserAvatar } from "src/App/User";
import { SelectCreateUsers, SelectUserValue } from "src/App/User/UserSelect";
import { Button, Col, EllipsisText, LoadingBar, Row } from "src/components";
import { ConfirmButton } from "src/components/ConfirmButton";
import { Toast } from "src/portals/Toast";
import { SETTINGS_TEAM_ADD_SEND } from "../Add/Submission";
import { GSettingsTeamAddSend, GSettingsTeamAddSendVariables } from "../Add/typings/GSettingsTeamAddSend";
import { RemoveMember, RemoveMemberVariables } from "./typings/RemoveMember";

const REMOVE_MEMBER = gql`
  mutation RemoveMember($params: TeamUsersRemove!) {
    teamRemove(params: $params) {
      code
      message
      success
    }
  }
`;

export function ManageMembers(props: { teamId: string }) {
  const teamSettingsResponse = useQuery<GSettingsTeamGet, GSettingsTeamGetVariables>(SETTINGS_TEAM_GET, {
    fetchPolicy: "network-only",
    variables: {
      id: props.teamId
    }
  });
  const refetchQueries = [
    {
      query: SETTINGS_TEAM_GET,
      variables: {
        id: props.teamId
      }
    },
    {
      query: SETTINGS_TEAM_LIST_GET
    }
  ];
  const [removeMember, removeMemberResponse] = useMutation<RemoveMember, RemoveMemberVariables>(REMOVE_MEMBER, {
    refetchQueries
  });
  const [selectedUsers, setSelectedUsers] = useState<SelectUserValue[]>([]);
  const [inviteMembers, inviteMembersResponse] = useMutation<GSettingsTeamAddSend, GSettingsTeamAddSendVariables>(
    SETTINGS_TEAM_ADD_SEND,
    { refetchQueries }
  );
  useEffect(() => {
    if (inviteMembersResponse.data && inviteMembersResponse.data.teamAdd.success) {
      setSelectedUsers([]);
    }
  }, [inviteMembersResponse]);
  return (
    <Col
      css={css`
        width: 36rem;
      `}
    >
      {removeMemberResponse.data?.teamRemove.success === false && (
        <Toast kind="mutationError" message={removeMemberResponse.data?.teamRemove.message} />
      )}
      {inviteMembersResponse.data?.teamAdd.success === false && (
        <Toast kind="mutationError" message={inviteMembersResponse.data?.teamAdd.message} />
      )}
      {(teamSettingsResponse.loading || removeMemberResponse.loading || inviteMembersResponse.loading) && (
        <LoadingBar />
      )}
      {teamSettingsResponse.data && teamSettingsResponse.data.team && (
        <>
          {teamSettingsResponse.data.team.users.map((user, i) => (
            <Row
              key={user.id}
              css={css`
                align-items: center;
                margin: 0.25rem 0;
              `}
            >
              <Row
                css={css`
                  align-items: center;
                  flex: 1 1 auto;
                  overflow: hidden;
                `}
              >
                <UserAvatar
                  sizeM
                  user={user}
                  css={css`
                    margin-right: var(--space-3-rem);
                  `}
                />
                <EllipsisText>
                  {user.name ? (
                    <>
                      {user.name}&nbsp;
                      <span
                        css={css`
                          color: var(--text-3);
                        `}
                      >
                        ({user.email})
                      </span>
                    </>
                  ) : (
                    user.email
                  )}
                </EllipsisText>
              </Row>
              <Row
                css={css`
                  flex: 1 1 auto;
                  align-items: center;
                  justify-content: flex-end;
                `}
              >
                <ConfirmButton
                  disabled={removeMemberResponse.loading}
                  onClick={() => {
                    removeMember({
                      variables: {
                        params: {
                          teamId: props.teamId,
                          userIds: [user.id]
                        }
                      }
                    });
                  }}
                />
              </Row>
            </Row>
          ))}
          <Row
            css={css`
              margin: 1rem 0 0 0;
            `}
          />
          <Row
            css={css`
              align-items: flex-end;
              margin: 1rem 0 0 0;
              > div:first-of-type {
                // hotfix css overflow bug as applying overflow:hidden also hid the popper
                max-width: calc(100% - 140px);
              }
            `}
          >
            <SelectCreateUsers
              filterUsers={teamSettingsResponse.data.team.users}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
            />
            <Button
              disabled={selectedUsers.length === 0 || inviteMembersResponse.loading}
              variant="primary"
              size="large"
              css={css`
                margin-left: 1rem;
              `}
              onClick={() => {
                inviteMembers({
                  variables: {
                    params: {
                      teamId: props.teamId,
                      emails: selectedUsers.map(u => u.email)
                    }
                  }
                });
              }}
            >
              Invite members
            </Button>
          </Row>
        </>
      )}
    </Col>
  );
}
