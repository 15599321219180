import { css } from "@emotion/core";
import React from "react";
import OktaCircle from "src/assets/logos/OktaCircle.svg";
import { csx } from "src/util/csx";
import { Subheading } from "./Component";
import { OktaProfileInfo_oktaUser } from "./typings/OktaProfileInfo";

const Logo = csx(
  [
    css`
      display: inline-block;
      vertical-align: middle;
      width: 1em;
      height: 1em;
    `
  ],
  {},
  "img"
);

const OktaSubheading: React.FC = ({ children }) => (
  <Subheading>
    <Logo src={OktaCircle} alt="Okta logo" /> {children}
  </Subheading>
);

export const OktaRequesterProfile: React.FC<{ user: OktaProfileInfo_oktaUser }> = ({ user }) => {
  return (
    <>
      <div
        css={css`
          & > * + * {
            margin-bottom: 0.5rem;
          }
        `}
      >
        <OktaSubheading>Okta Groups ({user.groups.length})</OktaSubheading>
        {user.groups
          .map(g => g.name)
          .sort((a, b) => a.localeCompare(b))
          .join(", ")}
      </div>
      <div>
        <OktaSubheading>Okta Apps ({user.apps.length})</OktaSubheading>
        {user.apps
          .map(a => a.app.label)
          .sort((a, b) => a.localeCompare(b))
          .join(", ")}
      </div>
    </>
  );
};
