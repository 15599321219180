import { css } from "@emotion/core";
import { captureException } from "@sentry/browser";
import { Field, Formik } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { useMutation, useQuery } from "react-apollo";
import { GetApproval, GetApprovalVariables } from "src/App/Approvals/typings/GetApproval";
import { ResolveApproval, ResolveApprovalVariables } from "src/App/Approvals/typings/ResolveApproval";
import { MessageBox } from "src/App/Requests/components";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { UserName } from "src/App/User";
import { LoadingBar, SubmitButton, TextAreaField } from "src/components";
import { ApprovalStatus } from "src/globalTypes";
import { Card } from "src/styling/primitives/Card";
import { Typo } from "src/styling/primitives/typography";
import { authTokenContext } from "src/util";
import { csx } from "src/util/csx";
import { RouteComponentProps } from "src/util/router";

const Wrapper = csx([
  css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    background: var(--lightGrey-1);
  `
]);

export function ProcessApprovalPage(props: RouteComponentProps<{ approval_id: string }>) {
  const [, authToken] = (props.location && props.location.search.match(/.*auth_token=(.*)/)) || [null, null];
  if (!props.approval_id) {
    throw new Error("no approval_id found");
  }

  const { emitSnack } = useSnack();

  const { data, error, loading } = useQuery<GetApproval, GetApprovalVariables>(
    gql`
      query GetApproval($id: ID!) {
        approval(id: $id) {
          id
          status
          text
          request {
            title
            requester {
              id
              name
              email
            }
          }
          author {
            id
            name
            email
          }
        }
      }
    `,
    {
      variables: {
        id: props.approval_id
      },
      context: authTokenContext(authToken)
    }
  );

  const [updateApproval, updateApprovalResponse] = useMutation<ResolveApproval, ResolveApprovalVariables>(
    gql`
      mutation ResolveApproval($params: RequestResolveApprovalParameters!) {
        requestResolveApproval(params: $params) {
          code
          success
          message
        }
      }
    `,
    {
      context: authTokenContext(authToken)
    }
  );

  const wasResolved =
    data?.approval.status === ApprovalStatus.APPROVAL_STATUS_APPROVED ||
    data?.approval.status === ApprovalStatus.APPROVAL_STATUS_REJECTED;
  const wasUpdated = updateApprovalResponse.data?.requestResolveApproval.success === true;

  if (error || data?.approval.status === ApprovalStatus.APPROVAL_STATUS_UNSPECIFIED) {
    captureException(error || new Error(`approval ${data?.approval.id} has an unspecified status`));
    return (
      <Wrapper>
        <ErrorCard />
      </Wrapper>
    );
  } else if (!data || loading) {
    return <LoadingBar />;
  } else if (wasResolved || wasUpdated) {
    return (
      <Wrapper>
        <ConfirmationCard existingStatus={data.approval.status} wasUpdated={wasUpdated} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Card
        sizeM
        hasExtraPadding
        css={css`
          width: 640px;
          & > * + * {
            margin-top: var(--space-7-px);
          }
        `}
      >
        <div
          css={css`
            align-items: flex-start;

            & > * + * {
              margin-top: var(--space-4-px);
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;

              & > * + * {
                margin-top: var(--space-2-px);
              }
            `}
          >
            <Typo.Body sizeXL medium>
              {data.approval.author.name} asked you for approval
            </Typo.Body>
            <Typo.Body
              css={css`
                b {
                  font-weight: var(--font-weight-body-medium);
                }
              `}
            >
              In the request <b>{data.approval.request.title}</b> by{" "}
              <b>
                <UserName user={data.approval.request.requester} />
              </b>
              :
            </Typo.Body>
          </div>
          <MessageBox
            css={css`
              display: block;
              white-space: pre-wrap;
            `}
            grey
          >
            <Typo.Body italic>{data.approval.text}</Typo.Body>
          </MessageBox>
        </div>
        <Formik
          initialValues={{
            comment: "",
            status: ApprovalStatus.APPROVAL_STATUS_UNSPECIFIED
          }}
          onSubmit={async (form, actions) => {
            const response = await updateApproval({
              variables: {
                params: {
                  approvalId: data.approval.id,
                  status: form.status,
                  comment: form.comment
                }
              }
            });

            if (response.data?.requestResolveApproval.success === false) {
              emitSnack({
                type: "mutationError",
                message: response.data.requestResolveApproval.message
              });
            }

            actions.setSubmitting(false);
          }}
          render={form => (
            <div
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;

                  & > * + * {
                    margin-top: var(--space-2-px);
                  }
                `}
              >
                <Typo.Body medium>
                  Comment&nbsp;
                  <span
                    css={css`
                      font-weight: var(--font-weight-body-regular);
                      color: var(--text-3);
                    `}
                  >
                    (optional)
                  </span>
                </Typo.Body>
                <Field
                  name="comment"
                  component={TextAreaField}
                  fullWidth
                  data-testid="approval-comment-text"
                  height="6.5rem"
                  hideErrorLabel
                  autoFocus
                />
              </div>
              <div
                css={css`
                  display: flex;
                  margin-top: var(--space-3-px);

                  & > * + * {
                    margin-left: var(--space-1-px);
                  }
                `}
              >
                <SubmitButton
                  onClick={() => {
                    form.setFieldValue("status", ApprovalStatus.APPROVAL_STATUS_APPROVED, false);
                    form.handleSubmit();
                  }}
                  variant="green"
                  disabled={form.isSubmitting}
                  data-testid="approval-approve-btn"
                >
                  Approve
                </SubmitButton>
                <SubmitButton
                  onClick={() => {
                    form.setFieldValue("status", ApprovalStatus.APPROVAL_STATUS_REJECTED, false);
                    form.handleSubmit();
                  }}
                  variant="red"
                  disabled={form.isSubmitting}
                  data-testid="approval-reject-btn"
                >
                  Reject
                </SubmitButton>
              </div>
              {form.isSubmitting && <LoadingBar />}
            </div>
          )}
        />
      </Card>
    </Wrapper>
  );
}

const ConfirmationCard: React.ComponentType<{ existingStatus: ApprovalStatus; wasUpdated: boolean }> = props => (
  <Card
    sizeM
    hasExtraPadding
    css={css`
      width: auto;
    `}
  >
    <div
      css={css`
        & > * + * {
          margin-top: var(--space-2-px);
        }
      `}
    >
      <Typo.Body sizeXL medium>
        {(props.existingStatus === ApprovalStatus.APPROVAL_STATUS_APPROVED && "You approved this request") ||
          (props.existingStatus === ApprovalStatus.APPROVAL_STATUS_REJECTED && "You rejected this request") ||
          (props.wasUpdated && "Your approval was resolved")}
      </Typo.Body>
    </div>
  </Card>
);

const ErrorCard: React.ComponentType = () => (
  <Card
    sizeM
    hasExtraPadding
    css={css`
      width: auto;
      & > * + * {
        margin-top: var(--space-2-px);
      }
    `}
  >
    <Typo.Body sizeXL medium>
      There was an unexpected error
    </Typo.Body>
    <Typo.Body>We've been notified! Please try again later.</Typo.Body>
  </Card>
);
