/**
 * LEGACY
 * Use primitives.ts
 */

/* @deprecated */
export const fontWeights = {
  bold: 600,
  regular: 400
} as const;

/* @deprecated */
export const fontSizes = {
  small: 0.6875,
  default: 0.875,
  header: 2.5,
  headingXL: 2.5,
  headingL: 2,
  requestCardTitle: 1.375,
  tooltip: 0.75
} as const;

/* @deprecated */
export const lineHeights = {
  header: 1.2,
  body: 1.5
} as const;

/* @deprecated */
export const fontFamily = {
  heading: "var(--font-family-display)",
  body: "var(--font-family-body)"
};
