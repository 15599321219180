import { css } from "@emotion/core";
import gql from "graphql-tag";
import uniqBy from "lodash/uniqBy";
import React, { useMemo } from "react";
import { useQuery } from "react-apollo";
import { priorityLabels } from "src/App/Requests/Actions/RequestPriorityButton";
import { useRequestList } from "src/App/Requests/ListView/Provider";
import { RequestListFragment_requester } from "src/App/Requests/ListView/typings/RequestListFragment";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { CleanButton } from "src/components";
import { OptionMap } from "src/components/PopOver";
import { REQUEST_TEAM_FRAGMENT } from "src/fragments/TeamInfoFragment";
import {
  OrderByKey,
  RequestListSortKey,
  RequestPriority,
  RequestStatus,
  SortDirection,
  SortOptions
} from "src/globalTypes";
import { grpc } from "src/grpc";
import { dimensions, stackOrder } from "src/styling/layout";
import { keys } from "src/util";
import { statusText } from "src/util/formatters";
import { FiltersCurrentUser } from "./typings/FiltersCurrentUser";
import { Filters } from "./urlParamHelpers";

export const WILDCARD_FILTER = "-";
const FILTER_UNSET = "_unset";

export const filterBarWrapperStyles = css`
  padding: var(--space-5-rem);
  box-sizing: border-box;
  min-height: 5rem;
  width: 100%;
  max-width: ${dimensions.card.maxWidth + 3}rem;

  display: flex;
  align-items: center;
  flex: 1 0 auto;
  z-index: ${stackOrder.high};
  font-size: var(--font-size-body);

  & .filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1 1 auto;
  }
  & .menu.open ${CleanButton} {
    background: rgba(var(--text-6-raw), 0.075);
  }
  & .menu.open ${/* sc-sel */ CleanButton}[disabled] {
    background: var(--red-1);
  }
  & .hover-wrap:hover ${CleanButton} {
    background: rgba(var(--text-6-raw), 0.075);
  }
`;

export const sortList: {
  label: string;
  key: OrderByKey;
  sort: SortOptions;
}[] = [
  {
    label: "Unread first",
    key: OrderByKey.isUnread,
    sort: {
      sortByField: RequestListSortKey.update_time,
      direction: SortDirection.DESCENDING,
      unreadFirst: true
    }
  },
  {
    label: "Last updated",
    key: OrderByKey.updateTime,
    sort: {
      sortByField: RequestListSortKey.update_time,
      direction: SortDirection.DESCENDING,
      unreadFirst: false
    }
  },
  {
    label: "Created at",
    key: OrderByKey.requestedAt,
    sort: {
      sortByField: RequestListSortKey.create_time,
      direction: SortDirection.DESCENDING,
      unreadFirst: false
    }
  },
  {
    label: "Due date",
    key: OrderByKey.dueAt,
    sort: {
      sortByField: RequestListSortKey.due_date,
      direction: SortDirection.ASCENDING,
      unreadFirst: false
    }
  }
];

/**
 * backend returns "" for no category id
 * & recognizes "unset" for filtering
 * */
const UNCATEGORIZED = {
  id: FILTER_UNSET,
  name: "Uncategorized"
};

/** Map priority string enum to valid filter values */
const requestPriorityMapping = {
  ...grpc.RequestPriority,
  PRIORITY_UNSPECIFIED: "_unset"
};

export function useFilterOptions() {
  const { currentUser } = useCurrentUser();
  const [requestList] = useRequestList();
  const filtersResponse = useQuery<FiltersCurrentUser>(gql`
    query FiltersCurrentUser {
      currentUser {
        id
        teams {
          ...RequestTeamInfo
        }
      }
    }
    ${REQUEST_TEAM_FRAGMENT}
  `);

  const requesterList = React.useMemo(() => {
    const list: RequestListFragment_requester[] = [];
    for (const request of requestList.response.data?.requestListPage.requests ?? []) {
      if (request.requester && !list.find(r => r.id === request.requester?.id)) {
        list.push(request.requester);
      }
    }
    return uniqBy(list, obj => obj.email).sort((a, b) => a.name.localeCompare(b.name));
  }, [requestList]);

  const assigneeList: { id: string; name: string }[] = uniqBy(
    filtersResponse.data?.currentUser?.teams
      .flatMap(team => team.users)
      .map(u => ({
        id: u.id,
        name: u.name !== "" ? u.name : u.email
      })) ?? [],
    obj => obj.id
  );

  const filterOptionsMap: {
    [key in keyof Filters]: {
      headline: string;
      options: OptionMap[];
    }[];
  } = useMemo(
    () => ({
      team: [
        {
          headline: "Filter by team",
          options: currentUser?.teams.map(team => ({ id: team.id, name: team.name })) ?? []
        }
      ],
      category: [
        { headline: "Filter by category", options: [UNCATEGORIZED] },
        ...(filtersResponse.data?.currentUser?.teams.map(t => {
          return {
            headline: t.name,
            options: (t.categories ?? [])
              .map(({ id, name }) => ({ id, name }))
              .sort((a, b) => a.name.localeCompare(b.name))
          };
        }) ?? [])
      ],
      status: [
        {
          headline: "Filter by status",
          options: keys(grpc.RequestStatus).map(k => {
            return {
              id: String(grpc.RequestStatus[k]),
              name: statusText(k as RequestStatus)
            };
          })
        }
      ],
      customStatus: [
        { headline: "Filter by status", options: [] },
        ...(filtersResponse.data?.currentUser?.teams.map(t => {
          return {
            headline: t.name,
            // haven't included icon here since we dump the reducer state (which includes these options)
            // in browser history and if the state contains react elements, the copy fails at the browser level.
            options: (t.customStatuses ?? [])
              .filter(({ step }) => step !== RequestStatus.RESOLVED)
              .map(({ id, name }) => ({
                id,
                name
              }))
          };
        }) ?? [])
      ],
      requester: [
        {
          headline: "Filter by requester",
          options: requesterList.map(requester => ({
            id: requester.id,
            name: currentUser?.id === requester.id ? "You" : requester.name === "" ? requester.email : requester.name
          }))
        }
      ],
      priority: [
        {
          headline: "Filter by priority",
          options: (Object.values(RequestPriority) ?? []).map(priority => ({
            id: String(requestPriorityMapping[priority]),
            name: priorityLabels[priority]
          }))
        }
      ],
      assignee: [
        {
          headline: "Filter by assignee",
          options: [
            { id: currentUser?.id ?? "", name: "You" },
            { id: "_unset", name: "Unassigned" }
          ]
            .concat(assigneeList.filter(assignee => currentUser?.id !== assignee.id) || [])
            .map(assignee => ({
              id: assignee.id,
              name: assignee.name
            }))
            .sort((a, b) => a.name.localeCompare(b.name))
        }
      ],
      type: [],
      fts: [],
      topic: []
    }),
    [assigneeList, currentUser, filtersResponse.data, requesterList]
  );

  const getFilterNameById = (key: keyof Filters, id: string) => {
    const name =
      Object.entries(filterOptionsMap[key])
        .flatMap(([k, o]) => o.options)
        .find(o => o.id === id)?.name ?? "Unknown";
    if (id === WILDCARD_FILTER) {
      return "All";
    } else if (id === "me") {
      return "You";
    }
    return name;
  };

  return { filterOptionsMap, getFilterNameById };
}
