import { navigate, NavigateFn, NavigateOptions, useLocation, useMatch as _useMatch } from "@reach/router";
import { reportDevError } from "src/util";

const navigateFn: NavigateFn = (to: string | number, options?: NavigateOptions<{}>) => {
  if (typeof to === "number") {
    reportDevError("Number not supported in navigate");
    return Promise.resolve();
  }
  const a = document.createElement("a");
  a.href = to;
  if (a.host !== window.location.host) {
    reportDevError(`Invalid navigate path: ${to}`);
    return Promise.resolve();
  }
  return (navigate as NavigateFn)(to, options);
};

export * from "@reach/router";
export { navigateFn as navigate };

/**
 * Set state as url param
 * setting state to null resets to default value
 * @param name url param name
 * @param defaultValue initial/fallback value
 */
export function useUrlState<S>(name: string, defaultValue: S) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  return [
    searchParams.get(name) ?? defaultValue,
    (value: string | null, replace?: true) => {
      searchParams.delete(name);
      if (value !== null) searchParams.set(name, value);
      navigateFn(`${location.pathname}?${searchParams}`, { replace });
    }
  ] as const;
}

export function useSearchParams() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  return [
    searchParams,
    (name: string, value: string[] | null, replace?: true) => {
      searchParams.delete(name);
      (value ?? []).forEach(v => searchParams.set(name, v));
      navigateFn(`${location.pathname}?${searchParams}`, { replace });
    }
  ] as const;
}

/** extends useMatch to provide param typing */
export function useMatch<P extends Record<string, string>>(path: string) {
  return _useMatch(path) as null | ({ uri: string; path: string } & P);
}
