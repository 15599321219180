import { css } from "@emotion/core";

export const slideInLeftRight = css`
  transform: translateX(-100%);
  animation: slideInLeftRight 0.3s ease-out 0.3s forwards;
`;

export const expandDown = css`
  max-height: 0;
  opacity: 0;
  animation: expandDown ease-out 0.3s forwards;
`;
