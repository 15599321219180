import { PopMenu } from "./PopMenu";
import {
  OptionGroup as PopOverOptionGroup,
  OptionMap as PopOverOptionMap,
  OptionsUnion as PopOverOptionsUnion,
  PopOverMenu
} from "./PopOverMenu";

export const PopOver = {
  Blank: PopMenu,
  Menu: PopOverMenu
};

export type OptionMap = PopOverOptionMap;
export type OptionGroup = PopOverOptionGroup;
export type OptionsUnion = PopOverOptionsUnion;
