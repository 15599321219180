import { css } from "@emotion/core";
import React from "react";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { Button, Dialog, LoadingBar } from "src/components";
import { Modal } from "src/portals/Modal";
import { Typo } from "src/styling/primitives/typography";
import { Link, navigate } from "src/util/router";
import { AddNotion } from "./typings/AddNotion";

const ADD_NOTION = gql`
  mutation AddNotion {
    externalDocumentSourceCreate(params: { notion: {} }) {
      code
      message
      success
      redirect {
        url
      }
    }
  }
`;

export const NotionInstallModal = (props: { onDismiss(): void; onSuccess(): void; isOpen: boolean }) => {
  const { emitSnack } = useSnack();

  const [addNotion, addNotionReponse] = useMutation<AddNotion, {}>(ADD_NOTION, {
    onCompleted: data => {
      if (data.externalDocumentSourceCreate.success) {
        window.location.assign(data.externalDocumentSourceCreate.redirect!.url);
      } else if (data.externalDocumentSourceCreate.success === false) {
        emitSnack({
          type: "mutationError",
          message: data.externalDocumentSourceCreate.message
        });
      }
    }
  });

  return (
    <Modal isOpen={props.isOpen} onDismiss={props.onDismiss}>
      <Dialog medium title="Connect Notion" onClose={props.onDismiss}>
        {addNotionReponse.loading && <LoadingBar />}
        <Typo.Body>
          Sign-in to Notion to connect Notion and Back. From Notion you will be able to select documents you want to
          make available to share on Back.
        </Typo.Body>
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
          `}
        >
          <Button
            variant="primary"
            size="large"
            disabled={addNotionReponse.loading}
            onClick={() => {
              addNotion();
            }}
          >
            Connect
          </Button>
        </div>
      </Dialog>
    </Modal>
  );
};

export const NotionConfirmationModal = (props: { onDismiss(): void; error: boolean; isOpen: boolean }) => (
  <Modal isOpen={props.isOpen} onDismiss={props.onDismiss}>
    <Dialog
      title={props.error ? "Something went wrong" : "Notion connected successfully"}
      onClose={props.onDismiss}
      medium
    >
      {props.error ? (
        <>
          <Typo.Body>
            Unfortunately we could not finish connecting Back to your Notion workspace. Please get in touch with us so
            that we can help you sort it out.
          </Typo.Body>
          <div
            css={css`
              display: flex;
              justify-content: center;
            `}
          >
            <Button
              variant="primary"
              size="large"
              onClick={() => {
                // Intercom inserted by segment at runtime
                if (window.Intercom) {
                  window.Intercom("showNewMessage", "I'm having problems with the Notion installation");
                } else {
                  window.open("http://support.back.ee/");
                }
              }}
            >
              Contact us
            </Button>
          </div>
        </>
      ) : (
        <>
          <Typo.Body>
            You have successfully connected Notion and Back. Any documents you share from Notion will know show up on
            the <Link to="/knowledge">knowledge management page</Link>, and when you select documents to share in
            requests.
          </Typo.Body>
          <div
            css={css`
              display: flex;
              justify-content: center;
              margin-top: var(--space-4-rem);
              & > * + * {
                margin-left: var(--space-2-rem);
              }
            `}
          >
            <Button variant="primary" size="large" onClick={() => navigate("/knowledge")}>
              Go to knowledge management
            </Button>
            <Button size="large" onClick={props.onDismiss}>
              Close
            </Button>
          </div>
        </>
      )}
    </Dialog>
  </Modal>
);
