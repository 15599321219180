import * as React from "react";
import * as yup from "yup";

import { AttachmentFieldResponse, DropdownResponse, FormButton, FormikResponseValue, TextfieldResponse } from "./Form";
import { ErrorMessage, Field, FieldProps } from "formik";
import { FC, useEffect, useReducer } from "react";
import { FieldType, TextFieldType } from "src/globalTypes";
import { FileStatus, filesReducer, LocalFileInfo } from "src/App/Attachments/reducer";
import { ImageViewer, createImageListFromAttachments } from "src/App/Attachments/ImageViewer";
import { NumberField, TextField } from "src/App/Forms/Components";
import { formatDateString, formatDateTimeString } from "src/util/formatters";

import { AttachmentItem } from "src/App/Attachments/Item";
import { DatePicker } from "src/components/DayPicker";
import { FileStack } from "src/App/Requests/DetailView/InputBar/FileStack";
import { FormikFieldGroup } from "src/components";
import { SingleSelect } from "src/components/Fields/Select";
import { ValueType } from "react-select/src/types";
import { css } from "@emotion/core";
import { csx } from "src/util/csx";
import { getAuthToken } from "src/App/Forms/helpers";
import { parseISO } from "date-fns";
import { reportDevError } from "src/util";
import { useFileInput } from "src/App/Attachments/Menu";

const FieldWrapper = csx([
  css`
    & .select__control {
      font-size: 1.5rem;
      line-height: 1.5;
      color: var(--text-6);
      background: none;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid var(--text-1);
    }

    & .select__control:hover,
    .select__control--is-focused {
      border-bottom: 1px solid var(--text-6);
    }

    & .select__single-value {
      font-size: 1.5rem;
      line-height: 1.5;
      color: var(--text-6);
    }

    & .select__control--is-focused .select__single-value {
      position: absolute;
    }

    & .select__value-container--has-value input:focus {
      outline: none;
    }

    & .select__indicators {
      background: none;
    }

    & .select__menu {
      z-index: var(--z-highest);
    }

    & .select__option {
      font-size: 1.25rem;
    }
  `
]);

interface FormFieldProps<R extends FormikResponseValue> {
  field: {
    index: number;
    fieldType: FieldType;
    textFieldType?: TextFieldType;
    optionsList?: string[];
    required?: boolean;
  };
  response: R;
  isFocused: boolean;
  isSubmitted: boolean;
  onFocus(e: React.FocusEvent<HTMLElement>): void;
  onComplete(): void;
}

/**
 * Map different form fields
 * Defines common interface for handling focus etc.
 */
export const FieldInterface: FC<FormFieldProps<FormikResponseValue>> = props => {
  if (props.response.fieldType === FieldType.TEXT_FIELD && props.field.textFieldType === TextFieldType.DATE) {
    return (
      <DateField
        field={props.field}
        response={props.response}
        isFocused={props.isFocused}
        isSubmitted={props.isSubmitted}
        onFocus={props.onFocus}
        onComplete={props.onComplete}
      />
    );
  }
  if (props.response.fieldType === FieldType.TEXT_FIELD && props.field.textFieldType === TextFieldType.NUMBER) {
    return (
      <NumberFieldField
        field={props.field}
        response={props.response}
        isFocused={props.isFocused}
        isSubmitted={props.isSubmitted}
        onFocus={props.onFocus}
        onComplete={props.onComplete}
      />
    );
  }
  if (props.response.fieldType === FieldType.TEXT_FIELD) {
    return (
      <TextFieldField
        field={props.field}
        response={props.response}
        isFocused={props.isFocused}
        isSubmitted={props.isSubmitted}
        onFocus={props.onFocus}
        onComplete={props.onComplete}
      />
    );
  }
  if (props.response.fieldType === FieldType.DROPDOWN_FIELD) {
    return (
      <DropdownField
        field={props.field}
        response={props.response}
        isFocused={props.isFocused}
        isSubmitted={props.isSubmitted}
        onFocus={props.onFocus}
        onComplete={props.onComplete}
      />
    );
  }
  if (props.response.fieldType === FieldType.ATTACHMENT_FIELD) {
    return (
      <FileUploadField
        field={props.field}
        response={props.response}
        isFocused={props.isFocused}
        isSubmitted={props.isSubmitted}
        onFocus={props.onFocus}
        onComplete={props.onComplete}
      />
    );
  }
  reportDevError("Unknown field type", props);
  return null;
};

const yupFieldTypes: {
  [key in TextFieldType]: undefined | yup.MixedSchema;
} = {
  [TextFieldType.SHORT_TEXT]: undefined,
  [TextFieldType.LONG_TEXT]: undefined,
  [TextFieldType.DATE]: yup.date(),
  [TextFieldType.NUMBER]: yup
    .string()
    .trim()
    .matches(/^\d+\.?\d*$/, "Sorry, but this does not look like a valid decimal number (e.g. 4.2)."),
  [TextFieldType.URL]: yup
    .string()
    .trim()
    .url("Sorry, but this does not look like a valid URL (e.g. https://backhq.com)."),
  [TextFieldType.EMAIL]: yup
    .string()
    .trim()
    .email("Sorry, but this does not look like a valid email address (e.g. jimmy@acme.corp).")
};

const requiredMessage = "This field is a required field.";

const validateTextFieldType =
  (required?: boolean, textFieldType?: TextFieldType) =>
  // the first parameters are curried so that we can return this async fn for the formik validate prop
  async (val: string) => {
    const yupFieldTypeSchema = textFieldType ? yupFieldTypes[textFieldType] : undefined;
    // yup schemas will throw an error on validation
    try {
      // first check if field fulfills required condition
      if (required) {
        await yup.string().trim().required(requiredMessage).validate(val);
      }
      if (!yupFieldTypeSchema) return;
      // validate if there's a schema for the field type
      !!val && (await yupFieldTypeSchema.validate(val));
    } catch (e) {
      if (e instanceof yup.ValidationError) {
        return (e as yup.ValidationError).message;
      }
    }
  };

/** Formik Field TextField */
const TextFieldField: FC<FormFieldProps<TextfieldResponse>> = props => {
  const name = `${props.field.index}.value`;
  return (
    <FieldWrapper>
      <Field
        required={props.field.required}
        name={name}
        validate={validateTextFieldType(props.field.required, props.field.textFieldType)}
      >
        {(fieldProps: FieldProps) => (
          <>
            <TextField
              key={String(props.isFocused)} // force component to re-render as autoFocus works only when a component is mounted
              multiline={props.field.textFieldType === TextFieldType.LONG_TEXT}
              name={name}
              placeholder="Type something"
              autoFocus={props.isFocused}
              disabled={props.isSubmitted}
              rowsMax={8}
              onFocus={props.onFocus}
              onKeyDown={e => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  // set field as blurred on submit
                  fieldProps.field.onBlur(e);
                  props.onComplete();
                }
              }}
              value={fieldProps.field.value}
              onChange={fieldProps.field.onChange}
              onBlur={fieldProps.field.onBlur}
              hasError={!!(fieldProps.form.errors[props.field.index] && fieldProps.form.touched[props.field.index])}
            />
            {props.field.textFieldType === TextFieldType.LONG_TEXT && (
              <FormikFieldGroup.HelpText
                css={[
                  css`
                    margin-top: var(--space-1-rem);
                  `
                ]}
              >
                Shift ⇧ + Enter ↵ to make a line break
              </FormikFieldGroup.HelpText>
            )}
            <FormikFieldGroup.Errors>
              <ErrorMessage name={name} />
            </FormikFieldGroup.Errors>
          </>
        )}
      </Field>
    </FieldWrapper>
  );
};

const NumberFieldField: FC<FormFieldProps<TextfieldResponse>> = props => {
  const name = `${props.field.index}.value`;
  return (
    <FieldWrapper>
      <Field
        required={props.field.required}
        name={name}
        validate={validateTextFieldType(props.field.required, props.field.textFieldType)}
      >
        {(fieldProps: FieldProps) => {
          const hasError = !!(fieldProps.form.errors[props.field.index] && fieldProps.form.touched[props.field.index]);
          return (
            <>
              <NumberField
                key={String(props.isFocused)}
                name={name}
                placeholder="Type something"
                onFocus={props.onFocus}
                autoFocus={props.isFocused}
                disabled={props.isSubmitted}
                value={fieldProps.field.value}
                onChange={fieldProps.field.onChange}
                onBlur={fieldProps.field.onBlur}
                hasError={hasError}
              />
              {!hasError && (
                <FormikFieldGroup.HelpText>Please use "." for decimals, e.g. 43, 19.99, 1000</FormikFieldGroup.HelpText>
              )}
              <FormikFieldGroup.Errors>
                <ErrorMessage name={name} />
              </FormikFieldGroup.Errors>
            </>
          );
        }}
      </Field>
    </FieldWrapper>
  );
};

const DropdownField: FC<FormFieldProps<DropdownResponse>> = props => {
  const name = `${props.field.index}.selected`;
  return (
    <Field
      name={name}
      required={props.field.required}
      validate={async (selected: DropdownResponse["selected"]) => {
        if (props.field.required && selected === null) return requiredMessage;
      }}
    >
      {(fieldProps: FieldProps) => {
        return (
          <FieldWrapper>
            {props.field.optionsList && (
              <SingleSelect
                showError={!!(fieldProps.form.errors[props.field.index] && fieldProps.form.touched[props.field.index])}
                isDisabled={props.isSubmitted}
                value={
                  props.response.selected !== null
                    ? {
                        label: props.field.optionsList[props.response.selected],
                        value: props.field.optionsList[props.response.selected]
                      }
                    : undefined
                }
                options={
                  props.field.optionsList?.map(o => ({
                    label: o,
                    value: o
                  })) ?? []
                }
                required={props.field.required}
                onFocus={props.onFocus}
                key={String(props.isFocused)} // force component to re-render as autoFocus works only when a component is mounted
                openMenuOnFocus={props.isFocused}
                autoFocus={props.isFocused}
                onChange={(selected: ValueType<{ value: string }>) => {
                  if (selected && "value" in selected) {
                    fieldProps.form.setFieldValue(
                      `${props.field.index}.selected`,
                      props.field.optionsList?.lastIndexOf(selected.value)
                    );
                    props.onComplete();
                  }
                }}
                css={[
                  !!(fieldProps.form.errors[props.field.index] && fieldProps.form.touched[props.field.index]) &&
                    css`
                      .select__control {
                        border-bottom: 1px solid var(--red-7);
                      }
                    `
                ]}
              />
            )}

            <FormikFieldGroup.Errors>
              <ErrorMessage name={name} />
            </FormikFieldGroup.Errors>
          </FieldWrapper>
        );
      }}
    </Field>
  );
};

const DateField: FC<FormFieldProps<TextfieldResponse>> = props => {
  const name = `${props.field.index}.value`;
  return (
    <Field
      required={props.field.required}
      name={name}
      validate={async (val: string | null) => {
        try {
          // only validate required (null value)
          if (props.field.required && !val) {
            await yup.string().trim().required(requiredMessage).validate("");
          }
        } catch (e) {
          if (e instanceof yup.ValidationError) {
            return (e as yup.ValidationError).message;
          }
        }
      }}
    >
      {(fieldProps: FieldProps) => (
        <div>
          <DatePicker
            placement="bottom-start"
            button={
              <FormButton type="button" margin="0" onFocus={props.onFocus} disabled={props.isSubmitted}>
                {!!fieldProps.field.value ? formatDateString(parseISO(fieldProps.field.value)) : "Pick a date"}
              </FormButton>
            }
            numberOfMonths={1}
            value={fieldProps.field.value ? parseISO(fieldProps.field.value) : null}
            onChange={day => {
              fieldProps.form.setFieldValue(name, day ? formatDateTimeString(day) : null);
              props.onComplete();
              fieldProps.form.setFieldTouched(name, true);
            }}
            allowPastDate={true}
          />
          <FormikFieldGroup.Errors>
            <ErrorMessage name={name} />
          </FormikFieldGroup.Errors>
        </div>
      )}
    </Field>
  );
};

const FileUploadField: FC<FormFieldProps<AttachmentFieldResponse>> = props => {
  const name = `${props.field.index}.attachment`;
  return (
    <Field
      required={props.field.required}
      name={name}
      validate={async (val: string | null) => {
        try {
          // only validate required (null value)
          if (props.field.required && !val) {
            await yup.string().trim().required(requiredMessage).validate("");
          }
        } catch (e) {
          if (e instanceof yup.ValidationError) {
            return (e as yup.ValidationError).message;
          }
        }
      }}
    >
      {(fieldProps: FieldProps) => (
        <>
          <FileUploadFieldChild
            response={props.response}
            isSubmitted={props.isSubmitted}
            onChange={attachment => {
              fieldProps.form.setFieldValue(name, attachment);
              fieldProps.form.setFieldTouched(name, true);
            }}
          />
          <FormikFieldGroup.Errors>
            <ErrorMessage name={name} />
          </FormikFieldGroup.Errors>
        </>
      )}
    </Field>
  );
};

/** Child component to access field props */
const FileUploadFieldChild: React.FC<{
  response: AttachmentFieldResponse;
  isSubmitted: boolean;
  onChange(attachment: AttachmentFieldResponse["attachment"] | null): void;
}> = ({ response, isSubmitted, onChange }) => {
  const authToken = getAuthToken();
  if (!authToken) {
    reportDevError("no auth token on form submission url");
  }

  const [filesState, filesDispatch] = useReducer(filesReducer, { files: [], preventMultiple: true });
  const fileInputElement = useFileInput(filesDispatch, true, authToken);

  useEffect(() => {
    const firstFile = filesState.files[0] as LocalFileInfo;
    // new file uploaded
    if (firstFile && firstFile.status === FileStatus.UPLOADED && firstFile.attachmentId) {
      // clear filesState
      filesDispatch({
        type: "CLEAR_FILES"
      });
      // add file as attachment response
      onChange({
        displayName: firstFile.name,
        size: firstFile.sizeBytes,
        id: firstFile.attachmentId
      });
    }
  }, [response, filesState, onChange]);

  // prevent multiple
  const shouldShowUploadButton = filesState.files.length === 0 && !response.attachment;

  // don't allow removing a submitted attachment
  const handleRemoveItem = isSubmitted ? undefined : () => onChange(null);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        & > * + * {
          margin-top: var(--space-4-rem) !important;
        }
      `}
    >
      {shouldShowUploadButton && (
        <div>
          <FormButton
            title={
              isSubmitted
                ? "You cannot upload an attachment after submission"
                : "Select an attachment from your computer"
            }
            disabled={isSubmitted}
            type="button"
            margin="0"
            onClick={() => fileInputElement.click()}
          >
            Upload file
          </FormButton>
        </div>
      )}
      <FileStack filesState={filesState} filesDispatch={filesDispatch} spaced />
      <ImageViewer imagesList={response.attachment ? createImageListFromAttachments([response.attachment], null) : []}>
        {dispatch =>
          response.attachment && (
            <AttachmentItem
              attachment={response.attachment}
              imageViewerDispatch={dispatch}
              handleRemove={handleRemoveItem}
              authToken={authToken}
            />
          )
        }
      </ImageViewer>
    </div>
  );
};
