import { css } from "@emotion/core";
import React from "react";
import { FilterButton, Hide, SimpleTooltip } from "src/components";
import { OptionsUnion, PopOver } from "src/components/PopOver";

export function FilterBy(props: {
  icon: React.ReactNode;
  unit: string;
  filterType: string;
  validValues: string[];
  selected: string[] | undefined;
  onSelect: (selected: string[] | null) => void;
  onClickAway: () => void;
  onRemove: () => void;
  showFilter: boolean | undefined;
  filterLabel: string;
  options: OptionsUnion;
  tooltip?: string;
  isOpen?: boolean;
}) {
  const count = props.validValues?.length ?? 0;
  const showFilter = props.showFilter || count > 0;

  return (
    <Hide when={!showFilter}>
      <PopOver.Menu
        isMulti
        isOpen={props.isOpen}
        trigger={
          <SimpleTooltip placement="top" label={props.tooltip ? props.tooltip : `Filtered by ${props.filterType}`}>
            <FilterButton
              css={[
                css`
                  margin-left: var(--space-1-rem);
                `
              ]}
              data-filtertype={props.filterType}
              icon={props.icon}
              label={count > 1 ? `${count} ${props.unit}` : count === 1 ? props.filterLabel : undefined}
            />
          </SimpleTooltip>
        }
        options={props.options}
        selected={props.selected}
        onSelect={props.onSelect}
        onClickAway={props.onClickAway}
        labelForRemoveOption="Clear all"
        onRemove={props.onRemove}
      />
    </Hide>
  );
}
