import { css, SerializedStyles } from "@emotion/core";
import { mdiDotsHorizontal } from "@mdi/js";
import { Icon as MdIcon } from "@mdi/react";
import React from "react";
import { ReactComponent as MdiTimer } from "src/assets/material/av_timer-24px.svg";
import { ReactComponent as MdiGroup } from "src/assets/material/group-24px.svg";
import { ReactComponent as MdiLabel } from "src/assets/material/label_important-24px.svg";
import { ReactComponent as MdiStyle } from "src/assets/material/style-24px.svg";
import { ReactComponent as PrioIcon } from "src/assets/priority.svg";
import { RequestPriority } from "src/globalTypes";
import { Typo } from "src/styling/primitives/typography";
import { csx } from "src/util/csx";
import { formatDueDate } from "src/util/formatters";
import { ConditionalTooltip } from "./ConditionalTooltip";

const pillButtonBase = css`
  height: 2rem;
  display: flex;
  max-width: 11.25rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  line-height: 1;
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-body-regular);
  color: var(--text-6);

  outline: none;
  border-radius: var(--border-radius-round);
  border-width: 0;
  padding: var(--space-1-rem) var(--space-3-rem);
  cursor: pointer;
  background-color: var(--lightGrey-1);

  p {
    color: var(--text-6);
    line-height: 1;
    padding: var(--space-1-rem) 0;
  }

  & > * + * {
    margin-left: var(--space-1-rem);
  }

  &:not([disabled]):hover {
    background-color: var(--lightGrey-2);
  }

  &:not([disabled]):active {
    background-color: var(--lightGrey-3);
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }
  & svg {
    width: 18px;
    height: 18px;
    & path,
    circle,
    rect {
      fill: currentColor;
    }
    & *[fill="none"],
    & *[fill="transparent"] {
      visibility: hidden;
    }
  }
  & .iconWrapper {
    display: inline-block;
    &:only-child {
      margin: 0 -5px;
    }
    & > * {
      display: flex;
      width: 18px;
      height: 18px;
      justify-content: center;
      align-items: center;
    }
  }
`;

const pillButtonVariants = {
  condensed: css`
    height: 1.5rem;
    padding: 0 0.25rem;
    font-size: var(--font-size-helper);
    & > * + * {
      margin-right: var(--space-1-rem);
    }

    & .iconWrapper {
      &:only-child {
        margin: 0;
      }
      & > * {
        width: 16px;
        height: 16px;
      }
      & svg {
        width: 16px;
        height: 16px;
      }
    }
  `,
  urgent: css`
    background: var(--flesh-2);
    &,
    & span,
    & p {
      color: var(--red-7);
    }

    &:not([disabled]):hover {
      background: var(--flesh-3);
    }

    &:not([disabled]):active {
      background: var(--flesh-4);
    }
  `
};
const priorityData: {
  [key in keyof typeof RequestPriority]: {
    label: string;
    style: SerializedStyles;
  };
} = {
  [RequestPriority.PRIORITY_UNSPECIFIED]: {
    label: "No priority",
    style: css`
      svg {
        & > rect {
          fill: var(--lightGrey-4);
        }
      }
    `
  },
  [RequestPriority.PRIORITY_LOW]: {
    label: "Low",
    style: css`
      svg {
        & > rect:not(:nth-child(1)) {
          fill: var(--lightGrey-4);
        }
        & rect:nth-child(1) {
          fill: currentColor;
        }
      }
    `
  },
  [RequestPriority.PRIORITY_MEDIUM]: {
    label: "Medium",
    style: css`
      svg {
        & > rect:not(:nth-child(3)) {
          fill: currentColor;
        }
        & rect:nth-child(3) {
          fill: var(--lightGrey-4);
        }
      }
    `
  },
  [RequestPriority.PRIORITY_HIGH]: {
    label: "High",
    style: css`
      svg {
        & > rect {
          fill: currentColor;
        }
      }
    `
  }
};

interface SharedPillProps {
  showText?: boolean;
  condensed?: boolean;
  disabled?: boolean;
  tooltipText?: string;
  "data-intercom-target"?: string;
  "data-testid"?: string;
}

export const Pill = {
  _Button: csx([pillButtonBase], pillButtonVariants, "button"),
  _Icon: ({ iconPath, iconComp }: { iconPath?: string; iconComp?: React.ReactNode }) => {
    if (!iconPath && !iconComp) {
      // eslint-disable-next-line no-console
      console.info("PillIcon: Neither an mdi icon path, nor an icon component was passed.");
      return null;
    }
    // classname is needed to change css on parents hover
    return (
      <span className="iconWrapper">
        <div>
          {iconPath && (
            <svg viewBox="0 0 24 24">
              <MdIcon path={iconPath} inStack={true} />
            </svg>
          )}
          {iconComp && iconComp}
        </div>
      </span>
    );
  },
  ContextMenu: React.forwardRef((props: { condensed?: boolean }, ref: React.Ref<HTMLButtonElement>) => (
    <Pill._Button condensed={props.condensed} ref={ref}>
      <Pill._Icon iconPath={mdiDotsHorizontal} />
    </Pill._Button>
  )),
  Priority: React.forwardRef(
    (
      props: SharedPillProps & {
        priority: RequestPriority;
      },
      ref: React.Ref<HTMLButtonElement>
    ) => {
      const { priority, showText = true, condensed, disabled, tooltipText } = props;
      return (
        <ConditionalTooltip tooltipText={tooltipText}>
          <Pill._Button
            css={priorityData[priority].style}
            condensed={condensed}
            disabled={disabled}
            ref={ref}
            data-intercom-target={props["data-intercom-target"]}
            data-testid={props["data-testid"]}
          >
            <Pill._Icon iconComp={<PrioIcon />} />
            {showText && (
              <Typo.Body sizeS={condensed} ellipsis>
                {priorityData[priority].label}
              </Typo.Body>
            )}
          </Pill._Button>
        </ConditionalTooltip>
      );
    }
  ),
  Team: React.forwardRef(
    (
      props: SharedPillProps & {
        teamName: string;
      },
      ref: React.Ref<HTMLButtonElement>
    ) => {
      const { teamName, showText = true, condensed, disabled, tooltipText } = props;
      return (
        <ConditionalTooltip tooltipText={tooltipText}>
          <Pill._Button
            condensed={condensed}
            disabled={disabled}
            ref={ref}
            data-intercom-target={props["data-intercom-target"]}
            data-testid={props["data-testid"]}
          >
            <Pill._Icon iconComp={<MdiGroup />} />
            {showText && (
              <Typo.Body sizeS={condensed} ellipsis>
                {teamName}
              </Typo.Body>
            )}
          </Pill._Button>
        </ConditionalTooltip>
      );
    }
  ),
  Project: React.forwardRef(
    (
      props: SharedPillProps & {
        projectName?: string;
      },
      ref: React.Ref<HTMLButtonElement>
    ) => {
      const { projectName, showText = true, condensed, disabled, tooltipText } = props;
      return (
        <ConditionalTooltip tooltipText={tooltipText}>
          <Pill._Button
            condensed={condensed}
            disabled={disabled}
            ref={ref}
            data-intercom-target={props["data-intercom-target"]}
            data-testid={props["data-testid"]}
          >
            <Pill._Icon iconComp={<MdiLabel />} />
            {showText && (
              <Typo.Body sizeS={condensed} ellipsis>
                {projectName || "No project"}
              </Typo.Body>
            )}
          </Pill._Button>
        </ConditionalTooltip>
      );
    }
  ),
  Category: React.forwardRef(
    (
      props: SharedPillProps & {
        categoryName?: string;
      },
      ref: React.Ref<HTMLButtonElement>
    ) => {
      const { categoryName, showText = true, condensed, disabled, tooltipText } = props;
      return (
        <ConditionalTooltip tooltipText={tooltipText}>
          <Pill._Button
            condensed={condensed}
            disabled={disabled}
            ref={ref}
            data-intercom-target={props["data-intercom-target"]}
            data-testid={props["data-testid"]}
          >
            <Pill._Icon iconComp={<MdiStyle />} />
            {showText && (
              <Typo.Body sizeS={condensed} ellipsis>
                {categoryName || "No category"}
              </Typo.Body>
            )}
          </Pill._Button>
        </ConditionalTooltip>
      );
    }
  ),
  DueDate: React.forwardRef(
    (
      props: SharedPillProps & {
        dueDate?: ScalarDateTime | null;
        isUrgent?: boolean;
      },
      ref: React.Ref<HTMLButtonElement>
    ) => {
      const { dueDate, isUrgent = false, showText = true, condensed, disabled, tooltipText } = props;
      if (isUrgent && !dueDate) {
        // eslint-disable-next-line no-console
        console.log("DueDate Pill: A pill without a due date cannot be urgent.");
        return null;
      }
      return (
        <ConditionalTooltip tooltipText={tooltipText}>
          <Pill._Button
            condensed={condensed}
            disabled={disabled}
            ref={ref}
            data-intercom-target={props["data-intercom-target"]}
            data-testid={props["data-testid"]}
            urgent={isUrgent}
          >
            <Pill._Icon iconComp={<MdiTimer />} />
            {showText && (
              <Typo.Body sizeS={condensed} ellipsis>
                {dueDate ? `Due ${formatDueDate(dueDate)}` : "No due date"}
              </Typo.Body>
            )}
          </Pill._Button>
        </ConditionalTooltip>
      );
    }
  )
};
