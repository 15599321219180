import { css } from "@emotion/core";
import styled from "@emotion/styled";
import * as Sentry from "@sentry/browser";
import { DraftEditorCommand, EditorState, getDefaultKeyBinding, KeyBindingUtil, Modifier } from "draft-js";
import "draft-js-mention-plugin/lib/plugin.css";
import Editor from "draft-js-plugins-editor";
import "draft-js/dist/Draft.css";
import "emoji-mart/css/emoji-mart.css";
import React, { useEffect } from "react";
import { FormAction, FormState } from "src/App/Forms/Manager/reducer";
import { DocumentAction, DocumentState } from "src/App/KB/Detail/reducer";
import { SavedReplyAction, SavedReplyState } from "src/App/KB/Detail/savedRepliesReducer";
import { ApprovalAction, ApprovalState } from "src/App/Requests/DetailView/InputBar/Approvals";
import { EmojiSuggestions } from "src/App/Requests/DetailView/InputBar/Emojis";
import { Externals } from "src/App/Requests/DetailView/InputBar/Externals";
import {
  ApprovalBanner,
  ContextForExternalsToggle,
  DocumentBanner,
  FormBanner,
  IsRequesterBanner
} from "src/App/Requests/DetailView/InputBar/InputBanner";
import { Mentions, useMentionTriggerPlugin } from "src/App/Requests/DetailView/InputBar/Mentions";
import { IInputState, TInputAction } from "src/App/Requests/DetailView/InputBar/reducer";
import { RequestGet_request } from "src/App/Requests/DetailView/typings/RequestGet";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { paddings } from "src/styling/layout";

const EditorWrapper = styled.div`
  flex: 1 1 auto;
  margin: 0 0 0.75rem 0;
  width: 100%;

  & .DraftEditor-root {
    flex: 1 0 auto;
    box-sizing: border-box;
    width: 100%;
    max-height: 11rem;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0.25rem 0.625rem 0 0.625rem;

    & .DraftEditor-editorContainer {
      height: auto;
    }

    & .public-DraftEditorPlaceholder-root {
      color: var(--text-1);
    }
  }

  & .draftJsMentionPlugin__mention__29BEd {
    border-radius: var(--border-radius-l);
    cursor: auto;
    padding: ${paddings.mention.combined};
  }

  & .draftJsMentionPlugin__mention__29BEd,
  & .draftJsMentionPlugin__mention__29BEd:hover {
    background: var(--lightGrey-2);
    color: inherit;
  }
`;

type EditorPlugins = "mentions" | "externals" | "emojis";

const SUBMIT_INPUT_CMD = "submit-input-action";
type EditorCmds = typeof SUBMIT_INPUT_CMD | DraftEditorCommand;

/**
 * Wrapper component for draft-js instance
 * Handles triggers for mentions, pasting files etc
 */
export function DraftJSComponent(props: {
  request: RequestGet_request;
  "data-intercom-target": string;
  placeholder?: string;
  initialEditorState?: EditorState;
  appendTextFnRef: React.MutableRefObject<((text: string) => void) | undefined>;
  onChange(value: EditorState): void;
  onFocus?(e: React.SyntheticEvent): void;
  onFilesAdded(files: File[]): void;
  handleSubmit(): void;
  disablePlugins: { [k in EditorPlugins]?: boolean };
  editorRef: React.RefObject<HTMLTextAreaElement>;
  inputState: IInputState;
  inputDispatch: React.Dispatch<TInputAction>;
  formState: FormState;
  formDispatch: React.Dispatch<FormAction>;
  documentState: DocumentState;
  documentDispatch: React.Dispatch<DocumentAction>;
  approvalState: ApprovalState;
  approvalDispatch: React.Dispatch<ApprovalAction>;
  savedReplyState: SavedReplyState;
  savedReplyDispatch: React.Dispatch<SavedReplyAction>;
}) {
  const [editorState, setEditorState] = React.useState(props.initialEditorState || EditorState.createEmpty());

  const mentionPlugin = useMentionTriggerPlugin({
    mentionTrigger: "@",
    mentionPrefix: "@",
    entityMutability: "SEGMENTED"
  });

  const externalsPlugin = useMentionTriggerPlugin({
    mentionTrigger: "+",
    mentionPrefix: "+",
    suggestionRegex: "[\\S]*",
    entityMutability: "SEGMENTED"
  });

  const emojiPlugin = useMentionTriggerPlugin({
    mentionTrigger: ":",
    mentionPrefix: "",
    mentionClassName: "emoji-mention"
  });

  const { currentUser } = useCurrentUser();

  useEffect(() => {
    props.appendTextFnRef.current = (text: string) => {
      try {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const ncs = Modifier.insertText(contentState, selection, text);
        setEditorState(EditorState.push(editorState, ncs, "insert-fragment"));
        setTimeout(() => {
          if (props.editorRef.current) {
            props.editorRef.current.focus();
          }
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Error appending text", e);
        Sentry.captureException(e);
      }
    };
  }, [props.editorRef, props.appendTextFnRef, editorState]);

  const { savedReplyState, savedReplyDispatch, appendTextFnRef } = props;
  useEffect(() => {
    if (savedReplyState && savedReplyState.savedReply) {
      // append saved reply body to the current message
      if (appendTextFnRef.current) {
        appendTextFnRef.current(`${savedReplyState.savedReply.body}\n`);
      }
      // clear document from document state
      savedReplyDispatch({
        type: "CLEAR_SAVED_REPLY"
      });
    }
  }, [savedReplyState, savedReplyDispatch, appendTextFnRef]);

  return (
    <>
      <EditorWrapper data-intercom-target={props["data-intercom-target"]}>
        {/* wrap all banners and render margin if more than 1 */}
        <div
          css={css`
            & > * + * {
              margin-top: var(--space-2-rem);
            }
            & > *:last-child {
              margin-bottom: var(--space-3-rem);
            }
          `}
        >
          {props.inputState.inputType === "COMMENTS" && currentUser?.id === props.request.requester.id && (
            <IsRequesterBanner />
          )}
          {props.inputState.inputType === "FORMS" && (
            <FormBanner
              inputState={props.inputState}
              inputDispatch={props.inputDispatch}
              formState={props.formState}
              formDispatch={props.formDispatch}
            />
          )}
          {props.inputState.inputType === "APPROVALS" && (
            <ApprovalBanner approvalState={props.approvalState} approvalDispatch={props.approvalDispatch} />
          )}
          {!!props.inputState.externals.length && (
            <ContextForExternalsToggle inputState={props.inputState} inputDispatch={props.inputDispatch} />
          )}
          {!!props.documentState.document && (
            <DocumentBanner documentState={props.documentState} documentDispatch={props.documentDispatch} />
          )}
        </div>
        <Editor
          ref={props.editorRef}
          editorState={editorState}
          placeholder={props.placeholder}
          onFocus={props.onFocus}
          onChange={(value: EditorState) => {
            setEditorState(value);
            props.onChange(value);
          }}
          keyBindingFn={(e: React.KeyboardEvent) => {
            if (e.key === "Enter" && KeyBindingUtil.hasCommandModifier(e)) {
              return SUBMIT_INPUT_CMD;
            }
            return getDefaultKeyBinding(e);
          }}
          handleKeyCommand={(command: EditorCmds) => {
            if (command === SUBMIT_INPUT_CMD) {
              props.handleSubmit();
              return "handled";
            }
            // commands follow default behavior
            return "not-handled";
          }}
          plugins={[mentionPlugin, externalsPlugin, emojiPlugin]}
          handlePastedFiles={(files: File[]) => {
            props.onFilesAdded(files);
            return "handled";
          }}
          spellCheck={true}
        />
      </EditorWrapper>
      {!props.disablePlugins.mentions && (
        <Mentions
          mentionPlugin={mentionPlugin}
          request={props.request}
          editorState={editorState}
          setEditorState={setEditorState}
        />
      )}
      {!props.disablePlugins.externals && (
        <Externals
          mentionPlugin={externalsPlugin}
          request={props.request}
          editorState={editorState}
          setEditorState={setEditorState}
          teamId={props.request.team.id}
        />
      )}
      {!props.disablePlugins.emojis && (
        <EmojiSuggestions plugin={emojiPlugin} editorState={editorState} setEditorState={setEditorState} />
      )}
    </>
  );
}
