import { css } from "@emotion/core";
import { mdiChevronLeft, mdiPlus } from "@mdi/js";
import gql from "graphql-tag";
import * as React from "react";
import { useRef } from "react";
import { useQuery } from "react-apollo";
import { RequestCreateModal } from "src/App/Requests/CreateView/RequestCreateDialog";
import { SortBy } from "src/App/Requests/ListView/Filters/FilterBar";
import { deserializeUrlParams } from "src/App/Requests/ListView/Filters/urlParamHelpers";
import { RequestListProvider, useRequestList } from "src/App/Requests/ListView/Provider";
import { RequestListUpdates } from "src/App/Requests/ListView/RequestListUpdates";
import { DateTime, EmptyState, MaterialIcon, SquareButton } from "src/components";
import { OrderByKey, RequestListView, RequestStatus } from "src/globalTypes";
import { align, body, col, flex, fontSize, justify, row } from "src/styling/primitives";
import { Typo } from "src/styling/primitives/typography";
import { LocationContext, navigate, RouteComponentProps } from "src/util/router";
import { CompletedPie } from "./Card";
import { PROJECT_FRAGMENT } from "./ProjectFragment";
import { SelectProjectStatus } from "./Status";
import { ProjectDetail, ProjectDetailVariables } from "./typings/ProjectDetail";

export const PROJECT_DETAIL = gql`
  query ProjectDetail($id: ID!) {
    request(id: $id) {
      ...ProjectFragment
    }
  }
  ${PROJECT_FRAGMENT}
`;

const listDimensions = css`
  flex: 1 1 auto;
  min-width: 40rem;
  max-width: 68rem;
  margin: 1.5rem 0;
`;

const HorizontalShadow = () => (
  <div
    css={css`
      height: 1rem;
      margin-bottom: -1rem;
      overflow: hidden;
    `}
  >
    <div
      css={css`
        height: 1rem;
        margin-top: -1rem;
        background: var(--white);
        border-radius: 1.5rem;
        box-shadow: -0.125rem 0.125rem 0.875rem rgba(var(--text-5-raw), 0.06),
          0 -0.125rem 0.125rem rgba(var(--text-5-raw), 0.08), 0 0.0625rem 0.0625rem rgba(var(--text-5-raw), 0.02);
      `}
    />
  </div>
);

export const ProjectsDetailPage: React.FC<
  RouteComponentProps<{ project_id: string }> & {
    context: LocationContext;
  }
> = props => {
  if (!props.project_id) {
    throw new Error("Missing project id");
  }

  const params = deserializeUrlParams(props.context.location, {
    filters: "",
    orderBy: OrderByKey.updateTime
  });

  return (
    <RequestListProvider
      viewId={props.project_id}
      listViewType={RequestListView.REQUESTS}
      shouldPaginate={false}
      filtersFromView={{ topic: [props.project_id] }}
      filtersFromUrl={{}}
      orderByKey={params.orderByKey}
      pageNumber={0}
    >
      <ProjectsDetailContainer project_id={props.project_id} />
    </RequestListProvider>
  );
};

const ProjectsDetailContainer: React.FC<{ project_id: string }> = props => {
  const [requestList] = useRequestList();

  const projectDetails = {
    query: PROJECT_DETAIL,
    options: {
      variables: {
        id: props.project_id ?? ""
      }
    }
  };
  const projectDetailResponse = useQuery<ProjectDetail, ProjectDetailVariables>(
    projectDetails.query,
    projectDetails.options
  );

  const queriesToRefetch = [
    ...requestList.queriesToRefetch,
    {
      query: projectDetails.query,
      variables: projectDetails.options.variables
    }
  ];

  const totalRequests =
    projectDetailResponse.data?.request.projectRequestCounts.reduce((acc, { count }) => acc + count, 0) ?? 0;
  const completedRequests =
    projectDetailResponse.data?.request.projectRequestCounts
      .filter(({ status }) => status === RequestStatus.RESOLVED)
      .reduce((acc, { count }) => acc + count, 0) ?? 0;
  const percentCompleted = totalRequests === 0 ? 0 : completedRequests / totalRequests;

  const [showCreateRequestModal, setShowCreateRequestModal] = React.useState(false);

  const listDimensionsRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      {showCreateRequestModal && (
        <RequestCreateModal
          onCancel={() => setShowCreateRequestModal(false)}
          onCreate={() => setShowCreateRequestModal(false)}
          projectInfo={projectDetailResponse.data?.request}
          queriesToRefetch={queriesToRefetch}
        />
      )}
      <div
        css={[
          col,
          css`
            height: 100%;
            width: 100%;
          `
        ]}
      >
        <div
          css={[
            row,
            justify.center,
            flex.grow0,
            css`
              margin: 0 2.5rem;
            `
          ]}
        >
          {
            /* TODO: join queries after refactoring request cards? */
            projectDetailResponse.data && (
              <div css={[col, listDimensions]}>
                <div css={[row]}>
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      margin-bottom: var(--space-4-rem);

                      & > * + * {
                        margin-top: var(--space-2-rem);
                      }
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                      `}
                    >
                      <SquareButton onClick={() => navigate("/projects")} size="small" variant="secondary">
                        <MaterialIcon path={mdiChevronLeft} size={1} />
                      </SquareButton>
                      <div
                        css={[
                          body.regular,
                          fontSize.S,
                          css`
                            margin-left: 0.75rem;
                          `
                        ]}
                      >
                        {projectDetailResponse.data.request.author?.name} created{" "}
                        <DateTime tooltip timestamp={projectDetailResponse.data.request.requestedAt} /> for{" "}
                        {projectDetailResponse.data?.request.team.name}
                      </div>
                    </div>
                    <Typo.Body sizeXXL>{projectDetailResponse.data?.request.title}</Typo.Body>
                  </div>
                  <div css={[row, align.center, justify.end]}>
                    {props.project_id && (
                      <SelectProjectStatus
                        projectId={props.project_id}
                        status={projectDetailResponse.data.request.status}
                        css={css`
                          margin-right: 0.5rem;
                        `}
                        queriesToRefetch={queriesToRefetch}
                      />
                    )}
                    <SquareButton variant="secondary" onClick={() => setShowCreateRequestModal(true)}>
                      <MaterialIcon path={mdiPlus} size={1.125} />
                    </SquareButton>
                  </div>
                </div>
                <HorizontalShadow />
                {totalRequests > 0 && (
                  <div
                    css={[
                      row,
                      flex.grow0,
                      body.regular,
                      fontSize.S,
                      css`
                        margin-top: 2rem;
                      `
                    ]}
                  >
                    <div css={[flex.grow1]}>Showing {totalRequests} requests</div>
                    <div
                      css={[
                        row,
                        flex.grow0,
                        align.center,
                        css`
                          & > * + * {
                            margin-left: var(--space-3-rem);
                          }
                        `
                      ]}
                    >
                      <div css={[row, flex.grow0, align.center]}>
                        <CompletedPie
                          percent={percentCompleted}
                          css={css`
                            width: 1.125rem;
                            height: 1.125rem;
                            margin-right: 0.5rem;
                          `}
                        />
                        <span>
                          {completedRequests}/{totalRequests} Resolved
                        </span>
                      </div>
                      <SortBy />
                    </div>
                  </div>
                )}
              </div>
            )
          }
        </div>
        <div
          css={[
            css`
              flex: 1 0 auto;
              display: flex;

              /*
              this is to give the request list container a height so that it can scroll
              and that itself is necessary since our top level #content has overflow-y: hidden;
              on top of that, this is 121px since the list view wrapper below this has a height
              of 100% - 80px to accommodate the filter bar and here, the total height above the
              container that contains the list of requests in the project is 201px. So to give this
              container the correct height, we remove 80px from the overall 201px and use the remaining
              here. It's messy and needs to go but this is the artefact of legacy layout.
              TODO: Fix this once layout is sorted.
              */
              height: calc(100% - (${201 - requestList.filterBarHeight}px));

              & .filter-requests-bar {
                display: none;
                height: 0;
              }
            `
          ]}
          ref={listDimensionsRef}
        >
          {
            /* wait data to calc virtual list height */ projectDetailResponse.data && (
              <RequestListUpdates
                listDimensionsRef={listDimensionsRef}
                queriesToRefetch={queriesToRefetch}
                emptyState={
                  <EmptyState
                    title="No requests to show"
                    subtitle="Create requests or add existing ones to this project to get started. Requests that are associated with a project still show up in their assigned teams’ list of requests."
                  />
                }
              />
            )
          }
        </div>
      </div>
    </>
  );
};
