import gql from "graphql-tag";

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    name
    email
    joinTime
  }
`;

export const USER_GET = gql`
  query GUserGet($id: ID!) {
    user(id: $id) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export type UserComponentFields = {
  id: string;
  name: string;
  email: string;
  joinTime?: ScalarDateTime | null;
};

export * from "./UserAvatar";
export * from "./UserName";
