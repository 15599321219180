import { css } from "@emotion/core";
import { mdiDotsHorizontal, mdiInformation, mdiPlus } from "@mdi/js";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import gql from "graphql-tag";
import { pick } from "lodash";
import React from "react";
import { useMutation, useQuery } from "react-apollo";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import {
  BaseLayout,
  Button,
  CancelButton,
  ConfirmDialogModal,
  Dialog,
  DisableButton,
  EmptyState,
  FormikFieldGroup,
  InfoBanner,
  Input,
  LoadingBar,
  MaterialIcon,
  Row,
  SquareButton,
  SubmitButton
} from "src/components";
import { FormikMultiSelect } from "src/components/Fields/MultiSelect";
import { SingleSelect } from "src/components/Fields/Select";
import { PopOver } from "src/components/PopOver";
import { Table } from "src/components/Table";
import { Tooltip } from "src/components/Tooltip";
import { InSLA } from "src/globalTypes";
import { Modal } from "src/portals/Modal";
import { Toast } from "src/portals/Toast";
import { Typo } from "src/styling/primitives/typography";
import { trackSLACreated, trackSLADeleted, trackSLAUpdated } from "src/util/analytics";
import { csx } from "src/util/csx";
import { navigate } from "src/util/router";
import { CreateSLA, CreateSLAVariables } from "./typings/CreateSLA";
import { DeleteSLA, DeleteSLAVariables } from "./typings/DeleteSLA";
import {
  GSettingsSLASGetAll,
  GSettingsSLASGetAll_currentUser,
  GSettingsSLASGetAll_teamList,
  GSettingsSLASGetAll_teamList_SLAs,
  GSettingsSLASGetAll_teamList_workingHours
} from "./typings/GSettingsSLASGetAll";
import { UpdateSLA, UpdateSLAVariables } from "./typings/UpdateSLA";

const SETTINGS_SLAS_GET_ALL = gql`
  query GSettingsSLASGetAll {
    teamList {
      id
      name
      slug
      userIds
      categories {
        id
        name
      }
      workingHours {
        startHour
        startMinute
        endHour
        endMinute
      }
      timezone
      SLAs {
        id
        name
        minutesToFirstResponse
        minutesToResolution
        createTime
        categories {
          id
          name
        }
      }
    }
    currentUser {
      id
    }
  }
`;

type SLAAction = "create" | "update" | "delete";

export const SLAsOverview: React.FC = () => {
  const allSLAs = useQuery<GSettingsSLASGetAll>(SETTINGS_SLAS_GET_ALL, {
    fetchPolicy: "network-only"
  });

  const [isSLAModalShown, setIsSLAModalShown] = React.useState<boolean>(false);
  const [slaModalCurrentMode, setSLAModalMode] = React.useState<SLAAction>("create");
  const [currentTeamId, setCurrentTeamId] = React.useState<GSettingsSLASGetAll_teamList["id"] | undefined>();
  const [currentSLAId, setCurrentSLAId] = React.useState<GSettingsSLASGetAll_teamList_SLAs["id"] | undefined>();

  const openSLAModal = ({
    mode,
    currentTeamId,
    currentSLAId
  }: {
    mode: SLAAction;
    currentTeamId?: GSettingsSLASGetAll_teamList["id"];
    currentSLAId?: GSettingsSLASGetAll_teamList_SLAs["id"];
  }): void => {
    setSLAModalMode(mode);
    setCurrentTeamId(currentTeamId);
    setCurrentSLAId(currentSLAId);
    setIsSLAModalShown(true);
  };
  const onSLAModalClose = () => {
    setIsSLAModalShown(false);
    setSLAModalMode("create");
  };

  if (allSLAs.loading) {
    return <LoadingBar />;
  }

  if (allSLAs.error) {
    return <Toast message={allSLAs.error.message} kind="error" />;
  }

  if (allSLAs.data && allSLAs.data.teamList && allSLAs.data.currentUser) {
    const teamList = allSLAs.data.teamList;
    const currentUser = allSLAs.data.currentUser;
    const slaList = teamList.flatMap(team => {
      return team.SLAs.map(sla => ({
        team,
        sla
      }));
    });
    const hasSLAs = !!slaList.length;

    return (
      <>
        <BaseLayout
          headline={"SLAs"}
          subline={
            !hasSLAs ? null : (
              <>
                There {slaList.length === 1 ? "is" : "are"}&nbsp;
                <b>{slaList.length === 1 ? " 1 SLA rule" : slaList.length + " SLA rules"}</b>&nbsp;set up for your
                organization.
              </>
            )
          }
          button={
            hasSLAs && (
              <Button variant="secondary" onClick={() => openSLAModal({ mode: "create" })}>
                <MaterialIcon path={mdiPlus} size={1.125} margin="0 0.25rem 0 0" />
                Add SLA rule
              </Button>
            )
          }
        >
          {hasSLAs && (
            <>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;

                  & > * + * {
                    margin-top: var(--space-6-px);
                  }
                `}
              >
                <SLATable slaList={slaList} currentUser={currentUser} openSLAModal={openSLAModal} />
                <InfoBanner title="Explanation of how working hours impact SLAs">
                  <MaterialIcon
                    path={mdiInformation}
                    size={1.125}
                    css={css`
                      margin-right: 0.5rem;
                      vertical-align: text-bottom;
                      display: inline-block;
                      svg {
                        margin-left: -0.25rem;
                      }
                    `}
                  />
                  <Typo.Body>
                    Time counts down only during working hours, which you can{" "}
                    <Typo.TextLink onClick={() => navigate("/settings/teams")}>customize for your teams</Typo.TextLink>.
                    Find more detailed information about SLAs in our{" "}
                    <Typo.ExternalLink href="https://support.back.ee/en/" target="_blank" rel="noreferrer noopener">
                      help center
                    </Typo.ExternalLink>
                    .
                  </Typo.Body>
                </InfoBanner>
              </div>
            </>
          )}

          {!hasSLAs && (
            <EmptyState title="No SLA rules found for the teams you belong to." variant="info">
              <SubmitButton onClick={() => openSLAModal({ mode: "create" })}>
                <MaterialIcon path={mdiPlus} size={1.125} margin="0 0.25rem 0 0" />
                Add SLA rule
              </SubmitButton>
            </EmptyState>
          )}
        </BaseLayout>

        <SLAModal
          isShown={isSLAModalShown}
          onClose={onSLAModalClose}
          mode={slaModalCurrentMode}
          setCurrentTeamId={setCurrentTeamId}
          teamList={teamList.filter(team => team.userIds.includes(currentUser.id))}
          slaList={slaList}
          currentTeamId={currentTeamId}
          currentSLAId={currentSLAId}
        />
      </>
    );
  }

  return null;
};

const SLATable: React.FC<{
  slaList: Array<{ team: GSettingsSLASGetAll_teamList; sla: GSettingsSLASGetAll_teamList_SLAs }>;
  currentUser: GSettingsSLASGetAll_currentUser;
  openSLAModal: (arg: {
    mode: SLAAction;
    currentTeamId?: GSettingsSLASGetAll_teamList["id"];
    currentSLAId?: GSettingsSLASGetAll_teamList_SLAs["id"];
  }) => void;
}> = ({ slaList, currentUser, openSLAModal }) => {
  return (
    <Table noCellEllipsis>
      <thead>
        <tr>
          <th>Team</th>
          <th>Categories</th>
          <th>Time to respond</th>
          <th>Time to resolve</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {slaList.map(({ team, sla }) => {
          const notAllowedToEdit = !team.userIds.includes(currentUser.id);

          return (
            <tr key={`${team.id}:${sla.id}`}>
              <td>{team.name}</td>
              <td>{sla.categories.length ? sla.categories.map(c => c.name).join(", ") : "All"}</td>
              <td>{formatSlaTime(sla.minutesToFirstResponse)}</td>
              <td>{formatSlaTime(sla.minutesToResolution)}</td>
              <td>
                <Row justify="flex-end">
                  <PopOver.Menu
                    options={[
                      {
                        id: "edit",
                        name: "Edit",
                        onClick: () => openSLAModal({ mode: "update", currentTeamId: team.id, currentSLAId: sla.id })
                      },
                      {
                        id: "delete",
                        name: "Delete",
                        onClick: () => openSLAModal({ mode: "delete", currentTeamId: team.id, currentSLAId: sla.id })
                      }
                    ]}
                    trigger={
                      <Tooltip
                        hideArrow
                        target={
                          <DisableButton disabled={notAllowedToEdit}>
                            <SquareButton size="small">
                              <MaterialIcon path={mdiDotsHorizontal} size={1.125} />
                            </SquareButton>
                          </DisableButton>
                        }
                      >
                        {notAllowedToEdit && " You can't edit this SLA rule because you're not a member of this team"}
                      </Tooltip>
                    }
                  />
                </Row>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const GoalInputContainer = csx([
  css`
    display: flex;
    align-items: center;
    margin: var(--space-2-rem) 0;

    & > * + input {
      margin-left: var(--space-4-rem);
    }

    & > input {
      max-width: var(--space-9-rem);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    & > span {
      padding: 0.6rem 0.3125rem;
      background: var(--lightGrey-1);
      border-top-right-radius: var(--border-radius-s);
      border-bottom-right-radius: var(--border-radius-s);
      border-top: 1px solid var(--lightGrey-4);
      border-bottom: 0.0625rem solid var(--lightGrey-4);
      border-right: 0.0625rem solid var(--lightGrey-4);
    }
  `
]);

// I am sorry for what you're about to witness below :(
export const SLAModal: React.FC<{
  isShown: boolean;
  onClose: () => void;
  mode: SLAAction;
  setCurrentTeamId: React.Dispatch<React.SetStateAction<string | undefined>>;
  teamList: GSettingsSLASGetAll["teamList"];
  slaList: Array<{ team: GSettingsSLASGetAll_teamList; sla: GSettingsSLASGetAll_teamList_SLAs }>;
  currentTeamId?: GSettingsSLASGetAll_teamList["id"];
  currentSLAId?: GSettingsSLASGetAll_teamList_SLAs["id"];
}> = ({
  isShown,
  onClose,
  mode,
  setCurrentTeamId,
  teamList,
  slaList,
  currentTeamId = teamList[0].id,
  currentSLAId /*, queriesToRefetch*/
}) => {
  const { emitSnack } = useSnack();

  const [createSLA, createSLAResponse] = useMutation<CreateSLA, CreateSLAVariables>(
    gql`
      mutation CreateSLA($sla: InSLA!) {
        slaCreate(sla: $sla) {
          code
          object {
            id
          }
          success
          message
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: SETTINGS_SLAS_GET_ALL
        }
      ]
    }
  );
  const [deleteSLA, deleteSLAResponse] = useMutation<DeleteSLA, DeleteSLAVariables>(
    gql`
      mutation DeleteSLA($slaId: ID!) {
        slaDelete(id: $slaId) {
          code
          object {
            id
          }
          success
          message
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: SETTINGS_SLAS_GET_ALL
        }
      ]
    }
  );
  const [updateSLA, updateSLAResponse] = useMutation<UpdateSLA, UpdateSLAVariables>(
    gql`
      mutation UpdateSLA($sla: InSLA!) {
        slaUpdate(sla: $sla) {
          code
          object {
            id
          }
          success
          message
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: SETTINGS_SLAS_GET_ALL
        }
      ]
    }
  );

  const teamListOptions = teamList.map(team => ({ label: team.name, value: team.id }));
  const currentTeam = teamList.find(team => team.id === currentTeamId);
  const categoryListOptions = (teamList.find(team => team.id === currentTeamId)?.categories ?? []).map(category => ({
    label: category.name,
    value: category.id
  }));
  const selectedSLA = slaList.find(({ sla }) => sla.id === currentSLAId)?.sla;
  const selectedCategoryIds = selectedSLA
    ? selectedSLA.categories.map(c => ({
        label: c.name,
        value: c.id
      }))
    : [];

  let title = "";

  if (mode === "create") {
    title = "Add SLA Rule";
  }

  if (mode === "update") {
    title = "Edit SLA Rule";
  }

  if (mode === "delete") {
    title = "Delete SLA Rule";
  }

  return (
    <>
      {updateSLAResponse.data?.slaUpdate.success === false && (
        <Toast kind="mutationError" message={updateSLAResponse.data?.slaUpdate.message} />
      )}
      {createSLAResponse.data?.slaCreate.success === false && (
        <Toast kind="mutationError" message={createSLAResponse.data?.slaCreate.message} />
      )}
      {deleteSLAResponse.data?.slaDelete.success === false && (
        <Toast kind="mutationError" message={deleteSLAResponse.data?.slaDelete.message} />
      )}
      <Modal isOpen={isShown && (mode === "update" || mode === "create")} onDismiss={onClose}>
        <Dialog loading={false} medium title={title} onClose={onClose}>
          <Formik
            validate={val => {
              const validTeamId = !!val.teamId;
              const validCategories =
                val.categories.choice === "all" ||
                (val.categories.choice === "custom" &&
                  (val.categories.selected ?? []).filter((v: object) => "label" in v && "value" in v).length !== 0);
              const atleastOneGoalPicked = val.timeToFirstResponse.checked || val.timeToResolution.checked;

              const hoursToResolution = Number.parseInt(val.timeToResolution.hours, 10);
              const minutesToResolution = Number.parseInt(val.timeToResolution.minutes, 10);
              const validTimeToResolution = val.timeToResolution.checked
                ? !Number.isNaN(hoursToResolution) &&
                  hoursToResolution >= 0 &&
                  !Number.isNaN(minutesToResolution) &&
                  minutesToResolution <= 59 &&
                  minutesToResolution >= 0 &&
                  hoursToResolution * 60 + minutesToResolution > 0
                : true;

              const hoursToFirstResponse = Number.parseInt(val.timeToFirstResponse.hours, 10);
              const minutesToFirstResponse = Number.parseInt(val.timeToFirstResponse.minutes, 10);
              const validTimeToFirstResponse = val.timeToFirstResponse.checked
                ? !Number.isNaN(hoursToFirstResponse) &&
                  hoursToFirstResponse >= 0 &&
                  !Number.isNaN(minutesToFirstResponse) &&
                  minutesToFirstResponse <= 59 &&
                  minutesToFirstResponse >= 0 &&
                  hoursToFirstResponse * 60 + minutesToFirstResponse > 0
                : true;

              if (
                validTeamId &&
                validCategories &&
                atleastOneGoalPicked &&
                validTimeToResolution &&
                validTimeToFirstResponse
              ) {
                return {};
              }

              return {
                ...(!validTeamId && { teamId: "Please select a team" }),
                ...(!validCategories && {
                  categories: "Please select either all or a custom set of categories"
                }),
                ...(!atleastOneGoalPicked && {
                  timeToFirstResponse: "Please select at least one goal for the SLA",
                  timeToResolution: "Please select at least one goal for the SLA"
                }),
                ...(!validTimeToResolution && { timeToResolution: "Please enter a valid time to resolve" }),
                ...(!validTimeToFirstResponse && { timeToFirstResponse: "Please enter a valid time to respond" })
              };
            }}
            onSubmit={formData => {
              const variables: {
                sla: InSLA;
              } = {
                sla: {
                  teamId: formData.teamId,
                  name: `SLA for ${formData.teamId}`,
                  ...(currentSLAId && { id: currentSLAId }),
                  ...(formData.categories?.choice === "custom" &&
                    formData.categories?.selected?.length && {
                      categoryIds: formData.categories.selected.map(c => c.value)
                    }),
                  ...(formData.timeToFirstResponse.checked && {
                    minutesToFirstResponse: getSLATime(formData.timeToFirstResponse)
                  }),
                  ...(formData.timeToResolution.checked && {
                    minutesToResolution: getSLATime(formData.timeToResolution)
                  })
                }
              };

              if (currentTeam) {
                if (mode === "update") {
                  return updateSLA({
                    variables,
                    update: (_cache, response) => {
                      if (response.data?.slaUpdate.success) {
                        trackSLAUpdated(
                          {
                            id: response.data.slaUpdate.object.id,
                            categories: variables.sla.categoryIds ?? [],
                            name: variables.sla.name,
                            // to narrow type from undefined | number | null to number | null
                            minutesToFirstResponse: variables.sla.minutesToFirstResponse ?? null,
                            minutesToResolution: variables.sla.minutesToResolution ?? null
                          },
                          currentTeam
                        );

                        emitSnack({
                          message: "SLA updated",
                          type: "info"
                        });
                      }
                      onClose();
                    }
                  });
                }

                if (mode === "create") {
                  return createSLA({
                    variables,
                    update: (_cache, response) => {
                      if (response.data?.slaCreate.success) {
                        trackSLACreated(
                          {
                            id: response.data.slaCreate.object.id,
                            categories: variables.sla.categoryIds ?? [],
                            name: variables.sla.name,
                            // to narrow type from undefined | number | null to number | null
                            minutesToFirstResponse: variables.sla.minutesToFirstResponse ?? null,
                            minutesToResolution: variables.sla.minutesToResolution ?? null
                          },
                          currentTeam
                        );

                        emitSnack({
                          message: "SLA created",
                          type: "info"
                        });
                      }
                      onClose();
                    }
                  });
                }
              }
            }}
            initialValues={{
              teamId: currentTeamId ? currentTeamId : teamListOptions[0].value,
              categories: {
                choice: selectedCategoryIds.length ? "custom" : "all",
                selected: selectedCategoryIds
              },
              timeToFirstResponse: {
                checked: !!selectedSLA?.minutesToFirstResponse,
                hours: selectedSLA?.minutesToFirstResponse
                  ? Math.trunc(selectedSLA.minutesToFirstResponse / 60)
                      .toString()
                      .padStart(2, "0")
                  : "",
                minutes: selectedSLA?.minutesToFirstResponse
                  ? (selectedSLA.minutesToFirstResponse % 60).toString().padStart(2, "0")
                  : ""
              },
              timeToResolution: {
                checked: !!selectedSLA?.minutesToResolution,
                hours: selectedSLA?.minutesToResolution
                  ? Math.trunc(selectedSLA.minutesToResolution / 60)
                      .toString()
                      .padStart(2, "0")
                  : "",
                minutes: selectedSLA?.minutesToResolution
                  ? (selectedSLA.minutesToResolution % 60).toString().padStart(2, "0")
                  : ""
              }
            }}
          >
            {form => {
              return (
                <Form
                  css={css`
                    & label > input {
                      min-height: 0; // to help FF figure out the correct baseline and height for the radio button/checkbox
                    }
                  `}
                >
                  <FormikFieldGroup.Container legend="Team">
                    <SingleSelect
                      name="teamId"
                      defaultValue={teamListOptions.find(team => team.value === form.values.teamId)}
                      options={teamListOptions}
                      onChange={e => {
                        if (e && "value" in e) {
                          form.setFieldValue("teamId", e.value);
                          form.setFieldValue("categories.selected", []);
                          setCurrentTeamId(e.value);
                        }
                      }}
                      isDisabled={false}
                      isSearchable={false}
                      showError={false}
                    />
                    <FormikFieldGroup.HelpText>You can only select teams you’re a member of</FormikFieldGroup.HelpText>
                    <FormikFieldGroup.Errors>
                      <ErrorMessage name="teamId" />
                    </FormikFieldGroup.Errors>
                  </FormikFieldGroup.Container>

                  <FormikFieldGroup.Container legend="Categories">
                    <div>
                      <Field name="categories.choice">
                        {(formikFieldProps: FieldProps) => (
                          <label>
                            <Input
                              {...formikFieldProps.field}
                              type="radio"
                              value="all"
                              checked={form.values.categories.choice === "all"}
                            />{" "}
                            Apply to all categories
                          </label>
                        )}
                      </Field>
                    </div>
                    <div
                      css={css`
                        margin-top: 0.5rem;
                      `}
                    >
                      <Field name="categories.choice">
                        {(formikFieldProps: FieldProps) => (
                          <label>
                            <Input
                              {...formikFieldProps.field}
                              type="radio"
                              value="custom"
                              checked={form.values.categories.choice === "custom"}
                            />{" "}
                            Select categories
                          </label>
                        )}
                      </Field>
                    </div>
                    {form.values.categories.choice === "custom" && (
                      <div
                        css={css`
                          margin-top: 0.5rem;
                        `}
                      >
                        <Field
                          name="categories.selected"
                          render={(fieldProps: FieldProps) => (
                            <FormikMultiSelect
                              {...fieldProps}
                              helperText="This overrides SLAs you may have set up for all categories"
                              options={categoryListOptions}
                              defaultValue={form.values?.categories?.selected || []}
                              isClearable={false}
                              isCreatable={false}
                              showError={false}
                            />
                          )}
                        />
                      </div>
                    )}
                    <FormikFieldGroup.Errors>
                      {form.values.categories.choice === "custom" && <ErrorMessage name="categories" />}
                    </FormikFieldGroup.Errors>
                  </FormikFieldGroup.Container>

                  <FormikFieldGroup.Container legend="Goals">
                    <div>
                      <Field name="timeToFirstResponse.checked">
                        {(formikFieldProps: FieldProps) => (
                          <label>
                            <Input
                              {...formikFieldProps.field}
                              type="checkbox"
                              checked={form.values.timeToFirstResponse.checked}
                            />{" "}
                            Time to respond
                          </label>
                        )}
                      </Field>
                    </div>
                    {form.values.timeToFirstResponse.checked && (
                      <GoalInputContainer>
                        <Field name="timeToFirstResponse.hours">
                          {(formikFieldProps: FieldProps) => (
                            <Input
                              {...formikFieldProps.field}
                              type="number"
                              min="0"
                              hasError={!!form.errors.timeToFirstResponse?.hours}
                            />
                          )}
                        </Field>
                        <span>h</span>
                        <Field name="timeToFirstResponse.minutes">
                          {(formikFieldProps: FieldProps) => (
                            <Input
                              {...formikFieldProps.field}
                              type="number"
                              min="0"
                              max="59"
                              hasError={!!form.errors.timeToFirstResponse?.minutes}
                            />
                          )}
                        </Field>
                        <span>m</span>
                      </GoalInputContainer>
                    )}
                    <div
                      css={css`
                        margin-top: 0.5rem;
                      `}
                    >
                      <Field name="timeToResolution.checked">
                        {(formikFieldProps: FieldProps) => (
                          <label>
                            <Input
                              {...formikFieldProps.field}
                              type="checkbox"
                              checked={form.values.timeToResolution.checked}
                            />{" "}
                            Time to resolve
                          </label>
                        )}
                      </Field>
                    </div>
                    {form.values.timeToResolution.checked && (
                      <GoalInputContainer>
                        <Field name="timeToResolution.hours">
                          {(formikFieldProps: FieldProps) => (
                            <Input
                              {...formikFieldProps.field}
                              type="number"
                              min="0"
                              hasError={!!form.errors.timeToResolution?.hours}
                            />
                          )}
                        </Field>
                        <span>h</span>
                        <Field name="timeToResolution.minutes">
                          {(formikFieldProps: FieldProps) => (
                            <Input
                              {...formikFieldProps.field}
                              type="number"
                              min="0"
                              max="59"
                              hasError={!!form.errors.timeToResolution?.minutes}
                            />
                          )}
                        </Field>
                        <span>m</span>
                      </GoalInputContainer>
                    )}
                    <FormikFieldGroup.Errors>
                      {form.values.timeToFirstResponse.checked && (
                        <div>
                          <ErrorMessage
                            render={() => <span>Enter a valid time to respond</span>}
                            name="timeToFirstResponse"
                          />
                        </div>
                      )}
                      {form.values.timeToResolution.checked && (
                        <div>
                          <ErrorMessage
                            render={() => <span>Enter a valid time to resolve</span>}
                            name="timeToResolution"
                          />
                        </div>
                      )}
                      {!form.values.timeToResolution.checked && !form.values.timeToFirstResponse.checked && (
                        <div>
                          <ErrorMessage render={() => <span>Please select a goal</span>} name="timeToFirstResponse" />
                        </div>
                      )}
                    </FormikFieldGroup.Errors>
                  </FormikFieldGroup.Container>
                  <InfoBanner title="Working hours for the selected team">
                    <MaterialIcon
                      path={mdiInformation}
                      size={1.125}
                      css={css`
                        margin-right: 0.5rem;
                        vertical-align: text-bottom;
                        display: inline-block;
                        svg {
                          margin-left: -0.25rem;
                        }
                      `}
                    />
                    {currentTeam && (
                      <Typo.Body>
                        Working hours for this team are {formatingWorkingHours(currentTeam.workingHours[0])} in the{" "}
                        {currentTeam.timezone} timezone.
                      </Typo.Body>
                    )}
                  </InfoBanner>
                  <Row justify="flex-end" margin="2rem 0 0 0">
                    <CancelButton onClick={onClose}>Cancel</CancelButton>
                    <SubmitButton disabled={!form.isValid || form.isSubmitting}>
                      {mode === "update" ? "Update" : "Add"} rule
                    </SubmitButton>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Dialog>
      </Modal>

      <ConfirmDialogModal
        isOpen={isShown && mode === "delete"}
        medium
        text={{
          cancel: "Cancel",
          heading: "Delete SLA Rule",
          confirm: "Delete rule"
        }}
        handleCancel={onClose}
        submittingConfirm={deleteSLAResponse.loading}
        handleConfirm={() => {
          return deleteSLA({
            variables: {
              slaId: currentSLAId ?? ""
            },
            update: (_cache, response) => {
              if (response.data?.slaDelete.success && selectedSLA && currentTeam) {
                trackSLADeleted(selectedSLA, currentTeam);

                emitSnack({
                  message: "SLA deleted",
                  type: "info"
                });
              }
              onClose();
            }
          });
        }}
      >
        <Typo.Body>Are you sure you want to delete this SLA rule?</Typo.Body>
      </ConfirmDialogModal>
    </>
  );
};

function formatingWorkingHours(workingHours: GSettingsSLASGetAll_teamList_workingHours): string {
  const [startHour, startMin, endHour, endMin] = Object.values(
    pick(workingHours, ["startHour", "startMinute", "endHour", "endMinute"])
  ).map(v => v.toString().padStart(2, "0"));

  return `${startHour}:${startMin} - ${endHour}:${endMin}`;
}

function getSLATime(arg: { checked: boolean; hours: string; minutes: string }): number | undefined {
  if (!arg.checked) {
    return;
  }

  const hours = Number.parseInt(arg.hours, 10);
  const minutes = Number.parseInt(arg.minutes, 10);

  if (!Number.isNaN(hours) && !Number.isNaN(minutes)) {
    return hours * 60 + minutes;
  }
}

export function formatSlaTime(timeInMin: number | null): string {
  if (typeof timeInMin !== "number") {
    return "-";
  }

  if (timeInMin < 60) {
    return `${timeInMin}min`;
  }

  const hours = Math.trunc(timeInMin / 60);
  const minutes = timeInMin % 60;

  if (minutes === 0) {
    return `${hours}${hours === 1 ? "hr" : "hrs"}`;
  }

  return `${hours}h ${minutes}m`;
}
