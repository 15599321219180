import { css } from "@emotion/core";
import React from "react";
import { HelpBubble } from "src/App/HelpBubble/HelpBubble";
import { Sidebar } from "src/App/Sidebar/Sidebar";
import { HotkeysHelp, useHotKeys } from "src/util/services/hotkeys";

export const SidebarWithHelpBubble: React.FC<{}> = () => {
  const [isHotKeysHelpShown, setIsHotKeysHelpShown] = React.useState<boolean>(false);

  useHotKeys({
    name: "Show keyboard shortcuts help",
    group: "help",
    // tinykeys doesn't work with just ? unfortunately
    // this binds help to ? across all keyboard layouts that
    // I have tested (DE, french canadian, french, dvorak and en-us)
    keys: "Shift+?",
    handler: () => setIsHotKeysHelpShown(v => !v)
  });

  return (
    <div
      css={css`
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        box-sizing: border-box;
        height: 100%;
        color: var(--text-6);
        background: var(--orange-1);
      `}
    >
      <Sidebar />
      <HelpBubble showHotKeysHelp={() => setIsHotKeysHelpShown(true)} />
      <HotkeysHelp isShown={isHotKeysHelpShown} onClose={() => setIsHotKeysHelpShown(false)} />
    </div>
  );
};
