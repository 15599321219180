import { css } from "@emotion/core";
import { csx } from "src/util/csx";

export const TableWrapper = csx([
  css`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 42rem;
    max-width: var(--dimension-general-maxWidth);
    font-size: 0.875rem;
  `
]);
