import { css } from "@emotion/core";
import { mdiCheckCircleOutline, mdiCircle, mdiPauseCircleOutline, mdiTimer, mdiInformation } from "@mdi/js";
import * as React from "react";
import { RequestGet_request_sla, RequestGet_request_slaStatus } from "src/App/Requests/DetailView/typings/RequestGet";
import { formatSlaTime } from "src/App/Settings/SLAs/Overview";
import { InfoBanner, MaterialIcon } from "src/components";
import { RequestStatus } from "src/globalTypes";
import { Typo } from "src/styling/primitives/typography";
import { slaIs, SLAType } from "./utils";

export const SLATabDotIcon: React.FC<{
  sla: {
    minutesToFirstResponse: RequestGet_request_sla["minutesToFirstResponse"];
    minutesToResolution: RequestGet_request_sla["minutesToResolution"];
  };
  slaStatus: RequestGet_request_slaStatus;
  slaTabSelected: boolean;
}> = props => {
  const { sla, slaStatus, slaTabSelected, ...rest } = props;
  let breachedAndIncomplete = false;
  let closeToBreach = false;

  if (sla.minutesToFirstResponse !== null && slaStatus.remainingTimeToFirstResponse !== null) {
    breachedAndIncomplete =
      breachedAndIncomplete ||
      slaIs.breachedAndIncomplete(
        slaStatus.remainingTimeToFirstResponse.remainingTimeMinutes,
        slaStatus.remainingTimeToFirstResponse.ticking
      );
    closeToBreach =
      closeToBreach ||
      slaIs.closeToBreach(
        slaStatus.remainingTimeToFirstResponse.remainingTimeMinutes,
        slaStatus.remainingTimeToFirstResponse.ticking,
        sla.minutesToFirstResponse
      );
  }

  if (sla.minutesToResolution !== null && slaStatus.remainingTimeToResolution !== null) {
    breachedAndIncomplete =
      breachedAndIncomplete ||
      slaIs.breachedAndIncomplete(
        slaStatus.remainingTimeToResolution.remainingTimeMinutes,
        slaStatus.remainingTimeToResolution.ticking
      );
    closeToBreach =
      closeToBreach ||
      slaIs.closeToBreach(
        slaStatus.remainingTimeToResolution.remainingTimeMinutes,
        slaStatus.remainingTimeToResolution.ticking,
        sla.minutesToResolution
      );
  }

  // breach dot gets priority
  // this means that if one sla is breached and another is close to breach
  // a red dot will be returned indication an sla was breached
  if (breachedAndIncomplete) {
    return (
      <MaterialIcon
        {...rest}
        css={css`
          color: var(${slaTabSelected ? "--red-6" : "--red-4"});
        `}
        path={mdiCircle}
        size={0.3125}
      />
    );
  }

  if (closeToBreach) {
    return (
      <MaterialIcon
        {...rest}
        css={css`
          color: var(${slaTabSelected ? "--yellow-8" : "--yellow-5"});
        `}
        path={mdiCircle}
        size={0.3125}
      />
    );
  }

  return null;
};

// Paused notice DOM will come in as children
export const SLAItemLayout: React.FC<{ iconComponent: JSX.Element; textComponent: JSX.Element }> = ({
  iconComponent: SLAStatusIcon,
  textComponent: SLAText,
  children,
  ...rest
}) => {
  return (
    <div
      css={css`
        & > * + * {
          margin-top: 2rem;
        }
      `}
      {...rest}
    >
      <div
        css={css`
          display: flex;

          & > * + * {
            margin-left: 0.5rem;
          }
        `}
      >
        <div
          css={css`
            padding-top: 0.1875rem;
          `}
        >
          {SLAStatusIcon}
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          {SLAText}
        </div>
      </div>
      {children}
    </div>
  );
};

export const SLAItem: React.FC<{
  slaType: SLAType;
  goalInMin: number;
  timeLeftInMin: number;
  ticking: boolean;
  requestStatus: RequestStatus;
}> = props => {
  const { slaType, goalInMin, timeLeftInMin, ticking, requestStatus, children, ...rest } = props;

  if (slaIs.breachedAndComplete(timeLeftInMin, ticking)) {
    return (
      <SLAItemLayout
        {...rest}
        iconComponent={
          <MaterialIcon
            css={css`
              color: var(--red-6);
            `}
            path={mdiCheckCircleOutline}
            size={1}
          />
        }
        textComponent={
          <>
            <Typo.Body bold strikethrough>
              {slaType === SLAType.FirstResponse
                ? "Responded"
                : slaType === SLAType.RequestResolution
                ? "Resolved"
                : "<unrecognized SLA goal type>"}{" "}
              within {formatSlaTime(goalInMin + Math.abs(timeLeftInMin))}
            </Typo.Body>
            <Typo.Body light>Goal: Within {formatSlaTime(goalInMin)}</Typo.Body>
          </>
        }
      />
    );
  }

  if (slaIs.successfullyMet(timeLeftInMin, ticking)) {
    return (
      <SLAItemLayout
        {...rest}
        iconComponent={
          <MaterialIcon
            css={css`
              color: var(--green-7);
            `}
            path={mdiCheckCircleOutline}
            size={1}
          />
        }
        textComponent={
          <>
            <Typo.Body bold strikethrough>
              {slaType === SLAType.FirstResponse
                ? "Responded"
                : slaType === SLAType.RequestResolution
                ? "Resolved"
                : "<unrecognized SLA goal type>"}{" "}
              within{" "}
              {formatSlaTime(goalInMin === Math.abs(timeLeftInMin) ? goalInMin : goalInMin - Math.abs(timeLeftInMin))}
            </Typo.Body>
            <Typo.Body light>Goal: Within {formatSlaTime(goalInMin)}</Typo.Body>
          </>
        }
      />
    );
  }

  if (slaIs.breachedAndIncomplete(timeLeftInMin, ticking)) {
    return (
      <SLAItemLayout
        {...rest}
        iconComponent={
          <MaterialIcon
            css={css`
              color: var(--red-6);
            `}
            path={mdiTimer}
            size={1}
          />
        }
        textComponent={
          <>
            <Typo.Body bold>
              {formatSlaTime(Math.abs(timeLeftInMin))} overdue to{" "}
              {slaType === SLAType.FirstResponse
                ? "respond"
                : slaType === SLAType.RequestResolution
                ? "resolve"
                : "<unrecognized SLA goal type>"}
            </Typo.Body>
            <Typo.Body light>Goal: Within {formatSlaTime(goalInMin)}</Typo.Body>
          </>
        }
      />
    );
  }

  if (slaIs.closeToBreach(timeLeftInMin, ticking, goalInMin)) {
    return (
      <SLAItemLayout
        {...rest}
        iconComponent={
          <MaterialIcon
            css={css`
              color: var(--yellow-8);
            `}
            path={mdiTimer}
            size={1}
          />
        }
        textComponent={
          <>
            <Typo.Body bold>
              {formatSlaTime(Math.abs(timeLeftInMin))} left to{" "}
              {slaType === SLAType.FirstResponse
                ? "respond"
                : slaType === SLAType.RequestResolution
                ? "resolve"
                : "<unrecognized SLA goal type>"}
            </Typo.Body>
            <Typo.Body light>Goal: Within {formatSlaTime(goalInMin)}</Typo.Body>
          </>
        }
      />
    );
  }

  if (slaIs.paused(requestStatus, slaType)) {
    return (
      <SLAItemLayout
        {...rest}
        iconComponent={
          <MaterialIcon
            css={css`
              color: var(--blue-1);
            `}
            path={mdiPauseCircleOutline}
            size={1}
          />
        }
        textComponent={
          <>
            <div
              css={css`
                display: flex;
                word-break: normal;
              `}
            >
              <Typo.Body bold>
                {formatSlaTime(Math.abs(timeLeftInMin))} left to{" "}
                {slaType === SLAType.FirstResponse
                  ? "respond"
                  : slaType === SLAType.RequestResolution
                  ? "resolve"
                  : "<unrecognized SLA goal type>"}
                &nbsp;
              </Typo.Body>
              <Typo.Body
                css={css`
                  color: var(--blue-1);
                `}
              >
                (paused)
              </Typo.Body>
            </div>
            <Typo.Body light>Goal: Within {formatSlaTime(goalInMin)}</Typo.Body>
          </>
        }
      >
        <InfoBanner>
          <MaterialIcon path={mdiInformation} size={1.125} />
          <Typo.Body>Goal to resolve is paused because the request is waiting</Typo.Body>
        </InfoBanner>
      </SLAItemLayout>
    );
  }

  // there's greater than 20% of time left for Goal (first response or request resolution)
  return (
    <SLAItemLayout
      {...rest}
      iconComponent={
        <MaterialIcon
          css={css`
            color: var(--blue-1);
          `}
          path={mdiTimer}
          size={1}
        />
      }
      textComponent={
        <>
          <Typo.Body bold>
            {formatSlaTime(Math.abs(timeLeftInMin))} left to{" "}
            {slaType === SLAType.FirstResponse
              ? "respond"
              : slaType === SLAType.RequestResolution
              ? "resolve"
              : "<unrecognized SLA goal type>"}
          </Typo.Body>
          <Typo.Body light>Goal: Within {formatSlaTime(goalInMin)}</Typo.Body>
        </>
      }
    />
  );
};
