import * as React from "react";

import { InjectedNotistackProps, VariantType, withSnackbar } from "notistack";

import { MaterialIcon } from "../../components";
import { mdiClose } from "@mdi/js";
import { useEffect } from "react";

/**
 * @param message external prop to display desired message
 * @param kind message type; currently only "error"
 */
interface IExternalProps {
  message: string;
  kind: "error" | "mutationError" | "custom";
  variant?: VariantType;
}

export const Toast = withSnackbar((props: InjectedNotistackProps & IExternalProps) => {
  useEffect(() => {
    if (props.message !== "Network error: Failed to fetch") {
      let message = props.message;
      if (process.env.ENV === "production") {
        if (props.kind === "error") {
          message = "Sorry, there was an error loading the page.";
        } else if (props.kind === "mutationError") {
          message = "Sorry, there was an error submitting.";
        }
      }
      const key = props.enqueueSnackbar(message, {
        action: <MaterialIcon path={mdiClose} size={1.25} onClick={() => true} data-testid="snack-close" />,
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        },
        persist: true,
        variant: props.variant || "error"
      });
      return () => {
        if (key) {
          props.closeSnackbar(key);
        }
      };
    }
    return () => null;
    /* "props" as dep causes render loop */
    /* eslint-disable-next-line */
  }, [props.message, props.variant]);
  // for backwards compatibility w/ tests
  return (
    <div style={{ display: "none" }} data-testid="hidden-toast">
      {props.message}
    </div>
  );
});
