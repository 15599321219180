import { SchemaList_schemaList } from "./typings/SchemaList";

export type FormAction =
  | {
      type: "SET_MODAL";
      modal: FormState["modal"];
    }
  | {
      type: "SET_MESSAGE";
      message: string;
    }
  | {
      type: "SELECT_SCHEMA_ID";
      selectedFormSchemaId: string;
    }
  | {
      type: "CHOOSE_SCHEMA";
      formSchemaToSend: SchemaList_schemaList;
    }
  | {
      type: "PREVIEW_SCHEMA";
      formSchemaToPreview: SchemaList_schemaList;
    }
  | {
      type: "SHOW_ANSWERS";
      submissionId: string;
    }
  | {
      type: "CLEAR";
    };

export interface FormState {
  message: string;
  modal: "choose" | "answers" | "preview" | "previewSelected" | "share" | null;
  formSchemaToSend?: SchemaList_schemaList;
  formSchemaToPreview?: SchemaList_schemaList;
  showAnswersSubmissionId?: string;
}

export const initialFormState: FormState = { modal: null, message: "" };

export function formReducer(state: FormState, action: FormAction): FormState {
  switch (action.type) {
    case "SET_MODAL": {
      return {
        ...state,
        modal: action.modal
      };
    }
    case "SET_MESSAGE": {
      return {
        ...state,
        message: action.message
      };
    }
    case "CHOOSE_SCHEMA": {
      return {
        ...state,
        modal: null,
        message: action.formSchemaToSend.description,
        formSchemaToSend: action.formSchemaToSend
      };
    }
    case "PREVIEW_SCHEMA": {
      return {
        ...state,
        modal: "preview",
        formSchemaToPreview: action.formSchemaToPreview
      };
    }
    case "SHOW_ANSWERS": {
      return {
        ...state,
        modal: "answers",
        showAnswersSubmissionId: action.submissionId
      };
    }
    case "CLEAR": {
      return initialFormState;
    }
    default: {
      return state;
    }
  }
}
