import { AnimatePresence, motion } from "framer-motion";
import React, { ReactNode, useState } from "react";

/** In lieu of #usetheplatform, this hacks around the focus state of details/summary
 *  and implements a toggable expander in a reacty way
 */
export function Details(props: {
  defaultOpen: boolean;
  summary: (toggle: () => void, isOpen: boolean) => ReactNode;
  children: ReactNode;
}) {
  const [open, setOpen] = useState(props.defaultOpen);
  return (
    <>
      {props.summary(() => setOpen(!open), open)}
      <AnimatePresence initial={false}>
        {open && (
          <motion.div
            style={{ overflow: "hidden" }}
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ ease: "easeInOut", duration: 0.2 }}
          >
            {props.children}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
