import { mapValues } from "lodash";

/**
 * LEGACY
 * Use primitives.ts
 */

/* @deprecated */
export const REM = 16;
/* @deprecated */
export enum stackOrder {
  lowest = -3,
  lower = -2,
  low = -1,
  default = 0,
  high = 1,
  higher = 2,
  highest = 3
}

interface IClear {
  bottom: number;
  left: number;
  right: number;
  top: number;
  combined?: string;
}

const addCombinedValues = (xs: { [type: string]: IClear }) =>
  mapValues(xs, x => ({ ...x, combined: `${x.top}rem ${x.right}rem ${x.bottom}rem ${x.left}rem` }));

const basePaddings = {
  button: {
    bottom: 0.25,
    left: 0.375,
    right: 0.375,
    top: 0.25
  },
  dialog: {
    bottom: 2.5,
    left: 2.5,
    right: 2.5,
    top: 2.5
  },
  mention: {
    bottom: 0,
    left: 0.125,
    right: 0.125,
    top: 0
  },
  textArea: {
    bottom: 0.75,
    left: 0.75,
    right: 0.75,
    top: 0.75
  }
};
/* @deprecated */
export const paddings = addCombinedValues(basePaddings);

const baseMargins = {
  dialogButtons: {
    bottom: 0,
    left: 0,
    right: 0,
    top: 2
  },
  heading: {
    bottom: 2,
    left: 0,
    right: 0,
    top: 0
  },
  subheading: {
    bottom: 3,
    left: 0,
    right: 0,
    top: 1
  }
};
/* @deprecated */
export const margins = addCombinedValues(baseMargins);

/* @deprecated */
export const dimensions = {
  button: {
    height: 1.875
  },
  card: {
    maxWidth: 68.125,
    minWidth: 37
  },
  general: {
    maxWidth: 70,
    minWidth: 42
  }
};
