import * as React from "react";

import { Button, ButtonBar, Dialog } from "src/components";
import { ChangeEvent, FC, useState } from "react";
import { RichEditor, richEditorInputStyles } from "src/components/RichEditor/Editor";
import { backMarkdownToHtml, htmlToBackMarkdown } from "src/util/formatters";

import { Modal } from "src/portals/Modal";
import { TextField } from "src/App/Forms/Components";
import { Typo } from "src/styling/primitives/typography";
import css from "@emotion/css";

export const EditWorkflowNameModal: FC<{
  isOpen: boolean;
  onClose(): void;
  onConfirm(value: string): void;
  initialValue: string;
}> = ({ isOpen, onClose, onConfirm, initialValue }) => {
  const [inputState, setInputState] = useState<string>(initialValue);
  return (
    <Modal isOpen={isOpen} onDismiss={onClose}>
      <Dialog medium title="Rename workflow" onClose={onClose}>
        <TextField
          name={"name"}
          value={inputState}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setInputState(e.target.value)}
          css={css`
            & textarea {
              font-size: var(--font-size-body);
            }
          `}
        />
        <ButtonBar
          css={[
            css`
              margin-top: var(--space-5-rem);
            `
          ]}
        >
          <Button type="button" variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="button"
            variant="primary"
            disabled={!inputState.length || inputState === initialValue}
            onClick={() => onConfirm(inputState)}
          >
            Save
          </Button>
        </ButtonBar>
      </Dialog>
    </Modal>
  );
};

export const EditWorkflowDescriptionModal: FC<{
  isOpen: boolean;
  onClose(): void;
  onConfirm(value: string): void;
  initialValue: string;
}> = ({ isOpen, onClose, onConfirm, initialValue }) => {
  const [inputState, setInputState] = useState<string>(initialValue);
  return (
    <Modal isOpen={isOpen} onDismiss={onClose}>
      <Dialog medium title="Change workflow description" onClose={onClose}>
        <div css={richEditorInputStyles}>
          <RichEditor
            initialValue={backMarkdownToHtml(inputState)}
            onChange={html => {
              setInputState(htmlToBackMarkdown(html));
            }}
            minLines={4}
          />
        </div>
        <ButtonBar
          css={[
            css`
              margin-top: var(--space-5-rem);
            `
          ]}
        >
          <Button type="button" variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="button"
            variant="primary"
            disabled={!inputState.length || inputState === initialValue}
            onClick={() => onConfirm(inputState)}
          >
            Save
          </Button>
        </ButtonBar>
      </Dialog>
    </Modal>
  );
};

export const EnableDisableWorkflowModal: FC<{
  isOpen: boolean;
  isWorkflowEnabled: boolean;
  onClose(): void;
  onConfirm(): void;
}> = ({ isOpen, isWorkflowEnabled, onClose, onConfirm }) => (
  <Modal isOpen={isOpen} onDismiss={onClose}>
    <Dialog medium title={`${isWorkflowEnabled ? "Disable" : "Enable"} workflow`} onClose={onClose}>
      <Typo.Body>Are you sure you want to {isWorkflowEnabled ? "disable" : "enable"} this workflow</Typo.Body>
      <ButtonBar
        css={[
          css`
            margin-top: var(--space-5-rem);
          `
        ]}
      >
        <Button type="button" variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="button" variant="primary" onClick={onConfirm}>
          {!isWorkflowEnabled ? "Enable" : "Disable"}
        </Button>
      </ButtonBar>
    </Dialog>
  </Modal>
);
