import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { authFlowLink } from "./authToken";
import { createCache } from "./cache";
import { errorHandlerLink, responseHandlerLink, setHeaders } from "./links";

export const createClient = () =>
  new ApolloClient({
    cache: createCache(),
    link: ApolloLink.from([
      errorHandlerLink(),
      responseHandlerLink(),
      authFlowLink,
      setHeaders,
      createHttpLink({
        // include cookies from e.g. 4000 if running on 3000
        credentials: "include",
        uri: `${process.env.REACT_APP_GRAPHQL_URL}`,
        // include operationName for better network filtering
        fetch: (uri: string, options: { body: string }) => {
          const { operationName } = JSON.parse(options.body);
          const graphqlUrl = new URL(uri);
          graphqlUrl.searchParams.set("operation", operationName);
          return fetch(graphqlUrl.toString(), options);
        }
      })
    ])
  });

export const apolloClient = createClient();
