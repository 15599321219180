import { Formik } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { useMutation } from "react-apollo";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { categoryList } from "src/App/Settings/defaultCategories";
import { getTeamInfoFromStorage, removeTeamInfoFromStorage } from "src/App/Settings/Teams/Create/Init/Form";
import { SETTINGS_TEAM_LIST_GET } from "src/App/Settings/Teams/Overview";
import { TeamCategoriesComponent } from "src/App/Settings/Teams/TeamCategories";
import { Col, LoadingBar, Row, SubmitButton } from "src/components";
import { Form, FormWrapper } from "src/components/Fields/FormWrappers";
import { TeamPrivacy } from "src/globalTypes";
import * as yup from "yup";
import { TeamCreate } from "./typings/TeamCreate";

const CreateTeamFormSchema = yup.object().shape({
  categories: yup
    .array(
      yup.object().shape({
        name: yup.string(),
        id: yup.string()
      })
    )
    .required("Required"),
  name: yup.string().required("Required"),
  privacy: yup.string().required("Required"),
  slug: yup
    .string()
    .matches(/^[a-z|\d|-]*$/, "Must be all lowercase alphanumeric characters with no spaces (hyphens allowed)")
    .matches(/[^-]$/, "Must not end with a hyphen")
    .matches(/^[^-]/, "Must not start with a hyphen")
    .required("Required")
});

export const SelectCategories: React.ComponentType<{
  redirect?: string;
  skippable?: boolean;
  submitText?: string;
  onSubmit?: (newTeamId: string) => void;
}> = props => {
  const { emitSnack } = useSnack();

  const [teamCreate] = useMutation(
    gql`
      mutation TeamCreate($params: InTeam!) {
        teamCreate(params: $params) {
          code
          message
          success
          invalid
          team {
            id
            name
            slug
            privacy
          }
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: SETTINGS_TEAM_LIST_GET
        }
      ]
    }
  );
  return (
    <FormWrapper data-testid="select-categories">
      <Formik
        enableReinitialize={true}
        onSubmit={async (payload, actions) => {
          // strip "__typename" field from payload
          const params = {
            ...payload,
            categories:
              payload && payload.categories && payload.categories.length
                ? payload.categories.map(c => ({ name: c.name, id: c.id }))
                : []
          };
          const { data } = await teamCreate({
            variables: {
              params
            }
          });
          if (data?.teamCreate.success === false) {
            emitSnack({ type: "mutationError", message: (data as TeamCreate).teamCreate.message });
          }
          if (data && data.teamCreate.success) {
            if (data.teamCreate.team?.id) {
              removeTeamInfoFromStorage();
              if (props.onSubmit) {
                props.onSubmit(data.teamCreate.team.id);
              }
            }
          }
          actions.setSubmitting(false);
        }}
        initialValues={{
          // Initially select all categories by default.
          categories: categoryList,
          name: "",
          privacy: TeamPrivacy.PUBLIC,
          internal: false,
          slug: ""
        }}
        validationSchema={CreateTeamFormSchema}
        render={form => {
          // Attempt to get the required team information from session storage and, if some of it
          // differs from the form's current values, update the form values accordingly.
          //
          // We just assume the team info to exist in the session storage. If it doesn't, that's
          // not our concern here and another component (most likely the parent component) should
          // handle that case appropriately, e.g. by redirecting to some other page.
          const teamInfo = getTeamInfoFromStorage();
          const parsedCategories = teamInfo && teamInfo.categories && JSON.parse(teamInfo.categories);
          const categories = parsedCategories && parsedCategories.length ? parsedCategories : categoryList;
          if (teamInfo.name && teamInfo.slug && teamInfo.privacy) {
            if (
              teamInfo.name !== form.values.name ||
              teamInfo.slug !== form.values.slug ||
              teamInfo.privacy !== form.values.privacy
            ) {
              form.setValues({
                ...form.values,
                categories,
                name: teamInfo.name,
                privacy: teamInfo.privacy,
                slug: teamInfo.slug,
                internal: teamInfo.internal
              });
            }
            removeTeamInfoFromStorage();
          }

          return (
            <Form onSubmit={form.handleSubmit} padding="0">
              <Col>
                <TeamCategoriesComponent selectedCategories={form.values.categories.map(c => c.name)} />
                <Row justify="flex-end" margin="2rem 0 0 0">
                  {!form.isSubmitting && <SubmitButton>{props.submitText || "Add categories"}</SubmitButton>}
                  {form.isSubmitting && <LoadingBar />}
                </Row>
              </Col>
            </Form>
          );
        }}
      />
    </FormWrapper>
  );
};
