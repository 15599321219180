/* This file was automatically generated and should not be edited. */

/**
 * 🛑 STOP: If you're using this you're doing something wrong
 * @deprecated
 **/
export const __rawColorValues = {
  black: "#000000",
  blue_1: "#3256b4",
  blue_2: "#2E4AA8",
  blue_3: "#2b4196",
  blue_4: "#263880",
  blue_5: "#203066",
  blue_6: "#192547",
  blue_7: "#121937",
  border: "#d9deea",
  box: "#f4f6fb",
  flesh_1: "#FFFCFA",
  flesh_2: "#fef0eb",
  flesh_3: "#F4DFD8",
  flesh_4: "#F2D2C7",
  flesh_5: "#E3AE9F",
  flesh_6: "#DB9C8B",
  flesh_7: "#CC8875",
  flesh_8: "#BE7966",
  green_1: "#e6ffe8",
  green_2: "#BCFCCA",
  green_3: "#98F3B9",
  green_4: "#73E4A6",
  green_5: "#54d399",
  green_6: "#3cbe88",
  green_7: "#2ba275",
  green_8: "#23684d",
  hover: "#e8ebf4",
  lightGrey_1: "#f4f6fb",
  lightGrey_2: "#E7EAF1",
  lightGrey_3: "#D7DBE5",
  lightGrey_4: "#C1C7D7",
  lightBlue_1: "#e8eeff",
  lightBlue_2: "#dee5ff",
  lightBlue_3: "#cbd6fb",
  lightBlue_4: "#b4c4f9",
  orange_1: "#fcfbf7",
  pink_1: "#FEEFFD",
  pink_2: "#EDC5E7",
  pink_3: "#DB9DCD",
  pink_4: "#C677B1",
  pink_5: "#AC558F",
  pink_6: "#A03475",
  pink_7: "#8F1758",
  red_1: "#fbe8e9",
  red_2: "#FDCACB",
  red_3: "#FAA8A8",
  red_4: "#F86B6E",
  red_5: "#F73F48",
  red_6: "#E12E32",
  red_7: "#bb1a24",
  sand_1: "#F7F6F0",
  sand_2: "#f2f1ea",
  text_1: "#c5cedd",
  text_2: "#a0adc3",
  text_3: "#7a87a1",
  text_4: "#576179",
  text_5: "#414C5D",
  text_6: "#192547",
  violet_1: "#ebebff",
  violet_2: "#D6D6FF",
  violet_3: "#A7A4FF",
  violet_4: "#8582FF",
  violet_5: "#685BF1",
  violet_6: "#5644C7",
  violet_7: "#4e37b0",
  white: "#ffffff",
  yellow_1: "#fffade",
  yellow_2: "#fff3ad",
  yellow_3: "#fee97c",
  yellow_4: "#fce050",
  yellow_5: "#F5C235",
  yellow_6: "#EFAD1A",
  yellow_7: "#e99800",
  yellow_8: "#ca7d00",
  yellow_9: "#975200"
};
