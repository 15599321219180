import { css } from "@emotion/core";
import { mdiBell, mdiClose, mdiLock, mdiPencil, mdiPlus } from "@mdi/js";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import gql from "graphql-tag";
import { groupBy } from "lodash";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import {
  CreateCustomRequestStatus,
  CreateCustomRequestStatusVariables
} from "src/App/Settings/RequestStatuses/typings/CreateCustomRequestStatus";
import {
  DeleteCustomReqStatus,
  DeleteCustomReqStatusVariables
} from "src/App/Settings/RequestStatuses/typings/DeleteCustomReqStatus";
import {
  RequestsWithStatus,
  RequestsWithStatusVariables
} from "src/App/Settings/RequestStatuses/typings/RequestsWithStatus";
import {
  UpdateCustomRequestStatus,
  UpdateCustomRequestStatusVariables
} from "src/App/Settings/RequestStatuses/typings/UpdateCustomRequestStatus";
import {
  BaseLayout,
  Button,
  CancelButton,
  CleanUnderlineButton,
  Col,
  Dialog,
  FormikFieldGroup,
  HelperText,
  Input,
  LoadingBar,
  MaterialIcon,
  Row,
  SquareButton,
  SubmitButton
} from "src/components";
import { Badge } from "src/components/Badges";
import { SingleSelect } from "src/components/Fields/Select";
import { PopOver } from "src/components/PopOver";
import { colorVariantMappings, ColorVariantsUnion, Status } from "src/components/StatusIndicator";
import { Tooltip } from "src/components/Tooltip";
import { RequestStatus } from "src/globalTypes";
import { Modal } from "src/portals/Modal";
import { Toast } from "src/portals/Toast";
import { Card } from "src/styling/primitives/Card";
import { Typo } from "src/styling/primitives/typography";
import { useUrlState } from "src/util/router";
import * as yup from "yup";
import {
  SettingsCustomRequestStatusGetAll,
  SettingsCustomRequestStatusGetAll_teamList,
  SettingsCustomRequestStatusGetAll_teamList_customStatuses
} from "./typings/SettingsCustomRequestStatusGetAll";

const SETTINGS_CUSTOM_REQ_STATUSES_GET_ALL = gql`
  query SettingsCustomRequestStatusGetAll {
    teamList {
      id
      name
      userIds
      customStatuses {
        id
        teamId
        step
        name
        color
        isDefaultStatus
        sendNotification
      }
    }
    currentUser {
      id
    }
  }
`;

export const RequestStatusesOverview: React.FC = () => {
  const allData = useQuery<SettingsCustomRequestStatusGetAll>(SETTINGS_CUSTOM_REQ_STATUSES_GET_ALL, {
    fetchPolicy: "network-only"
  });

  if (allData.loading) {
    return <LoadingBar />;
  }

  if (allData.error) {
    return <Toast message={allData.error.message} kind="error" />;
  }

  const teams = (allData.data?.teamList ?? []).filter(t => t.userIds.includes(allData.data?.currentUser?.id ?? ""));
  const statuses = Object.fromEntries(
    teams.map(t => {
      return [t.id, t.customStatuses];
    })
  );

  return <RequestStatuses teams={teams} statuses={statuses} />;
};

const RequestStatuses: React.FC<{
  teams: SettingsCustomRequestStatusGetAll_teamList[];
  statuses: { [key: string]: SettingsCustomRequestStatusGetAll_teamList_customStatuses[] };
}> = ({ teams, statuses }) => {
  const [selectedTeamId, setSelectedTeamId] = useUrlState("team", teams[0].id);
  const selectedTeam = teams.find(t => t.id === selectedTeamId) ?? teams[0];
  const [editStatusId, setEditStatusId] = useUrlState("editStatus", null);
  const [deleteStatusId, setDeleteStatusId] = useUrlState("deleteStatus", null);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const statusesForTeamGroupedByStep = groupBy(statuses[selectedTeamId], "step");
  const statusById = (id: string | null) => statuses[selectedTeamId].find(s => s.id === id);
  const placeholderStatus = statuses[selectedTeamId][0];

  return (
    <>
      <BaseLayout
        headline="Request statuses"
        subline={
          <Row>
            <Typo.Body>Customize the statuses for requests that belong to the team </Typo.Body>
            <PopOver.Menu
              options={teams.map(({ id, name }) => ({ id, name }))}
              onSelect={(teamId: string) => {
                setSelectedTeamId(teamId);
              }}
              selected={selectedTeamId}
              trigger={
                <CleanUnderlineButton
                  css={css`
                    margin-left: var(--space-1-rem);
                  `}
                >
                  {selectedTeam.name}
                </CleanUnderlineButton>
              }
            />
          </Row>
        }
      >
        <Row justify="space-between">
          <Col
            css={css`
              & > * + * {
                margin-top: var(--space-6-rem);
              }
            `}
          >
            <Step title="Not started">
              {statusesForTeamGroupedByStep[RequestStatus.NOTSTARTED].map(status => {
                return (
                  <RequestStatusCard
                    key={status.id}
                    status={status}
                    onEdit={() => {
                      setEditStatusId(status.id);
                    }}
                    onDelete={() => {
                      setDeleteStatusId(status.id);
                    }}
                  />
                );
              })}
            </Step>

            <Step title="Started">
              {statusesForTeamGroupedByStep[RequestStatus.INPROGRESS].map(status => {
                return (
                  <RequestStatusCard
                    key={status.id}
                    status={status}
                    onEdit={() => {
                      setEditStatusId(status.id);
                    }}
                    onDelete={() => {
                      setDeleteStatusId(status.id);
                    }}
                  />
                );
              })}
            </Step>

            <Step
              title="On hold"
              helpText="Requests assigned this status are paused for the time to resolve in reporting and their SLA"
            >
              {statusesForTeamGroupedByStep[RequestStatus.WAITING].map(status => {
                return (
                  <RequestStatusCard
                    key={status.id}
                    status={status}
                    onEdit={() => {
                      setEditStatusId(status.id);
                    }}
                    onDelete={() => {
                      setDeleteStatusId(status.id);
                    }}
                  />
                );
              })}
            </Step>

            <Step
              title="Done"
              helpText="Requests assigned this status are moved to the Archive and are not shown in regular views"
            >
              {statusesForTeamGroupedByStep[RequestStatus.RESOLVED].map(status => {
                return (
                  <RequestStatusCard
                    key={status.id}
                    status={status}
                    onEdit={() => {
                      setEditStatusId(status.id);
                    }}
                    onDelete={() => {
                      setDeleteStatusId(status.id);
                    }}
                  />
                );
              })}
            </Step>
            <Row>
              <SubmitButton onClick={() => setShowCreateModal(true)} size="medium">
                <MaterialIcon path={mdiPlus} size={1.125} margin="0 var(--space-1-rem) 0 0" />
                Add request status
              </SubmitButton>
            </Row>
          </Col>
        </Row>
      </BaseLayout>
      <CreateModal isShown={showCreateModal} onClose={() => setShowCreateModal(false)} team={selectedTeam} />
      <EditModal
        isShown={!!editStatusId}
        onClose={() => setEditStatusId(null)}
        status={statusById(editStatusId) ?? placeholderStatus}
      />
      <DeleteModal
        isShown={!!deleteStatusId}
        onClose={() => setDeleteStatusId(null)}
        status={statusById(deleteStatusId) ?? placeholderStatus}
      />
    </>
  );
};

const Step: React.FC<{ title: string; helpText?: string }> = props => {
  return (
    <div
      css={css`
        & > * + * {
          margin-top: var(--space-3-px);
        }
      `}
    >
      <Typo.Body bold>{props.title}</Typo.Body>
      <div
        css={css`
          & > * + * {
            margin-top: var(--space-2-px);
          }
        `}
      >
        {props.children} {/* the status cards go here */}
      </div>
      <div>
        <HelperText>{props.helpText}</HelperText>
      </div>
    </div>
  );
};

const RequestStatusCard: React.FC<{
  status: SettingsCustomRequestStatusGetAll_teamList_customStatuses;
  onEdit: () => void;
  onDelete: () => void;
}> = ({ status, onEdit, onDelete }) => {
  return (
    <Card
      sizeS
      key={status.id}
      css={css`
        display: flex;
        width: 30.5rem;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-grow: 1;

          & > * + * {
            margin-left: var(--space-2-px);
          }
        `}
      >
        <Status.Indicator
          requestStatus={status.step}
          customColor={status.color as ColorVariantsUnion}
          hasInteractions={false}
          plain={true}
        />
        <Typo.Body>{status.name}</Typo.Body>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;

          & > * + * {
            margin-left: var(--space-2-px);
          }
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;

            & > * + * {
              margin-left: var(--space-1-px);
            }
          `}
        >
          {status.sendNotification && (
            <Tooltip
              target={
                <Badge neutral icon inert noTextTransform>
                  <MaterialIcon path={mdiBell} size={1} />
                </Badge>
              }
            >
              Assigning this status notifies the requester
            </Tooltip>
          )}
          {status.isDefaultStatus && (
            <Tooltip
              target={
                <Badge neutral icon inert noTextTransform>
                  <MaterialIcon path={mdiLock} size={1} />
                </Badge>
              }
            >
              This is a default status. It can’t be modified
            </Tooltip>
          )}
        </div>
        {!status.isDefaultStatus && (
          <>
            <Tooltip
              target={
                <SquareButton size="small" onClick={onEdit}>
                  <MaterialIcon path={mdiPencil} size={1.125} />
                </SquareButton>
              }
            >
              Edit status
            </Tooltip>
            <Tooltip
              target={
                <SquareButton size="small" onClick={onDelete}>
                  <MaterialIcon path={mdiClose} size={1.125} />
                </SquareButton>
              }
            >
              Delete status
            </Tooltip>
          </>
        )}
      </div>
    </Card>
  );
};

export const steps = [
  { label: "Not started", value: RequestStatus.NOTSTARTED },
  { label: "Started", value: RequestStatus.INPROGRESS },
  { label: "On hold", value: RequestStatus.WAITING },
  { label: "Done", value: RequestStatus.RESOLVED }
];

const createRequestStatusSchema = yup.object().shape({
  name: yup.string().max(32).required(),
  step: yup.string().required(),
  color: yup.string().oneOf(Object.keys(colorVariantMappings)).required()
});

const CreateModal: React.FC<{
  isShown: boolean;
  onClose: () => void;
  team: SettingsCustomRequestStatusGetAll_teamList;
}> = ({ isShown, onClose, team }) => {
  const { emitSnack } = useSnack();

  const [createCustomStatus, createCustomStatusResponse] = useMutation<
    CreateCustomRequestStatus,
    CreateCustomRequestStatusVariables
  >(
    gql`
      mutation CreateCustomRequestStatus($customStatus: InCustomStatus!) {
        customStatusCreate(customStatus: $customStatus) {
          code
          success
          message
        }
      }
    `,
    {
      refetchQueries: [{ query: SETTINGS_CUSTOM_REQ_STATUSES_GET_ALL }],
      awaitRefetchQueries: true
    }
  );

  return (
    <>
      {createCustomStatusResponse.loading && <LoadingBar />}
      <Modal isOpen={isShown} onDismiss={onClose}>
        <Dialog loading={false} medium title="Add request status" onClose={onClose}>
          <Formik
            initialValues={{
              name: "",
              step: "" as RequestStatus,
              color: "lightGrey" as ColorVariantsUnion
            }}
            onSubmit={formData => {
              const payload = {
                teamId: team.id,
                ...formData
              };
              return createCustomStatus({
                variables: {
                  customStatus: payload
                },
                update(_cache, response) {
                  if (response.data?.customStatusCreate.success) {
                    emitSnack({
                      message: `Status ${payload.name} created`,
                      type: "info"
                    });
                  } else {
                    emitSnack({
                      message: response.data?.customStatusCreate?.message ?? `Error creating status ${payload.name}`,
                      type: "mutationError"
                    });
                  }
                  onClose();
                }
              });
            }}
            validationSchema={createRequestStatusSchema}
          >
            {form => {
              return (
                <Form>
                  <FormikFieldGroup.Container legend="Name">
                    <Field name="name">
                      {(formikFieldProps: FieldProps) => (
                        <Input
                          {...formikFieldProps.field}
                          css={css`
                            width: 100%;
                          `}
                          hasError={!!form.errors.name && !!form.touched.name}
                        />
                      )}
                    </Field>
                    <FormikFieldGroup.Errors>
                      <ErrorMessage name="name" />
                    </FormikFieldGroup.Errors>
                  </FormikFieldGroup.Container>

                  <FormikFieldGroup.Container legend="Step">
                    <SingleSelect
                      name="step"
                      placeholder="Select a step"
                      options={steps}
                      onChange={e => {
                        if (e && "value" in e) {
                          form.setFieldValue("step", e.value);
                        }
                      }}
                      isDisabled={false}
                      isSearchable={false}
                      showError={false}
                    />
                    {form.values.step === RequestStatus.WAITING && (
                      <FormikFieldGroup.HelpText>
                        Requests assigned this status are paused for the time to resolve in reporting and their SLA
                      </FormikFieldGroup.HelpText>
                    )}
                    {form.values.step === RequestStatus.RESOLVED && (
                      <FormikFieldGroup.HelpText>
                        Requests assigned this status are moved to the Archive and are not shown in regular views
                      </FormikFieldGroup.HelpText>
                    )}
                    <FormikFieldGroup.Errors>
                      <ErrorMessage name="step" />
                    </FormikFieldGroup.Errors>
                  </FormikFieldGroup.Container>

                  <FormikFieldGroup.Container legend="Color">
                    <Field name="color">
                      {(formikFieldProps: FieldProps) => (
                        <ColorPicker formikFieldProps={formikFieldProps} fieldValue={form.values.color} />
                      )}
                    </Field>
                  </FormikFieldGroup.Container>

                  <Row justify="flex-end" margin="var(--space-6-rem) 0 0 0">
                    <CancelButton onClick={onClose} disabled={createCustomStatusResponse.loading}>
                      Cancel
                    </CancelButton>
                    <SubmitButton disabled={!form.isValid || form.isSubmitting || createCustomStatusResponse.loading}>
                      Add status
                    </SubmitButton>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Dialog>
      </Modal>
    </>
  );
};

const EditModal: React.FC<{
  isShown: boolean;
  onClose: () => void;
  status: SettingsCustomRequestStatusGetAll_teamList_customStatuses;
}> = ({ isShown, onClose, status }) => {
  const { emitSnack } = useSnack();

  const [updateCustomStatus, updateCustomStatusResponse] = useMutation<
    UpdateCustomRequestStatus,
    UpdateCustomRequestStatusVariables
  >(
    gql`
      mutation UpdateCustomRequestStatus($customStatus: UpCustomStatus!) {
        customStatusUpdate(customStatus: $customStatus) {
          code
          success
          message
        }
      }
    `,
    {
      refetchQueries: [{ query: SETTINGS_CUSTOM_REQ_STATUSES_GET_ALL }],
      awaitRefetchQueries: true
    }
  );
  return (
    <>
      {updateCustomStatusResponse.loading && <LoadingBar />}
      <Modal isOpen={isShown} onDismiss={onClose}>
        <Dialog loading={false} medium title="Edit request status" onClose={onClose}>
          <Formik
            initialValues={{
              name: status.name,
              color: status.color
            }}
            onSubmit={formData => {
              const payload = {
                id: status.id,
                ...formData
              };
              return updateCustomStatus({
                variables: {
                  customStatus: payload
                },
                update(_cache, response) {
                  if (response.data?.customStatusUpdate.success) {
                    emitSnack({
                      message: `Status ${status.name} updated`,
                      type: "info"
                    });
                  } else {
                    emitSnack({
                      message: response.data?.customStatusUpdate?.message ?? `Error updating status ${status.name}`,
                      type: "mutationError"
                    });
                  }
                  onClose();
                }
              });
            }}
            validationSchema={yup.object().shape({
              name: yup.reach(createRequestStatusSchema, "name"),
              color: yup.reach(createRequestStatusSchema, "color")
            })}
          >
            {form => {
              return (
                <Form>
                  <FormikFieldGroup.Container legend="Name">
                    <Field name="name">
                      {(formikFieldProps: FieldProps) => (
                        <Input
                          {...formikFieldProps.field}
                          css={css`
                            width: 100%;
                          `}
                          hasError={!!form.errors.name && !!form.touched.name}
                        />
                      )}
                    </Field>
                    <FormikFieldGroup.Errors>
                      <ErrorMessage name="name" />
                    </FormikFieldGroup.Errors>
                  </FormikFieldGroup.Container>

                  <FormikFieldGroup.Container legend="Color">
                    <Field name="color">
                      {(formikFieldProps: FieldProps) => (
                        <ColorPicker
                          formikFieldProps={formikFieldProps}
                          fieldValue={form.values.color as ColorVariantsUnion}
                        />
                      )}
                    </Field>
                  </FormikFieldGroup.Container>

                  <Row justify="flex-end" margin="var(--space-6-rem) 0 0 0">
                    <CancelButton onClick={onClose} disabled={updateCustomStatusResponse.loading}>
                      Cancel
                    </CancelButton>
                    <SubmitButton
                      disabled={!form.isValid || !form.dirty || form.isSubmitting || updateCustomStatusResponse.loading}
                    >
                      Save changes
                    </SubmitButton>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Dialog>
      </Modal>
    </>
  );
};

const ColorPicker: React.FC<{ formikFieldProps: FieldProps; fieldValue: ColorVariantsUnion }> = ({
  formikFieldProps,
  fieldValue
}) => {
  const { name } = formikFieldProps.field;

  const colors = Object.entries(colorVariantMappings).map(([color, { icon: hex }]) => {
    const checked = fieldValue === color;
    return (
      <div
        key={color}
        css={css`
          cursor: pointer;

          & > input {
            display: none;
          }

          & > div {
            background-color: ${hex};
            height: 24px;
            width: 24px;
            border-radius: var(--border-radius-round);
            display: flex;
            justify-content: center;
            align-items: center;
          }

          & > div > div {
            background-color: ${checked ? "var(--white)" : "transparent"};
            height: 8px;
            width: 8px;
            border-radius: var(--border-radius-round);
            transition: background-color 75ms ease-in;
          }

          &:hover > div > div,
          &:focus > div > div {
            background-color: rgba(var(--white-raw), 0.5);
          }
        `}
        onClick={() => formikFieldProps.form.setFieldValue(name, color)}
        // a11y
        tabIndex={0}
        role="radio"
        onKeyDown={e => {
          if (e.key === "Enter" || e.key === " ") {
            formikFieldProps.form.setFieldValue(name, color);
          }
        }}
        aria-checked={checked}
      >
        <Input name={name} type="radio" value={color} checked={checked} readOnly />
        <div>
          <div></div>
        </div>
      </div>
    );
  });

  return (
    <Row
      css={css`
        & > * + * {
          margin-left: var(--space-3-px);
        }
      `}
    >
      {colors}
    </Row>
  );
};

const DeleteModal: React.FC<{
  isShown: boolean;
  onClose: () => void;
  status: SettingsCustomRequestStatusGetAll_teamList_customStatuses;
}> = ({ isShown, onClose, status }) => {
  const { emitSnack } = useSnack();

  const allReqs = useQuery<RequestsWithStatus, RequestsWithStatusVariables>(
    gql`
      query RequestsWithStatus($q: RequestListQuery) {
        requestList(query: $q) {
          id
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      variables: {
        q: {
          filters: `customStatus=${status.id}`
        }
      }
    }
  );
  const [deleteCustomRequestStatus, deleteCustomRequestStatusResponse] = useMutation<
    DeleteCustomReqStatus,
    DeleteCustomReqStatusVariables
  >(
    gql`
      mutation DeleteCustomReqStatus($statusId: ID!) {
        customStatusDelete(id: $statusId) {
          code
          success
          message
          object {
            id
          }
        }
      }
    `,
    {
      refetchQueries: [{ query: SETTINGS_CUSTOM_REQ_STATUSES_GET_ALL }],
      awaitRefetchQueries: true
    }
  );

  if (allReqs.loading) {
    return <LoadingBar />;
  }

  if (allReqs.error) {
    return <Toast message={allReqs.error.message} kind="error" />;
  }

  const assignedRequestsCount = allReqs.data?.requestList?.length ?? 0;
  const title = assignedRequestsCount ? "Can’t delete this status" : "Delete request status";
  const body = assignedRequestsCount ? (
    <Typo.Body>
      The status <i>{status.name}</i> has {assignedRequestsCount} {assignedRequestsCount > 1 ? "requests" : "request"}{" "}
      assigned to it. Assign {assignedRequestsCount > 1 ? "them" : "it"} another status before deleting.
    </Typo.Body>
  ) : (
    <Typo.Body>
      Are you sure you want to delete the status <i>{status.name}</i>?
    </Typo.Body>
  );

  return (
    <>
      {deleteCustomRequestStatusResponse.loading && <LoadingBar />}

      <Modal onDismiss={onClose} isOpen={isShown}>
        <Dialog medium title={title} onClose={onClose}>
          <Col>
            <div>{body}</div>
            <Row justify="flex-end" margin="var(--space-5-rem) 0 0">
              {assignedRequestsCount ? (
                <Button variant="secondary" size="large" onClick={onClose}>
                  Ok
                </Button>
              ) : (
                <>
                  <CancelButton
                    disabled={deleteCustomRequestStatusResponse.loading}
                    onClick={onClose}
                    margin="0 var(--space-3-rem) 0 0"
                  >
                    Cancel
                  </CancelButton>
                  <Button
                    disabled={deleteCustomRequestStatusResponse.loading}
                    variant="red"
                    size="large"
                    onClick={() => {
                      return deleteCustomRequestStatus({
                        variables: {
                          statusId: status.id
                        },
                        update(_cache, response) {
                          if (response.data?.customStatusDelete.success) {
                            emitSnack({
                              message: `Status ${status.name} deleted`,
                              type: "info"
                            });
                          } else {
                            emitSnack({
                              message:
                                response.data?.customStatusDelete?.message ?? `Error deleting status ${status.name}`,
                              type: "mutationError"
                            });
                          }
                          onClose();
                        }
                      });
                    }}
                  >
                    Delete status
                  </Button>
                </>
              )}
            </Row>
          </Col>
        </Dialog>
      </Modal>
    </>
  );
};
