const _routeData = {
  analytics: {
    pathname: "analytics",
    title: "Analytics",
    description: "Organization analytics"
  },
  approval: {
    pathname: "approval",
    title: "Approval",
    description: "Grant or reject your approval"
  },
  bestPractices: {
    pathname: "best-practices",
    title: "Best practices",
    description: "Get inspired by successful companies handling day to day processes"
  },
  default: {
    pathname: "",
    title: "App",
    description: "Seamless employee experiences"
  },
  dhl: {
    pathname: "status",
    title: "Request status",
    description: "See the full history and current status of your request"
  },
  forms: {
    pathname: "forms",
    title: "Forms",
    description: "Provide forms that can help to answer the right questions quickly"
  },
  formResponse: {
    pathname: "response",
    title: "Answer form",
    description: "Provide answers to a form sent to you"
  },
  insights: {
    pathname: "insights",
    title: "Insights",
    description: "Organization insights"
  },
  integrations: {
    pathname: "integrations",
    title: "Integrations",
    description: "Add or edit Back's integrations"
  },
  knowledge: {
    pathname: "knowledge",
    title: "Knowledge base",
    description: "Provide helpful knowledge documents for automatic answers",
    subpaths: {
      log: {
        pathname: "log",
        title: "Auto-answer log",
        description: "See previously suggested auto-answers"
      },
      playground: {
        pathname: "playground",
        title: "Auto-answer playground",
        description: "Understand how our suggester works with your knowledge base"
      },
      addConfluence: {
        pathname: "add-confluence",
        title: "Add document from Confluence",
        description: "Add document from Confluence"
      },
      addNotion: {
        pathname: "add-notion",
        title: "Add document from Notion",
        description: "Add document from Notion"
      },
      addSharepoint: {
        pathname: "add-sharepoint",
        title: "Add document from Sharepoint",
        description: "Add document from Sharepoint"
      }
    }
  },
  outreach: {
    pathname: "outreach",
    title: "Outreach",
    description: "Reach out to multiple users"
  },
  projects: {
    pathname: "projects",
    title: "Projects",
    description: "Keep track of a sequence of requests inside a project"
  },
  request: {
    pathname: "request",
    title: "Request detail",
    description: "See the full history of a request and interact with the requester"
  },
  requests: {
    pathname: "view",
    title: "Requests",
    description: "A list of requests",
    subpaths: {
      all: {
        pathname: "all",
        title: "All requests",
        description: "All requests for your teams"
      },
      me: {
        pathname: "me",
        title: "Your requests",
        description: "Requests assigned to you"
      },
      unassigned: {
        pathname: "unassigned",
        title: "Unassigned requests",
        description: "Unassigned requests"
      }
    }
  },
  search: {
    pathname: "search",
    title: "Search",
    description: "The request lost and found"
  },
  settings: {
    pathname: "settings",
    title: "Settings",
    description: "Team, user and app settings",
    subpaths: {
      integrations: {
        pathname: "integrations",
        title: "Integrations",
        description: "Install or remove integrations"
      },
      keyboardShortcuts: {
        pathname: "keyboard-shortcuts",
        title: "Keyboard shortcuts",
        description: "Your keyboard shortcut settings"
      },
      notifications: {
        pathname: "notifications",
        title: "Notifications",
        description: "Your notification settings"
      },
      slas: {
        pathname: "slas",
        title: "SLAs",
        description: "Service level agreement administration"
      },
      savedReplies: {
        pathname: "saved-replies",
        title: "Saved replies",
        description: "Your teams' saved replies"
      },
      statuses: {
        pathname: "statuses",
        title: "Request statuses",
        description: "Custom request status administration"
      },
      subscription: {
        pathname: "subscription",
        title: "Subscriptions",
        description: "Subscription administration"
      },
      teams: {
        pathname: "teams",
        title: "Team settings",
        description: "Create, update and remove teams"
      },
      users: {
        pathname: "users",
        title: "User settings",
        description: "Create, update and remove users"
      }
    }
  },
  teamInsights: {
    pathname: "team-insights",
    title: "Team insights",
    description: "Your team insights at a glance"
  },
  workflow: {
    pathname: "workflow",
    title: "Workflow",
    description: "View a workflow"
  },
  workflows: {
    pathname: "workflows",
    title: "Workflows",
    description: "Enable your team with powerful workflow automations"
  }
} as const;

interface Route {
  pathname: string;
  title: string;
  description: string;
}
type RouteObject<S extends string> = Record<S, Route & { subpaths?: Record<string, Route> }>;
export const routeData: RouteObject<keyof typeof _routeData> = _routeData;
