import { css } from "@emotion/core";
import styled from "@emotion/styled";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import { mdiArrowDown, mdiArrowUp, mdiCheck, mdiClose, mdiPlus } from "@mdi/js";
import { ErrorMessage, Field, FieldArray, FieldProps, Form, Formik, FormikValues } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-apollo";
import { BestPracticeSharing } from "src/App/BestPractices/BestPracticeSharing";
import { TextField } from "src/App/Forms/Components";
import { fieldId, fieldName } from "src/App/Forms/helpers";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { GSettingsTeamListGet_teamList } from "src/App/Settings/Teams/typings/GSettingsTeamListGet";
import { ReactComponent as DropdownIndicator } from "src/assets/DropdownIndicator.svg";
import { Button, Checkbox, Col, FormikFieldGroup, LineBreak, Row, SquareButton, Text } from "src/components";
import { MaterialIcon } from "src/components/Icons";
import { PopOver } from "src/components/PopOver";
import { Tooltip } from "src/components/Tooltip";
import { DidMount } from "src/components/Utilities";
import { FieldParams, FieldType, SchemaParams, TextFieldType } from "src/globalTypes";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { Typo } from "src/styling/primitives/typography";
import { keys } from "src/util";
import { csx } from "src/util/csx";
import { useUrlState } from "src/util/router";
import * as yup from "yup";
import { SCHEMA_LIST } from "./Choose";
import { SchemaCreate, SchemaCreateVariables } from "./typings/SchemaCreate";
import { SchemaList_schemaList } from "./typings/SchemaList";
import { SchemaUpdate, SchemaUpdateVariables } from "./typings/SchemaUpdate";

const InputWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 1.5rem 2rem;
  margin: 0.5rem 0;
  border-radius: 1rem;
  color: var(--text-6);
  background: var(--lightGrey-1);

  & label {
    color: var(--text-4);
    margin-bottom: 2rem;
  }

  & button {
    color: var(--text-4);
  }

  & textarea {
    color: var(--text-6);
    border-bottom-color: var(--lightGrey-3);
    padding: 0.25rem 0;

    &::placeholder {
      color: var(--text-2);
    }
  }

  & textarea:focus,
  & textarea:hover {
    color: var(--blue-5);
    border-bottom-color: var(--text-6);
  }

  & .option textarea {
    font-size: 1rem;
  }
`;

const FormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
`;

const displayNameWrapper = css`
  & textarea {
    color: var(--text-6);
    font-family: var(--font-family-body);
    font-size: var(--font-size-display-small);
    line-height: var(--line-height-normal);
    border: none;
    border-bottom: 1px solid transparent;
    padding: 0.25rem 0;
  }
`;

const DisplayNameWrapper = csx([displayNameWrapper], {
  empty: css`
    textarea {
      border-bottom: 1px solid var(--lightGrey-3);
    }
  `
});

export const Completed = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin: 0.5rem 0;
  padding: 1rem;
  border: 1px solid transparent;
  font-size: 0.875rem;
  background: var(--lightGrey-1);
  border-radius: 0.5rem;
  cursor: pointer;
  transition: border-color ease-in-out 0.1s;

  &:hover {
    border: 1px solid var(--lightGrey-3);
  }

  & .label {
    color: var(--text-4);
  }

  & .error {
    color: var(--red-3);
    border-bottom: 1px solid rgba(var(--red-5-raw), 0.5);
  }
`;

const SCHEMA_CREATE = gql`
  mutation SchemaCreate($teamId: ID!, $schema: SchemaParams!) {
    schemaCreate(teamId: $teamId, schema: $schema) {
      code
      success
      message
      object {
        id
      }
    }
  }
`;

const SCHEMA_UPDATE = gql`
  mutation SchemaUpdate($id: ID!, $revision: Int!, $schema: SchemaParams!) {
    schemaUpdate(id: $id, revision: $revision, schema: $schema) {
      code
      success
      message
      object {
        id
      }
    }
  }
`;

enum Selected {
  UNSELECTED = -1,
  NAME = 0,
  TEAM,
  DESCRIPTION,
  FIELDS
}

interface FormTemplate {
  displayName: string;
  description: string;
  fieldsList: InternalField[];
}

type InternalField =
  | {
      id: ReturnType<typeof fieldId>;
      fieldType: FieldType.TEXT_FIELD;
      textFieldType: TextFieldType;
      displayName: string;
      required: boolean;
    }
  | {
      id: ReturnType<typeof fieldId>;
      fieldType: FieldType.DROPDOWN_FIELD;
      displayName: string;
      optionsList: string[];
      required: boolean;
    }
  | {
      id: ReturnType<typeof fieldId>;
      fieldType: FieldType.ATTACHMENT_FIELD;
      displayName: string;
      required: boolean;
    };

const validationSchema = yup.object().shape({
  displayName: yup.string().required("Please provide a name for your form"),
  teamId: yup.string().required("You must select a team for the form"),
  description: yup.string(),
  fields: yup
    .array()
    .of(
      yup.object().shape({
        displayName: yup.string()
      })
    )
    .min(1, "Please provide at least one question")
    .test("Some question must have a title", "Please provide at least one question", function () {
      const fieldsList: InternalField[] = this.parent.fieldsList;
      return fieldsList.some(field => !!field.displayName.trim());
    })
    .test(
      "All dropdowns should have at least one option",
      "Please make sure you provided at least one option for your dropdown questions",
      function () {
        const fieldsList: InternalField[] = this.parent.fieldsList;
        return fieldsList.every(field => !("optionsList" in field) || field.optionsList.length > 0);
      }
    )
});

const schemaTemplate: FormTemplate = {
  displayName: "",
  description: "",
  fieldsList: []
};

const PersistFormChild: React.FC<{
  fieldProps: FieldProps;
}> = props => {
  const [newFormId] = useState(`form:${Date.now()}`);
  const [formStateId, setFormStateId] = useUrlState("formStateId", null);
  const [localValues, setLocalValues] = useLocalStorage<FormikValues | null>(formStateId ?? newFormId, null);

  // initialize url id or set form values from storage (on mount)
  const setValues = props.fieldProps.form.setValues;
  useEffect(() => {
    // no id or storage yet
    if (!formStateId) {
      setFormStateId(newFormId, true);
    }
    // initialize prev form values (when there is already id & values)
    else if (formStateId && localValues) {
      setValues(localValues);
    }
    // only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // store form values if form is "dirty" (!= initial form)
  const formValues = props.fieldProps.form.values;
  const didUpdate = props.fieldProps.form.dirty && formValues && formStateId;
  useEffect(() => {
    if (didUpdate) setLocalValues(formValues);
  }, [didUpdate, setLocalValues, formValues]);
  return null;
};

const PersistForm: React.FC<{}> = () => {
  return <Field>{(fieldProps: FieldProps) => <PersistFormChild fieldProps={fieldProps} />}</Field>;
};

export function CreateEditForm(props: {
  teamId?: string;
  teamList: GSettingsTeamListGet_teamList[];
  existingForm?: SchemaList_schemaList;
  onSuccess(id: string): void;
  onCancel(): void;
  onShareClick?(): void;
}) {
  const { emitSnack } = useSnack();

  const initialFormValue = { ...mapInitialValues(props.existingForm), teamId: props.teamId };
  const isInitialValid = useMemo(() => validationSchema.isValidSync(initialFormValue), [initialFormValue]);
  const [selected, setSelected] = useState(Selected.NAME);

  const [createSchema] = useMutation<SchemaCreate, SchemaCreateVariables>(SCHEMA_CREATE, {
    refetchQueries: [
      {
        query: SCHEMA_LIST,
        variables: {
          teamId: props.teamId
        }
      }
    ]
  });

  const [updateSchema] = useMutation<SchemaUpdate, SchemaUpdateVariables>(SCHEMA_UPDATE, {
    refetchQueries: [
      {
        query: SCHEMA_LIST,
        variables: {
          teamId: props.teamId
        }
      }
    ]
  });

  return (
    <Formik
      initialValues={initialFormValue}
      isInitialValid={isInitialValid}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        if (props.existingForm) {
          const response = await updateSchema({
            variables: {
              id: props.existingForm.id,
              revision: props.existingForm.revision,
              schema: mapSchemaParams(values)
            }
          });
          if (response.data?.schemaUpdate.success && response.data.schemaUpdate.object) {
            props.onSuccess(response.data.schemaUpdate.object.id);
          } else if (response.data?.schemaUpdate.success === false) {
            emitSnack({
              type: "mutationError",
              message: response.data.schemaUpdate.message
            });
          }
        } else {
          const response = await createSchema({
            variables: {
              teamId: values.teamId ?? "",
              schema: mapSchemaParams(values)
            }
          });
          if (response.data?.schemaCreate.success && response.data.schemaCreate.object) {
            props.onSuccess(response.data.schemaCreate.object.id);
            // success toast
          } else if (response.data?.schemaCreate.success === false) {
            emitSnack({
              type: "mutationError",
              message: response.data.schemaCreate.message
            });
          }
        }
      }}
    >
      {({ values, errors, setFieldTouched, setFieldValue, isValid, isSubmitting }) => (
        <FormWrapper
          onClick={() => {
            setSelected(Selected.UNSELECTED);
            const field = values.fieldsList[selected - Selected.FIELDS];
            if (field && field.fieldType === FieldType.DROPDOWN_FIELD) {
              setFieldValue(
                `fieldsList[${selected - Selected.FIELDS}].optionsList`,
                field.optionsList.filter(o => o.trim())
              );
            }
          }}
        >
          <PersistForm />
          <FieldArray name="fieldsList">
            {arrayHelpers => {
              const addQuestion = () =>
                arrayHelpers.push({
                  id: fieldId(FieldType.TEXT_FIELD, TextFieldType.LONG_TEXT),
                  name: fieldName(FieldType.TEXT_FIELD, TextFieldType.LONG_TEXT),
                  fieldType: FieldType.TEXT_FIELD,
                  textFieldType: TextFieldType.LONG_TEXT,
                  displayName: "",
                  required: false
                } as InternalField);
              const swapFields = (index1: number, index2: number) => {
                const [field1, field2] = [values.fieldsList[index1], values.fieldsList[index2]];
                setFieldValue(`fieldsList[${index1}]`, field2);
                setFieldValue(`fieldsList[${index2}]`, field1);
                setSelected(Selected.FIELDS + index2);
              };
              return (
                <>
                  <Col>
                    <DisplayNameWrapper empty={!values.displayName.trim()}>
                      <MgmtTextField
                        name="displayName"
                        css={css`
                          margin: 0;
                        `}
                        autoFocus={selected === Selected.NAME}
                        placeholder="Name your form"
                        onKeyDown={e => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            if (values.displayName.trim()) {
                              setSelected(Selected.DESCRIPTION);
                            }
                          }
                        }}
                      />
                    </DisplayNameWrapper>
                    <Row margin="0.75rem 0 3rem 0" fontSize="0.875rem">
                      You're {props.existingForm ? "editing" : "creating"} a form for the{" "}
                      {(props.teamList.find(t => t.id === props.teamId) || { name: "" }).name} team
                    </Row>
                  </Col>
                  <Col flex="1 1 auto">
                    <Col flex="0 0 auto">
                      {selected === Selected.DESCRIPTION || !values.description.trim() ? (
                        <>
                          {selected === Selected.DESCRIPTION && (
                            <ClickAwayListener
                              onClickAway={e => {
                                setSelected(Selected.UNSELECTED);
                              }}
                            >
                              <SetDescription
                                selected={selected}
                                setSelected={setSelected}
                                setTouched={() => setFieldTouched("description")}
                                onConfirm={() => {
                                  if (values.description.trim()) {
                                    if (values.fieldsList.length === 0) {
                                      addQuestion();
                                      setSelected(Selected.FIELDS + values.fieldsList.length);
                                    } else {
                                      setSelected(Selected.UNSELECTED);
                                    }
                                  }
                                }}
                              />
                            </ClickAwayListener>
                          )}
                          {selected !== Selected.DESCRIPTION && (
                            <SetDescription
                              selected={selected}
                              setSelected={setSelected}
                              setTouched={() => setFieldTouched("description")}
                              onConfirm={() => {
                                if (values.description.trim()) {
                                  if (values.fieldsList.length === 0) {
                                    addQuestion();
                                    setSelected(Selected.FIELDS + values.fieldsList.length);
                                  } else {
                                    setSelected(Selected.UNSELECTED);
                                  }
                                }
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <Completed
                          css={css`
                            margin: 0 0 1rem 0;
                          `}
                          onClick={e => {
                            e.stopPropagation();
                            setSelected(Selected.DESCRIPTION);
                            const field = values.fieldsList[selected - Selected.FIELDS];
                            if (field && field.fieldType === FieldType.DROPDOWN_FIELD) {
                              setFieldValue(
                                `fieldsList[${selected - Selected.FIELDS}].optionsList`,
                                field.optionsList.filter(o => o.trim())
                              );
                            }
                          }}
                        >
                          <Col flex="1 1 auto">
                            <Row className="label">Intro message visible in chat and email</Row>
                            <Typo.Body
                              css={css`
                                margin-top: var(--space-2-rem);
                              `}
                            >
                              <LineBreak text={values.description} />
                            </Typo.Body>
                            {!values.description.trim() && <Row className="error" />}
                          </Col>
                        </Completed>
                      )}
                      {values.fieldsList.map((field, i) =>
                        selected === Selected.FIELDS + i ? (
                          <div onClick={e => e.stopPropagation()}>
                            <FormInputEditor
                              key={i}
                              index={i}
                              value={field}
                              setFieldType={(fieldType, textFieldType) => {
                                if (fieldType === FieldType.DROPDOWN_FIELD) {
                                  setFieldValue(`fieldsList[${i}]`, {
                                    id: fieldId(fieldType),
                                    name: fieldName(fieldType),
                                    fieldType,
                                    displayName: field.displayName,
                                    optionsList: [],
                                    required: field.required
                                  } as InternalField);
                                } else {
                                  setFieldValue(`fieldsList[${i}]`, {
                                    id: fieldId(fieldType, textFieldType),
                                    name: fieldName(fieldType, textFieldType),
                                    fieldType,
                                    textFieldType,
                                    displayName: field.displayName,
                                    required: field.required
                                  } as InternalField);
                                }
                              }}
                              setOptions={options => {
                                setFieldValue(`fieldsList[${i}].optionsList`, options);
                              }}
                              setRequired={required => {
                                setFieldValue(`fieldsList[${i}]`, {
                                  ...field,
                                  required
                                });
                              }}
                              setTouched={() => setFieldTouched(`fieldsList[${i}].displayName`)}
                              onConfirm={() => {
                                const field = values.fieldsList[selected - Selected.FIELDS];
                                if (field && field.fieldType === FieldType.DROPDOWN_FIELD) {
                                  setFieldValue(
                                    `fieldsList[${selected - Selected.FIELDS}].optionsList`,
                                    field.optionsList.filter(o => o.trim())
                                  );
                                }
                                if (field.displayName.trim()) {
                                  if (i + 1 === values.fieldsList.length) {
                                    addQuestion();
                                    setSelected(Selected.FIELDS + values.fieldsList.length);
                                  } else {
                                    setSelected(Selected.UNSELECTED);
                                  }
                                }
                              }}
                              onCancel={() => {
                                setSelected(Selected.UNSELECTED);
                                arrayHelpers.remove(i);
                              }}
                              moveDown={i < values.fieldsList.length - 1 ? () => swapFields(i, i + 1) : null}
                              moveUp={i > 0 ? () => swapFields(i, i - 1) : null}
                            />
                          </div>
                        ) : (
                          <Completed
                            key={i}
                            onClick={e => {
                              e.stopPropagation();
                              setSelected(Selected.FIELDS + i);
                              const field = values.fieldsList[selected - Selected.FIELDS];
                              if (field && field.fieldType === FieldType.DROPDOWN_FIELD) {
                                setFieldValue(
                                  `fieldsList[${selected - Selected.FIELDS}].optionsList`,
                                  field.optionsList.filter(o => o.trim())
                                );
                              }
                            }}
                          >
                            <Row className="label" margin="0 0.625rem 0 0">
                              {`${i + 1}`.padStart(2, "0")}.
                            </Row>
                            <Col flex="1 1 auto">
                              {field.displayName.trim() && (
                                <div
                                  css={css`
                                    display: flex;
                                    justify-content: space-between;
                                  `}
                                >
                                  <Typo.Body>
                                    <LineBreak text={field.displayName} asterisk={field.required} />
                                  </Typo.Body>
                                  <Typo.Body lighter>
                                    {fieldName(
                                      field.fieldType,
                                      "textFieldType" in field ? field.textFieldType : undefined
                                    )}
                                  </Typo.Body>
                                </div>
                              )}
                              {field.fieldType === FieldType.DROPDOWN_FIELD &&
                                field.optionsList.map((option, j) => (
                                  <Row key={j} margin={j === 0 ? "0.5rem 0 0.25rem 0" : "0.25rem 0"}>
                                    <Text
                                      lineHeight="unset"
                                      css={css`
                                        color: var(--text-4);
                                      `}
                                    >
                                      {charOfIndex(j)}.
                                    </Text>
                                    &nbsp;{option}
                                  </Row>
                                ))}
                            </Col>
                            {!field.displayName.trim() && <Row className="error" flex="1 0 auto" />}
                          </Completed>
                        )
                      )}
                    </Col>
                    <Row align="center" margin="0.5rem 0 1rem 1.375rem">
                      <Button
                        type="button"
                        size="medium"
                        onClick={e => {
                          e.stopPropagation();
                          addQuestion();
                          setSelected(Selected.FIELDS + values.fieldsList.length);
                        }}
                      >
                        <Row margin="0 -3.75px" align="center">
                          <MaterialIcon path={mdiPlus} size={1.125} margin="0 0.25rem 0 0" padding="0 0 0 -7.5px" />
                        </Row>
                        Add question
                      </Button>
                    </Row>
                    {props.existingForm && props.onShareClick && <BestPracticeSharing onClick={props.onShareClick} />}
                  </Col>
                  <Col justify="flex-end" flex="1 0 auto">
                    <Row justify="flex-end">
                      <Button
                        type="button"
                        size="large"
                        margin="0 0.25rem 0 0"
                        onClick={props.onCancel}
                        disabled={isSubmitting}
                      >
                        Go back
                      </Button>
                      <Tooltip
                        css={[
                          css`
                            z-index: var(--z-highest);
                          `
                        ]}
                        target={
                          <Button
                            variant="primary"
                            // use class name since disabled attr blocks tooltip
                            className={!isValid || isSubmitting ? "disabled" : undefined}
                            size="large"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Done
                          </Button>
                        }
                      >
                        {!keys(errors).length ? null : keys(errors).map(k => <div key={k}>{errors[k]}</div>)}
                      </Tooltip>
                    </Row>
                  </Col>
                </>
              );
            }}
          </FieldArray>
        </FormWrapper>
      )}
    </Formik>
  );
}

function SetDescription(props: {
  setTouched(): void;
  selected: number;
  setSelected(selected: Selected): void;
  onConfirm(): void;
}) {
  return (
    <InputWrapper onClick={e => e.stopPropagation()}>
      {/* stop click event bubble to prevent unwanted deselect */}

      <DidMount callback={() => props.setTouched()} />
      <MgmtTextField
        css={css`
          margin: 0 0 1.5rem;
        `}
        key={props.selected}
        name="description"
        multiline={true}
        autoFocus={props.selected === Selected.DESCRIPTION}
        label="Pre-define a message to be shared with the employee together with the form:"
        placeholder="Hi there, we need to know a couple of things before..."
        onFocus={() => props.setSelected(Selected.DESCRIPTION)}
        onKeyDown={e => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            props.onConfirm();
          }
        }}
      />
      <Row justify="flex-end" margin="1.5rem 0 0 0">
        <SquareButton variant="primary" size="medium" type="submit" onClick={props.onConfirm}>
          <MaterialIcon path={mdiCheck} size={1.125} />
        </SquareButton>
      </Row>
    </InputWrapper>
  );
}

function FormInputEditor(props: {
  index: number;
  value: InternalField;
  setFieldType(fieldType: FieldType, textFieldType?: TextFieldType): void;
  setOptions(options: string[]): void;
  setTouched(): void;
  setRequired(required: boolean): void;
  onConfirm(): void;
  onCancel(): void;
  moveUp: (() => void) | null;
  moveDown: (() => void) | null;
}) {
  const { index, value, setFieldType, setRequired } = props;
  const hasUnsavedOptions = value.fieldType === FieldType.DROPDOWN_FIELD && value.optionsList.some(o => o.trim());
  const setFieldTypeText = (textFieldType: TextFieldType) => () => {
    if (!hasUnsavedOptions || window.confirm("Are you sure want to proceed and lose the options you entered?")) {
      setFieldType(FieldType.TEXT_FIELD, textFieldType);
    }
  };
  const questionTypeOptions: {
    id: ReturnType<typeof fieldId>;
    name: string;
    onClick: () => void;
  }[] = [
    {
      id: fieldId(FieldType.TEXT_FIELD, TextFieldType.SHORT_TEXT),
      name: fieldName(FieldType.TEXT_FIELD, TextFieldType.SHORT_TEXT),
      onClick: setFieldTypeText(TextFieldType.SHORT_TEXT)
    },
    {
      id: fieldId(FieldType.TEXT_FIELD, TextFieldType.LONG_TEXT),
      name: fieldName(FieldType.TEXT_FIELD, TextFieldType.LONG_TEXT),
      onClick: setFieldTypeText(TextFieldType.LONG_TEXT)
    },
    {
      id: fieldId(FieldType.TEXT_FIELD, TextFieldType.EMAIL),
      name: fieldName(FieldType.TEXT_FIELD, TextFieldType.EMAIL),
      onClick: setFieldTypeText(TextFieldType.EMAIL)
    },
    {
      id: fieldId(FieldType.TEXT_FIELD, TextFieldType.URL),
      name: fieldName(FieldType.TEXT_FIELD, TextFieldType.URL),
      onClick: setFieldTypeText(TextFieldType.URL)
    },
    {
      id: fieldId(FieldType.TEXT_FIELD, TextFieldType.NUMBER),
      name: fieldName(FieldType.TEXT_FIELD, TextFieldType.NUMBER),
      onClick: setFieldTypeText(TextFieldType.NUMBER)
    },
    {
      id: fieldId(FieldType.TEXT_FIELD, TextFieldType.DATE),
      name: fieldName(FieldType.TEXT_FIELD, TextFieldType.DATE),
      onClick: setFieldTypeText(TextFieldType.DATE)
    },
    {
      id: fieldId(FieldType.DROPDOWN_FIELD),
      name: fieldName(FieldType.DROPDOWN_FIELD),
      onClick: () => setFieldType(FieldType.DROPDOWN_FIELD)
    },
    {
      id: fieldId(FieldType.ATTACHMENT_FIELD),
      name: fieldName(FieldType.ATTACHMENT_FIELD),
      onClick: () => setFieldType(FieldType.ATTACHMENT_FIELD)
    }
  ];
  return (
    <InputWrapper>
      <FieldArray name={`fieldsList[${index}].optionsList`}>
        {arrayHelpers => (
          <React.Fragment key={`helper-${index}`}>
            <DidMount callback={props.setTouched} />
            <Row justify="space-between" align="center">
              <Text
                css={css`
                  color: var(--text-4);
                `}
              >
                Question {index + 1}
              </Text>

              <PopOver.Menu
                css={[
                  css`
                    z-index: var(--z-highest);
                  `
                ]}
                selected={value.id}
                options={questionTypeOptions}
                trigger={
                  <Button type="button" style={{ height: "2.25rem", padding: "0 0.75rem" }}>
                    {questionTypeOptions.find(o => o.id === value.id)?.name}{" "}
                    <DropdownIndicator style={{ transform: "scale(1.125)", margin: "0 0 0 0.5rem" }} />
                  </Button>
                }
              />
            </Row>
            <Row margin="2.5rem 0">
              <MgmtTextField
                multiline
                name={`fieldsList[${index}].displayName`}
                autoFocus={true}
                onKeyDown={e => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    if (value.fieldType === FieldType.TEXT_FIELD) {
                      props.onConfirm();
                    } else {
                      arrayHelpers.push("");
                    }
                  }
                }}
              />
            </Row>
            {value.fieldType === FieldType.DROPDOWN_FIELD && value.optionsList && (
              <Col>
                {value.optionsList.map((option, i) => (
                  <Row key={i} align="center" margin="1.5rem 0" className="option">
                    <Text
                      fontSize="1rem"
                      lineHeight="1.5"
                      margin="0 0.5rem 0 0"
                      css={css`
                        color: var(--text-4);
                      `}
                    >
                      {charOfIndex(i)}.
                    </Text>
                    <MgmtTextField
                      key={value.fieldType === FieldType.DROPDOWN_FIELD ? value.optionsList.length : ""}
                      name={`fieldsList[${index}].optionsList.${i}`}
                      autoFocus={true}
                      onKeyDown={e => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                        if (
                          e.key === "Enter" &&
                          value.fieldType === FieldType.DROPDOWN_FIELD &&
                          value.optionsList?.[i].trim()
                        ) {
                          arrayHelpers.push("");
                        } else if (
                          e.key === "Backspace" &&
                          value.fieldType === FieldType.DROPDOWN_FIELD &&
                          value.optionsList?.[i] === ""
                        ) {
                          e.preventDefault();
                          arrayHelpers.remove(i);
                        }
                      }}
                    />
                  </Row>
                ))}
                <Row align="center" margin="1.5rem 0" className="option">
                  <Text
                    fontSize="1rem"
                    lineHeight="1.5"
                    margin="0 0.5rem 0 0"
                    css={css`
                      color: var(--text-4);
                    `}
                  >
                    {charOfIndex(value.optionsList.length)}.
                  </Text>
                  <MgmtTextField
                    name="newQuestion"
                    placeholder="Add a new option"
                    onFocus={() => arrayHelpers.push("")}
                  />
                </Row>
              </Col>
            )}
            <Row justify="space-between" margin="1.5rem 0 0 0">
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                `}
              >
                <Checkbox
                  id="required"
                  checked={value.required}
                  onChange={() => {
                    setRequired(!value.required);
                  }}
                />
                <label htmlFor="required">Required</label>
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  & > * + * {
                    margin-right: var(--space-1-rem);
                  }
                `}
              >
                <Tooltip
                  placement="top"
                  css={[
                    css`
                      z-index: var(--z-highest);
                    `
                  ]}
                  target={
                    <SquareButton
                      variant="ghost"
                      size="medium"
                      onClick={() => {
                        props.moveUp?.();
                      }}
                      disabled={!props.moveUp}
                    >
                      <MaterialIcon path={mdiArrowUp} size={1.125} />
                    </SquareButton>
                  }
                >
                  Move question up
                </Tooltip>
                <Tooltip
                  css={[
                    css`
                      z-index: var(--z-highest);
                    `
                  ]}
                  target={
                    <SquareButton
                      variant="ghost"
                      size="medium"
                      onClick={() => {
                        props.moveDown?.();
                      }}
                      disabled={!props.moveDown}
                    >
                      <MaterialIcon path={mdiArrowDown} size={1.125} />
                    </SquareButton>
                  }
                >
                  Move question down
                </Tooltip>
                <SquareButton
                  variant="primary"
                  size="medium"
                  css={css`
                    margin-left: var(--space-3-rem);
                  `}
                  onClick={() => {
                    if (
                      !hasUnsavedOptions ||
                      window.confirm("Are you sure want to proceed and lose the options you entered?")
                    ) {
                      props.onCancel();
                    }
                  }}
                >
                  <MaterialIcon path={mdiClose} size={1.125} />
                </SquareButton>
                <SquareButton
                  variant="primary"
                  size="medium"
                  onClick={() => {
                    props.onConfirm();
                  }}
                >
                  <MaterialIcon path={mdiCheck} size={1.125} />
                </SquareButton>
              </div>
            </Row>
          </React.Fragment>
        )}
      </FieldArray>
    </InputWrapper>
  );
}

function mapInitialValues(schema?: SchemaList_schemaList): FormTemplate {
  return !schema
    ? schemaTemplate
    : {
        displayName: schema.displayName,
        description: schema.description,
        fieldsList: schema.fieldsList.map(f => {
          if (f.__typename === "DropdownField") {
            return {
              id: fieldId(FieldType.DROPDOWN_FIELD),
              fieldType: FieldType.DROPDOWN_FIELD,
              displayName: f.displayName,
              optionsList: f.optionsList,
              required: f.required
            };
          } else if (f.__typename === "TextField") {
            return {
              id: fieldId(FieldType.TEXT_FIELD, f.textFieldType),
              fieldType: FieldType.TEXT_FIELD,
              textFieldType: f.textFieldType,
              displayName: f.displayName,
              required: f.required
            };
          } else if (f.__typename === "AttachmentsField") {
            return {
              id: fieldId(FieldType.ATTACHMENT_FIELD),
              fieldType: FieldType.ATTACHMENT_FIELD,
              displayName: f.displayName,
              required: f.required
            };
          } else {
            throw new Error("Unknown field type");
          }
        })
      };
}

// map params for graphql submission (only include known fields)
function mapSchemaParams(values: FormTemplate): SchemaParams {
  return {
    displayName: values.displayName,
    description: values.description,
    fieldsList: values.fieldsList
      .filter(f => f.displayName.trim())
      .map(f => {
        const fieldParams: FieldParams = {
          fieldType: f.fieldType,
          displayName: f.displayName,
          required: !!f.required // force the required field to be set
        };
        if (f.fieldType === FieldType.DROPDOWN_FIELD) {
          fieldParams.optionsList = f.optionsList.filter(o => o.trim());
        }
        if (f.fieldType === FieldType.TEXT_FIELD) {
          fieldParams.textFieldType = f.textFieldType;
        }
        return fieldParams;
      })
  };
}

export function charOfIndex(index: number) {
  return String.fromCharCode("a".charCodeAt(0) + index);
}

/** Formik Field TextField for form mgmt */
function MgmtTextField(props: {
  name: string;
  required?: boolean;
  textFieldType?: TextFieldType;
  placeholder?: string;
  label?: string;
  className?: string;
  multiline?: boolean;
  autoFocus?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  onFocus?: () => void;
}) {
  return (
    <Field required={props.required} name={props.name}>
      {(fieldProps: FieldProps) => (
        <>
          <TextField
            className={props.className}
            name={props.name}
            label={props.label}
            placeholder={props.placeholder}
            multiline={props.multiline}
            autoFocus={props.autoFocus}
            onKeyDown={e => {
              if (props.onKeyDown) {
                props.onKeyDown(e);
              }
            }}
            value={fieldProps.field.value}
            onChange={fieldProps.field.onChange}
            onBlur={fieldProps.field.onBlur}
            onFocus={props.onFocus}
          />
          <FormikFieldGroup.Errors>
            <ErrorMessage name={props.name} />
          </FormikFieldGroup.Errors>
        </>
      )}
    </Field>
  );
}
