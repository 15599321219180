import styled from "@emotion/styled";
import * as React from "react";
import { MutationFunction } from "react-apollo";
import { FeatureFlags, useFeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import { UserName } from "src/App/User";
import { Checkbox, Col, ConfirmDialogModal, Menu, MenuItem, Row, SplitSubmitButton } from "src/components";
import { ReplyType } from "src/globalTypes";
import { PostCommentButtonProps } from "./PostComment";
import { GRequestAddComment } from "./typings/GRequestAddComment";

const MessageWrapper = styled.div`
  padding: 1rem;
  margin: 1rem 0 0;
  background: var(--lightGrey-1);
  border-radius: 0.875rem;
  max-width: 35rem;
`;

export function ReplyButtonWithPopoverMenu(
  props: PostCommentButtonProps & {
    postComment: MutationFunction<GRequestAddComment>;
  }
) {
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [sendToRequester, setSendToRequester] = React.useState(true);
  const { hasFeatureFlags } = useFeatureFlags();
  const swapFunctions = hasFeatureFlags(FeatureFlags.SWAPPUBLICREPLY);
  const postPrivateComment = () => {
    props.postComment({
      variables: {
        params: {
          ...props.params,
          replyType: ReplyType.DEFAULT
        }
      }
    });
  };
  const handleSubmit = swapFunctions ? () => setDialogVisible(true) : () => postPrivateComment();
  props.submitHandlerRef.current = handleSubmit;
  return (
    <>
      <SplitSubmitButton
        variant="green"
        size="medium"
        disabled={props.disabled}
        label={swapFunctions ? "Send public reply" : "Send reply"}
        margin="0 0 0 .25rem"
        data-intercom-target="Send public reply"
        onClick={handleSubmit}
        data-testid="submit-reply-button"
      >
        <Menu margin="0" padding="0.375rem 0">
          <MenuItem onClick={swapFunctions ? () => postPrivateComment() : () => setDialogVisible(true)}>
            {swapFunctions ? "Send reply" : "Send public reply"}
          </MenuItem>
        </Menu>
      </SplitSubmitButton>
      <ConfirmDialogModal
        isOpen={dialogVisible}
        text={{
          confirm: "Confirm",
          cancel: "Cancel",
          heading: "Confirm before sending"
        }}
        buttonVariant="green"
        handleCancel={() => setDialogVisible(false)}
        handleConfirm={() =>
          props.postComment({
            variables: {
              params: {
                ...props.params,
                replyType: sendToRequester ? ReplyType.BOTH : ReplyType.PUBLIC
              }
            }
          })
        }
      >
        <Col fontSize="0.875rem" margin="-1.5rem 0 0 0">
          <Row>
            You are about to send your reply to channel&nbsp;<strong>#{props.origin?.channel.name}</strong>.
          </Row>
          {props.hasAttachments && (
            <Row margin="0.25rem 0 0">Attachments added to the reply will also be sent to the public channel</Row>
          )}
          {props.params.text && <MessageWrapper>{props.params.text}</MessageWrapper>}
          <Row margin="0.75rem 0 0">
            <Checkbox
              id="sendToRequester"
              checked={sendToRequester}
              onChange={() => {
                setSendToRequester(!sendToRequester);
              }}
            />
            <label htmlFor="sendToRequester">
              Also send to <UserName userId={props.requesterId} />
            </label>
          </Row>
        </Col>
      </ConfirmDialogModal>
    </>
  );
}
