import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { mdiAlert, mdiClose, mdiPaperclip } from "@mdi/js";
import * as React from "react";
import { FileStatus, IFiles, TFileAction } from "src/App/Attachments/reducer";
import { Button, MaterialIcon, Row } from "src/components";
import { Typo } from "src/styling/primitives/typography";
import { fontSizes } from "src/styling/typography";
import { csx } from "src/util/csx";
import { fileSize } from "src/util/formatters";

const FileStackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  border-bottom: 1px solid var(--text-1);
  border-radius: var(--border-radius-s);
`;

const statusBar = css`
  display: flex;
  flex: 0 0 auto;
  background: var(--box);
  height: 2.375rem;
  border-radius: var(--border-radius-s);

  & ${/* sc-selector */ Row}.file {
    position: relative;
    overflow: hidden;
    color: var(--text-6);
  }

  &.error ${/* sc-selector */ Row}.file {
    color: var(--red-7);
  }

  & .file > span {
    flex: 1 1 auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const statusBarError = css`
  & .${Row}.file {
    color: var(--red-7);
  }
`;

const StatusBar = csx([statusBar], {
  error: statusBarError,
  spaced: css`
    margin-top: var(--space-2-rem);
  `
});

const FileSize = styled.span`
  color: var(--text-3);
  margin-left: 0.375rem;
  font-size: ${fontSizes.default};
  /* sorry did not want to re-write all of the component states */
  flex-shrink: 0 !important;
`;

interface UploadBarProps {
  percent: number;
}

const UploadBar = styled.div<UploadBarProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${p => Math.floor(p.percent)}%;
  color: var(--text-6);
  background: var(--lightBlue-3);
  white-space: nowrap;
  overflow: hidden;
  font-size: ${fontSizes.default};
`;

/**
 * List pending files
 * Displays processing, uploading progress, failed etc.
 */
export function FileStack(props: { filesState: IFiles; filesDispatch: React.Dispatch<TFileAction>; spaced?: boolean }) {
  return !props.filesState.files.length ? null : (
    <FileStackWrapper
      css={
        props.spaced &&
        css`
          border-bottom: none;
        `
      }
    >
      {props.filesState.files.map((file, i) => (
        <React.Fragment key={i}>
          {file.status !== FileStatus.UNSET && (
            <StatusBar
              spaced={props.spaced}
              error={![FileStatus.PROCESSING, FileStatus.UPLOADING, FileStatus.UPLOADED].includes(file.status)}
            >
              <Row className="file" flex="1 1 auto" align="center" padding="0.25rem 0.25rem 0.25rem 0.5rem">
                <CurrentFileStatus file={file} filesDispatch={props.filesDispatch} />
              </Row>
            </StatusBar>
          )}
        </React.Fragment>
      ))}
    </FileStackWrapper>
  );
}

interface IFileStatusProps {
  file: IFiles["files"][0];
  filesDispatch: React.Dispatch<TFileAction>;
}

/**
 * Current file status list item
 */
function CurrentFileStatus(props: IFileStatusProps) {
  switch (props.file.status) {
    case FileStatus.UNSET:
      return null;
    case FileStatus.PROCESSING:
      return <Processing {...props} />;
    case FileStatus.MAX_SIZE_EXCEEDED:
      return <MaxSizeExceeded {...props} />;
    case FileStatus.UPLOADING:
      return <Uploading {...props} />;
    case FileStatus.UPLOADED:
      return <Uploaded {...props} />;
    case FileStatus.FAILED:
      return <Failed {...props} />;
    default:
      throw new Error("Unknow file status");
  }
}

function Processing(props: IFileStatusProps) {
  return (
    <>
      <span>
        Processing {props.file.name}...
        <FileSize>({fileSize(props.file.sizeBytes)})</FileSize>
      </span>
      <Row flex="1 0 auto" justify="flex-end" align="center">
        <Button
          onClick={() => {
            props.filesDispatch({
              type: "REMOVE_FILE",
              id: props.file.id
            });
          }}
        >
          <MaterialIcon path={mdiClose} size={1.125} />
        </Button>
      </Row>
    </>
  );
}

function MaxSizeExceeded(props: IFileStatusProps) {
  return (
    <>
      <MaterialIcon className="status-icon" path={mdiAlert} size={1.125} margin="0 0.25rem 0 0" />
      <span>
        {props.file.name} <FileSize>({fileSize(props.file.sizeBytes)})</FileSize> exceeds{" "}
        {fileSize(Number(process.env.REACT_APP_MAX_ATTACHMENT_SIZE))}
      </span>
      <Row flex="1 0 auto" justify="flex-end" align="center">
        <Button
          onClick={() => {
            props.filesDispatch({
              type: "REMOVE_FILE",
              id: props.file.id
            });
          }}
        >
          <MaterialIcon path={mdiClose} size={1.125} />
        </Button>
      </Row>
    </>
  );
}

function Uploading(props: IFileStatusProps) {
  return (
    <>
      <UploadBar percent={props.file.uploadPercent}>
        <Row align="center" padding="0.25rem 0.25rem 0.25rem 0.5rem" height="100%">
          <span>
            Uploading {props.file.name}...
            <FileSize>({fileSize(props.file.sizeBytes)})</FileSize>
          </span>
        </Row>
      </UploadBar>
      <span>
        Uploading {props.file.name}...
        <FileSize>({fileSize(props.file.sizeBytes)})</FileSize>
      </span>
      <Row flex="1 0 auto" justify="flex-end" align="center">
        <Row className="percent" align="center">
          {Math.round(props.file.uploadPercent)}%
        </Row>
      </Row>
    </>
  );
}

function Uploaded(props: IFileStatusProps) {
  return (
    <>
      <MaterialIcon className="status-icon" path={mdiPaperclip} size={1.125} margin="0 0.125rem 0 0" />
      <Typo.Body
        ellipsis
        css={css`
          flex: 0 1 auto;
        `}
      >
        {props.file.name}
      </Typo.Body>
      <FileSize>({fileSize(props.file.sizeBytes)})</FileSize>
      <Row flex="1 0 auto" justify="flex-end" align="center">
        <Button
          onClick={() => {
            props.filesDispatch({
              type: "REMOVE_FILE",
              id: props.file.id
            });
          }}
        >
          <MaterialIcon path={mdiClose} size={1.125} />
        </Button>
      </Row>
    </>
  );
}

function Failed(props: IFileStatusProps) {
  return (
    <>
      <MaterialIcon className="status-icon" path={mdiAlert} size={1.125} margin="0 0.25rem 0 0" />
      <span>
        {props.file.name}
        <FileSize>({fileSize(props.file.sizeBytes)})</FileSize>
      </span>
      <Row flex="1 0 auto" justify="flex-end" align="center">
        <Button
          onClick={() => {
            props.filesDispatch({
              type: "REMOVE_FILE",
              id: props.file.id
            });
          }}
        >
          <MaterialIcon path={mdiClose} size={1.125} />
        </Button>
      </Row>
    </>
  );
}
