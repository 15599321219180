import { css } from "@emotion/core";
import * as React from "react";
import { FC, useLayoutEffect, useRef } from "react";
import { Col } from "src/components";
import { csx } from "src/util/csx";

const sharedInputStyles = css`
  box-sizing: border-box;
  flex: 1 0 auto;
  font-family: inherit;
  font-size: 1.25rem;
  line-height: var(--line-height-dense);
  color: var(--text-6);
  background: none;
  border: none;
  outline: none;
  resize: none;
  border-bottom: 1px solid var(--text-1);
  white-space: nowrap;

  &:hover,
  &:focus {
    &:not([disabled]) {
      color: var(--text-6);
      border-bottom: 1px solid var(--text-6);
    }
  }

  &::placeholder {
    color: var(--text-2);
  }
`;

const MIN_TEXTAREA_HEIGHT = 32;
const InternalTextarea = csx(
  [
    sharedInputStyles,
    css`
      height: ${MIN_TEXTAREA_HEIGHT}px;
    `
  ],
  {
    multiline: css`
      white-space: pre-wrap;
    `,
    hasError: css`
      border-bottom: 1px solid var(--red-7);
      color: var(--red-7);
    `
  },
  "textarea"
);

/** Common input props used across our number and textarea inputs */
interface CommonInputProps {
  value: string;
  name?: string;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  className?: string;
  onChange(e: React.ChangeEvent<HTMLElement>): void;
  onBlur?(e: React.FocusEvent<HTMLElement>): void;
  onFocus?(e: React.FocusEvent<HTMLElement>): void;
  onKeyDown?(event: React.KeyboardEvent<HTMLElement>): void;
}

export function TextField(
  props: CommonInputProps & {
    label?: string;
    multiline?: boolean;
    rowsMax?: number;
    margin?: string;
    hasError?: boolean;
  }
) {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const { multiline, value } = props;

  useLayoutEffect(() => {
    if (multiline && textareaRef && textareaRef.current) {
      // Reset height - important to shrink on delete
      textareaRef.current.style.height = "inherit";
      // Set height
      textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight, MIN_TEXTAREA_HEIGHT)}px`;
    }
  }, [multiline, value]);

  return (
    <div
      className={props.className}
      css={[
        css`
          display: flex;
          flex: 1 1 auto;
          margin: ${props.margin};
        `
      ]}
    >
      <Col flex="1 0 auto">
        {props.label && <label>{props.label}</label>}
        <InternalTextarea
          name={props.name}
          placeholder={props.placeholder}
          hasError={props.hasError}
          autoFocus={props.autoFocus}
          disabled={props.disabled}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onKeyDown={props.onKeyDown}
          multiline={!!props.multiline}
          value={props.multiline ? props.value : props.value?.replace("\n", "")}
          /* Needed to keep scroll height from increasing for a single line */
          rows={1}
          spellCheck={true}
          onFocus={e => {
            const val = e.target.value;
            e.target.value = "";
            e.target.value = val;
            if (props.onFocus) {
              props.onFocus(e);
            }
          }}
          ref={textareaRef}
        />
      </Col>
    </div>
  );
}

const NumberInput = csx(
  [
    sharedInputStyles,
    css`
      &[type="number"] {
        /* Disable number field indicators in Firefox */
        -moz-appearance: textfield;
      }
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        /* Disable number field indicators in Chrome/Safari */
        -webkit-appearance: none;
        margin: 0;
      }
    `
  ],
  {
    hasError: css`
      border-bottom: 1px solid var(--red-7);
      color: var(--red-7);
    `
  },
  "input"
);

export const NumberField: FC<
  CommonInputProps & {
    hasError: boolean;
  }
> = props => (
  <div
    className={props.className}
    css={[
      css`
        display: flex;
        flex: 1 1 auto;
      `
    ]}
  >
    <Col flex="1 0 auto">
      <NumberInput
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
        hasError={props.hasError}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={e => {
          const val = e.target.value;
          e.target.value = "";
          e.target.value = val;
          if (props.onFocus) {
            props.onFocus(e);
          }
        }}
        inputMode="numeric"
        lang={navigator.language}
      />
    </Col>
  </div>
);
