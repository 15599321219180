import { css } from "@emotion/core";
import { PopperPlacementType } from "@material-ui/core/Popper";
import { differenceInCalendarDays, startOfDay } from "date-fns";
import isEqual from "lodash/isEqual";
import React, { useState } from "react";
import DayPicker, { DateUtils, RangeModifier } from "react-day-picker";
import { Button, PillButton } from "src/components";
import { PopOver } from "src/components/PopOver";
import zIndices from "src/styling/tokens/z-indices.json";
import { trackCalendarRangeAction } from "src/util/analytics";
import { csx } from "src/util/csx";
import "./day-picker.css";

const emptyRange = {
  from: undefined,
  to: undefined
  /* missing from type def */
} as unknown as RangeModifier;

const DayPickerWrapper = csx([
  css`
    margin-top: var(--space-1-rem);
    padding: var(--space-6-rem);
    background: var(--white);
    border-radius: var(--border-radius-xl);
    box-shadow: var(--box-shadow-card);
  `
]);

const applyButtonId = "apply-button";

export const DateRangePicker: React.FC<{
  button: JSX.Element;
  rangeButtons: {
    range: RangeModifier;
    label: string;
  }[];
  value: RangeModifier;
  onChange: (value: RangeModifier) => void;
  maxDate?: Date;
  placement?: PopperPlacementType;
}> = props => {
  const [range, setRange] = useState<RangeModifier>(props.value);
  const [pickerKey, setPickerKey] = useState("");
  const isMultipleDaysSelected = range.from && range.to && Math.abs(differenceInCalendarDays(range.from, range.to)) > 0;
  return (
    <PopOver.Blank placement={props.placement} zIndex={zIndices.high.value} button={props.button}>
      <DayPickerWrapper
        onClick={e => {
          // propogate apply click event to close popover
          if ((e.target as HTMLElement).id !== applyButtonId) e.stopPropagation();
        }}
      >
        <DayPicker
          // key reset sets view to current month
          key={pickerKey}
          className={isMultipleDaysSelected ? "has-range" : ""}
          numberOfMonths={2}
          toMonth={props.maxDate ?? new Date()}
          selectedDays={[range.from ?? undefined, range]}
          disabledDays={[{ after: props.maxDate ?? new Date() }]}
          firstDayOfWeek={1}
          showOutsideDays
          modifiers={{ start: range.from ?? undefined, end: range.to ?? undefined }}
          onDayClick={(day, { disabled }) => {
            if (!disabled) {
              const updatedRange = DateUtils.addDayToRange(startOfDay(day), range as RangeModifier);
              setRange(updatedRange);
              trackCalendarRangeAction("select", updatedRange);
            }
          }}
          containerProps={{
            onMouseDown: e => e.preventDefault()
          }}
        />
        <div
          css={css`
            display: flex;
            align-items: flex-start;
            margin-top: var(--space-5-rem);

            & * > * + * {
              margin-left: var(--space-3-rem);
            }

            & button {
              font-size: var(--font-size-helper);
            }
          `}
        >
          <div>
            {props.rangeButtons.map((rangeButton, i) => (
              <PillButton
                key={i}
                small
                selected={isEqual(range, rangeButton.range)}
                onClick={() => {
                  setPickerKey(new Date().toString());
                  setRange(rangeButton.range);
                  trackCalendarRangeAction(rangeButton.label, rangeButton.range);
                }}
                onMouseDown={e => e.preventDefault()}
              >
                {rangeButton.label}
              </PillButton>
            ))}
          </div>
          <div
            css={css`
              display: flex;
              justify-content: flex-end;
              flex-grow: 1;
            `}
          >
            <Button
              size="small"
              onClick={() => {
                setRange(emptyRange);
                setPickerKey(Date.now().toString());
                trackCalendarRangeAction("Clear all", emptyRange);
              }}
              onMouseDown={e => e.preventDefault()}
            >
              Clear all
            </Button>
            <Button
              id={applyButtonId}
              variant="primary"
              size="small"
              disabled={!range.from || !range.to}
              onClick={() => {
                props.onChange(range);
                trackCalendarRangeAction("Apply", range);
              }}
              onMouseDown={e => e.preventDefault()}
            >
              Apply
            </Button>
          </div>
        </div>
      </DayPickerWrapper>
    </PopOver.Blank>
  );
};
export const DatePicker: React.FC<{
  button: JSX.Element;
  value: Date | null;
  onChange: (value: Date | null) => void;
  isOpen?: boolean;
  placement?: PopperPlacementType;
  onClickAway?(e: React.ChangeEvent<{}>): void;
  onClose?(): void;
  numberOfMonths?: number;
  allowPastDate?: boolean;
}> = props => {
  const [dueDate, setDueDate] = useState<Date | null>(props.value);
  const [popoverKey, setPopoverKey] = useState(new Date().toString());
  const closePopover = () => {
    setPopoverKey(new Date().toString());
  };
  return (
    <PopOver.Blank
      isOpen={props.isOpen}
      key={popoverKey}
      placement={props.placement}
      zIndex={zIndices.highest.value}
      button={props.button}
      onClickAway={props.onClickAway}
      onClose={props.onClose}
    >
      <DayPickerWrapper
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <DayPicker
          className="single-day-picker"
          numberOfMonths={props.numberOfMonths ?? 2}
          showOutsideDays
          selectedDays={dueDate ?? undefined}
          disabledDays={props.allowPastDate ? [] : [{ before: new Date() }]}
          firstDayOfWeek={1}
          onDayClick={(day, { disabled }) => {
            if (!disabled) {
              setDueDate(day);
            }
          }}
          containerProps={{
            onMouseDown: e => e.preventDefault()
          }}
        />
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            flex-grow: 1;
            margin-top: var(--space-5-rem);
            & > * + * {
              margin-left: var(--space-3-rem);
            }
            & button {
              font-size: var(--font-size-helper);
            }
          `}
        >
          <Button
            size="small"
            disabled={!props.value}
            onClick={() => {
              setDueDate(null);
              props.onChange(null);
              closePopover();
            }}
            onMouseDown={e => e.preventDefault()}
          >
            Remove
          </Button>
          <Button
            id={applyButtonId}
            disabled={!dueDate || (props.value ? DateUtils.isSameDay(dueDate, props.value) : false)}
            variant="primary"
            size="small"
            onClick={() => {
              dueDate && props.onChange(dueDate);
              closePopover();
            }}
            onMouseDown={e => e.preventDefault()}
          >
            Set
          </Button>
        </div>
      </DayPickerWrapper>
    </PopOver.Blank>
  );
};
