import { css } from "@emotion/core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import React from "react";
import { useMutation } from "react-apollo";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { Dialog, FormikFieldGroup, FormikInput, LoadingBar, SubmitButton } from "src/components";
import { Modal } from "src/portals/Modal";
import { justify, row } from "src/styling/primitives";
import * as yup from "yup";
import { INTEGRATIONS_LIST_GET } from "../Overview";
import { IntegrationAddBamboo, IntegrationAddBambooVariables } from "./typings/IntegrationAddBamboo";

export const INTEGRATION_ADD_BAMBOOHR = gql`
  mutation IntegrationAddBamboo($config: IntegrationHRISConfig!) {
    integrationAddHRIS(config: $config) {
      code
      message
      success
      redirect {
        url
      }
    }
  }
`;

const validationSchema = yup.object().shape({
  subdomain: yup
    .string()
    .required("You must enter a subdomain to connect BambooHR")
    .matches(
      /^((?!(https:\/\/|bamboohr)).)*$/,
      "Please enter only the subdomain without https:// and bamboohr.com or bamboohr.co.uk"
    )
});

export const BambooHRInstallModal = (props: { onDismiss(): void; organizationId: string; isOpen: boolean }) => {
  const { emitSnack } = useSnack();

  const [addBambooHR, response] = useMutation<IntegrationAddBamboo, IntegrationAddBambooVariables>(
    INTEGRATION_ADD_BAMBOOHR,
    {
      onCompleted: data => {
        if (data.integrationAddHRIS.success && data.integrationAddHRIS.redirect?.url) {
          window.location.assign(data.integrationAddHRIS.redirect?.url);
        } else {
          emitSnack({
            type: "mutationError",
            message: data.integrationAddHRIS.message
          });
        }
      },
      refetchQueries: [{ query: INTEGRATIONS_LIST_GET }]
    }
  );
  return (
    <Modal isOpen={props.isOpen} onDismiss={props.onDismiss}>
      <Dialog title="Connect BambooHR" onClose={props.onDismiss} medium>
        {response.loading && <LoadingBar />}
        <Formik
          initialValues={{
            subdomain: ""
          }}
          validationSchema={validationSchema}
          onSubmit={payload =>
            addBambooHR({
              variables: {
                config: {
                  bamboohr: {
                    subdomain: payload.subdomain
                  }
                }
              }
            })
          }
          render={form => (
            <Form>
              <FormikFieldGroup.Container legend="Subdomain">
                <Field autoFocus name="subdomain" component={FormikInput} placeholder="e.g. acme-corp" hideErrorLabel />
                <FormikFieldGroup.HelpText
                  css={css`
                    margin-top: var(--space-2-rem);
                  `}
                >
                  The subdomain from your BambooHR URL, e.g. if your URL is https://acme.bamboohr.com enter{" "}
                  <strong>acme</strong>.
                </FormikFieldGroup.HelpText>
                <FormikFieldGroup.Errors>
                  <ErrorMessage name="subdomain" />
                </FormikFieldGroup.Errors>
              </FormikFieldGroup.Container>

              <div css={[row, justify.end]}>
                <SubmitButton disabled={response.loading || !form.isValid}>Submit</SubmitButton>
              </div>
            </Form>
          )}
        />
      </Dialog>
    </Modal>
  );
};
