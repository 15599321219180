import gql from "graphql-tag";
import * as React from "react";
import { useMutation } from "react-apollo";
import { AuthWrapper } from "src/App/Auth/Components";
import { Button, Col, Dialog, LoadingBar } from "src/components";
import { Toast } from "src/portals/Toast";
import { navigate } from "src/util/router";
import { FinishPasswordRecoveryForm } from "./Form";
import { UserFinishPasswordRecovery, UserFinishPasswordRecoveryVariables } from "./typings/UserFinishPasswordRecovery";

const USER_FINISH_PASSWORD_RECOVERY = gql`
  mutation UserFinishPasswordRecovery($params: UserFinishPasswordRecoveryParams!) {
    userFinishPasswordRecovery(params: $params) {
      code
      message
      success
      invalid
    }
  }
`;

export function FinishPasswordRecoveryContainer() {
  const [finishPasswordRecovery, finishPasswordRecoveryResponse] = useMutation<
    UserFinishPasswordRecovery,
    UserFinishPasswordRecoveryVariables
  >(USER_FINISH_PASSWORD_RECOVERY);

  const token = new URLSearchParams(window.location.search).get("token") || "";
  const success =
    finishPasswordRecoveryResponse.data && finishPasswordRecoveryResponse.data.userFinishPasswordRecovery.success;
  const errorMessage =
    finishPasswordRecoveryResponse.data &&
    !finishPasswordRecoveryResponse.data.userFinishPasswordRecovery.success &&
    (finishPasswordRecoveryResponse.data.userFinishPasswordRecovery.message || "Unknown error");

  return (
    <AuthWrapper>
      <Dialog small title="Reset password" noClose={true}>
        {!success && token && (
          <FinishPasswordRecoveryForm
            loading={finishPasswordRecoveryResponse.loading}
            finishPasswordRecovery={finishPasswordRecovery}
            token={token}
          />
        )}

        {!success && !token && (
          <Col fontSize="0.875rem" data-testid="no-token-warning">
            Oops, this seems to be the wrong link. Please try clicking on the link in the e-mail again...
            <Button variant="primary" margin="2rem 0 0" size="large" onClick={() => navigate("/login")}>
              Go back to login
            </Button>
          </Col>
        )}

        {success && (
          <Col fontSize="0.875rem">
            Your password was reset successfully. You can now log in with your new password!
            <Button variant="primary" margin="2rem 0 0" size="large" onClick={() => navigate("/login")}>
              Go back to login
            </Button>
          </Col>
        )}
        {finishPasswordRecoveryResponse.loading && <LoadingBar />}
        {errorMessage && <Toast message={errorMessage} kind="mutationError" />}
      </Dialog>
    </AuthWrapper>
  );
}
