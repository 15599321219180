import mrkdwn from "@backhq/mrkdwn-parse";
import { Element } from "hast";
import gh from "hast-util-sanitize/lib/github.json";
import { unescape } from "he";
import { merge } from "lodash";
import micromark from "micromark";
import rehype from "rehype";
import rehypeParse from "rehype-parse";
import raw from "rehype-raw";
import rehype2remark from "rehype-remark";
import sanitize from "rehype-sanitize";
import html from "rehype-stringify";
import urls from "rehype-urls";
import breaks from "remark-breaks";
import remarkParse from "remark-parse";
import remark2rehype from "remark-rehype";
import remarkStringify from "remark-stringify";
import { reportDevError } from "src/util";
import unified from "unified";

/**
 * Removes all Slack specific URL formatting, e.g.:
 *    removeSlackUrlFormatting("Foo <http://google.de> Bar")              => "Foo http://google.de Bar"
 *    removeSlackUrlFormatting("<Foo> <www.google.de/foo?wtf=bbq> <Bar>") => "Foo www.google.de/foo?wtf=bbq Bar"
 *    removeSlackUrlFormatting("<www.foo.bar|foo>")                       => "www.foo.bar"
 *    removeSlackUrlFormatting("<foo@bar.com|foo bar>")                   => "foo@bar.com"
 *    removeSlackUrlFormatting("<www.foo.bar|foo>")                       => "www.foo.bar"
 *    removeSlackUrlFormatting("<foo@bar.com|foo bar>")                   => "foo@bar.com"
 *    removeSlackUrlFormatting("<foo@bar.com|foo@bar.com>")               => "foo@bar.com"
 *    removeSlackUrlFormatting("<foo@bar.com>")                           => "foo@bar.com"
 * @param str The string from which all Slack specific URL formatting should be removed
 */
export function removeSlackUrlFormatting(str: string) {
  return str
    .replace(/<\/[^>]*>/gi, "")
    .replace("mailto:", "")
    .replace(/<([^\|>]*)(?:\|[^>]*)?>/gi, "$1");
}

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//
// ATTENTION: Make sure that the input message is ALWAYS sanitized, i.e. all HTML tags and everything that
// ========== could potentially be dangerous are removed before converting the message to HTML. The output
//            of this is injected into the DOM, so we have to make sure that there is no way to execute
//            malicious JavaScript code on the clients.
//
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
const messageProcessor = unified()
  .use(mrkdwn)
  .use(breaks)
  .use(remark2rehype)
  .use(raw)
  .use(urls, function updateAnchor(url: string, node: Element) {
    // open anchor tags with href in new window
    if (
      node.type === "element" &&
      node.tagName === "a" &&
      node.properties?.href &&
      // mentions render as anchor
      node.properties?.href !== "#mention"
    ) {
      node.properties.target = "_blank";
      node.properties.rel = "noopener noreferrer";
    } else if (node.properties?.href === "#mention") {
      node.properties.class = "mention";
    }
  })
  // allowed html tags/attrs  to render
  .use(sanitize, {
    tagNames: ["a", "br", "strong", "em", "del", "pre", "code"],
    attributes: {
      "*": [],
      a: ["class", "href", "rel", "target", "title"]
    }
  })
  .use(html);

/**
 * Format mrkdwn for messages as dangerous html
 */
export const formatMessage = async (message: string): Promise<string> => {
  return messageProcessor.process(removeSlackUrlFormatting(message)).then(vFile => vFile.toString());
};

const titleProcessor = unified().use(mrkdwn).use(remark2rehype).use(sanitize, { tagNames: [] }).use(html);

/**
 * Format mrkdwn as plain text, e.g. for request title
 * Follows same parser rules, simply strips out html
 */
export const formatTitle = (message: string): string => {
  const asHtmlPlainText = titleProcessor.processSync(removeSlackUrlFormatting(message)).toString();
  // unescape html entities and strip newlines
  return unescape(asHtmlPlainText).replace(/\n/gm, "");
};

const disableExtensions = [
  {
    disable: {
      null: [
        "codeIndented",
        "definition",
        "blockQuote",
        "fencedCode",
        // "list",
        "codeFenced",
        "headingAtx",
        "setextUnderline",
        "htmlFlow",
        "thematicBreak",
        "characterEscape",
        "characterReferences",
        "codeText",
        "hardBreakEscape",
        "htmlText"
        // "labelStartImage",
        // "labelStartLink",
        // "labelEnd"
        // "attention"
      ]
    }
  }
  // these are not official, or not typed anyways, api (thus cast as any)
  // see https://github.com/micromark/micromark/commit/21fecde98dcf3529b88bbf8c9f6295cdf781b588
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
] as any;

/**
 * render newlines as line breaks
 * html consumed by markdown editor + viewer
 * scalped from https://github.com/micromark/micromark-extension-gfm-task-list-item/blob/main/html.js
 */
const lineEndingBlankBreakExtension = {
  enter: {
    lineEndingBlank() {
      const self = (this as unknown) as any;
      self.tag("<p><br /></p>");
    }
  }
};

// returns http + https urls (does not accept e.g. javascript:... uri)
function httpUrl(s: string) {
  try {
    const u = new URL(s);
    if (u.protocol === "http:" || u.protocol === "https:") {
      return u.toString();
    }
  } catch (e) {
    reportDevError(e);
  }
  return null;
}

/**
 * Defines how we compile link html tags
 * to include target _blank as well as rel noopener attrs
 * Adapted from their namesakes in micromark/lib/compile/html
 */
let destinationState = "";
let labelState = "";
const linksTargetRel = {
  exit: {
    resourceDestinationString() {
      const self = (this as unknown) as any;
      destinationState = self.resume();
    },
    label() {
      const self = (this as unknown) as any;
      labelState = self.resume();
    },
    link() {
      const self = (this as unknown) as any;
      const url = httpUrl(destinationState);
      if (!url) {
        // display as markdown plaintext
        self.raw(`[${labelState}](${destinationState})`);
        return;
      }
      // generate html using dom api to prevent escaping
      const a = document.createElement("a");
      a.href = url;
      a.target = "_blank";
      a.rel = "noopener noreferrer";
      a.appendChild(document.createTextNode(labelState));
      self.tag(a.outerHTML);
    }
  }
};

/* renders kb-doc flavor markdown as html string */
export function backMarkdownToHtml(md: string): string {
  return micromark(md, {
    extensions: disableExtensions,
    htmlExtensions: [lineEndingBlankBreakExtension, linksTargetRel]
  });
}

/* strips markdown formatting */
export function backMarkdownToText(md: string): string {
  return unified()
    .use(remarkParse)
    .use(remark2rehype)
    .use(raw)
    .use(sanitize, { tagNames: [] })
    .use(html)
    .processSync(md)
    .toString();
}

export const htmlToBackMarkdown = (html: string) => {
  return unified()
    .use(rehypeParse)
    .use(rehype2remark)
    .use(remarkStringify as any)
    .processSync(html)
    .toString();
};

/**
 * This method renders markdown with embedded html as html
 * It should be considered completely unsafe for user content
 * and only be used for markdown we control for e.g., best practice descriptions
 */
export function markdownWithEmbeddedHtmlToHtml(md: string): string {
  return unified()
    .use(remarkParse)
    .use(remark2rehype, { allowDangerousHtml: true })
    .use(raw)
    .use(sanitize, merge(gh, { attributes: { span: ["style"] } }))
    .use(html)
    .processSync(md)
    .toString();
}

/** sanitize svg for e.g. displaying icons loaded from server */
export const sanitizeSvg = (svg: string) =>
  rehype()
    .use(sanitize, {
      tagNames: ["svg", "style", "g", "path", "rect", "circle", "ellipse", "text", "tspan"],
      attributes: {
        "*": [
          "id",
          "className",
          "type",
          "xmlns",
          "viewBox",
          "width",
          "height",
          "style",
          "fill",
          "stroke",
          "d",
          "x",
          "y",
          "rx",
          "ry",
          "dx",
          "dy",
          "clip",
          "clip-path",
          "clip-rule",
          "fill",
          "fill-opacity",
          "fill-rule"
        ]
      }
    })
    .use(html)
    .processSync(svg)
    .toString();
