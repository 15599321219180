import styled from "@emotion/styled";
import { mdiPaperclip } from "@mdi/js";
import * as React from "react";
import { useEffect, useRef } from "react";
import { handleFilesEvent } from "src/App/Attachments/handlers";
import { IFiles, TFileAction } from "src/App/Attachments/reducer";
import { MaterialIcon, SquareButton } from "src/components";
import { AbsoluteTooltip, Tooltip } from "src/components/Tooltip";

const InputWrapper = styled.label<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  cursor: pointer;
  margin: 0;
  opacity: ${p => (p.disabled ? "0.5" : "1.0")};

  & input {
    position: absolute;
    pointer-events: none;
    visibility: hidden;
  }

  & .icon {
    color: var(--text-6);
  }

  & button {
    cursor: ${p => (p.disabled ? "not-allowed" : "pointer")};
  }
`;

export function useFileInput(
  filesDispatch: React.Dispatch<TFileAction> | null = null,
  preventMultiple = false,
  authToken: string | null = null
) {
  const fileInputElement = useRef(document.createElement("input")).current;
  useEffect(() => {
    if (!filesDispatch) return;
    fileInputElement.setAttribute("type", "file");
    if (!preventMultiple) {
      fileInputElement.setAttribute("multiple", "true");
    }
    fileInputElement.setAttribute("data-testid", "file-input");
    fileInputElement.style.display = "none";
    document.body.appendChild(fileInputElement);
    const handleChange = (e: Event) => {
      const files = (e as unknown as React.ChangeEvent<HTMLInputElement>).target.files || [];
      handleFilesEvent(Array.from(files), filesDispatch, authToken);
    };
    fileInputElement.addEventListener("change", handleChange);
    return () => {
      document.body.removeChild(fileInputElement);
      fileInputElement.removeEventListener("change", handleChange);
    };
  }, [fileInputElement, filesDispatch, preventMultiple, authToken]);
  return fileInputElement;
}

/**
 * Menu to open file dialog
 */
export const AttachmentsMenu = (props: {
  /**
   * Rerender / close menu
   */
  resetKey: string | number;
  filesState: IFiles;
  filesDispatch: React.Dispatch<TFileAction>;
  disabledReason?: string;
}) => {
  const fileInputElement = useFileInput(props.filesDispatch);
  return (
    <Tooltip
      placement="top"
      target={
        <InputWrapper htmlFor="upload" onClick={e => e.stopPropagation()} disabled={!!props.disabledReason}>
          <AbsoluteTooltip placement="top" content="Attach a file">
            <SquareButton onClick={() => fileInputElement.click()} disabled={!!props.disabledReason}>
              <MaterialIcon path={mdiPaperclip} size={1.125} data-testid="upload-icon" />
            </SquareButton>
          </AbsoluteTooltip>
        </InputWrapper>
      }
    >
      {props.disabledReason && <span>{props.disabledReason}</span>}
    </Tooltip>
  );
};
