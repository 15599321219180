import * as React from "react";

import { EditorContent, useEditor } from "@tiptap/react";
import { PublishedVariable, useWorkflow } from ".";
import { useRef, useState } from "react";

import Document from "@tiptap/extension-document";
import { FormikFieldGroup } from "src/components";
import History from "@tiptap/extension-history";
import Paragraph from "@tiptap/extension-paragraph";
import { ReactNode } from "@material-ui/types/node_modules/@types/react";
import Text from "@tiptap/extension-text";
import { VariableSuggestion } from "./VariableSuggestion";
import { WorkflowPublishedVariableType } from "src/globalTypes";
import { css } from "@emotion/core";
import { motion } from "framer-motion";

const extensions = [History, Document, Paragraph, Text];

interface TextNode {
  type: "text";
  text: string;
}

/* inline variable suggester relies on the mention extension
so nodes are rendered as type "mention" */
interface MentionExtensionNode {
  type: "mention";
  attrs: { id: string };
}

type ContentNode = TextNode | MentionExtensionNode;

type JsonDataFormat = {
  type: "doc";
  content: {
    type: "paragraph";
    content?: ContentNode[];
  }[];
};

const deserializeJsonData = (data: JsonDataFormat): string => {
  let text = "";
  for (const paragraph of data.content) {
    if (paragraph.content) {
      for (const block of paragraph.content) {
        if (block.type === "text") {
          text += block.text;
        } else if (block.type === "mention") {
          text += "${" + block.attrs.id + "}";
        }
      }
    }
  }
  return text;
};

const parse = (text: string): ContentNode[] => {
  if (!text.length) return [];
  const [_input, head, match, tail]: (string | undefined)[] = text.match(/(.*?)\$\{(.*?)\}(.*)/) ?? [];
  if (match && match.length > 0) {
    if (head.length > 0) {
      // don't return empty text nodes
      return [{ type: "text", text: head }, { type: "mention", attrs: { id: match } }, ...parse(tail ?? "")];
    } else {
      return [{ type: "mention", attrs: { id: match } }, ...parse(tail ?? "")];
    }
  }
  return [{ type: "text", text }];
};

export const serializeTextAsJsonData = (text: string): JsonDataFormat => {
  const data: JsonDataFormat = {
    type: "doc",
    content: []
  };
  const paragraphs = text.split("\n");
  for (const paragraphText of paragraphs) {
    data.content.push({
      type: "paragraph",
      content: parse(paragraphText)
    });
  }
  return data;
};

const plainTextVariables: WorkflowPublishedVariableType[] = [
  WorkflowPublishedVariableType.TYPE_DATE,
  WorkflowPublishedVariableType.TYPE_EMAIL,
  WorkflowPublishedVariableType.TYPE_NUMBER,
  WorkflowPublishedVariableType.TYPE_STRING,
  WorkflowPublishedVariableType.TYPE_UNSPECIFIED
];

const filterWorkflowVariables = (
  variables: PublishedVariable[],
  type?: WorkflowPublishedVariableType | "plaintext"
): PublishedVariable[] => {
  if (!type) return variables;
  if (type === "plaintext") {
    return variables.filter(variable => plainTextVariables.includes(variable.type));
  }
  return variables.filter(variable => variable.type === type);
};

export const TextAreaWithVariables: React.FC<{
  initialValue: string;
  onChange(html: string): void;
  type?: WorkflowPublishedVariableType | "plaintext";
  helperComponent?: ReactNode;
  errorComponent?: ReactNode;
}> = props => {
  const { getWorkflowVariables } = useWorkflow();
  const variableList = filterWorkflowVariables(getWorkflowVariables() ?? [], props.type);
  const onChangeRef = useRef<(text: string) => void>(props.onChange);
  onChangeRef.current = props.onChange;
  const editor = useEditor({
    extensions: [...extensions, VariableSuggestion(variableList)],
    content: serializeTextAsJsonData(props.initialValue ?? ""),
    onUpdate: event => {
      const text = deserializeJsonData(event.editor.getJSON() as JsonDataFormat);
      onChangeRef.current(text);
    }
  });
  const [isFocused, setIsFocused] = useState(false);
  return (
    <div
      css={css`
        & > * + * {
          margin-top: var(--space-1-rem);
          /* Needed to remove double spacing caused by helper */
          margin-bottom: var(--minus-4-rem);
        }
      `}
    >
      <div
        css={css`
          & .ProseMirror {
            border-bottom: 1px solid var(--lightGrey-4);
            &:hover,
            &:focus,
            &:focus-within {
              outline: none;
              border-bottom: 1px solid var(--text-6);
            }

            & > p {
              & * {
                display: inline-block;
              }
            }
          }
        `}
      >
        <EditorContent editor={editor} onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} />
      </div>
      {props.helperComponent || props.errorComponent || (
        <motion.div
          animate={{ opacity: isFocused ? 1 : 0 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 0.3 }}
        >
          <FormikFieldGroup.HelpText>$ + name to enter a variable</FormikFieldGroup.HelpText>
        </motion.div>
      )}
    </div>
  );
};
