import gql from "graphql-tag";
import {
  CATEGORY_MATCHER_FRAGMENT,
  DATE_MATCHER_FRAGMENT,
  DOCUMENT_MATCHER_FRAGMENT,
  FORM_MATCHER_FRAGMENT,
  PRIORITY_MATCHER_FRAGMENT,
  STATUS_MATCHER_FRAGMENT,
  TEAM_MATCHER_FRAGMENT,
  USER_MATCHER_FRAGMENT
} from "src/App/Settings/Workflows/WorfkflowMatcherFragments";

export const TEAM_WORKFLOW_GET = gql`
  query TeamWorkflowGet($teamId: ID!, $workflowId: ID!) {
    teamWorkflow(teamId: $teamId, workflowId: $workflowId) {
      id
      name
      enabled
      typeformId
      requiresCustomization
      description
      icon
      triggers {
        id
        publishedVariables {
          name
          description
          type
          editable
          value
        }
        trigger {
          ... on RequestTrigger {
            requestTriggerBase {
              onlyIf {
                actor {
                  ...UserMatcher
                }
                status {
                  ...StatusMatcher
                }
                team {
                  ...TeamMatcher
                }
                category {
                  ...CategoryMatcher
                }
                titleContains {
                  anyList
                }
                descriptionContains {
                  anyList
                }
              }
            }
          }
          ... on WorkflowTriggerRecurring {
            cron
          }
          ... on WorkflowTriggerManual {
            parameters {
              name
              description
              type
              options
            }
          }
        }
      }
      steps {
        id
        subWorkflowId
        stepMaps {
          id
          publishedVariables {
            name
            description
            type
            editable
            value
          }
          action {
            ... on Node {
              id
            }
            ... on ApprovalRequestAction {
              requestId
              approver {
                ...UserMatcher
              }
              text
              onApproved
              onRejected
            }
            ... on CreateConversationAction {
              subject
              body
              toList {
                ...UserMatcher
              }
              cc {
                ...UserMatcher
              }
              attachmentIds
              alias
            }
            ... on CreateNoteAction {
              requestId
              textRequired
              externalMentions {
                emails
                includeHistory
              }
              mentions {
                ...UserMatcher
              }
            }
            ... on CreateReplyAction {
              requestId
              textRequired
              documents {
                ...DocumentMatcher
              }
            }
            ... on CreateRequestAction {
              title
              description
              projectId
              alias
              requester {
                ...UserMatcher
              }
              team {
                ...TeamMatcher
              }
            }
            ... on CreateTaskAction {
              requestId
              instruction
              action {
                url
                label
              }
              onCompleted
            }
            ... on CreateProjectAction {
              title
              team {
                ...TeamMatcher
              }
              alias
            }
            ... on CreateConversationRequestAction {
              conversationId
              requester {
                ...UserMatcher
              }
              alias
            }
            ... on SendFormAction {
              requestId
              form {
                ...FormMatcher
              }
              onReplied
              aliasReplies
            }
            ... on LinkJiraRequestAction {
              requestId
              aliasIssue
            }
            ... on LaunchAction {
              flowsList {
                subworkflowName
                at {
                  ...DateMatcher
                }
              }
            }
            ... on SetRequestAssigneeAction {
              requestId
              assignee {
                ...UserMatcher
              }
            }
            ... on SetRequestTeamAction {
              requestId
              teamRequired {
                ...TeamMatcher
              }
            }
            ... on SetRequestCategoryAction {
              requestId
              category {
                ...CategoryMatcher
              }
            }
            ... on SetRequestStatusAction {
              requestId
              status {
                ...StatusMatcher
              }
            }
            ... on SetRequestPriorityAction {
              requestId
              priority {
                ...PriorityMatcher
              }
            }
            ... on SetRequestProjectAction {
              requestId
              projectIdRequired
            }
            ... on SetRequestDueDateAction {
              requestId
              dueDate {
                ...DateMatcher
              }
            }
            ... on SetRequestTitleAction {
              requestId
              title
            }
            ... on SetRequestRequesterAction {
              requestId
              requester {
                ...UserMatcher
              }
            }
            ... on FetchUserProfileAction {
              user {
                ...UserMatcher
              }
              alias
            }
            ... on JumpIfAction {
              streq {
                a
                b
              }
              numeq {
                a
                b
              }
              gte {
                a
                b
              }
              gt {
                a
                b
              }
              lte {
                a
                b
              }
              lt {
                a
                b
              }
              onTrue
              onFalse
            }
            ... on MapValueAction {
              in
              values {
                key
                value
              }
              out
            }
          }
        }
      }
    }
  }
  ${CATEGORY_MATCHER_FRAGMENT}
  ${DATE_MATCHER_FRAGMENT}
  ${DOCUMENT_MATCHER_FRAGMENT}
  ${FORM_MATCHER_FRAGMENT}
  ${PRIORITY_MATCHER_FRAGMENT}
  ${STATUS_MATCHER_FRAGMENT}
  ${TEAM_MATCHER_FRAGMENT}
  ${USER_MATCHER_FRAGMENT}
`;
