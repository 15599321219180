import gql from "graphql-tag";
import * as React from "react";
import { useMutation } from "react-apollo";
import { REQUEST_GET } from "src/App/Requests/DetailView/REQUEST_GET";
import { Button, LoadingBar } from "src/components";
import { Toast } from "src/portals/Toast";
import { GRequestAddCommentVariables } from "./typings/GRequestAddComment";

const REQUEST_ADD_NOTE = gql`
  mutation GRequestAddNote($params: RequestNoteParameters!) {
    requestAddNote(params: $params) {
      code
      success
      message
    }
  }
`;

/**
 * Button to submit a note
 * Contains mutation, loading, error handling logic
 */
export function PostNoteButton(props: {
  params: GRequestAddCommentVariables["params"];
  disabled?: boolean;
  submitHandlerRef: React.MutableRefObject<() => void>;
  /**
   * Callback after note submitted successfully
   */
  onSuccess(): void;
}) {
  const [postNote, postNoteResponse] = useMutation(REQUEST_ADD_NOTE, {
    refetchQueries: [
      {
        query: REQUEST_GET,
        variables: {
          id: props.params.requestId
        }
      }
    ]
  });
  const submitHandler = () =>
    postNote({
      update: (cache, response) => {
        if (response.data && response.data.requestAddNote.success) {
          props.onSuccess();
        }
      },
      variables: {
        params: props.params
      }
    });
  props.submitHandlerRef.current = submitHandler;
  return (
    <>
      {!postNoteResponse.loading && (
        <Button
          variant="yellow"
          disabled={props.disabled}
          margin="0 0 0 .25rem"
          onClick={() => submitHandler()}
          data-testid="submit-note-button"
        >
          Add note
        </Button>
      )}
      {postNoteResponse.loading && <LoadingBar />}
      {postNoteResponse.data && !postNoteResponse.data.requestAddNote.success && (
        <Toast kind="mutationError" message={postNoteResponse.data.requestAddNote.message} />
      )}
    </>
  );
}
