import { isSupported as isLoomSupported, SDKResult, setup } from "@loomhq/record-sdk";
import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { ReactComponent as LoomSVG } from "src/assets/logos/Loom.svg";
import { AbsoluteTooltip, Icon, SquareButton } from "src/components";
import { reportDevError } from "src/util";

interface LoomInstance {
  configureButton?: SDKResult["configureButton"];
  isSupported: boolean;
}

async function getLoomInstance(apiKey: string): Promise<LoomInstance> {
  try {
    if (!(await isLoomSupported()).supported) {
      return { isSupported: false };
    }

    return {
      isSupported: true,
      configureButton: (await setup({ publicAppId: apiKey })).configureButton
    };
  } catch (e) {
    reportDevError(`Loom not loaded. Reason: ${e}`);
    return { isSupported: false };
  }
}

function useLoomInstance(apiKey: string): Partial<LoomInstance> & { loading: boolean } {
  const [loomInstance, setLoomInstance] = useState<LoomInstance | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getLoomInstance(apiKey).then(instance => {
      setLoomInstance(instance);
      setLoading(false);
    });
  }, [apiKey]);

  return { loading, ...loomInstance };
}

export interface LoomButtonProps {
  onVideoURLReady(url: string): void;
}

type LoomButtonPropsWithApiKey = LoomButtonProps & { apiKey: string };

type LoomState = "RECORDING" | "READY" | "UNLOADED";

const ActualLoomButton: FC<LoomButtonPropsWithApiKey> = props => {
  const ref = useRef<HTMLButtonElement>(null);
  const [loomState, setLoomState] = useState<LoomState>("UNLOADED");
  const { loading, isSupported, configureButton } = useLoomInstance(props.apiKey);
  const { emitSnack } = useSnack();
  const { onVideoURLReady } = props;

  const buttonElement = ref?.current;

  useLayoutEffect(() => {
    if (!loading && isSupported && configureButton && buttonElement && loomState === "UNLOADED") {
      configureButton({
        element: buttonElement,
        hooks: {
          onInsertClicked: share => {
            emitSnack({
              message: `Loom video successfully recorded!`,
              type: "info"
            });
            onVideoURLReady(share.sharedUrl);
          },
          onRecordingStarted: () => {
            setLoomState("RECORDING");
          },
          onCancel: () => {
            setLoomState("READY");
          },
          onRecordingComplete: () => {
            setLoomState("READY");
          },
          onUploadComplete: () => {}
        }
      });
      setLoomState("READY");
    }
  }, [loading, isSupported, configureButton, buttonElement, loomState, emitSnack, onVideoURLReady]);

  const tooltipMessage = loading
    ? "Loom SDK is loading…"
    : isSupported
    ? "Record a video with Loom"
    : "Recording a video with Loom is currently not supported in your browser";

  return (
    <AbsoluteTooltip placement="top" content={tooltipMessage}>
      <SquareButton ref={ref} data-intercom-target="Loom button" variant="ghost" disabled={loomState !== "READY"}>
        <Icon>
          <LoomSVG />
        </Icon>
      </SquareButton>
    </AbsoluteTooltip>
  );
};

export const LoomButton: FC<LoomButtonProps> = props => {
  const apiKey = process.env.REACT_APP_LOOM_SDK_API_KEY;

  return apiKey ? <ActualLoomButton apiKey={apiKey} {...props} /> : null;
};
