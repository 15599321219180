import { css } from "@emotion/core";
import { BestPracticeColor, TeamType, IntegrationType } from "src/globalTypes";

export const bestPracticeBGColors = {
  [BestPracticeColor.COLOR_GREEN]: css`
    background-color: var(--green-2);
  `,
  [BestPracticeColor.COLOR_PINK]: css`
    background-color: var(--pink-2);
  `,
  [BestPracticeColor.COLOR_RED]: css`
    background-color: var(--violet-1);
  `,
  [BestPracticeColor.COLOR_VIOLET]: css`
    background-color: var(--red-1);
  `,
  [BestPracticeColor.COLOR_UNSPECIFIED]: css`
    background-color: var(--lightGrey-2);
  `
};

export const bestPracticeTeamTypes = {
  [TeamType.TEAM_TYPE_UNSPECIFIED]: "unspecified",
  [TeamType.TEAM_TYPE_FINANCE]: "Finance",
  [TeamType.TEAM_TYPE_HR]: "HR",
  [TeamType.TEAM_TYPE_IT]: "IT"
};

export const bestPracticeRequiredIntegrations = {
  [IntegrationType.INTEGRATION_TYPE_UNSPECIFIED]: "unspecified",
  [IntegrationType.INTEGRATION_TYPE_HRIS]: "HRIS",
  [IntegrationType.INTEGRATION_TYPE_MSTEAMS]: "MSTeams",
  [IntegrationType.INTEGRATION_TYPE_SLACK]: "Slack"
};
