import { css } from "@emotion/core";
import { addDays, endOfToday } from "date-fns";
import gql from "graphql-tag";
import * as React from "react";
import { useState } from "react";
import { useQuery } from "react-apollo";
import { Dialog } from "src/components";
import { Icon } from "src/components/Icon";
import { Table } from "src/components/Table";
import { FeedbackAction } from "src/globalTypes";
import { Modal } from "src/portals/Modal";
import { Typo } from "src/styling/primitives/typography";
import { Circle } from "src/svg/icons/Circle";
import { ThumbsUp } from "src/svg/icons/ThumbsUp";
import { filterNullsFromArray } from "src/util";
import { formatDateTimeString, formatTime } from "src/util/formatters";
import { DocumentSuggestionFeedback, DocumentSuggestionFeedbackVariables } from "./typings/DocumentSuggestionFeedback";

const AnswerSuggestions: React.FC<{
  accepted: string[];
  dismissed: string[];
}> = props => (
  <>
    {props.accepted.length > 0 && (
      <div
        css={css`
          display: flex;
          color: var(--green-8);

          & > * + * {
            margin-left: 6px;
          }
        `}
      >
        <Icon
          sizeS
          css={css`
            width: 1.125rem;
            height: 1.125rem;
            background: var(--green-2);
            border-radius: var(--border-radius-m);

            & path {
              fill: var(--green-8);
            }
          `}
        >
          <ThumbsUp />
        </Icon>
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          {props.accepted.map((t, i) => (
            <Typo.Body
              key={i}
              css={css`
                color: var(--green-8);
              `}
            >
              {t}
            </Typo.Body>
          ))}
        </div>
      </div>
    )}
    {props.dismissed.length > 0 && (
      <div
        css={css`
          display: flex;

          & > * + * {
            margin-left: 6px;
          }
        `}
      >
        <Icon
          sizeS
          css={css`
            width: 1.125rem;
            height: 1.125rem;
            background: var(--lightGrey-2);
            border-radius: var(--border-radius-m);
          `}
        >
          <Circle />
        </Icon>
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          {props.dismissed.map((t, i) => (
            <Typo.Body key={i}>{t}</Typo.Body>
          ))}
        </div>
      </div>
    )}
  </>
);

export const useSuggestionsGroupedByQueryId = () => {
  const last30days = {
    startTime: formatDateTimeString(addDays(endOfToday(), -30)),
    endTime: formatDateTimeString(endOfToday())
  };
  const documentSuggestionFeedbackResponse = useQuery<DocumentSuggestionFeedback, DocumentSuggestionFeedbackVariables>(
    gql`
      query DocumentSuggestionFeedback($period: Period!) {
        documentSuggestionFeedback(period: $period, actions: [FEEDBACK_ACTION_DISMISSED, FEEDBACK_ACTION_ACCEPTED]) {
          queryId
          query
          action
          createTime
          document {
            pk
            id
            title
            revision
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      variables: {
        period: last30days
      }
    }
  );
  if (!documentSuggestionFeedbackResponse.data) {
    return {
      groupedSuggestions: null,
      groupedSuggestionsIsLoading: documentSuggestionFeedbackResponse.loading
    };
  }
  const uniqueQueryIds = Array.from(
    new Set(documentSuggestionFeedbackResponse.data.documentSuggestionFeedback.map(s => s.queryId))
  );
  const groupedSuggestionsWithNulls = uniqueQueryIds.map(queryId => {
    const suggestions = documentSuggestionFeedbackResponse.data?.documentSuggestionFeedback.filter(
      s => s.queryId === queryId
    );
    if (!suggestions?.length) return null;
    const { query, createTime } = suggestions[0];
    return {
      query,
      queryId,
      createTime,
      accepted: suggestions
        .filter(s => s.action === FeedbackAction.FEEDBACK_ACTION_ACCEPTED)
        .map(s => s.document.title),
      dismissed: suggestions
        .filter(s => s.action === FeedbackAction.FEEDBACK_ACTION_DISMISSED)
        .map(s => s.document.title)
    };
  });
  const groupedSuggestions = filterNullsFromArray(groupedSuggestionsWithNulls);
  return {
    groupedSuggestions,
    groupedSuggestionsIsLoading: documentSuggestionFeedbackResponse.loading
  };
};

/** Log of document feedback */
export const LogTable: React.FC<{
  groupedSuggestions:
    | null
    | {
        query: string;
        queryId: string;
        createTime: string;
        accepted: string[];
        dismissed: string[];
      }[];
}> = props => {
  const [showQueryId, setShowQueryId] = useState<string | null>(null);
  const selectedQuery = props.groupedSuggestions?.find(s => s?.queryId === showQueryId);
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Message</th>
            <th>Suggestions</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {props.groupedSuggestions?.map((s, i) => {
            if (s === null) return null;
            return (
              <tr
                key={s.queryId}
                css={css`
                  box-sizing: border-box;
                  vertical-align: baseline;
                  transition: box-shadow 0.3s ease;
                  /* prevent box shadow from spilling out sides */
                  border-left: 2px solid var(--white);
                  border-right: 2px solid var(--white);

                  &:hover {
                    box-shadow: var(--box-shadow-condensedItem);
                    cursor: pointer;
                  }
                `}
                onClick={() => setShowQueryId(s.queryId)}
              >
                <td>{s.query}</td>
                <td>
                  <AnswerSuggestions accepted={s.accepted} dismissed={s.dismissed} />
                </td>
                <td>{formatTime(s.createTime)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Modal isOpen={!!selectedQuery} onDismiss={() => setShowQueryId(null)}>
        {selectedQuery && (
          <Dialog medium onClose={() => setShowQueryId(null)}>
            <div
              css={css`
                & > * + * {
                  margin-top: var(--space-6-px);
                }
              `}
            >
              <div
                css={css`
                  & > * + * {
                    margin-top: var(--space-1-px);
                  }
                `}
              >
                <Typo.Body sizeL medium>
                  Message
                </Typo.Body>
                <Typo.Body>{selectedQuery.query}</Typo.Body>
              </div>
              <div
                css={css`
                  & > * + * {
                    margin-top: var(--space-1-px);
                  }
                `}
              >
                <Typo.Body sizeL medium>
                  Suggestions
                </Typo.Body>
                <AnswerSuggestions accepted={selectedQuery.accepted} dismissed={selectedQuery.dismissed} />
              </div>
            </div>
          </Dialog>
        )}
      </Modal>
    </>
  );
};
