import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { mdiAccountPlus, mdiClose, mdiPlus } from "@mdi/js";
import gql from "graphql-tag";
import * as React from "react";
import { useState } from "react";
import { useMutation } from "react-apollo";
import { Subheading } from "src/App/Requests/DetailView/Component";
import { REQUEST_GET } from "src/App/Requests/DetailView/REQUEST_GET";
import { RequestGet_request_CCs_CCs } from "src/App/Requests/DetailView/typings/RequestGet";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { UserAvatar } from "src/App/User";
import { SelectCreateUsers, SelectUserValue } from "src/App/User/UserSelect";
import {
  Avatar,
  Button,
  Dialog,
  LegacyPill,
  LoadingBar,
  MaterialIcon,
  Row,
  SimpleTooltip,
  SquareButton
} from "src/components";
import { Modal } from "src/portals/Modal";
import { Typo } from "src/styling/primitives/typography";
import { reportDevError } from "src/util";
import { PollingRequestData } from "src/App/Requests/DetailView/Container";
import { UserEmailLabel } from "./Timeline/Comment";
import { RequestAddCCs, RequestAddCCsVariables } from "./typings/RequestAddCCs";
import { RequestRemoveCCs, RequestRemoveCCsVariables } from "./typings/RequestRemoveCCs";

const ccRow = css`
  align-items: center;

  & .avatar-overlap:not(:first-child) {
    margin-left: -0.625rem;
  }

  & .edit {
    display: none;
  }

  &:hover .edit {
    display: inline-block;
  }
`;

const REQUEST_ADD_CCS = gql`
  mutation RequestAddCCs($requestId: ID!, $ccEmails: [String!]!) {
    requestAddCCs(requestId: $requestId, ccEmails: $ccEmails) {
      code
      success
      message
    }
  }
`;

const REQUEST_REMOVE_CCS = gql`
  mutation RequestRemoveCCs($requestId: ID!, $ccEmails: [String!]!) {
    requestRemoveCCs(requestId: $requestId, ccEmails: $ccEmails) {
      code
      success
      message
    }
  }
`;

/** Number of CC avatar to display */
const numCCAvatarDisplay = 4;

export function AssignCCs(props: PollingRequestData) {
  const [showModal, setShowModal] = useState(false);
  const dismiss = () => setShowModal(false);
  const [_requestAddCCs, addResponse] = useMutation<RequestAddCCs, RequestAddCCsVariables>(REQUEST_ADD_CCS, {
    refetchQueries: [
      {
        query: REQUEST_GET,
        variables: {
          id: props.requestResponse.data?.request.id
        }
      }
    ]
  });
  const [_requestRemoveCCs, removeResponse] = useMutation<RequestRemoveCCs, RequestRemoveCCsVariables>(
    REQUEST_REMOVE_CCS,
    {
      refetchQueries: [
        {
          query: REQUEST_GET,
          variables: {
            id: props.requestResponse.data?.request.id
          }
        }
      ]
    }
  );
  const { emitSnack } = useSnack();

  const currentCCs = ((props.requestResponse.data && props.requestResponse.data.request.CCs) || []).reduce((a, c) => {
    return c.CCs || a;
  }, [] as RequestGet_request_CCs_CCs[]);
  const addCCs = (ccEmails: string[], onSuccess: () => void) => {
    if (props.requestResponse.data) {
      _requestAddCCs({
        variables: {
          requestId: props.requestResponse.data.request.id,
          ccEmails
        },
        update: (cache, response) => {
          if (response.data && response.data.requestAddCCs.success) {
            onSuccess();
          } else if (response.data && response.data.requestAddCCs.message) {
            emitSnack({
              type: "mutationError",
              message: response.data.requestAddCCs.message
            });
          }
        }
      });
    }
  };
  const removeCC = (ccEmail: string, onSuccess: () => void) => {
    if (props.requestResponse.data) {
      _requestRemoveCCs({
        variables: {
          requestId: props.requestResponse.data.request.id,
          ccEmails: [ccEmail]
        },
        update: (cache, response) => {
          if (response.data && response.data.requestRemoveCCs.success) {
            onSuccess();
          } else if (response.data && response.data.requestRemoveCCs.message) {
            emitSnack({
              type: "mutationError",
              message: response.data.requestRemoveCCs.message
            });
          }
        }
      });
    }
  };
  return !props.requestResponse.data ? null : (
    <>
      {(props.requestResponse.loading || addResponse.loading || removeResponse.loading) && <LoadingBar />}
      <Row margin="0 0 0.5rem">
        <Subheading>Email CCs</Subheading>
      </Row>
      <Row css={ccRow} data-intercom-target={currentCCs.length > 0 ? "Email CCs" : ""}>
        {currentCCs.length === 0 && (
          <SimpleTooltip placement="top" label={"Add email CCs"}>
            <LegacyPill
              onClick={() => setShowModal(true)}
              iconPath={mdiAccountPlus}
              padding={"0 0.4375rem"}
              margin={"0"}
              data-intercom-target="Email CCs: Empty"
            />
          </SimpleTooltip>
        )}
        {currentCCs.slice(0, numCCAvatarDisplay).map((user, i) => (
          <div key={i} className="avatar-overlap" style={{ zIndex: currentCCs.length - i }}>
            <SimpleTooltip placement="top" maxWidth="auto" label={<UserEmailLabel user={user} />}>
              <UserAvatar shadow sizeM user={user} />
            </SimpleTooltip>
          </div>
        ))}
        {currentCCs.length > numCCAvatarDisplay && (
          <SimpleTooltip
            placement="top"
            maxWidth="auto"
            label={
              <>
                {currentCCs.slice(numCCAvatarDisplay).map(user => (
                  <div key={user.id}>
                    <UserEmailLabel user={user} />
                  </div>
                ))}
              </>
            }
          >
            <Avatar sizeL>+{currentCCs.length - numCCAvatarDisplay}</Avatar>
          </SimpleTooltip>
        )}
        {currentCCs.length > 0 && (
          <Button className="edit" onClick={() => setShowModal(true)} margin="0 0 0 0.5rem">
            Edit
          </Button>
        )}
      </Row>

      <Modal isOpen={showModal} onDismiss={dismiss}>
        <Dialog medium onClose={dismiss}>
          {currentCCs.length === 0 && (
            <AddCCs
              onAdd={emails => {
                addCCs(emails, dismiss);
              }}
              dismiss={dismiss}
            />
          )}
          {currentCCs.length > 0 && (
            <EditCCs
              requestId={props.requestId}
              requestResponse={props.requestResponse}
              currentCCs={currentCCs}
              dismiss={dismiss}
              onRemove={email => {
                removeCC(email, () => null);
              }}
              onAdd={email => {
                addCCs([email], () => null);
              }}
            />
          )}
        </Dialog>
      </Modal>
    </>
  );
}

function AddCCs(props: { onAdd(ccEmails: string[]): void; dismiss(): void }) {
  const [CCs, setCCs] = useState<SelectUserValue[]>([]);
  return (
    <>
      <Row>
        <Typo.Body sizeXXL>Add CCs</Typo.Body>
      </Row>
      <Row margin="0.25rem 0 2rem 0">
        CCs receive all messages from you or the requester via email. They can contribute to the conversation by
        answering to the email.
      </Row>
      <SelectCreateUsers filterUsers={[]} selectedUsers={CCs} setSelectedUsers={setCCs} />
      <Row justify="flex-end" margin="2rem 0 0 0">
        <Button size="large" onClick={props.dismiss}>
          Cancel
        </Button>
        <Button
          size="large"
          variant="primary"
          onClick={() => props.onAdd(CCs.map(f => f.email))}
          disabled={CCs.length === 0}
        >
          Add
        </Button>
      </Row>
    </>
  );
}

const EditCCRow = styled(Row)`
  margin: 0.25rem 0;
  align-items: center;

  &:hover ${Button} {
    display: inline-flex;
  }

  & ${Button} {
    display: none;
  }
`;

function EditCCs(
  props: PollingRequestData & {
    currentCCs: RequestGet_request_CCs_CCs[];
    onRemove(email: string): void;
    onAdd(email: string): void;
    dismiss(): void;
  }
) {
  const [showSelect, setShowSelect] = useState(false);
  return !props.requestResponse.data ? null : (
    <>
      <Row>
        <Typo.Body sizeXXL>Edit CCs</Typo.Body>
      </Row>
      <Row
        css={css`
          margin: var(--space-1-rem) 0 var(--space-6-rem);
        `}
      >
        CCs receive all messages from your or the requester via email. They can contribute to the conversation by
        answering to the email.
      </Row>
      {props.currentCCs.map((cc, i) => (
        <EditCCRow key={i}>
          <UserAvatar
            sizeM
            css={css`
              margin: 0 0.75rem 0 0;
            `}
            user={cc}
          />
          {cc.name ? `${cc.name} (${cc.email})` : cc.email}
          <Row flex="1 0 auto" align="center" justify="flex-end">
            <SquareButton onClick={() => props.onRemove(cc.email)}>
              <MaterialIcon path={mdiClose} size={1.25} />
            </SquareButton>
          </Row>
        </EditCCRow>
      ))}
      <Row margin="0.75rem 0 1rem 0">
        {!showSelect && (
          <Button size="medium" onClick={() => setShowSelect(true)}>
            <MaterialIcon path={mdiPlus} size={1} /> Add CCs
          </Button>
        )}
        {showSelect && (
          <SelectCreateUsers
            key={props.currentCCs.join()}
            onBlur={() => setShowSelect(false)}
            filterUsers={props.currentCCs}
            selectedUsers={[]}
            setSelectedUsers={CCs => {
              if (CCs.length === 0) return reportDevError("No CCs");
              props.onAdd(CCs[0].email);
            }}
          />
        )}
      </Row>
      <Row justify="flex-end" margin="1rem 0 0 0">
        <Button variant="primary" size="large" onClick={props.dismiss}>
          Done
        </Button>
      </Row>
    </>
  );
}
