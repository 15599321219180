import * as Sentry from "@sentry/browser";
import React, { ErrorInfo, FC, useState } from "react";
import { useQuery } from "react-apollo";
import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from "react-error-boundary";
import { CURRENT_USER } from "src/App/Root/providers/CurrentUserProvider";
import { CurrentUser } from "src/App/Root/providers/typings/CurrentUser";
import { ConfirmDialogModal } from "src/components";
import { ErrorCode } from "src/util/apollo/links";

Sentry.init({
  environment: process.env.REACT_APP_ENVIRONMENT,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  ignoreErrors: [ErrorCode.UNAUTHENTICATED],
  release: `ohm@${process.env.REACT_APP_VERSION}`,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === "ui.click" && hint) {
      const { target } = hint.event;
      if (target.innerHTML) {
        breadcrumb.message = `selector: ${breadcrumb.message} - innerHTML: ${target.innerHTML}`;
      }
      if (target.getAttribute("data-test-id")) {
        breadcrumb.message = `${breadcrumb.message} - test-id: ${target.getAttribute("data-test-id")}`;
      }
    }
    return breadcrumb;
  }
});

const CurrentUserData = (props: { children: (data?: CurrentUser) => React.ReactElement }) => {
  const currentUserResponse = useQuery<CurrentUser>(CURRENT_USER);
  return props.children(currentUserResponse.data);
};

/** Hooks not supported inside FallbackRenderer */
const ReportErrorModal = ({ error, resetErrorBoundary, eventId }: FallbackProps & { eventId?: string }) => {
  const reload = () => window.location.reload();
  return (
    <CurrentUserData>
      {data => (
        <ConfirmDialogModal
          isOpen
          handleCancel={() => {
            resetErrorBoundary();
            reload();
          }}
          handleConfirm={() => {
            Sentry.showReportDialog({
              eventId: eventId,
              user: {
                name: data?.currentUser?.name,
                email: data?.currentUser?.email
              }
            });
          }}
          text={{
            heading: "Oops",
            confirm: "Submit Report",
            cancel: "Reload App"
          }}
          buttonVariant="primary"
        >
          {error.message ?? "Looks like something went wrong"}
        </ConfirmDialogModal>
      )}
    </CurrentUserData>
  );
};

export const SentryErrorBoundary: FC = props => {
  const [eventId, setEventId] = useState<string | undefined>();

  const myErrorHandler = (error: Error, info: ErrorInfo) => {
    Sentry.configureScope(scope => {
      for (const key in info) {
        scope.setExtra(key, info[key as keyof typeof info]);
      }
    });
    setEventId(Sentry.captureException(error));
  };

  return (
    <ReactErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }: FallbackProps) => (
        // Pass the eventId to the user feedback dialog after capturing the exception in sentry
        <ReportErrorModal error={error} resetErrorBoundary={resetErrorBoundary} eventId={eventId} />
      )}
      onError={myErrorHandler}
    >
      {props.children}
    </ReactErrorBoundary>
  );
};
