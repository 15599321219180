import { css } from "@emotion/core";
import styled from "@emotion/styled";
import * as React from "react";
import { Col, Row } from "src/components";
import { PopOver } from "src/components/PopOver";
import { stackOrder } from "src/styling/layout";
import { fontSizes } from "src/styling/typography";
import { csx } from "src/util/csx";

const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 0.3125rem solid transparent;
  border-right: 0.3125rem solid transparent;
  border-bottom: 0.3125rem solid var(--text-6);
`;

const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 0.3125rem solid transparent;
  border-right: 0.3125rem solid transparent;
  border-top: 0.3125rem solid var(--text-6);
`;

const TooltipWrapper = styled.div`
  box-sizing: border-box;
  max-width: 14.75rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background: var(--text-6);
  color: var(--hover);
  font-size: ${fontSizes.tooltip}rem;
  line-height: 150%;
`;

const TooltipArrow = csx(
  [
    css`
      width: 0;
      height: 0;
      border-left: 0.3125rem solid transparent;
      border-right: 0.3125rem solid transparent;
      border-bottom: 0.3125rem solid var(--text-6);
      display: none;
    `
  ],
  {
    bottom: css`
      transform: rotate(180deg);
    `,
    right: css`
      transform: translateX(-0.1875rem) rotate(90deg);
    `,
    left: css`
      transform: translateX(0.1875rem) rotate(270deg);
    `,
    hide: css`
      visibility: hidden;
    `
  }
);

const detectPlacement = css`
  &[data-placement="top"] .show-placement-top {
    display: unset;
  }

  &[data-placement="bottom"] .show-placement-bottom {
    display: unset;
  }

  &[data-placement="left"] .show-placement-left {
    display: unset;
  }

  &[data-placement="right"] .show-placement-right {
    display: unset;
  }
`;

export function Tooltip(
  props: React.PropsWithChildren<{
    target: JSX.Element;
    placement?: "top" | "bottom";
    hideArrow?: boolean;
    // pass down css prop for z-index, max-width and other styles
    className?: string;
  }>
) {
  if (!props.children) return props.target;
  return (
    <PopOver.Blank
      hover={true}
      button={props.target}
      placement={props.placement || "top"}
      className={props.className}
      css={detectPlacement}
    >
      <Row align="center" margin="0.25rem 0">
        <TooltipArrow left hide={props.hideArrow} className="show-placement-right" />
        <Col align="center">
          <TooltipArrow hide={props.hideArrow} className="show-placement-bottom" />
          <TooltipWrapper>{props.children}</TooltipWrapper>
          <TooltipArrow bottom hide={props.hideArrow} className="show-placement-top" />
        </Col>
        <TooltipArrow right hide={props.hideArrow} className="show-placement-left" />
      </Row>
    </PopOver.Blank>
  );
}

const TooltipContainer = styled.div<{ width?: string; placement: "top" | "bottom" }>`
  position: relative;

  & > .tooltip {
    display: none;
    position: absolute;
    top: ${p => (p.placement === "top" ? "-0.25rem" : "auto")};
    left: 50%;
    bottom: ${p => (p.placement === "bottom" ? "-0.25rem" : "auto")};
    width: ${p => p.width || "14.75rem"};
    transform: ${p => (p.placement === "top" ? "translateY(-100%)" : "translateY(100%)")} translateX(-50%);
  }

  & > .tooltip.persist {
    display: flex;
  }

  &:hover > .tooltip.hoverable {
    display: flex;
  }
`;

const TooltipContent = styled.div<{ width?: string; zIndex?: keyof typeof stackOrder }>`
  z-index: ${p => stackOrder[p.zIndex || "high"]};
  box-sizing: border-box;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background: var(--text-6);
  color: var(--hover);
  font-size: ${fontSizes.tooltip}rem;
  line-height: 150%;
  user-select: none;
`;

/** absolute positioned tooltip */
export function AbsoluteTooltip(
  props: React.PropsWithChildren<{
    content?: React.ReactNode;
    placement?: "top" | "bottom";
    disable?: boolean;
    zIndex?: keyof typeof stackOrder;
    width?: string;
    className?: string;
    style?: React.CSSProperties;
    persist?: boolean;
  }>
) {
  const placement = props.placement ?? "top";
  return (
    <TooltipContainer className={props.className} style={props.style} width={props.width} placement={placement}>
      {props.children}
      {props.content && !props.disable && (
        <Col
          className={`tooltip ${props.persist === true ? "persist" : ""} ${
            props.persist === undefined ? "hoverable" : ""
          }`}
          align="center"
          zIndex={props.zIndex}
        >
          {placement === "bottom" && <ArrowUp />}
          <TooltipContent zIndex={props.zIndex}>{props.content}</TooltipContent>
          {placement === "top" && <ArrowDown />}
        </Col>
      )}
    </TooltipContainer>
  );
}
