import { css } from "@emotion/core";
import { EditorState } from "draft-js";
import "draft-js-mention-plugin/lib/plugin.css";
import "draft-js/dist/Draft.css";
import * as React from "react";
import { useReducer } from "react";
import { AttachmentsDropZone } from "src/App/Attachments/DropZone";
import { filesReducer } from "src/App/Attachments/reducer";
import { FormAction, FormState } from "src/App/Forms/Manager/reducer";
import { documentsReducer, initialDocumentState } from "src/App/KB/Detail/reducer";
import { initialSavedReplyState, savedRepliesReducer } from "src/App/KB/Detail/savedRepliesReducer";
import { RequestGet_request } from "src/App/Requests/DetailView/typings/RequestGet";
import { FeatureFlags, useFeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import { Row } from "src/components";
import { fog } from "src/styling/effects";
import { stackOrder } from "src/styling/layout";
import { fontWeights } from "src/styling/typography";
import { csx } from "src/util/csx";
import { INIT_STATE, reducer } from "./Approvals";
import { EditorArea } from "./EditorArea";
import { FileStack } from "./FileStack";
import { initialInputState, inputReducer } from "./reducer";

const Wrapper = csx([
  css`
    z-index: ${stackOrder.high};
    border-width: 1px;
    border-style: solid;
    border-color: var(--border);
    border-radius: var(--border-radius-s);
    position: relative;

    &:focus-within {
      border-color: var(--text-3);
    }

    & label {
      display: block;
      font-weight: ${fontWeights.regular};
      color: var(--text-4);
    }

    & ${Row} {
      position: relative;
    }
  `,
  fog.top.shadow
]);

interface IInputBarProps {
  request: RequestGet_request;
  timelineRef: React.RefObject<HTMLElement>;
  formState: FormState;
  formDispatch: React.Dispatch<FormAction>;
  inputBarRef: React.RefObject<HTMLDivElement>;
  initialEditorState?: EditorState;
}

/**
 * Stateless component
 * @param props should inherit props from parent detail view
 */
export function InputBar(props: IInputBarProps) {
  const [filesState, filesDispatch] = useReducer(filesReducer, { files: [] });
  const [documentState, documentDispatch] = useReducer(documentsReducer, initialDocumentState);
  const [inputState, inputDispatch] = useReducer(inputReducer, initialInputState());
  const [approvalState, approvalDispatch] = useReducer(reducer, INIT_STATE);
  const [savedReplyState, savedReplyDispatch] = useReducer(savedRepliesReducer, initialSavedReplyState);
  const { hasFeatureFlags } = useFeatureFlags();
  const hasExternalMentions = inputState.externals.length > 0;
  const attachmentsDisabledReason = hasExternalMentions
    ? "You can’t add attachments when external people are notified"
    : undefined;

  return (
    <>
      {hasFeatureFlags(FeatureFlags.RICHTEXTREPLIES) ? (
        <div
          css={[
            css`
              position: relative;
            `
          ]}
          className="print-hidden"
          id="actionbar-wrapper"
          ref={props.inputBarRef}
          data-intercom-target="Request message box"
        >
          {(inputState.inputType.includes("COMMENTS") || inputState.inputType.includes("NOTES")) && (
            <AttachmentsDropZone
              filesState={filesState}
              filesDispatch={filesDispatch}
              disabledReason={attachmentsDisabledReason}
            />
          )}
          <EditorArea
            request={props.request}
            key={inputState.inputKey}
            inputState={inputState}
            inputDispatch={inputDispatch}
            filesState={filesState}
            filesDispatch={filesDispatch}
            initialEditorState={props.initialEditorState}
            formState={props.formState}
            formDispatch={props.formDispatch}
            documentState={documentState}
            documentDispatch={documentDispatch}
            approvalState={approvalState}
            approvalDispatch={approvalDispatch}
            savedReplyState={savedReplyState}
            savedReplyDispatch={savedReplyDispatch}
          />
        </div>
      ) : (
        <Wrapper
          className="print-hidden"
          id="actionbar-wrapper"
          ref={props.inputBarRef}
          data-intercom-target="Request message box"
        >
          <FileStack filesState={filesState} filesDispatch={filesDispatch} />
          <Row>
            {(inputState.inputType === "COMMENTS" || inputState.inputType === "NOTES") && (
              <AttachmentsDropZone filesState={filesState} filesDispatch={filesDispatch} />
            )}
            <EditorArea
              request={props.request}
              key={inputState.inputKey}
              inputState={inputState}
              inputDispatch={inputDispatch}
              filesState={filesState}
              filesDispatch={filesDispatch}
              initialEditorState={props.initialEditorState}
              formState={props.formState}
              formDispatch={props.formDispatch}
              documentState={documentState}
              documentDispatch={documentDispatch}
              approvalState={approvalState}
              approvalDispatch={approvalDispatch}
              savedReplyState={savedReplyState}
              savedReplyDispatch={savedReplyDispatch}
            />
          </Row>
        </Wrapper>
      )}
    </>
  );
}
