import { Field, Formik } from "formik";
import * as React from "react";
import { MutationFunction } from "react-apollo";
import { Button, FormikInput, Row, SubmitButton } from "src/components";
import { Form } from "src/components/Fields/FormWrappers";
import { navigate } from "src/util/router";
import * as yup from "yup";
import { UserStartPasswordRecovery, UserStartPasswordRecoveryVariables } from "./typings/UserStartPasswordRecovery";

/**
 * Validation rules
 */
const FormSchema = yup.object().shape({
  email: yup.string().email("Must be a valid email").required("Required")
});

export const StartPasswordRecoveryForm: React.ComponentType<{
  startPasswordRecovery: MutationFunction<UserStartPasswordRecovery, UserStartPasswordRecoveryVariables>;
  loginEmail: string;
  loading: boolean;
}> = props => (
  <Formik
    enableReinitialize={true}
    onSubmit={payload => {
      props.startPasswordRecovery({ variables: { params: payload } });
    }}
    initialValues={{
      email: props.loginEmail
    }}
    validationSchema={FormSchema}
  >
    {form => (
      <Form onSubmit={form.handleSubmit} padding="0" style={{ width: "20rem" }}>
        <Field name="email" component={FormikInput} label="E-mail address" placeholder="Enter your e-mail" />
        <Row justify="flex-end" margin="1rem 0 0">
          <Button
            size="large"
            margin="0 0.25rem 0 0"
            onClick={() => {
              navigate("/login");
            }}
          >
            Back
          </Button>
          <SubmitButton disabled={props.loading}>Reset password</SubmitButton>
        </Row>
      </Form>
    )}
  </Formik>
);
