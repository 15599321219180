import styled from "@emotion/styled";
import gql from "graphql-tag";
import * as React from "react";
import { Query } from "react-apollo";
import { LoadingBar } from "src/components";
import { ORG_INFO_FRAGMENT } from "src/fragments/OrgInfoFragment";
import { USER_INFO_FRAGMENT } from "src/fragments/UserInfoFragment";
import { Toast } from "src/portals/Toast";
import { TeamUpdateSubmission } from "./Submission";
import { GSettingsTeamGet, GSettingsTeamGetVariables } from "./typings/GSettingsTeamGet";

const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 28rem;
  background: var(--white);
`;

export const SETTINGS_TEAM_GET = gql`
  query GSettingsTeamGet($id: ID!) {
    currentUser {
      ...UserInfo
      organization {
        ...OrgInfo
      }
    }
    team(id: $id) {
      id
      name
      slug
      privacy
      internal
      users {
        ...UserInfo
      }
      categories {
        id
        name
      }
      slackChannels {
        slackName
        slackId
      }
      workingHours {
        id
        dayOfWeek
        startHour
        startMinute
        endHour
        endMinute
      }
      timezone
    }
    slackChannels {
      slackName
      slackId
      isAssigned
    }
  }
  ${USER_INFO_FRAGMENT}
  ${ORG_INFO_FRAGMENT}
`;

export interface ITeamUpdateProps {
  /**
   * Team.id uuid
   */
  team_id: string;
  /**
   * path to redirect after succesful update
   */
  redirect: string;
}

/**s
 * Container for editing team details
 * Queries team fields to edit
 */
export const TeamUpdateContainer: React.FunctionComponent<ITeamUpdateProps> = props => (
  <Wrapper>
    <Query<GSettingsTeamGet, GSettingsTeamGetVariables> query={SETTINGS_TEAM_GET} variables={{ id: props.team_id }}>
      {({ data, error, loading }) => {
        if (loading) {
          return <LoadingBar />;
        }
        if (error) {
          return <Toast message={error.message} kind="error" />;
        }
        return (!loading && data && data.team && <TeamUpdateSubmission {...props} {...data} />) || null;
      }}
    </Query>
  </Wrapper>
);
