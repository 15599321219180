import { css } from "@emotion/core";
import { mdiDelete, mdiMagnify, mdiPlus } from "@mdi/js";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import { Views } from "src/App/Requests/ListView/typings/Views";
import { VIEWS } from "src/App/Requests/ListView/ViewContainer";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { showModal } from "src/App/Root/Routes";
import {
  GRequestListCounts,
  GRequestListCountsVariables,
  GRequestListCounts_requestListCounts
} from "src/App/Sidebar/typings/GRequestListCounts";
import { ReactComponent as DropdownIndicator } from "src/assets/DropdownIndicator.svg";
import { ConfirmDialogModal, MaterialIcon, SquareButton } from "src/components";
import { Details } from "src/components/Details";
import { RequestListView } from "src/globalTypes";
import { body, fontSize } from "src/styling/primitives";
import { Typo } from "src/styling/primitives/typography";
import { csx } from "src/util/csx";
import { kebab } from "src/util/formatters";
import { Link, Location, navigate, RouterProps } from "src/util/router";
import { useSearchDialog } from "../Requests/Search/Dialog";
import { CountBadge } from "./CountBadge";
import { sidebarMenuItem, sidebarMenuItemSelected } from "./Sidebar";
import { SavedViewDelete, SavedViewDeleteVariables } from "./typings/SavedViewDelete";

export const REQUEST_LIST_COUNTS = gql`
  query GRequestListCounts($userId: String) {
    requestListCounts(userId: $userId) {
      id
      count
    }
  }
`;

const MenuHeader = csx(
  [
    body.regular,
    fontSize.S,
    css`
      display: flex;
      align-items: center;
      list-style: none;
      height: 2rem;
      padding: 0 var(--space-5-rem) 0 var(--space-5-rem);
      user-select: none;

      & * {
        color: var(--text-4);
      }

      & path {
        fill: var(--text-4);
      }

      &:hover {
        cursor: pointer;
        background: var(--sand-1);
      }

      &:focus {
        box-sizing: border-box;
        outline: none;
        background: var(--sand-1);
      }

      & .title {
        flex: 1 0 auto;
        padding-left: 0.5rem;
      }

      & .indicator {
        transform: rotate(-0.25turn);
        transition: transform 0.2s ease-in-out 0s;
      }
    `
  ],
  {
    open: css`
      & .indicator {
        transform: rotate(0turn);
        transition: transform 0.2s ease-in-out 0s;
      }
    `
  }
);

export const RequestListCounts: React.ComponentType<{
  location: RouterProps["location"];
  currentUserId: string;
}> = props => {
  const searchDialog = useSearchDialog();
  const response = useQuery<GRequestListCounts, GRequestListCountsVariables>(REQUEST_LIST_COUNTS, {
    variables: { userId: props.currentUserId },
    pollInterval: Number(process.env.REACT_APP_POLLING_INTERVAL)
  });
  const viewsResponse = useQuery<Views>(VIEWS);
  const archiveView = viewsResponse.data?.views.find(v => v.listViewType === RequestListView.ARCHIVE);
  return (
    <Details
      defaultOpen
      summary={(toggle, isOpen) => (
        <MenuHeader onClick={toggle} open={isOpen}>
          <DropdownIndicator className="indicator" />
          <span className="title">Requests</span>
          <Location>
            {locationContext => (
              <SquareButton
                data-testid="create-request"
                size="small"
                css={css`
                  margin: 0 var(--space-1-rem) 0 0;
                `}
                onClick={e => {
                  e.stopPropagation();
                  showModal(locationContext);
                }}
              >
                <MaterialIcon path={mdiPlus} size={1.125} />
              </SquareButton>
            )}
          </Location>
          <SquareButton
            onClick={e => {
              e.stopPropagation();
              searchDialog.open();
            }}
            size="small"
          >
            <MaterialIcon path={mdiMagnify} size={1.125} />
          </SquareButton>
        </MenuHeader>
      )}
    >
      <div data-testid="request-list-counts">
        {viewsResponse.data?.views
          .filter(v => v.listViewType !== RequestListView.ARCHIVE)
          .map(v => {
            return (
              <MenuRequestViewSelectorItem
                key={v.id}
                id={v.id}
                isDefault={v.isDefault}
                title={v.name}
                requestListCounts={response.data?.requestListCounts}
                location={props.location}
              />
            );
          })}
      </div>
      <div
        // hand-calculated to draw line above archive
        css={css`
          height: 1px;
          margin: 6px 24px 6px 46px;
          background: var(--lightGrey-3);
        `}
      />
      {archiveView && (
        <MenuRequestViewSelectorItem
          id={archiveView.id}
          isDefault
          title={archiveView.name}
          requestListCounts={response.data?.requestListCounts}
          location={props.location}
        />
      )}
    </Details>
  );
};

const CountBadgeContainer = csx([
  css`
    min-height: var(--space-5-rem);
    display: flex;
    align-items: center;
    justify-content: center;
  `
]);

/**
 * Menu item for each request view w/ counts
 */
function MenuRequestViewSelectorItem(props: {
  id: string;
  location: RouterProps["location"];
  requestListCounts: GRequestListCounts_requestListCounts[] | undefined;
  title: string;
  isDefault: boolean;
}) {
  const path = `/view/${props.id}`;
  const requestListCount = props.requestListCounts?.find(({ id }) => id === props.id);
  const selected = !!props.location && props.location.pathname.startsWith(path);
  const { emitSnack } = useSnack();

  const [showDeleteView, setShowDeleteView] = useState(false);
  const [deleteView, deleteViewResponse] = useMutation<SavedViewDelete, SavedViewDeleteVariables>(
    gql`
      mutation SavedViewDelete($id: ID!) {
        savedViewDelete(id: $id) {
          success
          message
          code
        }
      }
    `,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: VIEWS }],
      onCompleted: data => {
        if (data.savedViewDelete.success) {
          if (props.location?.pathname.includes(props.id)) {
            navigate("/view/me");
          }
          emitSnack({
            type: "info",
            message: "View deleted"
          });
          setShowDeleteView(false);
        } else {
          emitSnack({
            type: "mutationError",
            message: data.savedViewDelete.message
          });
        }
      }
    }
  );

  return (
    <Link
      to={path}
      css={[
        sidebarMenuItem,
        css`
          padding-left: 3rem;

          & > * + * {
            margin-left: var(--space-1-rem);
          }

          & button {
            color: var(--text-4);
            visibility: hidden;
          }

          &:hover button {
            visibility: visible;
          }
        `,
        selected && sidebarMenuItemSelected
      ]}
    >
      <Typo.Body
        ellipsis
        css={css`
          color: var(--text-4);
          flex-grow: 1;
        `}
      >
        {props.title}
      </Typo.Body>
      {!props.isDefault && (
        <SquareButton
          size="small"
          onClick={e => {
            e.preventDefault();
            setShowDeleteView(true);
          }}
        >
          <MaterialIcon path={mdiDelete} size={1} />
        </SquareButton>
      )}
      {requestListCount && (requestListCount.count ?? -1) > 0 && (
        <CountBadgeContainer>
          <CountBadge className="count" selected={selected} data-testid={`${kebab(props.title)}-count`}>
            {requestListCount.count}
          </CountBadge>
        </CountBadgeContainer>
      )}
      <ConfirmDialogModal
        isOpen={showDeleteView}
        loading={deleteViewResponse.loading}
        text={{
          heading: "Delete view",
          cancel: "Cancel",
          confirm: "Delete view"
        }}
        handleConfirm={() =>
          deleteView({
            variables: {
              id: props.id
            }
          })
        }
        handleCancel={() => setShowDeleteView(false)}
      >
        Are you sure you want to delete the view <i>{props.title}</i>?
      </ConfirmDialogModal>
    </Link>
  );
}
