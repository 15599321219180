import { css } from "@emotion/core";
import { Widget } from "@typeform/embed-react";
import * as React from "react";
import { Dialog } from ".";
import { Modal } from "../portals/Modal";

type TypeformModalProps = {
  title: string;
  isShown: boolean;
  onClose: () => void;
  formId: string;
  hiddenFields?: { [name: string]: string };
  onTypeformSubmit?: (...args: Array<{ response_id: string }>) => void;
  testId?: string;
};
export const TypeformModal: React.FC<TypeformModalProps> = ({
  title,
  isShown,
  onClose,
  formId,
  hiddenFields,
  onTypeformSubmit = onClose,
  testId = "typeform-modal",
  ...rest // e.g., css prop
}) => {
  return (
    <Modal onDismiss={onClose} isOpen={isShown}>
      <Dialog
        title={title}
        onClose={onClose}
        css={css`
          width: 800px;
        `}
      >
        <Widget
          id={formId}
          hidden={hiddenFields}
          hideHeaders
          hideFooters
          opacity={0}
          buttonText="Start"
          onSubmit={onTypeformSubmit}
          css={css`
            height: 450px;
          `}
          {...rest}
        />
      </Dialog>
    </Modal>
  );
};
