import { css } from "@emotion/core";
import { compareDesc, differenceInSeconds } from "date-fns";
import React, { useContext, useMemo, useRef, useEffect } from "react";
import { ActionsContext } from "src/App/Requests/Actions/Provider";
import { EventHighlight } from "src/App/Requests/components";
import { PollingRequestData } from "src/App/Requests/DetailView/Container";
import {
  RequestGet_request_events,
  RequestGet_request_events_approvalAddedEvent,
  RequestGet_request_events_approvalResolvedEvent,
  RequestGet_request_events_data,
  RequestGet_request_events_data_ApprovalAddedEvent_author
} from "src/App/Requests/DetailView/typings/RequestGet";
import { DhlRequestGet_request_events_data } from "src/App/Requests/DHLView/typings/DhlRequestGet";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { CurrentUser } from "src/App/Root/providers/typings/CurrentUser";
import { TimelineMessage, TimelineUpdate } from "src/App/Timeline/Universal";
import { UserName } from "src/App/User";
import { Button } from "src/components";
import { ColorVariantsUnion, Status } from "src/components/StatusIndicator";
import { ApprovalStatus, EventName } from "src/globalTypes";
import { statusText } from "src/util/formatters";
import { ApprovalAddedComponent, ApprovalResolvedComponent } from "./Approvals";
import { aggregateCCChanges, CCEditEvent, CCEditEventComponent } from "./CC";
import { NoteComponent } from "./Note";
import { UpdateComponent } from "./Update";

/**
 * Given an approval ID and a list of request events, returns the current status of the
 * approval with the provided event. If the provided list of events contains an
 * APPROVALRESOLVED event for the provided approval ID, the status contained in this
 * event will be returned. Otherwise, this function returns APPROVAL_STATUS_PENDING.
 *
 * @example
 *   const events = [
 *     {
 *       id: "f00",
 *       eventName: "APPROVALRESOLVED",
 *       approvalResolvedEvent: {
 *         status: "APPROVED",
 *         ...
 *       },
 *       ...
 *     },
 *     {
 *       id: "b4r",
 *       eventName: "APPROVALRESOLVED",
 *       approvalResolvedEvent: {
 *         status: "REJECTED",
 *         ...
 *       },
 *       ...
 *     },
 *     ...
 *   ];
 *   getApprovalStatus("f00", events);
 *   // => "APPROVED"
 *   getApprovalStatus("b4r", events);
 *   // => "REJECTED"
 *   getApprovalStatus("b4z", events);
 *   // => "PENDING"
 *
 * @param approvalId The ID of the approval whose current status should be determined
 * @param events The event list from which the approval status should be extracted
 */
function getApprovalStatus(approvalId: string, events: RequestGet_request_events[]): ApprovalStatus {
  const approvalEvents = events.filter(
    el => el.approvalResolvedEvent && el.approvalResolvedEvent.approvalId === approvalId
  );
  // cast type to prevent non null assertion
  return (
    (
      approvalEvents[approvalEvents.length - 1]
        ?.approvalResolvedEvent as RequestGet_request_events_approvalResolvedEvent
    )?.status || ApprovalStatus.APPROVAL_STATUS_PENDING
  );
}

/**
 * Given an approval ID and a list of request events, returns the ID of the original author of the
 * approval with the provided event. If the provided list of events contains an
 * APPROVALADDED event for the provided approval ID, the author of this
 * event will be returned. Otherwise, this function returns null.
 */
function getApprovalAuthor(
  approvalId: string,
  events: RequestGet_request_events[]
): RequestGet_request_events_data_ApprovalAddedEvent_author {
  const approvalEvents = events.filter(el => el.approvalAddedEvent && el.approvalAddedEvent.approvalId === approvalId);
  // cast type to prevent non null assertion
  return (approvalEvents[approvalEvents.length - 1].approvalAddedEvent as RequestGet_request_events_approvalAddedEvent)
    .author;
}

type TimelineEventData = RequestGet_request_events | CCEditEvent;

export function mergeRequestCreatedEvents<
  E extends {
    timestamp: string;
    eventName: EventName;
    data: RequestGet_request_events_data | DhlRequestGet_request_events_data;
  }
>(events: E[], requesterId: string, authorId: string): E[] {
  const [firstEvent, secondEvent] = events;
  if (
    events.length >= 2 &&
    firstEvent.data.__typename === "RequestCreatedEvent" &&
    secondEvent.data.__typename === "CommentAddedEvent" &&
    new Date(secondEvent.timestamp).getTime() - new Date(firstEvent.timestamp).getTime() <= 1000 /* milliseconds */ &&
    (secondEvent.data.author.id === authorId || secondEvent.data.author.id === requesterId)
  ) {
    // This createsa merged event of type RequestCreatedEvent and content from CommentAddedEvent.
    // That data is being rendered in RequestCreatedMessage, which is basically a comment without
    // Banner and original title abovethe text.
    const commentAddedData = secondEvent.data;
    return [
      {
        ...firstEvent,
        data: {
          ...commentAddedData,
          __typename: "RequestCreatedEvent"
        }
      },
      ...events.slice(2)
    ];
  } else {
    return events;
  }
}

/* processEvents transforms the raw event timeline into the sequence of events we display to the user. It does:
 * 1. merge the first two events if they both result from the request creation
 * 2. group CC add/removal events together if they're close enough in time
 */
function processEvents(
  events: RequestGet_request_events[],
  requesterId: string,
  authorId: string
): TimelineEventData[] {
  return (mergeRequestCreatedEvents(events, requesterId, authorId) as TimelineEventData[])
    .concat(aggregateCCChanges(events))
    .sort((a, b) => compareDesc(new Date(a.timestamp), new Date(b.timestamp)))
    .sort((a, b) => b.revision - a.revision);
}

/**
 * Maps events to respective components
 * @param props inherits props from parent
 * @returns fn that takes event object and array index
 * @returns timeline detail component
 */
const eventComponentMapper =
  (props: TimelineProps, currentUser: CurrentUser["currentUser"]) => (event: TimelineEventData, i: number) => {
    if (!props.requestResponse.data || !props.requestResponse.data.request) return null;
    switch (event.eventName) {
      case EventName.NOTEADDED:
        return (
          event.noteAddedEvent && (
            <NoteComponent
              key={i}
              requestId={props.requestResponse.data.request.id}
              id={event.noteAddedEvent.noteId}
              text={event.noteAddedEvent.text}
              author={event.noteAddedEvent.author}
              requester={props.requestResponse.data.request.requester}
              team={props.requestResponse.data.request.team}
              timestamp={event.timestamp}
              deleted={event.noteAddedEvent.deleted || false}
              attachmentsList={event.noteAddedEvent.attachmentsList}
              onAttachmentDelete={props.onNoteAttachmentDelete}
              hasHtml={!!event.noteAddedEvent?.hasHtml}
            />
          )
        );
      case EventName.APPROVALADDED:
        return (
          event.approvalAddedEvent && (
            <ApprovalAddedComponent
              key={i}
              requestId={props.requestResponse.data.request.id}
              id={event.approvalAddedEvent.approvalId}
              text={event.approvalAddedEvent.text}
              teamName={props.requestResponse.data.request.team.name}
              author={event.approvalAddedEvent.author}
              requester={props.requestResponse.data.request.requester}
              approver={event.approvalAddedEvent.approver}
              timestamp={event.timestamp}
              status={getApprovalStatus(event.approvalAddedEvent.approvalId, props.requestResponse.data.request.events)}
            />
          )
        );
      case EventName.APPROVALRESOLVED:
        return (
          event.approvalResolvedEvent && (
            <ApprovalResolvedComponent
              key={i}
              requester={props.requestResponse.data.request.requester}
              author={getApprovalAuthor(
                event.approvalResolvedEvent.approvalId,
                props.requestResponse.data.request.events
              )}
              teamName={props.requestResponse.data.request.team.name}
              text={event.approvalResolvedEvent.text}
              approverComment={event.approvalResolvedEvent.approverComment}
              approver={event.approvalResolvedEvent.approver}
              status={event.approvalResolvedEvent.status}
              timestamp={event.timestamp}
            />
          )
        );
      case EventName.STATUSUPDATED:
        if (event.statusUpdatedEvent) {
          return (
            <UpdateComponent
              key={i}
              authorId={event.statusUpdatedEvent.author.id}
              requesterId={props.requestResponse.data.request.requester.id}
              timestamp={event.timestamp}
            >
              <div
                css={css`
                  display: flex;
                `}
              >
                <UserName user={event.statusUpdatedEvent.author} />
                &nbsp;changed the status to&nbsp;
                <div
                  css={css`
                    position: relative;
                    top: -1px;
                  `}
                >
                  <Status.Indicator
                    condensed
                    requestStatus={event.statusUpdatedEvent.newCustomStatus?.step ?? event.statusUpdatedEvent.newStatus}
                    statusText={
                      event.statusUpdatedEvent.newCustomStatus?.name ?? statusText(event.statusUpdatedEvent.newStatus)
                    }
                    customColor={event.statusUpdatedEvent.newCustomStatus?.color as ColorVariantsUnion}
                    hasInteractions={false}
                  />
                </div>
              </div>
            </UpdateComponent>
          );
        } else return null;
      case EventName.ASSIGNMENTCHANGED:
        if (!event.assignmentChangedEvent) return null;
        const isAuthor = event.assignmentChangedEvent.author.id === currentUser?.id;
        const isAssignedToCurrent = event.assignmentChangedEvent.user?.id === currentUser?.id;
        return event.assignmentChangedEvent.autoAssign && event.assignmentChangedEvent.user?.id ? (
          <UpdateComponent
            key={i}
            authorId={event.assignmentChangedEvent.author.id}
            requesterId={props.requestResponse.data.request.requester.id}
            timestamp={event.timestamp}
          >
            {event.assignmentChangedEvent.user?.id && (
              <>
                Request was automatically assigned to&nbsp;
                <EventHighlight>
                  <UserName user={event.assignmentChangedEvent.user} />
                </EventHighlight>
                &nbsp;based on {isAssignedToCurrent ? "your" : "their"} activity
                {differenceInSeconds(new Date(), new Date(event.timestamp)) < 30 &&
                  !!props.requestResponse.data.request.assignee && (
                    <>
                      &nbsp;&bull;
                      <UndoAutoAssign requestId={props.requestResponse.data.request.id} />
                    </>
                  )}
              </>
            )}
          </UpdateComponent>
        ) : (
          <UpdateComponent
            key={i}
            authorId={event.assignmentChangedEvent.author.id}
            requesterId={props.requestResponse.data.request.requester.id}
            timestamp={event.timestamp}
          >
            {event.assignmentChangedEvent.user?.id && (
              <div>
                <UserName user={event.assignmentChangedEvent.author} />
                &nbsp;assigned the request to&nbsp;
                <EventHighlight>
                  <UserName user={event.assignmentChangedEvent.user} you={isAuthor ? "Yourself" : "You"} />
                </EventHighlight>
              </div>
            )}
            {!event.assignmentChangedEvent.user?.id && (
              <div>
                <UserName user={event.assignmentChangedEvent.author} />
                &nbsp;unassigned the request
              </div>
            )}
          </UpdateComponent>
        );
      case EventName.TEAMASSIGNMENTCHANGED:
        return (
          event.teamAssignmentChangedEvent && (
            <UpdateComponent
              key={i}
              authorId={event.teamAssignmentChangedEvent.author.id}
              requesterId={props.requestResponse.data.request.requester.id}
              timestamp={event.timestamp}
            >
              <UserName user={event.teamAssignmentChangedEvent.author} />
              &nbsp;moved the request to&nbsp;
              <EventHighlight>{event.teamAssignmentChangedEvent.newTeam.name}</EventHighlight>
            </UpdateComponent>
          )
        );
      case EventName.UNSETEVENTNAME:
        return null;
      case EventName.COMMENTDELETED:
        return null;
      case EventName.NOTEDELETED:
        return null;
      case EventName.CCSADDED:
        return null;
      case EventName.CCSREMOVED:
        return null;
      case "CCEdit":
        return <CCEditEventComponent key={i} event={event} requester={props.requestResponse.data.request.requester} />;
      default:
        // FIXME: why check?
        return (
          event.data && (
            <React.Fragment key={i}>
              <TimelineMessage
                backUserId={props.requestResponse.data?.backUser.id}
                request={props.requestResponse.data?.request}
                initialRequest={props.requestResponse.data?.initialRequestRevision}
                event={event}
                onCommentAttachmentDelete={props.onCommentAttachmentDelete}
              />
              <TimelineUpdate
                request={props.requestResponse.data?.request}
                initialRequest={props.requestResponse.data?.initialRequestRevision}
                event={event}
              />
            </React.Fragment>
          )
        );
    }
  };

type TimelineProps = PollingRequestData & {
  onCommentAttachmentDelete: (commentId: string, attachmentId: string) => void;
  onNoteAttachmentDelete: (noteId: string, attachmentId: string) => void;
};

/**
 * @param props should inherit props from parent detail view
 */
export const Timeline: React.ComponentType<TimelineProps> = props => {
  // Current user context has to be consumed here and passed on down to prevent error:
  // Rendered more hooks than during the previous render.
  const { currentUser } = useCurrentUser();
  const divRef = useRef<HTMLDivElement | null>(null);

  const events = useMemo(() => {
    return processEvents(
      props.requestResponse.data?.request?.events || [],
      props.requestResponse.data?.request?.requester?.id || "",
      props.requestResponse.data?.request?.author?.id || ""
    ).reverse();
  }, [props.requestResponse]);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [events.length]);

  return (
    <div
      ref={divRef}
      css={css`
        flex: 0 1 auto;
        height: auto;
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        // we used flex-direction: column-reverse before, but in Chrome version 108 there is an issue
        // content/text is not rendered ( is blank ) correctly in a view with scoll ( overflow-y: auto ) in our case
        // example of the issue is https://jsfiddle.net/k6p34xuc/29/
        flex-direction: column;
        padding: 0 var(--space-6-rem) 0 var(--space-6-rem);
        & > *:last-child > div {
          // last child is the first element in the timeline due to column reverse
          margin-top: var(--space-4-rem);
        }
        @media print {
          max-height: none;
          margin-top: 0;
          padding: var(--space-4-rem) var(--space-6-rem);
        }
      `}
    >
      {events && events.map(eventComponentMapper(props, currentUser))}
    </div>
  );
};

const UndoAutoAssign: React.FC<{ requestId: string }> = props => {
  const { handlers } = useContext(ActionsContext);
  return (
    <Button size="small" display="inline-block" onClick={() => handlers.assignExpert(props.requestId, null, null)}>
      Undo
    </Button>
  );
};
