import { css } from "@emotion/core";
import * as React from "react";
import { textLink } from "src/styling/primitives/typography";
import { backMarkdownToHtml } from "src/util/formatters";

export const KBMarkdownView: React.FC<{
  md: string;
  className?: string;
}> = props => {
  return (
    <div
      css={css`
        word-break: break-word;
        & a {
          ${textLink}
        }
      `}
      className={props.className}
      dangerouslySetInnerHTML={{
        __html:
          // sanitized
          backMarkdownToHtml(props.md)
      }}
    />
  );
};
