import React from "react";
export const Bookmark = () => (
  <svg id="Layer_1" version="1.1" viewBox="0 0 14 16" x="0" y="0" xmlSpace="preserve">
    <path
      className="st0"
      d="M.7 3.8c-.4 0-.7.3-.7.7v9.1c0 .8.6 1.4 1.4 1.4h9.1c.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7H1.4V4.5c0-.4-.3-.7-.7-.7z"
    />
    <path
      className="st0"
      d="M12.6 1H4.2c-.8 0-1.4.6-1.4 1.4v8.4c0 .8.6 1.4 1.4 1.4h8.4c.8 0 1.4-.6 1.4-1.4V2.4c0-.8-.6-1.4-1.4-1.4zm-1.9 6l-1.2-.8-1.3.8c-.2.1-.5 0-.5-.3V2.4h3.5v4.3c0 .3-.3.4-.5.3z"
    />
  </svg>
);
