import { css } from "@emotion/core";
import { mdiClose } from "@mdi/js";
import { captureException } from "@sentry/browser";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { ReactComponent as PushIcon } from "src/assets/PushIcon.svg";
import { IconButton, LinkTypeButton, MaterialIcon } from "src/components";
import { trackNotifSnack } from "src/util/analytics";
import { browserPushSettings } from "src/util/services/pushNotifications";

const AnimatedBanner: React.FC<{
  delayed?: boolean;
}> = props => (
  <motion.div
    css={css`
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      width: 100%;
      height: 2.5rem;
      color: var(--text-6);
      background: var(--lightBlue-2);
      z-index: var(--z-high);
    `}
    initial={{
      transform: "translate3d(0px, -2.5rem, 0px)",
      height: 0,
      opacity: 0
    }}
    animate={{
      transition: { duration: 0.3, delay: props.delayed ? 1 : 0 },
      transform: "translate3d(0px, 0px, 0px)",
      height: "2.5rem",
      opacity: 1
    }}
    exit={{
      transition: { duration: 0 },
      transform: "translate3d(0px, -2.5rem, 0px)",
      height: 0,
      opacity: 0
    }}
  >
    {props.children}
  </motion.div>
);

const blueHover = css`
  &:hover {
    background-color: rgba(var(--blue-2-raw), 0.075);
  }
`;

enum BannerState {
  init,
  clickedX
}

export const PushBanner: React.FC<{}> = () => {
  const { emitSnack } = useSnack();

  const handleError = (e: Error) => {
    emitSnack({
      type: "mutationError",
      message: "Error updating your push notification preferences"
    });
    captureException(e);
  };
  const [, rerender] = useState();
  const [bannerState, setBannerState] = useState<BannerState>(BannerState.init);
  const { currentUser } = useCurrentUser();
  const pushSettings = browserPushSettings(currentUser?.id);

  useEffect(() => {
    if (pushSettings) {
      pushSettings.onPageLoad().catch(captureException);
      pushSettings.listen(({ type }) => {
        if (type === "pushSettingDidChange") rerender(true);
      });
    }
  }, [pushSettings]);

  const shouldShowBanner =
    // only show for experts (defined here as users in a team)
    !!currentUser?.teams.length &&
    "PushManager" in window &&
    Notification.permission === "default" &&
    pushSettings &&
    !pushSettings.isDisabled;

  if (!shouldShowBanner) {
    return null;
  }

  return (
    <>
      {bannerState === BannerState.init && (
        <AnimatedBanner delayed>
          <div
            css={css`
              flex-grow: 0;
            `}
          />
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              flex-grow: 1;
            `}
          >
            <PushIcon
              css={css`
                margin: 1px 6px 0 0;
              `}
            />
            Back needs your permission to&nbsp;
            <LinkTypeButton
              css={blueHover}
              onClick={async () => {
                await pushSettings?.enable().catch(handleError);
                rerender(true);
                trackNotifSnack("enabled");
              }}
            >
              enable desktop notifications
            </LinkTypeButton>
          </div>
          <div
            css={css`
              flex-grow: 0;
            `}
          >
            <IconButton
              onClick={() => {
                setBannerState(BannerState.clickedX);
              }}
              css={css`
                color: var(--black);
              `}
            >
              <MaterialIcon path={mdiClose} size={1} />
            </IconButton>
          </div>
        </AnimatedBanner>
      )}
      {bannerState === BannerState.clickedX && (
        <AnimatedBanner>
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              flex-grow: 1;
            `}
          >
            <PushIcon
              css={css`
                margin: 1px 6px 0 0;
              `}
            />
            <span
              css={css`
                margin-right: var(--space-4-px);
              `}
            >
              We recommend you enable notifications if you’re using Back on this computer.
            </span>
            <LinkTypeButton
              css={blueHover}
              onClick={async () => {
                trackNotifSnack("enabled");
                await pushSettings?.enable().catch(handleError);
                rerender(true);
              }}
            >
              Enable desktop notifications
            </LinkTypeButton>
            &nbsp;•&nbsp;
            <LinkTypeButton
              css={blueHover}
              onClick={() => {
                pushSettings?.dismiss();
                rerender(true);
                trackNotifSnack("ignored");
              }}
            >
              Never ask me again
            </LinkTypeButton>
            &nbsp;•&nbsp;
            <LinkTypeButton
              css={blueHover}
              onClick={() => {
                pushSettings?.snooze();
                rerender(true);
                trackNotifSnack("snoozed");
              }}
            >
              Ask me later
            </LinkTypeButton>
          </div>
        </AnimatedBanner>
      )}
    </>
  );
};
