import gql from "graphql-tag";
import React, { createContext, useCallback, useMemo, useState } from "react";
import { useApolloClient, useMutation, useQuery } from "react-apollo";
import { REQUEST_LIST_FRAGMENT } from "src/App/Requests/ListView/RequestListFragment";
import { RequestListFragment } from "src/App/Requests/ListView/typings/RequestListFragment";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { ConfirmDialogModal } from "src/components";
import { OptionGroup, OptionMap, OptionsUnion } from "src/components/PopOver";
import { ColorVariantsUnion, Status } from "src/components/StatusIndicator";
import { TEAM_INFO_FRAGMENT } from "src/fragments/TeamInfoFragment";
import { RequestTeamInfo } from "src/fragments/typings/RequestTeamInfo";
import { UserInfo } from "src/fragments/typings/UserInfo";
import { USER_INFO_FRAGMENT } from "src/fragments/UserInfoFragment";
import { RequestPriority, TeamPrivacy } from "src/globalTypes";
import { trackReqCategoryAssigned, trackRequestAssigned, trackRequestPriorityChanged } from "src/util/analytics";
import { formatDueDate } from "src/util/formatters";
import { navigate } from "src/util/router";
import { priorityLabels } from "./RequestPriorityButton";
import { RequestAssignCategory, RequestAssignCategoryVariables } from "./typings/RequestAssignCategory";
import { RequestAssignExpert, RequestAssignExpertVariables } from "./typings/RequestAssignExpert";
import { RequestChangeCustomStatus, RequestChangeCustomStatusVariables } from "./typings/RequestChangeCustomStatus";
import { RequestChangeDueDate, RequestChangeDueDateVariables } from "./typings/RequestChangeDueDate";
import { RequestChangeRequester, RequestChangeRequesterVariables } from "./typings/RequestChangeRequester";
import { RequestChangeTeam, RequestChangeTeamVariables } from "./typings/RequestChangeTeam";
import { RequestPriorityChange, RequestPriorityChangeVariables } from "./typings/RequestPriorityChange";
import { TeamListOptions } from "./typings/TeamListOptions";

const OPTIMISTIC_RES_CODE = 420;

const initialHandlers = {
  assignCategory: (
    requestId: string,
    newCategoryId: string | null,
    oldCategoryId: string | null,
    onAssign: () => void
  ) => {
    void 0;
  },
  assignExpert: (requestId: string, newExpert: string | null, oldExpert: string | null) => {
    void 0;
  },
  changeCustomStatus: (requestId: string, customStatusId: string) => {
    void 0;
  },
  changeDueDate: (requestId: string, dueAt: isoDateTimeString | null) => {
    void 0;
  },
  changeRequester: (requestId: string, requesterId: string) => {
    void 0;
  },
  changeTeam: (requestId: string, newTeamId: string, oldTeam: RequestTeamInfo) => {
    void 0;
  },
  setPriority: (requestId: string, newPriority: RequestPriority, oldPriority: RequestPriority) => {
    void 0;
  }
};

const initialContext: {
  handlers: typeof initialHandlers;
  options: {
    [action in keyof typeof initialHandlers]?: OptionsUnion;
  };
  optionsByTeam: {
    [action in keyof typeof initialHandlers]?: (team: RequestTeamInfo) => OptionsUnion | null;
  };
} = {
  handlers: initialHandlers,
  // initialize as empty arrays
  options: {},
  optionsByTeam: {}
};

export const ActionsContext = createContext(initialContext);

export const RequestActions: React.FC<{}> = props => {
  const { currentUser } = useCurrentUser();
  const currentUserId = currentUser?.id ?? null;
  const [assignCategory, assignCategoryOptions] = useAssignCategory();
  const [assignExpert, assignExpertOptions] = useAssignExpert(currentUserId);
  const [changeCustomStatus, changeCustomStatusOptions] = useChangeCustomStatus();
  const [changeTeam, changeTeamOptions, changeTeamConfirm] = useChangeTeam(currentUserId);
  const [setPriority, setPriorityOptions] = useSetPriority();
  const [changeRequester] = useChangeRequester();
  const [changeDueDate] = useChangeDueDate();
  return (
    <ActionsContext.Provider
      value={{
        options: {
          changeTeam: changeTeamOptions,
          setPriority: setPriorityOptions
        },
        optionsByTeam: {
          assignCategory: assignCategoryOptions,
          assignExpert: assignExpertOptions,
          changeCustomStatus: changeCustomStatusOptions
        },
        handlers: {
          assignCategory,
          assignExpert,
          changeCustomStatus,
          changeDueDate,
          changeTeam,
          setPriority,
          changeRequester
        }
      }}
    >
      <ChangeTeamFlow
        state={changeTeamConfirm.state}
        confirm={changeTeamConfirm.confirm}
        cancel={changeTeamConfirm.cancel}
      />
      {props.children}
    </ActionsContext.Provider>
  );
};

/* pull request data from cache */
function useGetRequestFragmentFromCache() {
  const client = useApolloClient();
  return useCallback(
    (id: string) => {
      return client.readFragment<RequestListFragment>({
        id: `Request:${id}`,
        fragment: REQUEST_LIST_FRAGMENT,
        fragmentName: "RequestListFragment"
      });
    },
    [client]
  );
}

/* pull user data from cache */
function useGetUserFragmentFromCache() {
  const client = useApolloClient();
  return useCallback(
    (id: string) => {
      return client.readFragment<UserInfo>({
        id: `User:${id}`,
        fragment: USER_INFO_FRAGMENT,
        fragmentName: "UserInfo"
      });
    },
    [client]
  );
}

/* pull user data from cache */
function useGetTeamFragmentFromCache() {
  const client = useApolloClient();
  return useCallback(
    (id: string) => {
      return client.readFragment<RequestTeamInfo>({
        id: `Team:${id}`,
        fragment: TEAM_INFO_FRAGMENT,
        fragmentName: "TeamInfo"
      });
    },
    [client]
  );
}

function useChangeCustomStatus() {
  const { emitSnack } = useSnack();

  const options = (team: RequestTeamInfo) => {
    return [
      {
        headline: "Change status",
        options:
          team.customStatuses.map(({ id, name, step, color }) => ({
            id,
            name,
            icon: (
              <Status.Indicator
                plain={true}
                requestStatus={step}
                customColor={color as ColorVariantsUnion}
                hasInteractions={false}
              />
            )
          })) ?? []
      }
    ];
  };

  const [requestChangeCustomStatus] = useMutation<RequestChangeCustomStatus, RequestChangeCustomStatusVariables>(
    gql`
      mutation RequestChangeCustomStatus($requestId: ID!, $customStatusId: ID!) {
        requestChangeCustomStatus(requestId: $requestId, customStatusId: $customStatusId) {
          code
          success
          message
          request {
            id
            customStatus {
              id
            }
          }
        }
      }
    `,
    {
      onCompleted: data => {
        if (data.requestChangeCustomStatus.success === false) {
          emitSnack({
            message: data.requestChangeCustomStatus.message,
            type: "mutationError"
          });
        } else if (data.requestChangeCustomStatus.success) {
          emitSnack({
            message: `Request status updated.`,
            type: "info"
          });
        }
      }
    }
  );
  const handler = useCallback(
    (requestId: string, customStatusId: string) => {
      requestChangeCustomStatus({
        variables: {
          requestId,
          customStatusId
        },
        optimisticResponse: {
          requestChangeCustomStatus: {
            __typename: "RequestMutationRes",
            code: OPTIMISTIC_RES_CODE,
            message: "OK",
            success: true,
            request: {
              id: requestId,
              __typename: "Request",
              customStatus: {
                __typename: "CustomStatus",
                id: customStatusId
              }
            }
          }
        }
      });
    },
    [requestChangeCustomStatus]
  );
  return [handler, options] as const;
}

function useAssignExpert(currentUserId: string | null) {
  const { emitSnack } = useSnack();

  const getUserFragment = useGetUserFragmentFromCache();
  const getRequestFragment = useGetRequestFragmentFromCache();
  const getTeamFragment = useGetTeamFragmentFromCache();
  const options = useCallback(
    (team: RequestTeamInfo) => {
      const nonCurrentUserOptions =
        team.users
          .filter(u => !!u.joinTime && u.id !== currentUserId)
          .sort((x, y) => x.name.localeCompare(y.name))
          .map(
            user =>
              ({
                id: user.id,
                name: !!user.name ? user.name : user.email,
                testId: "request-assignee-item"
              } as OptionMap)
          ) ?? [];
      return [
        {
          headline: "Change assignee",
          // only show "You" if in team
          options: (!(currentUserId && team.users.map(({ id }) => id).includes(currentUserId))
            ? []
            : ([
                {
                  id: currentUserId,
                  name: "You",
                  testId: "request-assignee-item"
                }
              ] as OptionMap[])
          ).concat(nonCurrentUserOptions)
        }
      ].filter(o => (o.options?.length ?? 0) > 0) as OptionsUnion;
    },
    [currentUserId]
  );
  const [requestAssignExpert] = useMutation<RequestAssignExpert, RequestAssignExpertVariables>(
    gql`
      mutation RequestAssignExpert($requestId: ID!, $oldExpert: ID, $newExpert: ID!) {
        requestAssignExpert(requestId: $requestId, oldExpert: $oldExpert, newExpert: $newExpert) {
          code
          success
          message
          request {
            id
            assignee {
              ...UserInfo
            }
          }
        }
      }
      ${USER_INFO_FRAGMENT}
    `,
    {
      onCompleted: data => {
        if (data.requestAssignExpert.success === false) {
          emitSnack({
            message: data.requestAssignExpert.message,
            type: "mutationError"
          });
        } else if (data.requestAssignExpert.request) {
          const requestId = data.requestAssignExpert.request.id;
          if (data.requestAssignExpert.request.assignee?.id) {
            const assigneeUserFragment = getUserFragment(data.requestAssignExpert.request.assignee.id);
            const formattedName =
              currentUserId === assigneeUserFragment?.id ? "you" : assigneeUserFragment?.name || "new expert";
            emitSnack({
              message: `Request assigned to ${formattedName}.`,
              type: "info"
            });
          } else {
            emitSnack({
              message: "Request unassigned.",
              type: "info"
            });
          }

          const teamId = getRequestFragment(data.requestAssignExpert.request.id)?.team.id ?? "err";
          const teamSlug = (teamId && getTeamFragment(teamId)?.slug) ?? "err";
          trackRequestAssigned(requestId, teamId, teamSlug, data.requestAssignExpert.request.assignee?.id ?? null);
        }
      }
    }
  );
  const handler = useCallback(
    (requestId: string, newExpert: string | null, oldExpert: string | null) => {
      requestAssignExpert({
        variables: {
          requestId,
          oldExpert: oldExpert ?? "",
          newExpert: newExpert ?? ""
        },
        optimisticResponse: {
          requestAssignExpert: {
            __typename: "RequestMutationRes",
            code: OPTIMISTIC_RES_CODE,
            message: "OK",
            success: true,
            request: {
              id: requestId,
              __typename: "Request",
              assignee: newExpert ? getUserFragment(newExpert) : null
            }
          }
        }
      });
    },
    [requestAssignExpert, getUserFragment]
  );
  return [handler, options] as const;
}

type ChangeTeamState = {
  requestId: string;
  old: RequestTeamInfo;
  new: RequestTeamInfo;
  isMemberOfNewTeam: boolean;
} | null;

function useChangeTeam(currentUserId: string | null) {
  const { emitSnack } = useSnack();

  const [confirmState, setConfirmState] = useState<ChangeTeamState>(null);
  const [storedTeamId, setStoredTeamId] = useState<string>("");
  const teamListOptionsResponse = useQuery<TeamListOptions>(gql`
    query TeamListOptions {
      teamList {
        ...TeamInfo
      }
    }
    ${TEAM_INFO_FRAGMENT}
  `);
  const getTeamFragment = useGetTeamFragmentFromCache();
  const options = useMemo(() => {
    return teamListOptionsResponse.data?.teamList.map(t => ({
      id: t.id,
      name: t.name
    }));
  }, [teamListOptionsResponse]);

  const [requestChangeTeam] = useMutation<RequestChangeTeam, RequestChangeTeamVariables>(
    gql`
      mutation RequestChangeTeam($oldTeamId: ID!, $newTeamId: ID!, $requestId: ID!) {
        requestChangeTeam(oldTeamId: $oldTeamId, newTeamId: $newTeamId, requestId: $requestId) {
          code
          message
          success
          request {
            id
            team {
              id
            }
          }
        }
      }
    `,
    {
      onCompleted: data => {
        if (data.requestChangeTeam.success === false) {
          emitSnack({
            message: data.requestChangeTeam.message,
            type: "mutationError"
          });
        } else if (data.requestChangeTeam.success) {
          // use storedTeamId for user not in new team & not requester
          const teamInfo = getTeamFragment(data.requestChangeTeam.request?.team.id || storedTeamId);
          emitSnack({
            message: `Request assigned to team ${teamInfo?.name}`,
            type: "info"
          });
        }
        setStoredTeamId("");
      }
    }
  );
  const changeTeam = useCallback(
    (requestId: string, newTeamId: string, oldTeamId: string) => {
      requestChangeTeam({
        variables: {
          requestId,
          oldTeamId,
          newTeamId
        },
        optimisticResponse: {
          requestChangeTeam: {
            __typename: "RequestMutationRes",
            code: OPTIMISTIC_RES_CODE,
            message: "OK",
            success: true,
            request: {
              __typename: "Request",
              id: requestId,
              team: {
                __typename: "Team",
                id: newTeamId
              }
            }
          }
        }
      });
    },
    [requestChangeTeam]
  );
  const handler = useCallback(
    (requestId: string, newTeamId: string, currentTeam: RequestTeamInfo) => {
      const newTeam = getTeamFragment(newTeamId);
      const isMemberOfNewTeam = !!newTeam?.users.map(({ id }) => id).includes(currentUserId || "");

      if (currentUserId && newTeam && currentTeam) {
        if (currentTeam.privacy !== newTeam.privacy || !isMemberOfNewTeam) {
          setConfirmState({
            requestId,
            new: newTeam,
            old: currentTeam,
            isMemberOfNewTeam
          });
        } else {
          changeTeam(requestId, newTeam.id, currentTeam.id);
        }
      } else {
        throw new Error("Unable to access mutation parameters");
      }
    },
    [changeTeam, getTeamFragment, currentUserId]
  );
  const confirm = () => {
    if (confirmState) {
      changeTeam(confirmState.requestId, confirmState.new.id, confirmState.old.id);
      setStoredTeamId(confirmState.new.id);
      setConfirmState(null);
    }
  };
  const cancel = () => {
    setConfirmState(null);
  };
  return [
    handler,
    [{ headline: "Change team", options }] as OptionGroup[],
    {
      state: confirmState,
      confirm,
      cancel
    }
  ] as const;
}

const ChangeTeamFlow: React.FC<{
  state: ChangeTeamState;
  confirm: () => void;
  cancel: () => void;
}> = props => {
  if (!props.state) return null;
  const isNotMemberOfNewTeam = !props.state.isMemberOfNewTeam;
  const isMovingToPrivateTeamFromPublic =
    props.state.new.privacy === TeamPrivacy.PRIVATE && props.state.old.privacy === TeamPrivacy.PUBLIC;
  const isMovingToPublicTeamFromPrivate =
    props.state.new.privacy === TeamPrivacy.PUBLIC && props.state.old.privacy === TeamPrivacy.PRIVATE;
  return (
    <ConfirmDialogModal
      isOpen={true}
      text={{
        cancel: "No thanks, cancel",
        confirm: "Yes continue",
        heading: "Re-assign request warning"
      }}
      handleCancel={props.cancel}
      handleConfirm={props.confirm}
    >
      <div data-testid="team-reassign-warning">
        {isMovingToPrivateTeamFromPublic && (
          <p>
            You are moving the request from a <strong>public team</strong> to a <strong>private team</strong>. This may
            prevent non-team members from accessing the request.
          </p>
        )}
        {isMovingToPublicTeamFromPrivate && (
          <p>
            You are moving the request from a <strong>private team</strong> to a <strong>public team</strong>. This will
            allow <strong>anyone</strong> to have access.
          </p>
        )}
        {isNotMemberOfNewTeam && (
          <p>
            You are moving the request to a team you are not a member of. You will no longer have access to the request.
          </p>
        )}
        <p>Are you sure you want to continue and re-assign the request?</p>
      </div>
    </ConfirmDialogModal>
  );
};

function useAssignCategory() {
  const { emitSnack } = useSnack();

  const getRequestFragment = useGetRequestFragmentFromCache();
  const getTeamFragment = useGetTeamFragmentFromCache();
  const options = (team: RequestTeamInfo) => {
    const categories = team.categories;
    return categories?.length === 0
      ? [
          {
            headline: "No categories",
            options: [
              {
                id: "",
                name: "Create new categories",
                onClick: e => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate(`/settings/teams/${team.id}/edit`);
                }
              } as OptionMap
            ]
          }
        ]
      : [
          {
            headline: "Change category",
            options:
              categories?.map(({ id, name }) => ({
                testId: "request-category-item",
                id,
                name
              })) ?? []
          } as OptionGroup
        ];
  };

  const [requestAssignCategory] = useMutation<RequestAssignCategory, RequestAssignCategoryVariables>(
    gql`
      mutation RequestAssignCategory($requestId: ID!, $oldCategoryId: ID, $newCategoryId: ID) {
        requestAssignCategory(requestId: $requestId, oldCategoryId: $oldCategoryId, newCategoryId: $newCategoryId) {
          code
          success
          message
          request {
            id
            category {
              id
            }
          }
        }
      }
    `,
    {
      onCompleted: data => {
        if (data && !data.requestAssignCategory.success) {
          emitSnack({
            message: data.requestAssignCategory.message,
            type: "mutationError"
          });
        } else if (data.requestAssignCategory.request) {
          const requestId = data.requestAssignCategory.request.id;
          const requestFragment = getRequestFragment(requestId);
          const teamId = requestFragment?.team.id ?? "err";
          const teamSlug = (teamId && getTeamFragment(teamId)?.slug) ?? "err";
          const category =
            requestFragment && requestFragment.category?.id === data.requestAssignCategory.request.category?.id
              ? requestFragment.category
              : null;
          trackReqCategoryAssigned(teamId, teamSlug, requestId, category?.name, category?.id);
        }
      }
    }
  );
  const handler = useCallback(
    (requestId: string, newCategoryId: string | null, oldCategoryId: string | null, onAssign: () => void) => {
      requestAssignCategory({
        variables: {
          requestId,
          newCategoryId,
          oldCategoryId
        },
        optimisticResponse: {
          requestAssignCategory: {
            __typename: "RequestMutationRes",
            code: OPTIMISTIC_RES_CODE,
            message: "OK",
            success: true,
            request: {
              id: requestId,
              __typename: "Request",
              category: !newCategoryId
                ? null
                : {
                    __typename: "Category",
                    id: newCategoryId
                  }
            }
          }
        },
        update: (_proxy, res) => {
          if (
            (res.data as RequestAssignCategory)?.requestAssignCategory.success &&
            (res.data as RequestAssignCategory)?.requestAssignCategory.code !== OPTIMISTIC_RES_CODE
          ) {
            onAssign();
          }
        }
      });
    },
    [requestAssignCategory]
  );
  return [handler, options] as const;
}

function useSetPriority() {
  const { emitSnack } = useSnack();

  const options = [
    {
      headline: "Set priority",
      options: Object.entries(priorityLabels)
        .filter(([id]) => id !== RequestPriority.PRIORITY_UNSPECIFIED)
        .map(([id, label]) => {
          return {
            id: id,
            name: label
          };
        })
    }
  ];

  const [requestChangePriority] = useMutation<RequestPriorityChange, RequestPriorityChangeVariables>(
    gql`
      mutation RequestPriorityChange($requestId: ID!, $priority: RequestPriority!) {
        requestChangePriority(requestId: $requestId, priority: $priority) {
          code
          success
          message
          request {
            id
            priority
          }
        }
      }
    `,
    {
      onCompleted: data => {
        if (data.requestChangePriority.success === false) {
          emitSnack({
            message: data.requestChangePriority.message,
            type: "mutationError"
          });
        } else if (data.requestChangePriority.success && data.requestChangePriority.request?.priority) {
          if (data.requestChangePriority.request?.priority === RequestPriority.PRIORITY_UNSPECIFIED) {
            emitSnack({
              type: "info",
              message: "Priority removed"
            });
          } else {
            emitSnack({
              type: "info",
              message: `Priority changed to ${priorityLabels[data.requestChangePriority.request?.priority]}`
            });
          }
        }
      }
    }
  );
  const handler = useCallback(
    (requestId: string, newPriority: RequestPriority, oldPriority: RequestPriority) => {
      requestChangePriority({
        variables: {
          requestId,
          priority: newPriority
        },
        optimisticResponse: {
          requestChangePriority: {
            __typename: "RequestMutationRes",
            code: OPTIMISTIC_RES_CODE,
            message: "OK",
            success: true,
            request: { id: requestId, __typename: "Request", priority: newPriority }
          }
        },
        update: (_, res) => {
          if (
            (res.data as RequestPriorityChange)?.requestChangePriority.success &&
            (res.data as RequestPriorityChange)?.requestChangePriority.code !== OPTIMISTIC_RES_CODE
          ) {
            trackRequestPriorityChanged(oldPriority, newPriority, requestId);
          }
        }
      });
    },
    [requestChangePriority]
  );
  return [handler, options] as const;
}

function useChangeRequester() {
  const { emitSnack } = useSnack();

  const getUserFragment = useGetUserFragmentFromCache();

  const [requestChangeRequester] = useMutation<RequestChangeRequester, RequestChangeRequesterVariables>(
    gql`
      mutation RequestChangeRequester($requestId: ID!, $requesterId: ID!) {
        requestChangeRequester(requestId: $requestId, requesterId: $requesterId) {
          code
          success
          message
          request {
            id
            requester {
              id
              name
              email
            }
          }
        }
      }
    `,
    {
      onCompleted: data => {
        if (data.requestChangeRequester.success === false) {
          emitSnack({
            message: data.requestChangeRequester.message,
            type: "mutationError"
          });
        } else if (data.requestChangeRequester.success && data.requestChangeRequester.request?.requester) {
          const name = data.requestChangeRequester.request.requester.name;
          const email = data.requestChangeRequester.request.requester.email;
          emitSnack({
            type: "info",
            message: `Requester changed to ${name === "" ? email : name}`
          });
        }
      }
    }
  );
  const handler = useCallback(
    (requestId: string, requesterId: string) => {
      requestChangeRequester({
        variables: {
          requestId,
          requesterId
        },
        optimisticResponse: {
          requestChangeRequester: {
            __typename: "RequestMutationRes",
            code: OPTIMISTIC_RES_CODE,
            message: "OK",
            success: true,
            request: {
              id: requestId,
              __typename: "Request",
              requester: getUserFragment(requesterId) ?? {
                __typename: "User",
                name: "...",
                email: "...",
                id: requesterId
              }
            }
          }
        }
      });
    },
    [requestChangeRequester, getUserFragment]
  );
  return [handler] as const;
}

function useChangeDueDate() {
  const { emitSnack } = useSnack();

  const [requestChangeDueDate] = useMutation<RequestChangeDueDate, RequestChangeDueDateVariables>(
    gql`
      mutation RequestChangeDueDate($requestId: ID!, $dueAt: DateTime) {
        requestChangeDueDate(requestId: $requestId, dueAt: $dueAt) {
          code
          message
          success
          request {
            id
            dueAt
          }
        }
      }
    `,
    {
      onCompleted: data => {
        if (data.requestChangeDueDate.success === false) {
          emitSnack({
            message: data.requestChangeDueDate.message,
            type: "mutationError"
          });
        } else if (data.requestChangeDueDate.success && data.requestChangeDueDate.request?.dueAt) {
          emitSnack({
            type: "info",
            message: `Request due date ${formatDueDate(data.requestChangeDueDate.request?.dueAt)}`
          });
        } else if (data.requestChangeDueDate.success && !data.requestChangeDueDate.request?.dueAt) {
          emitSnack({
            type: "info",
            message: "Request due date removed"
          });
        }
      }
    }
  );
  const handler = useCallback(
    (requestId: string, dueAt: isoDateTimeString | null) => {
      requestChangeDueDate({
        variables: {
          requestId,
          dueAt
        },
        optimisticResponse: {
          requestChangeDueDate: {
            __typename: "RequestMutationRes",
            code: OPTIMISTIC_RES_CODE,
            message: "OK",
            success: true,
            request: {
              id: requestId,
              __typename: "Request",
              dueAt
            }
          }
        }
      });
    },
    [requestChangeDueDate]
  );
  return [handler] as const;
}
