import gql from "graphql-tag";
import * as React from "react";
import { Mutation } from "react-apollo";
import { REQUEST_GET } from "src/App/Requests/DetailView/REQUEST_GET";
import {
  GRequestResolveApproval,
  GRequestResolveApprovalVariables
} from "src/App/Requests/DetailView/Timeline/typings/GRequestResolveApproval";
import { Button, LoadingBar } from "src/components";
import { Toast } from "src/portals/Toast";

export const REQUEST_RESOLVE_APPROVAL = gql`
  mutation GRequestResolveApproval($params: RequestResolveApprovalParameters!) {
    requestResolveApproval(params: $params) {
      code
      success
      message
    }
  }
`;

/**
 * Button to resolve an approval request
 * Contains mutation, loading, error handling logic
 */
export function ResolveApprovalButton(props: {
  params: GRequestResolveApprovalVariables["params"];
  requestId: string;
  text: string;
  disabled?: boolean;
  margin?: string;
  /**
   * Callback after comment submitted successfully
   */
  onSuccess?(): void;
}) {
  return (
    <Mutation<GRequestResolveApproval, GRequestResolveApprovalVariables>
      mutation={REQUEST_RESOLVE_APPROVAL}
      refetchQueries={[
        {
          query: REQUEST_GET,
          variables: {
            id: props.requestId
          }
        }
      ]}
    >
      {(resolveApproval, result) => (
        <>
          <Button
            variant="primary"
            disabled={props.disabled || result.loading}
            margin={props.margin}
            onClick={() =>
              resolveApproval({
                update: (cache, response) => {
                  if (response.data && response.data.requestResolveApproval.success && props.onSuccess) {
                    props.onSuccess();
                  }
                },
                variables: {
                  params: props.params
                }
              })
            }
          >
            {props.text}
          </Button>

          {result.loading && <LoadingBar />}
          {result.data && !result.data.requestResolveApproval.success && (
            <Toast kind="mutationError" message={result.data.requestResolveApproval.message} />
          )}
        </>
      )}
    </Mutation>
  );
}
