import gql from "graphql-tag";
import * as React from "react";
import { Mutation } from "react-apollo";
import { REQUEST_LIST_COUNTS } from "src/App/Sidebar/RequestListCounts";
import { Toast } from "src/portals/Toast";
import { SETTINGS_TEAM_LIST_GET } from "../Overview";
import { ITeamAddProps } from "./Container";
import { TeamAddForm } from "./Form";
import { GSettingsTeamAddGet } from "./typings/GSettingsTeamAddGet";
import { GSettingsTeamAddSend, GSettingsTeamAddSendVariables } from "./typings/GSettingsTeamAddSend";

export const SETTINGS_TEAM_ADD_SEND = gql`
  mutation GSettingsTeamAddSend($params: TeamUsersAdd!) {
    teamAdd(params: $params) {
      code
      message
      success
      invalid
    }
  }
`;

/**
 * Component to handle team invite mutation and submission logic
 */
export const TeamAddSubmission: React.ComponentType<GSettingsTeamAddGet & ITeamAddProps> = props => (
  <Mutation<GSettingsTeamAddSend, GSettingsTeamAddSendVariables>
    mutation={SETTINGS_TEAM_ADD_SEND}
    refetchQueries={[
      {
        query: SETTINGS_TEAM_LIST_GET
      },
      {
        query: REQUEST_LIST_COUNTS
      }
    ]}
  >
    {(teamAdd, response) => {
      return (
        <>
          <TeamAddForm {...props} teamAdd={teamAdd} />
          {response.data && !response.data.teamAdd.success && (
            <Toast message={response.data.teamAdd.message} kind="mutationError" />
          )}
        </>
      );
    }}
  </Mutation>
);
