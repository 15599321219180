import { css } from "@emotion/core";
import styled from "@emotion/styled";
import * as React from "react";
import { Row } from "src/components";
import { csx } from "src/util/csx";
import * as yup from "yup";
import { default as zxcvbn } from "zxcvbn";
/**
 * Check password strength
 * NB: loads ~400kb library
 */
export const passwordValidation = yup
  .string()
  .min(10, "Must be at least 10 characters")
  .test("is-strong", "Password not strong enough", val => {
    if (val && val.length >= 10) {
      return zxcvbn(val).score > 2;
    }
    return false;
  })
  .required("Required");

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0.5rem 2rem 0;
  padding: 0.5rem;
  border-radius: 2px;
  font-size: 0.875rem;
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid var(--border);
  box-shadow: 0 4px 8px 0 rgba(176, 190, 197, 0.2);
  box-sizing: border-box;
`;

const Bar = csx(
  [
    css`
      flex: 1 0 auto;
      height: 0.25rem;
      margin: 0.25rem;
      border-radius: 2px;
      background: var(--border);
    `
  ],
  {
    complete: css`
      background: var(--green-5);
    `
  }
);

/**
 * Provide visual feedback and suggestions for passwords
 * NB: loads ~400kb library
 */
export const PasswordStrength = (props: { value: string }) => {
  const result = props.value ? zxcvbn(props.value) : undefined;
  return result ? (
    <Wrapper>
      <Row>
        <Bar complete={result.score > 0} />
        <Bar complete={result.score > 1} />
        <Bar complete={result.score > 2} />
        <Bar complete={result.score > 3} />
      </Row>
      {props.value.length < 10 && <Row padding="0.25rem">Password must be at least 10 characters</Row>}
      {props.value.length > 9 && (
        <>
          {result.feedback.warning && <Row padding="0.25rem">{result.feedback.warning}</Row>}
          {result.feedback.suggestions.map((suggestion, i) => (
            <Row padding="0.25rem" key={i}>
              {suggestion}
            </Row>
          ))}
        </>
      )}
    </Wrapper>
  ) : null;
};
