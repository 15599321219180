import { css } from "@emotion/core";
import { captureException } from "@sentry/browser";
import { detect } from "detect-browser";
import { Formik } from "formik";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import { CURRENT_USER } from "src/App/Root/providers/CurrentUserProvider";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { CurrentUser } from "src/App/Root/providers/typings/CurrentUser";
import { BaseLayout, Button, FormikFieldGroup, FormikFieldInputs, LoadingBar } from "src/components";
import { NotificationChannel, NotificationSettingsUpdate } from "src/globalTypes";
import { useWarnBeforeUnload } from "src/hooks/useWindowEvents";
import { Toast } from "src/portals/Toast";
import { Typo } from "src/styling/primitives/typography";
import { keys } from "src/util";
import { trackNotifToggle } from "src/util/analytics";
import { browserPushSettings } from "src/util/services/pushNotifications";
import { NotificationSettings } from "./typings/NotificationSettings";
import {
  NotificationSettingsMutation,
  NotificationSettingsMutationVariables
} from "./typings/NotificationSettingsMutation";
import { NotificationsFragment } from "./typings/NotificationsFragment";

const NOTIFICATIONS_FRAGMENT = gql`
  fragment NotificationsFragment on NotificationSettings {
    notifyRequestAssignedTeam
    notifyRequestAssignedUser
    notifyRequestUpdated
    notifyUserMentioned
    notifyUserApprovalRequested
    emailDigest
    availableChannels
    preferredChannel
  }
`;

export const NotificationSettingsPage: React.FC<{}> = props => {
  const notificationsResponse = useQuery<NotificationSettings>(
    gql`
      query NotificationSettings {
        notificationSettings {
          ...NotificationsFragment
        }
      }
      ${NOTIFICATIONS_FRAGMENT}
    `,
    {
      notifyOnNetworkStatusChange: true
    }
  );
  return (
    <BaseLayout
      headline="Notifications"
      subline="In addition to displaying updates in Back you can manage which notifications you want to receive on which channels"
    >
      <Typo.Body bold>Notify me about</Typo.Body>
      {notificationsResponse.loading && <LoadingBar />}
      {notificationsResponse.error && <Toast kind="error" message={notificationsResponse.error.message} />}
      {/* remount on refetch */}
      {!notificationsResponse.loading && notificationsResponse.data && (
        <NotificationSettingsForm
          notificationSettings={notificationsResponse.data.notificationSettings}
          refetchNotificationSettings={() => notificationsResponse.refetch()}
        />
      )}
    </BaseLayout>
  );
};

const notificationSettingsInfo = {
  notifyRequestAssignedTeam: {
    title: "New requests",
    description: "You are notified when new requests are created in any of the teams you’re a member of"
  },
  notifyRequestAssignedUser: {
    title: "Requests assigned to me",
    description: "You are notified when you are assigned to requests"
  },
  notifyRequestUpdated: {
    title: "Request updates",
    description:
      "You are notified when requests you’re assigned to have updates. That includes replies, internal notes, form responses, and approval requests and responses"
  },
  notifyUserMentioned: {
    title: "New mentions",
    description: "You are notified when you’re mentioned in a request"
  },
  notifyUserApprovalRequested: {
    title: "Approval requests",
    description: "You are notified when you’re asked for approval in a request"
  }
} as const;

/* sync form setting state */
const SyncSettings: React.FC<{
  formState: NotificationSettingsUpdate;
  notificationSettings: NotificationSettingsUpdate;
  refetchNotificationSettings(): void;
}> = props => {
  const { emitSnack } = useSnack();

  const [notificationSettingsUpdate, notificationSettingsUpdateResponse] = useMutation<
    NotificationSettingsMutation,
    NotificationSettingsMutationVariables
  >(
    gql`
      mutation NotificationSettingsMutation($update: NotificationSettingsUpdate!) {
        notificationSettingsUpdate(update: $update) {
          success
          code
          message
          notificationSettings {
            ...NotificationsFragment
          }
        }
      }
      ${NOTIFICATIONS_FRAGMENT}
    `,
    {
      onCompleted: data => {
        if (data.notificationSettingsUpdate.success === false) {
          emitSnack({
            type: "mutationError",
            message: data.notificationSettingsUpdate.message
          });
        } else {
          emitSnack({
            type: "info",
            message: "Notification settings saved",
            deduplicateKey: "saveNotificationSettingsSuccess"
          });
        }
      }
    }
  );

  // ensure mutation completes if user tries to nav away
  useWarnBeforeUnload(notificationSettingsUpdateResponse.loading);

  // synchronization logic for unsaved changes
  useEffect(() => {
    const lastLoadedSettings =
      notificationSettingsUpdateResponse.data?.notificationSettingsUpdate.notificationSettings ??
      props.notificationSettings;
    const hasUnsavedChanges =
      !notificationSettingsUpdateResponse.loading &&
      (!notificationSettingsUpdateResponse.data ||
        notificationSettingsUpdateResponse.data.notificationSettingsUpdate.success === true) &&
      lastLoadedSettings &&
      keys(props.formState).filter(k => props.formState[k] !== lastLoadedSettings[k]).length > 0;
    const hasErrorAndUnsynchronized =
      notificationSettingsUpdateResponse.data?.notificationSettingsUpdate.success === false &&
      keys(props.formState).filter(
        k =>
          props.formState[k] !==
          notificationSettingsUpdateResponse.data?.notificationSettingsUpdate.notificationSettings[k]
      ).length > 0;

    if (hasUnsavedChanges) {
      notificationSettingsUpdate({
        variables: {
          update: props.formState
        }
      });
    } else if (hasErrorAndUnsynchronized) {
      // refetches and remounts component
      props.refetchNotificationSettings();
    }
  }, [
    props,
    props.formState,
    props.notificationSettings,
    props.refetchNotificationSettings,
    notificationSettingsUpdateResponse.data,
    notificationSettingsUpdateResponse.loading,
    notificationSettingsUpdate
  ]);
  return notificationSettingsUpdateResponse.loading ? <LoadingBar /> : null;
};

const NotificationSettingsForm: React.FC<{
  notificationSettings: NotificationsFragment;
  refetchNotificationSettings(): void;
}> = props => {
  // only include valid fields for NotificationSettingsUpdate
  const { __typename, availableChannels, ...notificationSettings } = props.notificationSettings;
  const hasSlack = availableChannels.includes(NotificationChannel.CHANNEL_SLACK);

  return (
    <Formik enableReinitialize initialValues={notificationSettings} onSubmit={() => void 0 /* no submit */}>
      {form => (
        <>
          <SyncSettings
            formState={form.values}
            notificationSettings={notificationSettings}
            refetchNotificationSettings={props.refetchNotificationSettings}
          />
          <form
            css={css`
              & > * {
                margin-top: var(--space-3-rem);
              }
            `}
          >
            {Object.entries(notificationSettingsInfo).map(([name, info]) => (
              <FormikFieldInputs.Checkbox key={name} name={name} label={info.title} helperLabel={info.description} />
            ))}
            <ManagePushNotifications />
            <FormikFieldGroup.Container
              legend="Additional notification channels"
              helperLegend="Select one of these channels to receive notifications when your browser is not open."
              css={css`
                margin: var(--space-5-rem) 0 var(--space-3-rem);
              `}
            >
              <FormikFieldInputs.Radio name="preferredChannel" value={NotificationChannel.CHANNEL_EMAIL}>
                Email
              </FormikFieldInputs.Radio>
              {hasSlack && (
                <FormikFieldInputs.Radio name="preferredChannel" value={NotificationChannel.CHANNEL_SLACK}>
                  Slack
                </FormikFieldInputs.Radio>
              )}
            </FormikFieldGroup.Container>
            <FormikFieldGroup.Container
              legend="Daily email digest"
              css={css`
                margin: var(--space-5-rem) 0 var(--space-3-rem);
              `}
            >
              <FormikFieldInputs.Checkbox
                name="emailDigest"
                label="Receive email digest"
                helperLabel="Daily update on requests in your teams"
              />
            </FormikFieldGroup.Container>
          </form>
        </>
      )}
    </Formik>
  );
};

const ManagePushNotifications: React.FC<{}> = props => {
  const currentUserResponse = useQuery<CurrentUser>(CURRENT_USER);
  const [processingChange, setProcessingChange] = useState(false);
  const [, rerender] = useState();

  const pushSettings = browserPushSettings(currentUserResponse.data?.currentUser?.id);
  useEffect(() => {
    pushSettings?.listen(({ type }) => {
      if (type === "pushSettingDidChange") rerender(true);
    });
  }, [pushSettings]);

  // unsupported if no PushManager
  if (!pushSettings || !("PushManager" in window)) return null;
  const isBlocked = window.Notification.permission === "denied";
  const isNotEnabled = !isBlocked && (pushSettings.isDisabled || window.Notification.permission === "default");
  const isEnabled = !isBlocked && !pushSettings.isDisabled && window.Notification.permission === "granted";

  if (!(isNotEnabled || isEnabled || isBlocked)) {
    captureException(new Error("Unknown notification state"));
    return null;
  }

  return (
    <div>
      <Typo.Body
        bold
        css={css`
          margin: var(--space-5-rem) 0 var(--space-3-rem);
        `}
      >
        Notify me via
      </Typo.Body>
      <Typo.Body>Browser notifications</Typo.Body>
      {isNotEnabled && (
        <>
          <Typo.Body sizeS italic light>
            Your push notifications are disabled. Enable them to be notified when requests need your attention.
          </Typo.Body>
          <Button
            variant="secondary"
            size="large"
            disabled={processingChange}
            onClick={async () => {
              setProcessingChange(true);
              await pushSettings.enable();
              setProcessingChange(false);
              trackNotifToggle("enabled");
            }}
            css={css`
              margin-top: var(--space-3-rem);
            `}
          >
            Enable desktop notifications
          </Button>
        </>
      )}
      {isEnabled && (
        <>
          <Typo.Body sizeS italic light>
            Your push notifications are enabled.
          </Typo.Body>
          <Button
            variant="secondary"
            size="large"
            disabled={processingChange}
            onClick={async () => {
              setProcessingChange(true);
              await pushSettings.disable();
              setProcessingChange(false);
              trackNotifToggle("disabled");
            }}
            css={css`
              margin-top: var(--space-3-rem);
            `}
          >
            Disable desktop notifications
          </Button>
        </>
      )}
      {isBlocked && (
        <Typo.Body sizeS italic light>
          Your push notifications are <b>blocked</b>. To unblock them, check your{" "}
          <Typo.ExternalLink
            href={`https://www.google.com/search?q=how+to+unblock+push+notifications+${detect()?.name}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            browser settings
          </Typo.ExternalLink>
          .
        </Typo.Body>
      )}
    </div>
  );
};
