import { css } from "@emotion/core";
import { mdiAccount, mdiAccountMultiple, mdiCircle, mdiPaletteSwatch, mdiSwapVertical } from "@mdi/js";
import { Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { FC } from "react";
import { useMutation } from "react-apollo";
import { useRequestList } from "src/App/Requests/ListView/Provider";
import { VIEWS } from "src/App/Requests/ListView/ViewContainer";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { ReactComponent as EmojiPeople } from "src/assets/material/emoji_people-24px.svg";
import { ReactComponent as PrioIcon } from "src/assets/priority.svg";
import { Button, Dialog, FilterButton, FormikInput, MaterialIcon, SubmitButton } from "src/components";
import { FormLabel } from "src/components/Fields/FormWrappers";
import { OrderByKey, OrderDirection, SortDirection } from "src/globalTypes";
import { Modal } from "src/portals/Modal";
import { col, icon, justify, row } from "src/styling/primitives";
import { keys } from "src/util";
import * as yup from "yup";
import { sortList, useFilterOptions } from ".";
import { viewTypeSpecification } from "./FilterBar";
import { SavedViewCreate, SavedViewCreateVariables } from "./typings/SavedViewCreate";
import { FilterMap, Filters } from "./urlParamHelpers";

export const filterIcons: {
  [key in keyof Filters]?: React.ReactNode;
} = {
  team: <MaterialIcon path={mdiAccountMultiple} size={1} />,
  category: <MaterialIcon path={mdiPaletteSwatch} size={1} />,
  status: <MaterialIcon path={mdiCircle} size={1} />,
  customStatus: <MaterialIcon path={mdiCircle} size={1} />,
  requester: <EmojiPeople css={[icon.M]} />,
  priority: <PrioIcon />,
  assignee: <MaterialIcon path={mdiAccount} size={1} />
};

const disablePointerEvents = css`
  pointer-events: none;
`;

const validationSchema = yup.object().shape({
  name: yup.string().required("Required")
});

export const SaveViewModal: FC<{ isOpen: boolean; dismiss(): void; orderByKey: OrderByKey }> = props => {
  const { getFilterNameById } = useFilterOptions();
  const [requestList] = useRequestList();
  const { emitSnack } = useSnack();

  const [saveView, saveViewResponse] = useMutation<SavedViewCreate, SavedViewCreateVariables>(
    gql`
      mutation SavedViewCreate($view: InSavedView!) {
        savedViewCreate(view: $view) {
          success
          code
          message
        }
      }
    `,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: VIEWS
        }
      ],
      onCompleted: data => {
        if (data.savedViewCreate.success) {
          props.dismiss();
        } else {
          emitSnack({
            type: "mutationError",
            message: data.savedViewCreate.message
          });
        }
      }
    }
  );

  const sortObj = sortList.find(s => s.key === props.orderByKey) ?? sortList[0];

  const filterArray = keys(requestList.filters).flatMap(function (key): [keyof FilterMap, string][] {
    const filter: string[] | undefined = requestList.filters[key];
    if (!filter?.length) return [];
    return filter.map(value => [key, value] as [keyof FilterMap, string]);
  });

  return (
    <Modal isOpen={props.isOpen} onDismiss={props.dismiss}>
      <Dialog medium title="Save as view" loading={saveViewResponse.loading} onClose={props.dismiss}>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            name: ""
          }}
          onSubmit={payload => {
            saveView({
              variables: {
                view: {
                  name: payload.name,
                  filters: requestList.serializeFilters(),
                  orderBy: props.orderByKey,
                  orderDirection:
                    sortObj.sort.direction === SortDirection.ASCENDING
                      ? OrderDirection.ASCENDING
                      : OrderDirection.DESCENDING,
                  orderUnreadFirst: sortObj.sort.unreadFirst,
                  viewType: requestList.state.viewType
                }
              }
            });
          }}
          render={form => (
            <Form
              css={[
                col,
                css`
                  & > *:not(:last-child):not(:first-child) {
                    margin-bottom: var(--space-5-px);
                  }
                `
              ]}
            >
              <Field
                component={FormikInput}
                name="name"
                label="Name"
                placeholder="Saved view name"
                autoFocus
                autoComplete="off"
              />
              <div css={col}>
                <FormLabel>Filters ({filterArray.length})</FormLabel>
                <div
                  css={[
                    row,
                    css`
                      flex-wrap: wrap;

                      & > * {
                        margin: 0 var(--space-1-px) var(--space-1-px) 0;
                      }
                    `
                  ]}
                >
                  {filterArray.map(([key, value]) => {
                    return (
                      <FilterButton
                        css={disablePointerEvents}
                        key={key}
                        icon={filterIcons[key]}
                        label={getFilterNameById(key, value)}
                      />
                    );
                  })}
                </div>
              </div>
              <div css={col}>
                <FormLabel>Sorted by</FormLabel>
                <div>
                  <FilterButton
                    css={disablePointerEvents}
                    icon={<MaterialIcon path={mdiSwapVertical} size={1} />}
                    label={sortObj.label}
                  />
                </div>
              </div>
              <div css={col}>
                <FormLabel>View</FormLabel>
                <div>
                  <FilterButton
                    css={disablePointerEvents}
                    icon={viewTypeSpecification[requestList.state.viewType].icon}
                    label={viewTypeSpecification[requestList.state.viewType].label}
                  />
                </div>
              </div>
              <div
                css={[
                  row,
                  justify.end,
                  css`
                    & > * + * {
                      margin-left: var(--space-1-rem);
                    }
                  `
                ]}
              >
                <Button onClick={props.dismiss}>Cancel</Button>
                <SubmitButton disabled={!form.isValid || saveViewResponse.loading}>Save as view</SubmitButton>
              </div>
            </Form>
          )}
        />
      </Dialog>
    </Modal>
  );
};
