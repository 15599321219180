import * as React from "react";
import * as yup from "yup";
import {
  ConfirmDialogModal,
  Dialog,
  FormikFieldGroup,
  FormikInput,
  Row,
  CancelButton,
  DangerButton,
  TextAreaField,
  SubmitButton,
  FormikField
} from "src/components";
import { Field, Form, Formik, FieldProps } from "formik";
import { RichEditor, richEditorInputStyles } from "src/components/RichEditor/Editor";
import { SavedReplyCreate, SavedReplyCreateVariables } from "./typings/SavedReplyCreate";
import { SavedReplyDelete, SavedReplyDeleteVariables } from "./typings/SavedReplyDelete";
import { SavedReplyUpdate, SavedReplyUpdateVariables } from "./typings/SavedReplyUpdate";
import { backMarkdownToHtml, htmlToBackMarkdown } from "src/util/formatters";
import { navigate, useUrlState } from "src/util/router";
import { filesReducer, FileStatus, IFiles, TFileAction } from "src/App/Attachments/reducer";
import { Modal } from "src/portals/Modal";
import { PureQueryOptions } from "apollo-client";
import { SavedReplyList_savedReplyList, SavedReplyList_savedReplyList_attachments } from "./typings/SavedReplyList";
import { css } from "@emotion/core";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { Tabs } from "src/components/Tabs";
import { useReducer, useState } from "react";
import { ErrorMessage } from "formik";
import { FeatureFlags, useFeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import { AttachmentList } from "src/components/Attachments";

const savedReplySchema = yup.object().shape({
  title: yup.string().trim().required("Please provide a title for your reply"),
  body: yup.string().trim().required("Please provide a body for your reply")
});

interface FormValues {
  title: string;
  body: string;
}

const ReplyBody = (
  props: FieldProps<FormValues> & {
    filesState: IFiles;
    filesDispatch: React.Dispatch<TFileAction>;
    currentAttachments?: SavedReplyList_savedReplyList_attachments[];
    handleAttachmentRemove?: (id: string) => void;
    selectedReply?: SavedReplyList_savedReplyList | null;
    isFilesUploading: boolean;
    useRichTextEditor: boolean;
    submit: string;
    onDelete?: () => void;
    onCancel?: () => void;
    hasAttachmentsFeatureFlag: boolean;
  }
) => {
  const fieldName = props.field.name as keyof typeof props.form.initialValues;

  return (
    <FormikField>
      <Field>
        {() => (
          <Tabs
            transitionHeight
            items={[
              {
                headline: "Content",
                dataIntercomTarget: "reply-content",
                content: (
                  <>
                    {props.useRichTextEditor ? (
                      <>
                        <div css={richEditorInputStyles}>
                          <RichEditor
                            data-intercom-target="saved-reply-rich-editor"
                            initialValue={backMarkdownToHtml(props.form.initialValues[fieldName])}
                            onChange={html => {
                              props.form.setFieldValue("body", htmlToBackMarkdown(html));
                              props.form.setFieldTouched("body");
                            }}
                            hasError={!!props.form.touched[fieldName] && !!props.form.errors[fieldName]}
                            minLines={4}
                          />
                        </div>
                        <FormikFieldGroup.Errors>
                          <ErrorMessage name="body" />
                        </FormikFieldGroup.Errors>
                      </>
                    ) : (
                      <Field name="body" component={TextAreaField} height="10rem" />
                    )}
                  </>
                )
              },
              {
                headline: "Attachments",
                dataIntercomTarget: "reply-attachments",
                content: (
                  <div
                    css={css`
                      border: 1px solid #d9deea;
                      padding: var(--space-3-rem) var(--space-4-rem);
                      border-radius: var(--border-radius-s);
                    `}
                  >
                    <AttachmentList
                      filesState={props.filesState}
                      filesDispatch={props.filesDispatch}
                      attachments={props.currentAttachments ?? []}
                      handleAttachmentRemove={props.handleAttachmentRemove}
                    />
                  </div>
                ),
                shouldNotDisplay: !props.hasAttachmentsFeatureFlag
              }
            ]}
          />
        )}
      </Field>
      <Row
        css={[
          css`
            margin-top: var(--space-4-rem);
            justify-content: flex-end;
            & > * + * {
              margin-left: var(--space-2-rem);
            }
          `
        ]}
      >
        {props.selectedReply?.id ? (
          <DangerButton onClick={props.onDelete}>Delete</DangerButton>
        ) : (
          <CancelButton onClick={props.onCancel} />
        )}
        <SubmitButton disabled={!props.form.isValid || props.form.isSubmitting || props.isFilesUploading}>
          {props.submit}
        </SubmitButton>
      </Row>
    </FormikField>
  );
};

export const CreateSavedReplyModal: React.FC<{
  isOpen: boolean;
  team: {
    id: string;
    slug: string;
  };
  refetchQueries: PureQueryOptions[];
  hasAttachmentsFeatureFlag: boolean;
}> = props => {
  const { emitSnack } = useSnack();
  const { hasFeatureFlags } = useFeatureFlags();

  const [title] = useUrlState("title", "");
  const [body] = useUrlState("body", "");

  const [filesState, filesDispatch] = useReducer(filesReducer, { files: [] });

  const [createSavedReply] = useMutation<SavedReplyCreate, SavedReplyCreateVariables>(
    gql`
      mutation SavedReplyCreate($params: InSavedReply!) {
        savedReplyCreate(params: $params) {
          success
          code
          message
        }
      }
    `,
    {
      awaitRefetchQueries: true,
      refetchQueries: props.refetchQueries,
      onCompleted: data => {
        if (data.savedReplyCreate.success === false) {
          emitSnack({
            type: "mutationError",
            message: data.savedReplyCreate.message
          });
        } else {
          emitSnack({
            type: "info",
            message: "New saved reply successfully created"
          });
        }
      }
    }
  );
  const isFilesUploading = filesState.files.some(f => f.status !== FileStatus.UPLOADED);
  return (
    <Formik
      enableReinitialize
      // validate initial values from url
      isInitialValid={title && body ? savedReplySchema.isValidSync({ title, body }) : undefined}
      initialValues={{
        title,
        body
      }}
      validationSchema={savedReplySchema}
      onSubmit={async (form, actions) => {
        await createSavedReply({
          variables: {
            params: {
              teamId: props.team.id,
              title: form.title,
              body: form.body,
              attachmentIds: filesState.files.filter(f => !!f.attachmentId).map(f => f.attachmentId ?? "")
            }
          }
        });
        navigate(
          `/settings/saved-replies/${props.team.slug}`,
          // no need to save history item for creating reply
          { replace: true }
        );
        actions.setSubmitting(false);
        actions.resetForm();
        filesDispatch({ type: "CLEAR_FILES" });
      }}
      render={() => (
        <Modal isOpen={props.isOpen} onDismiss={() => navigate(`/settings/saved-replies/${props.team.slug}`)}>
          <Dialog large title="New saved reply" onClose={() => navigate(`/settings/saved-replies/${props.team.slug}`)}>
            <Form>
              <Field name="title" component={FormikInput} label="Title" autoFocus />
              <Field
                name="body"
                component={ReplyBody}
                filesState={filesState}
                filesDispatch={filesDispatch}
                team={props.team}
                useRichTextEditor={hasFeatureFlags(FeatureFlags.RICHTEXTREPLIES)}
                submit="Create"
                onCancel={() => navigate(`/settings/saved-replies/${props.team.slug}`)}
                isFilesUploading={isFilesUploading}
                hasAttachmentsFeatureFlag={props.hasAttachmentsFeatureFlag}
              />
            </Form>
          </Dialog>
        </Modal>
      )}
    />
  );
};

export const EditSavedReplyModal: React.FC<{
  isOpen: boolean;
  team: {
    id: string;
    slug: string;
  };
  selectedReply: SavedReplyList_savedReplyList | null;
  refetchQueries: PureQueryOptions[];
  hasAttachmentsFeatureFlag: boolean;
}> = props => {
  const { emitSnack } = useSnack();
  const [removedAttachmentIds, setRemovedAttachmentIds] = useState<string[]>([]);
  const [filesState, filesDispatch] = useReducer(filesReducer, { files: [] });
  const { hasFeatureFlags } = useFeatureFlags();
  const [editSavedReply] = useMutation<SavedReplyUpdate, SavedReplyUpdateVariables>(
    gql`
      mutation SavedReplyUpdate($params: UpSavedReply!) {
        savedReplyUpdate(params: $params) {
          success
          code
          message
        }
      }
    `,
    {
      awaitRefetchQueries: true,
      refetchQueries: props.refetchQueries,
      onCompleted: data => {
        if (data.savedReplyUpdate.success === false) {
          emitSnack({
            type: "mutationError",
            message: data.savedReplyUpdate.message
          });
        } else {
          emitSnack({
            type: "info",
            message: "Saved reply successfully updated"
          });
        }
      }
    }
  );
  if (!props.selectedReply) return null;

  const currentAttachments = props.selectedReply?.attachments.filter(f => !removedAttachmentIds.includes(f.id));
  const didAttachmentsChange = filesState.files.length > 0 || removedAttachmentIds.length > 0;
  const isFilesUploading = filesState.files.some(f => f.status !== FileStatus.UPLOADED);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        title: props.selectedReply.title ?? "",
        body: props.selectedReply.body ?? ""
      }}
      isInitialValid={savedReplySchema.isValidSync(props.selectedReply) && didAttachmentsChange}
      validationSchema={savedReplySchema}
      onSubmit={async (form, actions) => {
        if (props.selectedReply) {
          await editSavedReply({
            variables: {
              params: {
                id: props.selectedReply.id,
                title: form.title,
                body: form.body,
                attachmentIds: [
                  ...(currentAttachments?.map(a => a.id) ?? []),
                  ...filesState.files.filter(f => !!f.attachmentId).map(f => f.attachmentId ?? "")
                ]
              }
            }
          });
          navigate(`/settings/saved-replies/${props.team.slug}`);
          actions.setSubmitting(false);
          filesDispatch({ type: "CLEAR_FILES" });
          setRemovedAttachmentIds([]);
        }
      }}
      render={() => (
        <Modal isOpen={props.isOpen} onDismiss={() => navigate(`/settings/saved-replies/${props.team.slug}`)}>
          <Dialog
            large
            title="Edit saved reply"
            onClose={() => {
              navigate(`/settings/saved-replies/${props.team.slug}`);
              filesDispatch({ type: "CLEAR_FILES" });
              setRemovedAttachmentIds([]);
            }}
          >
            <Form>
              <Field name="title" component={FormikInput} label="Title" autoFocus />
              <Field
                name="body"
                currentAttachments={currentAttachments}
                component={ReplyBody}
                filesState={filesState}
                filesDispatch={filesDispatch}
                team={props.team}
                useRichTextEditor={hasFeatureFlags(FeatureFlags.RICHTEXTREPLIES)}
                handleAttachmentRemove={(id: string) => {
                  setRemovedAttachmentIds(a => [...a, id]);
                }}
                submit="Update"
                onDelete={() =>
                  navigate(`/settings/saved-replies/${props.team.slug}/delete/${props.selectedReply?.id}`)
                }
                isFilesUploading={isFilesUploading}
                selectedReply={props.selectedReply}
                hasAttachmentsFeatureFlag={props.hasAttachmentsFeatureFlag}
              />
            </Form>
          </Dialog>
        </Modal>
      )}
    />
  );
};

export const DeleteSavedReplyModal: React.FC<{
  isOpen: boolean;
  team: {
    id: string;
    slug: string;
  };
  selectedReply: SavedReplyList_savedReplyList | null;
  refetchQueries: PureQueryOptions[];
}> = props => {
  const { emitSnack } = useSnack();

  const [deleteSavedReply, deleteSavedReplyResponse] = useMutation<SavedReplyDelete, SavedReplyDeleteVariables>(
    gql`
      mutation SavedReplyDelete($id: ID!) {
        savedReplyDelete(id: $id) {
          success
          code
          message
        }
      }
    `,
    {
      awaitRefetchQueries: true,
      refetchQueries: props.refetchQueries,
      onCompleted: data => {
        if (data.savedReplyDelete.success === false) {
          emitSnack({
            type: "mutationError",
            message: data.savedReplyDelete.message
          });
        } else {
          emitSnack({
            type: "info",
            message: "Saved reply deleted"
          });
        }
      }
    }
  );
  return (
    <ConfirmDialogModal
      small
      isOpen={props.isOpen}
      handleCancel={() => navigate(`/settings/saved-replies/${props.team.slug}`)}
      handleConfirm={async () => {
        if (props.selectedReply) {
          await deleteSavedReply({
            variables: {
              id: props.selectedReply.id
            }
          });
          navigate(`/settings/saved-replies/${props.team.slug}`);
        }
      }}
      submittingConfirm={deleteSavedReplyResponse.loading}
      text={{ heading: "Delete saved reply", confirm: "Yes", cancel: "No" }}
    >
      Are you sure you would like to delete this saved reply?
    </ConfirmDialogModal>
  );
};
