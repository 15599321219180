import * as React from "react";

import { FC } from "react";
import { css } from "@emotion/core";
import { highlightDomElement } from "src/App/Settings/Workflows/DetailView/components";
import { subHeadingStyles } from "src/styling/primitives/typography";

export const JumpLinkContainer: FC<{ disabled: boolean }> = props => (
  <div
    css={[
      css`
        margin-top: var(--space-2-rem);
        transition: 0.3s all ease;
      `,
      props.disabled &&
        css`
          opacity: 0.4;
        `
    ]}
  >
    {props.children}
  </div>
);

export const JumpLink: FC<{ subWorkflowId: string; disabled: boolean }> = ({ subWorkflowId, disabled }) => (
  <div
    css={[
      subHeadingStyles,
      css`
        cursor: pointer;
        display: inline;
        color: var(--blue-1);
        font-weight: var(--font-weight-body-medium);
      `,
      disabled &&
        css`
          cursor: not-allowed;
        `
    ]}
    onClick={() => {
      if (!disabled) {
        const el = document.getElementById(subWorkflowId);
        if (el) {
          el.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
          highlightDomElement(el);
        }
      }
    }}
    title={`Go to step ${subWorkflowId}`}
  >
    #{subWorkflowId}
  </div>
);
