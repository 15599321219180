import { css } from "@emotion/core";
import { mdiAlert, mdiFile, mdiUpload } from "@mdi/js";
import * as React from "react";
import { useContext } from "react";
import { DragOver } from "src/App/Root/Wrapper";
import { MaterialIcon, Row } from "src/components";
import { stackOrder } from "src/styling/layout";
import { csx } from "src/util/csx";
import { handleFilesEvent } from "./handlers";
import { IFiles, TFileAction } from "./reducer";

const dropOverlay = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--border);
  background: var(--lightBlue-3);
  border-color: var(--border);
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  cursor: copy;
  z-index: ${stackOrder.higher};
`;

const DropOverlay = csx([dropOverlay], {
  isDraggingOver: css`
    color: var(--text-6);
    background: var(--lightBlue-1);
  `
});

const DropOverlayWarning = csx(
  [
    dropOverlay,
    css`
      color: var(--red-7);
      background: var(--red-1);
      border-color: var(--red-2);
    `
  ],
  {
    isDraggingOver: css`
      color: var(--red-2);
      background: var(--pink-1);
    `
  }
);

interface IDropProps {
  filesState: IFiles;
  filesDispatch: React.Dispatch<TFileAction>;
  disabledReason?: string;
}

/**
 * Drop zone overlay for handling file uploads
 * Only renders on dragover on root wrapper element
 */
export const AttachmentsDropZone = (props: IDropProps) => {
  const isDragging = useContext(DragOver);
  const [isDraggingOver, setIsDraggingOver] = React.useState(false);

  if (!isDragging) return null;

  if (props.disabledReason) {
    return (
      <DropOverlayWarning isDraggingOver={isDraggingOver}>
        <Row justify="center" align="center">
          <MaterialIcon path={mdiAlert} size={1} margin="0 1rem 0 0" /> {props.disabledReason}
        </Row>
      </DropOverlayWarning>
    );
  }

  return (
    <DropOverlay
      isDraggingOver={isDraggingOver}
      onDrop={e => handleFilesEvent(Array.from(e.dataTransfer.files), props.filesDispatch, null)}
      onDragOver={e => {
        e.dataTransfer.dropEffect = "copy";
        setIsDraggingOver(true);
      }}
      onDragLeave={() => setIsDraggingOver(false)}
    >
      <Row justify="center" align="center">
        {!isDraggingOver && (
          <>
            <MaterialIcon path={mdiFile} size={1} margin="0 1rem 0 0" /> Drag files here to upload
          </>
        )}
        {isDraggingOver && (
          <>
            <MaterialIcon path={mdiUpload} size={1} margin="0 1rem 0 0" /> Drop files here to upload
          </>
        )}
      </Row>
    </DropOverlay>
  );
};
