import * as React from "react";
import * as yup from "yup";

import { AuthGetMechanism, AuthGetMechanismVariables } from "./typings/AuthGetMechanism";
import { AuthInvalid, AuthType } from "src/globalTypes";
import { AuthMigrateToSSO, AuthMigrateToSSOVariables } from "./typings/AuthMigrateToSSO";
import {
  ConfirmDialogModal,
  Dialog,
  FormikFieldGroup,
  Input,
  LinkTypeButton,
  LoadingBar,
  SubmitButton
} from "src/components";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import { UserLogin, UserLoginVariables } from "./typings/UserLogin";
import { useEffect, useState } from "react";

import { AuthWrapper } from "src/App/Auth/Components";
import { ReactComponent as BackLogoGreen } from "src/assets/logos/BackLogo_Green.svg";
import { CURRENT_USER } from "src/App/Root/providers/CurrentUserProvider";
import { CurrentUser } from "src/App/Root/providers/typings/CurrentUser";
import { ErrorCode } from "src/util/apollo/links";
import { FEATURE_FLAGS } from "src/App/Root/providers/FeatureFlagProvider";
import { Toast } from "src/portals/Toast";
import { Typo } from "src/styling/primitives/typography";
import { apolloClient } from "src/util/apollo/client";
import { browserPushSettings } from "src/util/services/pushNotifications";
import { captureException } from "@sentry/browser";
import { css } from "@emotion/core";
import gql from "graphql-tag";
import { navigate } from "src/util/router";
import { searchParam } from "src/util";
import { subscriptionExpiredPath } from "src/App/Settings/Subscription/Expired";
import { trackUser } from "src/util/analytics";
import { useMutation, useLazyQuery } from "react-apollo";
import { useSnack } from "src/App/Root/providers/SnackProvider";

/**
 * Performs the appropriate redirect, depending on the redirect query parameter
 * (e.g. /login?redirect=/view/me)
 */
export async function performLoginRedirect() {
  const redirectSearchParam = searchParam("redirect");
  navigate(redirectSearchParam ?? process.env.REACT_APP_LOGIN_REDIRECT ?? "/");
}

export const UserLoginPage: React.FC<{}> = () => {
  const { emitSnack } = useSnack();
  const [hideSsoModal, setHideSsoModal] = useState(false);
  // display potential error message on load
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const errorMessage = queryParams.get("errorMessage");
    if (errorMessage)
      emitSnack({
        type: "mutationError",
        message: errorMessage
      });
  }, [emitSnack]);
  const [getLoginMethod, getLoginMethodResponse] = useLazyQuery<AuthGetMechanism, AuthGetMechanismVariables>(gql`
    query AuthGetMechanism($email: String!, $redirectPath: String!) {
      authGetMechanism(email: $email, redirectPath: $redirectPath) {
        code
        message
        success
        authMechanism {
          authType
          ssoRedirectUrl
        }
      }
    }
  `);
  const [migrateToSSO, migrateToSSOResponse] = useMutation<AuthMigrateToSSO, AuthMigrateToSSOVariables>(gql`
    mutation AuthMigrateToSSO($params: UserLoginParams!, $redirectPath: String!) {
      authMigrateToSSO(params: $params, redirectPath: $redirectPath) {
        code
        message
        success
        authMechanism {
          authType
          ssoRedirectUrl
        }
      }
    }
  `);
  const [userLogin, userLoginResponse] = useMutation<UserLogin, UserLoginVariables>(
    gql`
      mutation UserLogin($params: UserLoginParams!) {
        userLogin(params: $params) {
          code
          message
          success
          invalid
        }
      }
    `,
    {
      refetchQueries: [{ query: FEATURE_FLAGS }]
    }
  );
  const isLoading = getLoginMethodResponse.loading || userLoginResponse.loading;
  const didSubmitInvalidCredentialsError =
    userLoginResponse.data?.userLogin.invalid === AuthInvalid.INVALID_CREDENTIALS;
  const isAccountLockedError = userLoginResponse.data?.userLogin.invalid === AuthInvalid.ACCOUNT_LOCKED;
  // unhandled error message, besides the invalid credentials case
  const otherError =
    !didSubmitInvalidCredentialsError && !isAccountLockedError && userLoginResponse.data?.userLogin.success === false
      ? userLoginResponse.data.userLogin.message
      : getLoginMethodResponse.data?.authGetMechanism.success === false
      ? getLoginMethodResponse.data.authGetMechanism.message
      : null;
  const shouldShowPasswordField =
    getLoginMethodResponse.data?.authGetMechanism.authMechanism?.authType === AuthType.AUTH_TYPE_PASSWORD ||
    getLoginMethodResponse.data?.authGetMechanism.authMechanism?.authType === AuthType.AUTH_TYPE_MIGRATE;
  const step: "EMAIL" | "PASSWORD" | "MIGRATE" =
    getLoginMethodResponse.data?.authGetMechanism.authMechanism?.authType === AuthType.AUTH_TYPE_MIGRATE
      ? "MIGRATE"
      : getLoginMethodResponse.data?.authGetMechanism.authMechanism?.authType === AuthType.AUTH_TYPE_PASSWORD
      ? "PASSWORD"
      : "EMAIL";

  const ssoRedirectUrl = getLoginMethodResponse.data?.authGetMechanism.authMechanism?.ssoRedirectUrl;

  if (ssoRedirectUrl) {
    window.location.href = ssoRedirectUrl;
  }

  return (
    <AuthWrapper>
      {isLoading && <LoadingBar />}
      <Dialog
        small
        noClose
        css={css`
          margin-bottom: var(--space-4-px);
        `}
      >
        <BackLogoGreen
          css={css`
            width: 4rem;
            height: 4rem;
            padding-bottom: var(--space-7-px);
          `}
        />
        <Typo.Body
          sizeXXL
          css={css`
            margin-bottom: var(--space-2-px);
          `}
        >
          Welcome
        </Typo.Body>
        <Typo.Body
          css={css`
            margin-bottom: var(--space-5-px);
          `}
        >
          Sign in to return to Back
        </Typo.Body>
        <Formik
          initialValues={{
            email: "",
            password: ""
          }}
          // must be set to accomodate autocomplete
          isInitialValid
          validationSchema={
            step === "EMAIL"
              ? yup.object().shape({
                  email: yup.string().email("Enter a valid email").required("Enter a valid email")
                })
              : yup.object().shape({
                  email: yup.string().email("Enter a valid email").required("Enter a valid email"),
                  password: yup.string().required("Enter password to continue")
                })
          }
          onSubmit={async (form, actions) => {
            // always call getLoginMethod on first submit
            if (step === "EMAIL") {
              getLoginMethod({
                variables: {
                  email: form.email,
                  redirectPath: searchParam("redirect") ?? process.env.REACT_APP_LOGIN_REDIRECT ?? "/"
                }
              });
            } else if (step === "MIGRATE") {
              const response = await migrateToSSO({
                variables: {
                  params: {
                    email: form.email,
                    password: form.password
                  },
                  redirectPath: searchParam("redirect") ?? process.env.REACT_APP_LOGIN_REDIRECT ?? "/"
                }
              });

              if (response.data?.authMigrateToSSO.authMechanism?.ssoRedirectUrl) {
                window.location.href = response.data.authMigrateToSSO.authMechanism.ssoRedirectUrl;
              }
            } else if (step === "PASSWORD") {
              const res = await userLogin({
                variables: {
                  params: {
                    email: form.email,
                    password: form.password
                  }
                }
              });
              if (res.data && res.data.userLogin.success) {
                performLoginRedirect();
                // track user after logging in
                const currentUserQuery = await apolloClient.query<CurrentUser>({
                  query: CURRENT_USER,
                  fetchPolicy: "network-only"
                });
                trackUser();
                browserPushSettings(currentUserQuery.data?.currentUser?.id)?.onLogin().catch(captureException);
              } else if (res.errors && JSON.stringify(res.errors).includes(ErrorCode.UNSUBSCRIBED)) {
                navigate(subscriptionExpiredPath);
              }
            }
            actions.setSubmitting(false);
          }}
        >
          {form => (
            <Form>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                `}
              >
                <FormikFieldGroup.Container legend="Email address">
                  <Field name="email">
                    {(formikFieldProps: FieldProps) => (
                      <Input
                        {...formikFieldProps.field} // key allows autoFocus
                        autoFocus={step === "EMAIL"}
                        // ensure cursor goes to end of input
                        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                          const val = e.target.value;
                          e.target.value = "";
                          e.target.value = val;
                        }}
                        name="email"
                        autoComplete="username"
                        css={css`
                          width: 100%;
                        `}
                        disabled={step !== "EMAIL"}
                      />
                    )}
                  </Field>
                  <FormikFieldGroup.Errors>
                    <ErrorMessage name="email" />
                  </FormikFieldGroup.Errors>
                </FormikFieldGroup.Container>
                <div
                  css={
                    shouldShowPasswordField
                      ? css`
                          max-height: 200px;
                          transition: max-height 300ms ease-in-out;
                          animation: fadeIn 300ms ease-out;
                        `
                      : css`
                          visibility: hidden;
                          max-height: 0;
                        `
                  }
                >
                  <FormikFieldGroup.Container legend="Password">
                    <Field name="password">
                      {(formikFieldProps: FieldProps) => (
                        <Input
                          {...formikFieldProps.field} // key allows autoFocus
                          key={shouldShowPasswordField ? 1 : 0}
                          autoFocus={shouldShowPasswordField}
                          name="password"
                          autoComplete="current-password"
                          type="password"
                          css={css`
                            width: 100%;
                          `}
                        />
                      )}
                    </Field>
                    <FormikFieldGroup.Errors
                      css={css`
                        display: flex;
                      `}
                    >
                      {form.touched.password && <ErrorMessage name="password" />}
                      <LinkTypeButton
                        css={css`
                          color: var(--text-4);
                          float: right;
                        `}
                        type="button"
                        onClick={() => {
                          navigate("/password/reset", { state: { loginEmail: form.values.email } });
                        }}
                      >
                        Forgot password?
                      </LinkTypeButton>
                    </FormikFieldGroup.Errors>
                  </FormikFieldGroup.Container>
                </div>
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                    margin-top: var(--space-6-rem);
                  `}
                >
                  <SubmitButton disabled={form.isSubmitting || !form.isValid}>
                    {step === "EMAIL" ? "Next" : step === "PASSWORD" || step === "MIGRATE" ? "Sign in" : ""}
                  </SubmitButton>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        {didSubmitInvalidCredentialsError && <Toast message="Email or password incorrect" kind="custom" />}
        {isAccountLockedError && (
          <Toast message="Your account is locked. You must wait 15 minutes before trying again" kind="custom" />
        )}
        {otherError && <Toast message={otherError} kind="mutationError" />}
        <ConfirmDialogModal
          isOpen={step === "MIGRATE" && !hideSsoModal}
          small
          text={{ heading: "Single sign-on redirect", confirm: "Continue" }}
          buttonVariant="primary"
          handleCancel={() => setHideSsoModal(true)}
          handleConfirm={() => setHideSsoModal(true)}
        >
          <Typo.Body>
            Your company has updated its login method to use a single sign-on (SSO) provider. Before you continue to
            Back, you need to complete a one-time association. Please, enter your current password to authenticate and
            then we will redirect you to sign in with your SSO provider.
          </Typo.Body>
        </ConfirmDialogModal>
      </Dialog>
      <div
        css={css`
          display: flex;
          justify-content: center;
          margin-bottom: auto;
          & > * + * {
            margin-left: var(--space-1-px);
          }
        `}
      >
        <Typo.Body>No account yet?</Typo.Body>
        <Typo.ExternalLink href="https://backhq.com/demo" rel="noopener noreferrer">
          Book a demo
        </Typo.ExternalLink>
      </div>
    </AuthWrapper>
  );
};
