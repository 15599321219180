import { mdiDotsHorizontal } from "@mdi/js";
import * as React from "react";
import { UserAvatar, UserName } from "src/App/User";
import { Col, MaterialIcon, Menu, MenuItem, Row, SquareButton } from "src/components";
import { PopOver } from "src/components/PopOver";
import { Table } from "src/components/Table";
import { formatShortDate } from "src/util/formatters";
import { GExpertListGet_userList2_users } from "./typings/GExpertListGet";

export const UserTable: React.ComponentType<{
  userList: GExpertListGet_userList2_users[];
  setEditUser: (userId: string) => void;
  setRemoveUser: (userId: string) => void;
}> = props => (
  <Table>
    <thead>
      <tr>
        <th className="name">Name</th>
        <th className="email">Email</th>
        <th className="joined">Date joined</th>
        <th className="more" />
      </tr>
    </thead>
    <tbody>
      {props.userList &&
        props.userList.map((user, i) => (
          <tr key={i} className="user" data-testid="user-loaded">
            <td className="name">
              <Row align="center">
                <Col margin="0 1rem 0 0">
                  <UserAvatar sizeL user={user} />
                </Col>
                <UserName user={user} you={false} bold />
              </Row>
            </td>
            <td className="email">{user.email}</td>
            <td className="joined">{(user.joinTime && formatShortDate(user.joinTime)) || "-"}</td>
            <td className="more">
              <Row justify="flex-end">
                <PopOver.Blank
                  placement="bottom-end"
                  button={
                    <SquareButton size="small" data-testid="user-settings-more">
                      <MaterialIcon path={mdiDotsHorizontal} size={1.125} />
                    </SquareButton>
                  }
                >
                  <Menu>
                    <MenuItem data-testid="user-settings-item" onClick={() => props.setEditUser(user.id)}>
                      Edit
                    </MenuItem>
                    <MenuItem data-testid="user-settings-item" onClick={() => props.setRemoveUser(user.id)}>
                      Remove user
                    </MenuItem>
                  </Menu>
                </PopOver.Blank>
              </Row>
            </td>
          </tr>
        ))}
    </tbody>
  </Table>
);
