import { mdiPlus } from "@mdi/js";
import gql from "graphql-tag";
import * as React from "react";
import { Query } from "react-apollo";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { BaseLayout, Button, Dialog, MaterialIcon } from "src/components";
import { TEAM_INFO_FRAGMENT } from "src/fragments/TeamInfoFragment";
import { Modal } from "src/portals/Modal";
import { Toast } from "src/portals/Toast";
import { navigate, RouteComponentProps } from "src/util/router";
import { TeamCreateFlow } from "./Create/Flow";
import { ManageMembers } from "./Manage/Container";
import { TeamTable } from "./Table";
import { GSettingsTeamListGet } from "./typings/GSettingsTeamListGet";
import { TeamUpdateContainer } from "./Update/Container";

/**
 * All teams list
 */
export const SETTINGS_TEAM_LIST_GET = gql`
  query GSettingsTeamListGet {
    teamList {
      id
      name
      slug
      privacy
      userIds
      memberCount
      emailAddresses {
        to
      }
    }
    currentUser {
      id
      organization {
        id
        slug
      }
      teams {
        ...TeamInfo
        emailAddresses {
          from
          to
        }
      }
    }
  }
  ${TEAM_INFO_FRAGMENT}
`;

/**
 * Team overview list / managment page
 */
export const TeamOverview: React.ComponentType<RouteComponentProps<{
  team_id: string;
  modal: "create" | "edit" | "manage";
  step?: string;
}>> = props => {
  const { emitSnack } = useSnack();

  if ((props.modal === "edit" || props.modal === "manage") && !props.team_id) {
    emitSnack({
      type: "mutationError",
      message: "Team id is not defined"
    });
  }
  return (
    <>
      <Query<GSettingsTeamListGet> query={SETTINGS_TEAM_LIST_GET} fetchPolicy="network-only">
        {({ data, error, loading }) => {
          if (error) {
            return <Toast message={error.message} kind="error" />;
          }
          return (
            (data && data.teamList && (
              <BaseLayout
                headline={"Teams"}
                subline={
                  <>
                    There {data.teamList.length === 1 ? "is" : "are"}&nbsp;
                    <b>{data.teamList.length === 1 ? "1 team" : data.teamList.length + " teams"}</b>&nbsp;in your
                    company.
                  </>
                }
                isLoading={loading}
                button={
                  <Button variant="secondary" onClick={() => navigate("/settings/teams/create")}>
                    <MaterialIcon path={mdiPlus} size={1.125} margin="0 0.25rem 0 0" />
                    Create team
                  </Button>
                }
              >
                {" "}
                <TeamTable teamList={data.teamList} currentUser={data.currentUser} />
              </BaseLayout>
            )) ||
            null
          );
        }}
      </Query>

      <Modal isOpen={props.modal === "create"} onDismiss={() => navigate("/settings/teams")}>
        <TeamCreateFlow {...props} redirect="/settings/teams" />
      </Modal>

      <Modal isOpen={props.modal === "edit"} onDismiss={() => navigate("/settings/teams")}>
        <Dialog title="Edit team" onClose={() => navigate("/settings/teams")}>
          {props.team_id && <TeamUpdateContainer team_id={props.team_id} redirect="/settings/teams" />}
        </Dialog>
      </Modal>

      <Modal isOpen={props.modal === "manage"} onDismiss={() => navigate("/settings/teams")}>
        <Dialog title="Manage members" onClose={() => navigate("/settings/teams")}>
          {props.team_id && <ManageMembers teamId={props.team_id} />}
        </Dialog>
      </Modal>
    </>
  );
};
