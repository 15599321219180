import "src/styling/animations/highlight.css";

export const highlightDomElement = (e: HTMLElement) => {
  if (!e.className.includes("highlightSubWorkflow")) {
    e.classList.add("highlightSubWorkflow");
    setTimeout(() => {
      e.classList.remove("highlightSubWorkflow");
    }, 1500);
  }
};
