import { css } from "@emotion/core";
import React from "react";
import { useIntercom } from "react-use-intercom";
import { FeatureFlags, useFeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import { OptionMap, PopOver } from "src/components/PopOver";
import { csx } from "src/util/csx";

export const HELP_BUBBLE_WIDTH = 42;

const ROADMAP_LINK = "https://portal.productboard.com/7iarad6gkhswttqkhytf2vnb";
export const HELP_CENTER_URL = "https://support.back.ee/";
export const HELP_CENTER_NOTION_URL = "https://support.back.ee/en/articles/5356662-integrate-back-with-notion";

const Bubble = csx([
  css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${HELP_BUBBLE_WIDTH}px;
    height: ${HELP_BUBBLE_WIDTH}px;
    font-size: 22px;
    color: var(--text-3);
    background: var(--white);
    border-radius: ${HELP_BUBBLE_WIDTH / 2}px;
    cursor: pointer;
    pointer-events: all;
    user-select: none;
    box-sizing: border-box;
    box-shadow: 0px 16px 16px rgba(var(--text-6-raw), 0.04), 0px 1px 1px rgba(var(--text-6-raw), 0.22),
      0px -1px 0px rgba(var(--text-6-raw), 0.09), 0px 4px 12px rgba(var(--text-6-raw), 0.04);

    &:hover {
      border: 1px solid var(--lightGrey-3);
    }
  `
]);

export const HelpBubble: React.FC<{ showHotKeysHelp: () => void }> = ({ showHotKeysHelp }) => {
  const { hasFeatureFlags } = useFeatureFlags();
  const { showNewMessages } = useIntercom();

  const supportItem = hasFeatureFlags(FeatureFlags.INTERCOMENABLED)
    ? {
        id: "intercom",
        name: "Chat with us",
        onClick: () => {
          showNewMessages();
        },
        icon: "💬"
      }
    : {
        id: "support-email",
        name: "Talk to us",
        linkAttrs: {
          href: "mailto:support@back.ee"
        },
        icon: "💬"
      };

  const items: OptionMap[] = [
    ...[supportItem],
    {
      id: "helpCenter",
      name: "Help center",
      linkAttrs: {
        href: HELP_CENTER_URL,
        target: "_blank"
      },
      icon: "🤓"
    },
    {
      id: "roadmap",
      name: "Roadmap",
      linkAttrs: {
        href: ROADMAP_LINK,
        target: "_blank"
      },
      icon: "🗺"
    },
    {
      id: "featureRequest",
      name: "Send a feature request",
      linkAttrs: {
        href: `${ROADMAP_LINK}/submit-idea`,
        target: "_blank"
      },
      icon: "🔬"
    },
    {
      id: "hotkeys",
      name: "Keyboard shortcuts",
      onClick: showHotKeysHelp,
      icon: "⌨️"
    }
  ];

  return (
    <div
      css={css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: var(--space-5-px);
        z-index: var(--z-higher);
        pointer-events: none;
      `}
    >
      <PopOver.Menu options={items} trigger={<Bubble>?</Bubble>} />
    </div>
  );
};
