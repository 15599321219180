import { css } from "@emotion/core";
import { Link } from "@reach/router";
import { groupBy, upperFirst } from "lodash";
import * as React from "react";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { Dialog, Row } from "src/components";
import { Modal } from "src/portals/Modal";
import { Typo } from "src/styling/primitives/typography";
import tinykeys, { KeyBindingMap } from "tinykeys";

interface HotKeysState {
  meta: {
    // null denotes the pre-hydrated state from localStorage i.e., the "user preference not loaded" state
    enabled: boolean | null;
  };
  bindings: {
    [key: string]: Pick<HookOptions, "name" | "description" | "group" | "keys">;
  };
}
type Action =
  | {
      type: "ADD_HOTKEY";
      payload: Pick<HookOptions, "keys" | "name" | "description" | "group">;
    }
  | { type: "REMOVE_HOTKEY"; payload: Pick<HookOptions, "keys"> }
  | { type: "DISABLE_ALL_HOTKEYS" }
  | { type: "ENABLE_ALL_HOTKEYS" };

const initState: HotKeysState = { meta: { enabled: null }, bindings: {} };

const hotKeyStateReducer = (state: HotKeysState, action: Action): HotKeysState => {
  switch (action.type) {
    case "ADD_HOTKEY":
      return {
        ...state,
        bindings: {
          ...state.bindings,
          [action.payload.keys]: {
            name: action.payload.name,
            description: action.payload.description,
            group: action.payload.group,
            keys: action.payload.keys
          }
        }
      };
    case "REMOVE_HOTKEY":
      delete state.bindings[action.payload.keys];
      return { ...state, bindings: { ...state.bindings } };
    case "ENABLE_ALL_HOTKEYS":
      return {
        ...state,
        meta: {
          enabled: true
        }
      };
    case "DISABLE_ALL_HOTKEYS":
      return {
        ...state,
        meta: {
          enabled: false
        }
      };
    default:
      return state;
  }
};

function useHotKeyState() {
  const [state, dispatch] = React.useReducer(hotKeyStateReducer, initState);
  const value = React.useMemo(
    () => ({
      state,
      dispatch
    }),
    [state, dispatch]
  );

  return value;
}

export function getPrefKey(orgId: string): string {
  return `keyboardShorcuts:${orgId}`;
}

export const HotKeysProvider: React.FC = ({ children }) => {
  const { currentUser } = useCurrentUser();
  const value = useHotKeyState();
  const { state, dispatch } = value;

  // setup initial state from user pref stored in localStorage
  React.useEffect(() => {
    if (currentUser) {
      const enabled = JSON.parse(localStorage.getItem(getPrefKey(currentUser.organization.id)) ?? "false");

      if (enabled && !state.meta.enabled) {
        dispatch({ type: "ENABLE_ALL_HOTKEYS" });
      }
      if (!enabled) {
        dispatch({ type: "DISABLE_ALL_HOTKEYS" });
      }
    }
  }, [currentUser, dispatch, state.meta.enabled]);

  return <HotKeysContext.Provider value={value}>{children}</HotKeysContext.Provider>;
};

export const HotKeysContext = React.createContext<ReturnType<typeof useHotKeyState>>({
  state: initState,
  dispatch: () => {}
});

interface HookOptions {
  name: string;
  description?: string;
  keys: string;
  group: string;
  handler: KeyBindingMap[string];
  // character used to separate different keys
  // in `keys` string. It defaults to `,` (comma)
  // This lets us bind the same handler to different
  // keys in one call to useHotKeys
  separator?: string;
  // These are the dependencies of the handler.
  // Same as the dependency array that you'd pass to React.useCallback if memoising the handler
  // This means the same caveats as React.useCallback apply
  // For e.g., deps: [{ a: 10 }] will cause and infinte loop as the first dep is recreated
  // whenver the component containing this code is called thus causing the useEffect in useHotKey
  // to become unstable
  deps?: Array<unknown>;
}

function isTargetEditable(event: KeyboardEvent): boolean {
  const target = (event.target || event.srcElement) as HTMLElement;
  const { tagName } = target;
  return target.isContentEditable || tagName === "INPUT" || tagName === "TEXTAREA" || tagName === "SELECT";
}

// This is a _simple_ hook around hotkeys-js and thus doesn't provide any support for
// scopes or filters, etc that are provided by hotkeys-js.
export function useHotKeys({ name, description = "", keys, separator = ",", handler, group, deps }: HookOptions) {
  const { state, dispatch } = React.useContext(HotKeysContext);
  const memoisedHandler = React.useCallback(
    (event: KeyboardEvent) => {
      // only run the register hotkey handler if hotkeys are enabled
      // and the keyboard events are not coming from an editable field
      if (state.meta.enabled && !isTargetEditable(event)) {
        return handler(event);
      }
    },
    /* we ignore the lint error as we want this memoised handler to only change
     * when what _it_ depends on changes such that we get a stable useEffect below
     * that doesn't go in an infite loop because the handler fn isn't the same as
     * in the prev invocation (for e.g., this occurs if we define a handler inline in the component)
     * With this setup, we are free to define inline handlers in the component that uses this hook
     * without worrying about the stability of the useEffect used here to register the keyboard
     * shortcut.
     * In other words, this dependency array is purpose built for a certain behaviour
     * i.e., keep this memoisedHandler stable and change it only if at the callsite
     * of this hook, we expect the passed handler behaviour to change.
     * NOTE: please make sure that the passed in dep array doesn't not contain
     * and non-primitive values. If it does, make sure those values are memoised as well
     * or just don't pass them in if they can be ignored from the perspective of the handler.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps ? [state.meta.enabled, ...deps] : [state.meta.enabled]
  );

  React.useEffect(() => {
    const keysArray = keys.split(separator).map(keys => keys.trim());
    const keybindingsObject = Object.fromEntries(keysArray.map(keys => [keys, memoisedHandler]));
    const unbind = tinykeys(window, keybindingsObject);
    dispatch({ type: "ADD_HOTKEY", payload: { keys, name, description, group } });

    return () => {
      unbind();
      dispatch({ type: "REMOVE_HOTKEY", payload: { keys } });
    };
  }, [dispatch, keys, separator, name, description, group, memoisedHandler]);
}

export const HotkeysHelp: React.FC<{ isShown: boolean; onClose: () => void }> = ({ isShown, onClose }) => {
  const { state: hotkeysState } = React.useContext(HotKeysContext);
  const groupedHotkeyState = groupBy(hotkeysState.bindings, "group");
  const subtitle = hotkeysState.meta.enabled ? (
    <Typo.Body>
      Keyboard shortcuts are <i>enabled</i>
    </Typo.Body>
  ) : (
    <Typo.Body>
      Keyboard shortcuts are <i>disabled</i>.{" "}
      <Link to="/settings/keyboard-shortcuts">
        <Typo.TextLink onClick={onClose}>Click here</Typo.TextLink>
      </Link>{" "}
      to enable them.
    </Typo.Body>
  );

  return (
    <Modal onDismiss={onClose} isOpen={isShown}>
      <Dialog loading={false} medium title="Keyboard shortcuts" subtitle={subtitle} onClose={onClose}>
        <div
          css={css`
            & > * + * {
              margin-top: var(--space-3-px);
            }
          `}
        >
          {Object.keys(groupedHotkeyState).map(group => {
            return (
              <div
                key={group}
                css={css`
                  & > * + * {
                    margin-top: var(--space-1-px);
                  }
                `}
              >
                <Typo.Body sizeL bold>
                  {upperFirst(group)}
                </Typo.Body>
                <div>
                  {groupedHotkeyState[group].map(h => {
                    return (
                      <Row
                        css={css`
                          & > * + * {
                            margin-left: var(--space-2-px);
                          }
                        `}
                        key={h.keys}
                      >
                        <Typo.Body
                          italic
                          css={css`
                            min-width: var(--space-6-px);
                          `}
                        >
                          {h.keys === "Shift+?" ? "?" : h.keys === "ArrowLeft,ArrowRight" ? "← →" : h.keys}
                        </Typo.Body>
                        <span>{h.name}</span>
                      </Row>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </Dialog>
    </Modal>
  );
};
