import * as React from "react";

import { AbsoluteTooltip, Avatar, Badge } from "src/components";
import { GUserGet, GUserGetVariables } from "./typings/GUserGet";

import { ReactComponent as MdiAccountPlus } from "src/assets/material/account_plus-24px.svg";
import { USER_INFO_FRAGMENT } from "src/fragments/UserInfoFragment";
import { UserComponentFields } from ".";
import { authTokenContext } from "src/util";
import { css } from "@emotion/core";
import gql from "graphql-tag";
import { initialsOf } from "src/util/formatters";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { useQuery } from "react-apollo";

interface IUserAvatar {
  userId?: string;
  user?: UserComponentFields;

  /* hack to pass css prop */
  className?: string;
  /* TODO: set up logic for hovering */
  hoverable?: boolean;
  /**
   * dhl auth token
   */
  authToken?: string;
  "data-testid"?: string;
  zIndex?: number;

  /* pass css modifier props */
  shadow?: boolean;
  sizeXL?: boolean;
  sizeL?: boolean;
  sizeM?: boolean;
  sizeS?: boolean;
  sizeXS?: boolean;
}

export const UserAvatar: React.ComponentType<IUserAvatar> = props => {
  // the query below logic should only run in DHL view thus making our in app views perf non-💩
  const userResponse = useQuery<GUserGet, GUserGetVariables>(
    gql`
      query UserInfo($id: ID!) {
        user(id: $id) {
          ...UserInfo
        }
      }
      ${USER_INFO_FRAGMENT}
    `,
    {
      variables: {
        id: props.userId ?? ""
      },
      skip: !!props.user || !props.userId,
      context: props.authToken ? authTokenContext(props.authToken) : undefined
    }
  );
  const { currentUser } = useCurrentUser();

  if (!props.userId && !props.user) return null;
  // AvatarPlaceholder is rendered while the user data is being fetched

  const passedOrFetchedUser = props.user ?? userResponse?.data?.user;
  const isCurrentUser = passedOrFetchedUser && !!passedOrFetchedUser.id && passedOrFetchedUser?.id === currentUser?.id;
  // make sure name ain't an enmpty string
  const nameOrEmail = !!passedOrFetchedUser?.name
    ? passedOrFetchedUser?.name
    : passedOrFetchedUser
    ? passedOrFetchedUser.email
    : "";

  return (
    <div
      css={css`
        /* render same cursor than parent */
        cursor: inherit;
        z-index: ${props.zIndex};
      `}
      className={props.className}
    >
      <Avatar
        data-testid={props["data-testid"]}
        className={props.hoverable ? "hasHover" : ""}
        shadow={props.shadow}
        sizeXL={props.sizeXL}
        sizeL={props.sizeL}
        sizeM={props.sizeM}
        sizeS={props.sizeS}
        sizeXS={props.sizeXS}
        lightBlue={!!passedOrFetchedUser && isCurrentUser}
      >
        {!!passedOrFetchedUser ? initialsOf(nameOrEmail) : <MdiAccountPlus />}
      </Avatar>
    </div>
  );
};

/**
 * Display up to two badges for a user's teams
 */
export const UserTeamBadges: React.FC<{
  userId: string;
}> = props => {
  const { currentUser } = useCurrentUser();
  const userTeams = currentUser?.teams.filter(team => team.userIds.indexOf(props.userId) > -1);
  const twoUserTeams = userTeams?.slice(0, 2);
  const moreUserTeams = userTeams?.slice(2);
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1 0 auto;
        & > * + * {
          margin-left: var(--space-1-rem);
        }
      `}
    >
      {twoUserTeams?.map((team, i) => (
        <Badge key={i} unread>
          {team.slug}
        </Badge>
      ))}
      {!!moreUserTeams?.length && (
        <AbsoluteTooltip content={moreUserTeams.map(t => t.name).join()}>
          <Badge>+{moreUserTeams.length}</Badge>
        </AbsoluteTooltip>
      )}
    </div>
  );
};
