import { css } from "@emotion/core";
import { mdiLock, mdiThumbDown, mdiThumbUp } from "@mdi/js";
import { isEqual, some } from "lodash";
import * as React from "react";
import { MessageBox, TimelineEvent } from "src/App/Requests/components";
import { ResolveApprovalButton } from "src/App/Requests/DetailView/Timeline/ResolveApproval";
import { RequestGet_request_events } from "src/App/Requests/DetailView/typings/RequestGet";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { UserAvatar, UserComponentFields, UserName } from "src/App/User";
import { DateTime, MaterialIcon, MessageText, Row, SimpleTooltip } from "src/components";
import { ApprovalStatus, EventName, RequestChannels } from "src/globalTypes";
import { Typo } from "src/styling/primitives/typography";
import { formatLongDate } from "src/util/formatters";

/**
 * Given an approval ID and a list of events, checks if the approval with the provided ID
 * has already been resolved, i.e. if there's an APPROVALRESOLVED event for that specific
 * approval in the provided event list.
 * @param approvalId The ID of the approval whose resolved status should be checked
 * @param events A list of events that should be used for checking the approval's status
 * @returns True if the approval with the provided ID has already been resolved, false otherwise
 */
export function isApprovalResolved(approvalId: string, events: RequestGet_request_events[]) {
  return some(events, { approvalResolvedEvent: { approvalId } });
}

/**
 * Given an approval event and a list of events, checks if the provided event is the latest version
 * of the approval it represents. An APPROVALRESOLVED event is always the latest version, as an
 * approval can only be resolved once. An APPROVALADDED event is the latest version only if that
 * approval has not been resolved yet.
 * @param event The approval event that should be checked
 * @param events The list of all events for that specific request
 * @returns True if the provided approval event is the latest version, false otherwise
 */
export function isLatestApprovalVersion(event: RequestGet_request_events, events: RequestGet_request_events[]) {
  return (
    event.eventName === EventName.APPROVALRESOLVED ||
    (!!event.approvalAddedEvent && !isApprovalResolved(event.approvalAddedEvent.approvalId, events))
  );
}

export interface IApprovalAddedComponent {
  requestId: string;
  id: string;
  text: string;
  author: UserComponentFields;
  requester: UserComponentFields;
  approver: UserComponentFields;
  teamName: string;
  timestamp: ScalarDateTime;
  status: ApprovalStatus;
  channel?: RequestChannels;
}

export const ApprovalAddedComponent = React.memo(
  (props: IApprovalAddedComponent) => {
    const isApproved = props.status === ApprovalStatus.APPROVAL_STATUS_APPROVED;
    const isRejected = props.status === ApprovalStatus.APPROVAL_STATUS_REJECTED;
    const isRequester = props.author?.id === props.requester.id;
    const { currentUser } = useCurrentUser();

    return (
      <TimelineEvent
        alignLeft={isRequester}
        data-testid="request-note"
        avatar={<UserAvatar sizeL user={props.author} />}
        footer={
          <SimpleTooltip
            label={
              <span>
                Sent on <b>{formatLongDate(props.timestamp)}</b> <br />
                Only you and experts in <b>{props.teamName}</b> can see this. It is not visible to the requester.
              </span>
            }
          >
            <Row align="center">
              <UserName user={props.author} bold={true} />
              {","}&nbsp;
              <DateTime timestamp={props.timestamp} />
              <MaterialIcon path={mdiLock} size={1} />
            </Row>
          </SimpleTooltip>
        }
      >
        <MessageBox blue>
          <div>
            <UserName user={props.author} bold={true} />
            &nbsp;asked&nbsp;
            <UserName user={props.approver} you={props.author?.id === currentUser?.id ? "yourself" : "you"} bold />
            &nbsp;for approval
            <Typo.Body>
              <MessageText text={props.text} />
            </Typo.Body>
          </div>
          {(isApproved || isRejected) && (
            <MessageBox darkBlue>
              <Row align="center">
                <MaterialIcon
                  path={props.status === ApprovalStatus.APPROVAL_STATUS_APPROVED ? mdiThumbUp : mdiThumbDown}
                  size={1.125}
                  css={css`
                    margin-right: var(--space-2-rem);
                  `}
                />
                <b>{props.status === ApprovalStatus.APPROVAL_STATUS_APPROVED ? "Approved" : "Rejected"}</b>
              </Row>
            </MessageBox>
          )}
          {!isApproved && !isRejected && props.approver.id === currentUser?.id && (
            <div
              css={css`
                display: flex;
                & > * + * {
                  margin-left: var(--space-2-rem);
                }
              `}
            >
              <ResolveApprovalButton
                text="Approve"
                requestId={props.requestId}
                params={{
                  approvalId: props.id,
                  status: ApprovalStatus.APPROVAL_STATUS_APPROVED
                }}
              />
              <ResolveApprovalButton
                text="Reject"
                requestId={props.requestId}
                params={{
                  approvalId: props.id,
                  status: ApprovalStatus.APPROVAL_STATUS_REJECTED
                }}
              />
            </div>
          )}
        </MessageBox>
      </TimelineEvent>
    );
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps);
  }
);

export interface IApprovalResolvedComponent {
  requester: UserComponentFields;
  approver: UserComponentFields;
  author: UserComponentFields;
  teamName: string;
  text: string;
  approverComment: string | null;
  status: ApprovalStatus;
  timestamp: ScalarDateTime;
  channel?: RequestChannels;
}

export const ApprovalResolvedComponent = React.memo((props: IApprovalResolvedComponent) => {
  const { currentUser } = useCurrentUser();
  const isRequester = props.approver.id === props.requester.id;

  return (
    <TimelineEvent
      alignLeft={isRequester}
      data-testid="string"
      avatar={<UserAvatar sizeL user={props.approver} />}
      footer={
        <SimpleTooltip
          label={
            <span>
              Only you and experts in <b>{props.teamName}</b> can see this. It is not visible to the requester
            </span>
          }
          cursor="default"
        >
          <Row align="center">
            <UserName
              user={props.approver}
              bold={true}
              you={props.author?.id === currentUser?.id ? "Yourself" : "You"}
            />
            {","}&nbsp;
            <DateTime timestamp={props.timestamp} tooltip={true} />
            <MaterialIcon
              path={mdiLock}
              size={1}
              css={css`
                color: var(--text-3);
                margin: 0 0 0 0.375rem;
              `}
            />
          </Row>
        </SimpleTooltip>
      }
    >
      <MessageBox blue>
        <div>
          <Row align="center">
            <MaterialIcon
              path={props.status === ApprovalStatus.APPROVAL_STATUS_APPROVED ? mdiThumbUp : mdiThumbDown}
              size={1.125}
              css={css`
                margin-right: var(--space-2-rem);
              `}
            />
            <b>
              {props.status === ApprovalStatus.APPROVAL_STATUS_APPROVED ? "Approved" : "Rejected"} by{" "}
              <UserName user={props.approver} you="you" bold={true} />
            </b>
          </Row>
          <Typo.Body>{props.approverComment}</Typo.Body>
        </div>
        <MessageBox darkBlue>
          <div>
            <Typo.Body>
              <UserName user={props.author} bold={true} /> asked{" "}
              <UserName
                user={props.approver}
                you={!!props.author?.id && props.author.id === currentUser?.id ? "yourself" : "you"}
                bold={true}
              />{" "}
              for approval
            </Typo.Body>
            <MessageText text={props.text} />
          </div>
        </MessageBox>
      </MessageBox>
    </TimelineEvent>
  );
}, isEqual);
