import * as React from "react";

import { FC } from "react";
import Helmet from "react-helmet";

export const HelmetComponent: FC<{
  title: string;
  description?: string;
}> = ({ title, description }) => (
  <Helmet>
    <title>Back - {title}</title>
    <meta name="description" content={description ?? "A truly seamless employee experience"} />
  </Helmet>
);
