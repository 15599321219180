import { css } from "@emotion/core";
import gql from "graphql-tag";
import { debounce } from "lodash";
import * as React from "react";
import { useQuery } from "react-apollo";
import { getSourceName } from "src/App/KB";
import { sortedDocumentList } from "src/App/KB/Docs";
import { DocumentLogoForSourceKind } from "src/App/KB/DocumentLogos";
import { Button, Col, Dialog, EmptyState, Input, LoadingBar, Table } from "src/components";
import { PopOverMenu } from "src/components/PopOver/PopOverMenu";
import { ExternalDocumentSourceDocumentSyncType, ExternalDocumentSourceKind } from "src/globalTypes";
import { Modal } from "src/portals/Modal";
import { Toast } from "src/portals/Toast";
import { Typo } from "src/styling/primitives/typography";
import { openNewTab } from "src/util";
import { useExternalDocumentsList } from ".";
import { ExternalDocuments_externalDocumentSources_synchronizedDocuments } from "./typings/ExternalDocuments";
import {
  ExternalDocumentsSearchAvailable,
  ExternalDocumentsSearchAvailableVariables
} from "./typings/ExternalDocumentsSearchAvailable";

export const AddModal: React.FC<{
  isOpen: boolean;
  onDismiss(): void;
  source: { id: string; kind: ExternalDocumentSourceKind };
}> = props => {
  const sourceName = getSourceName(props.source.kind);

  const [searchQuery, privateSetSearchQuery] = React.useState("");
  const documentSearchResponse = useQuery<ExternalDocumentsSearchAvailable, ExternalDocumentsSearchAvailableVariables>(
    gql`
      query ExternalDocumentsSearchAvailable($sourceId: ID!, $query: String!) {
        externalDocumentSourceSearchAvailableDocuments(sourceId: $sourceId, query: $query) {
          id
          title
          url
          createTime
          updateTime
        }
      }
    `,
    {
      skip: searchQuery.length < 2,
      variables: {
        sourceId: props.source.id,
        query: searchQuery
      }
    }
  );

  const setSearchQuery = React.useCallback(
    debounce((query: string) => privateSetSearchQuery(query), 200),
    []
  );

  const { externalDocumentsResponse, setDocSyncType, syncingDocIds } = useExternalDocumentsList();

  const synchronizedDocumentsById = new Map<string, ExternalDocuments_externalDocumentSources_synchronizedDocuments>();

  for (const d of externalDocumentsResponse.data?.externalDocumentSources.find(s => s.id === props.source.id)
    ?.synchronizedDocuments ?? []) {
    synchronizedDocumentsById.set(d.document.id, d);
  }

  const documentsSearchWithSyncStatus = documentSearchResponse.data?.externalDocumentSourceSearchAvailableDocuments.map(
    doc => {
      return {
        ...doc,
        syncType:
          synchronizedDocumentsById.get(doc.id)?.syncType ??
          ExternalDocumentSourceDocumentSyncType.DOCUMENT_SYNC_TYPE_UNSPECIFIED
      };
    }
  );
  const documentsSearchResults = sortedDocumentList(documentsSearchWithSyncStatus ?? []);

  return (
    <Modal isOpen={props.isOpen} onDismiss={props.onDismiss} alignTop>
      <Dialog large onClose={props.onDismiss} title={`Select ${sourceName} pages`}>
        <Col flex="1 1 auto">
          {documentSearchResponse.error && <Toast kind="error" message={documentSearchResponse.error.message} />}
          {documentSearchResponse.loading && <LoadingBar />}
          <Input
            autoFocus
            placeholder={`Start typing to search for pages in ${sourceName}`}
            onChange={e => {
              setSearchQuery(e.target.value);
            }}
          />
          {/* only show after results loaded and none found */}
          {!documentSearchResponse.loading &&
            documentSearchResponse.data &&
            !documentSearchResponse.data.externalDocumentSourceSearchAvailableDocuments?.length && (
              <EmptyState
                title="No pages found"
                subtitle={`We could not find any pages in ${sourceName} matching your search`}
                spacing="tight"
                variant="info"
              />
            )}
          {documentsSearchResults && documentsSearchResults.length > 0 && (
            <Table
              hasClickableRows
              css={css`
                margin-top: var(--space-5-px);
              `}
            >
              <thead>
                <tr>
                  <th>Title</th>
                </tr>
              </thead>
              <tbody>
                {documentsSearchResults.map((doc, i) => {
                  const isSyncing = syncingDocIds.has(doc.id);
                  return (
                    <tr
                      key={i}
                      onClick={() => {
                        if (doc.url) openNewTab(doc.url);
                      }}
                    >
                      <td>
                        <div
                          css={css`
                            display: flex;
                            align-items: center;

                            & * + * {
                              margin-left: 0.375rem;
                            }
                          `}
                        >
                          <DocumentLogoForSourceKind kind={props.source.kind} />
                          <span>{doc.title}</span>
                          {isSyncing && <Typo.Body light>Syncing...</Typo.Body>}
                          <div
                            css={css`
                              display: flex;
                              flex-grow: 1;
                              justify-content: flex-end;
                            `}
                          >
                            {doc.syncType === ExternalDocumentSourceDocumentSyncType.DOCUMENT_SYNC_TYPE_RECURSIVE && (
                              <Typo.Body light>Page including sub-pages added</Typo.Body>
                            )}
                            {doc.syncType === ExternalDocumentSourceDocumentSyncType.DOCUMENT_SYNC_TYPE_SINGLE && (
                              <Typo.Body light>Page added</Typo.Body>
                            )}
                            {doc.syncType === ExternalDocumentSourceDocumentSyncType.DOCUMENT_SYNC_TYPE_UNSPECIFIED && (
                              <PopOverMenu
                                trigger={
                                  <Button disabled={isSyncing} variant="secondary" size="small">
                                    Add...
                                  </Button>
                                }
                                options={[
                                  {
                                    id: "page",
                                    name: "Add this page only",
                                    onClick() {
                                      setDocSyncType(
                                        props.source.id,
                                        doc.id,
                                        ExternalDocumentSourceDocumentSyncType.DOCUMENT_SYNC_TYPE_SINGLE
                                      );
                                    }
                                  },
                                  {
                                    id: "sub-pages",
                                    name: "Add including sub-pages",
                                    onClick() {
                                      setDocSyncType(
                                        props.source.id,
                                        doc.id,
                                        ExternalDocumentSourceDocumentSyncType.DOCUMENT_SYNC_TYPE_RECURSIVE
                                      );
                                    }
                                  }
                                ]}
                                css={css`
                                  z-index: var(--z-highest);
                                `}
                              />
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </Col>
      </Dialog>
    </Modal>
  );
};
