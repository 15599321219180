import { BaseLayout, Button, IconAndText, LoadingBar, MaterialIcon } from "src/components";
import { BestPracticeList, BestPracticeList_bestPracticeList } from "src/App/BestPractices/typings/BestPracticeList";
import { GenericSvgFromString, Icon } from "src/components/Icon";
import { Link, RouteComponentProps } from "src/util/router";
import { OptionMap, PopOver } from "src/components/PopOver";
import React, { useState } from "react";
import {
  bestPracticeBGColors,
  bestPracticeRequiredIntegrations,
  bestPracticeTeamTypes
} from "src/App/BestPractices/enumTranslations";

import BrushBig from "src/assets/brushBig.svg";
import { Card } from "src/styling/primitives/Card";
import { Subheading } from "src/App/Requests/DetailView/Component";
import { Toast } from "src/portals/Toast";
import { Tools } from "src/svg/icons/Tools";
import { Typo } from "src/styling/primitives/typography";
import { Users } from "src/svg/icons/Users";
import { css } from "@emotion/core";
import gql from "graphql-tag";
import { mdiSwapVertical } from "@mdi/js";
import { motion } from "framer-motion";
import { trackBPGalleryUseCaseFilterChange } from "src/util/analytics";
import { useQuery } from "react-apollo";

const defaultFilterOption = { id: "all", name: "All use cases" };

export const generateUseCaseOptionsFromResponseData = (
  bestPractices: BestPracticeList_bestPracticeList[]
): OptionMap[] => {
  if (!bestPractices.length) {
    return [];
  }
  const presentUseCases = bestPractices.map(bp => bp?.useCase);
  const presentUseCasesAsOptions = [...new Set(presentUseCases)]
    .map(
      useCase =>
        ({
          id: useCase,
          name: useCase
        } as OptionMap)
    )
    .sort((a, b) => {
      const textA = a.id.toLowerCase();
      const textB = b.id.toLowerCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  return [defaultFilterOption, ...presentUseCasesAsOptions];
};

export const BestPracticeGallery: React.FC<RouteComponentProps> = () => {
  const [bpFilter, setBpFilter] = useState<string>("all");
  const bestPracticeList = useQuery<BestPracticeList>(
    gql`
      query BestPracticeList {
        bestPracticeList {
          id
          name
          summary
          color
          icon
          isFeatured
          preview {
            ... on BestPracticePreviewSVG {
              svg
            }
          }
          useCase
          teamsInvolved
          requiredIntegrations
        }
      }
    `
  );

  // take the first bp with isFeatured flag
  const featuredBestPractice = (bestPracticeList.data?.bestPracticeList || []).find(bp => bp.isFeatured);

  const notFeaturedBestPractices = (bestPracticeList.data?.bestPracticeList || []).filter(
    bp => bp.id !== featuredBestPractice?.id
  );

  // generate the pop over options from the distinct use cases
  const filters = generateUseCaseOptionsFromResponseData(notFeaturedBestPractices);

  // Filter the best practices by useCase, if the filter state is not "all"
  const filteredBestPracticesList = notFeaturedBestPractices.filter(bp =>
    bpFilter === defaultFilterOption.id ? true : bp?.useCase === bpFilter
  );

  return (
    <BaseLayout
      stickyHeader={
        <div
          css={css`
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <Typo.Body bold>Best practices</Typo.Body>
          <PopOver.Menu
            onSelect={(filter: string) => {
              setBpFilter(filter);
              trackBPGalleryUseCaseFilterChange(filter);
            }}
            selected={bpFilter}
            options={filters}
            css={[
              css`
                z-index: var(--z-high);
              `
            ]}
            trigger={
              <Button variant="secondary" size="small">
                <MaterialIcon path={mdiSwapVertical} size={1} />
                {/* Show label before all bps are loaded */}
                {bpFilter ? filters.find(e => e.id === bpFilter)?.name : defaultFilterOption.name}
              </Button>
            }
          />
        </div>
      }
    >
      {featuredBestPractice && (
        <Card
          sizeL
          hasExtraPadding
          data-testid="bestpractices-card-featured"
          css={css`
          margin: var(--space-7-rem) 0;
          background-image: url('${BrushBig}');
          background-size: auto 100%;
          background-position: right top;
          background-repeat: no-repeat;
        `}
        >
          <div
            css={css`
              display: grid;
              grid-gap: var(--space-6-rem);
              @media (min-width: 1025px) {
                /* max width defned by designer */
                grid-template-columns: minmax(auto, 370px) 1fr;
              }
              @media (max-width: 1024px) {
                grid-template-rows: 1fr;
              }
            `}
          >
            <div>
              <Typo.Body
                css={css`
                  margin-bottom: var(--space-2-rem);
                `}
              >
                Recommended
              </Typo.Body>
              <Typo.Body
                sizeXXL
                css={css`
                  margin-bottom: var(--space-3-rem);
                  font-weight: var(--font-weight-body-regular);
                `}
              >
                {featuredBestPractice.name}
              </Typo.Body>
              <Typo.Body
                css={css`
                  margin-bottom: var(--space-4-rem);
                  max-height: 4rem; /* 64px */
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 3;
                `}
              >
                {featuredBestPractice.summary}
              </Typo.Body>
              <div
                css={css`
                  display: flex;
                  margin-bottom: var(--space-6-rem);
                `}
              >
                <div
                  css={css`
                    flex: 0 1 10rem;
                    & > * + * {
                      margin-top: var(--space-1-rem);
                    }
                  `}
                >
                  <Subheading>Suitable for</Subheading>
                  <IconAndText
                    icon={<Users />}
                    label={featuredBestPractice.teamsInvolved.map(team => bestPracticeTeamTypes[team]).join(", ")}
                  />
                </div>
                {!!(featuredBestPractice.requiredIntegrations && featuredBestPractice.requiredIntegrations.length) && (
                  <div
                    css={css`
                      flex: 1 1 auto;
                      & > * + * {
                        margin-top: var(--space-1-rem);
                      }
                    `}
                  >
                    <Subheading>Integrations included</Subheading>
                    <IconAndText
                      icon={<Tools />}
                      label={featuredBestPractice.requiredIntegrations
                        .map(integration => bestPracticeRequiredIntegrations[integration])
                        .join(", ")}
                    />
                  </div>
                )}
              </div>
              <Link
                to={`/best-practices/${featuredBestPractice.id}`}
                title={`See details for "${featuredBestPractice.name}"`}
                data-testid="bestpractices-see-details"
              >
                <Button variant="darkBlue" size="medium">
                  See details
                </Button>
              </Link>
            </div>
            {featuredBestPractice.preview && (
              <div
                css={css`
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                `}
              >
                <GenericSvgFromString
                  css={css`
                    max-width: 460px;
                    height: auto;
                    flex: 1 0 auto;
                  `}
                  svg={featuredBestPractice.preview.svg}
                />
              </div>
            )}
          </div>
        </Card>
      )}
      <Typo.Body
        css={css`
          margin-bottom: var(--space-4-rem);
        `}
      >
        Explore more
      </Typo.Body>
      {bestPracticeList.loading && <LoadingBar />}
      {bestPracticeList.error && <Toast kind="error" message={bestPracticeList.error?.message} />}
      {!bestPracticeList.loading && !!filteredBestPracticesList.length && (
        <div
          data-testid="best-practice-list-container"
          css={css`
            display: grid;
            grid-gap: var(--space-6-rem);
            @media (min-width: 1025px) {
              grid-template-columns: 1fr 1fr;
            }
            @media (max-width: 1024px) {
              grid-template-rows: 1fr;
            }
          `}
        >
          {filteredBestPracticesList.map(bp => {
            return (
              <motion.div
                key={bp.id}
                initial={{ opacity: 0, y: 32 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 32 }}
              >
                <Card sizeL hasExtraPadding hasInteractions>
                  <Link to={`/best-practices/${bp.id}`} title={`See details for "${bp.name}"`}>
                    <div
                      css={css`
                        display: flex;
                        margin-bottom: var(--space-4-rem);
                      `}
                    >
                      <Icon
                        sizeL
                        css={[
                          css`
                            padding: var(--space-1-rem);
                            border-radius: var(--border-radius-round);
                          `,
                          bestPracticeBGColors[bp.color]
                        ]}
                      >
                        <GenericSvgFromString
                          css={css`
                            height: var(--space-6-rem);
                            min-width: var(--space-6-rem);
                            width: var(--space-6-rem);
                            border-radius: var(--border-radius-s);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            > svg {
                              fill: rgb(87, 97, 121);
                            }
                          `}
                          svg={bp.icon}
                        />
                      </Icon>
                    </div>
                    <Typo.Body
                      sizeXL
                      css={css`
                        margin-bottom: var(--space-3-rem);
                      `}
                    >
                      {bp.name}
                    </Typo.Body>
                    <Typo.Body
                      css={css`
                        max-height: 2.625rem; /* 42px */
                        min-height: 2.625rem; /* 42px */
                        /* min-height is needed to have even gaps in two column grid */
                        margin-bottom: var(--space-6-rem);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                      `}
                    >
                      {bp.summary}
                    </Typo.Body>
                    <div
                      css={css`
                        display: flex;
                        & > * {
                          flex: 1 0 auto;
                        }
                      `}
                    >
                      <div
                        css={css`
                          display: flex;
                        `}
                      >
                        <div
                          css={css`
                            flex: 0 1 10rem;
                            & > * + * {
                              margin-top: var(--space-1-rem);
                            }
                          `}
                        >
                          <Subheading>Suitable for</Subheading>
                          <IconAndText
                            icon={<Users />}
                            label={bp.teamsInvolved.map(team => bestPracticeTeamTypes[team]).join(", ")}
                          />
                        </div>
                        {!!(bp.requiredIntegrations && bp.requiredIntegrations.length) && (
                          <div
                            css={css`
                              flex: 1 1 auto;
                              & > * + * {
                                margin-top: var(--space-1-rem);
                              }
                            `}
                          >
                            <Subheading>Integrations included</Subheading>
                            <IconAndText
                              icon={<Tools />}
                              label={bp.requiredIntegrations
                                .map(integration => bestPracticeRequiredIntegrations[integration])
                                .join(", ")}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </Link>
                </Card>
              </motion.div>
            );
          })}
        </div>
      )}
    </BaseLayout>
  );
};
