import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { mdiEmail } from "@mdi/js";
import { apolloClient } from "src/util/apollo/client";
import { EditorState } from "draft-js";
import createMentionPlugin from "draft-js-mention-plugin";
import "draft-js-mention-plugin/lib/plugin.css";
import "draft-js/dist/Draft.css";
import gql from "graphql-tag";
import { debounce, pick } from "lodash";
import * as React from "react";
import { useCallback, useState } from "react";
import { EllipsisText, MaterialIcon, Row, Text } from "src/components";
import { fontWeights } from "src/styling/typography";
import * as yup from "yup";
import { RequestGet_request } from "../typings/RequestGet";
import { EntryItem } from "./Mentions";
import { UserExternalsList, UserExternalsListVariables } from "./typings/UserExternalsList";

const schema = yup.string().email();
const isValidEmail = (email: string) => {
  return schema.isValidSync(email);
};

const Popover = styled.div`
  &::before {
    display: block;
    content: "Notify someone by typing their email";
    padding: 0 1rem 0.375rem 1rem;
    font-weight: ${fontWeights.bold};
  }
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.5rem 0 0;
  border-radius: 0.75rem;
  background: var(--border);
`;

const USER_EXTERNALS_LIST = gql`
  query UserExternalsList($teamId: ID!, $filter: String) {
    userList2(query: { externalsOnly: true, teams: [$teamId], filterNameOrEmail: $filter }, first: 5) {
      users {
        id
        name
        email
      }
    }
  }
`;

const getExternals = async (inputEmail: string, teamId: string): Promise<UserExternalsList["userList2"]> => {
  const response = await apolloClient.query<UserExternalsList, UserExternalsListVariables>({
    fetchPolicy: "network-only",
    query: USER_EXTERNALS_LIST,
    variables: {
      teamId: teamId,
      filter: inputEmail
    }
  });
  return response.data?.userList2;
};

/**
 * Mention suggestions popover
 * Loads cached sidebar data to check team membership
 */
export function Externals(props: {
  request: RequestGet_request;
  editorState: EditorState;
  mentionPlugin: ReturnType<typeof createMentionPlugin>;
  setEditorState(state: EditorState): void;
  teamId: string;
}) {
  const [inputEmail, setInputEmail] = useState("");
  const [suggestions, setSuggestions] = useState<Array<{ name: string; email: string }>>([]);

  const debouncedSetInputEmail = useCallback(
    debounce(async e => {
      const data = await getExternals(e, props.teamId);
      const newSuggestions = data.users.map(u => ({ name: u.email, email: u.email })).concat({ name: e, email: e });
      setInputEmail(inputEmail);
      setSuggestions(newSuggestions);
    }, 200),
    [setInputEmail, setSuggestions, getExternals]
  );

  return (
    <div>
      <props.mentionPlugin.MentionSuggestions
        onSearchChange={async (change: { value: string }) => {
          debouncedSetInputEmail(change.value);
        }}
        onAddMention={(mention: { name: string; email: string }) => {
          if (!isValidEmail(mention.name)) {
            // hack to prevent adding mention to editorstate
            setTimeout(() => props.setEditorState(props.editorState), 1);
          }
        }}
        suggestions={suggestions}
        popoverComponent={<Popover />}
        entryComponent={(entryProps: { isFocused?: boolean; mention: { name: string; email: string } }) => {
          const invalid = !isValidEmail(entryProps.mention.email);
          return (
            entryProps.mention.name.length > 0 && (
              <EntryItem
                {...pick(entryProps, ["onMouseEnter", "onMouseDown", "onMouseUp"])}
                focused={!!entryProps.isFocused}
                invalid={invalid}
              >
                <Row align="center" flex="1 1 auto" overflow="hidden" margin="0 0.5rem">
                  <Circle>
                    <MaterialIcon path={mdiEmail} size={1} />
                  </Circle>
                  <EllipsisText>{entryProps.mention.name}</EllipsisText>
                  <Text
                    css={css`
                      color: var(--text-3);
                    `}
                  >
                    {invalid ? "Please enter a valid email address" : "Press return to confirm"}
                  </Text>
                </Row>
              </EntryItem>
            )
          );
        }}
      />
    </div>
  );
}
