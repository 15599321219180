import { setContext } from "apollo-link-context";
import { authTokenContext } from "src/util";

// cached storage for the auth token
let token: string | null = null;

export const setAuthToken = (tokenString: string) => {
  token = tokenString;
};

export const authFlowLink = setContext(() => {
  return authTokenContext(token);
});
