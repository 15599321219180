import { css } from "@emotion/core";
import { PlayArrow } from "@material-ui/icons";
import { mdiCloseCircle, mdiInformation } from "@mdi/js";
import { Field, Formik } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { useState } from "react";
import { useLazyQuery } from "react-apollo";
import { DocumentLogo } from "src/App/KB/DocumentLogos";
import {
  DocumentSuggestionAnalysis,
  DocumentSuggestionAnalysisVariables,
  DocumentSuggestionAnalysis_documentSuggestionAnalysis,
  DocumentSuggestionAnalysis_documentSuggestionAnalysis_resultsList
} from "src/App/KB/typings/DocumentSuggestionAnalysis";
import Score0SVG from "src/assets/score/Score0.svg";
import Score1SVG from "src/assets/score/Score1.svg";
import Score2SVG from "src/assets/score/Score2.svg";
import Score3SVG from "src/assets/score/Score3.svg";
import Score4SVG from "src/assets/score/Score4.svg";
import Score5SVG from "src/assets/score/Score5.svg";
import { Badge, EmptyState, FormikInput, Icon, MaterialIcon, SimpleTooltip, SubmitButton } from "src/components";
import { Form } from "src/components/Fields/FormWrappers";
import { Table } from "src/components/Table";
import { DocumentSource, EmptyResultsReason, HRISFilterResult } from "src/globalTypes";
import { Toast } from "src/portals/Toast";
import { Typo } from "src/styling/primitives/typography";
import { CheckCircle } from "src/svg/icons/CheckCircle";
import { HelpCircle } from "src/svg/icons/HelpCircle";
import { Human } from "src/svg/icons/Human";
import { openNewTab, reportDevError } from "src/util";
import { KBSuggestionDisplayResult, trackKBSuggestionAnalysis } from "src/util/analytics";
import { csx } from "src/util/csx";
import { pluralize } from "src/util/formatters";

type Analysis = DocumentSuggestionAnalysis_documentSuggestionAnalysis;
type Result = DocumentSuggestionAnalysis_documentSuggestionAnalysis_resultsList;

type DisplayedResult = {
  result: Result;
  notShownReason?: NotShownReason;
};

enum NotShownReason {
  RELEVANCE,
  TARGETING,
  NUMBER_OF_SUGGESTIONS
}

export const Playground: React.FC<{}> = () => {
  const [query, setQuery] = useState("");

  const [getSuggestionAnalysis, { data, error, loading }] = useLazyQuery<
    DocumentSuggestionAnalysis,
    DocumentSuggestionAnalysisVariables
  >(
    gql`
      query DocumentSuggestionAnalysis($query: String!) {
        documentSuggestionAnalysis(query: $query) {
          maxResults
          scoreThreshold
          emptyResultsReason
          resultsList {
            score
            hrisFilterResult
            document {
              pk
              id
              source
              title
              url
              external {
                pk
                source {
                  id
                  kind
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      onCompleted: data => {
        const { shown, notShown } = convertAnalysisToDisplay(data.documentSuggestionAnalysis);
        track(shown, notShown, data.documentSuggestionAnalysis.emptyResultsReason);
      }
    }
  );

  const { shown, notShown } = data?.documentSuggestionAnalysis
    ? convertAnalysisToDisplay(data.documentSuggestionAnalysis)
    : { shown: [], notShown: [] };

  return (
    <>
      {error && <Toast kind="error" message={error.message} />}
      <Formik
        initialValues={{ query: "" }}
        onSubmit={async (values, actions) => {
          await getSuggestionAnalysis({
            variables: {
              query: values.query
            }
          });

          setQuery(values.query);

          actions.setSubmitting(false);
        }}
      >
        {form => (
          <Form
            onSubmit={form.handleSubmit}
            padding="0 0 1.5rem 0"
            css={css`
              display: flex;
              flex-direction: row;

              > * + * {
                margin-left: var(--space-4-rem);
              }
            `}
          >
            <Field
              name="query"
              placeholder="Ask a question"
              component={FormikInput}
              autoFocus
              fullWidth
              hideErrorLabel
            />
            <SubmitButton variant="secondary" disabled={!form.values.query || form.isSubmitting}>
              <PlayArrow />
              Ask
            </SubmitButton>
          </Form>
        )}
      </Formik>
      {!data?.documentSuggestionAnalysis &&
        !loading && ( // pre-query empty state
          <EmptyState title="Ask me anything!" subtitle="Ask your question to see what answers Back would suggest" />
        )}
      {data?.documentSuggestionAnalysis && shown.length === 0 && notShown.length === 0 && (
        <EmptyResults
          reason={
            data.documentSuggestionAnalysis.emptyResultsReason // empty results
          }
        />
      )}
      {data?.documentSuggestionAnalysis &&
        (shown.length > 0 || notShown.length > 0) && ( // results
          <SuggestedResults
            query={query}
            maxResults={data.documentSuggestionAnalysis.maxResults}
            shown={shown}
            notShown={notShown}
          />
        )}
    </>
  );
};

const EmptyResultText = {
  [EmptyResultsReason.EMPTY_RESULTS_REASON_UNSPECIFIED]: {
    title: "No suggestions",
    subtitle: (
      <Typo.Body>
        We could not find any answers that would relate to your question.
        <br />
        <br />
        Try including different keywords in your question. If you just added your answers, we might need some time to
        process them, so please try later.
      </Typo.Body>
    )
  },
  [EmptyResultsReason.EMPTY_RESULTS_REASON_ORG_HAS_NO_DOCS]: {
    title: "No articles to suggest",
    subtitle: (
      <Typo.Body>
        There are no articles that we could suggest. Go ahead and add some in the <b>Articles</b> tab to start answering
        employees' questions automatically.
      </Typo.Body>
    )
  },
  [EmptyResultsReason.EMPTY_RESULTS_REASON_QUERY_TOO_SHORT]: {
    title: "We need a bit more",
    subtitle: (
      <Typo.Body>
        To make sure we find the most relevant answers, our algorithm only kicks in for questions that are longer than 3
        words. Please try again with a longer question.
      </Typo.Body>
    )
  },
  [EmptyResultsReason.EMPTY_RESULTS_REASON_UNSUPPORTED_LANGUAGE]: {
    title: "We're not sure what you meant",
    subtitle: (
      <Typo.Body>
        Sorry, but we could not understand your question. This is likely because it was written in a language other than
        English, which is currently the only supported language.
      </Typo.Body>
    )
  }
};

const EmptyResults: React.FC<{ reason: EmptyResultsReason | null }> = props => {
  const { title, subtitle } = EmptyResultText[props.reason ?? EmptyResultsReason.EMPTY_RESULTS_REASON_UNSPECIFIED];
  return <EmptyState title={title} subtitle={subtitle} />;
};

const tooltipLabel = (maxResults: number) =>
  `For each question, Back evaluates whether any of the knowledge answers could be suggested as a relevant answer.

  This evaluation has three steps:
    1. A relevance score is calculated for each answer and those with low scores are eliminated.
    2. Targeting of each remaining answer is evaluated against the profile of the user, removing answers that don't fit.
    3. The remaining answers are sorted by their score and the ${maxResults} most relevant answers are suggested.

    Relevance scores are calculated using our ML model, comparing individual words in the question and the answer.`;

const SuggestionInfoTooltip: React.FC<{
  query: string;
  shownLength: number;
  maxResults: number;
}> = ({ query, shownLength, maxResults }) => {
  return (
    <span
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      `}
    >
      {shownLength === 0 && (
        <Typo.Body>
          For the question <b>"{query}"</b> Back could not find any answers to suggest:
        </Typo.Body>
      )}
      {shownLength > 0 && (
        <Typo.Body>
          For the question <b>"{query}"</b>, Back would suggest {pluralize(shownLength, "answer", "answers")}:
        </Typo.Body>
      )}
      <SimpleTooltip label={tooltipLabel(maxResults)}>
        <Typo.Body sizeS>
          <Typo.TextLink
            light
            css={css`
              display: flex;
              cursor: default;
            `}
          >
            <Icon
              sizeS
              css={css`
                margin-right: var(--space-1-rem);
              `}
            >
              <HelpCircle />
            </Icon>
            How does knowledge suggestion work?
          </Typo.TextLink>
        </Typo.Body>
      </SimpleTooltip>
    </span>
  );
};

const SuggestedResults: React.FC<{
  query: string;
  maxResults: number;
  shown: DisplayedResult[];
  notShown: DisplayedResult[];
}> = ({ query, maxResults, shown, notShown }) => {
  return (
    <div
      css={css`
        > :first-child + * {
          margin-top: var(--space-4-rem);
        }

        > table + p {
          margin-top: var(--space-7-rem);
        }

        > p + table {
          margin-top: var(--space-4-rem);
        }
      `}
    >
      <Typo.Body sizeL bold>
        {pluralize(shown.length, "suggestion", "suggestions", "No suggestions")}
      </Typo.Body>
      <SuggestionInfoTooltip query={query} shownLength={shown.length} maxResults={maxResults} />
      <SuggestedResultTable results={shown} includeNotShownReason={false} />
      {notShown.length > 0 && (
        <>
          <Typo.Body>
            <b>{pluralize(notShown.length, "answer", "answers")}</b> {notShown.length === 1 ? "was" : "were"} not
            suggested based on {notShown.length === 1 ? "its" : "their"} relevance, targeting, or the total number of
            suggestions:
          </Typo.Body>
          <SuggestedResultTable results={notShown} includeNotShownReason />
        </>
      )}
    </div>
  );
};

const BadgeForFilterResult = {
  [HRISFilterResult.HRIS_FILTER_RESULT_NO_FILTERS]: (
    <Badge success icon>
      <CheckCircle /> No rules
    </Badge>
  ),
  [HRISFilterResult.HRIS_FILTER_RESULT_UNSPECIFIED]: (
    <Badge success icon>
      <CheckCircle /> No rules
    </Badge>
  ),
  [HRISFilterResult.HRIS_FILTER_RESULT_ACCEPTED]: (
    <Badge success icon>
      <CheckCircle /> Match
    </Badge>
  ),
  [HRISFilterResult.HRIS_FILTER_RESULT_REJECTED]: (
    <Badge neutral icon>
      <MaterialIcon path={mdiCloseCircle} size={1.125} /> No match
    </Badge>
  )
};

const BadgeForNotShownReason = {
  [NotShownReason.RELEVANCE]: (
    <Badge info icon>
      <MaterialIcon path={mdiInformation} size={1.125} />
      Relevance
    </Badge>
  ),

  [NotShownReason.TARGETING]: (
    <Badge info icon>
      <Human />
      Targeting
    </Badge>
  ),
  [NotShownReason.NUMBER_OF_SUGGESTIONS]: <Badge info># of Suggestions</Badge>
};

const BadgeCell = csx(
  [
    css`
      > * {
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        width: fit-content;
      }

      > div > span > div {
        // disable the extra height from the material icon wrapper
        height: inherit;
      }

      span {
        padding: var(--space-1-rem);
        border-radius: var(--space-2-px);
      }

      svg {
        width: var(--space-3-rem);
        height: var(--space-3-rem);
        opacity: 0.7;
        padding-right: var(--space-1-rem);
      }
    `
  ],
  undefined,
  "td"
);

function SuggestedResultTable(props: { results: DisplayedResult[]; includeNotShownReason: boolean }) {
  const { results, includeNotShownReason } = props;
  return (
    <Table
      hasClickableRows={results.length !== 0}
      noCellEllipsis
      css={css`
        margin-top: var(--space-1-rem);
      `}
    >
      <thead>
        <tr>
          <th
            css={css`
              width: 50%;
            `}
          >
            Answer
          </th>
          <th
            css={css`
              width: 15%;
            `}
          >
            Relevance
          </th>
          <th>Targeting</th>
          {includeNotShownReason && <th>Reason</th>}
        </tr>
      </thead>
      <tbody>
        {results.length === 0 && (
          <tr>
            <td
              colSpan={3 + (includeNotShownReason ? 1 : 0)}
              css={css`
                text-align: center;
              `}
            >
              <Typo.Body lighter>No suggestions</Typo.Body>
            </td>
          </tr>
        )}
        {results.map(result => {
          return (
            <tr
              onClick={() => {
                const doc = result.result.document;
                if (doc.source === DocumentSource.internal) {
                  openNewTab(`/knowledge/${doc.id}/preview`);
                } else {
                  if (!doc.url) return reportDevError(`missing url for external doc: ${doc.pk}`);
                  openNewTab(doc.url);
                }
              }}
            >
              <td
                css={css`
                  display: flex;
                  align-items: center;
                  gap: var(--space-2-rem);
                  max-width: initial !important;

                  > img {
                    height: var(--space-4-rem);
                  }
                `}
              >
                <DocumentLogo doc={result.result.document} />
                {result.result.document.title}
              </td>
              <td>
                <SimpleTooltip label={`Relevance score: ${result.result.score.toFixed(2)}`}>
                  <img src={getRelevanceIconSVG(result.result.score)} alt={result.result.score.toString()} />
                </SimpleTooltip>
              </td>
              <BadgeCell>
                <SimpleTooltip label={filterResultLabel(result.result.hrisFilterResult)}>
                  {BadgeForFilterResult[result.result.hrisFilterResult]}
                </SimpleTooltip>
              </BadgeCell>
              {includeNotShownReason && result.notShownReason !== undefined && (
                <BadgeCell>
                  <SimpleTooltip label={notShownReasonLabel(result.notShownReason)}>
                    {BadgeForNotShownReason[result.notShownReason]}
                  </SimpleTooltip>
                </BadgeCell>
              )}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

function filterResultLabel(result: HRISFilterResult): string {
  switch (result) {
    case HRISFilterResult.HRIS_FILTER_RESULT_UNSPECIFIED:
    case HRISFilterResult.HRIS_FILTER_RESULT_NO_FILTERS:
      return "This answer has no targeting specified";
    case HRISFilterResult.HRIS_FILTER_RESULT_REJECTED:
      return "Current user does not match the targeting";
    case HRISFilterResult.HRIS_FILTER_RESULT_ACCEPTED:
      return "Current user matches the targeting";
  }
}

function notShownReasonLabel(reason: NotShownReason): string {
  switch (reason) {
    case NotShownReason.RELEVANCE:
      return "This answer would not be suggested due to its relevance score";
    case NotShownReason.TARGETING:
      return "This answer would not be suggested due to its targeting";
    case NotShownReason.NUMBER_OF_SUGGESTIONS:
      return "This answer would not be suggested due to the maximum number of suggestions";
  }
}

function convertAnalysisToDisplay(analysis: Analysis): { shown: DisplayedResult[]; notShown: DisplayedResult[] } {
  analysis.resultsList.sort((a, b) => b.score - a.score);

  const shown: DisplayedResult[] = [];
  const notShown: DisplayedResult[] = [];
  for (const result of analysis.resultsList) {
    if (result.score < analysis.scoreThreshold) {
      notShown.push({ result, notShownReason: NotShownReason.RELEVANCE });
    } else if (result.hrisFilterResult === HRISFilterResult.HRIS_FILTER_RESULT_REJECTED) {
      notShown.push({ result, notShownReason: NotShownReason.TARGETING });
    } else if (shown.length >= analysis.maxResults) {
      notShown.push({ result, notShownReason: NotShownReason.NUMBER_OF_SUGGESTIONS });
    } else {
      shown.push({ result });
    }
  }

  return { shown, notShown };
}

function getRelevanceIconSVG(score: number): string {
  if (score < 0.3) {
    return Score0SVG;
  } else if (score < 0.5) {
    return Score1SVG;
  } else if (score < 0.7) {
    return Score2SVG;
  } else if (score < 0.9) {
    return Score3SVG;
  } else if (score < 1.1) {
    return Score4SVG;
  } else {
    return Score5SVG;
  }
}

const notShownReasonToTrackingParam: (reason?: NotShownReason) => KBSuggestionDisplayResult = reason => {
  switch (reason) {
    case NotShownReason.RELEVANCE:
      return "score_too_low";
    case NotShownReason.TARGETING:
      return "filters_not_match";
    case NotShownReason.NUMBER_OF_SUGGESTIONS:
      return "too_many_suggestions";
    case undefined:
      return "ok";
  }
};

function track(shown: DisplayedResult[], notShown: DisplayedResult[], emptyResultsReason: EmptyResultsReason | null) {
  trackKBSuggestionAnalysis({
    total_suggestion_count: shown.length + notShown.length,
    valid_suggestion_count: shown.length,
    empty_results_reason: emptyResultsReason,
    suggestions: shown.concat(notShown).map(result => {
      return {
        document_pk: result.result.document.pk,
        score: result.result.score,
        result: notShownReasonToTrackingParam(result.notShownReason)
      };
    })
  });
}
