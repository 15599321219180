import { css } from "@emotion/core";
import gql from "graphql-tag";
import * as React from "react";
import { useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import { DOCUMENTS_LIST_GET } from "src/App/KB";
import { DocumentLogo, IDocument } from "src/App/KB/DocumentLogos";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { ExternalDocumentSourceDocumentSyncType } from "src/globalTypes";
import { Typo } from "src/styling/primitives/typography";
import { ExternalDocuments } from "./typings/ExternalDocuments";
import { SetSynchronizedDocuments, SetSynchronizedDocumentsVariables } from "./typings/SetSynchronizedDocuments";

export const EXTERNAL_DOCUMENTS_QUERY = gql`
  query ExternalDocuments {
    externalDocumentSources {
      id
      kind
      synchronizedDocuments {
        document {
          id
          title
          url
          createTime
          updateTime
        }
        syncType
      }
    }
  }
`;

export const useExternalDocumentsList = () => {
  const { emitSnack } = useSnack();

  const [syncingDocIds, setSyncingDocIds] = useState<Set<string>>(new Set([]));

  const externalDocumentsResponse = useQuery<ExternalDocuments>(EXTERNAL_DOCUMENTS_QUERY, {
    fetchPolicy: "network-only"
  });

  const [setSynchronizedDocuments, setSynchronizedDocumentsResponse] = useMutation<
    SetSynchronizedDocuments,
    SetSynchronizedDocumentsVariables
  >(
    gql`
      mutation SetSynchronizedDocuments($params: ExternalDocumentSourceSetSynchronizedDocumentsParameters!) {
        externalDocumentSourceSetSynchronizedDocuments(params: $params) {
          success
          message
        }
      }
    `,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: EXTERNAL_DOCUMENTS_QUERY
        },
        {
          query: DOCUMENTS_LIST_GET
        }
      ],
      onCompleted(data) {
        if (data.externalDocumentSourceSetSynchronizedDocuments.success) {
          emitSnack({
            type: "info",
            message: "Updating synced documents"
          });
        } else {
          emitSnack({
            type: "mutationError",
            message: data.externalDocumentSourceSetSynchronizedDocuments.message ?? "Unable to add document"
          });
        }
      }
    }
  );

  const setDocSyncType = async (
    sourceId: string,
    documentId: string,
    syncType: ExternalDocumentSourceDocumentSyncType
  ) => {
    const currentSynchronizedDocuments = externalDocumentsResponse.data?.externalDocumentSources.find(
      s => s.id === sourceId
    )?.synchronizedDocuments;
    if (!currentSynchronizedDocuments) {
      throw new Error(`Could not find source id ${sourceId}`);
    }
    await setSynchronizedDocuments({
      variables: {
        params: {
          sourceId,
          synchronizedDocuments: [
            ...currentSynchronizedDocuments
              .filter(({ document: { id } }) => id !== documentId)
              .map(({ document: { id }, syncType }) => ({
                documentId: id,
                syncType
              })),
            ...[
              {
                documentId,
                syncType
              }
              // remove if syncType === unspecified
            ].filter(
              ({ syncType }) => syncType !== ExternalDocumentSourceDocumentSyncType.DOCUMENT_SYNC_TYPE_UNSPECIFIED
            )
          ]
        }
      }
    });
    setSyncingDocIds(ids => {
      ids.add(documentId);
      return new Set(ids);
    });
  };

  return {
    externalDocumentsResponse,
    setDocSyncType,
    setDocSyncTypeLoading: setSynchronizedDocumentsResponse.loading,
    syncingDocIds
  };
};

export const ExternalDocumentLink: React.FC<{
  doc: IDocument & { url: string };
}> = ({ doc }) => (
  <Typo.Body>
    <Typo.ExternalLink
      href={doc.url || ""}
      target="_blank"
      rel="noreferrer noopener"
      css={css`
        display: inline-flex;
        align-items: center;
        gap: var(--space-1-rem);
      `}
    >
      <DocumentLogo doc={doc} />
      {doc.url}
    </Typo.ExternalLink>
  </Typo.Body>
);
