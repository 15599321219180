import { css } from "@emotion/core";
/**
 * LEGACY
 * Use primitives.ts
 */

/* @deprecated */
export const transitions = {
  colors: "0.1s ease-out"
};

/* @deprecated */
export const dialog = {
  shadow: css`
    box-shadow: var(--box-shadow-card);
  `
};

/* @deprecated */
export const popMenu = {
  shadow: css`
    box-shadow: 0 0.75rem 1.125rem rgba(var(--text-5-raw), 0.08), 0 0.0625rem 0.125rem rgba(var(--text-5-raw), 0.08),
      0 -0.0625rem 0.125rem rgba(var(--text-5-raw), 0.06);
  `
};

/* @deprecated */
export const fog = {
  bottom: {
    shadow: css`
      box-shadow: 0 0.125rem 0.5rem 0.5rem var(--white);
    `
  },
  top: {
    shadow: css`
      box-shadow: 0 -0.125rem 0.5rem 0.5rem var(--white);
    `
  }
};

/* @deprecated */
export const separator = {
  bottom: {
    shadow: css`
      box-shadow: 0 1rem 0.875rem -0.875rem rgba(var(--text-5-raw), 0.06),
        0 0.125rem 0.125rem -0.125rem rgba(var(--text-5-raw), 0.08),
        0 0.125rem 0.0625rem -0.0625rem rgba(var(--text-5-raw), 0.02);
    `
  },
  left: {
    shadow: css`
      box-shadow: -1rem 0 0.875rem -0.875rem rgba(var(--text-5-raw), 0.06),
        -0.125rem 0 0.125rem -0.125rem rgba(var(--text-5-raw), 0.08),
        -0.125rem 0 0.0625rem -0.0625rem rgba(var(--text-5-raw), 0.02);
    `
  }
};

/* @deprecated */
export const input = {
  shadow: css`
    box-shadow: 0.125rem 0.25rem 0.875rem rgba(var(--text-5-raw), 0.06);
  `
};
