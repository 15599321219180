/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActionKey {
  ApprovalRequest = "ApprovalRequest",
  CreateConversation = "CreateConversation",
  CreateConversationRequest = "CreateConversationRequest",
  CreateNote = "CreateNote",
  CreateProject = "CreateProject",
  CreateReply = "CreateReply",
  CreateRequest = "CreateRequest",
  CreateTask = "CreateTask",
  FetchUserProfile = "FetchUserProfile",
  JumpIf = "JumpIf",
  Launch = "Launch",
  LinkJiraRequest = "LinkJiraRequest",
  MapValue = "MapValue",
  SendForm = "SendForm",
  SetRequestAssignee = "SetRequestAssignee",
  SetRequestCategory = "SetRequestCategory",
  SetRequestDueDate = "SetRequestDueDate",
  SetRequestPriority = "SetRequestPriority",
  SetRequestProject = "SetRequestProject",
  SetRequestRequester = "SetRequestRequester",
  SetRequestStatus = "SetRequestStatus",
  SetRequestTeam = "SetRequestTeam",
  SetRequestTitle = "SetRequestTitle"
}

export enum ActivityStatus {
  ACTIVITY_STATUS_ACTIVE = "ACTIVITY_STATUS_ACTIVE",
  ACTIVITY_STATUS_INACTIVE = "ACTIVITY_STATUS_INACTIVE",
  ACTIVITY_STATUS_UNSPECIFIED = "ACTIVITY_STATUS_UNSPECIFIED"
}

export enum ApprovalStatus {
  APPROVAL_STATUS_APPROVED = "APPROVAL_STATUS_APPROVED",
  APPROVAL_STATUS_PENDING = "APPROVAL_STATUS_PENDING",
  APPROVAL_STATUS_REJECTED = "APPROVAL_STATUS_REJECTED",
  APPROVAL_STATUS_UNSPECIFIED = "APPROVAL_STATUS_UNSPECIFIED"
}

export enum AuthInvalid {
  ACCOUNT_LOCKED = "ACCOUNT_LOCKED",
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
  INVALID_PASSWORD = "INVALID_PASSWORD",
  INVALID_SLUG = "INVALID_SLUG",
  INVALID_USERNAME = "INVALID_USERNAME"
}

export enum AuthType {
  AUTH_TYPE_MIGRATE = "AUTH_TYPE_MIGRATE",
  AUTH_TYPE_PASSWORD = "AUTH_TYPE_PASSWORD",
  AUTH_TYPE_SSO = "AUTH_TYPE_SSO",
  AUTH_TYPE_UNSPECIFIED = "AUTH_TYPE_UNSPECIFIED"
}

export enum BestPracticeColor {
  COLOR_GREEN = "COLOR_GREEN",
  COLOR_PINK = "COLOR_PINK",
  COLOR_RED = "COLOR_RED",
  COLOR_UNSPECIFIED = "COLOR_UNSPECIFIED",
  COLOR_VIOLET = "COLOR_VIOLET"
}

export enum BestPracticeCompanyType {
  COMPANY_TYPE_DISTRIBUTED = "COMPANY_TYPE_DISTRIBUTED",
  COMPANY_TYPE_ONSITE = "COMPANY_TYPE_ONSITE",
  COMPANY_TYPE_REMOTE = "COMPANY_TYPE_REMOTE",
  COMPANY_TYPE_UNSPECIFIED = "COMPANY_TYPE_UNSPECIFIED"
}

export enum BestPracticeType {
  BEST_PRACTICE_TYPE_FORM = "BEST_PRACTICE_TYPE_FORM",
  BEST_PRACTICE_TYPE_POLICY = "BEST_PRACTICE_TYPE_POLICY",
  BEST_PRACTICE_TYPE_PROCESS = "BEST_PRACTICE_TYPE_PROCESS",
  BEST_PRACTICE_TYPE_UNSPECIFIED = "BEST_PRACTICE_TYPE_UNSPECIFIED"
}

export enum ChannelType {
  CHANNEL_TYPE_UNSPECIFIED = "CHANNEL_TYPE_UNSPECIFIED",
  DIRECT_MESSAGE = "DIRECT_MESSAGE",
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC"
}

export enum DayOfWeek {
  DOW_UNSPECIFIED = "DOW_UNSPECIFIED",
  FRIDAY = "FRIDAY",
  MONDAY = "MONDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
  THURSDAY = "THURSDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY"
}

export enum DocumentSource {
  confluence = "confluence",
  external = "external",
  internal = "internal",
  notion = "notion"
}

export enum EmptyResultsReason {
  EMPTY_RESULTS_REASON_ORG_HAS_NO_DOCS = "EMPTY_RESULTS_REASON_ORG_HAS_NO_DOCS",
  EMPTY_RESULTS_REASON_QUERY_TOO_SHORT = "EMPTY_RESULTS_REASON_QUERY_TOO_SHORT",
  EMPTY_RESULTS_REASON_UNSPECIFIED = "EMPTY_RESULTS_REASON_UNSPECIFIED",
  EMPTY_RESULTS_REASON_UNSUPPORTED_LANGUAGE = "EMPTY_RESULTS_REASON_UNSUPPORTED_LANGUAGE"
}

export enum EventName {
  APPROVALADDED = "APPROVALADDED",
  APPROVALRESOLVED = "APPROVALRESOLVED",
  ASSIGNMENTCHANGED = "ASSIGNMENTCHANGED",
  CCSADDED = "CCSADDED",
  CCSREMOVED = "CCSREMOVED",
  COMMENTADDED = "COMMENTADDED",
  COMMENTDELETED = "COMMENTDELETED",
  FORMSUBMITTED = "FORMSUBMITTED",
  NOTEADDED = "NOTEADDED",
  NOTEDELETED = "NOTEDELETED",
  PRIORITYCHANGED = "PRIORITYCHANGED",
  PROJECTCHANGED = "PROJECTCHANGED",
  REQUESTCREATED = "REQUESTCREATED",
  REQUESTERCHANGED = "REQUESTERCHANGED",
  STATUSUPDATED = "STATUSUPDATED",
  TASKCOMPLETED = "TASKCOMPLETED",
  TEAMASSIGNMENTCHANGED = "TEAMASSIGNMENTCHANGED",
  TITLECHANGED = "TITLECHANGED",
  UNSETEVENTNAME = "UNSETEVENTNAME"
}

export enum ExternalDocumentSourceDocumentSyncType {
  DOCUMENT_SYNC_TYPE_RECURSIVE = "DOCUMENT_SYNC_TYPE_RECURSIVE",
  DOCUMENT_SYNC_TYPE_SINGLE = "DOCUMENT_SYNC_TYPE_SINGLE",
  DOCUMENT_SYNC_TYPE_UNSPECIFIED = "DOCUMENT_SYNC_TYPE_UNSPECIFIED"
}

export enum ExternalDocumentSourceKind {
  EXTERNAL_SOURCE_KIND_CONFLUENCE = "EXTERNAL_SOURCE_KIND_CONFLUENCE",
  EXTERNAL_SOURCE_KIND_NOTION = "EXTERNAL_SOURCE_KIND_NOTION",
  EXTERNAL_SOURCE_KIND_SHAREPOINT = "EXTERNAL_SOURCE_KIND_SHAREPOINT",
  EXTERNAL_SOURCE_KIND_UNSPECIFIED = "EXTERNAL_SOURCE_KIND_UNSPECIFIED"
}

export enum FeedbackAction {
  FEEDBACK_ACTION_ACCEPTED = "FEEDBACK_ACTION_ACCEPTED",
  FEEDBACK_ACTION_DISMISSED = "FEEDBACK_ACTION_DISMISSED",
  FEEDBACK_ACTION_UNSPECIFIED = "FEEDBACK_ACTION_UNSPECIFIED"
}

export enum FieldType {
  ATTACHMENT_FIELD = "ATTACHMENT_FIELD",
  DROPDOWN_FIELD = "DROPDOWN_FIELD",
  TEXT_FIELD = "TEXT_FIELD"
}

export enum Gender {
  GENDER_DIVERSE = "GENDER_DIVERSE",
  GENDER_FEMALE = "GENDER_FEMALE",
  GENDER_MALE = "GENDER_MALE",
  GENDER_UNSPECIFIED = "GENDER_UNSPECIFIED"
}

export enum HRISFilterResult {
  HRIS_FILTER_RESULT_ACCEPTED = "HRIS_FILTER_RESULT_ACCEPTED",
  HRIS_FILTER_RESULT_NO_FILTERS = "HRIS_FILTER_RESULT_NO_FILTERS",
  HRIS_FILTER_RESULT_REJECTED = "HRIS_FILTER_RESULT_REJECTED",
  HRIS_FILTER_RESULT_UNSPECIFIED = "HRIS_FILTER_RESULT_UNSPECIFIED"
}

export enum IntegrationHRISKind {
  BAMBOOHR = "BAMBOOHR",
  HIBOB = "HIBOB",
  PERSONIO = "PERSONIO",
  WORKDAY = "WORKDAY"
}

export enum IntegrationSSOOAuthProvider {
  GOOGLE = "GOOGLE",
  MICROSOFT = "MICROSOFT"
}

export enum IntegrationSSOStatus {
  DISABLED = "DISABLED",
  ENABLED = "ENABLED"
}

export enum IntegrationType {
  INTEGRATION_TYPE_HRIS = "INTEGRATION_TYPE_HRIS",
  INTEGRATION_TYPE_MSTEAMS = "INTEGRATION_TYPE_MSTEAMS",
  INTEGRATION_TYPE_SLACK = "INTEGRATION_TYPE_SLACK",
  INTEGRATION_TYPE_UNSPECIFIED = "INTEGRATION_TYPE_UNSPECIFIED"
}

export enum InteractionType {
  INTERACTION_UNSPECIFIED = "INTERACTION_UNSPECIFIED",
  INTERACTIVE_MESSAGE = "INTERACTIVE_MESSAGE",
  MESSAGE_ACTION = "MESSAGE_ACTION"
}

export enum NotificationChannel {
  CHANNEL_EMAIL = "CHANNEL_EMAIL",
  CHANNEL_MSTEAMS = "CHANNEL_MSTEAMS",
  CHANNEL_SLACK = "CHANNEL_SLACK",
  CHANNEL_UNSPECIFIED = "CHANNEL_UNSPECIFIED"
}

export enum OktaAppAssignmentScope {
  APPLICATION_ASSIGNMENT_SCOPE_GROUP = "APPLICATION_ASSIGNMENT_SCOPE_GROUP",
  APPLICATION_ASSIGNMENT_SCOPE_UNSPECIFIED = "APPLICATION_ASSIGNMENT_SCOPE_UNSPECIFIED",
  APPLICATION_ASSIGNMENT_SCOPE_USER = "APPLICATION_ASSIGNMENT_SCOPE_USER"
}

export enum OrderByKey {
  dueAt = "dueAt",
  isUnread = "isUnread",
  requestedAt = "requestedAt",
  updateTime = "updateTime"
}

export enum OrderDirection {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
  ORDER_DIRECTION_UNSPECIFIED = "ORDER_DIRECTION_UNSPECIFIED"
}

export enum ReplyType {
  BOTH = "BOTH",
  DEFAULT = "DEFAULT",
  PUBLIC = "PUBLIC"
}

export enum RequestChannels {
  CONVERSATIONS = "CONVERSATIONS",
  EMAIL = "EMAIL",
  GCHAT = "GCHAT",
  JIRA = "JIRA",
  MSTEAMS = "MSTEAMS",
  REQUEST_CHANNEL_UNKNOWN = "REQUEST_CHANNEL_UNKNOWN",
  SLACK = "SLACK",
  UNSETREQUESTCHANNELS = "UNSETREQUESTCHANNELS",
  WEB = "WEB",
  WORKFLOWS = "WORKFLOWS"
}

export enum RequestListSortKey {
  create_time = "create_time",
  due_date = "due_date",
  priority = "priority",
  update_time = "update_time"
}

export enum RequestListView {
  ARCHIVE = "ARCHIVE",
  PROJECTS = "PROJECTS",
  REQUESTS = "REQUESTS",
  SEARCH = "SEARCH",
  VIEW = "VIEW"
}

export enum RequestPriority {
  PRIORITY_HIGH = "PRIORITY_HIGH",
  PRIORITY_LOW = "PRIORITY_LOW",
  PRIORITY_MEDIUM = "PRIORITY_MEDIUM",
  PRIORITY_UNSPECIFIED = "PRIORITY_UNSPECIFIED"
}

export enum RequestStatus {
  INPROGRESS = "INPROGRESS",
  NOTSTARTED = "NOTSTARTED",
  RESOLVED = "RESOLVED",
  UNSETREQUESTSTATUS = "UNSETREQUESTSTATUS",
  WAITING = "WAITING"
}

export enum SortDirection {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
  SORT_DIRECTION_UNSPECIFIED = "SORT_DIRECTION_UNSPECIFIED"
}

export enum SubscriptionStatus {
  STATUS_ACTIVE = "STATUS_ACTIVE",
  STATUS_CANCELLED = "STATUS_CANCELLED",
  STATUS_DELETED = "STATUS_DELETED",
  STATUS_IN_TRIAL = "STATUS_IN_TRIAL",
  STATUS_UNSPECIFIED = "STATUS_UNSPECIFIED"
}

export enum TeamCreateInvalid {
  SLUG_TAKEN = "SLUG_TAKEN"
}

export enum TeamPrivacy {
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
  UNSETTEAMPRIVACY = "UNSETTEAMPRIVACY"
}

export enum TeamType {
  TEAM_TYPE_FINANCE = "TEAM_TYPE_FINANCE",
  TEAM_TYPE_HR = "TEAM_TYPE_HR",
  TEAM_TYPE_IT = "TEAM_TYPE_IT",
  TEAM_TYPE_UNSPECIFIED = "TEAM_TYPE_UNSPECIFIED"
}

export enum TeamWorkflowStatus {
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
  INPROGRESS = "INPROGRESS"
}

export enum TextFieldType {
  DATE = "DATE",
  EMAIL = "EMAIL",
  LONG_TEXT = "LONG_TEXT",
  NUMBER = "NUMBER",
  SHORT_TEXT = "SHORT_TEXT",
  URL = "URL"
}

export enum TriggerKey {
  WorkflowTriggerHRISOnboarding = "WorkflowTriggerHRISOnboarding",
  WorkflowTriggerKnowledgeAnswer = "WorkflowTriggerKnowledgeAnswer",
  WorkflowTriggerManual = "WorkflowTriggerManual",
  WorkflowTriggerRecurring = "WorkflowTriggerRecurring",
  WorkflowTriggerRequestCategoryChanged = "WorkflowTriggerRequestCategoryChanged",
  WorkflowTriggerRequestCommentAdded = "WorkflowTriggerRequestCommentAdded",
  WorkflowTriggerRequestCreated = "WorkflowTriggerRequestCreated",
  WorkflowTriggerRequestStatusChanged = "WorkflowTriggerRequestStatusChanged",
  WorkflowTriggerRequestTeamChanged = "WorkflowTriggerRequestTeamChanged"
}

export enum UserMatcherUserRole {
  ROLE_BACK_USER = "ROLE_BACK_USER",
  ROLE_CURRENT_USER = "ROLE_CURRENT_USER",
  ROLE_UNSPECIFIED = "ROLE_UNSPECIFIED"
}

export enum UserTypeSelection {
  USER_TYPE_SELECTION_EXPERTS = "USER_TYPE_SELECTION_EXPERTS",
  USER_TYPE_SELECTION_INTERNAL_AND_EXTERNAL_USERS = "USER_TYPE_SELECTION_INTERNAL_AND_EXTERNAL_USERS",
  USER_TYPE_SELECTION_INTERNAL_USERS = "USER_TYPE_SELECTION_INTERNAL_USERS",
  USER_TYPE_SELECTION_UNSPECIFIED = "USER_TYPE_SELECTION_UNSPECIFIED"
}

export enum ViewType {
  CLASSIC = "CLASSIC",
  CONDENSED = "CONDENSED",
  KANBAN = "KANBAN",
  VIEW_TYPE_UNSPECIFIED = "VIEW_TYPE_UNSPECIFIED"
}

export enum WorkflowParameterType {
  DATE = "DATE",
  DROPDOWN = "DROPDOWN",
  EMAIL = "EMAIL",
  NUMBER = "NUMBER",
  STRING = "STRING"
}

export enum WorkflowPublishedVariableType {
  TYPE_BOOLEAN = "TYPE_BOOLEAN",
  TYPE_CONVERSATION_ID = "TYPE_CONVERSATION_ID",
  TYPE_DATE = "TYPE_DATE",
  TYPE_EMAIL = "TYPE_EMAIL",
  TYPE_NUMBER = "TYPE_NUMBER",
  TYPE_PROJECT_ID = "TYPE_PROJECT_ID",
  TYPE_REQUEST_ID = "TYPE_REQUEST_ID",
  TYPE_STRING = "TYPE_STRING",
  TYPE_TEAM_ID = "TYPE_TEAM_ID",
  TYPE_UNSPECIFIED = "TYPE_UNSPECIFIED",
  TYPE_USER_ID = "TYPE_USER_ID"
}

export enum WorkflowRunState {
  RUN_STATE_FAILED = "RUN_STATE_FAILED",
  RUN_STATE_PENDING = "RUN_STATE_PENDING",
  RUN_STATE_RUNNING = "RUN_STATE_RUNNING",
  RUN_STATE_SUCCEEDED = "RUN_STATE_SUCCEEDED",
  RUN_STATE_UNSPECIFIED = "RUN_STATE_UNSPECIFIED"
}

export interface ActionInput {
  key: ActionKey;
  map: ActionInputMap;
}

export interface ActionInputMap {
  ApprovalRequest?: ApprovalRequestInput | null;
  CreateConversation?: CreateConversationInput | null;
  CreateConversationRequest?: CreateConversationRequestInput | null;
  CreateNote?: CreateNoteInput | null;
  CreateProject?: CreateProjectInput | null;
  CreateReply?: CreateReplyInput | null;
  CreateRequest?: CreateRequestInput | null;
  CreateTask?: CreateTaskInput | null;
  FetchUserProfile?: FetchUserProfileInput | null;
  JumpIf?: JumpIfInput | null;
  Launch?: LaunchInput | null;
  LinkJiraRequest?: LinkJiraRequestInput | null;
  MapValue?: MapValueInput | null;
  SendForm?: SendFormInput | null;
  SetRequestAssignee?: SetRequestAssigneeInput | null;
  SetRequestCategory?: SetRequestCategoryInput | null;
  SetRequestDueDate?: SetRequestDueDateInput | null;
  SetRequestPriority?: SetRequestPriorityInput | null;
  SetRequestProject?: SetRequestProjectInput | null;
  SetRequestRequester?: SetRequestRequesterInput | null;
  SetRequestStatus?: SetRequestStatusInput | null;
  SetRequestTeam?: SetRequestTeamInput | null;
  SetRequestTitle?: SetRequestTitleInput | null;
}

export interface ApprovalRequestInput {
  requestId: string;
  approver: UserMatcherInput;
  text: string;
  onApproved: ScalarSubWorkflow;
  onRejected: ScalarSubWorkflow;
}

export interface AttachmentParameters {
  displayName: string;
  size: number;
  authToken?: string | null;
}

export interface CategoryMatcherInput {
  name?: string | null;
  categoryId?: string | null;
}

export interface ConversationFilter {
  fts?: ConversationFilterFTS | null;
}

export interface ConversationFilterFTS {
  value: string;
}

export interface ConversationInput {
  authorId: string;
  teamId: string;
  subject: string;
  body: string;
  toEmailAddresses: string[];
  ccEmailAddresses?: string[] | null;
  attachmentIds?: string[] | null;
}

export interface ConversationListQuery {
  pageNumber: number;
  pageSize: number;
  filters?: ConversationFilter | null;
}

export interface CreateConversationInput {
  subject: string;
  body?: string | null;
  toList: UserMatcherInput[];
  cc?: UserMatcherInput[] | null;
  attachmentIds?: string[] | null;
  alias?: ScalarAlias | null;
}

export interface CreateConversationRequestInput {
  conversationId: string;
  requester: UserMatcherInput;
  alias?: ScalarAlias | null;
}

export interface CreateIntegrationGChat {
  associationState: string;
}

export interface CreateIntegrationMSTeams {
  associationState: string;
}

export interface CreateIntegrationSSO {
  organizationDomain?: string | null;
  oauthProvider?: IntegrationSSOOAuthProvider | null;
}

export interface CreateNoteInput {
  requestId: string;
  textRequired: string;
  externalMentions?: WorkflowExternalMentionInput | null;
  mentions?: UserMatcherInput[] | null;
}

export interface CreateProjectInput {
  title: string;
  team?: TeamMatcherInput | null;
  alias?: ScalarAlias | null;
}

export interface CreateReplyInput {
  requestId: string;
  textRequired: string;
  documents?: DocumentMatcherInput[] | null;
}

export interface CreateRequestInput {
  title: string;
  description?: string | null;
  projectId?: string | null;
  requester: UserMatcherInput;
  team?: TeamMatcherInput | null;
  alias?: ScalarAlias | null;
}

export interface CreateTaskInput {
  requestId: string;
  instruction: string;
  action?: CreateTaskInputButton | null;
  icon?: string | null;
  onCompleted: ScalarSubWorkflow;
}

export interface CreateTaskInputButton {
  url: string;
  label: string;
}

export interface DateMatcherInput {
  date: string;
}

export interface DocumentMatcherInput {
  docPk?: string | null;
  title?: string | null;
}

export interface DocumentParam {
  id: string;
  revision: number;
  source?: DocumentSource | null;
}

export interface DocumentSetTargetingParams {
  documentId: string;
  documentSource: DocumentSource;
  targeting: Targeting;
}

export interface DocumentSetWorkflowParams {
  documentId: string;
  documentSource: DocumentSource;
  workflowId?: string | null;
}

export interface ExternalDocumentSourceCreateParameters {
  confluence?: ExternalDocumentSourceCreateParametersConfluence | null;
  notion?: ExternalDocumentSourceCreateParametersNotion | null;
  sharepoint?: ExternalDocumentSourceCreateParametersSharepoint | null;
}

export interface ExternalDocumentSourceCreateParametersConfluence {
  endpoint: string;
  username: string;
  apiKey: string;
}

export interface ExternalDocumentSourceCreateParametersNotion {
  empty?: number | null;
}

export interface ExternalDocumentSourceCreateParametersSharepoint {
  siteName: string;
}

export interface ExternalDocumentSourceSetSynchronizedDocumentsParameters {
  sourceId: string;
  synchronizedDocuments: ExternalDocumentSourceSynchronizedDocumentIDEntry[];
}

export interface ExternalDocumentSourceSynchronizedDocumentIDEntry {
  documentId: string;
  syncType: ExternalDocumentSourceDocumentSyncType;
}

export interface FetchUserProfileInput {
  user: UserMatcherInput;
  alias?: ScalarAlias | null;
}

export interface FieldParams {
  fieldType: FieldType;
  textFieldType?: TextFieldType | null;
  displayName: string;
  optionsList?: string[] | null;
  required: boolean;
}

export interface FirstTeam {
  name: string;
  slug: string;
  categories: InCategory[];
  firstUserEmail: string;
}

export interface FormDataParams {
  id: string;
  values: ResponseValueParams[];
}

export interface FormMatcherInput {
  formId?: string | null;
  name?: string | null;
}

export interface InCategory {
  id?: string | null;
  name: string;
}

export interface InCustomStatus {
  teamId: string;
  step: RequestStatus;
  name: string;
  color: string;
}

export interface InDocument {
  title: string;
  content: string;
  attachmentIds?: string[] | null;
  commentId?: string | null;
  workflowId?: string | null;
}

export interface InSLA {
  id?: string | null;
  teamId: string;
  name: string;
  categoryIds?: string[] | null;
  minutesToFirstResponse?: number | null;
  minutesToResolution?: number | null;
}

export interface InSavedReply {
  teamId: string;
  title: string;
  body: string;
  attachmentIds?: string[] | null;
}

export interface InSavedView {
  id?: string | null;
  name: string;
  filters: string;
  orderBy: OrderByKey;
  orderDirection: OrderDirection;
  orderUnreadFirst: boolean;
  viewType: ViewType;
}

export interface InTeam {
  name: string;
  slug: string;
  categoryIds?: (string | null)[] | null;
  privacy?: TeamPrivacy | null;
  categories?: InCategory[] | null;
  internal: boolean;
}

export interface InWorkingHours {
  dayOfWeek: DayOfWeek;
  startHour: number;
  startMinute: number;
  endHour: number;
  endMinute: number;
}

export interface IntegrationAddOktaParams {
  domain: string;
  apiToken: string;
  teamIds: string[];
}

export interface IntegrationHRISConfig {
  hibob?: IntegrationHRISConfigHiBob | null;
  personio?: IntegrationHRISConfigPersonio | null;
  bamboohr?: IntegrationHRISConfigBambooHR | null;
  workday?: IntegrationHRISConfigWorkday | null;
}

export interface IntegrationHRISConfigBambooHR {
  subdomain: string;
  apiKey?: string | null;
}

export interface IntegrationHRISConfigHiBob {
  token: string;
}

export interface IntegrationHRISConfigPersonio {
  credentials: string;
}

export interface IntegrationHRISConfigWorkday {
  token?: string | null;
}

export interface JumpIfInput {
  streq?: TwoOperandOpInput | null;
  numeq?: TwoOperandOpInput | null;
  gte?: TwoOperandOpInput | null;
  gt?: TwoOperandOpInput | null;
  lte?: TwoOperandOpInput | null;
  lt?: TwoOperandOpInput | null;
  onTrue: ScalarSubWorkflow;
  onFalse: ScalarSubWorkflow;
}

export interface LaunchFlowInput {
  subworkflowName: ScalarSubWorkflow;
  at?: DateMatcherInput | null;
}

export interface LaunchInput {
  flowsList: LaunchFlowInput[];
}

export interface LinkJiraRequestInput {
  requestId: string;
  aliasIssue?: ScalarAlias | null;
}

export interface MSTeamsDeleteTeamAssociation {
  teamId?: string | null;
  disassociationState?: string | null;
}

export interface ManualTaskCreateParameters {
  requestId: string;
  instruction: string;
  actionLabel?: string | null;
  actionUrl?: string | null;
  icon?: string | null;
  completionLabel?: string | null;
}

export interface MapValueInput {
  in: string;
  values: MapValueInputValue[];
  default?: string | null;
  out: string;
}

export interface MapValueInputValue {
  key: string;
  value: string;
}

export interface NotificationSettingsUpdate {
  notifyRequestAssignedTeam: boolean;
  notifyRequestAssignedUser: boolean;
  notifyRequestUpdated: boolean;
  notifyUserMentioned: boolean;
  notifyUserApprovalRequested: boolean;
  emailDigest: boolean;
  preferredChannel: NotificationChannel;
}

export interface OrgRegisterParams {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  organizationName: string;
  organizationSlug: string;
  organizationId?: string | null;
  toc: boolean;
}

export interface Period {
  startTime: ScalarDateTime;
  endTime: ScalarDateTime;
}

export interface PriorityMatcherInput {
  name?: string | null;
  priority: RequestPriority;
}

export interface ProjectParameters {
  teamId: string;
  title: string;
}

export interface PushSubscription {
  endpoint: string;
  expirationTime?: ScalarLong | null;
  p256dh: string;
  auth: string;
}

export interface RequestAddApprovalParameters {
  requestId: string;
  approverId?: string | null;
  approverEmail: string;
  text: string;
  mentionUserIds?: string[] | null;
}

export interface RequestCommentParameters {
  requestId: string;
  text: string;
  attachmentIds?: string[] | null;
  documents?: DocumentParam[] | null;
  mentionUserIds?: string[] | null;
  externals?: string[] | null;
  externalsIncludePreviousComments?: boolean | null;
  replyType?: ReplyType | null;
  formId?: string | null;
  channel?: RequestChannels | null;
}

export interface RequestListQuery {
  filters?: string | null;
  sort?: SortOptions | null;
  pageNumber?: number | null;
  pageSize?: number | null;
}

export interface RequestNoteParameters {
  requestId: string;
  text: string;
  attachmentIds?: string[] | null;
  mentionUserIds?: string[] | null;
  externals?: string[] | null;
  externalsIncludePreviousComments?: boolean | null;
}

export interface RequestParameters {
  teamId: string;
  title: string;
  description: string;
  requesterId: string;
  projectId?: string | null;
}

export interface RequestResolveApprovalParameters {
  approvalId: string;
  status: ApprovalStatus;
  comment?: string | null;
}

export interface RequestTriggerBaseInput {
  actor?: UserMatcherInput | null;
  status?: StatusMatcherInput | null;
  team?: TeamMatcherInput | null;
  category?: CategoryMatcherInput | null;
  titleContains?: StringMatcherInput | null;
  descriptionContains?: StringMatcherInput | null;
}

export interface ResponseValueParams {
  fieldType: FieldType;
  value?: string | null;
  selected?: number | null;
  attachmentId?: string | null;
}

export interface SchemaParams {
  displayName: string;
  description: string;
  fieldsList: FieldParams[];
}

export interface SendFormInput {
  requestId: string;
  form: FormMatcherInput;
  onReplied: ScalarSubWorkflow;
  aliasReplies?: ScalarAlias | null;
}

export interface SetRequestAssigneeInput {
  requestId: string;
  assignee: UserMatcherInput;
}

export interface SetRequestCategoryInput {
  requestId: string;
  category: CategoryMatcherInput;
}

export interface SetRequestDueDateInput {
  requestId: string;
  dueDate: DateMatcherInput;
}

export interface SetRequestPriorityInput {
  requestId: string;
  priority: PriorityMatcherInput;
}

export interface SetRequestProjectInput {
  requestId: string;
  projectIdRequired: string;
}

export interface SetRequestRequesterInput {
  requestId: string;
  requester: UserMatcherInput;
}

export interface SetRequestStatusInput {
  requestId: string;
  status: StatusMatcherInput;
}

export interface SetRequestTeamInput {
  requestId: string;
  teamRequired: TeamMatcherInput;
}

export interface SetRequestTitleInput {
  requestId: string;
  title: string;
}

export interface SortOptions {
  sortByField: RequestListSortKey;
  direction: SortDirection;
  unreadFirst: boolean;
}

export interface StatusMatcherInput {
  name?: string | null;
  statusId?: string | null;
}

export interface StringMatcherInput {
  anyList: string[];
}

export interface Targeting {
  officesList: string[];
  departmentsList: string[];
  isManager: boolean;
  allowExternals: boolean;
}

export interface TeamAnalyticsFilters {
  assignees?: string[] | null;
  categories?: string[] | null;
}

export interface TeamMatcherInput {
  slug?: string | null;
  teamId?: string | null;
}

export interface TeamUsersAdd {
  teamId: string;
  emails: (string | null)[];
}

export interface TeamUsersRemove {
  teamId: string;
  userIds: string[];
}

export interface TriggerInput {
  key: TriggerKey;
  map: TriggerInputMap;
  publishedVariables: WorkflowPublishedVariableInput[];
}

export interface TriggerInputMap {
  WorkflowTriggerRecurring?: WorkflowTriggerRecurringInput | null;
  WorkflowTriggerRequestCreated?: WorkflowTriggerRequestCreatedInput | null;
  WorkflowTriggerRequestStatusChanged?: WorkflowTriggerRequestStatusChangedInput | null;
  WorkflowTriggerRequestCategoryChanged?: WorkflowTriggerRequestCategoryChangedInput | null;
  WorkflowTriggerRequestCommentAdded?: WorkflowTriggerRequestCommentAddedInput | null;
  WorkflowTriggerRequestTeamChanged?: WorkflowTriggerRequestTeamChangedInput | null;
  WorkflowTriggerHRISOnboarding?: WorkflowTriggerHRISOnboardingInput | null;
  WorkflowTriggerManual?: WorkflowTriggerManualInput | null;
  WorkflowTriggerKnowledgeAnswer?: WorkflowTriggerKnowledgeAnswerInput | null;
}

export interface TwoOperandOpInput {
  a: string;
  b: string;
}

export interface UpCustomStatus {
  id: string;
  teamId?: string | null;
  name: string;
  color: string;
}

export interface UpDocument {
  id: string;
  title: string;
  content: string;
  revision: number;
  attachmentIds?: string[] | null;
  workflowId?: string | null;
}

export interface UpSavedReply {
  id: string;
  title: string;
  body: string;
  attachmentIds?: string[] | null;
}

export interface UpTeam {
  id: string;
  name?: string | null;
  categoryIds?: (string | null)[] | null;
  categories?: InCategory[] | null;
  slackChannelIds?: (string | null)[] | null;
  workingHours?: InWorkingHours[] | null;
  internal: boolean;
}

export interface UpUser {
  id: string;
  name: string;
  email: string;
}

export interface UserFinishPasswordRecoveryParams {
  token: string;
  password: string;
  confirmPassword: string;
}

export interface UserListQuery {
  filterNameOrEmail?: string | null;
  teams?: string[] | null;
  expertsOnly?: boolean | null;
  externalsOnly?: boolean | null;
}

export interface UserLoginParams {
  email: string;
  password: string;
}

export interface UserMatcherInput {
  email?: string | null;
  userId?: string | null;
  role?: UserMatcherUserRole | null;
  userTypeSelection: UserTypeSelection;
}

export interface UserRegisterParams {
  invitationToken: string;
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export interface UserStartPasswordRecoveryParams {
  email: string;
}

export interface UserUnregisterParams {
  userId: string;
}

export interface UserUpdatePasswordParams {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export interface WorkflowExternalMentionInput {
  emails: string[];
  includeHistory?: boolean | null;
}

export interface WorkflowParameterInput {
  name: string;
  description: string;
  type: WorkflowParameterType;
  options?: string[] | null;
}

export interface WorkflowPublishedVariableInput {
  name: string;
  description: string;
  type: WorkflowPublishedVariableType;
  editable: boolean;
  value: string;
}

export interface WorkflowSaveParams {
  teamId: string;
  workflowId: string;
  name: string;
  icon: string;
  description: string;
  triggers: TriggerInput[];
  steps: WorkflowStepMapInput[];
}

export interface WorkflowStepMapInput {
  id: string;
  actions: ActionInput[];
}

export interface WorkflowTriggerHRISOnboardingInput {
  _?: boolean | null;
}

export interface WorkflowTriggerKnowledgeAnswerInput {
  _?: boolean | null;
}

export interface WorkflowTriggerManualInput {
  parameters?: WorkflowParameterInput[] | null;
}

export interface WorkflowTriggerRecurringInput {
  cron: string;
}

export interface WorkflowTriggerRequestCategoryChangedInput {
  requestTriggerBase: RequestTriggerBaseInput;
}

export interface WorkflowTriggerRequestCommentAddedInput {
  requestTriggerBase: RequestTriggerBaseInput;
}

export interface WorkflowTriggerRequestCreatedInput {
  requestTriggerBase: RequestTriggerBaseInput;
}

export interface WorkflowTriggerRequestStatusChangedInput {
  requestTriggerBase: RequestTriggerBaseInput;
}

export interface WorkflowTriggerRequestTeamChangedInput {
  requestTriggerBase: RequestTriggerBaseInput;
}

export interface WorkflowVariableInput {
  name: string;
  value: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
