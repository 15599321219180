import { createChangeEmitter } from "change-emitter";

export type TSnack =
  | {
      type: "error" | "info" | "mutationError";
      message: string;
      deduplicateKey?: string;
    }
  | {
      type: "network";
      offline: boolean;
    };

export const apolloEmitter = createChangeEmitter<TSnack>();
