import * as React from "react";
import { ConfirmDialogModal } from "src/components";

export const AttachmentDeleteConfirm: React.FC<{
  deleting: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}> = props => {
  return (
    <ConfirmDialogModal
      text={{
        cancel: "No thanks, cancel",
        confirm: "Yes, delete it!",
        heading: "Deletion warning"
      }}
      isOpen={true}
      loading={props.deleting}
      handleCancel={props.onCancel}
      handleConfirm={props.onConfirm}
    >
      <p>Are you sure you want to delete this attachment?</p>
    </ConfirmDialogModal>
  );
};
