import { css } from "@emotion/core";
import gql from "graphql-tag";
import React from "react";
import { useQuery } from "react-apollo";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { RequestListGet_currentUser } from "src/App/Requests/ListView/typings/RequestListGet";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { SimpleTooltip } from "src/components";
import { USER_INFO_FRAGMENT } from "src/fragments/UserInfoFragment";
import zIndices from "src/styling/tokens/z-indices.json";
import { authTokenContext } from "src/util";
import { csx } from "src/util/csx";
import { UserComponentFields } from ".";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { GUserGet, GUserGetVariables } from "./typings/GUserGet";

const UserNameWrapper = csx(
  [],
  {
    bold: css`
      font-weight: var(--font-weight-body-medium);
    `
  },
  "span"
);

interface IUserName {
  userId?: string | null;
  /**
   * dhl auth token
   * @deprecated
   */
  authToken?: string;
  you?: string | false;
  bold?: boolean;
  tooltip?: boolean;
  copyToClipboard?: boolean;
  user?: UserComponentFields;
  currentUser?: RequestListGet_currentUser;
}

const UserNameComponent: React.FunctionComponent<IUserName> = props => {
  const { emitSnack } = useSnack();

  /* Display a user name or "you", if current user */
  const { currentUser } = useCurrentUser();
  if (!props.user)
    return (
      <span
        css={css`
          width: var(--space-4-rem);
        `}
      >
        &nbsp;
      </span>
    );
  const isCurrentUser = props.user?.id === currentUser?.id;
  const nameOrEmail = props.user?.name || props.user?.email || "";
  const email = props.user?.email || "";
  const userName = props.copyToClipboard ? (
    <CopyToClipboard
      text={email}
      onCopy={() => {
        emitSnack({
          message: `Email ${email} copied to the clipboard`,
          type: "info"
        });
      }}
    >
      <UserNameWrapper bold={props.bold}>{(isCurrentUser && (props.you ?? "You")) || nameOrEmail}</UserNameWrapper>
    </CopyToClipboard>
  ) : (
    <UserNameWrapper bold={props.bold}>{(isCurrentUser && (props.you ?? "You")) || nameOrEmail}</UserNameWrapper>
  );

  return (
    (props.tooltip && (
      <SimpleTooltip
        label={props.copyToClipboard ? `${email}\nClick to copy to clipboard` : email}
        cursor="default"
        alignment="center"
        placement="top"
        children={userName}
        zIndex={zIndices.higher.value}
      />
    )) ||
    userName
  );
};

/**
 * Display a user name or "you", if current user
 */
export const UserName: React.FC<IUserName> = props => {
  const userResponse = useQuery<GUserGet, GUserGetVariables>(
    gql`
      query UserInfo($id: ID!) {
        user(id: $id) {
          ...UserInfo
        }
      }
      ${USER_INFO_FRAGMENT}
    `,
    {
      variables: {
        id: props.userId ?? ""
      },
      skip: !!props.user || !props.userId,
      context: authTokenContext(props.authToken)
    }
  );
  if (!props.userId && !props.user) return null;
  else return <UserNameComponent {...props} user={props.user ?? userResponse.data?.user} />;
};
