import { useEffect, useState } from "react";
import { CURRENT_USER } from "src/App/Root/providers/CurrentUserProvider";
import { CurrentUser } from "src/App/Root/providers/typings/CurrentUser";
import { apolloClient } from "src/util/apollo/client";

/**
 * Custom hook exposing the current login status.
 *
 * FIXME: doesn't update on logout
 *
 * @returns A state that is true if a user is currently logged in and false otherwise
 */
export function useLoginStatus(initialValue = false) {
  const [isLoggedIn, setIsLoggedIn] = useState(initialValue);

  useEffect(() => {
    // Use an IIFE because the function passed to useEffect() can't be async itself.
    (async () => {
      try {
        const query = await apolloClient.query<CurrentUser>({
          query: CURRENT_USER,
          fetchPolicy: "network-only"
        });
        setIsLoggedIn(!!query.data.currentUser);
      } catch {
        setIsLoggedIn(false);
      }
    })();
  }, []); // Passing in an empty array causes the effect to only be run on mount.

  return isLoggedIn;
}
