import gql from "graphql-tag";
import { GDocumentsListGet_documentList } from "src/App/KB/typings/GDocumentsListGet";
import { routeData } from "src/App/Root/RouteData";
import { ExternalDocumentSourceKind } from "src/globalTypes";

export const DOCUMENT_FRAGMENT = gql`
  fragment DocumentFragment on Document {
    pk
    id
    revision
    title
    content
    createTime
    updateTime
    source
    url
    workflowId
    attachments {
      id
      displayName
      size
      deleteTime
    }
    external {
      source {
        id
        kind
      }
      pk
      id
    }
  }
`;

export const DOCUMENTS_LIST_GET = gql`
  query GDocumentsListGet {
    documentList {
      ...DocumentFragment
      hrisFilter {
        id
        createTime
        updateTime
        officesList
        departmentsList
        isManager
        allowExternals
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export function getSourceName(sourceKind: ExternalDocumentSourceKind): string {
  switch (sourceKind) {
    case ExternalDocumentSourceKind.EXTERNAL_SOURCE_KIND_CONFLUENCE:
      return "Confluence";
    case ExternalDocumentSourceKind.EXTERNAL_SOURCE_KIND_NOTION:
      return "Notion";
    case ExternalDocumentSourceKind.EXTERNAL_SOURCE_KIND_SHAREPOINT:
      return "Sharepoint";
    case ExternalDocumentSourceKind.EXTERNAL_SOURCE_KIND_UNSPECIFIED:
      return "";
  }
}

export const documentsOverviewRoute = `/${routeData.knowledge.pathname}${window.location.search}`;

export const documentPreviewRoute = (id: GDocumentsListGet_documentList["id"]) =>
  `/${routeData.knowledge.pathname}/${encodeURIComponent(id)}/preview${window.location.search}`;

export const documentDeleteRoute = (id: GDocumentsListGet_documentList["id"]) =>
  `/${routeData.knowledge.pathname}/${encodeURIComponent(id)}/delete`;

export const documentCreateRoute = `/${routeData.knowledge.pathname}/create`;

export const documentEditRoute = (id: GDocumentsListGet_documentList["id"]) =>
  `/${routeData.knowledge.pathname}/${encodeURIComponent(id)}/edit${window.location.search}`;
