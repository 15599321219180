import { addMilliseconds, addMinutes, format, formatISO } from "date-fns";
// Analytics specific date formatters

/* format date in local tz */
export const formatDate = (date: string | Date) => {
  return format(new Date(date), "MMM d");
};

/* format date in UTC time */
export const formatDateInUTC = (date: string | Date, offsetMilli = 0) => {
  date = new Date(date);
  return format(addMilliseconds(addMinutes(date, date.getTimezoneOffset()), offsetMilli), "MMM d");
};

/* format date in UTC time */
export const formatDateStringInUTC = (date: string | Date, offsetMilli = 0) => {
  date = new Date(date);
  return formatISO(addMilliseconds(addMinutes(date, date.getTimezoneOffset()), offsetMilli), {
    representation: "date"
  });
};
