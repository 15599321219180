import { css } from "@emotion/core";
import { PureQueryOptions } from "apollo-client";
import * as React from "react";
import { Dialog } from "src/components";
import { Modal } from "src/portals/Modal";
import { paddings } from "src/styling/layout";
import { body } from "src/styling/primitives";
import { LocationContext, navigate } from "src/util/router";
import { RequestForm } from "./Component";

export const RequestCreateModal: React.FC<{
  onCancel(): void;
  onCreate(): void;
  projectInfo?: { id: string; title: string };
  queriesToRefetch?: Array<string | PureQueryOptions>;
}> = props => {
  return (
    <Modal isOpen={true} onDismiss={props.onCancel}>
      <Dialog
        title="Create new request"
        onClose={props.onCancel}
        css={css`
          width: ${47.5 - paddings.dialog.left - paddings.dialog.right}rem;
        `}
      >
        <div
          css={[
            css`
              margin-bottom: 2rem;
            `
          ]}
        >
          {props.projectInfo && (
            <div
              css={[
                css`
                  margin-top: 0.5rem;
                `
              ]}
            >
              You are creating a request that’s associated with the project{" "}
              <span css={[body.italic]}>{props.projectInfo.title}</span>
            </div>
          )}
        </div>
        <RequestForm
          projectId={props.projectInfo?.id}
          onCreate={props.onCreate}
          onCancel={props.onCancel}
          queriesToRefetch={props.queriesToRefetch}
        />
      </Dialog>
    </Modal>
  );
};

/**
 * Hide the new request modal dialog (if it's open) by navigating to the current URL, but
 * without the URL hash that triggers the display of the modal.
 *
 * @param context The router's current LocationContext object
 */
function hideModal(context: LocationContext) {
  // str.split("#")[0] will also work, if str doesn't contain any # characters. In this case,
  // it will simply return str.
  context.navigate(context.location.href.split("#")[0].replace(/\/$/, ""));
}

export const RequestCreateDialog = (props: { context: LocationContext }) => {
  return <RequestCreateModal onCancel={() => hideModal(props.context)} onCreate={() => navigate("/view/all")} />;
};
