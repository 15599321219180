import { useEffect, useRef } from "react";

/**
 * Run @param callback on click outside of container
 */
export function useClickaway<E extends HTMLElement>(callback: () => void) {
  const container = useRef<E>(null);
  useEffect(
    () => {
      const onClickaway = (e: MouseEvent) => {
        if (container.current && !container.current.contains(e.target as HTMLElement)) {
          callback();
        }
      };
      document.body.addEventListener("click", onClickaway);
      return () => document.body.removeEventListener("click", onClickaway);
    },
    [callback, container]
  );
  return [container];
}
