import { css } from "@emotion/core";
import { mdiDotsHorizontal } from "@mdi/js";
import { PureQueryOptions } from "apollo-client";
import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { AssignExpertButton } from "src/App/Requests/Actions/AssignExpertButton";
import { ChangeDueDatePicker } from "src/App/Requests/Actions/ChangeDueDatePicker";
import { ChangeProjectButton } from "src/App/Requests/Actions/ChangeProjectButton";
import { ActionsContext } from "src/App/Requests/Actions/Provider";
import { priorityLabels, RequestPriorityButton } from "src/App/Requests/Actions/RequestPriorityButton";
import { ActionEnum, actionMenuLabels, notificationMessage, requestDetailPath } from "src/App/Requests/ListView";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { UserName } from "src/App/User";
import { AbsoluteTooltip, Col, Hide, LegacyPill, Row, Tooltip } from "src/components";
import { Badge } from "src/components/Badges";
import { Pill } from "src/components/PillButton";
import { OptionMap, PopOver } from "src/components/PopOver";
import { ColorVariantsUnion, Status } from "src/components/StatusIndicator";
import { RequestPriority, RequestStatus, TeamPrivacy } from "src/globalTypes";
import { Toast } from "src/portals/Toast";
import { dimensions } from "src/styling/layout";
import { Card } from "src/styling/primitives/Card";
import { Typo } from "src/styling/primitives/typography";
import { fontWeights } from "src/styling/typography";
import { csx } from "src/util/csx";
import { formatDueDate, formatTime, formatTitle } from "src/util/formatters";
import { Link } from "src/util/router";
import { useRequestList } from "./Provider";
import { SlaStatusBadge } from "./SlaStatusBadge";
import { GRequestListUpdated_requestListUpdated } from "./typings/GRequestListUpdated";
import { RequestListFragment } from "./typings/RequestListFragment";

export interface RequestListItemProps {
  request: RequestListFragment;
  requestUpdates?: GRequestListUpdated_requestListUpdated;
  style?: React.CSSProperties;
  queriesToRefetch?: Array<string | PureQueryOptions>;
  updateVisiblePills(requestId: string, pillId: string): void;
}

export const RequestRowWrapper = csx(
  [
    css`
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      padding: 0 var(--space-4-rem);
    `
  ],
  {}
);

const requestStyles = css`
  & .info {
    flex: 1 1 auto;
    margin-right: 1rem;
    overflow: hidden;

    & .title {
      display: inline;
      font-size: var(--font-size-display-small);
      line-height: var(--line-height-dense);
      font-weight: ${fontWeights.regular};
      font-family: var(--font-family-body);
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & .subtitle {
      display: flex;
      align-items: center;
      color: var(--text-4);
      line-height: 1.5;
    }

    & .notification {
      font-weight: bold;
    }
  }

  & .due {
    padding: 0 2.5rem;
  }
`;

const requestPrivate = css`
  cursor: not-allowed;

  & * {
    pointer-events: none;
  }

  & .all-pointer-events,
  & .all-pointer-events * {
    pointer-events: all;
    z-index: var(--z-higher);
  }
`;

/**
 * Component for showing an individual request
 */
export const RequestCard = memo((props: RequestListItemProps) => {
  const { currentUser } = useCurrentUser();
  const [requestList] = useRequestList();
  const { handlers, options, optionsByTeam } = useContext(ActionsContext);

  const { request, requestUpdates, updateVisiblePills } = props;

  const pillRefs = {
    [ActionEnum.ASSIGNEE]: useRef<HTMLButtonElement>(null),
    [ActionEnum.CATEGORY]: useRef<HTMLButtonElement>(null),
    [ActionEnum.DUEDATE]: useRef<HTMLButtonElement>(null),
    [ActionEnum.PROJECT]: useRef<HTMLButtonElement>(null),
    [ActionEnum.PRIORITY]: useRef<HTMLButtonElement>(null)
  };
  const [lastVisiblePills, setLastVisiblePills] = useState<{ [pillId: string]: boolean }>({});
  const visiblePills = requestList.state.visiblePills[request.id];
  useEffect(() => {
    // diff list of visible pills and trigger a click if needed
    const visiblePillIds = visiblePills ? Object.keys(visiblePills).filter(pillId => visiblePills[pillId]) : [];
    const lastVisiblePillIds =
      lastVisiblePills && Object.keys(lastVisiblePills).filter(pillId => lastVisiblePills[pillId]);
    if (visiblePills && Object.keys(visiblePills).length && visiblePillIds.join("-") !== lastVisiblePillIds.join("-")) {
      // quick check if the selection changed
      const pillToTrigger =
        visiblePillIds &&
        !!visiblePillIds.length &&
        (visiblePillIds.find(pill => lastVisiblePillIds && !lastVisiblePillIds.includes(pill)) as ActionEnum);

      if (pillToTrigger) pillRefs[pillToTrigger].current?.click();

      setLastVisiblePills(visiblePills);
    }
  }, [visiblePills, lastVisiblePills, setLastVisiblePills, pillRefs]);

  const removePillFromVisiblePills = (pillId: ActionEnum) => {
    visiblePills && !!visiblePills[pillId] && updateVisiblePills && updateVisiblePills(request.id, pillId);
  };

  const requestTeam = requestList.response.data?.teamList.find(t => t.id === request.team.id);
  if (!requestTeam) {
    return <Toast kind="mutationError" message="Team is not defined" />;
  }
  const noAccess = requestTeam.privacy === TeamPrivacy.PRIVATE && !requestTeam.userIds.includes(currentUser?.id ?? "");

  const formattedDueDate = request.dueAt ? formatDueDate(request.dueAt) : "";
  const isUrgent =
    (formattedDueDate === "today" || formattedDueDate.includes("ago") || formattedDueDate.includes("yesterday")) &&
    request &&
    request.customStatus.step !== RequestStatus.RESOLVED;

  const hideCategoryPill =
    !(visiblePills && visiblePills[ActionEnum.CATEGORY]) && !(request.category && request.category.id);
  const hideDueDatePill = !(visiblePills && visiblePills[ActionEnum.DUEDATE]) && !request.dueAt;
  const hideProjectPill = !(visiblePills && visiblePills[ActionEnum.PROJECT]) && !request.project;
  const hidePriorityPill =
    !(visiblePills && visiblePills[ActionEnum.PRIORITY]) && request.priority === RequestPriority.PRIORITY_UNSPECIFIED;

  const showActions = !noAccess && (hideCategoryPill || hideDueDatePill || hideProjectPill || hidePriorityPill);

  const assignedToMe = !!(currentUser && request.assignee && currentUser.id === request.assignee.id);
  const unassigned = props.request.assignee === null;
  const unreadMessage = requestUpdates ? notificationMessage({ requestUpdates, assignedToMe, unassigned }) : null;

  const requestCurrentCustomStatus = props.request.customStatus;

  return (
    <RequestRowWrapper
      style={props.style}
      css={css`
        &:first-child {
          padding-top: ${requestList.filterBarHeight}px;
        }
      `}
    >
      <div
        css={
          // center and constrain the request card dimensions
          css`
            /* for e.g. no access overlay */
            position: relative;
            display: flex;
            flex: 1 1 auto;
            min-width: ${dimensions.card.minWidth}rem;
            max-width: ${dimensions.card.maxWidth}rem;
          `
        }
      >
        <Link
          to={!noAccess ? requestDetailPath(props.request.id) : ""}
          state={{
            requestListViewId: requestList.viewId
          }}
          css={[
            css`
              width: 100%;
            `,
            noAccess && requestPrivate
          ]}
        >
          {/* cover card with overlay when noAccess (e.g. in projects) */}
          {noAccess && (
            <div
              css={css`
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba(var(--white-raw), 0.55);
                z-index: var(--z-high);
              `}
            />
          )}
          <Card sizeL hasInteractions={!noAccess} data-testid="request-loaded" css={requestStyles}>
            <Col fullWidth>
              <Row>
                <Col className="info" justify="center">
                  <Row
                    className="subtitle"
                    css={css`
                      & > * + * {
                        margin-left: var(--space-2-px) !important;
                      }
                    `}
                  >
                    <Typo.Body light>{formatTime(props.request.requestedAt)} by&nbsp;</Typo.Body>
                    <Tooltip
                      placement="top"
                      target={
                        <Typo.Body
                          ellipsis
                          light
                          css={css`
                            margin-left: calc(var(--space-2-px) * -1);
                          `}
                        >
                          <UserName user={request.requester} />
                        </Typo.Body>
                      }
                    >
                      <span>{`By: ${request.requester.name || request.requester.email}`}</span>
                    </Tooltip>
                    {noAccess && (
                      <span className="all-pointer-events">
                        <Tooltip target={<Badge warning>no access</Badge>}>
                          You can't access this request because it's assigned to a private team you're not a member of.
                        </Tooltip>
                      </span>
                    )}
                    {props.request.sla && props.request.slaStatus && (
                      <SlaStatusBadge sla={props.request.sla} slaStatus={props.request.slaStatus} />
                    )}
                    {props.requestUpdates && (
                      <RequestUpdates
                        requestUpdates={props.requestUpdates}
                        assignedToMe={assignedToMe}
                        unassigned={props.request.assignee === null}
                      />
                    )}
                  </Row>
                  <Typo.Body
                    sizeL
                    ellipsis
                    bold={!!props.requestUpdates?.unread}
                    title={formatTitle(request.title)}
                    css={css`
                      line-height: var(--line-height-dense);
                      padding: 6px 0 10px;
                    `}
                    data-testid="request-card-title"
                  >
                    {formatTitle(request.title)}
                  </Typo.Body>
                </Col>
                <Col className="tags">
                  <Row
                    css={css`
                      padding-top: var(--space-1-px);
                      & > * + * {
                        margin-left: var(--space-2-px);
                      }
                    `}
                  >
                    <Hide when={hidePriorityPill} style={hidePriorityPill ? { marginLeft: 0 } : {}}>
                      <AbsoluteTooltip
                        placement="top"
                        content={<span>{`Priority: ${priorityLabels[request.priority]}`}</span>}
                      >
                        <RequestPriorityButton
                          requestId={request.id}
                          priority={request.priority}
                          buttonRef={pillRefs[ActionEnum.PRIORITY]}
                          onUpdate={() => {
                            removePillFromVisiblePills(ActionEnum.PRIORITY);
                          }}
                        />
                      </AbsoluteTooltip>
                    </Hide>
                    <Tooltip
                      placement="top"
                      target={
                        <PopOver.Menu
                          options={optionsByTeam.changeCustomStatus?.(requestTeam) ?? []}
                          selected={requestCurrentCustomStatus.id}
                          onSelect={(customStatusId: string) =>
                            handlers.changeCustomStatus(props.request.id, customStatusId)
                          }
                          trigger={
                            <Status.Indicator
                              requestStatus={requestCurrentCustomStatus.step}
                              statusText={requestCurrentCustomStatus.name}
                              customColor={requestCurrentCustomStatus.color as ColorVariantsUnion}
                              data-intercom-target="Request custom status button"
                              data-testid="request-status-button"
                            />
                          }
                        />
                      }
                    >
                      <span>{`Status: ${request.customStatus?.name}`}</span>
                    </Tooltip>
                  </Row>
                </Col>
              </Row>
              <Row
                align="center"
                css={css`
                  margin-top: var(--space-1-rem);
                  & > * + * {
                    margin-left: var(--space-2-px);
                  }
                `}
              >
                <>
                  <AbsoluteTooltip
                    placement="top"
                    content={<span>{`Assignee: ${request.assignee?.name || "Unassigned"}`}</span>}
                  >
                    <AssignExpertButton request={request} team={requestTeam} />
                  </AbsoluteTooltip>
                  <AbsoluteTooltip placement="top" content={<span>{`Team: ${requestTeam.name}`}</span>}>
                    <PopOver.Menu
                      options={options.changeTeam ?? []}
                      selected={request.team.id}
                      onSelect={(teamId: string) => {
                        handlers.changeTeam(request.id, teamId, requestTeam);
                      }}
                      trigger={<Pill.Team data-testid="request-team" teamName={requestTeam.name} />}
                    />
                  </AbsoluteTooltip>
                  <Hide when={hideCategoryPill} style={hideCategoryPill ? { marginLeft: 0 } : {}}>
                    <AbsoluteTooltip placement="top" content={<span>{`Category: ${request.category?.name}`}</span>}>
                      <PopOver.Menu
                        options={optionsByTeam.assignCategory?.(requestTeam) ?? []}
                        labelForRemoveOption="Remove category"
                        selected={request.category?.id}
                        onSelect={(categoryId: string | null) => {
                          handlers.assignCategory(request.id, categoryId, request.category?.id ?? null, () => {
                            removePillFromVisiblePills(ActionEnum.CATEGORY);
                          });
                        }}
                        onRemove={() =>
                          handlers.assignCategory(request.id, null, request.category?.id ?? null, () => void 0)
                        }
                        onClickAway={e => {
                          e?.stopPropagation();
                          removePillFromVisiblePills(ActionEnum.CATEGORY);
                        }}
                        trigger={
                          <Pill.Category
                            data-testid="request-category"
                            categoryName={request.category?.name}
                            ref={pillRefs[ActionEnum.CATEGORY]}
                          />
                        }
                      />
                    </AbsoluteTooltip>
                  </Hide>
                </>
                <Hide
                  className="all-pointer-events"
                  when={hideProjectPill}
                  onClick={e => e.stopPropagation()}
                  style={hideProjectPill ? { marginLeft: 0 } : {}}
                >
                  <AbsoluteTooltip placement="top" content={<span>{`Project: ${request.project?.title}`}</span>}>
                    <ChangeProjectButton
                      onClickAway={e => {
                        e.stopPropagation();
                        removePillFromVisiblePills(ActionEnum.PROJECT);
                      }}
                      onAssign={() => {
                        removePillFromVisiblePills(ActionEnum.PROJECT);
                      }}
                      menuPlacement="bottom-start"
                      requestId={request.id}
                      project={request.project}
                      trigger={<Pill.Project projectName={request.project?.title} ref={pillRefs[ActionEnum.PROJECT]} />}
                    />
                  </AbsoluteTooltip>
                </Hide>
                <Hide when={hideDueDatePill} style={hideDueDatePill ? { marginLeft: 0 } : {}}>
                  <AbsoluteTooltip placement="top" content={<span>{`Due date: ${formattedDueDate}`}</span>}>
                    <ChangeDueDatePicker
                      button={
                        <Pill.DueDate ref={pillRefs[ActionEnum.DUEDATE]} dueDate={request.dueAt} isUrgent={isUrgent} />
                      }
                      dueAt={request.dueAt}
                      requestId={request.id}
                      onClickAway={e => {
                        e.stopPropagation();
                        removePillFromVisiblePills(ActionEnum.DUEDATE);
                      }}
                    />
                  </AbsoluteTooltip>
                </Hide>
                {showActions && (
                  <AbsoluteTooltip placement="top" content={<span>More actions</span>}>
                    <PopOver.Menu
                      trigger={<LegacyPill iconPath={mdiDotsHorizontal} padding="0.4375rem" margin="0" />}
                      options={[
                        {
                          headline: "Actions",
                          options: [
                            !(request.category && request.category.id) && {
                              id: ActionEnum.CATEGORY,
                              name: actionMenuLabels[ActionEnum.CATEGORY],
                              onClick: () => {
                                updateVisiblePills(request.id, ActionEnum.CATEGORY);
                              }
                            },
                            request.priority === RequestPriority.PRIORITY_UNSPECIFIED && {
                              id: ActionEnum.PRIORITY,
                              name: actionMenuLabels[ActionEnum.PRIORITY],
                              onClick: () => {
                                updateVisiblePills(request.id, ActionEnum.PRIORITY);
                              }
                            },
                            !request.dueAt && {
                              id: ActionEnum.DUEDATE,
                              name: actionMenuLabels[ActionEnum.DUEDATE],
                              onClick: () => {
                                updateVisiblePills(request.id, ActionEnum.DUEDATE);
                              }
                            },
                            !request.project && {
                              id: ActionEnum.PROJECT,
                              name: actionMenuLabels[ActionEnum.PROJECT],
                              onClick: () => {
                                updateVisiblePills(request.id, ActionEnum.PROJECT);
                              }
                            }
                          ].filter(Boolean) as OptionMap[]
                        }
                      ]}
                    />
                  </AbsoluteTooltip>
                )}
              </Row>
            </Col>
          </Card>
        </Link>
      </div>
    </RequestRowWrapper>
  );
});

/**
 * Displays the notification message
 * (if there is one)
 */
export function RequestUpdates(props: {
  requestUpdates: GRequestListUpdated_requestListUpdated;
  assignedToMe: boolean;
  unassigned: boolean;
}) {
  const message = notificationMessage(props);
  if (message !== null) {
    return (
      <Row align="center" className="notification" data-testid="notification-message">
        <Badge unread>{message.toLowerCase()}</Badge>
      </Row>
    );
  } else {
    return null;
  }
}
