import { css } from "@emotion/core";
import * as React from "react";
import { Dispatch, FC } from "react";
import { AttachmentsDropZone } from "src/App/Attachments/DropZone";
import { createImageListFromAttachments, ImageViewer } from "src/App/Attachments/ImageViewer";
import { AttachmentItem, AttachmentItemWrapper } from "src/App/Attachments/Item";
import { useFileInput } from "src/App/Attachments/Menu";
import { IFiles, TFileAction } from "src/App/Attachments/reducer";
import { FileStack } from "src/App/Requests/DetailView/InputBar/FileStack";
import { Typo } from "src/styling/primitives/typography";

export const AttachmentList: FC<{
  attachments: Array<{ id: string; displayName: string; size: number }>;
  filesState?: IFiles;
  filesDispatch?: Dispatch<TFileAction>;
  handleAttachmentRemove?(id: string): void;
  preventMultiple?: boolean;
}> = ({ filesState, filesDispatch, attachments, handleAttachmentRemove, preventMultiple = false }) => {
  const fileInputElement = useFileInput(filesDispatch, preventMultiple);

  return (
    <>
      {filesState && filesDispatch && (
        <AttachmentItemWrapper
          css={css`
            transition: background-color 300ms ease;
            /*  styles needed for DropZone */
            position: relative;
            & > div {
              color: var(--text-6);
            }
            &:hover {
              background-color: var(--lightGrey-2);
            }
          `}
          onClick={() => fileInputElement.click()}
        >
          <Typo.Body light>
            Add {!!filesState.files.length || attachments.length ? "another" : "the first"} attachment
          </Typo.Body>
          <AttachmentsDropZone filesState={filesState} filesDispatch={filesDispatch} />
        </AttachmentItemWrapper>
      )}
      {filesState && filesDispatch && <FileStack filesState={filesState} filesDispatch={filesDispatch} spaced />}
      <ImageViewer imagesList={createImageListFromAttachments(attachments, null)}>
        {dispatch =>
          attachments.map(attachment => (
            <AttachmentItem
              key={attachment.id}
              attachment={attachment}
              imageViewerDispatch={dispatch}
              handleRemove={handleAttachmentRemove}
              extraSpacing={!!filesDispatch}
              authToken={null}
            />
          ))
        }
      </ImageViewer>
    </>
  );
};
