import { css } from "@emotion/core";
import { mdiChevronLeft } from "@mdi/js";
import { motion } from "framer-motion";
import * as React from "react";
import { useEffect, useState } from "react";
import { KBMarkdownView } from "src/App/KB/Detail/MarkdownView";
import { LinkTypeButton, MaterialIcon } from ".";

const invisibleButton = css`
  visibility: hidden;
  pointer-events: none;
`;

/**
 * Expandable less/more text component
 * Shows "more" button when text has more than one line
 */
export const Expandable: React.FC<{
  text: string;
  lines?: number;
  className?: string;
}> = ({ text, lines = 1, className }) => {
  const initialHeight = 21 * lines;
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [scrollHeight, setScrollHeight] = useState<number>(0);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [hasTextOverflow, setHasTextOverflow] = useState<boolean>(false);
  const isExpanded = contentHeight <= scrollHeight;

  useEffect(() => {
    if (containerHeight && contentHeight) {
      const evaluateScrollHeight = (): number => {
        if (contentHeight <= initialHeight) {
          // content is shorter than the set of lines to show
          setHasTextOverflow(false);
          return contentHeight;
        } else {
          // content is larger than the set of lines to show and the container
          setHasTextOverflow(true);
          return initialHeight;
        }
      };
      setScrollHeight(evaluateScrollHeight());
    }
  }, [containerHeight, contentHeight, initialHeight]);

  return (
    <div>
      <motion.div
        className={className}
        initial={{ height: !!scrollHeight ? scrollHeight : initialHeight }}
        animate={{
          height: !!scrollHeight ? scrollHeight : initialHeight,
          transition: {
            ease: "easeInOut"
          }
        }}
        css={[
          css`
            overflow: hidden;
            display: flex;
            align-items: flex-start;
          `,
          hasTextOverflow &&
            !isExpanded &&
            css`
              -webkit-mask-image: -webkit-gradient(
                linear,
                left 20%,
                left bottom,
                from(rgba(0, 0, 0, 1)),
                to(rgba(0, 0, 0, 0))
              );
            `
        ]}
        ref={container => {
          container && setContainerHeight(container.clientHeight);
        }}
      >
        <div
          ref={text => {
            text && setContentHeight(text.scrollHeight);
          }}
        >
          <KBMarkdownView md={text} />
        </div>
      </motion.div>
      {hasTextOverflow && (
        <motion.div
          initial={{ marginTop: 0 }}
          animate={{
            marginTop: isExpanded ? 12 : 0,
            transition: {
              ease: "easeInOut"
            }
          }}
        >
          <LinkTypeButton
            css={[
              css`
                display: flex;
                align-items: center;
              `
            ]}
            onClick={() => setScrollHeight(isExpanded ? initialHeight : contentHeight)}
          >
            <span>Show {isExpanded ? "less" : "more"}</span>
            <motion.div
              initial={{ transform: `rotate(-0.25turn)` }}
              animate={{
                transform: isExpanded ? `rotate(0.25turn)` : `rotate(-0.25turn)`,
                transition: {
                  ease: "easeInOut"
                }
              }}
            >
              <MaterialIcon path={mdiChevronLeft} size={1} />
            </motion.div>
          </LinkTypeButton>
        </motion.div>
      )}
    </div>
  );
};
