import { css } from "@emotion/core";
import * as React from "react";
import { PopOverMenu } from "src/components/PopOver/PopOverMenu";
import { ActionInputMap, ActionKey, JumpIfInput, WorkflowPublishedVariableType } from "src/globalTypes";
import { Typo } from "src/styling/primitives/typography";
import { keys } from "src/util";
import { csx } from "src/util/csx";
import { pluralize } from "src/util/formatters";
import {
  SelectSubworkflow,
  SelectVariable,
  StepActionIndex,
  useWorkflow,
  VariablesAsComponents,
  ViewVariable
} from "./components";
import {
  CategoryMatcherComponent,
  DateMatcherComponent,
  DocumentMatcherComponent,
  FormMatcherComponent,
  MatcherValueContainer,
  PriorityMatcherComponent,
  SelectTrigger,
  StatusMatcherComponent,
  TeamMatcherComponent,
  UserMatcherComponent,
  userMatcherLabel
} from "./components/MatcherComponents";
import { TextAreaWithVariables } from "./components/TextArea";

export const WfStepTable = csx(
  [
    css`
      padding: 0;
      border-spacing: 0;

      & > * + * > td {
        padding-top: var(--space-4-rem);
      }
      & > tr {
        vertical-align: top;

        & > td:first-of-type {
          white-space: nowrap;
          text-align: right;
          padding-right: var(--space-2-rem);
        }
      }
    `
  ],
  {},
  "table"
);

interface ActionProps<K extends ActionKey> {
  action: NonNullable<ActionInputMap[K]>;
  index: StepActionIndex;
  onChange(value: Partial<NonNullable<ActionInputMap[K]>>): void;
  expanded: boolean;
}

export const ApprovalRequestStepCard: React.FC<ActionProps<ActionKey.ApprovalRequest>> = props => {
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Request approval</Typo.Body>
      <WfStepTable>
        <tr>
          <td>Send approval request to:</td>
          <td>
            <UserMatcherComponent
              matcher={props.action.approver}
              actionId={props.index}
              onChange={approver =>
                props.onChange({
                  approver
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>with the message:</td>
          <td>
            <TextAreaWithVariables
              type="plaintext"
              initialValue={props.action.text}
              onChange={text =>
                props.onChange({
                  text
                })
              }
            />
          </td>
        </tr>
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>
      Send approval request to <MatcherValueContainer>{props.action.approver.email}</MatcherValueContainer>
    </Typo.Body>
  );
};

export const CreateConversationStepCard: React.FC<ActionProps<ActionKey.CreateConversation>> = props => {
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Send outreach</Typo.Body>
      <WfStepTable>
        <tr>
          <td>Send outreach to:</td>
          <td>
            <UserMatcherComponent
              isMulti
              matcherList={props.action.toList}
              actionId={props.index}
              onChangeList={toList => {
                props.onChange({
                  toList
                });
              }}
            />
          </td>
        </tr>
        <tr>
          <td>with subject:</td>
          <td>
            <TextAreaWithVariables
              type="plaintext"
              initialValue={props.action.subject}
              onChange={subject =>
                props.onChange({
                  subject
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>and text:</td>
          <td>
            <TextAreaWithVariables
              type="plaintext"
              initialValue={props.action.body ?? ""}
              onChange={body =>
                props.onChange({
                  body
                })
              }
            />
          </td>
        </tr>
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>
      Send outreach to <ViewVariable value={props.action.toList.map(userMatcherLabel).join(", ")} />
    </Typo.Body>
  );
};

export const CreateConversationRequestStepCard: React.FC<ActionProps<ActionKey.CreateConversationRequest>> = props => {
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Create request from outreach</Typo.Body>
      <WfStepTable>
        {props.action.alias && (
          <tr>
            <td>Request variable name:</td>
            <td>
              <TextAreaWithVariables
                type="plaintext"
                initialValue={props.action.alias}
                onChange={alias =>
                  props.onChange({
                    alias
                  })
                }
              />
            </td>
          </tr>
        )}
        <tr>
          <td>from outreach:</td>
          <td>
            <SelectVariable
              index={props.index}
              type={WorkflowPublishedVariableType.TYPE_CONVERSATION_ID}
              value={props.action.conversationId}
              onChange={conversationId =>
                props.onChange({
                  conversationId
                })
              }
            />
          </td>
        </tr>
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>
      Create request from <ViewVariable value={props.action.conversationId} />
    </Typo.Body>
  );
};

export const CreateNoteStepCard: React.FC<ActionProps<ActionKey.CreateNote>> = props => {
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Add internal note</Typo.Body>
      <WfStepTable>
        <tr>
          <td>In request:</td>
          <td>
            <SelectVariable
              index={props.index}
              type={WorkflowPublishedVariableType.TYPE_REQUEST_ID}
              value={props.action.requestId}
              onChange={requestId =>
                props.onChange({
                  requestId
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>add internal note with text:</td>
          <td>
            <TextAreaWithVariables
              type="plaintext"
              initialValue={props.action.textRequired}
              onChange={textRequired =>
                props.onChange({
                  textRequired
                })
              }
            />
          </td>
        </tr>
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>
      Add internal note to <ViewVariable value={props.action.textRequired} />
    </Typo.Body>
  );
};

export const CreateProjectStepCard: React.FC<ActionProps<ActionKey.CreateProject>> = props => {
  const { workflow } = useWorkflow();
  if (!workflow) return null;
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Create project</Typo.Body>
      <WfStepTable>
        <tr>
          <td>Create project:</td>
          <td>
            <TextAreaWithVariables
              type="plaintext"
              initialValue={props.action.title}
              onChange={title =>
                props.onChange({
                  title
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>in team:</td>
          <td>
            <TeamMatcherComponent
              matcher={
                props.action.team ?? {
                  teamId: workflow.teamId
                }
              }
              actionId={props.index}
              onChange={team =>
                props.onChange({
                  team
                })
              }
            />
          </td>
        </tr>
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>
      Create new project in{" "}
      <TeamMatcherComponent
        matcher={
          props.action.team ?? {
            teamId: workflow.teamId
          }
        }
      />
    </Typo.Body>
  );
};

export const CreateRequestStepCard: React.FC<ActionProps<ActionKey.CreateRequest>> = props => {
  const { workflow } = useWorkflow();
  if (!workflow) return null;
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Create new request</Typo.Body>
      <WfStepTable>
        <tr>
          <td>Create request:</td>
          <td>
            <TextAreaWithVariables
              type="plaintext"
              initialValue={props.action.title}
              onChange={title =>
                props.onChange({
                  title
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>from:</td>
          <td>
            <UserMatcherComponent
              matcher={props.action.requester}
              actionId={props.index}
              onChange={requester =>
                props.onChange({
                  requester
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>with description:</td>
          <td>
            <TextAreaWithVariables
              type="plaintext"
              initialValue={props.action.description ?? ""}
              onChange={description =>
                props.onChange({
                  description
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>in team:</td>
          <td>
            <TeamMatcherComponent
              actionId={props.index}
              matcher={
                props.action.team ?? {
                  teamId: workflow.teamId
                }
              }
              onChange={team =>
                props.onChange({
                  team
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>in project:</td>
          <td>
            <SelectVariable
              index={props.index}
              type={WorkflowPublishedVariableType.TYPE_PROJECT_ID}
              value={props.action.projectId ?? null}
              onChange={projectId =>
                props.onChange({
                  projectId
                })
              }
            />
          </td>
        </tr>
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>
      Create request <ViewVariable value={props.action.title} />
    </Typo.Body>
  );
};

export const CreateReplyStepCard: React.FC<ActionProps<ActionKey.CreateReply>> = props => {
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Send reply</Typo.Body>
      <WfStepTable>
        <tr>
          <td>In request:</td>
          <td>
            <SelectVariable
              index={props.index}
              type={WorkflowPublishedVariableType.TYPE_REQUEST_ID}
              value={props.action.requestId}
              onChange={requestId =>
                props.onChange({
                  requestId
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>send reply with text:</td>
          <td>
            <TextAreaWithVariables
              type="plaintext"
              initialValue={props.action.textRequired}
              onChange={textRequired =>
                props.onChange({
                  textRequired
                })
              }
            />
          </td>
        </tr>
        {!!props.action.documents?.length && (
          <tr>
            <td>and the documents:</td>
            <td>
              <DocumentMatcherComponent
                matcher={props.action.documents}
                onChange={documents => {
                  props.onChange({
                    documents
                  });
                }}
              />
            </td>
          </tr>
        )}
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>
      Send reply to employee in <ViewVariable value={props.action.requestId} />
    </Typo.Body>
  );
};

export const CreateTaskStepCard: React.FC<ActionProps<ActionKey.CreateTask>> = props => {
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Add task to request</Typo.Body>
      <WfStepTable>
        <tr>
          <td>Add task:</td>
          <td>
            <TextAreaWithVariables
              type="plaintext"
              initialValue={props.action.instruction}
              onChange={instruction =>
                props.onChange({
                  instruction
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>to request:</td>
          <td>
            <SelectVariable
              index={props.index}
              type={WorkflowPublishedVariableType.TYPE_REQUEST_ID}
              value={props.action.requestId}
              onChange={requestId =>
                props.onChange({
                  requestId
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>Call to action:</td>
          <td>
            <TextAreaWithVariables
              type="plaintext"
              initialValue={props.action.action?.label ?? ""}
              onChange={label =>
                props.onChange({
                  action: {
                    url: props.action.action?.url ?? "",
                    label
                  }
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>URL:</td>
          <td>
            <TextAreaWithVariables
              initialValue={props.action.action?.url ?? ""}
              onChange={url =>
                props.onChange({
                  action: {
                    label: props.action.action?.label ?? "",
                    url
                  }
                })
              }
            />
          </td>
        </tr>
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>
      Add task <ViewVariable value={props.action.instruction} /> to <ViewVariable value={props.action.requestId} />
    </Typo.Body>
  );
};

export const FetchUserProfileStepCard: React.FC<ActionProps<ActionKey.FetchUserProfile>> = props => {
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Fetch employee profile</Typo.Body>
      <WfStepTable>
        <tr>
          <td>Fetch employee profile:</td>
          <td>
            <UserMatcherComponent
              matcher={props.action.user}
              actionId={props.index}
              onChange={user =>
                props.onChange({
                  user
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>alias:</td>
          <td>
            <TextAreaWithVariables
              type="plaintext"
              initialValue={props.action.alias ?? ""}
              onChange={alias =>
                props.onChange({
                  alias
                })
              }
            />
          </td>
        </tr>
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>
      Fetch user profile {props.action.user.email && `for ${props.action.user.email}`}{" "}
      <MatcherValueContainer>{props.action.user.email}</MatcherValueContainer>
    </Typo.Body>
  );
};

type relationTypes = keyof Omit<JumpIfInput, "onTrue" | "onFalse">;
const relations = (a = "A", b = "B"): Record<relationTypes, string> => ({
  gt: `${a} is greater than ${b}`,
  gte: `${a} is greater than or equal to ${b}`,
  lt: `${a} is less than ${b}`,
  lte: `${a} is less than or equal to ${b}`,
  numeq: `${a} equals ${b}`,
  streq: `${a} and ${b} have the same string value`
});

export const JumpIfStepCard: React.FC<ActionProps<ActionKey.JumpIf>> = props => {
  const condition = {
    name: "",
    a: "",
    b: ""
  };
  for (const key of keys(props.action)) {
    const cond = props.action[key];
    if (cond && typeof cond === "object" && cond.a && cond.b) {
      condition.name = key;
      condition.a = cond.a;
      condition.b = cond.b;
    }
  }
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Check condition</Typo.Body>
      <WfStepTable>
        <tr>
          <td>A:</td>
          <td>
            <TextAreaWithVariables
              initialValue={condition.a ?? ""}
              onChange={a =>
                props.onChange({
                  [condition.name]: {
                    a,
                    b: condition.b
                  }
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>B:</td>
          <td>
            <TextAreaWithVariables
              initialValue={condition.b ?? ""}
              onChange={b =>
                props.onChange({
                  [condition.name]: {
                    a: condition.a,
                    b
                  }
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>Condition:</td>
          <td>
            <PopOverMenu
              selected={condition.name}
              trigger={<SelectTrigger>{relations()[condition.name as relationTypes]}</SelectTrigger>}
              options={keys(relations()).map(rel => ({
                id: rel,
                name: relations()[rel]
              }))}
              onSelect={(rel: keyof typeof relations) =>
                props.onChange({
                  [rel]: {
                    a: condition.a,
                    b: condition.b
                  }
                })
              }
            />
          </td>
        </tr>
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>
      Check condition: <ViewVariable value={relations(condition.a, condition.b)[condition.name as relationTypes]} />
    </Typo.Body>
  );
};

export const LaunchActionStepCard: React.FC<ActionProps<ActionKey.Launch>> = props => {
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Launch sub-workflows</Typo.Body>
      <WfStepTable>
        {props.action.flowsList.map((flow, i) => (
          <tr key={i}>
            <td>
              <SelectSubworkflow
                index={props.index}
                value={flow.subworkflowName}
                onChange={subworkflowName =>
                  props.onChange({
                    flowsList: [
                      ...props.action.flowsList.slice(0, i),
                      {
                        ...flow,
                        subworkflowName
                      },
                      ...props.action.flowsList.slice(i + 1)
                    ]
                  })
                }
              />
            </td>
            <td>
              <DateMatcherComponent
                index={props.index}
                matcher={
                  flow.at ?? {
                    date: "now"
                  }
                }
                onChange={at =>
                  props.onChange({
                    flowsList: [
                      ...props.action.flowsList.slice(0, i),
                      {
                        ...flow,
                        at
                      },
                      ...props.action.flowsList.slice(i + 1)
                    ]
                  })
                }
              />
            </td>
          </tr>
        ))}
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>Launch {pluralize(props.action.flowsList.length, "subworkflow", "subworkflows")}</Typo.Body>
  );
};

export const LinkJiraRequestStepCard: React.FC<ActionProps<ActionKey.LinkJiraRequest>> = props => {
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Create Jira issue from request</Typo.Body>
      <WfStepTable>
        <tr>
          <td>request:</td>
          <td>
            <SelectVariable
              index={props.index}
              type={WorkflowPublishedVariableType.TYPE_REQUEST_ID}
              value={props.action.requestId}
              onChange={requestId =>
                props.onChange({
                  requestId
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>Jira issue alias:</td>
          <td>
            <TextAreaWithVariables
              type="plaintext"
              initialValue={props.action.aliasIssue ?? ""}
              onChange={aliasIssue =>
                props.onChange({
                  aliasIssue
                })
              }
            />
          </td>
        </tr>
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>
      Create Jira issue from <ViewVariable value={props.action.requestId} />
    </Typo.Body>
  );
};

export const MapValueStepCard: React.FC<ActionProps<ActionKey.MapValue>> = props => {
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Map values from input</Typo.Body>
      <WfStepTable>
        <tr>
          <td>in:</td>
          <td>
            <TextAreaWithVariables
              initialValue={props.action.in}
              onChange={inText =>
                props.onChange({
                  in: inText
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>values:</td>
          <td>
            <VariablesAsComponents text={props.action.values.map(v => `key: ${v.key}, value: ${v.value}`).join(";")} />
          </td>
        </tr>
        <tr>
          <td>out:</td>
          <td>
            <VariablesAsComponents text={props.action.out} />
          </td>
        </tr>
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>Map values from input</Typo.Body>
  );
};

export const SendFormStepCard: React.FC<ActionProps<ActionKey.SendForm>> = props => {
  // Use dynamic team id here at some point
  const { workflow } = useWorkflow();
  if (!workflow) return null;
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Send form</Typo.Body>
      <WfStepTable>
        <tr>
          <td>Send form:</td>
          <td>
            <FormMatcherComponent
              matcher={props.action.form}
              teamId={workflow.teamId}
              onChange={form =>
                props.onChange({
                  form
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>in request:</td>
          <td>
            <SelectVariable
              index={props.index}
              type={WorkflowPublishedVariableType.TYPE_REQUEST_ID}
              value={props.action.requestId}
              onChange={requestId =>
                props.onChange({
                  requestId
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>set replies to alias:</td>
          <td>
            <TextAreaWithVariables
              type="plaintext"
              initialValue={props.action.aliasReplies ?? ""}
              onChange={aliasReplies =>
                props.onChange({
                  aliasReplies
                })
              }
            />
          </td>
        </tr>
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>
      Send form <FormMatcherComponent matcher={props.action.form} teamId={workflow.teamId} /> in{" "}
      <ViewVariable value={props.action.requestId} />
    </Typo.Body>
  );
};

export const SetRequestAssigneeStepCard: React.FC<ActionProps<ActionKey.SetRequestAssignee>> = props => {
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Set assignee</Typo.Body>
      <WfStepTable>
        <tr>
          <td>Assign request:</td>
          <td>
            <SelectVariable
              index={props.index}
              type={WorkflowPublishedVariableType.TYPE_REQUEST_ID}
              value={props.action.requestId}
              onChange={requestId =>
                props.onChange({
                  requestId
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>to expert:</td>
          <td>
            <UserMatcherComponent
              matcher={props.action.assignee}
              actionId={props.index}
              onChange={assignee =>
                props.onChange({
                  assignee
                })
              }
            />
          </td>
        </tr>
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>
      Assign request <ViewVariable value={props.action.requestId} /> to{" "}
      <MatcherValueContainer>{props.action.assignee.email}</MatcherValueContainer>
    </Typo.Body>
  );
};

export const SetRequestCategoryStepCard: React.FC<ActionProps<ActionKey.SetRequestCategory>> = props => {
  // Use dynamic team id here at some point
  const { workflow } = useWorkflow();
  if (!workflow) return null;
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Set category</Typo.Body>
      <WfStepTable>
        <tr>
          <td>Assign request:</td>
          <td>
            <SelectVariable
              index={props.index}
              type={WorkflowPublishedVariableType.TYPE_REQUEST_ID}
              value={props.action.requestId}
              onChange={requestId =>
                props.onChange({
                  requestId
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>to category:</td>
          <td>
            <CategoryMatcherComponent
              matcher={props.action.category}
              onChange={category =>
                props.onChange({
                  category
                })
              }
              teamId={workflow.teamId}
            />
          </td>
        </tr>
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>
      Change category of <ViewVariable value={props.action.requestId} /> to{" "}
      <CategoryMatcherComponent matcher={props.action.category} teamId={workflow.teamId} />
    </Typo.Body>
  );
};

export const SetRequestDueDateStepCard: React.FC<ActionProps<ActionKey.SetRequestDueDate>> = props => {
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Set due date</Typo.Body>
      <WfStepTable>
        <tr>
          <td>Set request:</td>
          <td>
            <SelectVariable
              index={props.index}
              type={WorkflowPublishedVariableType.TYPE_REQUEST_ID}
              value={props.action.requestId}
              onChange={requestId =>
                props.onChange({
                  requestId
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>to due date:</td>
          <td>
            <DateMatcherComponent
              index={props.index}
              matcher={props.action.dueDate}
              onChange={dueDate =>
                props.onChange({
                  dueDate
                })
              }
            />
          </td>
        </tr>
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>
      Change due date of <ViewVariable value={props.action.requestId} /> to{" "}
      <DateMatcherComponent index={props.index} matcher={props.action.dueDate} />
    </Typo.Body>
  );
};

export const SetRequestPriorityStepCard: React.FC<ActionProps<ActionKey.SetRequestPriority>> = props => {
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Set priority</Typo.Body>
      <WfStepTable>
        <tr>
          <td>Change request:</td>
          <td>
            <SelectVariable
              index={props.index}
              type={WorkflowPublishedVariableType.TYPE_REQUEST_ID}
              value={props.action.requestId}
              onChange={requestId =>
                props.onChange({
                  requestId
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>to priority:</td>
          <td>
            <PriorityMatcherComponent
              matcher={props.action.priority}
              onChange={priority => props.onChange({ priority })}
            />
          </td>
        </tr>
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>
      Change priority of <ViewVariable value={props.action.requestId} /> to{" "}
      <PriorityMatcherComponent matcher={props.action.priority} />
    </Typo.Body>
  );
};

export const SetRequestProjectStepCard: React.FC<ActionProps<ActionKey.SetRequestProject>> = props => {
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Set project</Typo.Body>
      <WfStepTable>
        <tr>
          <td>Assign request:</td>
          <td>
            <SelectVariable
              index={props.index}
              type={WorkflowPublishedVariableType.TYPE_REQUEST_ID}
              value={props.action.requestId}
              onChange={requestId =>
                props.onChange({
                  requestId
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>to project:</td>
          <td>
            <SelectVariable
              index={props.index}
              type={WorkflowPublishedVariableType.TYPE_PROJECT_ID}
              value={props.action.projectIdRequired}
              onChange={projectIdRequired =>
                props.onChange({
                  projectIdRequired
                })
              }
            />
          </td>
        </tr>
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>
      Assign <ViewVariable value={props.action.requestId} /> to project{" "}
      <ViewVariable value={props.action.projectIdRequired} />
    </Typo.Body>
  );
};

export const SetRequestStatusStepCard: React.FC<ActionProps<ActionKey.SetRequestStatus>> = props => {
  // Use dynamic team id here at some point
  const { workflow } = useWorkflow();
  if (!workflow) return null;
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Set status</Typo.Body>
      <WfStepTable>
        <tr>
          <td>Set request:</td>
          <td>
            <SelectVariable
              index={props.index}
              type={WorkflowPublishedVariableType.TYPE_REQUEST_ID}
              value={props.action.requestId}
              onChange={requestId =>
                props.onChange({
                  requestId
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>to status:</td>
          <td>
            <StatusMatcherComponent
              matcher={props.action.status}
              onChange={status =>
                props.onChange({
                  status
                })
              }
              teamId={workflow.teamId}
            />
          </td>
        </tr>
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>
      Change status of <ViewVariable value={props.action.requestId} /> to{" "}
      <StatusMatcherComponent matcher={props.action.status} teamId={workflow.teamId} />
    </Typo.Body>
  );
};

export const SetRequestTeamStepCard: React.FC<ActionProps<ActionKey.SetRequestTeam>> = props => {
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Change team</Typo.Body>
      <WfStepTable>
        <tr>
          <td>Move request:</td>
          <td>
            <SelectVariable
              index={props.index}
              type={WorkflowPublishedVariableType.TYPE_REQUEST_ID}
              value={props.action.requestId}
              onChange={requestId =>
                props.onChange({
                  requestId
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>to team:</td>
          <td>
            <TeamMatcherComponent
              matcher={props.action.teamRequired}
              actionId={props.index}
              onChange={teamRequired =>
                props.onChange({
                  teamRequired
                })
              }
            />
          </td>
        </tr>
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>
      Move request <ViewVariable value={props.action.requestId} /> to{" "}
      <TeamMatcherComponent matcher={props.action.teamRequired} />
    </Typo.Body>
  );
};

export const SetRequestTitleStepCard: React.FC<ActionProps<ActionKey.SetRequestTitle>> = props => {
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Set title</Typo.Body>
      <WfStepTable>
        <tr>
          <td>Set title of request:</td>
          <td>
            <SelectVariable
              index={props.index}
              type={WorkflowPublishedVariableType.TYPE_REQUEST_ID}
              value={props.action.requestId}
              onChange={requestId =>
                props.onChange({
                  requestId
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>to:</td>
          <td>
            <TextAreaWithVariables
              type="plaintext"
              initialValue={props.action.title}
              onChange={title =>
                props.onChange({
                  title
                })
              }
            />
          </td>
        </tr>
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>
      Set title of request <ViewVariable value={props.action.requestId} /> to{" "}
      <ViewVariable value={props.action.title} />
    </Typo.Body>
  );
};

export const SetRequestRequesterStepCard: React.FC<ActionProps<ActionKey.SetRequestRequester>> = props => {
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Set requester</Typo.Body>
      <WfStepTable>
        <tr>
          <td>Change requester of request:</td>
          <td>
            <SelectVariable
              index={props.index}
              type={WorkflowPublishedVariableType.TYPE_REQUEST_ID}
              value={props.action.requestId}
              onChange={requestId =>
                props.onChange({
                  requestId
                })
              }
            />
          </td>
        </tr>
        <tr>
          <td>to user:</td>
          <td>
            <UserMatcherComponent
              matcher={props.action.requester}
              actionId={props.index}
              onChange={requester => props.onChange({ requester })}
            />
          </td>
        </tr>
      </WfStepTable>
    </>
  ) : (
    <Typo.Body sizeL>
      Change requester of request <ViewVariable value={props.action.requestId} /> to{" "}
      <MatcherValueContainer>{props.action.requester.email}</MatcherValueContainer>
    </Typo.Body>
  );
};
