import { ApolloError, ApolloQueryResult } from "apollo-client";
import gql from "graphql-tag";
import * as React from "react";
import { useContext } from "react";
import { useQuery } from "react-apollo";
import { useIntercom } from "react-use-intercom";
import { FeatureFlags, useFeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import { CurrentUser } from "src/App/Root/providers/typings/CurrentUser";
import { ORG_INFO_FRAGMENT } from "src/fragments/OrgInfoFragment";
import { TEAM_INFO_FRAGMENT } from "src/fragments/TeamInfoFragment";
import { USER_INFO_FRAGMENT } from "src/fragments/UserInfoFragment";

export const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      ...UserInfo
      teams {
        ...TeamInfo
        emailAddresses {
          from
          to
        }
      }
      organization {
        ...OrgInfo
      }
    }
  }
  ${TEAM_INFO_FRAGMENT}
  ${USER_INFO_FRAGMENT}
  ${ORG_INFO_FRAGMENT}
`;

const CurrentUserContext = React.createContext({
  currentUser: null as CurrentUser["currentUser"],
  refetchCurrentUser: ((() => {
    void 0;
  }) as unknown) as () => Promise<ApolloQueryResult<CurrentUser>>,
  errorLoadingUser: undefined as ApolloError | undefined
});

/** Access current user data */
export const useCurrentUser = () => {
  const context = useContext(CurrentUserContext);
  if (!context) {
    throw new Error("useCurrentUser must be called from CurrentUserProvider child");
  }
  return context;
};

export const CurrentUserProvider: React.FC<{}> = props => {
  const currentUserResponse = useQuery<CurrentUser>(CURRENT_USER);

  const { hasFeatureFlags } = useFeatureFlags();
  const { boot } = useIntercom();

  // Only `boot` Intercom (ping the server) if the user is set and the
  // relevant feature flag is enabled
  // Repeated calls to boot do nothing and it should not be re-called unless the currentUser
  // object changes
  React.useEffect(() => {
    if (currentUserResponse?.data?.currentUser && hasFeatureFlags(FeatureFlags.INTERCOMENABLED)) {
      boot({
        userId: currentUserResponse.data.currentUser.id,
        name: currentUserResponse.data.currentUser.name,
        email: currentUserResponse.data.currentUser.email,
        company: {
          companyId: currentUserResponse.data.currentUser.organization.id,
          name: currentUserResponse.data.currentUser.organization.name
        },
        customAttributes: {
          joined_at: currentUserResponse.data.currentUser.joinTime
        }
      });
    }
  }, [currentUserResponse, boot, hasFeatureFlags]);

  // sort team names for use in options lists etc
  if (currentUserResponse.data?.currentUser?.teams) {
    currentUserResponse.data.currentUser.teams.sort((a, b) => a.name.localeCompare(b.name));
  }

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser: currentUserResponse?.data?.currentUser ?? null,
        refetchCurrentUser: currentUserResponse.refetch,
        errorLoadingUser: currentUserResponse.error
      }}
    >
      {props.children}
    </CurrentUserContext.Provider>
  );
};
