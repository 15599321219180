import * as Sentry from "@sentry/browser";
import { RangeModifier } from "react-day-picker";
import { teamInsightsSection } from "src/App/Dashboard/Container";
import { REQUEST_GET } from "src/App/Requests/DetailView/REQUEST_GET";
import { RequestGet, RequestGet_request } from "src/App/Requests/DetailView/typings/RequestGet";
import { Filters } from "src/App/Requests/ListView/Filters/urlParamHelpers";
import { CURRENT_USER } from "src/App/Root/providers/CurrentUserProvider";
import { CurrentUser, CurrentUser_currentUser } from "src/App/Root/providers/typings/CurrentUser";
import {
  GSettingsSLASGetAll_teamList,
  GSettingsSLASGetAll_teamList_SLAs
} from "src/App/Settings/SLAs/typings/GSettingsSLASGetAll";
import { WorkflowsGetList_teamWorkflows } from "src/App/Settings/Workflows/typings/WorkflowsGetList";
import { TeamInfo } from "src/fragments/typings/TeamInfo";
import {
  DocumentSource,
  EmptyResultsReason,
  EventName,
  OrderByKey,
  RequestListView,
  RequestPriority,
  ViewType
} from "src/globalTypes";
import { reportDevError } from "src/util";
import { apolloClient } from "src/util/apollo/client";

export enum AnalyticEvents {
  AnalyticsExportDownloaded = "Analytics export downloaded",
  AttachmentAdded = "Attachment added",
  BestPracticeGalleryUseCaseFilterApplied = "Best practice gallery filter applied",
  BestPracticeAddToTeam = "Best practice attempt to add to team",
  BestPracticeSharingIntent = "Best practice sharing intent",
  CalendarRangePickerAction = "Calendar range picker action",
  CannotCreateOrg = "Cannot create organization",
  ConversationDetailOpened = "Conversation detail opened",
  DueDateSet = "Due date set",
  ExpertAdded = "Expert added to team",
  ExpertInvited = "Expert invited to team",
  FilterApplied = "Filter applied",
  FiltersCleared = "Clear all filters",
  FourOhFour = "404 page not found",
  OrganizationCreated = "Organization created",
  TeamAdded = "Team added",
  IntegrationAdded = "Integration added",
  IllegalPrivateTeamMention = "Illegal private team mention",
  KBDocMigrateSavedReply = "Migrate KB doc to saved reply",
  KBTesterQuerySubmitted = "KB tester query submitted",
  NotifSettingToggled = "Notifications setting toggled",
  NotifSnackClicked = "Notifications snack clicked",
  NotifPermissionAsked = "Notifications permission asked",
  NotifClicked = "Notification clicked",
  RequestResolved = "Request resolved",
  RequestStatusChanged = "Request status changed",
  RequestCardCollapsed = "Request card collapsed",
  RequestCardExpanded = "Request card expanded",
  RequestCategoryAssigned = "Request category assigned",
  RequestAssigned = "Request assigned",
  SortApplied = "Sorting applied",
  ViewFiltered = "View filtered",
  RequestPriorityChanged = "Request priority changed",
  SLAAdded = "SLA added",
  SLAUpdated = "SLA updated",
  SLARemoved = "SLA removed",
  RequestOpenedFromTeamInsights = "Request opened from team insights",
  TeamInsightsTeamSwitch = "Team insights team switch",
  ViewChanged = "View changed",
  DocumentAttached = "Document attached to request",
  WorkflowConfigurationNotCompleted = "Workflow configuration not completed",
  JiraLinkClicked = "Jira link clicked",
  SavedReplyUsed = "Saved reply used",
  // Workflow tracking events
  StepExpanded = "Step expanded",
  StepSaved = "Step saved",
  StepCollapsed = "Step collapsed",
  TriggerExpanded = "Trigger expanded",
  TriggerSaved = "Trigger saved",
  TriggerCollapsed = "Trigger collapsed",
  FormAnswersDownload = "Form answers downloaded"
}

const trackIntercomEvent = (event: string, properties?: object) => {
  const IntercomGlobal = window.Intercom as any;
  if (!IntercomGlobal?.booted) {
    return;
  }
  try {
    IntercomGlobal("trackEvent", event, properties);
  } catch (e) {
    Sentry.captureException(e);
  }
};

const trackIntercomUser = (properties: object) => {
  const IntercomGlobal = window.Intercom as any;
  if (!IntercomGlobal?.booted) {
    return;
  }
  try {
    IntercomGlobal("update", properties);
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const trackToDestinations = async (event: string, properties?: object, _options?: unknown) => {
  trackIntercomEvent(event, properties);
};

export const trackUser = async (firstUser?: boolean) => {
  try {
    // track user and org if already logged in
    const query = await apolloClient.query<CurrentUser>({ query: CURRENT_USER });

    if (query.data.currentUser) {
      // set user id for tracking errors
      Sentry.configureScope(scope => {
        scope.setUser({ id: query.data.currentUser?.id });
      });

      trackIntercomUser({
        userId: query.data.currentUser.id,
        name: query.data.currentUser.email,
        company: { companyId: query.data.currentUser.organization.id, name: query.data.currentUser.organization.name },
        joined_at: query.data.currentUser.joinTime,
        ...(firstUser && { first_user: true })
      });
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export const trackTeamAdd = async (teamId: string, teamName: string, emails: string[]) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  // track invites
  for (const email of emails) {
    trackToDestinations(
      AnalyticEvents.ExpertInvited,
      {
        email,
        team_id: teamId,
        team_name: teamName
      },
      {
        context: {
          groupId: query.data.currentUser ? query.data.currentUser.organization.id : null
        }
      }
    );
  }
};

export const trackOrgCreated = async () => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.OrganizationCreated,
    {
      organization_id: query.data.currentUser?.organization.id,
      organization_name: query.data.currentUser?.organization.name,
      organization_slug: query.data.currentUser?.organization.slug
    },
    {
      context: { groupId: query.data.currentUser?.organization.id }
    }
  );
};

export const trackCannotCreateOrg = (reason: string) => {
  trackToDestinations(AnalyticEvents.CannotCreateOrg, {
    reason
  });
};

export const trackRequestStatusChanged = async (
  requestId: string,
  teamId: string,
  teamSlug: string,
  oldStatus: string,
  newStatus: string
) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.RequestStatusChanged,
    {
      request_id: requestId,
      status_from: oldStatus.toLowerCase(),
      status_to: newStatus.toLowerCase(),
      team_id: teamId,
      team_slug: teamSlug
    },
    {
      context: {
        groupId: query.data.currentUser ? query.data.currentUser.organization.id : null
      }
    }
  );
};

export const trackRequestResolved = async (requestId: string, teamId: string, teamSlug: string) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.RequestResolved,
    {
      request_id: requestId,
      team_id: teamId,
      team_slug: teamSlug
    },
    {
      context: {
        groupId: query.data.currentUser ? query.data.currentUser.organization.id : null
      }
    }
  );
};

export const trackRequestAssigned = async (
  requestId: string,
  teamId: string,
  teamSlug: string,
  assigneeId: string | null
) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  // *should* fetch updated query
  const requestQuery = await apolloClient.query<RequestGet>({
    query: REQUEST_GET,
    variables: {
      id: requestId
    }
  });
  // number times status previously changed w new status
  const assignmentChangedCount = requestQuery.data.request
    ? requestQuery.data.request.events.filter(event => event.eventName === EventName.ASSIGNMENTCHANGED).length
    : -1;
  trackToDestinations(
    AnalyticEvents.RequestAssigned,
    {
      assignee_id: assigneeId,
      assignment_ordinal: assignmentChangedCount,
      request_id: requestId,
      team_id: teamId,
      team_slug: teamSlug
    },
    {
      context: {
        groupId: query.data.currentUser ? query.data.currentUser.organization.id : null
      }
    }
  );
};

export const trackReqCategoryAssigned = async (
  teamId: string,
  teamSlug: string,
  requestId: string,
  categoryName: string | null | undefined,
  categoryId: string | null | undefined
) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.RequestCategoryAssigned,
    {
      category_id: categoryId,
      category_name: categoryName,
      request_id: requestId,
      team_id: teamId,
      team_slug: teamSlug
    },
    {
      context: { groupId: query.data.currentUser?.organization.id }
    }
  );
};

export const trackDueDateSet = async (requestId: string, dueDate: string) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.DueDateSet,
    {
      due_date: dueDate,
      request_id: requestId
    },
    {
      context: {
        groupId: query.data.currentUser ? query.data.currentUser.organization.id : null
      }
    }
  );
};

export const trackAttachmentAdded = async (requestId: string, type: string, contentType: string) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.AttachmentAdded,
    {
      content_type: contentType,
      request_id: requestId,
      type
    },
    {
      context: {
        groupId: query.data.currentUser ? query.data.currentUser.organization.id : null
      }
    }
  );
};

export const trackIllegalPrivateTeamMention = async (requestId: string, teamId: string, teamSlug: string) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.IllegalPrivateTeamMention,
    {
      request_id: requestId,
      request_team_id: teamId,
      request_team_slug: teamSlug
    },
    {
      context: { groupId: query.data.currentUser?.organization.id }
    }
  );
};

export const trackFilterApplied = async (view: RequestListView, filterType: keyof Filters, filterValue: string[]) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.FilterApplied,
    {
      view,
      filter_type: filterType,
      filter_value: filterValue
    },
    {
      context: { groupId: query.data.currentUser?.organization.id }
    }
  );
};

export const trackSortingApplied = async (view: RequestListView, sortKey: OrderByKey) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.SortApplied,
    {
      view,
      sort_type: sortKey
    },
    {
      context: { groupId: query.data.currentUser?.organization.id }
    }
  );
};

export const trackFiltersCleared = async (view: RequestListView) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.FiltersCleared,
    {
      view
    },
    {
      context: { groupId: query.data.currentUser?.organization.id }
    }
  );
};

export const trackViewFiltered = async (
  view: RequestListView,
  filters: { [f in keyof Filters]?: number },
  sortKey: OrderByKey | null,
  viewType: ViewType
) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.ViewFiltered,
    {
      view,
      filters,
      sort_type: sortKey,
      view_type: viewType
    },
    {
      context: { groupId: query.data.currentUser?.organization.id }
    }
  );
};

export const trackNotifSnack = async (action: "enabled" | "ignored" | "snoozed") => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.NotifSnackClicked,
    {
      action
    },
    {
      context: { groupId: query.data.currentUser?.organization.id }
    }
  );
};

export const trackNotifPermission = async (permission: typeof Notification.permission) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.NotifPermissionAsked,
    {
      permission
    },
    {
      context: { groupId: query.data.currentUser?.organization.id }
    }
  );
};

export const trackNotifClicked = async (url: string) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.NotifClicked,
    {
      url
    },
    {
      context: { groupId: query.data.currentUser?.organization.id }
    }
  );
};

export const trackNotifToggle = async (action: "enabled" | "disabled") => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.NotifSettingToggled,
    {
      action
    },
    {
      context: { groupId: query.data.currentUser?.organization.id }
    }
  );
};

export const track404 = async (pathname: string) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.FourOhFour,
    {
      pathname
    },
    {
      context: { groupId: query.data.currentUser?.organization.id }
    }
  );
};

export const trackRequestPriorityChanged = async (
  from: RequestPriority,
  to: RequestPriority,
  requestId: RequestGet_request["id"]
) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });

  trackToDestinations(
    AnalyticEvents.RequestPriorityChanged,
    {
      priority_from: from,
      priority_to: to,
      request_id: requestId
    },
    {
      context: {
        groupId: query.data.currentUser?.organization.id ?? null
      }
    }
  );
};

const trackSLAAction = (event: AnalyticEvents.SLAAdded | AnalyticEvents.SLAUpdated) => {
  return async (
    sla: Omit<GSettingsSLASGetAll_teamList_SLAs, "__typename" | "createTime" | "categories"> & { categories: any[] },
    team: Omit<GSettingsSLASGetAll_teamList, "__typename" | "createTime">
  ) => {
    const query = await apolloClient.query<CurrentUser>({
      query: CURRENT_USER
    });

    trackToDestinations(
      event,
      {
        id: sla.id,
        organization: {
          id: query.data.currentUser?.organization.id ?? null,
          slug: query.data.currentUser?.organization.slug ?? null
        },
        team: {
          id: team.id,
          slug: team.slug
        },
        number_of_categories: sla.categories.length ?? 0,
        first_response_goal: sla.minutesToFirstResponse ?? 0,
        request_resolution_goal: sla.minutesToResolution ?? 0
      },
      {
        context: {
          groupId: query.data.currentUser?.organization.id ?? null
        }
      }
    );
  };
};

export const trackSLACreated = trackSLAAction(AnalyticEvents.SLAAdded);
export const trackSLAUpdated = trackSLAAction(AnalyticEvents.SLAUpdated);
export const trackSLADeleted = async (
  sla: Omit<GSettingsSLASGetAll_teamList_SLAs, "__typename" | "createTime">,
  team: Omit<GSettingsSLASGetAll_teamList, "__typename" | "createTime">
) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });

  trackToDestinations(
    AnalyticEvents.SLARemoved,
    {
      id: sla.id,
      organization: {
        id: query.data.currentUser?.organization.id ?? null,
        slug: query.data.currentUser?.organization.slug ?? null
      },
      team: {
        id: team.id,
        slug: team.slug
      }
    },
    {
      context: {
        groupId: query.data.currentUser?.organization.id ?? null
      }
    }
  );
};

export const trackViewChanged = async (view_type: ViewType) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.ViewChanged,
    {
      view_type
    },
    {
      context: { groupId: query.data.currentUser?.organization.id ?? null }
    }
  );
};

export const trackDocumentAttached = async (
  doc: { id: string; title: string; source: DocumentSource },
  requestId: string
) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.DocumentAttached,
    {
      request_id: requestId,
      document_title: doc.title,
      document_id: doc.id,
      document_source: doc.source
    },
    {
      context: { groupId: query.data.currentUser?.organization.id ?? null }
    }
  );
};

export const trackSavedReplyUsed = async (
  reply: { id: string; title: string },
  channel: "request" | "outreach",
  requestId?: string
) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.SavedReplyUsed,
    {
      channel,
      request_id: requestId,
      document_title: reply.title,
      document_id: reply.id
    },
    {
      context: { groupId: query.data.currentUser?.organization.id ?? null }
    }
  );
};

export const trackWorkflowConfigNotCompleted = (
  workflow: WorkflowsGetList_teamWorkflows,
  team: { id: string; slug: string },
  currentUser: CurrentUser_currentUser | null
) => {
  trackToDestinations(
    AnalyticEvents.WorkflowConfigurationNotCompleted,
    {
      id: workflow.id,
      organization: {
        id: currentUser?.organization.id ?? null,
        slug: currentUser?.organization.slug ?? null
      },
      team: {
        id: team.id,
        slug: team.slug
      }
    },
    {
      context: {
        groupId: currentUser?.organization.id ?? null
      }
    }
  );
};

export const trackBPGalleryUseCaseFilterChange = async (useCase: string) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });

  trackToDestinations(
    AnalyticEvents.BestPracticeGalleryUseCaseFilterApplied,
    {
      filter: useCase
    },
    {
      context: {
        groupId: query.data.currentUser?.organization.id
      }
    }
  );
};

interface TrackForm {
  form_id: string;
}
interface TrackDocument {
  document_id: string;
}
interface TrackWorkflow {
  workflow_id: string;
}

export const trackBestPracticeSharingIntent = async (params: TrackForm | TrackDocument | TrackWorkflow) => {
  trackToDestinations(AnalyticEvents.BestPracticeSharingIntent, params);
};

export const trackBPAttemptToInstall = async (bestPracticeId: string) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });

  trackToDestinations(
    AnalyticEvents.BestPracticeAddToTeam,
    {
      best_practice: bestPracticeId
    },
    {
      context: {
        groupId: query.data.currentUser?.organization.id
      }
    }
  );
};

export const trackAnalyticsExport = async (
  team: TeamInfo,
  filters: { assignees: number; categories: { id: string; name: string }[]; range: RangeModifier },
  metrics: string
) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.AnalyticsExportDownloaded,
    {
      organization: {
        id: query.data.currentUser?.organization.id,
        slug: query.data.currentUser?.organization.slug
      },
      team: {
        id: team.id,
        slug: team.slug
      },
      filters,
      metrics
    },
    {
      context: {
        groupId: query.data.currentUser?.organization.id
      }
    }
  );
};

export const trackCalendarRangeAction = async (action: string, range: RangeModifier) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.CalendarRangePickerAction,
    {
      organization: {
        id: query.data.currentUser?.organization.id,
        slug: query.data.currentUser?.organization.slug
      },
      action,
      range
    },
    {
      context: {
        groupId: query.data.currentUser?.organization.id
      }
    }
  );
};

export const trackTeamInsightsTeamSwitch = async (
  oldTeamId: string,
  oldTeamName: string,
  newTeamId: string,
  newTeamName: string
) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.TeamInsightsTeamSwitch,
    {
      oldTeamId,
      oldTeamName,
      newTeamId,
      newTeamName
    },
    {
      context: { groupId: query.data.currentUser?.organization.id ?? null }
    }
  );
};

export const trackRequestOpenedFromTeamInsights = async (team_insights_section: teamInsightsSection) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.RequestOpenedFromTeamInsights,
    {
      team_insights_section
    },
    {
      context: { groupId: query.data.currentUser?.organization.id ?? null }
    }
  );
};

export type RequestCollapsedExpandedParams = {
  request_id: string;
  view: string;
  type: "manual" | "auto";
};

const trackRequestCardCollapsed = async (params: RequestCollapsedExpandedParams) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(AnalyticEvents.RequestCardCollapsed, params, {
    context: { groupId: query.data.currentUser?.organization.id ?? null }
  });
};

const trackRequestCardExpanded = async (params: RequestCollapsedExpandedParams) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(AnalyticEvents.RequestCardExpanded, params, {
    context: { groupId: query.data.currentUser?.organization.id ?? null }
  });
};

export const trackRequestCardExpandedCollapsed = (
  shouldExpand: boolean,
  action: {
    userInitiated: boolean;
    requestId: string;
  }
) => {
  let view = "unknown";
  switch (window.location.pathname) {
    case "/view/me":
      view = "me";
      break;
    case "/view/all":
      view = "all";
      break;
    case "/view/archive":
      view = "archive";
      break;
    case "/search":
      view = "search";
      break;
    default:
      if (window.location.pathname.startsWith("/view")) {
        view = "custom";
      }
  }
  if (shouldExpand) {
    trackRequestCardExpanded({
      type: action.userInitiated ? "manual" : "auto",
      request_id: action.requestId,
      view
    });
  } else {
    trackRequestCardCollapsed({
      type: action.userInitiated ? "manual" : "auto",
      request_id: action.requestId,
      view
    });
  }
};

export const trackJiraLinkClick = async (params: { requestId: string; ticketNumber: string }) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.JiraLinkClicked,
    {
      request_id: params.requestId,
      ticket_number: params.ticketNumber
    },
    {
      context: { groupId: query.data.currentUser?.organization.id ?? null }
    }
  );
};

export const trackKBDocMigrate = async (params: { docId: string }) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.KBDocMigrateSavedReply,
    {
      kb_doc_id: params.docId
    },
    {
      context: { groupId: query.data.currentUser?.organization.id ?? null }
    }
  );
};

export const trackConversationDetailOpened = async (params: { conversationId: string }) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.ConversationDetailOpened,
    {
      conversation_id: params.conversationId
    },
    {
      context: { groupId: query.data.currentUser?.organization.id ?? null }
    }
  );
};

type CardEvents = "expanded" | "saved" | "collapsed";
interface SteptrackingParams {
  event: CardEvents;
  workflowId: string;
  branchName: string;
  stepType: string;
  stepIndex: number;
}

export const trackStep = async (params: SteptrackingParams) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  const context = {
    context: { groupId: query.data.currentUser?.organization.id ?? null }
  };
  switch (params.event) {
    case "expanded":
      trackToDestinations(
        AnalyticEvents.StepExpanded,
        {
          workflow_id: params.workflowId,
          branch_name: params.branchName,
          step_type: params.stepType,
          step_index: params.stepIndex.toString()
        },
        context
      );
      break;
    case "saved":
      trackToDestinations(
        AnalyticEvents.StepSaved,
        {
          workflow_id: params.workflowId,
          branch_name: params.branchName,
          step_type: params.stepType,
          step_index: params.stepIndex.toString()
        },
        context
      );
      break;
    case "collapsed":
      trackToDestinations(
        AnalyticEvents.StepCollapsed,
        {
          workflow_id: params.workflowId,
          branch_name: params.branchName,
          step_type: params.stepType,
          step_index: params.stepIndex.toString()
        },
        context
      );
  }
};

interface TriggertrackingParams {
  event: CardEvents;
  workflowId: string;
  triggerType: string;
  triggerIndex: number;
}

export const trackTrigger = async (params: TriggertrackingParams) => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  const context = {
    context: { groupId: query.data.currentUser?.organization.id ?? null }
  };
  switch (params.event) {
    case "expanded":
      trackToDestinations(
        AnalyticEvents.TriggerExpanded,
        {
          workflow_id: params.workflowId,
          trigger_type: params.triggerType,
          trigger_index: params.triggerIndex.toString()
        },
        context
      );
      break;
    case "saved":
      trackToDestinations(
        AnalyticEvents.TriggerSaved,
        {
          workflow_id: params.workflowId,
          trigger_type: params.triggerType,
          trigger_index: params.triggerIndex.toString()
        },
        context
      );
      break;
    case "collapsed":
      trackToDestinations(
        AnalyticEvents.TriggerCollapsed,
        {
          workflow_id: params.workflowId,
          trigger_type: params.triggerType,
          trigger_index: params.triggerIndex.toString()
        },
        context
      );
  }
};

export type KBSuggestionDisplayResult = "ok" | "score_too_low" | "filters_not_match" | "too_many_suggestions";

interface KBSuggestion {
  document_pk: string;
  score: number;
  result: KBSuggestionDisplayResult;
}

export const trackKBSuggestionAnalysis = async (params: {
  total_suggestion_count: number;
  valid_suggestion_count: number;
  suggestions: KBSuggestion[];
  empty_results_reason: EmptyResultsReason | null;
}) => {
  const { data, errors } = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });

  if (!data.currentUser) {
    reportDevError("could not get current user", errors);
    return;
  }

  const { id, organization } = data.currentUser;

  trackToDestinations(
    AnalyticEvents.KBTesterQuerySubmitted,
    {
      ...params,
      user_id: id,
      organization_id: organization.id,
      organization_slug: organization.slug
    },
    {
      context: {
        groupId: organization.id
      }
    }
  );
};

export const trackFormAnswersDownload = async () => {
  const query = await apolloClient.query<CurrentUser>({
    query: CURRENT_USER
  });
  trackToDestinations(
    AnalyticEvents.FormAnswersDownload,
    {
      organization: {
        id: query.data.currentUser?.organization.id,
        slug: query.data.currentUser?.organization.slug
      }
    },
    {
      context: {
        groupId: query.data.currentUser?.organization.id
      }
    }
  );
};
