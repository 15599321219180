import { css } from "@emotion/core";
import { mdiHelpCircle } from "@mdi/js";
import { PureQueryOptions } from "apollo-client";
import { ErrorMessage, Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { useEffect, useReducer, useState } from "react";
import { useMutation } from "react-apollo";
import { AttachmentsDropZone } from "src/App/Attachments/DropZone";
import { AttachmentsMenu } from "src/App/Attachments/Menu";
import { filesReducer } from "src/App/Attachments/reducer";
import { SavedReplyButton } from "src/App/KB/Detail/SavedReplyPicker";
import { FileStack } from "src/App/Requests/DetailView/InputBar/FileStack";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { SelectCreateUsers } from "src/App/User/UserSelect";
import { AbsoluteTooltip, Button, Dialog, FormikFieldGroup, FormikInput, MaterialIcon } from "src/components";
import { SingleSelect } from "src/components/Fields/Select";
import { WarnBeforeUnload } from "src/hooks/useWindowEvents";
import { Modal } from "src/portals/Modal";
import { navigate } from "src/util/router";
import * as yup from "yup";
import { OutreachConversationCreate, OutreachConversationCreateVariables } from "./typings/OutreachConversationCreate";

const user = yup.object({
  id: yup.string(),
  name: yup.string(),
  email: yup.string().required()
});

const validationSchema = yup.object().shape({
  from: yup.string().required(),
  to: yup.array(user).required(),
  cc: yup.array(user),
  subject: yup.string().required("Please provide a message subject to send"),
  body: yup.string().required("Please provide a message body to send")
});

type EmailSelect = {
  id: string;
  name: string;
  email: string;
};

export const ConversationCreateModal: React.FC<{
  isOpen: boolean;
  onDismiss(): void;
  refetchQueries: PureQueryOptions[];
}> = props => {
  const { currentUser } = useCurrentUser();
  const { emitSnack } = useSnack();

  const fromOptions = currentUser?.teams.map(team => ({
    label: `${team.name} <${team.emailAddresses.from}>`,
    value: team.id
  }));
  const [shouldDisplayCC, setShouldDisplayCC] = useState(false);
  const [filesState, filesDispatch] = useReducer(filesReducer, { files: [] });
  // clear files on open / close
  const { isOpen } = props;
  useEffect(() => {
    filesDispatch({
      type: "CLEAR_FILES"
    });
  }, [isOpen, filesDispatch]);
  const [conversationCreate] = useMutation<OutreachConversationCreate, OutreachConversationCreateVariables>(
    gql`
      mutation OutreachConversationCreate($params: ConversationInput!) {
        conversationCreate(params: $params) {
          code
          message
          success
        }
      }
    `,
    {
      awaitRefetchQueries: true,
      refetchQueries: props.refetchQueries
    }
  );
  const handleSubmit = async (form: {
    from: string;
    to: EmailSelect[];
    cc: EmailSelect[];
    subject: string;
    body: string;
  }) => {
    if (!currentUser) return;
    const attachmentIds = filesState.files.filter(f => f.attachmentId).map(f => f.attachmentId as string);
    const res = await conversationCreate({
      variables: {
        params: {
          authorId: currentUser.id,
          teamId: form.from,
          toEmailAddresses: form.to.map(user => user.email),
          ccEmailAddresses: form.cc.map(user => user.email),
          subject: form.subject,
          body: form.body,
          attachmentIds
        }
      }
    });
    if (res.data?.conversationCreate.success) {
      emitSnack({
        type: "info",
        message: "Message sent"
      });
      navigate("/outreach");
    } else {
      emitSnack({
        type: "mutationError",
        message: res.data?.conversationCreate.message ?? "Sorry, there was an error"
      });
    }
  };
  return (
    <Modal alignTop isOpen={props.isOpen} onDismiss={props.onDismiss}>
      <Dialog
        large
        title="Start a new conversation"
        onClose={props.onDismiss}
        shouldWarnBeforeClose
        data-testid="outreach-create-modal"
      >
        <Formik
          initialValues={{
            from: currentUser?.teams[0].id ?? "",
            to: [] as EmailSelect[],
            cc: [] as EmailSelect[],
            subject: "",
            body: ""
          }}
          validationSchema={validationSchema}
          onSubmit={(form, actions) => {
            // The modal closes after submission so no need to reset setSubmitting
            actions.setSubmitting(true);
            handleSubmit(form);
          }}
        >
          {form => (
            <Form
              css={css`
                & > * + * {
                  margin-top: var(--space-4-rem);
                }
              `}
            >
              <WarnBeforeUnload hasChanges={Object.values(form.touched).some(v => v)} />
              <div data-testid="outreach-create-form-team-select">
                <SingleSelect<{ value: string; label: string }>
                  css={css`
                    width: 100%;
                  `}
                  name="from"
                  label="From:"
                  isSearchable={false}
                  defaultValue={fromOptions?.find(o => o.value === form.values.from)}
                  onChange={data => {
                    if (data && !("length" in data)) {
                      form.setFieldValue("from", data.value);
                    }
                  }}
                  options={fromOptions}
                />
              </div>
              <FormikFieldGroup.Container
                legend={
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      & > * + * {
                        margin-left: var(--space-1-rem);
                      }
                    `}
                  >
                    <span>Recipients:</span>
                    <AbsoluteTooltip
                      css={css`
                        color: var(--text-4);
                      `}
                      children={<MaterialIcon path={mdiHelpCircle} size={1} zIndex="highest" />}
                      content={
                        <span>
                          If you include multiple recipients they will receive separate messages and will not see other
                          recipients. They will, however, see everyone in Cc
                        </span>
                      }
                    />
                  </div>
                }
              >
                <div
                  css={css`
                    display: flex;
                    & > * + * {
                      margin-left: var(--space-2-rem);
                    }
                  `}
                >
                  <SelectCreateUsers
                    shouldHideSelectedUserCount
                    shouldCloseMenuOnSelect
                    filterUsers={form.values.cc}
                    selectedUsers={form.values.to}
                    setSelectedUsers={selectedUsers => form.setFieldValue("to", selectedUsers)}
                    data-testid="outreach-create-form-recipients-select"
                  />
                  {!shouldDisplayCC && (
                    <Button onClick={() => setShouldDisplayCC(true)} data-testid="outreach-create-form-addccs-button">
                      Add CC
                    </Button>
                  )}
                </div>
              </FormikFieldGroup.Container>
              {shouldDisplayCC && (
                <FormikFieldGroup.Container legend="Cc:">
                  <SelectCreateUsers
                    shouldHideSelectedUserCount
                    shouldCloseMenuOnSelect
                    filterUsers={form.values.to}
                    selectedUsers={form.values.cc}
                    setSelectedUsers={selectedUsers => form.setFieldValue("cc", selectedUsers)}
                    data-testid="outreach-create-form-ccs-select"
                  />
                </FormikFieldGroup.Container>
              )}
              <div>
                <Field name="subject" component={FormikInput} label="Subject:" hideErrorLabel />
                {form.touched.subject && form.errors.subject && (
                  <FormikFieldGroup.Errors>
                    <ErrorMessage name="subject" />
                  </FormikFieldGroup.Errors>
                )}
              </div>
              <div
                css={[
                  css`
                    padding: 0.5rem 1rem;
                    background-color: var(--white);
                    color: var(--text-6);
                    border-radius: var(--border-radius-s);
                    border: 1px solid var(--text-1);

                    &:hover {
                      border: 1px solid var(--text-6);
                    }

                    &:focus-within {
                      border: 1px solid var(--text-6);
                    }
                  `,
                  form.touched.body &&
                    form.errors.body &&
                    css`
                      border: 1px solid var(--red-7);
                      &:hover {
                        border: 1px solid var(--red-7);
                      }

                      &:focus-within {
                        border: 1px solid var(--red-7);
                      }
                    `
                ]}
              >
                <AttachmentsDropZone filesState={filesState} filesDispatch={filesDispatch} />
                <FileStack filesState={filesState} filesDispatch={filesDispatch} />
                <textarea
                  value={form.values.body}
                  onBlur={e => form.setFieldTouched("body")}
                  onChange={e => form.setFieldValue("body", e.target.value)}
                  css={css`
                    width: 100%;
                    min-height: 10rem;
                    padding: 0.5rem 0;
                    margin: 0;
                    border: none;
                    outline: none;
                    resize: none;
                    font: inherit;
                  `}
                  data-testid="outreach-create-form-messagebody-textarea"
                />
                <div
                  css={css`
                    display: flex;
                    justify-content: flex-end;
                  `}
                >
                  {currentUser && (
                    <SavedReplyButton
                      channel="outreach"
                      onSelect={reply => {
                        if (!form.values.subject) {
                          form.setFieldValue("subject", reply.title);
                        }
                        form.setFieldValue("body", form.values.body + reply.body);
                        filesDispatch({
                          type: "ADD_EXISTING_ATTACHMENTS",
                          attachments: reply.attachments
                        });
                      }}
                      selectedTeam={currentUser.teams.find(t => t.id === form.values.from) ?? currentUser.teams[0]}
                    />
                  )}
                  <AttachmentsMenu
                    resetKey={filesState.files.length}
                    filesState={filesState}
                    filesDispatch={filesDispatch}
                  />
                </div>
              </div>
              {form.touched.body && form.errors.body && (
                <FormikFieldGroup.Errors>{form.errors.body}</FormikFieldGroup.Errors>
              )}
              <div
                css={css`
                  display: flex;
                  justify-content: flex-end;
                  padding-top: var(--space-2-rem);
                  & > * + * {
                    margin-left: var(--space-2-rem);
                  }
                `}
              >
                <Button size="large" onClick={props.onDismiss}>
                  Cancel
                </Button>
                <Button size="large" variant="primary" type="submit" disabled={form.isSubmitting || !form.isValid}>
                  Send
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Modal>
  );
};
