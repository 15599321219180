import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { mdiClose } from "@mdi/js";
import { get } from "lodash";
import * as React from "react";
import Select from "react-select";
import AsyncSelect, { Props as AsyncSelectProps } from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { Props as CreatableProps } from "react-select/creatable";
import { SelectComponentsConfig } from "react-select/src/components";
import { Props as SelectProps } from "react-select/src/Select";
import { StylesConfig } from "react-select/src/styles";
import Check from "src/assets/Check.svg";
import DropdownIndicator from "src/assets/DropdownIndicator.svg";
import { MaterialIcon, SquareButton } from "src/components";
import { stackOrder } from "src/styling/layout";
import zIndices from "src/styling/tokens/z-indices.json";
import { __rawColorValues } from "src/styling/tokens/__colors";
import { csx } from "src/util/csx";
import { ErrorLabel } from ".";
import { FormLabel } from "./FormWrappers";

const select = {
  bg: __rawColorValues.white,
  fg_muted: __rawColorValues.text_1
};

const clearIndicator = {
  bg: __rawColorValues.red_1,
  bg_active: __rawColorValues.red_2,
  fg: __rawColorValues.red_7
};

const multiSelect = {
  bg: __rawColorValues.box
};

const multiSelectRemove = {
  bg: __rawColorValues.hover,
  fg: __rawColorValues.text_3
};

const control = {
  border_color: __rawColorValues.text_1,
  border_color_active: __rawColorValues.text_3
};

const option = {
  fg: __rawColorValues.text_6,
  bg_active: __rawColorValues.lightGrey_3,
  bg_hover: __rawColorValues.lightGrey_2
};

/**
 * special config to override react select styles
 * TODO: reconcile component overrides, different styling strategies
 */
export const selectStyles: StylesConfig = {
  menu: styles => ({
    ...styles,
    margin: 0,
    zIndex: zIndices.high.value
  }),
  clearIndicator: styles => ({
    ...styles,
    position: "absolute",
    top: "0.125rem",
    bottom: "0.125rem",
    right: "0.125rem",
    borderRadius: "var(--border-radius-xs)",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    background: __rawColorValues.green_3,
    color: multiSelectRemove.fg,
    "&:hover": {
      background: clearIndicator.bg,
      color: clearIndicator.fg
    },
    "&:active": {
      background: clearIndicator.bg_active,
      color: clearIndicator.fg
    }
  }),
  container: styles => ({
    ...styles,
    flex: "1 1 auto",
    maxWidth: "100%"
  }),
  control: (styles, state) => ({
    ...styles,
    borderColor: state.isFocused ? control.border_color_active : control.border_color,
    borderRadius: "var(--border-radius-s)",
    boxShadow: "none",
    flexWrap: "nowrap",
    fontSize: "0.875rem",
    minHeight: "2.625rem",
    overflow: "hidden",
    "&:hover": {
      borderColor: __rawColorValues.text_6,
      cursor: "pointer"
    },
    "& > div:first-child": {
      width: "100%"
    }
  }),
  indicatorsContainer: styles => ({
    ...styles,
    position: "absolute",
    right: 0,
    top: 0,
    width: "2.5rem",
    height: "100%",
    justifyContent: "flex-end",
    background: select.bg,
    borderRadius: "var(--border-radius-s)",
    zIndex: stackOrder.high
  }),
  indicatorSeparator: styles => ({
    display: "none"
  }),
  multiValue: styles => ({
    ...styles,
    background: multiSelect.bg,
    height: "2rem",
    alignItems: "center",
    fontSize: "0.875rem",
    margin: "0.25rem 0.75rem 0 -0.5rem",
    padding: "0 0 0 0.5rem",
    borderRadius: "var(--border-radius-xs)"
  }),
  multiValueRemove: styles => ({
    ...styles,
    height: "2rem",
    width: "2rem",
    padding: "0",
    marginLeft: "0.5rem",
    justifyContent: "center",
    cursor: "pointer",
    background: multiSelectRemove.bg,
    color: multiSelectRemove.fg,
    borderRadius: "var(--border-radius-xs)",
    flexShrink: 0,
    "&:hover": {
      background: clearIndicator.bg,
      color: clearIndicator.fg
    },
    "&:active": {
      background: clearIndicator.bg_active,
      color: clearIndicator.fg
    }
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isFocused ? option.bg_hover : "none",
    background: state.isSelected ? `right 16px center / 16px no-repeat url("${Check}")` : undefined,
    paddingRight: state.isSelected ? " 32px" : undefined,
    color: option.fg,
    margin: "0.125rem 0",
    fontSize: "0.875rem",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: option.bg_hover,
      color: option.fg
    }
  }),
  placeholder: () => ({
    color: select.fg_muted,
    whiteSpace: "nowrap",
    width: "0",
    // This hack is there to align the placeholder with the input bar as
    // these elements are stacked next to one another with width: 0
    // We use padding to create the space next to the placeholder into which
    // the input element is then pulled with negative margin.
    paddingLeft: "0.125rem",
    marginRight: "-0.125rem"
  }),
  singleValue: () => ({
    fontSize: "0.875rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "calc(100% - 4.5rem)"
  }),
  valueContainer: styles => ({
    ...styles,
    padding: "0 2rem 0.25rem 0.75rem"
  })
};

export const Indicator = csx(
  [
    css`
  width: 6px;
  height: 4px;
  margin: 0;
  color: var(--text-6);
  background-image: url("${DropdownIndicator}");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`
  ],
  {
    // spacing from right in select option
    selectDropdownIndicator: css`
      margin-right: 1rem;
    `
  }
);

export const SmallSelectText = styled.span`
  font-size: 0.75rem;
`;

interface ISelectProps {
  errorMessage?: string;
  label?: string;
  showError?: boolean;
}

export const AsyncCreateableMultiSelect = <O extends object>(
  props: AsyncSelectProps<O> &
    CreatableProps<O> &
    ISelectProps & { noLabel?: boolean; innerRef?: React.RefObject<AsyncCreatableSelect<O>> }
) => (
  <React.Fragment>
    {props.label && <FormLabel htmlFor={props.name}>{props.label}</FormLabel>}
    <AsyncCreatableSelect
      {...props}
      ref={props.innerRef}
      className={`select-container ${props.className}`}
      classNamePrefix="select"
      cacheOptions={true}
      styles={selectStyles}
      components={{ DropdownIndicator: () => <Indicator selectDropdownIndicator />, ...props.components }}
    />
    {!props.noLabel && <ErrorLabel>{props.errorMessage}</ErrorLabel>}
  </React.Fragment>
);

type SingleSelectProps<O extends object> = SelectProps<O> & {
  errorMessage?: string;
  label?: string;
  showError?: boolean;
};

interface RemovableSingleSelectProps<O extends object> extends SingleSelectProps<O> {
  onRemove(): void;
}

export const SingleSelect = <O extends object>(props: SingleSelectProps<O> | RemovableSingleSelectProps<O>) => {
  const { showError = true } = props;

  return (
    <React.Fragment>
      {props.label && <FormLabel htmlFor={props.name}>{props.label}</FormLabel>}
      <Select
        {...props}
        styles={selectStyles}
        className={`select-container ${props.className ? props.className : ""}`}
        classNamePrefix="select"
        components={{
          DropdownIndicator: ({ hasValue }) =>
            hasValue && props.onRemove ? null : <Indicator selectDropdownIndicator />,
          ClearIndicator: () =>
            props.onRemove ? (
              <SquareButton
                // don't trigger dropdown
                onMouseDown={e => e.stopPropagation()}
                onClick={() => props.onRemove(null)}
                css={css`
                  margin-right: var(--space-2-rem);
                `}
              >
                <MaterialIcon path={mdiClose} size={1.25} />
              </SquareButton>
            ) : null,
          ...props.components
        }}
      />
      {showError && <ErrorLabel>{props.errorMessage}</ErrorLabel>}
    </React.Fragment>
  );
};

const SelectValue = styled.div<{ height?: string }>`
  display: flex;
  align-items: center;
  line-height: ${p => (p.height ? p.height : "1.5rem")};

  & .icon {
    color: var(--border);
  }

  & .placeholder {
    width: 0;
    white-space: nowrap;
  }
`;

const NoOptionsMessage = styled.div<{}>`
  width: 100%;
  padding: 0.8rem 0;
  text-align: center;
  font-size: 1rem;
  color: var(--text-4);
`;

export const getIconComponents = <O extends object>(props: SelectProps<O>): SelectComponentsConfig<O> => ({
  DropdownIndicator: () => <Indicator selectDropdownIndicator />,
  NoOptionsMessage: (params): React.ReactElement => {
    const inputValue = params.selectProps.inputValue;
    const message = inputValue ? `Name '${inputValue}' not found!` : `Start typing a name...`;
    return <NoOptionsMessage>{message}</NoOptionsMessage>;
  },
  ValueContainer: (params): React.ReactElement => {
    const selected = params.getValue();
    return (
      <SelectValue>
        {
          // this renders a spacer on the left of the input if there is no icon

          !props.icon && (
            <div
              css={css`
                width: var(--space-2-rem);
              `}
            />
          )
        }
        {props.getIcon && props.getIcon(get(selected, "[0].value"))}
        {!props.getIcon && props.icon && <MaterialIcon path={props.icon} size={1.125} margin="0 0.5rem" />}
        {params.children}
      </SelectValue>
    );
  }
});

interface IIconSelectProps {
  icon?: string;
  iconColor?: string;
  getIcon?: (value?: any) => any;
  height?: string;
}

export const SingleSelectWithIcon = <O extends object>(props: SelectProps<O> & ISelectProps & IIconSelectProps) => (
  <React.Fragment>
    <SingleSelect
      {...props}
      className={`select-container ${props.className}`}
      classNamePrefix="select"
      styles={selectStyles}
      components={getIconComponents(props)}
    />
  </React.Fragment>
);

export const AsyncSingleSelectWithIcon = <O extends object>(
  props: AsyncSelectProps<O> & ISelectProps & IIconSelectProps
) => (
  <React.Fragment>
    {props.label && <FormLabel htmlFor={props.name}>{props.label}</FormLabel>}
    <AsyncSelect
      {...props}
      className={`select-container ${props.className}`}
      classNamePrefix="select"
      cacheOptions={false}
      defaultOptions={true}
      loadOptions={props.loadOptions}
      styles={selectStyles}
      components={{ ...getIconComponents(props), ...props.components }}
    />
    <ErrorLabel>{props.errorMessage}</ErrorLabel>
  </React.Fragment>
);

export const AsyncCreatableSingleSelectWithIcon = <O extends object>(
  props: AsyncSelectProps<O> & CreatableProps<O> & ISelectProps & IIconSelectProps
) => (
  <React.Fragment>
    {props.label && <FormLabel htmlFor={props.name}>{props.label}</FormLabel>}
    <AsyncCreatableSelect
      {...props}
      className={`select-container ${props.className}`}
      classNamePrefix="select"
      cacheOptions={false}
      defaultOptions={props.defaultOptions ?? true}
      loadOptions={props.loadOptions}
      styles={selectStyles}
      components={{ ...getIconComponents(props), ...props.components }}
    />
    <ErrorLabel>{props.errorMessage}</ErrorLabel>
  </React.Fragment>
);
