import { css } from "@emotion/core";
import { mdiClose, mdiLock } from "@mdi/js";
import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { get } from "lodash";
import React, { useState } from "react";
import { MutationTuple, useApolloClient, useMutation, useQuery } from "react-apollo";
import AsyncSelect from "react-select/async-creatable";
import { MultiValueProps } from "react-select/src/components/MultiValue";
import { StylesConfig } from "react-select/src/styles";
import { AssignGroups, AssignGroupsVariables } from "src/App/Requests/DetailView/typings/AssignGroups";
import { FeatureFlags, useFeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import Okta from "src/assets/logos/OktaCircle.svg";
import {
  Dialog,
  FormikFieldGroup,
  LoadingBar,
  MaterialIcon,
  Row,
  SimpleTooltip,
  SquareButton,
  SubmitButton
} from "src/components";
import { ConfirmDialogModal } from "src/components/Dialogs";
import { sharedMultiSelectStylz } from "src/components/Fields/MultiSelect";
import { OptionMap, PopOver } from "src/components/PopOver";
import { OktaAppAssignmentScope } from "src/globalTypes";
import { Modal } from "src/portals/Modal";
import { justify } from "src/styling/primitives";
import { Typo } from "src/styling/primitives/typography";
import zIndices from "src/styling/tokens/z-indices.json";
import { __rawColorValues } from "src/styling/tokens/__colors";
import { openNewTab } from "src/util";
import { capitalizeFirst } from "src/util/formatters";
import { AssignApps, AssignAppsVariables } from "./typings/AssignApps";
import { OktaApps } from "./typings/OktaApps";
import { OktaGroups, OktaGroups_oktaGroups } from "./typings/OktaGroups";
import {
  OktaProfileInfo,
  OktaProfileInfoVariables,
  OktaProfileInfo_oktaUser,
  OktaProfileInfo_oktaUser_apps
} from "./typings/OktaProfileInfo";

interface MutationResponse {
  __typename: "Response";
  success: boolean;
  message: string;
}

const SEARCH_GROUPS = gql`
  query OktaGroups($query: String!) {
    oktaGroups(query: $query) {
      id
      name
    }
  }
`;

const ASSIGN_GROUPS = gql`
  mutation AssignGroups($userId: String!, $groupIds: [String!]!) {
    oktaSetUserGroups(params: { userId: $userId, groupIds: $groupIds }) {
      success
      message
    }
  }
`;

const SEARCH_APPS = gql`
  query OktaApps($query: String!) {
    oktaApps(query: $query) {
      id
      label
    }
  }
`;

const ASSIGN_APPS = gql`
  mutation AssignApps($userId: String!, $appIds: [String!]!) {
    oktaSetUserApps(params: { userId: $userId, appIds: $appIds }) {
      success
      message
    }
  }
`;

const OKTA_PROFILE_INFO = gql`
  query OktaProfileInfo($email: String!) {
    integrationOkta {
      domain
    }
    oktaUser(email: $email) {
      id
      status
      createTime
      activateTime
      statusChangeTime
      lastLoginTime
      updateTime
      passwordChangeTime
      hasMFA
      firstName
      lastName
      email
      groups {
        id
        name
      }
      apps {
        app {
          id
          label
          logoUrl
        }
        scope
      }
    }
  }
`;

type OktaUserMutationResponse<MutationName extends string> = Record<MutationName, MutationResponse>;

interface OktaUserMutationVariables {
  userId: string;
}

function useOktaUserMutation<MutationName extends string>(
  mutationName: MutationName,
  userId: string,
  successMessage: string,
  onCompleted: () => void
): MutationTuple<OktaUserMutationResponse<MutationName>, OktaUserMutationVariables> {
  const { emitSnack } = useSnack();

  return useMutation<OktaUserMutationResponse<MutationName>, OktaUserMutationVariables>(
    gql`
      mutation ${capitalizeFirst(mutationName as string)}($userId: String!) {
        ${mutationName}(params: {userId: $userId}) { success message }
      }
    `,
    {
      variables: { userId: userId },
      onCompleted: response => {
        if (response[mutationName].success) {
          emitSnack({ message: successMessage, type: "info" });
        } else {
          emitSnack({ message: response[mutationName].message, type: "mutationError" });
        }

        onCompleted();
      }
    }
  );
}

interface OktaLifecycleChange {
  id: string;
  name: string;
  confirmMessage: string | JSX.Element;
  mutationName: string;
  successMessage: string;
  onlyIf?: (p: OktaProfileInfo_oktaUser) => boolean;
}

function oktaUserHasStatus(u: OktaProfileInfo_oktaUser, ...statuses: string[]): boolean {
  return statuses.findIndex(x => x === u.status) !== -1;
}

const lifecycleChanges: OktaLifecycleChange[] = [
  {
    id: "account-reset-factors",
    name: "Reset multifactor",
    confirmMessage:
      "This will wipe away the credentials for all configured factors and allow users to set up their factors again.",
    mutationName: "oktaResetUserFactors",
    successMessage: "User multifactor reset",
    onlyIf: p => p.hasMFA
  },
  {
    id: "account-clear-sessions",
    name: "Clear user sessions",
    confirmMessage: (
      <>
        This action will:
        <ul>
          <li>Clear sessions, signing out the user from any active session across all devices.</li>
          <li>Revoke Okta Mobile tokens, signing out the user from Okta Mobile and managed apps across all devices.</li>
          <li>Revoke refresh tokens, forcing all OpenID Connect and OAuth clients to re-request access.</li>
        </ul>
      </>
    ),
    mutationName: "oktaClearUserSessions",
    successMessage: "User sessions cleared"
  },
  {
    id: "activate-user",
    name: "Activate user",
    confirmMessage: "Are you sure you want to activate the user?",
    mutationName: "oktaActivateUser",
    successMessage: "User activated",
    onlyIf: p => oktaUserHasStatus(p, "STAGED", "DEPROVISIONED")
  },
  {
    id: "reactivate-user",
    name: "Reactivate user",
    confirmMessage: (
      <>
        <p>This operation restarts the activation workflow if for some reason the user activation was not completed.</p>
        <p>
          Users that don't have a password must complete the flow by completing Reset Password and MFA enrollment steps
          to become active.
        </p>
      </>
    ),
    mutationName: "oktaReactivateUser",
    successMessage: "User reactivated",
    onlyIf: p => oktaUserHasStatus(p, "PROVISIONED")
  },
  {
    id: "deactivate-user",
    name: "Deactivate user",
    confirmMessage:
      "Deactivating a user is a destructive operation. The user is deprovisioned from all assigned applications which may destroy their data such as email or files. This action cannot be recovered!",
    mutationName: "oktaDeactivateUser",
    successMessage: "User deactivated",
    onlyIf: p => !oktaUserHasStatus(p, "DEPROVISIONED")
  },
  {
    id: "suspend-user",
    name: "Suspend user",
    confirmMessage: (
      <>
        Suspended users:
        <ul>
          <li>Can't log in to Okta. Their group and app assignments are retained.</li>
          <li>Can only be unsuspended or deactivated</li>
        </ul>
      </>
    ),
    mutationName: "oktaSuspendUser",
    successMessage: "User suspended",
    onlyIf: p => oktaUserHasStatus(p, "ACTIVE")
  },
  {
    id: "unsuspend-user",
    name: "Unsuspend user",
    confirmMessage: "Are you sure you want to unsuspend the user?",
    mutationName: "oktaUnsuspendUser",
    successMessage: "User unsuspended",
    onlyIf: p => oktaUserHasStatus(p, "SUSPENDED")
  },
  {
    id: "unlock-user",
    name: "Unlock user",
    confirmMessage: (
      <>
        <p>This unlocks a locked out user so that they can login again with their current password.</p>
        <p>
          Note: This operation works with Okta-mastered users. It doesn't support directory-mastered accounts such as
          Active Directory.
        </p>
      </>
    ),
    mutationName: "oktaUnlockUser",
    successMessage: "User unlocked",
    onlyIf: p => oktaUserHasStatus(p, "LOCKED_OUT")
  }
];

const LifecycleChangeModal: React.FC<{
  onDismiss: () => void;
  lifecycleChange: OktaLifecycleChange;
  oktaProfile: OktaProfileInfo_oktaUser;
}> = props => {
  const [mutation, mutationResponse] = useOktaUserMutation(
    props.lifecycleChange.mutationName,
    props.oktaProfile.id,
    props.lifecycleChange.successMessage,
    props.onDismiss
  );

  return (
    <ConfirmDialogModal
      isOpen={true}
      text={{
        cancel: "Cancel",
        confirm: "Continue",
        heading: props.lifecycleChange.name
      }}
      submittingConfirm={mutationResponse.loading}
      handleCancel={props.onDismiss}
      handleConfirm={() => {
        mutation();
      }}
    >
      {mutationResponse.loading && <LoadingBar />}

      <div>{props.lifecycleChange.confirmMessage}</div>
    </ConfirmDialogModal>
  );
};

interface OktaAssignment<SearchResponseType, SelectOptionType, MutationResponseType, MutationVariablesType> {
  id: "groups-assign" | "apps-assign";
  name: string;
  description: string;
  label: string;
  search: DocumentNode;
  getSearchResponse: (response: SearchResponseType) => SelectOptionType[];
  mutation: DocumentNode;
  mutationVariables: (userId: string, selectedItems: SelectOptionType[]) => MutationVariablesType;
  getMutationResponse: (response: MutationResponseType) => MutationResponse;
  initialItems: (profile: OktaProfileInfo_oktaUser) => SelectOptionType[];
  fixedItems: (profile: OktaProfileInfo_oktaUser) => SelectOptionType[];
  successMessage: string;
  errorMessage: string;
}

type SelectOption = { id: string; label: string };
type OktaGroupsAssignment = OktaAssignment<OktaGroups, SelectOption, AssignGroups, AssignGroupsVariables>;
type OktaAppsAssignment = OktaAssignment<OktaApps, SelectOption, AssignApps, AssignAppsVariables>;

const mapGroups = (groups: OktaGroups_oktaGroups[]): SelectOption[] => {
  return groups.map(g => ({ id: g.id, label: g.name }));
};

const mapAppAssignments = (appAssignments: OktaProfileInfo_oktaUser_apps[]): SelectOption[] => {
  return appAssignments.map(a => a.app);
};

const assignments = [
  {
    id: "groups-assign",
    name: "Manage groups",
    description:
      "Add and remove user from Okta groups. Groups with the lock symbol next to them are default ones and the user cannot be removed from them.",
    label: "Groups",
    search: SEARCH_GROUPS,
    getSearchResponse: x => mapGroups(x.oktaGroups),
    mutation: ASSIGN_GROUPS,
    mutationVariables: (userId, selected) => ({ userId, groupIds: selected.map(x => x.id) }),
    getMutationResponse: x => x.oktaSetUserGroups,
    initialItems: p => mapGroups(p.groups),
    fixedItems: p => mapGroups(p.groups.filter(g => g.name === "Everyone")),
    successMessage: "Groups updated",
    errorMessage: "Error updating groups"
  } as OktaGroupsAssignment,
  {
    id: "apps-assign",
    name: "Manage apps",
    description:
      "Assign user to Okta apps or remove individual app assignments. Apps with the lock symbol next to them are assigned through group membership and cannot be removed from here.",
    label: "Apps",
    search: SEARCH_APPS,
    getSearchResponse: x => x.oktaApps,
    mutation: ASSIGN_APPS,
    mutationVariables: (userId, selected) => ({ userId, appIds: selected.map(x => x.id) }),
    getMutationResponse: x => x.oktaSetUserApps,
    initialItems: p => mapAppAssignments(p.apps),
    fixedItems: p =>
      mapAppAssignments(p.apps.filter(a => a.scope === OktaAppAssignmentScope.APPLICATION_ASSIGNMENT_SCOPE_GROUP)),
    successMessage: "Apps updated",
    errorMessage: "Error updating apps"
  } as OktaAppsAssignment
];

const AssignModal: React.FC<{
  onDismiss: () => void;
  assignment: OktaGroupsAssignment | OktaAppsAssignment;
  oktaProfile: OktaProfileInfo_oktaUser;
}> = props => {
  const apolloClient = useApolloClient();
  const { emitSnack } = useSnack();
  const [assign, assignResponse] = useMutation(props.assignment.mutation, {
    onCompleted: response => {
      const mutationResponse = props.assignment.getMutationResponse(response);
      if (mutationResponse.success) {
        emitSnack({ message: props.assignment.successMessage, type: "info" });
        props.onDismiss();
      } else {
        emitSnack({ message: `${props.assignment.errorMessage}: ${mutationResponse.message}`, type: "mutationError" });
      }
    },
    refetchQueries: [{ query: OKTA_PROFILE_INFO, variables: { email: props.oktaProfile.email } }]
  });
  const fixedItemsIds = props.assignment.fixedItems(props.oktaProfile).map(i => i.id);
  const sortItems = (items: SelectOption[]) => {
    items = items.sort((a, b) => a.label.localeCompare(b.label));
    const sortedFixed = items.filter(i => fixedItemsIds.includes(i.id));
    const sortedVariable = items.filter(i => !fixedItemsIds.includes(i.id));
    return [...sortedFixed, ...sortedVariable];
  };

  const [items, setItems] = useState(sortItems(props.assignment.initialItems(props.oktaProfile)));

  const multiSelectStyles: StylesConfig = {
    ...sharedMultiSelectStylz,
    multiValue: (base, state) => {
      return {
        ...sharedMultiSelectStylz.multiValue?.(base, state),
        display: "flex",
        alignItems: "center",
        ...(fixedItemsIds.includes(state.data.id) ? { backgroundColor: __rawColorValues.lightGrey_3 } : {})
      };
    },
    multiValueLabel: (base, state) => {
      return {
        ...sharedMultiSelectStylz.multiValueLabel?.(base, state),
        paddingRight: fixedItemsIds.includes(state.data.id) ? "0.5rem" : "0.1875rem"
      };
    }
  };

  return (
    <Modal isOpen={true} onDismiss={props.onDismiss}>
      <Dialog title={props.assignment.name} onClose={props.onDismiss} medium>
        {assignResponse.loading && <LoadingBar />}
        <Typo.Body
          css={css`
            margin-bottom: var(--space-3-rem);
          `}
        >
          {props.assignment.description}
        </Typo.Body>
        <FormikFieldGroup.Container legend={`Assigned ${props.assignment.label}`}>
          <AsyncSelect
            autoFocus={true}
            isMulti={true}
            loadOptions={async (query: string) => {
              const { data } = await apolloClient.query({ query: props.assignment.search, variables: { query } });
              return props.assignment.getSearchResponse(data);
            }}
            value={items}
            components={{
              MultiValueRemove: (props: MultiValueProps<SelectOption>) => {
                return fixedItemsIds.includes(props.data.id) ? (
                  <div
                    css={css`
                      padding: 0.25rem;
                      padding-left: 0;
                    `}
                  >
                    <MaterialIcon path={mdiLock} size={1} />
                  </div>
                ) : (
                  <SquareButton
                    {...props.innerProps}
                    variant="ghost"
                    size="small"
                    type="button"
                    css={css`
                      padding-left: 0.125rem;
                    `}
                  >
                    <MaterialIcon path={mdiClose} size={1} />
                  </SquareButton>
                );
              }
            }}
            noOptionsMessage={input => {
              return input.inputValue.length > 0
                ? `No ${props.assignment.label.toLowerCase()} matching your search`
                : "Start typing to search";
            }}
            onChange={(value, actionMeta) => {
              let newItems;
              switch (actionMeta.action) {
                case "remove-value":
                case "pop-value":
                  const removedValueId = get(actionMeta, "removedValue.id", "");
                  if (fixedItemsIds.includes(removedValueId)) {
                    return;
                  } else {
                    newItems = items.filter(i => i.id !== removedValueId);
                  }
                  break;
                case "select-option":
                  newItems = value;
              }
              setItems(Array.isArray(newItems) ? sortItems(newItems) : []);
            }}
            isClearable={false}
            getOptionLabel={o => o.label}
            getOptionValue={o => o.id}
            isValidNewOption={() => false /* never show the "create" option */}
            styles={multiSelectStyles}
          />
        </FormikFieldGroup.Container>

        <Row
          css={[
            justify.end,
            css`
              margin-top: var(--space-4-rem);
            `
          ]}
        >
          <SubmitButton
            disabled={assignResponse.loading}
            onClick={() => {
              assign({ variables: props.assignment.mutationVariables(props.oktaProfile.id, items) });
            }}
          >
            Save
          </SubmitButton>
        </Row>
      </Dialog>
    </Modal>
  );
};

export const OktaPopupMenu: React.FC<{
  oktaDomain: string | null | undefined;
  oktaProfile: OktaProfileInfo_oktaUser | null | undefined;
}> = ({ oktaDomain, oktaProfile }) => {
  const tooltipText = oktaProfile ? "Okta actions" : "User not found in Okta";
  const options: {
    headline: string;
    options: OptionMap[];
  }[] = [
    {
      headline: "Account",
      options: lifecycleChanges.filter(c => c.onlyIf === undefined || (oktaProfile && c.onlyIf(oktaProfile)))
    },
    {
      headline: "Groups & Apps",
      options: assignments
    },
    {
      headline: "More…",
      options: [{ id: "open-okta", name: "Open profile in Okta" }]
    }
  ];
  const [currentLifecycleChange, setCurrentLifecycleChange] = useState<OktaLifecycleChange>();
  const [currentAssignment, setCurrentAssignment] = useState<OktaAppsAssignment | OktaGroupsAssignment>();

  return (
    <>
      <PopOver.Menu
        disableTrigger={!oktaProfile}
        trigger={
          <SimpleTooltip zIndex={zIndices.higher.value} label={tooltipText}>
            <SquareButton className="print-hidden" variant="secondary" disabled={!oktaProfile} size="small">
              <img
                src={Okta}
                css={css`
                  height: 1em;
                  width: 1em;
                `}
                alt="Okta logo"
              />
            </SquareButton>
          </SimpleTooltip>
        }
        options={options}
        onSelect={(selected: string | null) => {
          setCurrentLifecycleChange(lifecycleChanges.find(x => x.id === selected));
          setCurrentAssignment(assignments.find(x => x.id === selected));

          if (selected === "open-okta") {
            openNewTab(`https://${oktaDomain}-admin.okta.com/admin/user/profile/view/${oktaProfile?.id}`);
          }
        }}
      />
      {currentLifecycleChange && oktaProfile && (
        <LifecycleChangeModal
          onDismiss={() => {
            setCurrentLifecycleChange(undefined);
          }}
          lifecycleChange={currentLifecycleChange}
          oktaProfile={oktaProfile}
        />
      )}
      {currentAssignment && oktaProfile && (
        <AssignModal
          assignment={currentAssignment}
          oktaProfile={oktaProfile}
          onDismiss={() => {
            setCurrentAssignment(undefined);
          }}
        />
      )}
    </>
  );
};

export function useOktaProfile(userEmail: string | undefined) {
  const { hasFeatureFlags } = useFeatureFlags();
  const oktaUserProfile = useQuery<OktaProfileInfo, OktaProfileInfoVariables>(OKTA_PROFILE_INFO, {
    skip: !hasFeatureFlags(FeatureFlags.OKTA) || !userEmail,
    variables: { email: userEmail ?? "" /* actually always defined since we skip else above */ }
  });

  return oktaUserProfile;
}
