import gql from "graphql-tag";

export const PROJECT_FRAGMENT = gql`
  fragment ProjectFragment on Request {
    id
    title
    requestedAt
    status
    author {
      id
      name
      email
    }
    team {
      id
      name
    }
    projectRequestCounts {
      status
      count
    }
  }
`;

export const PROJECT_INFO_FRAGMENT = gql`
  fragment ProjectInfoFragment on Request {
    id
    title
    status
  }
`;
