import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { get } from "lodash";
import * as React from "react";
import { PopperProps } from "react-popper";
import { ReactComponent as DropdownIndicator } from "src/assets/DropdownIndicator.svg";
import { RequestStatus } from "src/globalTypes";
import { MODAL_Z_INDEX } from "src/portals/Modal";
import { SC, StyleVariant } from "src/styling";
import { transitions } from "src/styling/effects";
import { stackOrder } from "src/styling/layout";
import { fontSizes, fontWeights } from "src/styling/typography";
import { csx } from "src/util/csx";
import { EllipsisText, ICommonStyleProps, MaterialIcon, Row, UnderlineText } from ".";
import { Indicator } from "./Fields/Select";
import { PopOver } from "./PopOver";

export const ButtonDimensions = {
  small: {
    lineHeight: "1.5rem",
    padding: "0 0.375rem",
    combinedHeight: "1.5rem"
  },
  medium: {
    lineHeight: "1.5rem",
    padding: "0.25rem 0.625rem",
    combinedHeight: "2rem"
  },
  large: {
    lineHeight: "1.5rem",
    padding: "0.5rem 0.875rem",
    combinedHeight: "2.5rem"
  }
};

type ButtonSize = keyof typeof ButtonDimensions;

const buttonDimensions = (size: ButtonSize) => css`
  padding: ${ButtonDimensions[size].padding};
  line-height: ${ButtonDimensions[size].lineHeight};
`;

export const button = {
  ghost: css`
    color: var(--text-6);
    background: rgba(var(--white-raw), 0);
    border-color: var(--lightGrey-3);

    &:active,
    &:focus {
      background: var(--lightGrey-3);
    }

    &:not([disabled]):not(.disabled):hover {
      background: rgba(var(--text-6-raw), 0.08);
    }
  `,
  primary: css`
    color: var(--blue-5);
    background: var(--lightBlue-2);
    border-color: rgba(var(--blue-6-raw), 0.5);

    &:active,
    &:focus {
      background: var(--lightBlue-4);
    }

    &:not([disabled]):not(.disabled):hover {
      background: var(--lightBlue-3);
    }
  `,
  secondary: css`
    color: var(--text-6);
    background: var(--lightGrey-2);
    border-color: rgba(var(--lightGrey-4-raw), 0.5);

    &:active,
    &:focus {
      background: var(--lightGrey-4);
    }

    &:not([disabled]):not(.disabled):hover {
      background: var(--lightGrey-3);
    }
  `,
  green: css`
    color: var(--green-8);
    background: var(--green-1);
    border-color: rgba(var(--green-7-raw), 0.5);

    &:active,
    &:focus {
      background: var(--green-3);
    }

    &:not([disabled]):not(.disabled):hover {
      background: var(--green-3);
    }
  `,
  yellow: css`
    color: var(--yellow-9);
    background: var(--yellow-1);
    border-color: rgba(var(--yellow-7-raw), 0.5);

    &:active,
    &:focus {
      background: var(--yellow-3);
    }

    &:not([disabled]):not(.disabled):hover {
      background: var(--yellow-2);
    }
  `,
  red: css`
    color: var(--red-7);
    background: var(--red-1);
    border-color: rgba(var(--red-7-raw), 0.5);

    &:active,
    &:focus {
      background: var(--red-3);
    }

    &:not([disabled]):not(.disabled):hover {
      background: var(--red-2);
    }
  `,
  darkBlue: css`
    color: var(--white);
    background: var(--blue-7);
    transition: box-shadow 0.1s ease-out;

    &:active,
    &:focus,
    &:not([disabled]):not(.disabled):hover {
      box-shadow: var(--box-shadow-darkBlueButtonHover);
    }
  `
};

export type ButtonVariant = keyof typeof button;
export interface ButtonProps extends ICommonStyleProps {
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  size?: ButtonSize;
  children?: React.ReactNode;
  disabled?: boolean;
  variant?: ButtonVariant;
}

const buttonDisabled = `
  opacity: 0.6;
  cursor: not-allowed;
`;

/**
 * Use this component to align buttons on the right in modals
 */
export const ButtonBar = csx(
  [
    css`
      display: flex;
      justify-content: flex-end;
      & > * + * {
        margin-left: var(--space-2-rem);
      }
    `
  ],
  {}
);

export const Button = styled.button<ButtonProps>`
  display: ${p => p.display || "flex"};
  box-sizing: border-box;
  flex: ${p => p.flex || "0 0 auto"};
  justify-content: center;
  align-items: center;
  ${p =>
    p.margin &&
    css`
      margin: ${p.margin};
    `}
  font-size: ${p => p.fontSize || "0.875rem"};
  font-weight: ${fontWeights.regular};
  border: none;
  border-radius: var(--border-radius-s);
  cursor: pointer;
  transition: background ${transitions.colors}, color ${transitions.colors};

  &:active,
  &:focus {
    outline: none;
  }

  &[disabled],
  &.disabled {
    ${buttonDisabled}
  }

  ${p => button[p.variant || "ghost"]}
  ${p => buttonDimensions(p.size || "medium")}
`;

/* NB: deprecated in React */
Button.defaultProps = {
  type: "button"
};

/** Force disable button
 * • allow mouse events for tooltips
 * • show cursor: not-allowed
 *  */
export const DisableButton: React.FC<{ disabled?: boolean }> = props =>
  !props.disabled ? (
    <>{props.children}</>
  ) : (
    <div
      css={css`
        pointer-events: all;
        cursor: not-allowed;

        & button {
          pointer-events: none;
          ${buttonDisabled}
        }
      `}
      onClick={e => e.stopPropagation()}
    >
      {props.children}
    </div>
  );

export const SquareButton = styled(Button)`
  width: ${p => ButtonDimensions[p.size || "medium"].combinedHeight};
  height: ${p => ButtonDimensions[p.size || "medium"].combinedHeight};
`;

const SplitButton = styled(Button)`
  border-radius: var(--border-radius-s) 0 0 var(--border-radius-s);
`;

const SplitButtonMenuTrigger = styled(Button)`
  border-radius: 0 var(--border-radius-s) var(--border-radius-s) 0;
  border-left: 1px solid rgba(var(--green-8-raw), 0.25);
  height: 100%;
`;

export const SubmitButton = (props: ButtonProps & { onClick?(): void }) => (
  <Button {...props} variant={props.variant || "primary"} size={props.size || "large"} type="submit">
    {props.children}
  </Button>
);

export const CancelButton = (props: ButtonProps & { onClick?(): void }) => (
  <Button {...props} margin="0 0.25rem 0 0" size="large" onClick={props.onClick}>
    {props.children || "Cancel"}
  </Button>
);

export const DangerButton = (props: ButtonProps & { onClick?(): void }) => (
  <Button {...props} variant={props.variant || "red"} size="large" onClick={props.onClick}>
    {props.children}
  </Button>
);

export const SplitSubmitButton = (props: ButtonProps & { onClick?(): void; label: string }) => (
  <Row>
    <SplitButton {...props} type="submit" onClick={props.onClick}>
      {props.label}
    </SplitButton>
    <PopOver.Blank
      placement="top-end"
      disabled={props.disabled}
      zIndex={MODAL_Z_INDEX + stackOrder.high}
      button={
        <SplitButtonMenuTrigger
          {...props}
          margin="0"
          onClick={() => {
            return false;
          }}
        >
          <Indicator />
        </SplitButtonMenuTrigger>
      }
    >
      {props.children}
    </PopOver.Blank>
  </Row>
);

/**
 * Button to display a clickable icon w/ flexbox styling
 * @deprecated
 */
export const IconButton = styled.button<ICommonStyleProps & { size?: number; shadow?: boolean }>`
  display: flex;
  flex: ${p => p.flex || "0 0 auto"};
  align-items: ${p => p.align || "center"};
  align-self: ${p => p.alignSelf || "normal"};
  font-size: ${p => (p.size ? p.size : 0.875)}rem;
  font-weight: ${fontWeights.bold};
  color: var(--text-6);
  background: none;
  border-radius: 0.25rem;
  border-width: 0;
  padding: ${p => p.padding || "0.5rem 0.75rem"};
  margin: ${p => p.margin || "0"};
  cursor: pointer;
  outline: none;

  &:hover {
    background: none;
  }

  & .icon {
    cursor: pointer;
  }

  & path {
    fill: currentColor;
  }
`;

export const pillButtonVariants = {
  dueDateUrgent: css`
    background: var(--red-1);
    color: var(--red-7);

    &:hover {
      background: var(--red-2);
    }

    &:active {
      background: var(--red-3);
    }
  `,
  red: css`
    background: var(--flesh-2);
    color: var(--red-7);

    &:hover {
      background: var(--flesh-3);
    }

    &:active {
      background: var(--flesh-4);
    }
  `,
  blue: css`
    background: var(--lightBlue-1);
    color: var(--blue-5);

    &:hover {
      background: var(--lightBlue-2);
    }

    &:active {
      background: var(--lightBlue-3);
    }
  `,
  violet: css`
    background: var(--violet-1);
    color: var(--violet-7);

    &:hover {
      background: var(--violet-2);
    }

    &:active {
      background: var(--violet-3);
    }
  `,
  green: css`
    background: var(--green-1);
    color: var(--green-8);

    &:hover {
      background: var(--green-2);
    }

    &:active {
      background: var(--green-3);
    }
  `,
  highUrgency: css`
    background: var(--text-4);
    color: var(--white);

    &:hover {
      background: var(--text-5);
    }

    &:active {
      background: var(--text-6);
    }
  `,
  mediumUrgency: css`
    background: var(--lightGrey-3);
    color: var(--black);

    &:hover {
      background: var(--lightGrey-4);
    }

    &:active {
      background: var(--lightGrey-5);
    }
  `,
  small: css`
    line-height: 1.5rem;
    height: 1.5rem;
    font-size: var(--font-size-helper);
  `,
  selected: css`
    background: var(--border);
  `
};

export const pillVariantByStatus = (status: RequestStatus) => {
  switch (status) {
    case RequestStatus.NOTSTARTED:
      return pillButtonVariants.red;
    case RequestStatus.INPROGRESS:
      return pillButtonVariants.violet;
    case RequestStatus.WAITING:
      return pillButtonVariants.red;
    case RequestStatus.RESOLVED:
      return pillButtonVariants.green;
    case RequestStatus.UNSETREQUESTSTATUS:
      return pillButtonVariants.red;
  }
};

const pillDimensionsM = css`
  line-height: 2rem;
  height: 2rem;
`;

export const pillButtonBase = css`
  display: inline-block;
  max-width: 11.25rem;
  font-size: 0.875rem;
  font-weight: ${fontWeights.regular};
  color: var(--text-6);
  background-color: var(--white);
  border-radius: var(--border-radius-round);
  border-width: 0;
  padding: 0 0.75rem;
  cursor: pointer;
  outline: none;
  background: var(--box);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    background: var(--hover);
  }

  &:active {
    background: var(--border);
  }

  & .icon {
    cursor: pointer;
  }

  & path {
    fill: currentColor;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

export const PillButton = csx([pillButtonBase, pillDimensionsM], pillButtonVariants, "button");

type LegacyPillButtonProps = ICommonStyleProps & {
  size?: number;
  height?: never;
  variant?: keyof typeof pillButtonVariants;
};

const legacyPillButtonBase = css`
  max-width: 11.25rem;
  display: flex;
  flex: 0 0 auto;
  align-self: flex-end;
  font-size: 0.875rem;
  font-weight: ${fontWeights.regular};
  color: var(--text-6);
  background-color: var(--white);
  border-radius: var(--border-radius-round);
  border-width: 0;
  padding: 0 1rem;
  margin: 0 0.25rem;
  cursor: pointer;
  outline: none;
  background: var(--box);

  &:hover {
    background: var(--hover);
  }

  &:active {
    background: var(--border);
  }

  & div + span {
    margin-left: 0.5rem;
  }

  & .icon {
    cursor: pointer;
  }

  & path {
    fill: currentColor;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const legacyPillOverrides = (p: LegacyPillButtonProps) => css`
  flex: ${p.flex || "0 0 auto"};
  align-items: ${p.align || "center"};
  align-self: ${p.alignSelf || "flex-end"};
  font-size: ${p.fontSize || "0.875rem"};
  line-height: ${p.height || `2rem`};
  height: ${p.height || `2rem`};
  border-radius: var(--border-radius-round);
  border-width: 0;
  padding: ${p.padding || "0 1rem"};
  margin: ${p.margin || "0 0.25rem"};
`;

/**
 * Pill button w/ optional icon
 * @deprecated
 */
export const LegacyPill = (
  props: LegacyPillButtonProps & {
    requestStatus?: RequestStatus;
    children?: string | JSX.Element;
    iconPath?: string;
    iconSize?: number;
    textMargin?: string;
    innerRef?: React.RefObject<HTMLButtonElement>;
    className?: string;
    disabled?: boolean;
    onClick?(): void;
  }
) => {
  const { innerRef, variant, requestStatus, iconPath, iconSize, textMargin, children, ...rest } = props;

  return (
    <button
      {...rest}
      ref={innerRef}
      className={`pill ${props.className ?? ""}`}
      css={[
        legacyPillButtonBase,
        pillDimensionsM,
        legacyPillOverrides(props),
        variant && pillButtonVariants[variant],
        requestStatus && pillVariantByStatus(requestStatus)
      ]}
    >
      {iconPath && <MaterialIcon path={iconPath} size={iconSize || 1.125} />}
      {children && <EllipsisText margin={textMargin}>{children}</EllipsisText>}
    </button>
  );
};

const bigButton = {
  default: css`
    color: var(--white);
    background: var(--green-6);
    transition: none;
  `,
  ghost: css`
    color: var(--text-6);
    background: none;
  `
};

export const BigButton = styled.button<StyleVariant<typeof bigButton>>`
  display: flex;
  box-sizing: border-box;
  flex: 1 0 auto;
  align-items: center;
  align-self: flex-end;
  justify-content: center;
  width: 100%;
  padding: 0.75rem 1rem;
  margin: 0;
  font-weight: ${fontWeights.bold};
  font-size: 0.875rem;
  border: none;
  border-radius: var(--border-radius-round);
  cursor: pointer;
  transition: none;

  &:not([disabled]) {
    &:hover,
    &:focus {
      color: var(--white);
      background: var(--green-6);
      box-shadow: 0 0.25rem 0.5rem rgba(var(--text-6-raw), 0.06), 0 0.0625rem 0 rgba(var(--green-6-raw), 0.25);
      outline: none;
    }

    &:active {
      margin-top: 0.0625rem;
      margin-bottom: -0.0625rem;
      box-shadow: none;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }

  ${p => bigButton[p.variant || "default"]}
`;

export const CleanButton = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 0 0.125rem;
  margin: 0;
  font-size: inherit;
  font-weight: ${fontWeights.bold};
  text-decoration: none;
  color: var(--text-6);
  background: none;
  border-radius: 0.25rem;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    background: rgba(var(--text-6-raw), 0.075);
    cursor: pointer;
  }

  &[disabled]:hover {
    background: var(--red-1);
    cursor: not-allowed;
  }
`;

export const CleanUnderlineButton: SC<"button"> = ({ children, ...attrs }) => (
  <CleanButton {...attrs}>
    <UnderlineText>{children}</UnderlineText>
  </CleanButton>
);

export const CleanUnderlineHeadlineButton: SC<"button"> = ({ children, ...attrs }) => (
  <CleanButton
    css={css`
      position: relative;
      margin-left: var(--space-1-rem);
      display: flex;
      /* Needs to be pseudo element to not get the border-radius */
      &::after {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
        border-bottom: 2px solid currentColor;
      }
    `}
    {...attrs}
  >
    <span
      css={[
        css`
          padding-right: var(--space-6-rem);
        `
      ]}
    >
      {children}
    </span>
    <DropdownIndicator
      css={[
        css`
          margin-left: -1.5rem;
        `
      ]}
    />
  </CleanButton>
);

const underlineButton = css`
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  padding: 0.125rem 0;
  margin: 0;
  color: var(--text-2);
  background: none;
  font-size: 0.6875rem;
  font-weight: ${fontWeights.bold};
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  border-bottom: 1px solid transparent;
  outline: none;
  cursor: pointer;

  &:hover {
    color: var(--text-6);
    cursor: pointer;
  }

  &[disabled]:hover {
    cursor: not-allowed;
  }
`;

/* Used in request sidebar (not filters etc.) */
export const UnderlineButton = csx(
  [underlineButton],
  {
    selected: css`
      color: var(--text-6);
      border-bottom: 1px solid currentColor;
    `
  },
  "button"
);

const TooltipLabel = styled.div<{ alignment?: string; maxWidth?: string }>`
  max-width: ${p => p.maxWidth || "16rem"};
  padding: 0.25rem 0.5rem;
  white-space: pre-line;
  text-align: ${p => p.alignment || "left"};
  color: var(--white);
  background: var(--text-6);
  font-size: ${fontSizes.tooltip}rem;
  border-radius: var(--border-radius-m);

  div[data-placement="top"] &::after,
  div[data-placement="bottom"] &::after {
    content: " ";
    position: absolute;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
  }

  div[data-placement="top"] & {
    margin-bottom: 0.5rem;
  }

  div[data-placement="bottom"] & {
    margin-top: 0.5rem;
  }

  div[data-placement="top"] &::after {
    top: 100%;
    margin-top: -0.5rem;
    border-color: var(--text-6) transparent transparent transparent;
  }

  div[data-placement="bottom"] &::after {
    bottom: 100%;
    margin-bottom: -0.5rem;
    border-color: transparent transparent var(--text-6) transparent;
  }
`;

/** Display tooltip below element (button) intended for filters */
export function SimpleTooltip(props: {
  children: JSX.Element;
  label: string | JSX.Element | React.ReactNode;
  cursor?: string;
  alignment?: string;
  placement?: PopperProps["placement"];
  maxWidth?: string;
  zIndex?: number;
}) {
  return get(props.label, "length", 1) > 0 ? (
    <PopOver.Blank
      hover={true}
      placement={props.placement || "bottom"}
      button={props.children}
      cursor={props.cursor}
      zIndex={props.zIndex}
    >
      <TooltipLabel alignment={props.alignment} maxWidth={props.maxWidth}>
        {props.label}
      </TooltipLabel>
    </PopOver.Blank>
  ) : (
    props.children
  );
}

export const LinkTypeButton = styled.button`
  font-size: inherit;
  padding: 0;
  color: currentColor;
  background: none;
  outline: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    background-color: rgba(var(--text-6-raw), 0.075);
  }
`;

export const FilterButton = React.forwardRef<
  HTMLButtonElement,
  {
    icon: React.ReactNode;
    label?: string | null;
    /* variant for actions like save view, export  */
    secondary?: boolean;
    className?: string;
    disabled?: boolean;
    ["data-testid"]?: string;
    ["data-filtertype"]?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onMouseDown?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  }
>((props, ref) => (
  <Button
    onClick={props.onClick}
    data-filtertype={props["data-filtertype"]}
    data-testid={props["data-testid"]}
    disabled={props.disabled}
    className={props.className}
    ref={ref}
    variant="secondary"
    size="small"
    css={[
      css`
        background: var(--lightGrey-1);
        max-width: 140px;

        & * + * {
          margin-left: var(--space-1-rem);
        }
      `,
      props.secondary &&
        css`
          background: var(--lightGrey-2);
        `
    ]}
  >
    {props.icon}
    {props.label !== null && (
      <span
        css={css`
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        `}
      >
        {props.label ?? "..."}
      </span>
    )}
  </Button>
));
