import { css } from "@emotion/core";
import { Field, FieldProps, Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import { ValueType } from "react-select/src/types";
import { ISelectOption } from "src/App/Requests/CreateView/Component";
import { DisabledInteractionWrapper } from "src/App/Requests/DetailView/RequestNotes/JiraNote";
import { FeatureFlags, useFeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import { UserAvatar, UserComponentFields } from "src/App/User";
import { getUserList } from "src/App/User/getUserList";
import { Button, SubmitButton } from "src/components";
import { AsyncSingleSelectWithIcon, SmallSelectText } from "src/components/Fields/Select";
import { Typo } from "src/styling/primitives/typography";
import { ActionsContext } from "./Provider";

export const ChangeRequester: React.FC<{
  requestId: string;
  currentRequester: UserComponentFields;
  disabled: boolean;
}> = ({ requestId, currentRequester, disabled }) => {
  const { handlers } = useContext(ActionsContext);
  const { hasFeatureFlags } = useFeatureFlags();
  const [showRequesterInput, setShowRequesterInput] = useState(false);
  const listInternalOnly = hasFeatureFlags(FeatureFlags.LISTINTERNALONLY);
  return showRequesterInput ? (
    <Formik
      initialValues={{ requesterId: "" }}
      onSubmit={payload => {
        handlers.changeRequester(requestId, payload.requesterId);
        setShowRequesterInput(false);
      }}
    >
      {form => (
        <Form
          css={css`
            display: flex;

            & > * + * {
              margin-left: var(--space-2-rem);
            }
          `}
        >
          <div
            css={css`
              flex-grow: 1;
              flex-shrink: 1;
              width: 1px;
            `}
          >
            <Field name="requesterId">
              {(formikFieldProps: FieldProps) => (
                <AsyncSingleSelectWithIcon<ISelectOption>
                  autoFocus
                  openMenuOnFocus
                  loadOptions={(value: string) =>
                    getUserList(value, false, listInternalOnly).then(userList =>
                      Promise.resolve(
                        userList.users.map(user => ({
                          label: user.name ? (
                            <>
                              {user.name} <SmallSelectText>({user.email})</SmallSelectText>
                            </>
                          ) : (
                            user.email
                          ),
                          value: user.id
                        }))
                      )
                    )
                  }
                  onChange={(data: ValueType<ISelectOption>) => {
                    if (data && !("length" in data)) {
                      formikFieldProps.form.setFieldValue("requesterId", data.value);
                    }
                  }}
                  placeholder="Type to search..."
                />
              )}
            </Field>
          </div>
          <SubmitButton
            variant="secondary"
            size="small"
            disabled={form.values.requesterId === "" || form.values.requesterId === currentRequester.id}
          >
            Save
          </SubmitButton>
        </Form>
      )}
    </Formik>
  ) : (
    <DisabledInteractionWrapper disabled={disabled}>
      <div
        css={css`
          display: flex;
          align-items: center;
          min-height: 2.625rem;
        `}
      >
        <UserAvatar hoverable={false} user={currentRequester} />
        <Typo.Body
          ellipsis
          css={css`
            margin-left: var(--space-2-rem);
            flex-grow: 1;
          `}
        >
          {currentRequester.name !== "" ? currentRequester.name : currentRequester.email}
        </Typo.Body>
        <Button
          disabled={disabled}
          css={css`
            margin-left: var(--space-2-rem);
            justify-self: end;
          `}
          variant="secondary"
          size="small"
          onClick={() => setShowRequesterInput(true)}
        >
          Change
        </Button>
      </div>
    </DisabledInteractionWrapper>
  );
};
