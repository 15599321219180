import "src/styling/animations/keyframes.css";
import "src/styling/tokens/tokens.css";
import "./body.css";

import * as React from "react";

import { FC, useState } from "react";
import { FeatureFlagProvider, useFeatureFlags } from "./providers/FeatureFlagProvider";

import { CurrentUserProvider } from "./providers/CurrentUserProvider";
import { HotKeysProvider } from "src/util/services/hotkeys";
import { IntercomProvider } from "react-use-intercom";
import { PushBanner } from "src/App/PushBanner/PushBanner";
import { RequestActions } from "src/App/Requests/Actions/Provider";
import { Routes } from "./Routes";
import { SearchDialogProvider } from "../Requests/Search/Dialog";
import { SentryErrorBoundary } from "./Sentry";
import { SnackProvider } from "./providers/SnackProvider";
import { createPortal } from "react-dom";
import { css } from "@emotion/core";
import { csx } from "src/util/csx";
import { dialog } from "src/styling/effects";

const snackStyles = css`
  .snack {
    font-family: var(--font-family-body);
    font-size: var(--font-size-body);
    line-height: var(--line-height-normal);
    padding: 0.25rem 1rem;
    ${dialog.shadow}
  }

  .snack.error {
    color: var(--white);
    background: var(--red-7);
  }

  .snack.warning {
    color: var(--yellow-9);
    background: var(--yellow-2);
  }

  .snack.success {
    color: var(--green-8);
    background: var(--green-1);
  }
`;

const RootLayoutWrapper = csx(
  [
    css`
      color: var(--text-6);
      background-color: var(--white);
      display: flex;

      & #sidebar {
        width: 21.875vw;
        max-width: 20rem;
        min-width: 17.5rem;
        height: 100%;
      }

      & #content {
        /* used for abs pos animations over content area */
        position: relative;
        background: var(--white);
        color: var(--text-6);
        width: 100%;
        height: 100%;
        display: flex;
        overflow-x: auto;
      }
    `,
    snackStyles
  ],
  {},
  "section"
);

/** Used for e.g. animating confetti over #content area  */
export const ContentAnimationPortal: FC = props => {
  const portalTarget: Element | null = document.getElementById("content");
  if (!portalTarget) return null;
  return createPortal(
    <div
      css={css`
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      `}
    >
      {props.children}
    </div>,
    portalTarget
  );
};

const IntercomFeatureFlags: FC = props => {
  const { featureFlags } = useFeatureFlags();
  return <div data-feature-flags={featureFlags.join(" ")}>{props.children}</div>;
};

/**
 * True when user drags something over root wrapper
 * @deprecated should be moved to hook
 */
export const DragOver = React.createContext(false);

/**
 * Root wrapper for handling global mouse events like dragging
 */
export const RootWrapper = () => {
  const [isDragging, setDragging] = useState(false);

  return (
    <SentryErrorBoundary>
      <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID || ""}>
        <FeatureFlagProvider>
          <CurrentUserProvider>
            <IntercomFeatureFlags>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  height: 100vh;

                  /* no push banner so RootLayoutWrapper can take full 100% */
                  & > section:first-child {
                    height: 100%;
                  }

                  /* there's a push banner so scale RootLayoutWrapper accordingly */
                  & > section:nth-child(2) {
                    height: calc(100% - 40px);
                  }
                `}
              >
                <PushBanner />
                <RootLayoutWrapper
                  onDragEnter={() => setDragging(true)}
                  onDragEnd={() => setDragging(false)}
                  onMouseOut={() => setDragging(false)}
                  onDragOver={e => e.preventDefault()}
                  onDrop={e => {
                    setDragging(false);
                    e.preventDefault();
                  }}
                >
                  <DragOver.Provider value={isDragging}>
                    <HotKeysProvider>
                      <SnackProvider>
                        <RequestActions>
                          <SearchDialogProvider>
                            <Routes />
                          </SearchDialogProvider>
                        </RequestActions>
                      </SnackProvider>
                    </HotKeysProvider>
                  </DragOver.Provider>
                </RootLayoutWrapper>
              </div>
            </IntercomFeatureFlags>
          </CurrentUserProvider>
        </FeatureFlagProvider>
      </IntercomProvider>
    </SentryErrorBoundary>
  );
};
