import { Field, Formik } from "formik";
import * as React from "react";
import { MutationFunction } from "react-apollo";
import { FormikInput, Row, SubmitButton } from "src/components";
import { Form } from "src/components/Fields/FormWrappers";
import * as yup from "yup";
import { PasswordStrength, passwordValidation } from "../../Register/PasswordStrength";
import { UserFinishPasswordRecovery, UserFinishPasswordRecoveryVariables } from "./typings/UserFinishPasswordRecovery";

/**
 * Validation rules
 */
const FormSchema = yup.object().shape({
  password: passwordValidation,
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords do not match")
    .required("Required")
});

export const FinishPasswordRecoveryForm: React.ComponentType<{
  finishPasswordRecovery: MutationFunction<UserFinishPasswordRecovery, UserFinishPasswordRecoveryVariables>;
  token: string;
  loading: boolean;
}> = props => (
  <Formik
    enableReinitialize={true}
    onSubmit={payload => {
      const params = {
        token: props.token,
        ...payload
      };

      props.finishPasswordRecovery({ variables: { params } });
    }}
    initialValues={{
      password: "",
      confirmPassword: ""
    }}
    validationSchema={FormSchema}
  >
    {form => (
      <Form onSubmit={form.handleSubmit} padding="0" style={{ width: "20rem" }}>
        <Field
          name="password"
          component={FormikInput}
          type="password"
          autocomplete="new-password"
          label="New password"
        />
        {form.errors.password && <PasswordStrength value={form.values.password} />}
        <Field
          name="confirmPassword"
          component={FormikInput}
          type="password"
          autocomplete="new-password"
          label="Confirm password"
        />
        <Row justify="center" margin="1rem 0 0">
          <SubmitButton variant="green" disabled={props.loading}>
            Reset password
          </SubmitButton>
        </Row>
      </Form>
    )}
  </Formik>
);
