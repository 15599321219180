import { PureQueryOptions } from "apollo-client";
import gql from "graphql-tag";
import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-apollo";
import { ListViewContainer } from "./Component";
import { useRequestList } from "./Provider";
import { GRequestListUpdated, GRequestListUpdatedVariables } from "./typings/GRequestListUpdated";

const SLA_POLLING_INTERVAL = 60000;
const REQ_LIST_UPDATES_INTERVAL = parseInt(process.env.REACT_APP_POLLING_INTERVAL ?? "0", 10);

const REQUEST_LIST_UPDATED = gql`
  query GRequestListUpdated($view: RequestListView!, $query: RequestListQuery) {
    requestListUpdated(view: $view, query: $query) {
      id
      updateTime
      isNew
      unread
      mentions
      approvals
      hasPendingTasks
    }
  }
`;

type TRequestUpdate = GRequestListUpdated["requestListUpdated"][0];
export type IRequestUpdateMap = Map<string, TRequestUpdate>;

/**
 * Periodically fetches updates for the requests in the current view
 * without any of the filters applied by the user.
 * If there are new update times, we refetch the request list with
 * user applied filters.
 */
export const RequestListUpdates: React.FC<{
  listDimensionsRef: React.MutableRefObject<HTMLElement | null>;
  queriesToRefetch?: Array<string | PureQueryOptions>;
  // used for projects empty state
  emptyState?: React.ReactNode;
}> = props => {
  const [requestList] = useRequestList();
  const [updateTimes, setUpdateTimes] = useState<string[]>([]);
  const { data, startPolling, stopPolling } = useQuery<GRequestListUpdated, GRequestListUpdatedVariables>(
    REQUEST_LIST_UPDATED,
    {
      fetchPolicy: "network-only",
      variables: {
        view: requestList.response.variables.view,
        query: {
          filters: requestList.response.variables.query.filters
        }
      },
      pollInterval: REQ_LIST_UPDATES_INTERVAL
    }
  );

  const requestListResponse = requestList.response;
  useEffect(() => {
    const visibilityChangeHandler = () => {
      // on hidden
      if (document.visibilityState === "hidden") {
        stopPolling();
        requestListResponse.stopPolling();
        // on visible
      } else if (document.visibilityState === "visible") {
        startPolling(REQ_LIST_UPDATES_INTERVAL);
        requestListResponse.startPolling(SLA_POLLING_INTERVAL);
      }
    };
    // run for initial mount
    visibilityChangeHandler();
    document.addEventListener("visibilitychange", visibilityChangeHandler);
    return () => {
      document.removeEventListener("visibilitychange", visibilityChangeHandler);
    };
  }, [requestListResponse, startPolling, stopPolling]);

  if (data && data.requestListUpdated) {
    const latestUpdateTimes = data.requestListUpdated.map(r => r.updateTime).sort();
    if (latestUpdateTimes.join("") !== updateTimes.join("")) {
      requestList.response.refetch();
      setUpdateTimes(latestUpdateTimes);
    }
  }
  const requestUpdateMap = useMemo(() => {
    const res: IRequestUpdateMap = new Map();

    for (const r of data?.requestListUpdated ?? []) {
      res.set(r.id, r);
    }

    return res;
  }, [data]);

  if (!requestList.response.data) return null;
  return (
    <ListViewContainer
      requestUpdateMap={requestUpdateMap}
      queriesToRefetch={props.queriesToRefetch ?? requestList.queriesToRefetch}
      // passes empty state from projects
      emptyState={props.emptyState}
      listDimensionsRef={props.listDimensionsRef}
    />
  );
};
