import { css } from "@emotion/core";
import { mdiPaperclip } from "@mdi/js";
import * as React from "react";
import { documentPreviewRoute } from "src/App/KB";
import { DocumentLogo } from "src/App/KB/DocumentLogos";
import { Badge, DateTime, EmptyState, MaterialIcon, Tooltip } from "src/components";
import { Table } from "src/components/Table";
import { DocumentSource } from "src/globalTypes";
import { TextLink } from "src/styling/primitives";
import { Typo } from "src/styling/primitives/typography";
import { backMarkdownToText, fileSize } from "src/util/formatters";
import { navigate } from "src/util/router";
import { Targeting } from "./Targeting";
import { GDocumentsListGet_documentList } from "./typings/GDocumentsListGet";

export function sortedDocumentList<
  D extends {
    title: string | null;
    createTime: string | null;
  }
>(documents: D[]): D[] {
  const sorted = [...documents];

  sorted.sort((a, b) => {
    const titleCmp = a.title?.localeCompare(b.title ?? "") ?? 0;
    if (titleCmp !== 0) {
      return titleCmp;
    }

    const aCreationTime = new Date(a.createTime ?? 0),
      bCreationTime = new Date(b.createTime ?? 0);

    if (aCreationTime === bCreationTime) {
      return 0;
    }

    return aCreationTime.getTime() < bCreationTime.getTime() ? -1 : 1;
  });

  return sorted;
}

export const KBDocsTable: React.FC<{
  documentList: GDocumentsListGet_documentList[];
}> = props => {
  return (
    <>
      {props.documentList.length === 0 && (
        <EmptyState
          title="No answers yet"
          subtitle={
            <Typo.Body>
              Go ahead and <TextLink onClick={() => navigate("/knowledge/create")}>add the first answer</TextLink>!
            </Typo.Body>
          }
          spacing="loose"
          variant="error"
        />
      )}
      {props.documentList.length > 0 && (
        <Table hasClickableRows>
          <thead>
            <tr>
              <th>Title</th>
              <th>Content</th>
              <th
                css={css`
                  text-align: center;
                `}
              >
                Attachments
              </th>
              <th
                css={css`
                  text-align: center;
                `}
              >
                Targeting
              </th>
              <th
                css={css`
                  text-align: right;
                `}
              >
                Updated
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedDocumentList(props.documentList).map(doc => {
              const content = backMarkdownToText(doc.content);
              return (
                <tr
                  key={doc.id}
                  onClick={() => {
                    navigate(documentPreviewRoute(doc.id));
                  }}
                >
                  <td>
                    <Typo.Body
                      ellipsis
                      css={css`
                        display: flex;
                        align-items: center;
                        width: 100%;
                        & > * + * {
                          margin-left: var(--space-2-rem);
                        }
                      `}
                    >
                      <DocumentLogo doc={doc} />
                      <span>{doc.title || "Untitled document"}</span>
                    </Typo.Body>
                  </td>
                  <>
                    {doc.source === DocumentSource.notion &&
                      (!!doc.content ? (
                        <td title="Click to open page in Notion...">{content}</td>
                      ) : (
                        <td>
                          <Typo.Body light>Click to open page in Notion...</Typo.Body>
                        </td>
                      ))}
                    {doc.source !== DocumentSource.notion && <td title={content}>{content}</td>}
                  </>
                  <td
                    css={css`
                      text-align: center;
                    `}
                  >
                    {doc.attachments.length === 0 && "--"}
                    {doc.attachments.length > 0 && (
                      <Tooltip
                        target={
                          <div
                            css={[
                              css`
                                display: flex;
                                align-items: center;
                              `
                            ]}
                          >
                            {doc.attachments.length} <MaterialIcon path={mdiPaperclip} size={0.875} />
                          </div>
                        }
                      >
                        {doc.attachments.map(attachment => (
                          <div>
                            {attachment.displayName} ({fileSize(attachment.size)})
                          </div>
                        ))}
                      </Tooltip>
                    )}
                  </td>
                  <td
                    css={css`
                      text-align: center;
                    `}
                  >
                    {doc.hrisFilter && (
                      <Tooltip target={<Badge neutral>Limited</Badge>}>
                        <Targeting
                          hrisFilter={doc.hrisFilter}
                          css={css`
                            & * {
                              color: var(--white) !important;
                            }
                          `}
                        />
                      </Tooltip>
                    )}
                    {!doc.hrisFilter && <Badge success>No rules</Badge>}
                  </td>
                  <td
                    css={css`
                      text-align: right;
                    `}
                  >
                    <DateTime timestamp={doc.updateTime} tooltip={true} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
};
