import gql from "graphql-tag";
import { FORM_SENT_DETAILS } from "src/App/Forms/FORM_SENT_DETAILS";
import { FORM_SUBMITTED_EVENT_DETAILS } from "src/App/Forms/FORM_SUBMITTED_EVENT_DETAILS";
import { DOCUMENT_FRAGMENT } from "src/App/KB";
import { PROJECT_INFO_FRAGMENT } from "src/App/Projects/ProjectFragment";
import { REQUEST_TEAM_FRAGMENT } from "src/fragments/TeamInfoFragment";
import { USER_INFO_FRAGMENT } from "src/fragments/UserInfoFragment";

export const REQUEST_GET = gql`
  query RequestGet($id: ID!) {
    backUser {
      id
    }
    initialRequestRevision(id: $id) {
      title
      requester {
        ...UserInfo
      }
    }
    request(id: $id) {
      id
      title
      channel
      requestedAt
      updateTime
      dueAt
      priority
      customStatus {
        ...CustomStatusFragment
      }
      sla {
        minutesToFirstResponse
        minutesToResolution
      }
      slaStatus {
        slaId
        remainingTimeToFirstResponse {
          remainingTimeMinutes
          ticking
        }
        remainingTimeToResolution {
          remainingTimeMinutes
          ticking
        }
      }
      requester {
        ...UserInfo
      }
      assignee {
        ...UserInfo
      }
      author {
        ...UserInfo
      }
      isUnread
      category {
        id
        name
      }
      project {
        ...ProjectInfoFragment
      }
      team {
        ...RequestTeamInfo
      }
      CCs {
        revision
        CCs {
          ...UserInfo
        }
      }
      jiraLinkedIssue {
        key
        url
      }
      events {
        eventName
        timestamp
        revision
        data {
          ... on RequestCreatedEvent {
            text
          }
          ... on CommentAddedEvent {
            author {
              ...UserInfo
            }
            commentId
            text
            channel
            deleted
            publicComment
            mentionsOnly
            kbDocumentsList {
              ...DocumentFragment
            }
            form {
              ...FormSentDetails
            }
            attachmentsList {
              id
              displayName
              size
              deleteTime
            }
            ccsRevision
            hasHtml
          }
          ... on NoteAddedEvent {
            author {
              ...UserInfo
            }
            noteId
            text
            deleted
            attachmentsList {
              id
              displayName
              size
              deleteTime
            }
            hasHtml
          }
          ... on StatusUpdatedEvent {
            author {
              ...UserInfo
            }
            oldStatus
            newStatus
            newCustomStatus {
              id
              step
              name
              color
              createTime
            }
          }
          ... on AssignmentChangedEvent {
            author {
              ...UserInfo
            }
            user {
              ...UserInfo
            }
            autoAssign
          }
          ... on TeamAssignmentChangedEvent {
            author {
              ...UserInfo
            }
            oldTeam {
              id
              name
            }
            newTeam {
              id
              name
            }
          }
          ... on ApprovalAddedEvent {
            approvalId
            approver {
              ...UserInfo
            }
            author {
              ...UserInfo
            }
            text
          }
          ... on ApprovalResolvedEvent {
            approvalId
            approver {
              ...UserInfo
            }
            status
            text
            approverComment
          }
          ... on FormSubmittedEvent {
            ...FormSubmittedEventDetails
          }
          ... on CCsAddedEvent {
            author {
              ...UserInfo
            }
            ccUsersList {
              id
              name
              email
            }
          }
          ... on CCsRemovedEvent {
            author {
              ...UserInfo
            }
            ccUsersList {
              id
              name
              email
            }
          }
          ... on RequesterChangedEvent {
            author {
              ...UserInfo
            }
            requester {
              ...UserInfo
            }
          }
          ... on TaskCompletedEvent {
            task {
              id
              instruction
              icon
              workflow {
                id
                name
              }
              completionAuthor {
                ...UserInfo
              }
            }
          }
        }
        requestCreatedEvent {
          text
        }
        commentAddedEvent {
          author {
            ...UserInfo
          }
          commentId
          text
          channel
          deleted
          publicComment
          mentionsOnly
          kbDocumentsList {
            ...DocumentFragment
          }
          attachmentsList {
            id
            displayName
            size
            deleteTime
          }
          ccsRevision
          hasHtml
        }
        noteAddedEvent {
          author {
            ...UserInfo
          }
          noteId
          text
          deleted
          attachmentsList {
            id
            displayName
            size
            deleteTime
          }
          hasHtml
        }
        statusUpdatedEvent {
          author {
            ...UserInfo
          }
          oldStatus
          newStatus
          newCustomStatus {
            id
            step
            name
            color
            createTime
          }
        }
        assignmentChangedEvent {
          author {
            ...UserInfo
          }
          user {
            ...UserInfo
          }
          autoAssign
        }
        teamAssignmentChangedEvent {
          author {
            ...UserInfo
          }
          oldTeam {
            id
            name
          }
          newTeam {
            id
            name
          }
        }
        approvalAddedEvent {
          approvalId
          approver {
            ...UserInfo
          }
          author {
            ...UserInfo
          }
          text
        }
        approvalResolvedEvent {
          approvalId
          approver {
            ...UserInfo
          }
          status
          text
          approverComment
        }
        formSubmittedEvent {
          ...FormSubmittedEventDetails
        }
        ccsAddedEvent {
          author {
            ...UserInfo
          }
          ccUsersList {
            id
            name
            email
          }
        }
        ccsRemovedEvent {
          author {
            ...UserInfo
          }
          ccUsersList {
            id
            name
            email
          }
        }
      }
      tasks {
        id
        createTime
        requestId
        actionLabel
        actionUrl
        completionLabel
        icon
        workflow {
          id
          name
        }
        instruction
        isPending
      }
      origin {
        interactionType
        channel {
          name
          type
        }
      }
      blacklistedFormAssociations {
        requestId
        formId
      }
    }
  }
  ${FORM_SENT_DETAILS}
  ${FORM_SUBMITTED_EVENT_DETAILS}
  ${DOCUMENT_FRAGMENT}
  ${PROJECT_INFO_FRAGMENT}
  ${USER_INFO_FRAGMENT}
  ${REQUEST_TEAM_FRAGMENT}
`;
