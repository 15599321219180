import { css } from "@emotion/core";
import React, { useRef } from "react";
import { ListViewContainer } from "src/App/Requests/ListView/Component";
import { FilterRequestsBar } from "src/App/Requests/ListView/Filters/FilterBar";
import { deserializeUrlParams } from "src/App/Requests/ListView/Filters/urlParamHelpers";
import { RequestListProvider, useRequestList } from "src/App/Requests/ListView/Provider";
import { RequestListView } from "src/globalTypes";
import { RouteComponentProps } from "src/util/router";

export const SearchRequestsContainer = (props: RouteComponentProps<{}>) => {
  const listDimensionsRef = useRef<HTMLDivElement | null>(null);

  // guard location
  if (!props.location) return null;
  const params = deserializeUrlParams(props.location);

  return (
    <RequestListProvider
      listViewType={RequestListView.VIEW}
      viewId="__search"
      filtersFromView={params.filtersFromView}
      filtersFromUrl={params.filtersFromUrl}
      orderByKey={params.orderByKey}
      pageNumber={params.pageNumber}
      shouldPaginate={false}
      filterBarHeight={80}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex-grow: 1;
        `}
        ref={listDimensionsRef}
      >
        <FilterRequestsBar />
        <SearchRequestListView listDimensionsRef={listDimensionsRef} />
      </div>
    </RequestListProvider>
  );
};

/**
 * Special container to pass queriesToRefetch from provider
 * Substitutes for RequestListUpdates, to prevent polling for search
 *  */
const SearchRequestListView: React.FC<{ listDimensionsRef: React.MutableRefObject<HTMLElement | null> }> = props => {
  const [requestList] = useRequestList();
  return (
    <ListViewContainer
      requestUpdateMap={new Map()}
      queriesToRefetch={requestList.queriesToRefetch}
      listDimensionsRef={props.listDimensionsRef}
    />
  );
};
