import gql from "graphql-tag";
import * as React from "react";
import { Mutation } from "react-apollo";
import { Toast } from "src/portals/Toast";
import { ITeamUpdateProps, SETTINGS_TEAM_GET } from "./Container";
import { TeamUpdateForm } from "./Form";
import { GSettingsTeamGet } from "./typings/GSettingsTeamGet";
import { GSettingsTeamUpdate, GSettingsTeamUpdateVariables } from "./typings/GSettingsTeamUpdate";

const SETTINGS_TEAM_UPDATE = gql`
  mutation GSettingsTeamUpdate($params: UpTeam!) {
    teamUpdate(params: $params) {
      code
      message
      success
    }
  }
`;

/**
 * Component to handle team update mutation and submission logic
 */
export const TeamUpdateSubmission: React.ComponentType<GSettingsTeamGet & ITeamUpdateProps> = props => (
  <Mutation<GSettingsTeamUpdate, GSettingsTeamUpdateVariables>
    mutation={SETTINGS_TEAM_UPDATE}
    refetchQueries={[
      {
        query: SETTINGS_TEAM_GET,
        variables: {
          id: props.team.id
        }
      }
    ]}
  >
    {(teamUpdate, response) => {
      return (
        <>
          <TeamUpdateForm {...props} teamUpdate={teamUpdate} />
          {response.data && !response.data.teamUpdate.success && (
            <Toast message={response.data.teamUpdate.message} kind="mutationError" />
          )}
        </>
      );
    }}
  </Mutation>
);
