import { css } from "@emotion/core";
import { csx } from "src/util/csx";

// moved CountBadge to separate file because it was causing
// "instantiation" order errors w/ useQuery
export const countBadge = css`
  display: flex;
  justify-content: center;
  width: 1.125rem;
  height: 1.125rem;
  line-height: 1.125rem;
  font-size: var(--font-size-label);
  font-weight: var(--font-weight-body-semiBold);
  border-radius: var(--border-radius-round);
  background: var(--yellow-3);
  color: var(--yellow-9);
`;

export const CountBadge = csx([countBadge], {
  selected: css`
    background: var(--yellow-4);
  `
});
