import gql from "graphql-tag";
import { CUSTOM_STATUS_FRAGMENT } from "./CustomStatusFragment";
import { USER_INFO_FRAGMENT } from "./UserInfoFragment";
export const TEAM_INFO_FRAGMENT = gql`
  fragment TeamInfo on Team {
    id
    name
    slug
    privacy
    userIds
    memberCount
    users {
      ...UserInfo
    }
  }
  ${USER_INFO_FRAGMENT}
`;

/**
 * these include necessary fields for request actions options
 * */
export const REQUEST_TEAM_FRAGMENT = gql`
  fragment RequestTeamInfo on Team {
    id
    name
    slug
    privacy
    categories {
      id
      name
    }
    userIds
    memberCount
    users {
      ...UserInfo
    }
    customStatuses {
      ...CustomStatusFragment
    }
  }
  ${USER_INFO_FRAGMENT}
  ${CUSTOM_STATUS_FRAGMENT}
`;
