import { css } from "@emotion/core";
import { csx } from "src/util/csx";

export const TiptapDropdown = {
  Menu: csx(
    [
      css`
        border-radius: var(--border-radius-s);
        box-shadow: var(--box-shadow-card);
      `
    ],
    {}
  ),
  Item: csx(
    [
      css`
        padding: var(--space-1-rem) var(--space-2-rem);
        background: var(--white);
        &:hover {
          background: var(--lightBlue-1);
          cursor: pointer;
        }
      `
    ],
    {
      selected: css`
        background: var(--lightGrey-1);
      `
    }
  )
};
