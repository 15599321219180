import { css } from "@emotion/core";
import { mdiCheck } from "@mdi/js";
import throttle from "lodash/throttle";
import React from "react";
import { MaterialIcon } from "src/components";
import { popMenu } from "src/styling/effects";
import { Typo } from "src/styling/primitives/typography";
import { reportDevError } from "src/util";
import { csx } from "src/util/csx";
import { kebab } from "src/util/formatters";
import { Link } from "src/util/router";
import { HotKeysContext, useHotKeys } from "src/util/services/hotkeys";
import { isString } from "util";
import { PopMenu } from "./PopMenu";

interface PopOverMenuBaseProps {
  options: OptionsUnion;
  trigger: JSX.Element;
  selected?: OptionId | OptionId[] | null;
  disabled?: OptionId | OptionId[] | null;
  onRemove?(): void;
  /* prevent from opening */
  disableTrigger?: boolean;
  /* Falls back to "Remove" or "Remove all" */
  labelForRemoveOption?: string;
  /* when clicking outside the popper */
  onClickAway?(e?: React.ChangeEvent<{}>): void;
  /* open or close the popover */
  isOpen?: boolean;
  /* invoked when the pop over is closed */
  onClose?: () => void;
  /* enable auto hotkey assignment for options */
  enableOptionHotKeys?: boolean;
  className?: string;
}
type SinglePOMProps = PopOverMenuBaseProps & {
  onSelect?(val: OptionId | null): void;
};
type MultiPOMProps = PopOverMenuBaseProps & {
  isMulti: true;
  onSelect?(val: OptionId[] | null): void;
};
export type PopOverMenuProps = SinglePOMProps | MultiPOMProps;

type OptionId = string;
export interface OptionMap {
  id: OptionId;
  name: string;
  icon?: React.ReactNode;
  labelNode?: React.ReactNode;
  linkAttrs?: { href: string; target?: string } | { to: string };
  onClick?(e: React.MouseEvent): void;
  testId?: string;
  intercomId?: string;
  disabled?: boolean;
}
export interface OptionGroup {
  headline: string;
  options: OptionId[] | OptionMap[];
}
export type OptionsUnion = OptionId[] | OptionMap[] | OptionGroup[];

export const PopOverMenuItemInner = csx(
  [
    css`
      box-sizing: border-box;
      appearance: none;
      background-color: transparent;
      border: none;
      margin: 0;
      padding: 0;
      width: 100%;
      display: flex;
      padding: 6px var(--space-4-px);
      cursor: pointer;
      align-items: center;
      > p {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      > * + * {
        margin-left: var(--space-3-px);
      }
      &:hover {
        background-color: var(--lightGrey-2);
      }
      &:focus {
        outline: none;
        background-color: var(--blue-4);
      }
    `
  ],
  {
    disabled: css`
      cursor: not-allowed;

      &:hover {
        background-color: unset;
      }
      p {
        color: var(--lightGrey-4);
      }
    `,
    focused: css`
      outline: none;
      background-color: var(--lightBlue-1);
    `,
    selected: css`
      > div:last-of-type {
        /*  Materia icon wrapper */
        margin-left: 6px;
      }
      svg {
        box-sizing: border-box;
        width: var(--space-4-px) !important;
      }
    `
  }
);

const Menu = {
  Container: csx(
    [
      popMenu.shadow,
      css`
        margin: var(--space-1-px) 0;
        background: var(--white);
        border-radius: var(--border-radius-m);
        box-sizing: border-box;
        max-width: 384px;
        // add the height of half an element to make the scroll area more visible
        max-height: calc(50vh + 1rem);
        display: flex;
        flex-direction: column;
        > div {
          flex: 1 1 auto;
        }
        a {
          /* Reach router link */
          text-decoration: none;
          text-decoration-color: initial;
          text-decoration-line: none;
        }
      `
    ],
    {}
  ),
  Scrollable: csx(
    [
      css`
        overflow-y: auto;
        flex: 1 0 auto;
      `
    ],
    {}
  ),
  Group: csx([
    css`
      box-sizing: border-box;
      padding: 6px 0;
      &:not(:first-of-type) {
        border-top: var(--border-lightGrey);
      }
    `
  ]),
  Item: ({
    selectItem,
    selected = false,
    disabled = false,
    option,
    keepOpen,
    children
  }: {
    selectItem(val: OptionId | null): void;
    children: React.ReactNode;
    selected?: boolean;
    disabled?: boolean;
    keepOpen: boolean;
    option?: OptionId | OptionMap;
  }) => {
    let val: string | null = null;
    if (isString(option)) {
      val = option as OptionId;
    } else val = option?.id as OptionId;
    const onClickHandler = (e: React.MouseEvent) => {
      // keep menu open for multi select
      if (keepOpen) e.stopPropagation();
      if (!isString(option)) {
        if (option?.onClick) {
          option.onClick(e);
        }
      }
      selectItem(val);
    };
    if (isString(option) || !option) {
      // simple string || undefined
      return (
        <Menu.ItemInner
          selected={selected}
          disabled={disabled}
          onClick={e => {
            if (!disabled) {
              onClickHandler(e);
            } else {
              e.stopPropagation();
            }
          }}
        >
          {children}
        </Menu.ItemInner>
      );
    } else {
      // complex OptionType
      if (option.linkAttrs) {
        // has a / Link wrapper
        if ("to" in option.linkAttrs) {
          return (
            <Link to={option.linkAttrs.to}>
              <Menu.ItemInner
                selected={selected}
                disabled={disabled}
                onClick={e => !disabled && onClickHandler(e)}
                data-testid={option.testId}
                data-intercom-target={option.intercomId}
              >
                {children}
              </Menu.ItemInner>
            </Link>
          );
        } else {
          return (
            <a
              href={option.linkAttrs.href}
              target={option.linkAttrs.target}
              rel={!!option.linkAttrs.target ? "noopener noreferrer" : undefined}
            >
              <Menu.ItemInner
                selected={selected}
                disabled={disabled}
                onClick={e => !disabled && onClickHandler(e)}
                data-testid={option.testId}
                data-intercom-target={option.intercomId}
              >
                {children}
              </Menu.ItemInner>
            </a>
          );
        }
      } else {
        // regular OptionType item without link
        return (
          <Menu.ItemInner
            selected={selected}
            disabled={disabled}
            onClick={e => !disabled && onClickHandler(e)}
            data-testid={option.testId}
            data-intercom-target={option.intercomId}
          >
            {children}
          </Menu.ItemInner>
        );
      }
    }
  },
  ItemInner: PopOverMenuItemInner,
  SectionHeadline: ({ text }: { text: string }) => (
    <Typo.SectionHeading
      bold
      light
      css={css`
        padding: 6px var(--space-4-px);
      `}
    >
      {text}
    </Typo.SectionHeading>
  )
} as const;

enum InputType {
  STRINGARRAY = "stringArray",
  OPTIONARRAY = "optionArray",
  GROUPARRAY = "groupArray"
}

const inferOptionsTypes = (options: OptionsUnion): InputType => {
  if (Array.isArray(options) && options.length) {
    const sampleItem = options[0];
    if (isString(sampleItem)) {
      return InputType.STRINGARRAY;
    } else if (!isString(sampleItem) && "id" in sampleItem) {
      return InputType.OPTIONARRAY;
    } else if (!isString(sampleItem) && "headline" in sampleItem) {
      return InputType.GROUPARRAY;
    } else return InputType.STRINGARRAY;
  } else return InputType.STRINGARRAY;
};

export const PopOverMenu = (props: PopOverMenuProps) => {
  const isMulti = "isMulti" in props;
  const labelForRemoveOption = props.labelForRemoveOption ?? (isMulti ? "Remove all" : "Remove");
  const inputType = inferOptionsTypes(props.options);
  const selectItem = (item: OptionId) => {
    if (!props.onSelect) return;
    if (!("isMulti" in props)) {
      props.onSelect(item);
    } else {
      const selectedItems = (props.selected as OptionId[]) || [];
      const idx = selectedItems?.indexOf(item);
      if (idx > -1) {
        props.onSelect(selectedItems.filter((_el, i) => i !== idx));
      } else {
        props.onSelect([...selectedItems, item]);
      }
    }
  };
  return (
    <PopMenu
      placement="bottom-start"
      disabled={props.disableTrigger}
      hover={false}
      button={props.trigger}
      onClickAway={props.onClickAway}
      onClose={props.onClose}
      isOpen={props.isOpen}
      className={props.className}
    >
      <Menu.Container>
        <MenuSwitchComponent
          isMulti={isMulti}
          options={props.options}
          selected={props.selected}
          disabled={props.disabled}
          selectItem={selectItem}
          inputType={inputType}
          enableOptionHotKeys={props.enableOptionHotKeys}
        />
        {(isMulti || props.onRemove) && (
          <Menu.Group>
            {isMulti && (
              <Menu.Item keepOpen={false} selectItem={() => props.onClickAway && props.onClickAway()}>
                <Typo.Body>Done</Typo.Body>
              </Menu.Item>
            )}
            {props.onRemove && (
              <Menu.Item keepOpen={false} selectItem={() => props.onRemove && props.onRemove()}>
                <Typo.Body data-testid="popovermenu-remove">{labelForRemoveOption}</Typo.Body>
              </Menu.Item>
            )}
          </Menu.Group>
        )}
      </Menu.Container>
    </PopMenu>
  );
};

const MenuSwitchComponent: React.FunctionComponent<
  Partial<PopOverMenuProps> & {
    selectItem(item: OptionId): void;
    inputType: InputType;
    isMulti: boolean;
  }
> = ({ options, isMulti, selected, disabled, selectItem, inputType, enableOptionHotKeys }) => {
  switch (inputType) {
    case InputType.STRINGARRAY:
    default:
      return (
        <Menu.Scrollable>
          <Menu.Group>
            <StringArrayComponent
              stringArray={options as OptionId[]}
              isMulti={isMulti}
              selected={selected}
              disabled={disabled}
              selectItem={selectItem}
              enableOptionHotKeys={enableOptionHotKeys}
            />
          </Menu.Group>
        </Menu.Scrollable>
      );
    case InputType.OPTIONARRAY:
      return (
        <Menu.Scrollable>
          <Menu.Group>
            <OptionArrayComponent
              optionArray={options as OptionMap[]}
              isMulti={isMulti}
              selected={selected}
              disabled={disabled}
              selectItem={selectItem}
              enableOptionHotKeys={enableOptionHotKeys}
            />
          </Menu.Group>
        </Menu.Scrollable>
      );
    case InputType.GROUPARRAY:
      const groupArray: OptionGroup[] = options as OptionGroup[];
      return (
        <Menu.Scrollable>
          {groupArray.map((group: OptionGroup, i) => (
            <Menu.Group key={i}>
              <Menu.SectionHeadline text={group.headline} />
              {(function () {
                if (group.options.length === 0) {
                  throttle(() => {
                    // guard against empty array but give the query 200ms to return a value
                    reportDevError("Popover options array empty");
                  }, 200);
                  return null;
                }
                if (isString(group.options[0])) {
                  return (
                    <StringArrayComponent
                      stringArray={group.options as OptionId[]}
                      isMulti={isMulti}
                      selected={selected}
                      disabled={disabled}
                      selectItem={selectItem}
                      enableOptionHotKeys={enableOptionHotKeys}
                    />
                  );
                } else {
                  return (
                    <OptionArrayComponent
                      optionArray={group.options as OptionMap[]}
                      isMulti={isMulti}
                      selected={selected}
                      disabled={disabled}
                      selectItem={selectItem}
                      enableOptionHotKeys={enableOptionHotKeys}
                    />
                  );
                }
              })()}
            </Menu.Group>
          ))}
        </Menu.Scrollable>
      );
  }
};

interface SharedItemProps {
  isMulti: boolean;
  selected?: OptionId | OptionId[] | null;
  disabled?: OptionId | OptionId[] | null;
  selectItem(val: OptionId): void;
  enableOptionHotKeys?: boolean;
}

interface StringArrayComponentProps {
  stringArray: OptionId[];
}

const KbdShortcutLabel: React.FC<{ visible: boolean; active: boolean; disabled: boolean }> = ({
  visible,
  active,
  disabled,
  children
}) => {
  return (
    <Typo.Body
      css={[
        css`
          height: 21px;
          text-align: center;
          width: 21px;
          transition: background 200ms ease-in;
        `,
        visible &&
          css`
            border: var(--border-regular);
            border-radius: var(--border-radius-s);
            box-shadow: var(--box-shadow-card);
          `,
        active &&
          css`
            background: var(--lightGrey-2);
          `,
        disabled &&
          css`
            border: var(--border-lightGrey);
          `
      ]}
      light
    >
      {children}
    </Typo.Body>
  );
};

const StringArrayComponent: React.FunctionComponent<StringArrayComponentProps & SharedItemProps> = ({
  isMulti,
  stringArray,
  selected,
  disabled,
  selectItem,
  enableOptionHotKeys
}) => {
  const [pressedHotKey, setPressedHotKey] = React.useState<string | null>(null);
  const {
    state: {
      meta: { enabled: kbdShortcutsEnabledForUser }
    }
  } = React.useContext(HotKeysContext);

  useHotKeys({
    name: "Select dropdown option",
    group: "dropdowns",
    keys:
      stringArray.length >= 10
        ? "0,1,2,3,4,5,6,7,8,9"
        : Array.from({ length: stringArray.length })
            .map((_, i) => i)
            .join(","),
    handler: e => {
      const idx = Number.parseInt(e.key, 10);
      const option = stringArray[idx];
      const isSelected = !selected ? false : !isMulti && selected === option ? true : selected?.indexOf(option) > -1;
      const isDisabled = !disabled ? false : !isMulti && disabled === option ? true : disabled?.indexOf(option) > -1;

      setPressedHotKey(e.key);
      window.setTimeout(() => setPressedHotKey(null), 1000);

      if (!isSelected && !isDisabled) {
        selectItem(option);
      }
    },
    // selected is changed on every successful mutation thus we use it
    // as a signal to generate a new memoised handler for handling the
    // hotkeys
    deps: [selected]
  });

  return (
    <>
      {stringArray.map((option: OptionId, index) => {
        const isSelected = !selected ? false : !isMulti && selected === option ? true : selected?.indexOf(option) > -1;
        const isDisabled = !disabled ? false : !isMulti && disabled === option ? true : disabled?.indexOf(option) > -1;
        return (
          <Menu.Item
            key={kebab(option)}
            option={option}
            selectItem={selectItem}
            selected={isSelected}
            disabled={isDisabled}
            keepOpen={isMulti}
          >
            {enableOptionHotKeys && kbdShortcutsEnabledForUser && (
              <KbdShortcutLabel visible={index < 10} active={index.toString() === pressedHotKey} disabled={isDisabled}>
                {index < 10 ? index : ""}
              </KbdShortcutLabel>
            )}
            <Typo.Body>{option}</Typo.Body>
            {isSelected && <MaterialIcon path={mdiCheck} size={1.5} />}
          </Menu.Item>
        );
      })}
    </>
  );
};

interface OptionArrayComponentProps {
  optionArray: OptionMap[];
}
const OptionArrayComponent: React.FunctionComponent<OptionArrayComponentProps & SharedItemProps> = ({
  isMulti,
  optionArray,
  selected,
  disabled,
  selectItem,
  enableOptionHotKeys
}) => {
  const [pressedHotKey, setPressedHotKey] = React.useState<string | null>(null);
  const {
    state: {
      meta: { enabled: kbdShortcutsEnabledForUser }
    }
  } = React.useContext(HotKeysContext);

  useHotKeys({
    name: "Select dropdown option",
    group: "dropdowns",
    keys:
      optionArray.length > 10
        ? "0,1,2,3,4,5,6,7,8,9"
        : Array.from({ length: optionArray.length })
            .map((_, i) => i)
            .join(","),
    handler: e => {
      const idx = Number.parseInt(e.key, 10);
      const option = optionArray[idx];
      const isSelected = !selected
        ? false
        : !isMulti && selected === option.id
        ? true
        : isMulti && selected?.indexOf(option.id) > -1;

      const isDisabled = option.disabled
        ? true
        : !disabled
        ? false
        : !isMulti && disabled === option.id
        ? true
        : isMulti && disabled?.indexOf(option.id) > -1;

      setPressedHotKey(e.key);
      window.setTimeout(() => setPressedHotKey(null), 1000);

      if (!isSelected && !isDisabled) {
        selectItem(option.id);
      }
    },
    // selected is changed on every successful mutation thus we use it
    // as a signal to generate a new memoised handler for handling the
    // hotkeys
    deps: [selected]
  });

  return (
    <>
      {optionArray.map((option: OptionMap, index) => {
        const isSelected = !selected
          ? false
          : !isMulti && selected === option.id
          ? true
          : isMulti && selected?.indexOf(option.id) > -1;

        const isDisabled = option.disabled
          ? true
          : !disabled
          ? false
          : !isMulti && disabled === option.id
          ? true
          : isMulti && disabled?.indexOf(option.id) > -1;

        return (
          <Menu.Item
            key={option.id}
            option={option}
            selectItem={selectItem}
            selected={isSelected}
            disabled={isDisabled}
            keepOpen={isMulti}
          >
            {enableOptionHotKeys && kbdShortcutsEnabledForUser && (
              <KbdShortcutLabel visible={index < 10} active={index.toString() === pressedHotKey} disabled={isDisabled}>
                {index < 10 ? index : ""}
              </KbdShortcutLabel>
            )}
            {option.icon && <span>{option.icon}</span>}
            {option.labelNode ?? <Typo.Body>{option.name}</Typo.Body>}
            {isSelected && <MaterialIcon path={mdiCheck} size={1.5} />}
          </Menu.Item>
        );
      })}
    </>
  );
};
