import * as Sentry from "@sentry/browser";
import { toInteger } from "lodash";
import * as React from "react";
import { TeamAddContainer } from "src/App/Settings/Teams/Add/Container";
import { Dialog, LoadingBar } from "src/components";
import { FormWrapper } from "src/components/Fields/FormWrappers";
import { StepIndicator } from "src/components/StepIndicator";
import { navigate, RouteComponentProps } from "src/util/router";
import { TeamInitContainer } from "./Init/Container";
import { hasTeamInfoInStorage } from "./Init/Form";
import { SelectCategories } from "./SelectCategories";

export interface ITeamCreateProps extends RouteComponentProps {
  step?: string;
  team_id?: string;
  redirect?: string;
}

const titles = {
  1: "Create team",
  2: "Add categories",
  3: "Invite team members"
};

/**
 * Form component for editing team details
 */
export const TeamCreateFlow: React.FunctionComponent<ITeamCreateProps> = props => {
  const step = toInteger(props.step) || 0;

  // The team ID is extracted from the URL query parameters, e.g. /create/step/3?teamid=foo.
  // Will be undefined if teamid doesn't exist in the current query parameters.
  const teamId = props.team_id || "";
  const redirect = props.redirect || "/settings/teams";
  const isValidStep =
    step in titles && (step === 1 || (step === 2 && hasTeamInfoInStorage()) || (step === 3 && !!teamId));

  if (!isValidStep) {
    Sentry.captureException({ message: "Invalid step", step });
  }

  return (
    <>
      {!isValidStep && <LoadingBar />}
      {isValidStep && (
        <Dialog title={titles[step as keyof typeof titles]} onClose={() => navigate("/settings/teams")}>
          <FormWrapper>
            {step === 1 && (
              <TeamInitContainer
                submitText="Continue"
                onSubmit={() => navigate(`/settings/teams/create/step/2`)}
                skippable={false}
                redirect={redirect}
              />
            )}
            {step === 2 && (
              <SelectCategories
                submitText="Add categories"
                onSubmit={newTeamId => navigate(`/settings/teams/create/step/3/${newTeamId}`)}
                redirect={redirect}
              />
            )}
            {step === 3 && (
              <TeamAddContainer
                submitText="Create team"
                team_id={teamId}
                preselectCurrentUser={true}
                redirect={redirect}
                skippable={true}
                skipText="Skip step"
              />
            )}
            <StepIndicator currentStep={step} stepCount={3} />
          </FormWrapper>
        </Dialog>
      )}
    </>
  );
};
