import { css } from "@emotion/core";
import * as React from "react";
import { FC } from "react";
import { AbsoluteTooltip, FormikFieldGroup, UnderlineText } from "src/components";
import { PopOverMenu } from "src/components/PopOver/PopOverMenu";
import { RequestTriggerBaseInput, TriggerInputMap, TriggerKey, WorkflowParameterType } from "src/globalTypes";
import { Typo } from "src/styling/primitives/typography";
import { keys } from "src/util";
import {
  CategoryMatcherComponent,
  MatcherValueContainer,
  SelectTrigger,
  StatusMatcherComponent,
  TeamMatcherComponent,
  UserMatcherComponent,
  useWorkflow
} from "./components";
import { TextAreaWithVariables } from "./components/TextArea";
import { WfStepTable } from "./Steps";

const RequestTriggerBaseConditionTable: FC<{
  requestTrigger: RequestTriggerBaseInput;
  onChange(value: RequestTriggerBaseInput): void;
  actorLabel?: string;
}> = ({ requestTrigger, actorLabel, onChange }) => {
  return (
    <>
      <Typo.Subheading light>Conditions</Typo.Subheading>
      <WfStepTable>
        <RequestTriggerBaseCondition requestTrigger={requestTrigger} actorLabel={actorLabel} onChange={onChange} />
      </WfStepTable>
    </>
  );
};

const RequestTriggerBaseCondition: FC<{
  requestTrigger: RequestTriggerBaseInput;
  onChange(value: RequestTriggerBaseInput): void;
  actorLabel?: string;
}> = ({ requestTrigger, onChange, actorLabel }) => {
  const { workflow } = useWorkflow();
  return (
    <>
      {requestTrigger.actor && (
        <tr>
          <td>{actorLabel ?? "Actor"} is set to:</td>
          <td>
            <UserMatcherComponent
              matcher={requestTrigger.actor}
              onChange={user => onChange({ ...requestTrigger, actor: user })}
            />
          </td>
        </tr>
      )}
      {requestTrigger.team && (
        <tr>
          <td>Team is set to:</td>
          <td>
            <TeamMatcherComponent
              matcher={requestTrigger.team}
              onChange={team => onChange({ ...requestTrigger, team: team })}
            />
          </td>
        </tr>
      )}
      {requestTrigger.status && workflow?.teamId && (
        <tr>
          <td>Status is set to:</td>
          <td>
            <StatusMatcherComponent
              teamId={workflow.teamId}
              matcher={requestTrigger.status}
              onChange={status => onChange({ ...requestTrigger, status: status })}
            />
          </td>
        </tr>
      )}
      {requestTrigger.category && workflow?.teamId && (
        <tr>
          <td>Category is set to:</td>
          <td>
            <CategoryMatcherComponent
              teamId={workflow.teamId}
              matcher={requestTrigger.category}
              onChange={category => onChange({ ...requestTrigger, category: category })}
            />
          </td>
        </tr>
      )}
      {requestTrigger.titleContains && (
        <tr>
          <td>Title contains:</td>
          <td>
            <TextAreaWithVariables
              type="plaintext"
              initialValue={requestTrigger.titleContains.anyList.join(", ")}
              onChange={text =>
                onChange({
                  ...requestTrigger,
                  titleContains: { anyList: text.split(",").map(t => t.trimStart()) }
                })
              }
              helperComponent={
                <FormikFieldGroup.HelpText>Enter text to match, separated by commas</FormikFieldGroup.HelpText>
              }
            />
          </td>
        </tr>
      )}
      {requestTrigger.descriptionContains && workflow?.teamId && (
        <tr>
          <td>Description contains:</td>
          <td>
            <TextAreaWithVariables
              type="plaintext"
              initialValue={requestTrigger.descriptionContains.anyList.join(", ")}
              onChange={text =>
                onChange({
                  ...requestTrigger,
                  descriptionContains: { anyList: text.split(",").map(t => t.trimStart()) }
                })
              }
              helperComponent={
                <FormikFieldGroup.HelpText>Enter text to match, separated by commas</FormikFieldGroup.HelpText>
              }
            />
          </td>
        </tr>
      )}
    </>
  );
};

const RequestTriggerCollapsedLabel: FC<{
  requestTrigger: RequestTriggerBaseInput;
  exclude?: keyof RequestTriggerBaseInput;
  actorLabel?: string;
}> = ({ requestTrigger, exclude, actorLabel }) => {
  const additionalConditionCount = Object.entries(requestTrigger)
    .map(([name, value]) => (name !== exclude && value ? 1 : 0) as number)
    .reduce((a, b) => a + b, 0);

  if (additionalConditionCount === 0) return <></>;

  if (additionalConditionCount === 1) {
    return (
      <>
        <span> and </span>
        <div
          css={[
            css`
              display: inline;

              > div {
                display: inline;
              }
            `
          ]}
        >
          <RequestTriggerCollapsedLabelContent
            requestTrigger={requestTrigger}
            exclude={exclude}
            actorLabel={actorLabel}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <AbsoluteTooltip
        css={[
          css`
            display: inline;
          `
        ]}
        zIndex={"high"}
        placement="bottom"
        content={
          <RequestTriggerCollapsedLabelContent
            requestTrigger={requestTrigger}
            exclude={exclude}
            actorLabel={actorLabel}
          />
        }
      >
        and <UnderlineText>{additionalConditionCount} other conditions</UnderlineText>
      </AbsoluteTooltip>
    </>
  );
};

const RequestTriggerCollapsedLabelContent: FC<{
  requestTrigger: RequestTriggerBaseInput;
  exclude?: keyof RequestTriggerBaseInput;
  actorLabel?: string;
}> = ({ requestTrigger, exclude, actorLabel }) => {
  const { workflow } = useWorkflow();
  return (
    <>
      {exclude !== "actor" && requestTrigger.actor && (
        <div>
          by {actorLabel ?? "actor"} <MatcherValueContainer>{requestTrigger.actor.email}</MatcherValueContainer>
        </div>
      )}
      {exclude !== "team" && requestTrigger.team && (
        <div>
          team is set to <TeamMatcherComponent matcher={requestTrigger.team} />
        </div>
      )}
      {exclude !== "status" && requestTrigger.status && workflow?.teamId && (
        <div>
          status is set to <StatusMatcherComponent teamId={workflow.teamId} matcher={requestTrigger.status} />
        </div>
      )}
      {exclude !== "category" && requestTrigger.category && workflow?.teamId && (
        <div>
          with category <CategoryMatcherComponent teamId={workflow.teamId} matcher={requestTrigger.category} />
        </div>
      )}
      {exclude !== "titleContains" && requestTrigger.titleContains && (
        <div>
          the title contains{" "}
          <MatcherValueContainer>{requestTrigger.titleContains.anyList.join(", ")}</MatcherValueContainer>
        </div>
      )}
      {exclude !== "descriptionContains" && requestTrigger.descriptionContains && (
        <div>
          the description contains{" "}
          <MatcherValueContainer>{requestTrigger.descriptionContains.anyList.join(", ")}</MatcherValueContainer>
        </div>
      )}
    </>
  );
};

interface TriggerProps<K extends TriggerKey> {
  trigger: NonNullable<TriggerInputMap[K]>;
  onChange(value: Partial<NonNullable<TriggerInputMap[K]>>): void;
  expanded: boolean;
}

export const RequestCreatedTriggerCard: React.FC<TriggerProps<TriggerKey.WorkflowTriggerRequestCreated>> = props => {
  const createdInSpecificTeam = props.trigger.requestTriggerBase.team;
  return props.expanded ? (
    <div
      css={[
        css`
          & > * {
            margin-bottom: var(--space-3-rem);
          }
        `
      ]}
    >
      <Typo.Body sizeXXL>Request created</Typo.Body>
      <Typo.Body>Whenever a new request is created{!createdInSpecificTeam && " in this team"}</Typo.Body>
      {props.trigger.requestTriggerBase && (
        <RequestTriggerBaseConditionTable
          requestTrigger={props.trigger.requestTriggerBase}
          onChange={requestTriggerBase => props.onChange({ requestTriggerBase })}
        />
      )}
    </div>
  ) : createdInSpecificTeam ? (
    <Typo.Body sizeL>
      Request is created in <TeamMatcherComponent matcher={createdInSpecificTeam} />
    </Typo.Body>
  ) : (
    <Typo.Body sizeL>
      Request is created in this team <RequestTriggerCollapsedLabel requestTrigger={props.trigger.requestTriggerBase} />
    </Typo.Body>
  );
};

export const RequestStatusChangedTriggerCard: React.FC<TriggerProps<
  TriggerKey.WorkflowTriggerRequestStatusChanged
>> = props => {
  const { workflow } = useWorkflow();
  if (!workflow) return null;
  const selectedStatus = props.trigger.requestTriggerBase.status;
  return props.expanded ? (
    <div
      css={[
        css`
          & > * {
            margin-bottom: var(--space-3-rem);
          }
        `
      ]}
    >
      <Typo.Body sizeXXL>Request status changed</Typo.Body>
      {props.trigger.requestTriggerBase && (
        <RequestTriggerBaseConditionTable
          requestTrigger={props.trigger.requestTriggerBase}
          onChange={requestTriggerBase => props.onChange({ requestTriggerBase })}
        />
      )}
    </div>
  ) : (
    <Typo.Body sizeL>
      Status of a request is set to{" "}
      <StatusMatcherComponent matcher={selectedStatus ?? null} teamId={workflow.teamId} emptyLabel="any status" />{" "}
      <RequestTriggerCollapsedLabel requestTrigger={props.trigger.requestTriggerBase} exclude={"status"} />
    </Typo.Body>
  );
};

export const RequestCategoryChangedTriggerCard: React.FC<TriggerProps<
  TriggerKey.WorkflowTriggerRequestCategoryChanged
>> = props => {
  const { workflow } = useWorkflow();
  if (!workflow) return null;
  const selectedCategory = props.trigger.requestTriggerBase.category;
  return props.expanded ? (
    <div
      css={[
        css`
          & > * {
            margin-bottom: var(--space-3-rem);
          }
        `
      ]}
    >
      <Typo.Body sizeXXL>Request category changed</Typo.Body>
      {props.trigger.requestTriggerBase && (
        <RequestTriggerBaseConditionTable
          requestTrigger={props.trigger.requestTriggerBase}
          onChange={requestTriggerBase => props.onChange({ requestTriggerBase })}
        />
      )}
    </div>
  ) : (
    <Typo.Body sizeL>
      Category of a request is set to{" "}
      <CategoryMatcherComponent matcher={selectedCategory ?? null} teamId={workflow.teamId} emptyLabel="any category" />{" "}
      <RequestTriggerCollapsedLabel requestTrigger={props.trigger.requestTriggerBase} exclude={"category"} />
    </Typo.Body>
  );
};

export const RequestCommentAddedTriggerCard: React.FC<TriggerProps<
  TriggerKey.WorkflowTriggerRequestCommentAdded
>> = props => {
  return props.expanded ? (
    <div
      css={[
        css`
          & > * {
            margin-bottom: var(--space-3-rem);
          }
        `
      ]}
    >
      <Typo.Body sizeXXL>Comment added to request</Typo.Body>
      <Typo.Body>Whenever there is a new comment added to a request </Typo.Body>
      {props.trigger.requestTriggerBase && (
        <RequestTriggerBaseConditionTable
          requestTrigger={props.trigger.requestTriggerBase}
          onChange={requestTriggerBase => props.onChange({ requestTriggerBase })}
          actorLabel="Author"
        />
      )}
    </div>
  ) : (
    <>
      <Typo.Body sizeL>There is a new comment added to a request</Typo.Body>{" "}
      <RequestTriggerCollapsedLabel requestTrigger={props.trigger.requestTriggerBase} actorLabel="author" />
    </>
  );
};

export const RequestTeamChangedTriggerCard: React.FC<TriggerProps<
  TriggerKey.WorkflowTriggerRequestTeamChanged
>> = props => {
  const selectedTeam = props.trigger.requestTriggerBase.team;
  return props.expanded ? (
    <div
      css={[
        css`
          & > * {
            margin-bottom: var(--space-3-rem);
          }
        `
      ]}
    >
      <Typo.Body sizeXXL>Request team changed</Typo.Body>
      {props.trigger.requestTriggerBase && (
        <RequestTriggerBaseConditionTable
          requestTrigger={props.trigger.requestTriggerBase}
          onChange={requestTriggerBase => props.onChange({ requestTriggerBase })}
        />
      )}
    </div>
  ) : (
    <Typo.Body sizeL>
      Request is assigned to <TeamMatcherComponent matcher={selectedTeam ?? null} emptyLabel="this team" />{" "}
      <RequestTriggerCollapsedLabel requestTrigger={props.trigger.requestTriggerBase} />
    </Typo.Body>
  );
};

export const HRISOnboardingTriggerCard: React.FC<TriggerProps<TriggerKey.WorkflowTriggerHRISOnboarding>> = props => {
  return props.expanded ? (
    <div
      css={[
        css`
          & > * {
            margin-bottom: var(--space-3-rem);
          }
        `
      ]}
    >
      <Typo.Body sizeXXL>New employee</Typo.Body>
      <Typo.Body>Whenever a new employee is added to HR system </Typo.Body>
    </div>
  ) : (
    <Typo.Body sizeL>New employee is added to HR system</Typo.Body>
  );
};

export const RecurringTriggerCard: React.FC<TriggerProps<TriggerKey.WorkflowTriggerRecurring>> = props => {
  return props.expanded ? (
    <div
      css={[
        css`
          & > * {
            margin-bottom: var(--space-3-rem);
          }
        `
      ]}
    >
      <Typo.Body sizeXXL>Scheduled execution</Typo.Body>
      <Typo.Body>On a regular schedule {props.trigger.cron}</Typo.Body>
    </div>
  ) : (
    <Typo.Body sizeL>Every {props.trigger.cron}</Typo.Body>
  );
};

const paramTypeNames: Record<WorkflowParameterType, string> = {
  DATE: "Date",
  DROPDOWN: "Dropdown",
  EMAIL: "Email",
  NUMBER: "Number",
  STRING: "String"
};

export const ManualTriggerCard: React.FC<TriggerProps<TriggerKey.WorkflowTriggerManual>> = props => {
  return props.expanded ? (
    <div
      css={[
        css`
          & > * {
            margin-bottom: var(--space-3-rem);
          }
        `
      ]}
    >
      <Typo.Body sizeXXL>Manual execution</Typo.Body>
      <Typo.Body>The workflow is triggered manually </Typo.Body>
      {props.trigger.parameters && <Typo.Body>Parameters:</Typo.Body>}
      <div
        css={css`
          & > * + * {
            margin-top: var(--space-4-rem);
          }
        `}
      >
        {props.trigger.parameters?.map((p, i) => (
          <WfStepTable
            key={i}
            css={css`
              width: 100%;
            `}
          >
            <tr>
              <td>Name:</td>
              <td>
                <TextAreaWithVariables
                  type="plaintext"
                  initialValue={p.name}
                  onChange={name =>
                    props.onChange({
                      parameters: [
                        ...(props.trigger.parameters?.slice(0, i) ?? []),
                        {
                          ...p,
                          name
                        },
                        ...(props.trigger.parameters?.slice(i + 1) ?? [])
                      ]
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Type:</td>
              <td>
                <PopOverMenu
                  trigger={<SelectTrigger>{paramTypeNames[p.type]}</SelectTrigger>}
                  selected={p.type}
                  options={keys(WorkflowParameterType).map(paramType => ({
                    id: paramType,
                    name: paramTypeNames[paramType]
                  }))}
                  onSelect={(paramType: WorkflowParameterType) =>
                    props.onChange({
                      parameters: [
                        ...(props.trigger.parameters?.slice(0, i) ?? []),
                        {
                          ...p,
                          type: paramType
                        },
                        ...(props.trigger.parameters?.slice(i + 1) ?? [])
                      ]
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Description:</td>
              <td>
                <TextAreaWithVariables
                  type="plaintext"
                  initialValue={p.description}
                  onChange={description =>
                    props.onChange({
                      parameters: [
                        ...(props.trigger.parameters?.slice(0, i) ?? []),
                        {
                          ...p,
                          description
                        },
                        ...(props.trigger.parameters?.slice(i + 1) ?? [])
                      ]
                    })
                  }
                />
              </td>
            </tr>
          </WfStepTable>
        ))}
      </div>
    </div>
  ) : (
    <Typo.Body sizeL>Workflow is triggered manually from a request</Typo.Body>
  );
};

export const KnowledgeAnswerTriggerCard: React.FC<TriggerProps<TriggerKey.WorkflowTriggerKnowledgeAnswer>> = props => {
  return props.expanded ? (
    <>
      <Typo.Body sizeXXL>Workflow is triggered from a knowledge suggestion</Typo.Body>
    </>
  ) : (
    <Typo.Body sizeL>Workflow is triggered from a knowledge suggestion</Typo.Body>
  );
};
