import * as React from "react";

import { Col, LineBreak, LoadingBar, Row, Text } from "src/components";
import { Completed, charOfIndex } from "./CreateEdit";
import { FormGet, FormGetVariables } from "src/App/Forms/Employee/typings/FormGet";
import { SchemaList_schemaList, SchemaList_schemaList_fieldsList } from "./typings/SchemaList";
import { fieldName, resolveFieldType } from "src/App/Forms/helpers";

import { BestPracticeGet_bestPractice_forms } from "src/App/BestPractices/DetailView/typings/BestPracticeGet";
import { FORM_GET } from "src/App/Forms/Employee/Form";
import { Typo } from "src/styling/primitives/typography";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { useQuery } from "react-apollo";

const CompletedPreview = styled(Completed)`
  cursor: auto;
  &:hover {
    border: 1px solid transparent;
  }
`;

type FormPreviewFields = {
  displayName: string;
  description: string;
  /* these don't include response */
  fieldsList: SchemaList_schemaList_fieldsList[];
};

export const FormPreview: React.FC<{
  formId?: string;
  schema?: SchemaList_schemaList | BestPracticeGet_bestPractice_forms;
}> = props => {
  const formResponse = useQuery<FormGet, FormGetVariables>(FORM_GET, {
    fetchPolicy: "network-only",
    variables: {
      id: props.formId ?? ""
    },
    skip: !props.formId
  });
  const formSchema = formResponse.data?.form.schema;
  const formPreview: FormPreviewFields | null = formSchema ? formSchema : props.schema ?? null;
  return (
    <>
      {formResponse.loading && <LoadingBar />}
      {formPreview && (
        <Col>
          <Row
            css={css`
              margin-bottom: var(--space-5-rem);
            `}
          >
            <Typo.Body sizeXXL>{formPreview.displayName}</Typo.Body>
          </Row>
          <CompletedPreview
            css={css`
              margin: 0 0 1rem 0;
            `}
          >
            <Col flex="1 1 auto">
              <Row className="label">Message shared with the employee together with the form:</Row>
              <Typo.Body
                css={css`
                  margin-top: var(--space-2-rem);
                `}
              >
                {formPreview.description.split("\n").map(text => (
                  <>
                    {text}
                    <br />
                  </>
                ))}
              </Typo.Body>
            </Col>
          </CompletedPreview>
          {formPreview.fieldsList.map((field, i) => (
            <CompletedPreview
              key={i}
              css={[
                css`
                  display: flex;
                  align-items: flex-start;
                  & > * {
                    flex-shrink: 0;
                  }
                  & > * + * {
                    margin-left: var(--space-3-rem);
                  }
                `
              ]}
            >
              <Typo.Body light>{`${i + 1}`.padStart(2, "0")}.</Typo.Body>
              {field.displayName.trim() && (
                <>
                  <div
                    css={[
                      css`
                        display: flex;
                        flex-direction: column;
                        flex: 1 1 auto;
                        & > * + * {
                          margin-top: var(--space-2-rem);
                        }
                      `
                    ]}
                  >
                    <Typo.Body>
                      <LineBreak text={field.displayName} asterisk={field.required} />
                    </Typo.Body>
                    {field.__typename === "DropdownField" &&
                      field.optionsList &&
                      field.optionsList.map((option, j) => (
                        <Typo.Body key={j}>
                          <Text
                            lineHeight="unset"
                            css={css`
                              color: var(--text-4);
                            `}
                          >
                            {charOfIndex(j)}.
                          </Text>
                          &nbsp;{option}
                        </Typo.Body>
                      ))}
                  </div>
                  <Typo.Body lighter>
                    {fieldName(
                      resolveFieldType(field.__typename),
                      "textFieldType" in field ? field.textFieldType : undefined
                    )}
                  </Typo.Body>
                </>
              )}
            </CompletedPreview>
          ))}
        </Col>
      )}
    </>
  );
};
