import { css } from "@emotion/core";
import * as React from "react";
import { ReactNode, useRef, useState } from "react";
import { subHeadingStyles } from "src/styling/primitives/typography";
import { reportDevError } from "src/util";
import { csx } from "src/util/csx";
import { useUrlState } from "src/util/router";

const animationTime = 350;

const TabHeading = csx(
  [
    subHeadingStyles,
    css`
      font-weight: var(--font-weight-body-medium);
      padding: var(--space-3-rem) 0;
      padding: 3px 0;
    `
  ],
  {
    clickable: css`
      color: var(--text-2);
      cursor: pointer;
      transition: all ${animationTime}ms ease;
      border-bottom: 1px solid var(--white);
      &:hover {
        color: var(--text-6);
      }
    `,
    active: css`
      font-weight: var(--font-weight-body-semiBold);
      border-bottom: 1px solid var(--text-6);
      color: var(--text-6);
    `
  }
);

interface TabItem<IdType extends string> {
  /** override default string index */
  id?: IdType;
  headline: string;
  content: ReactNode;
  shouldNotDisplay?: boolean;
  dataIntercomTarget?: string;
}

interface TabsProps<IdType extends string, Items extends TabItem<IdType>> {
  /** tab id must match an explicitly passed id in items */
  initialTabId?: Items["id"];
  transitionHeight?: boolean;
  className?: string;
  "data-testid"?: string;
  items: Items[];
}

export const Tabs = <IdType extends string, Items extends TabItem<IdType>>({
  items: _items,
  initialTabId,
  transitionHeight,
  className,
  ...rest
}: TabsProps<IdType, Items>) => {
  // filter out items that should not be displayed (e.g. feature flagged items) and initialize string ids
  const items = _items
    .filter(tab => !tab.shouldNotDisplay)
    .map((tab, i) => ({
      // default id, can overwritten by `tab` object below
      id: String(i),
      ...tab
    }));
  const [currentTabId, setCurrentTabId] = useUrlState("tab", initialTabId ?? "0");
  const activeTabIndex = items.indexOf(items.find(tab => tab.id === currentTabId) ?? items[0]);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
  const [showAllTabs, setShowAllTabs] = useState<boolean>(false);
  const transitionToTabId = (id: string) => {
    setCurrentTabId(id);
    setIsTransitioning(true);
    setShowAllTabs(true);
    setTimeout(() => {
      setShowAllTabs(false);
      setTimeout(() => {
        // Another timeout with small delay to wait for
        // call stack and component rerender to finish
        setIsTransitioning(false);
      }, 150);
    }, animationTime);
  };
  const hasMultipleTabs = items.length > 1;

  const resizeObserver = useRef<ResizeObserver>(
    new ResizeObserver((entries: ResizeObserverEntry[]) => {
      // there should only be one entry for the active tab
      if (entries.length > 1) reportDevError(`Too many tab entries: ${entries.length}`);
      const [entry] = entries;
      setContainerHeight(entry.contentRect.height ?? 0);
    })
  );
  return (
    <div
      className={className}
      data-testid={rest["data-testid"]}
      ref={element => {
        if (!element) return;
        setContainerWidth(element.getBoundingClientRect().width);
      }}
      css={[
        css`
          max-width: 100%;
        `,
        isTransitioning &&
          css`
            overflow: hidden;
          `
      ]}
    >
      <div
        css={[
          css`
            display: flex;
            margin-bottom: var(--space-4-rem);
            & > * + * {
              margin-left: var(--space-4-rem);
            }
          `
        ]}
      >
        {items.map(tab => (
          <TabHeading
            data-testid={`tab-heading-${tab.id}`}
            data-intercom-target={tab.dataIntercomTarget || ""}
            key={`tabButton-${tab.headline}`}
            onClick={() => {
              if (currentTabId !== tab.id && !isTransitioning) {
                transitionToTabId(tab.id);
              }
            }}
            active={currentTabId === tab.id}
            clickable={hasMultipleTabs}
          >
            {tab.headline}
          </TabHeading>
        ))}
      </div>
      <div
        css={[
          css`
            width: 100%;
          `
        ]}
      >
        <div
          css={[
            css`
              width: ${Math.floor(containerWidth * items.length + 32 * (items.length - 1))}px;
              display: grid;
              grid-gap: 32px;
              grid-template-columns: repeat(${items.length}, 1fr);
              transition: transform ${animationTime}ms ease;
              // transform by currently active tab
              // and also deduct content spacer of 32px
              transform: translateX(-${Math.floor(containerWidth * activeTabIndex + 32 * activeTabIndex)}px);
            `,
            transitionHeight &&
              css`
                transition: transform ${animationTime}ms ease, max-height ${animationTime}ms ease;
                max-height: ${containerHeight}px;
              `
          ]}
        >
          {items.map(tab => (
            <div
              css={[
                css`
                  visibility: hidden;
                  pointer-events: none;
                `,
                (tab.id === currentTabId || showAllTabs) &&
                  css`
                    visibility: visible;
                    pointer-events: all;
                  `,
                transitionHeight &&
                  css`
                    transition: max-height ${animationTime}ms ease;
                    max-height: ${containerHeight}px;
                  `
              ]}
              data-testid={`tab-content-${tab.id}`}
              key={`tabContent-${tab.headline}`}
            >
              <div
                ref={element => {
                  if (element && tab.id === currentTabId) {
                    // disconnect any elements which are not the active tab
                    resizeObserver.current.disconnect();
                    resizeObserver.current.observe(element);
                  }
                }}
              >
                {tab.content}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
