import gql from "graphql-tag";
import * as React from "react";
import { Query } from "react-apollo";
import { LoadingBar } from "src/components";
import { FormWrapper } from "src/components/Fields/FormWrappers";
import { ORG_INFO_FRAGMENT } from "src/fragments/OrgInfoFragment";
import { USER_INFO_FRAGMENT } from "src/fragments/UserInfoFragment";
import { TeamPrivacy } from "src/globalTypes";
import { Toast } from "src/portals/Toast";
import { RouteComponentProps } from "src/util/router";
import { TeamInitForm } from "./Form";
import { GSettingsTeamInitGet } from "./typings/GSettingsTeamInitGet";

const SETTINGS_TEAM_INIT_GET = gql`
  query GSettingsTeamInitGet {
    currentUser {
      ...UserInfo
      organization {
        ...OrgInfo
      }
    }
    slackChannels {
      slackName
      slackId
      isAssigned
    }
  }
  ${USER_INFO_FRAGMENT}
  ${ORG_INFO_FRAGMENT}
`;

export interface ITeamInfo {
  name: string;
  privacy: TeamPrivacy;
  slug: string;
  slackChannels: string[];
}

export interface ITeamInitProps extends RouteComponentProps {
  redirect?: string;
  title?: string;
  subtitle?: string;
  skippable?: boolean;
  skipText?: string;
  submitText?: string;
  bigButton?: boolean;
  onSubmit?: (data: ITeamInfo) => void;
}

export const TeamInitContainer: React.ComponentType<ITeamInitProps> = props => (
  <FormWrapper data-testid="team-init">
    <Query<GSettingsTeamInitGet, {}> query={SETTINGS_TEAM_INIT_GET}>
      {({ data, error, loading }) => {
        if (loading) {
          return <LoadingBar />;
        }
        if (error) {
          return <Toast message={error.message} kind="error" />;
        }
        return (!loading && data && data.currentUser && <TeamInitForm {...props} {...data} />) || null;
      }}
    </Query>
  </FormWrapper>
);
