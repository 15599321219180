import * as React from "react";
import { Tooltip } from "src/components/Tooltip";

export const ConditionalTooltip: React.FC<{ children: React.ReactElement; tooltipText?: string }> = ({
  tooltipText,
  children
}) =>
  tooltipText ? (
    <Tooltip placement="top" target={children}>
      <span>{tooltipText}</span>
    </Tooltip>
  ) : (
    <div>{children}</div>
  );
