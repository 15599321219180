import css from "@emotion/css";
import styled from "@emotion/styled";
import { ErrorMessage, Field, FieldProps, Formik } from "formik";
import { pick } from "lodash";
import * as React from "react";
import { MutationFunction } from "react-apollo";
import { categoryList } from "src/App/Settings//defaultCategories";
import {
  CancelButton,
  Col,
  FormikFieldGroup,
  FormikFieldInputs,
  FormikInput,
  Input,
  LoadingBar,
  Row,
  SubmitButton
} from "src/components";
import { FormikMultiSelect } from "src/components/Fields/MultiSelect";
import { DayOfWeek } from "src/globalTypes";
import { justify } from "src/styling/primitives";
import { Typo } from "src/styling/primitives/typography";
import { navigate } from "src/util/router";
import * as yup from "yup";
import { ITeamUpdateProps } from "./Container";
import { GSettingsTeamGet, GSettingsTeamGet_team_workingHours } from "./typings/GSettingsTeamGet";
import { GSettingsTeamUpdate, GSettingsTeamUpdateVariables } from "./typings/GSettingsTeamUpdate";

const Form = styled.form`
  & .heading {
    border: 1px solid var(--border);
  }

  & .close {
    color: var(--border);
  }
`;

/**
 * Validation rules
 */
const categoriesSchema = yup.array(
  yup.object().shape({
    name: yup.string().max(36, "Category is too long"),
    id: yup.string().nullable(true)
  })
);

const workingHoursSchema = yup
  .object()
  .shape({
    startHour: yup.number().integer().min(0).max(23).required(),
    startMinute: yup.number().integer().min(0).max(59).required(),
    endHour: yup.number().integer().min(0).max(23).required(),
    endMinute: yup.number().integer().min(0).max(59).required()
  })
  .test("validate-working-hours", "Start of working hours should be smaller than end", function (val) {
    const startTime = Number.parseInt(`${val.startHour}${val.startMinute.toString().padEnd(2, "0")}`, 10);
    const endTime = Number.parseInt(`${val.endHour}${val.endMinute.toString().padEnd(2, "0")}`, 10);

    return startTime < endTime;
  });

const FormSchemaWithWorkingHours = yup.object().shape({
  name: yup.string().required("Required"),
  slackChannels: yup
    .array(
      yup.object().shape({
        label: yup.string(),
        value: yup.string()
      })
    )
    .nullable(true),
  categories: categoriesSchema,
  workingHours: workingHoursSchema
});

/**
 * Submission handler
 * @param teamUpdate apollo mutation fn
 * @param redirect url path to redirect after submission
 * @param params mutation variable object
 */
const submitTeamUpdateMutation = (
  teamUpdate: MutationFunction<GSettingsTeamUpdate, GSettingsTeamUpdateVariables>,
  redirect: string,
  params: GSettingsTeamUpdateVariables["params"]
) => {
  teamUpdate({
    update: (_cache, response) => {
      if (response.data && response.data.teamUpdate.success) {
        navigate(redirect);
      }
    },
    variables: {
      params
    }
  });
};

type WorkingHoursFormikBagShape = {
  [key in Exclude<keyof GSettingsTeamGet_team_workingHours, "id" | "__typename" | "dayOfWeek">]: string;
};
const prepareWorkingHoursPayload = (workingHours: WorkingHoursFormikBagShape) => {
  const parsedWorkingHours = {
    startHour: Number.parseInt(workingHours.startHour, 10),
    startMinute: Number.parseInt(workingHours.startMinute, 10),
    endHour: Number.parseInt(workingHours.endHour, 10),
    endMinute: Number.parseInt(workingHours.endMinute, 10)
  };

  // copy the working hours for each day of the week as
  // that's how berghain expects it
  return (
    Object.values(DayOfWeek)
      // we assume working days are Monday to Friday for now
      // Backend supports all to leave the chance to configure working days
      // per team in the future.
      .filter(d => d !== DayOfWeek.DOW_UNSPECIFIED && d !== DayOfWeek.SATURDAY && d !== DayOfWeek.SUNDAY)
      .map(dayOfWeek => ({
        dayOfWeek,
        ...parsedWorkingHours
      }))
  );
};

/**
 * Form component for editing team details
 */
export const TeamUpdateForm: React.ComponentType<
  GSettingsTeamGet &
    ITeamUpdateProps & {
      teamUpdate: MutationFunction<GSettingsTeamUpdate, GSettingsTeamUpdateVariables>;
    }
> = props => {
  // need the value with all strings as the default initial value so
  // that react doesn't treat the working hours inputs as uncontrolled
  const initialWorkingHours = Object.entries(
    // while the backend supports different working hours per day of the week,
    // as per designs we show only from and to controls once and those times
    // apply to all weekdays
    pick(props.team.workingHours[0], ["startHour", "startMinute", "endHour", "endMinute"])
  ).reduce((acc, [key, value]: [string, string | number]) => {
    acc[key] = value.toString().padStart(2, "0"); // to pad single digit hour with a preceding 0 e.g., 08

    return acc;
  }, {} as { [key: string]: string }) as WorkingHoursFormikBagShape;

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={payload => {
        submitTeamUpdateMutation(props.teamUpdate, props.redirect, {
          id: props.team.id,
          name: payload.name,
          internal: payload.internal,
          slackChannelIds: (payload.slackChannels || []).map(s => s.value),
          // strip "__typename" field from payload
          categories:
            payload && payload.categories && payload.categories.length
              ? payload.categories.map(c => ({ name: c.label, id: c.label === c.value ? null : c.value })) // null to create a new custom category
              : [],
          workingHours: prepareWorkingHoursPayload(payload.workingHours)
        });
      }}
      initialValues={{
        name: props.team.name,
        internal: props.team.internal,
        slackChannels: (props.team.slackChannels || []).map(s => ({ label: s.slackName, value: s.slackId })),
        categories: (props.team.categories ?? []).map(c => ({
          label: c.name,
          value: c.id
        })),
        workingHours: initialWorkingHours
      }}
      validationSchema={FormSchemaWithWorkingHours}
      render={form => (
        <Form onSubmit={form.handleSubmit}>
          <Col>
            <Field name="name" component={FormikInput} label={"Name"} />
            <FormikFieldGroup.Container legend="Team visibility">
              <FormikFieldInputs.Checkbox name="internal" label="Internal only" />
              <FormikFieldGroup.HelpText>
                Internal-only teams are not visible to employees in Slack, MS Teams, and Google Chat
              </FormikFieldGroup.HelpText>
              <FormikFieldGroup.Errors>
                <ErrorMessage name="internal" />
              </FormikFieldGroup.Errors>
            </FormikFieldGroup.Container>
            <FormikFieldGroup.Container legend="Working hours">
              <div
                css={css`
                  display: flex;
                  align-items: center;

                  & > * + * {
                    margin-left: var(--space-4-rem);
                  }

                  & > div {
                    display: flex;
                    align-items: center;

                    /*  FF flex item width fix from https://moduscreate.com/blog/how-to-fix-overflow-issues-in-css-flex-layouts */
                    min-width: 0;
                    width: 50%;

                    & > input {
                      min-width: 0;
                    }

                    & > input:first-of-type {
                      border-top-right-radius: 0;
                      border-bottom-right-radius: 0;
                    }

                    & > span:first-of-type {
                      padding: 0.6875rem 0.3125rem;
                      background: var(--lightGrey-1);
                      font-size: var(--space-3-rem);
                      border-top: 1px solid var(--lightGrey-4);
                      border-bottom: 0.0625rem solid var(--lightGrey-4);
                    }

                    & > input:nth-of-type(2) {
                      border-top-left-radius: 0;
                      border-bottom-left-radius: 0;
                    }
                  }
                `}
              >
                <div>
                  <Field name="workingHours.startHour">
                    {(formikFieldProps: FieldProps) => (
                      <Input {...formikFieldProps.field} placeholder="HH" type="number" min="0" max="23" />
                    )}
                  </Field>
                  <span>:</span>
                  <Field name="workingHours.startMinute">
                    {(formikFieldProps: FieldProps) => (
                      <Input {...formikFieldProps.field} placeholder="MM" type="number" min="0" max="59" />
                    )}
                  </Field>
                </div>
                <span></span>
                <div>
                  <Field name="workingHours.endHour">
                    {(formikFieldProps: FieldProps) => (
                      <Input {...formikFieldProps.field} placeholder="HH" type="number" min="0" max="23" />
                    )}
                  </Field>
                  <span>:</span>
                  <Field name="workingHours.endMinute">
                    {(formikFieldProps: FieldProps) => (
                      <Input {...formikFieldProps.field} placeholder="MM" type="number" min="0" max="59" />
                    )}
                  </Field>
                </div>
              </div>

              <FormikFieldGroup.HelpText
                css={css`
                  margin-top: var(--space-2-rem);
                `}
              >
                Set in 24h clock in the {props.team.timezone} timezone. Working hours affect the{" "}
                <Typo.TextLink light onClick={() => navigate("/settings/slas")}>
                  SLAs
                </Typo.TextLink>{" "}
                set up for this team
              </FormikFieldGroup.HelpText>

              <FormikFieldGroup.Errors>
                <ErrorMessage render={() => <span>Please enter valid working hours</span>} name="workingHours" />
              </FormikFieldGroup.Errors>
            </FormikFieldGroup.Container>

            {props.currentUser?.organization.isSlackInstalled && (
              <Field
                name="slackChannels"
                render={(fieldProps: FieldProps) => (
                  <FormikMultiSelect
                    {...fieldProps}
                    label="Associated slack channels"
                    helperText="Requests from these channels will be automatically assigned to this team"
                    options={(props.slackChannels || []).map(channel => ({
                      disabled: !!channel.isAssigned,
                      label: channel.slackName,
                      value: channel.slackId
                    }))}
                    defaultValue={form.values?.slackChannels || []}
                    isClearable={false}
                  />
                )}
              />
            )}

            <Col>
              <div>
                <Field
                  name="categories"
                  render={(fieldProps: FieldProps) => (
                    <FormikMultiSelect
                      {...fieldProps}
                      label="Team categories"
                      options={categoryList.map(category => {
                        // categoryList returns bogus ids alongside category names
                        // this can cause react-select to show the same category option as we use the id as
                        // the option value (which react-select uses to de-duplicate options).
                        // For e.g., team.categories["Visa"].id !== categoryList["Visa"].id
                        // This causes react-select to show two Visa options as it de-duplicates based on
                        // option.value. This leads to bad UX.
                        // To solve this in a backwards compatible way, we treat team.categories as
                        // source of truth and if we find a categoryList.name in team.categories array,
                        // we use the category from team.categories to generate the option for react-select.
                        const teamCategory = (props.team.categories ?? []).find(tc => tc.name === category.name);

                        if (teamCategory) {
                          return {
                            value: teamCategory.id,
                            label: teamCategory.name
                          };
                        }
                        return {
                          value: category.id,
                          label: category.name
                        };
                      })}
                      defaultValue={form.values?.categories || []}
                      isCreatable={true}
                      isClearable={false}
                      noOptionsText={"Category already exists"}
                    />
                  )}
                />
              </div>
            </Col>

            <Row
              css={[
                justify.end,
                css`
                  margin: var(--space-2-rem) 0 0 0;
                `
              ]}
            >
              {!form.isSubmitting && (
                <>
                  <CancelButton onClick={() => navigate(props.redirect)} />
                  <SubmitButton disabled={form.dirty && !form.isValid}>Save changes</SubmitButton>
                </>
              )}
              {form.isSubmitting && <LoadingBar />}
            </Row>
          </Col>
        </Form>
      )}
    />
  );
};
