import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { mdiClose } from "@mdi/js";
import { isString } from "lodash";
import * as React from "react";
import { useContext } from "react";
import { Button, ButtonVariant, CancelButton, Col, LoadingBar, MaterialIcon, Row, SquareButton } from "src/components";
import { DelayNavContext } from "src/hooks/useWindowEvents";
import { Modal } from "src/portals/Modal";
import { margins } from "src/styling/layout";
import { Typo } from "src/styling/primitives/typography";
import { csx } from "src/util/csx";

export const dialogWidths = {
  small: 400,
  medium: 520,
  large: 720
};

const DialogWrapper = csx(
  [
    css`
      position: relative;
      display: flex;
      flex-direction: column;
      background-color: var(--white);
      border-radius: var(--border-radius-xxl);
      box-shadow: var(--box-shadow-card);
      box-sizing: border-box;
      max-width: 100%;
      padding: var(--space-7-px);

      @media (max-width: ${dialogWidths.large}px) {
        padding: var(--space-4-px);
        border-radius: var(--border-radius-m);
      }

      & .body {
        & p {
          margin: var(--space-4-px) 0;
        }

        & p:first-child {
          margin-top: 0;
        }
      }

      & a {
        font-weight: var(--font-weight-body-semiBold);
        cursor: pointer;
      }
    `
  ],
  {
    small: css`
      width: ${dialogWidths.small}px;
    `,
    medium: css`
      width: ${dialogWidths.medium}px;
    `,
    large: css`
      width: ${dialogWidths.large}px;
    `,
    hasSubtitle: css`
      & .heading {
        margin: 0;
      }
    `
  }
);

const CloseWrapper = styled.div`
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
`;

export const Dialog: React.FC<{
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  noClose?: boolean;
  loading?: boolean;
  onClose?(): void;
  // check unload context before closing
  shouldWarnBeforeClose?: boolean;
  className?: string;
  "data-testid"?: string;
}> = props => {
  const { shouldDelayNav } = useContext(DelayNavContext);
  const onClose = () => {
    if (!props.onClose) return;
    if (!shouldDelayNav) {
      props.onClose();
    } else if (window.confirm("Changes you made may not be saved. Are you sure you want to close?")) {
      props.onClose();
    }
  };
  return (
    <DialogWrapper
      className={props.className}
      small={props.small}
      medium={props.medium}
      large={props.large}
      hasSubtitle={!!props.subtitle}
      onClick={e => e.stopPropagation()}
      data-testid={props["data-testid"]}
    >
      {props.loading && <LoadingBar />}
      {(props.title || props.subtitle) && (
        <div
          css={css`
            margin-bottom: var(--space-5-px);

            & > * + * {
              margin-top: var(--space-4-px);
            }
          `}
        >
          {props.title && (
            <Typo.Body sizeXXL data-testid="dialog-title">
              {props.title}
            </Typo.Body>
          )}
          {props.subtitle &&
            (isString(props.subtitle) ? (
              <Typo.Body data-testid="dialog-subtitle">{props.subtitle}</Typo.Body>
            ) : (
              props.subtitle
            ))}
        </div>
      )}
      {props.children}
      {!props.noClose && (
        <CloseWrapper>
          <SquareButton onClick={onClose} data-testid="close-modal">
            <MaterialIcon path={mdiClose} size={1.1} />
          </SquareButton>
        </CloseWrapper>
      )}
    </DialogWrapper>
  );
};

interface IConfirmDialog {
  text: {
    heading: string;
    confirm?: string;
    cancel?: string;
  };
  handleConfirm(): void;
  handleCancel(): void;
  isOpen: boolean;
  alignTop?: boolean;
  loading?: boolean;
  buttonVariant?: ButtonVariant;
  submittingConfirm?: boolean;
  children?: React.ReactNode;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  // check unload context before dismissing
  shouldWarnBeforeClose?: boolean;
}

/**
 * Confirm / cancel dialog modal
 */
export function ConfirmDialogModal(props: IConfirmDialog) {
  const { small, medium, large, isOpen, alignTop, ...rest } = props;

  return (
    <Modal
      onDismiss={() => props.handleCancel()}
      data-testid="confirm-dialog-modal"
      isOpen={isOpen}
      alignTop={alignTop}
    >
      {props.loading && <LoadingBar />}
      <Dialog
        small={small}
        medium={medium}
        large={large}
        title={props.text.heading}
        onClose={() => props.handleCancel()}
        shouldWarnBeforeClose={props.shouldWarnBeforeClose}
      >
        <Col>
          <div className="body">{props.children}</div>
          <Row justify="flex-end" margin={margins.dialogButtons.combined}>
            {props.text.cancel && (
              <CancelButton
                onClick={() => props.handleCancel()}
                data-testid="confirm-dialog-modal-cancel"
                margin="0 0.75rem 0 0"
              >
                {props.text.cancel}
              </CancelButton>
            )}
            {props.text.confirm && (
              <Button
                {...rest}
                type="submit"
                disabled={props.submittingConfirm}
                variant={props.buttonVariant || "red"}
                size="large"
                onClick={() => props.handleConfirm()}
                data-testid="confirm-dialog-modal-confirm"
              >
                {props.text.confirm}
              </Button>
            )}
          </Row>
        </Col>
      </Dialog>
    </Modal>
  );
}
