import { css } from "@emotion/core";
import { mdiAccountMultiple, mdiChevronLeft } from "@mdi/js";
import { captureException } from "@sentry/browser";
import { AnimatePresence, motion } from "framer-motion";
import gql from "graphql-tag";
import React, { PropsWithChildren, ReactNode, useRef, useState } from "react";
import { useMutation } from "react-apollo";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { FeatureFlags, useFeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import { UserAvatar } from "src/App/User";
import { ReactComponent as DropdownIndicator } from "src/assets/DropdownIndicator.svg";
import { ReactComponent as SantaHat } from "src/assets/SantaHat.svg";
import { Avatar, ClickBlock, LoadingBar, MaterialIcon, Menu } from "src/components";
import { Typo } from "src/styling/primitives/typography";
import { apolloClient } from "src/util/apollo/client";
import { csx } from "src/util/csx";
import { initialsOf } from "src/util/formatters";
import { Link, Location, navigate, useMatch } from "src/util/router";
import { useHotKeys } from "src/util/services/hotkeys";
import { browserPushSettings } from "src/util/services/pushNotifications";
import { RequestListCounts } from "./RequestListCounts";
import { UserSignOut } from "./typings/UserSignOut";

/* Special absolutely-positioned menu designed for the user menu */
function AbsoluteMenu(props: PropsWithChildren<{ button: ReactNode; className?: string }>) {
  const [open, setOpen] = useState(false);
  return (
    <div
      css={css`
        position: relative;
        z-index: var(--z-medium);
      `}
      className={props.className}
      onClick={() => setOpen(!open)}
    >
      {props.button}
      {open && <ClickBlock />}
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          z-index: var(--z-medium);
        `}
      >
        {open && props.children}
      </div>
    </div>
  );
}

const UserMenuItem = csx([
  css`
    display: flex;
    padding: 0.375rem var(--space-5-rem);
    user-select: none;
    cursor: pointer;

    &:hover {
      background: rgba(var(--yellow-2-raw), 0.6);

      & * {
        background: rgba(var(--yellow-2-raw), 0.6);
      }
    }

    &:focus {
      outline: none;
      background-color: var(--sand-1);
    }

    & > * + * {
      margin-left: var(--space-3-rem);
    }
  `
]);

const UserMenuItemHeader = csx([
  css`
    display: flex;
    padding: 0.375rem var(--space-5-rem);

    & > * + * {
      margin-left: var(--space-3-rem);
    }
  `
]);

const UserMenuItemSeparator = csx([
  css`
    height: 1px;
    margin: var(--space-3-rem) var(--space-5-rem);
    background: var(--border);
  `
]);

/* Some specific mumbo-jumbo here for the user menu icon */
const OrgAvatar = csx(
  [
    css`
      display: inline-block;
      flex: 0 0 auto;
      width: 2.375rem;
      height: 2.375rem;
      line-height: 2.375rem;
      text-align: center;
      font-size: var(--font-size-helper);
      font-weight: var(--font-weight-body-bold);
      color: var(--orange-1);
      background: var(--blue-1);
      border-radius: var(--border-radius-round);
      transition: width 1s ease-out, height 1s ease-out;
      box-shadow: 0 var(--space-4-rem) var(--space-4-rem) rgba(var(--text-4-raw), 0.04),
        0 0.125rem 0.125rem rgba(var(--text-4-raw), 0.04),
        0 var(--space-2-rem) var(--space-2-rem) rgba(var(--text-4-raw), 0.04),
        0 0.25rem 0.25rem rgba(var(--text-4-raw), 0.04);
    `
  ],
  {
    small: css`
      width: var(--space-5-rem);
      height: var(--space-5-rem);
      line-height: var(--space-5-rem);
      font-size: 0.625rem;
      box-shadow: none;
    `
  }
);

const UserMenu: React.FC<{
  items: React.ReactNode;
}> = props => {
  const { currentUser } = useCurrentUser();
  const hasTeams = !!currentUser?.teams.length;
  const menuItemsRef = useRef<HTMLDivElement>(null);
  const [isItChristmas] = useState<boolean>(
    (function () {
      const start = new Date(2020, 11, 1).getTime();
      const end = new Date(2020, 11, 31, 23, 59, 59).getTime();
      const now = new Date().getTime();

      return start <= now && now <= end;
    })()
  );

  if (!currentUser) return null;
  return (
    <AbsoluteMenu
      button={
        <Typo.Body
          bold
          ellipsis
          data-testid="org-heading"
          css={css`
            display: flex;
            align-items: center;
            padding: var(--space-4-rem) var(--space-5-rem) var(--space-4-rem) var(--space-4-rem);
            cursor: pointer;
            border-radius: var(--space-3-rem);

            &:hover {
              background: rgba(var(--text-6-raw), 0.075);
            }

            &:active {
              background: rgba(var(--text-6-raw), 0.2);
            }

            & > * + * {
              margin-left: var(--space-4-rem);
            }
          `}
        >
          <OrgAvatar>
            {initialsOf(currentUser.organization.name)}
            {isItChristmas && (
              <SantaHat
                css={css`
                  position: absolute;
                  width: 3rem;
                  left: 0;
                  top: -0.2rem;
                `}
              />
            )}
          </OrgAvatar>
          <span
            css={css`
              flex-grow: 1;
            `}
          >
            {currentUser.organization.name}
          </span>
          <DropdownIndicator />
        </Typo.Body>
      }
    >
      <Menu
        data-testid="sidebar-dropdown"
        onClick={e => {
          // keep menu open unless selecting menu item
          if (e.target !== menuItemsRef.current) e.stopPropagation();
        }}
        css={css`
          margin: 0;
          padding: var(--space-4-rem) 0;
          border-radius: var(--border-radius-m);
        `}
      >
        <UserMenuItemHeader>
          <OrgAvatar small>{initialsOf(currentUser.organization.name)}</OrgAvatar>
          <Typo.Body
            bold
            ellipsis
            css={css`
              flex-grow: 1;
            `}
          >
            {currentUser.organization.name}
          </Typo.Body>
          <DropdownIndicator />
        </UserMenuItemHeader>
        <div
          ref={menuItemsRef}
          css={css`
            padding-top: var(--space-1-rem);
          `}
        >
          {props.items}
        </div>
        <UserMenuItemSeparator />
        <UserMenuItemHeader>
          <Avatar>
            <MaterialIcon path={mdiAccountMultiple} size={0.875} />
          </Avatar>
          <Typo.Body bold>Your teams</Typo.Body>
        </UserMenuItemHeader>
        <div
          css={css`
            padding: var(--space-4-rem) var(--space-5-rem);

            & > * + * {
              padding-top: var(--space-4-rem);
            }
          `}
          data-testid="team-list"
        >
          {hasTeams &&
            currentUser?.teams.map((team, i) => (
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                `}
                key={team.slug}
              >
                <Typo.Body light title={team.name}>
                  {team.name}
                </Typo.Body>
                <Typo.ExternalLink
                  href={`mailto:${team.emailAddresses.to}`}
                  title={`${team.emailAddresses.to}`}
                  css={css`
                    color: var(--text-3);

                    &:hover {
                      color: var(--text-6);
                      background-color: unset;
                    }
                  `}
                >
                  {team.emailAddresses.to}
                </Typo.ExternalLink>
              </div>
            ))}
          {!hasTeams && <UserMenuItemHeader>You have not joined any teams yet.</UserMenuItemHeader>}
        </div>
        <UserMenuItemSeparator />
        <UserMenuItemHeader>
          <UserAvatar user={currentUser} />
          <Typo.Body bold>{currentUser.name}</Typo.Body>
        </UserMenuItemHeader>
        <SignOutItem />
      </Menu>
    </AbsoluteMenu>
  );
};

const SignOutItem: React.FC = () => {
  const { currentUser, refetchCurrentUser } = useCurrentUser();
  const [userLogout, userLogoutResponse] = useMutation<UserSignOut>(
    gql`
      mutation UserSignOut {
        userLogout {
          code
          message
          success
        }
      }
    `,
    {
      onCompleted: async () => {
        await browserPushSettings(currentUser?.id)?.onLogout().catch(captureException);
        navigate("/login");
        // clear user-specific data from memory
        await apolloClient.clearStore();
        await refetchCurrentUser();
      }
    }
  );
  return (
    <UserMenuItem
      onClick={e => {
        e.stopPropagation();
        userLogout();
      }}
      data-testid="sign-out-link"
    >
      Sign out
      {userLogoutResponse.loading && <LoadingBar />}
    </UserMenuItem>
  );
};

const sidebarSubheading = css`
  padding: var(--space-1-rem) var(--space-5-rem);
`;

export const sidebarMenuItem = css`
  display: flex;
  align-items: center;
  height: var(--space-6-rem);
  padding: 0 var(--space-5-rem);
  color: var(--text-4);
  text-decoration: none;
  user-select: none;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    background-color: var(--sand-1);
  }

  &:focus {
    outline: none;
    background-color: var(--sand-1);
  }
`;

export const sidebarMenuItemSelected = css`
  color: var(--text-6);
  background-color: var(--sand-2);

  &:hover {
    color: var(--text-6);
    background-color: var(--sand-2);
  }
`;

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? "100%" : "-100%",
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? "100%" : "-100%",
      opacity: 0
    };
  }
};

/** Scrollable, animated sidebar container */
const AnimatedSidebarContainer: React.FC<{
  direction: 1 | -1;
}> = props => {
  return (
    <div
      data-testid="sidebar"
      css={css`
        position: relative;
        flex-grow: 1;
        overflow-y: auto;
        overflow-x: hidden;
      `}
    >
      <AnimatePresence initial={false}>
        <motion.div
          key={props.direction}
          custom={props.direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            ease: "easeInOut"
          }}
          css={css`
            position: absolute;
            top: 0;
            width: 100%;
            box-sizing: border-box;
            overflow-y: auto;
            overflow-x: hidden;

            & > * + * {
              margin-top: var(--space-5-rem);
            }
          `}
        >
          {props.children}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export const Sidebar: React.FC<{}> = props => {
  const { hasFeatureFlags } = useFeatureFlags();
  const { currentUser } = useCurrentUser();

  const isSettingsPage = !!useMatch("/settings/*");
  const initialSidebarView = isSettingsPage ? "settings" : "overview";
  const [sidebarView, setSideBarView] = useState<"overview" | "settings">(initialSidebarView);
  const navigateToOverviewSection = (path: string) => {
    setSideBarView("overview");
    navigate(path);
  };

  useHotKeys({
    name: "Goto Unassigned",
    keys: "g u",
    handler: () => navigateToOverviewSection("/view/unassigned"),
    group: "navigation"
  });

  useHotKeys({
    name: "Goto Assigned to me",
    keys: "g m",
    handler: () => navigateToOverviewSection("/view/me"),
    group: "navigation"
  });

  useHotKeys({
    name: "Goto All requests",
    keys: "g a",
    handler: () => navigateToOverviewSection("/view/all"),
    group: "navigation"
  });

  useHotKeys({
    name: "Goto Archive",
    keys: "g x",
    handler: () => navigateToOverviewSection("/view/archive"),
    group: "navigation"
  });

  if (!currentUser) return null;

  return (
    <Location>
      {context => (
        <>
          {sidebarView === "settings" && (
            <div
              css={css`
                padding: var(--space-6-px) 0 var(--space-4-px) 0;
              `}
            >
              <div css={sidebarMenuItem} onClick={() => setSideBarView("overview")}>
                <div
                  css={css`
                    display: flex;
                    align-items: center;

                    & > * + * {
                      margin-left: var(--space-1-rem);
                    }
                  `}
                >
                  <MaterialIcon
                    size={1}
                    path={mdiChevronLeft}
                    css={css`
                      display: inline;
                    `}
                  />
                  <span
                    css={css`
                      line-height: 1;
                    `}
                  >
                    Back
                  </span>
                </div>
              </div>
            </div>
          )}
          {sidebarView === "overview" && (
            <div
              css={css`
                padding: var(--space-4-rem) var(--space-4-rem) 1.75rem var(--space-4-rem);
              `}
            >
              <UserMenu items={<UserMenuItem onClick={() => setSideBarView("settings")}>Settings</UserMenuItem>} />
            </div>
          )}
          <AnimatedSidebarContainer direction={sidebarView === "overview" ? -1 : 1}>
            {sidebarView === "overview" && (
              <>
                <div>
                  <Typo.Subheading css={sidebarSubheading}>Work</Typo.Subheading>
                  {!hasFeatureFlags(FeatureFlags.HIDEEXPERTANALYTICS) && (
                    <Link
                      to="/team-insights"
                      css={[
                        sidebarMenuItem,
                        context.location.pathname.startsWith("/team-insights") && sidebarMenuItemSelected
                      ]}
                    >
                      Team insights
                    </Link>
                  )}
                  <RequestListCounts location={context.location} currentUserId={currentUser.id} />
                  <Link
                    data-intercom-target="Projects"
                    to="/projects"
                    css={[
                      sidebarMenuItem,
                      context.location.pathname.startsWith("/projects") && sidebarMenuItemSelected
                    ]}
                  >
                    Projects
                  </Link>
                  <Link
                    to="/outreach"
                    css={[
                      sidebarMenuItem,
                      context.location.pathname.startsWith("/outreach") && sidebarMenuItemSelected
                    ]}
                  >
                    Outreach
                  </Link>
                </div>
                <div>
                  <Typo.Subheading css={sidebarSubheading}>Manage</Typo.Subheading>
                  <Link
                    to="/best-practices"
                    css={[
                      sidebarMenuItem,
                      context.location.pathname.startsWith("/best-practices") && sidebarMenuItemSelected
                    ]}
                  >
                    Best practices
                  </Link>
                  {hasFeatureFlags(FeatureFlags.WORKFLOWS) && (
                    <Link
                      to="/workflows"
                      css={[
                        sidebarMenuItem,
                        css`
                          & > * + * {
                            margin-left: var(--space-1-px);
                          }
                        `,
                        (context.location.pathname.startsWith("/workflows") ||
                          context.location.pathname.startsWith("/workflow")) &&
                          sidebarMenuItemSelected
                      ]}
                    >
                      <span>Workflows</span>
                    </Link>
                  )}
                  <Link
                    to="/analytics"
                    css={[
                      sidebarMenuItem,
                      context.location.pathname.startsWith("/analytics") && sidebarMenuItemSelected
                    ]}
                  >
                    Analytics
                  </Link>
                  <Link
                    to="/forms"
                    css={[sidebarMenuItem, context.location.pathname.startsWith("/forms") && sidebarMenuItemSelected]}
                  >
                    Forms
                  </Link>
                  <Link
                    to="/knowledge"
                    data-intercom-target="knowledge-sidebar"
                    css={[
                      sidebarMenuItem,
                      context.location.pathname.startsWith("/knowledge") && sidebarMenuItemSelected
                    ]}
                  >
                    Knowledge
                  </Link>
                </div>
              </>
            )}
            {sidebarView === "settings" && (
              <>
                <div>
                  <Typo.Subheading css={sidebarSubheading}>Organization</Typo.Subheading>
                  <Link
                    to="/settings/teams"
                    css={[
                      sidebarMenuItem,
                      context.location.pathname.startsWith("/settings/teams") && sidebarMenuItemSelected
                    ]}
                  >
                    Teams
                  </Link>
                  <Link
                    to="/settings/users"
                    css={[
                      sidebarMenuItem,
                      context.location.pathname.startsWith("/settings/users") && sidebarMenuItemSelected
                    ]}
                  >
                    Users
                  </Link>
                  <Link
                    to="/settings/integrations"
                    css={[
                      sidebarMenuItem,
                      context.location.pathname.startsWith("/settings/integrations") && sidebarMenuItemSelected
                    ]}
                  >
                    Integrations
                  </Link>
                  <Link
                    to="/settings/subscription"
                    css={[
                      sidebarMenuItem,
                      context.location.pathname.startsWith("/settings/subscription") && sidebarMenuItemSelected
                    ]}
                  >
                    Subscription
                  </Link>
                </div>
                <div>
                  <Typo.Subheading css={sidebarSubheading}>Team settings</Typo.Subheading>
                  {hasFeatureFlags(FeatureFlags.CUSTOMREQUESTSTATUS) && (
                    <Link
                      to="/settings/statuses"
                      css={[
                        sidebarMenuItem,
                        context.location.pathname.startsWith("/settings/statuses") && sidebarMenuItemSelected
                      ]}
                    >
                      Request status
                    </Link>
                  )}
                  {hasFeatureFlags(FeatureFlags.SLA) && (
                    <Link
                      to="/settings/slas"
                      css={[
                        sidebarMenuItem,
                        context.location.pathname.startsWith("/settings/slas") && sidebarMenuItemSelected
                      ]}
                    >
                      SLAs
                    </Link>
                  )}
                  <Link
                    to="/settings/saved-replies"
                    css={[
                      sidebarMenuItem,
                      context.location.pathname.startsWith("/settings/saved-replies") && sidebarMenuItemSelected
                    ]}
                  >
                    Saved replies
                  </Link>
                </div>
                <div>
                  <Typo.Subheading css={sidebarSubheading}>Account</Typo.Subheading>
                  <Link
                    to="/settings/notifications"
                    css={[
                      sidebarMenuItem,
                      context.location.pathname.startsWith("/settings/notifications") && sidebarMenuItemSelected
                    ]}
                  >
                    Notifications
                  </Link>
                  <Link
                    to="/settings/keyboard-shortcuts"
                    css={[
                      sidebarMenuItem,
                      context.location.pathname.startsWith("/settings/keyboard-shortcuts") && sidebarMenuItemSelected
                    ]}
                  >
                    Keyboard shortcuts
                  </Link>
                </div>
              </>
            )}
          </AnimatedSidebarContainer>
        </>
      )}
    </Location>
  );
};
