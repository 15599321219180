import { css } from "@emotion/core";
import { csvFormat } from "d3-dsv";
import { format } from "date-fns";
import * as React from "react";
import { attachmentUrl } from "src/App/Attachments/Item";
import { GetFormSubmissions_formSubmissionList_submissions } from "src/App/Forms/Manager/typings/GetFormSubmissions";
import {
  FormSubmittedEventDetails_submittedForm,
  FormSubmittedEventDetails_submittedForm_schema_fieldsList
} from "src/App/Forms/typings/FormSubmittedEventDetails";
import { Button, ButtonProps, Icon } from "src/components";
import { TextFieldType } from "src/globalTypes";
import { Download } from "src/svg/icons/Download";
import { generateDownload } from "src/util";
import { trackFormAnswersDownload } from "src/util/analytics";

const extractValueFromField = (
  field: FormSubmittedEventDetails_submittedForm_schema_fieldsList,
  questionNr: number
): { question: string; value: string } => {
  let value = "";
  switch (field.__typename) {
    case "DropdownField":
      value = field.selected !== null ? field.optionsList[field.selected] : "";
      break;
    case "AttachmentsField":
      value = field.attachment ? attachmentUrl(field.attachment, null) : "";
      break;
    case "TextField":
      value = field.value
        ? field.textFieldType === TextFieldType.DATE
          ? format(new Date(field.value), "d MMM yyyy")
          : field.value
        : "";
      break;
  }

  return {
    question: `${questionNr + 1}. ${field.displayName}${field.required ? "*" : ""}`,
    value: value
  };
};

const generateFileName = (submissions: Submission[]) => {
  if (submissions.length > 1) {
    return submissions[0].schema.displayName + " - Version " + submissions[0].schema.revision;
  } else {
    return (
      submissions[0].schema.displayName +
      (submissions[0].submitter && ` - ${submissions[0].submitter?.email}`) +
      (submissions[0].submitTime && ` - ${format(new Date(submissions[0].submitTime), "yyyy-MM-dd")}`)
    );
  }
};

const formatAndDownloadSubmissions = (submissions: Submission[], suffix?: string) => {
  const mappedSubmissions = submissions.map(s => {
    const row: { [key: string]: string } = {};
    for (const [i, f] of s.schema.fieldsList.entries()) {
      const o = extractValueFromField(f, i);
      row[o.question] = o.value;
    }

    row["Answered by"] = `${s.submitter?.name} <${s.submitter?.email}>`;
    row["Answered on"] = submissions[0].submitTime ? format(new Date(submissions[0].submitTime), "yyyy-MM-dd") : "";

    return row;
  });

  const fileName = generateFileName(submissions) + `${!!suffix ? " - " + suffix : ""}.csv`;
  const csvData = csvFormat(mappedSubmissions);

  generateDownload(fileName, csvData);
  trackFormAnswersDownload();
};

export type Submission = FormSubmittedEventDetails_submittedForm | GetFormSubmissions_formSubmissionList_submissions;

export const ExportAnswersButton: React.FC<{
  submissions: Submission[];
  buttonProps?: ButtonProps;
  label?: string;
  fileSuffix?: string;
}> = props => {
  const getData = () => {
    formatAndDownloadSubmissions(props.submissions, props.fileSuffix);
  };

  return (
    <>
      <Button variant="ghost" onClick={() => getData()} {...props.buttonProps}>
        <Icon
          css={css`
            margin-right: ${props.label ? `var(--space-1-rem)` : ``};
          `}
        >
          <Download />
        </Icon>
        {props.label}
      </Button>
    </>
  );
};
