import { css } from "@emotion/core";
import React from "react";
import { FormSubmittedMessage, TimelineFormMessage as TimelineMessageFormSent } from "src/App/Forms/Message";
import { EventHighlight, MessageBox, TimelineEvent } from "src/App/Requests/components";
import { CommentComponent, RequestCreatedMessage } from "src/App/Requests/DetailView/Timeline/Comment";
import { UpdateComponent } from "src/App/Requests/DetailView/Timeline/Update";
import {
  RequestGet_initialRequestRevision,
  RequestGet_request,
  RequestGet_request_events
} from "src/App/Requests/DetailView/typings/RequestGet";
import { UserAvatar, UserName } from "src/App/User";
import { DateTime } from "src/components";
import { Expandable } from "src/components/Expandable";
import { Icon, IconFromString } from "src/components/Icon";
import { Typo } from "src/styling/primitives/typography";
import { Flash } from "src/svg/icons/Flash";
import { Lock } from "src/svg/icons/Lock";

export type EventProps = {
  event: RequestGet_request_events;
  request: RequestGet_request;
  initialRequest: RequestGet_initialRequestRevision;
  onCommentAttachmentDelete?: (commentId: string, attachmentId: string) => void;
};

const updateEventTypenames = ["RequesterChangedEvent"] as const;
const updateEvent = (event: RequestGet_request_events) => {
  for (const key of updateEventTypenames) {
    if (event.data.__typename === key) {
      return event.data;
    }
  }
  return null;
};

export const TimelineUpdate: React.FC<EventProps> = props => {
  const data = updateEvent(props.event);
  if (data == null) return null;
  return (
    <UpdateComponent
      authorId={data.author.id}
      requesterId={props.request.requester.id}
      timestamp={props.event.timestamp}
    >
      {data.__typename === "RequesterChangedEvent" && (
        <div>
          <UserName user={data.author} />
          &nbsp;changed the requester to&nbsp;
          <EventHighlight>
            <UserName user={data.requester} />
          </EventHighlight>
        </div>
      )}
    </UpdateComponent>
  );
};

const messageEventTypenames = [
  "RequestCreatedEvent",
  "CommentAddedEvent",
  "FormSubmittedEvent",
  "TaskCompletedEvent"
] as const;
const messageEvent = (event: RequestGet_request_events) => {
  for (const key of messageEventTypenames) {
    if (event.data.__typename === key) {
      return event.data;
    }
  }
  return null;
};

export const TimelineMessage: React.FC<EventProps & { backUserId: string }> = props => {
  const data = messageEvent(props.event);
  if (data === null) return null;
  switch (data.__typename) {
    case "RequestCreatedEvent":
      return (
        <RequestCreatedMessage
          data={data}
          event={props.event}
          request={props.request}
          initialRequest={props.initialRequest}
          onAttachmentDelete={props.onCommentAttachmentDelete}
        />
      );
    case "CommentAddedEvent":
      if (data.form) {
        return (
          <TimelineMessageFormSent
            data={data}
            event={props.event}
            request={props.request}
            initialRequest={props.initialRequest}
            fromBot={props.backUserId === data.author.id}
          />
        );
      }
      return (
        <CommentComponent
          data={data}
          event={props.event}
          request={props.request}
          initialRequest={props.initialRequest}
          onAttachmentDelete={props.onCommentAttachmentDelete}
        />
      );
    case "FormSubmittedEvent":
      return (
        <FormSubmittedMessage
          data={data}
          event={props.event}
          request={props.request}
          initialRequest={props.initialRequest}
        />
      );
    case "TaskCompletedEvent":
      return (
        <TimelineEvent
          data-testid="task-completed-event"
          avatar={<UserAvatar sizeL user={data.task.completionAuthor ?? undefined} />}
          footer={
            <div
              css={css`
                align-items: center;
              `}
            >
              <UserName user={data.task.completionAuthor ?? undefined} bold />
              {","}&nbsp;
              <DateTime timestamp={props.event.timestamp} />{" "}
              <Icon inline sizeS>
                <Lock />
              </Icon>
            </div>
          }
        >
          <MessageBox blue>
            <Typo.Body medium>
              {data.task.icon ? (
                <IconFromString
                  inline
                  sizeS
                  css={css`
                    width: 0.75rem;
                    height: 1.125rem;
                    vertical-align: middle;
                  `}
                  svg={data.task.icon}
                />
              ) : (
                <Icon inline sizeS>
                  <Flash />
                </Icon>
              )}{" "}
              <UserName user={data.task.completionAuthor ?? undefined} /> completed a manual task{" "}
              {data.task.workflow && (
                <>
                  to resume the worfklow
                  <em>{data.task.workflow.name}</em>
                </>
              )}
            </Typo.Body>
            <MessageBox darkBlue>
              <Expandable lines={1} text={data.task.instruction} />
            </MessageBox>
          </MessageBox>
        </TimelineEvent>
      );
  }
};
