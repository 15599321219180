import * as React from "react";

import {
  ActionCard,
  CollapsableWorkflowSection,
  CollapsableWorkflowSectionHeading,
  SectionIcon,
  TriggerCard,
  WorkflowPayload,
  WorkflowProvider,
  omitTypenames,
  useWorkflow
} from "./components";
import {
  BackLink,
  Badge,
  BaseLayout,
  Button,
  LoadingBar,
  MaterialIcon,
  SquareButton,
  Tooltip,
  TypeformModal
} from "src/components";
import {
  EditWorkflowDescriptionModal,
  EditWorkflowNameModal,
  EnableDisableWorkflowModal
} from "src/App/Settings/Workflows/DetailView/components/WorkflowDetailModals";
import { FC, useState } from "react";
import { FeatureFlags, useFeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import { Form, Formik } from "formik";
import { TeamWorkflowStatus, WorkflowSaveParams } from "src/globalTypes";
import { Typo, subHeadingStyles } from "src/styling/primitives/typography";
import { WorkflowChangeStatus, WorkflowChangeStatusVariables } from "./typings/WorkflowChangeStatus";
import { WorkflowSave, WorkflowSaveVariables } from "./typings/WorkflowSave";
import { trackStep, trackTrigger } from "src/util/analytics";

import { HelmetComponent } from "src/App/Root/HelmetComponent";
import { PageNotFound } from "src/components/PageNotFound";
import { PopOver } from "src/components/PopOver";
import { TEAM_WORKFLOW_GET } from "src/App/Settings/Workflows";
import { backMarkdownToHtml } from "src/util/formatters";
import { css } from "@emotion/core";
import { getStatusText } from "src/App/Settings/Workflows/Overview";
import gql from "graphql-tag";
import { keys } from "src/util";
import { mdiDotsHorizontal } from "@mdi/js";
import { pick } from "lodash";
import { routeData } from "src/App/Root/RouteData";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { useMatch } from "src/util/router";
import { useMutation } from "react-apollo";
import { useSnack } from "src/App/Root/providers/SnackProvider";

const mapWorkflowSave = (payload: WorkflowPayload): WorkflowSaveParams => {
  // Record ensures typechecker warns on any change in params
  const workflowSaveParamsKeys: Record<keyof WorkflowSaveParams, boolean> = {
    teamId: true,
    workflowId: true,
    name: true,
    description: true,
    icon: true,
    triggers: true,
    steps: true
  };
  const saveParamsOnly = pick({ ...payload }, keys(workflowSaveParamsKeys));
  return {
    ...saveParamsOnly,
    triggers: saveParamsOnly.triggers.map(({ id, publishedVariables, ...trigger }) => ({
      ...trigger,
      publishedVariables: publishedVariables.map(omitTypenames)
    })),
    steps: saveParamsOnly.steps.map(step => ({
      ...step,
      actions: step.actions.map(({ id, index, publishedVariables, ...action }) => action)
    }))
  };
};

export const WorkflowDetailPage: FC = () => {
  const { currentUser } = useCurrentUser();
  const urlParams = useMatch<{ team_slug: string; workflow_id: string }>(
    `/${routeData.workflows.pathname}/:team_slug/:workflow_id`
  );
  if (!currentUser) return <LoadingBar />;
  const team = currentUser.teams.find(t => t.slug === urlParams?.team_slug);
  const workflowId = urlParams?.workflow_id;
  if (!team || !workflowId) return <PageNotFound />;
  return (
    <WorkflowProvider team={team} workflowId={workflowId}>
      <WorkflowDetail team={team} workflowId={workflowId} />
    </WorkflowProvider>
  );
};

export type WorkflowDetailModal = "enableDisable" | "editName" | "editDescription";

const WorkflowDetail: FC<{
  team: {
    id: string;
    name: string;
    slug: string;
  };
  workflowId: string;
}> = props => {
  const { emitSnack } = useSnack();
  const { workflow, isWorkflowEnabled, requiresCustomization, typeformId } = useWorkflow();
  const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
  const [showTypeformModal, setShowTypeformModal] = useState<boolean>(false);
  const [modalState, setModalState] = useState<WorkflowDetailModal | null>(null);
  const [collapsedSubworkflows, setCollapsedSubworkflows] = useState(() => new Set());
  const { hasFeatureFlags } = useFeatureFlags();
  const { currentUser } = useCurrentUser();

  const [changeWorkflowStatus, changeWorkflowStatusRes] = useMutation<
    WorkflowChangeStatus,
    WorkflowChangeStatusVariables
  >(
    gql`
      mutation WorkflowChangeStatus($teamId: ID!, $workflowId: ID!, $enable: Boolean!) {
        workflowChangeStatus(teamId: $teamId, workflowId: $workflowId, enable: $enable) {
          code
          success
          message
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: TEAM_WORKFLOW_GET,
          variables: {
            teamId: props.team.id,
            workflowId: props.workflowId
          }
        }
      ],
      awaitRefetchQueries: true
    }
  );

  const [saveWorkflow] = useMutation<WorkflowSave, WorkflowSaveVariables>(
    gql`
      mutation WorkflowSave($params: WorkflowSaveParams!) {
        workflowSave(params: $params) {
          code
          success
          message
        }
      }
    `,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: TEAM_WORKFLOW_GET,
          variables: {
            teamId: props.team.id,
            workflowId: props.workflowId
          }
        }
      ]
    }
  );

  const toggleWorkflowSection = (workflowSectionId: string) => {
    if (!selectedCardId) {
      // Disable functionality while a card is expanded
      collapsedSubworkflows.has(workflowSectionId)
        ? setCollapsedSubworkflows(prev => {
            const next = new Set(prev);
            next.delete(workflowSectionId);
            return next;
          })
        : setCollapsedSubworkflows(prev => new Set(prev).add(workflowSectionId));
    }
  };

  if (changeWorkflowStatusRes.loading || !workflow) return <LoadingBar />;
  if (workflow && !workflow.steps?.length) return <PageNotFound />;

  return (
    <Formik
      enableReinitialize
      initialValues={workflow}
      onSubmit={async (payload, { setSubmitting }) => {
        const res = await saveWorkflow({
          variables: {
            params: mapWorkflowSave(payload)
          }
        });
        if (res.data?.workflowSave.success) {
          emitSnack({
            type: "info",
            message: res.data.workflowSave.message
          });
        } else {
          emitSnack({
            type: "mutationError",
            message: res.data?.workflowSave.message ?? "Sorry, the workflow could not save"
          });
        }
        setSubmitting(false);
      }}
      render={form => (
        <Form
          css={[
            css`
              width: 100%;
              height: 100%;
            `
          ]}
        >
          {form.isSubmitting && <LoadingBar />}

          <HelmetComponent title={workflow.name} description={`View and edit the workflow ${workflow.name}`} />
          <BaseLayout
            stickyHeader={
              <div
                css={[
                  css`
                    width: 100%;
                  `
                ]}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    margin-bottom: var(--space-2-rem);
                    & > * + * {
                      margin-left: var(--space-2-rem);
                    }
                  `}
                >
                  <BackLink path={`/${routeData.workflows.pathname}/${props.team.slug}`} />
                  <div
                    css={css`
                      flex-grow: 1;
                    `}
                  >
                    <Typo.Body sizeXXL>
                      {!!form.values.name ? form.values.name : "No name"}
                      <Badge
                        success={isWorkflowEnabled}
                        neutral={!isWorkflowEnabled}
                        css={[
                          css`
                            vertical-align: middle;
                            margin-left: var(--space-2-rem);
                          `
                        ]}
                      >
                        {requiresCustomization
                          ? "Template"
                          : getStatusText(isWorkflowEnabled ? TeamWorkflowStatus.ENABLED : TeamWorkflowStatus.DISABLED)}
                      </Badge>
                    </Typo.Body>
                  </div>

                  {hasFeatureFlags(FeatureFlags.WORKFLOWBUILDEREDITSTEPS) && requiresCustomization && (
                    <Button variant="primary" type="button" onClick={() => setShowTypeformModal(true)}>
                      Customize
                    </Button>
                  )}

                  {hasFeatureFlags(FeatureFlags.WORKFLOWBUILDEREDITSTEPS) && !requiresCustomization && (
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={!form.dirty || !form.isValid || form.isSubmitting}
                    >
                      Publish changes
                    </Button>
                  )}
                  {!requiresCustomization && (
                    <PopOver.Menu
                      css={[
                        css`
                          z-index: var(--z-high);
                        `
                      ]}
                      trigger={
                        <SquareButton onClick={e => e.preventDefault()}>
                          <MaterialIcon path={mdiDotsHorizontal} size={1.125} />
                        </SquareButton>
                      }
                      options={
                        hasFeatureFlags(FeatureFlags.WORKFLOWBUILDEREDITSTEPS)
                          ? [
                              {
                                id: "rename",
                                name: "Rename",
                                onClick: () => setModalState("editName")
                              },
                              {
                                id: "changeDescription",
                                name: "Change description",
                                onClick: () => setModalState("editDescription")
                              },
                              {
                                id: "toggle",
                                name: !isWorkflowEnabled ? "Enable" : "Disable",
                                onClick: () => setModalState("enableDisable")
                              }
                            ]
                          : [
                              {
                                id: "toggle",
                                name: !isWorkflowEnabled ? "Enable" : "Disable",
                                onClick: () => setModalState("enableDisable")
                              }
                            ]
                      }
                    />
                  )}
                </div>

                <div
                  css={[
                    css`
                      flex-grow: 1;
                    `
                  ]}
                  dangerouslySetInnerHTML={{
                    __html:
                      // sanitized
                      backMarkdownToHtml(!!form.values.description.length ? form.values.description : "No description")
                  }}
                />
              </div>
            }
            fullHeight
          >
            <div
              css={[
                css`
                  /*  space top to not lay under the fixed header */
                  margin-top: var(--space-6-px);
                  /* do not be under rotating section icons */
                  border-radius: var(--border-radius-l);
                  padding: var(--space-4-px);
                  background-color: var(--lightGrey-1);
                  flex-grow: 1;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  & > * + * {
                    margin-top: var(--space-6-rem);
                  }
                `
              ]}
            >
              <div>
                <Typo.Subheading>when</Typo.Subheading>
                <div
                  css={[
                    css`
                      & > * {
                        margin: var(--space-3-rem) 0 0 var(--space-5-rem);
                      }
                      & > * + * {
                        position: relative;
                        &::before {
                          ${subHeadingStyles}
                          color: var(--text-4);
                          font-weight: var(--font-weight-body-medium);
                          content: "OR";
                          position: absolute;
                          left: var(--minus-5-rem);
                          top: 50%;
                          transform: translateY(-50%);
                        }
                      }
                    `
                  ]}
                >
                  {form.values.triggers.map((trigger, triggerIndex) => (
                    <TriggerCard
                      key={triggerIndex}
                      trigger={trigger}
                      submit={triggerInput => {
                        form.setFieldValue(`triggers.${triggerIndex}`, triggerInput);
                        setSelectedCardId(null);
                        trackTrigger({
                          event: "saved",
                          workflowId: workflow.workflowId,
                          triggerType: trigger.key,
                          triggerIndex: triggerIndex
                        });
                      }}
                      cardState={
                        !selectedCardId
                          ? "collapsed"
                          : !!selectedCardId && trigger.id === selectedCardId
                          ? "expanded"
                          : "disabled"
                      }
                      toggleExpanded={() => {
                        if (hasFeatureFlags(FeatureFlags.WORKFLOWBUILDEREDITSTEPS) && !requiresCustomization) {
                          if (trigger.id === selectedCardId) {
                            setSelectedCardId(null);
                            trackTrigger({
                              event: "collapsed",
                              workflowId: workflow.workflowId,
                              triggerType: trigger.key,
                              triggerIndex: triggerIndex
                            });
                          } else {
                            setSelectedCardId(trigger.id);
                            trackTrigger({
                              event: "expanded",
                              workflowId: workflow.workflowId,
                              triggerType: trigger.key,
                              triggerIndex: triggerIndex
                            });
                          }
                        }
                      }}
                      publishedVariables={trigger.publishedVariables}
                    />
                  ))}
                </div>
              </div>
              {form.values.steps.map(
                (workflowStep, workflowStepIndex) =>
                  workflowStep && (
                    <div id={workflowStep.id}>
                      <CollapsableWorkflowSection
                        key={workflowStep.id}
                        collapsed={collapsedSubworkflows.has(workflowStep.id)}
                      >
                        <Tooltip
                          css={[
                            css`
                              display: block;
                              z-index: var(--z-medium);
                            `
                          ]}
                          target={
                            <CollapsableWorkflowSectionHeading
                              disabled={!!selectedCardId || workflowStep.actions.length === 0}
                              collapsed={collapsedSubworkflows.has(workflowStep.id)}
                              onClick={() => workflowStep.actions.length > 0 && toggleWorkflowSection(workflowStep.id)}
                            >
                              <SectionIcon collapsed={collapsedSubworkflows.has(workflowStep.id)} />

                              <Typo.Subheading>
                                {workflowStep.id === "main" ? "then" : `#${workflowStep.id}`}
                              </Typo.Subheading>
                            </CollapsableWorkflowSectionHeading>
                          }
                        >
                          {workflowStep.actions.length === 0 && <span>This subworkflow has no steps</span>}
                        </Tooltip>
                        {workflowStep.actions.map((action, actionIndex) => {
                          return (
                            <div
                              key={actionIndex}
                              css={css`
                                margin-left: var(--space-2-rem);
                                margin-top: var(--space-2-rem);
                              `}
                            >
                              <ActionCard
                                last={actionIndex >= workflowStep.actions.length - 1}
                                index={action.index}
                                action={action}
                                cardState={
                                  !selectedCardId
                                    ? "collapsed"
                                    : !!selectedCardId && action.id === selectedCardId
                                    ? "expanded"
                                    : "disabled"
                                }
                                toggleExpanded={() => {
                                  if (
                                    hasFeatureFlags(FeatureFlags.WORKFLOWBUILDEREDITSTEPS) &&
                                    !requiresCustomization
                                  ) {
                                    if (action.id === selectedCardId) {
                                      setSelectedCardId(null);
                                      trackStep({
                                        event: "collapsed",
                                        workflowId: workflow.workflowId,
                                        branchName: workflowStep.id,
                                        stepType: action.key,
                                        stepIndex: action.index.actionIndex
                                      });
                                    } else {
                                      setSelectedCardId(action.id);
                                      trackStep({
                                        event: "expanded",
                                        workflowId: workflow.workflowId,
                                        branchName: workflowStep.id,
                                        stepType: action.key,
                                        stepIndex: action.index.actionIndex
                                      });
                                    }
                                  }
                                }}
                                submitParent={actionInput => {
                                  form.setFieldValue(`steps.${workflowStepIndex}.actions.${actionIndex}`, actionInput);
                                  trackStep({
                                    event: "saved",
                                    workflowId: workflow.workflowId,
                                    branchName: workflowStep.id,
                                    stepType: action.key,
                                    stepIndex: action.index.actionIndex
                                  });
                                  setSelectedCardId(null);
                                }}
                                publishedVariables={action.publishedVariables}
                              />
                            </div>
                          );
                        })}
                      </CollapsableWorkflowSection>
                    </div>
                  )
              )}
            </div>
          </BaseLayout>

          {/* ====== MODALS ====== */}
          {/* ====== Enable / disable workflow ====== */}
          <EnableDisableWorkflowModal
            isOpen={modalState === "enableDisable"}
            isWorkflowEnabled={isWorkflowEnabled}
            onClose={() => setModalState(null)}
            onConfirm={async () => {
              setModalState(null);
              const res = await changeWorkflowStatus({
                variables: {
                  enable: !isWorkflowEnabled,
                  teamId: props.team.id,
                  workflowId: workflow.workflowId
                }
              });
              if (res.data?.workflowChangeStatus.success) {
                emitSnack({
                  type: "info",
                  message: !isWorkflowEnabled ? "Workflow enabled" : "Workflow disabled"
                });
              } else {
                emitSnack({
                  type: "mutationError",
                  message: res.data?.workflowChangeStatus.message ?? "Error"
                });
              }
            }}
          />
          {/* ====== Edit workflow name ====== */}
          <EditWorkflowNameModal
            isOpen={modalState === "editName"}
            onClose={() => setModalState(null)}
            initialValue={form.values.name}
            onConfirm={newValue => {
              form.setFieldValue("name", newValue);
              form.setFieldTouched("name");
              setModalState(null);
            }}
          />
          {/* ====== Edit workflow description ====== */}
          <EditWorkflowDescriptionModal
            isOpen={modalState === "editDescription"}
            initialValue={form.values.description}
            onClose={() => setModalState(null)}
            onConfirm={newValue => {
              form.setFieldValue("description", newValue);
              form.setFieldTouched("description");
              setModalState(null);
            }}
          />
          {/* ====== Typeform for template workflows ====== */}
          <TypeformModal
            isShown={showTypeformModal}
            onClose={() => setShowTypeformModal(false)}
            title={`Enable editing for workflow "${workflow?.name}"`}
            formId={typeformId ?? ""}
            hiddenFields={{
              teamid: props.team.id,
              teamslug: props.team.slug,
              orgid: currentUser?.organization.id ?? "",
              orgslug: currentUser?.organization.slug ?? "",
              userid: currentUser?.id ?? "",
              workflowid: workflow.workflowId,
              workflowname: workflow.name
            }}
          />
        </Form>
      )}
    />
  );
};
