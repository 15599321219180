import { css } from "@emotion/core";
import React from "react";
import { KanbanRequestListItem } from "src/App/Requests/ListView/KanbanCard";
import { steps } from "src/App/Settings/RequestStatuses/Overview";
import { LoadingBar } from "src/components";
import { RequestStatus } from "src/globalTypes";
import { Toast } from "src/portals/Toast";
import { Typo } from "src/styling/primitives/typography";
import { keys } from "src/util";
import { csx } from "src/util/csx";
import { useRequestList } from "./Provider";
import { ListComponentProps } from "./REQUEST_LIST_GET";
import { RequestListFragment } from "./typings/RequestListFragment";

const ViewWrapper = csx(
  [
    css`
      overflow-y: auto;
      overflow-x: auto;
      display: flex;
      flex: 1 0 auto;
      height: 100%;
    `
  ],
  {}
);

const KanbanBoardWrapper = csx(
  [
    css`
      flex-grow: 0;
      padding: 0 var(--space-5-px);
      display: grid;
      grid-gap: var(--space-6-px);
      grid-template-columns: auto auto auto;
      align-items: start;
    `
  ],
  {}
);

const KanbanBoardColumn = csx(
  [
    css`
      display: flex;
      flex-direction: column;
      padding-left: var(--space-3-px);
      margin-bottom: var(--space-3-px);
      border-left: solid 2px var(--lightGrey-1);
      width: 22.85vw;
      max-width: 25rem;
      min-width: 20rem;

      & > * + * {
        margin-top: var(--space-3-px);
      }
    `
  ],
  {}
);

const Grey = csx(
  [
    css`
      color: var(--text-3);
    `
  ],
  {},
  "span"
);

enum KanbanSteps {
  NOTSTARTED = "NOTSTARTED",
  INPROGRESS = "INPROGRESS",
  WAITING = "WAITING"
}

export const KanbanBoard: React.ComponentType<ListComponentProps> = props => {
  const [requestList, requestListDispatch] = useRequestList();
  const requests = requestList.response.data?.requestListPage.requests;

  const updateVisiblePills = (requestId: string, pillId: string): void => {
    requestListDispatch({
      type: "TOGGLE_VISIBLE_PILLS",
      requestId,
      pillId
    });
  };

  const requestsByStep = {
    [RequestStatus.NOTSTARTED]: requests?.filter(r => r.customStatus.step === RequestStatus.NOTSTARTED),
    [RequestStatus.INPROGRESS]: requests?.filter(r => r.customStatus.step === RequestStatus.INPROGRESS),
    [RequestStatus.WAITING]: requests?.filter(r => r.customStatus.step === RequestStatus.WAITING)
  };

  return (
    <ViewWrapper
      css={css`
        & > div {
          // margin top is needed to have first element not too be rendered underneath the flter bar
          margin: ${requestList.filterBarHeight}px auto 0 auto;
        }
      `}
    >
      <div>
        {requestList.response.loading && <LoadingBar />}
        {requestList.response.error && <Toast message={requestList.response.error.message} kind="error" />}
        {!requestList.response.loading && (
          <KanbanBoardWrapper>
            {keys(KanbanSteps).map(step => (
              <KanbanBoardColumn key={step}>
                <Typo.Body sizeM>
                  {steps.find(statusStep => statusStep.value === step)?.label}{" "}
                  <Grey>{requestsByStep[step]?.length}</Grey>
                </Typo.Body>
                {requestsByStep[step]?.map((request: RequestListFragment) => (
                  <KanbanRequestListItem
                    key={request.id}
                    request={request}
                    requestUpdates={props.requestUpdateMap.get(request.id)}
                    updateVisiblePills={updateVisiblePills}
                  />
                ))}
              </KanbanBoardColumn>
            ))}
          </KanbanBoardWrapper>
        )}
      </div>
    </ViewWrapper>
  );
};
