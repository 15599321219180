import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { findChildren } from "@tiptap/react";
import { EditorState, KeyBindingUtil } from "draft-js";
import "draft-js-mention-plugin/lib/plugin.css";
import "draft-js/dist/Draft.css";
import "emoji-mart/css/emoji-mart.css";
import { every, includes } from "lodash";
import * as React from "react";
import { Dispatch, SyntheticEvent, useEffect, useRef } from "react";
import { handleFilesEvent } from "src/App/Attachments/handlers";
import { AttachmentsMenu } from "src/App/Attachments/Menu";
import { FileStatus, IFiles, LocalFileInfo, TFileAction } from "src/App/Attachments/reducer";
import { DraftJSComponent } from "src/App/Editor/DraftJSComponent";
import { extractEntities, formatContent } from "src/App/Editor/formatContent";
import { FormAction, FormState } from "src/App/Forms/Manager/reducer";
import { DocumentPicker } from "src/App/KB/Detail/DocumentPicker";
import { DocumentAction, DocumentState } from "src/App/KB/Detail/reducer";
import { SavedReplyAction, SavedReplyState } from "src/App/KB/Detail/savedRepliesReducer";
import { SavedReplyButton, SavedReplyPicker } from "src/App/KB/Detail/SavedReplyPicker";
import { LoomButton } from "src/App/Requests/DetailView/InputBar/LoomButton";
import { RequestGet_request } from "src/App/Requests/DetailView/typings/RequestGet";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { FeatureFlags, useFeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import { SentryErrorBoundary } from "src/App/Root/Sentry";
import { Row } from "src/components";
import { MentionAttrs } from "src/components/RichEditor/AtMention";
import { RichEditor, richEditorInputStyles } from "src/components/RichEditor/Editor";
import { AbsoluteTooltip } from "src/components/Tooltip";
import { input } from "src/styling/effects";
import { dimensions, paddings } from "src/styling/layout";
import { trackAttachmentAdded } from "src/util/analytics";
import { csx } from "src/util/csx";
import { htmlToBackMarkdown } from "src/util/formatters";
import { ApprovalAction, ApprovalState, SendApprovalButton } from "./Approvals";
import { EmojiPicker } from "./Emojis";
import { FileStack } from "./FileStack";
import {
  ApprovalBanner,
  ContextForExternalsToggle,
  DocumentBanner,
  FormBanner,
  IsRequesterBanner
} from "./InputBanner";
import { InputMenu } from "./InputMenu";
import { TMentionData } from "./Mentions";
import { PostCommentButton } from "./PostComment";
import { PostNoteButton } from "./PostNote";
import { IInputState, TInputAction } from "./reducer";

/* Remove after FF RichTextReplies is removed */
const EditorAreaWrapper = styled.div<{ type: IInputState["inputType"]; hasAttachments?: boolean }>`
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: ${paddings.textArea.combined};
  line-height: 1.5;
  width: 100%;
  color: var(--text-6);
  background-color: var(--white);
  ${input.shadow}
  border-radius: var(--border-radius-s);
`;
/* Remove after FF RichTextReplies is removed */

const HiddenTestInput = csx(
  [
    css`
      display: none;
    `
  ],
  {},
  "input"
);

const FooterRow = styled(Row)`
  flex: 100%;
  height: ${dimensions.button.height}rem;
  justify-content: space-between;
  align-items: center;
`;

const ToolbarWrapper = csx([
  css`
    display: flex;
    align-items: center;

    & .icon {
      color: var(--text-6);
    }
  `
]);

const inputTypeIntercomTargets = {
  COMMENTS: "Reply text",
  NOTES: "Internal note text",
  APPROVALS: "Approval text",
  FORMS: "Forms text"
};

const inputTypePlaceholders = (approverName?: string) => ({
  COMMENTS: "Type a reply",
  NOTES: "Add an internal note for your team",
  APPROVALS: `Add a message for ${approverName ? approverName.split(" ")[0].split("@")[0] : "the approver"}`,
  FORMS: "Add a message for the employee"
});

/**
 * Rich text input area for requests
 */
export function EditorArea(props: {
  request: RequestGet_request;
  inputState: IInputState;
  inputDispatch: Dispatch<TInputAction>;
  filesState: IFiles;
  filesDispatch: Dispatch<TFileAction>;
  initialEditorState?: EditorState;
  formState: FormState;
  formDispatch: Dispatch<FormAction>;
  documentState: DocumentState;
  documentDispatch: Dispatch<DocumentAction>;
  savedReplyState: SavedReplyState;
  savedReplyDispatch: Dispatch<SavedReplyAction>;
  approvalState: ApprovalState;
  approvalDispatch: Dispatch<ApprovalAction>;
  onFocus?(e: SyntheticEvent): void;
}) {
  const { hasFeatureFlags } = useFeatureFlags();
  const { currentUser } = useCurrentUser();
  const editorRef = useRef<HTMLTextAreaElement>(null);
  const attachmentIds = props.filesState.files.map(file => file.attachmentId ?? "");
  const documentParams = props.documentState.document
    ? [
        {
          id: props.documentState.document.id,
          revision: props.documentState.document.revision,
          source: props.documentState.document.source
        }
      ]
    : [];
  const hasFiles = props.filesState.files.length > 0;
  const hasDocuments = !!props.documentState.document;
  const filesReady = every(props.filesState.files, { status: FileStatus.UPLOADED });
  const textProvided = !!props.inputState.text.length && props.inputState.text !== "\u200B";
  const hasExternalMentions = props.inputState.externals.length > 0;
  const hasExternalsAndFiles = hasExternalMentions && props.filesState.files.length > 0;
  const attachmentMenuDisabledReason = hasExternalMentions
    ? "You can’t add attachments when external people are notified"
    : undefined;
  // Can submit when
  // - there are no externals and files
  // - there is at least one file and it's ready
  // - there is a document attached
  // - there is text and files are ready
  const submitReady =
    !hasExternalsAndFiles &&
    ((!textProvided && hasFiles && filesReady) || (!textProvided && hasDocuments) || (textProvided && filesReady));

  const submitHandlerRef = useRef<() => void>(() => void 0);

  function handleSendSuccess() {
    props.formDispatch({ type: "CLEAR" });
    props.filesState.files.forEach(file => {
      trackAttachmentAdded(props.request.id, props.inputState.inputType, (file as LocalFileInfo).mimeType);
    });
    props.inputDispatch({
      type: "CLEAR_INPUT"
    });
    props.filesDispatch({ type: "CLEAR_FILES" });
    props.documentDispatch({ type: "CLEAR_DOCUMENT" });
    props.approvalDispatch({ type: "CLEAR_APPROVAL" });
  }

  const appendTextFnRef = useRef<(text: string) => void>();
  useEffect(() => {
    if (props.formState.formSchemaToSend && appendTextFnRef.current) {
      appendTextFnRef.current(props.formState.formSchemaToSend.description);
    }
  }, [props.formState.formSchemaToSend]);
  const topBanner = css`
    & > * + * {
      margin-top: var(--space-2-rem);
    }
  `;
  return (
    <SentryErrorBoundary>
      {hasFeatureFlags(FeatureFlags.RICHTEXTREPLIES) ? (
        <div
          css={[
            richEditorInputStyles,
            css`
              width: 100%;
            `
          ]}
          onKeyDown={e => {
            if (e.key === "Enter" && KeyBindingUtil.hasCommandModifier(e)) {
              return submitHandlerRef.current();
            }
          }}
        >
          {/* dummy input for testing */}
          <HiddenTestInput
            data-testid="hidden-input"
            placeholder={inputTypePlaceholders(props.approvalState.approver.name)[props.inputState.inputType]}
            value={props.inputState.text}
            onFocus={props.onFocus}
            onChange={e => {
              props.inputDispatch({
                type: "SET_INPUT_TEXT",
                text: e.target.value
              });
            }}
          />

          <FileStack filesState={props.filesState} filesDispatch={props.filesDispatch} />

          <RichEditor
            minLines={2}
            maxLines={4}
            hasMentions={true}
            initialValue=""
            onChange={(html, state) => {
              // move asynchronous work off main thread
              // seems primarily to affect perf in dev build
              setTimeout(() => {
                props.inputDispatch({
                  type: "SET_INPUT_TEXT",
                  text: htmlToBackMarkdown(html)
                });
                const mentions = findChildren(state.doc, node => node.type.name === "mention").map(
                  mention => mention.node.attrs as MentionAttrs
                );
                props.inputDispatch({
                  type: "SET_MENTION_USER_IDS",
                  mentionUserIds: mentions.filter(mention => mention.char === "@").map(mention => mention.id)
                });
                const externalEmails = mentions.filter(mention => mention.char === "+").map(mention => mention.email);
                props.inputDispatch({
                  type: "SET_EXTERNALS",
                  externals: externalEmails
                });
              });
            }}
            appendTextFnRef={appendTextFnRef}
            topBanner={(() => {
              if (props.inputState.inputType === "COMMENTS" && currentUser?.id === props.request.requester.id) {
                return (
                  <div css={topBanner}>
                    <IsRequesterBanner />
                  </div>
                );
              } else if (props.inputState.inputType === "FORMS") {
                return (
                  <div css={topBanner}>
                    <FormBanner
                      inputState={props.inputState}
                      inputDispatch={props.inputDispatch}
                      formState={props.formState}
                      formDispatch={props.formDispatch}
                    />
                  </div>
                );
              } else if (props.inputState.inputType === "APPROVALS") {
                return (
                  <div css={topBanner}>
                    <ApprovalBanner approvalState={props.approvalState} approvalDispatch={props.approvalDispatch} />
                  </div>
                );
              } else if (!!props.inputState.externals.length) {
                return (
                  <div css={topBanner}>
                    <ContextForExternalsToggle inputState={props.inputState} inputDispatch={props.inputDispatch} />
                  </div>
                );
              } else if (!!props.documentState.document) {
                return (
                  <div css={topBanner}>
                    <DocumentBanner documentState={props.documentState} documentDispatch={props.documentDispatch} />
                  </div>
                );
              }
            })()}
            leftToolbar={() => (
              <InputMenu
                inputType={props.inputState.inputType}
                inputDispatch={props.inputDispatch}
                formDispatch={props.formDispatch}
                approvalDispatch={props.approvalDispatch}
              />
            )}
            rightToolbar={appendText => (
              <>
                <LoomButton onVideoURLReady={url => appendText(url)} />
                <SavedReplyButton
                  channel="request"
                  requestId={props.request.id}
                  selectedTeam={props.request.team}
                  onSelect={reply => {
                    appendText(reply.body);
                    props.filesDispatch({
                      type: "ADD_EXISTING_ATTACHMENTS",
                      attachments: reply.attachments
                    });
                  }}
                />
                {props.inputState.inputType === "COMMENTS" && (
                  <DocumentPicker
                    documentDispatch={props.documentDispatch}
                    documentState={props.documentState}
                    requestId={props.request.id}
                  />
                )}
                {includes(["COMMENTS", "NOTES"], props.inputState.inputType) && (
                  <>
                    <AttachmentsMenu
                      resetKey={props.filesState.files.length}
                      filesState={props.filesState}
                      filesDispatch={props.filesDispatch}
                      disabledReason={attachmentMenuDisabledReason}
                    />
                  </>
                )}
                <AbsoluteTooltip
                  placement="top"
                  content={
                    hasExternalsAndFiles && <span>You can’t add attachments when external people are notified</span>
                  }
                >
                  {props.inputState.inputType === "COMMENTS" && (
                    <PostCommentButton
                      text="Send reply"
                      disabled={!submitReady}
                      hasAttachments={hasFiles}
                      params={{
                        attachmentIds,
                        documents: documentParams,
                        mentionUserIds: props.inputState.mentionUserIds,
                        externalsIncludePreviousComments: props.inputState.externalsIncludePreviousComments,
                        externals: props.inputState.externals,
                        requestId: props.request.id,
                        text: props.inputState.text
                      }}
                      requesterId={props.request.requester.id}
                      origin={props.request.origin}
                      submitHandlerRef={submitHandlerRef}
                      onSuccess={handleSendSuccess}
                    />
                  )}
                  {props.inputState.inputType === "NOTES" && (
                    <PostNoteButton
                      disabled={!submitReady}
                      params={{
                        attachmentIds,
                        mentionUserIds: props.inputState.mentionUserIds,
                        externals: props.inputState.externals,
                        externalsIncludePreviousComments: props.inputState.externalsIncludePreviousComments,
                        requestId: props.request.id,
                        text: props.inputState.text
                      }}
                      submitHandlerRef={submitHandlerRef}
                      onSuccess={handleSendSuccess}
                    />
                  )}
                </AbsoluteTooltip>
                {props.inputState.inputType === "FORMS" && (
                  <PostCommentButton
                    text="Send form"
                    disabled={!submitReady || !props.formState.formSchemaToSend}
                    hasAttachments={hasFiles}
                    params={{
                      attachmentIds,
                      mentionUserIds: props.inputState.mentionUserIds,
                      requestId: props.request.id,
                      text: props.inputState.text,
                      formId: props.formState.formSchemaToSend?.id
                    }}
                    requesterId={props.request.requester.id}
                    submitHandlerRef={submitHandlerRef}
                    onSuccess={handleSendSuccess}
                  />
                )}
                {props.inputState.inputType === "APPROVALS" && (
                  <AbsoluteTooltip
                    placement="top"
                    content={
                      hasExternalMentions && <span>Sorry, you cannot notify external people in an approval</span>
                    }
                  >
                    <SendApprovalButton
                      disabled={hasExternalMentions || !submitReady}
                      params={{
                        approverEmail: props.approvalState.approver.email,
                        approverId: props.approvalState.approver.id,
                        mentionUserIds: props.inputState.mentionUserIds,
                        requestId: props.request.id,
                        text: props.inputState.text
                      }}
                      submitHandlerRef={submitHandlerRef}
                      onSuccess={handleSendSuccess}
                    />
                  </AbsoluteTooltip>
                )}
              </>
            )}
          />
        </div>
      ) : (
        <EditorAreaWrapper
          type={props.inputState.inputType}
          hasAttachments={props.filesState.files.length > 0}
          onClick={() => {
            if (editorRef && editorRef.current) {
              editorRef.current.focus();
            }
          }}
        >
          {/* dummy input for testing */}
          <HiddenTestInput
            data-testid="hidden-input"
            placeholder={inputTypePlaceholders(props.approvalState.approver.name)[props.inputState.inputType]}
            value={props.inputState.text}
            onFocus={props.onFocus}
            onChange={e => {
              props.inputDispatch({
                type: "SET_INPUT_TEXT",
                text: e.target.value
              });
            }}
          />

          <DraftJSComponent
            {...props}
            editorRef={editorRef}
            placeholder={inputTypePlaceholders(props.approvalState.approver.name)[props.inputState.inputType]}
            data-intercom-target={inputTypeIntercomTargets[props.inputState.inputType]}
            handleSubmit={() =>
              // child submit components must assign submitHandler
              submitHandlerRef.current()
            }
            onChange={(value: EditorState) => {
              const content = value.getCurrentContent();
              props.inputDispatch({
                type: "SET_INPUT_TEXT",
                text: formatContent(content)
              });
              const mentionEntities = extractEntities<TMentionData>(content, "mention", "mention");
              const externalsEntities = extractEntities<{ email: string; name: string }>(
                content,
                "+mention",
                "mention"
              );
              props.inputDispatch({
                type: "SET_MENTION_USER_IDS",
                mentionUserIds: mentionEntities.map(mention => mention.id)
              });
              props.inputDispatch({
                type: "SET_EXTERNALS",
                externals: externalsEntities.map(mention => mention.email)
              });
            }}
            appendTextFnRef={appendTextFnRef}
            onFilesAdded={files => {
              if (props.inputState.inputType !== "APPROVALS") {
                handleFilesEvent(files, props.filesDispatch, null);
              }
            }}
            disablePlugins={{
              externals: !includes(["COMMENTS", "NOTES"], props.inputState.inputType)
            }}
          />
          <FooterRow>
            <InputMenu
              inputType={props.inputState.inputType}
              inputDispatch={props.inputDispatch}
              formDispatch={props.formDispatch}
              approvalDispatch={props.approvalDispatch}
            />
            <ToolbarWrapper>
              {" "}
              <LoomButton
                onVideoURLReady={url => {
                  if (appendTextFnRef.current) {
                    appendTextFnRef.current(url);
                  }
                }}
              />
              <EmojiPicker
                handleSelect={e => {
                  if (appendTextFnRef.current) {
                    appendTextFnRef.current(e.native);
                  }
                }}
              />
              <SavedReplyPicker
                savedReplyState={props.savedReplyState}
                savedReplyDispatch={props.savedReplyDispatch}
                channel="request"
                requestId={props.request.id}
                team={props.request.team}
                filesDispatch={props.filesDispatch}
              />
              {props.inputState.inputType === "COMMENTS" && (
                <DocumentPicker
                  documentDispatch={props.documentDispatch}
                  documentState={props.documentState}
                  requestId={props.request.id}
                />
              )}
              {includes(["COMMENTS", "NOTES"], props.inputState.inputType) && (
                <>
                  <AttachmentsMenu
                    resetKey={props.filesState.files.length}
                    filesState={props.filesState}
                    filesDispatch={props.filesDispatch}
                    disabledReason={attachmentMenuDisabledReason}
                  />
                </>
              )}
              <AbsoluteTooltip
                placement="top"
                content={
                  hasExternalsAndFiles && <span>You can’t add attachments when external people are notified</span>
                }
              >
                {props.inputState.inputType === "COMMENTS" && (
                  <PostCommentButton
                    text="Send reply"
                    disabled={!submitReady}
                    hasAttachments={hasFiles}
                    params={{
                      attachmentIds,
                      documents: documentParams,
                      mentionUserIds: props.inputState.mentionUserIds,
                      externalsIncludePreviousComments: props.inputState.externalsIncludePreviousComments,
                      externals: props.inputState.externals,
                      requestId: props.request.id,
                      text: props.inputState.text
                    }}
                    requesterId={props.request.requester.id}
                    origin={props.request.origin}
                    submitHandlerRef={submitHandlerRef}
                    onSuccess={handleSendSuccess}
                  />
                )}
                {props.inputState.inputType === "NOTES" && (
                  <PostNoteButton
                    disabled={!submitReady}
                    params={{
                      attachmentIds,
                      mentionUserIds: props.inputState.mentionUserIds,
                      externals: props.inputState.externals,
                      externalsIncludePreviousComments: props.inputState.externalsIncludePreviousComments,
                      requestId: props.request.id,
                      text: props.inputState.text
                    }}
                    submitHandlerRef={submitHandlerRef}
                    onSuccess={handleSendSuccess}
                  />
                )}
              </AbsoluteTooltip>
              {props.inputState.inputType === "FORMS" && (
                <PostCommentButton
                  text="Send form"
                  disabled={!submitReady || !props.formState.formSchemaToSend}
                  hasAttachments={hasFiles}
                  params={{
                    attachmentIds,
                    mentionUserIds: props.inputState.mentionUserIds,
                    requestId: props.request.id,
                    text: props.inputState.text,
                    formId: props.formState.formSchemaToSend?.id
                  }}
                  requesterId={props.request.requester.id}
                  submitHandlerRef={submitHandlerRef}
                  onSuccess={handleSendSuccess}
                />
              )}
              <AbsoluteTooltip
                placement="top"
                content={hasExternalMentions && <span>Sorry, you cannot notify external people in an approval</span>}
              >
                {props.inputState.inputType === "APPROVALS" && (
                  <SendApprovalButton
                    disabled={hasExternalMentions || !submitReady}
                    params={{
                      approverEmail: props.approvalState.approver.email,
                      approverId: props.approvalState.approver.id,
                      mentionUserIds: props.inputState.mentionUserIds,
                      requestId: props.request.id,
                      text: props.inputState.text
                    }}
                    submitHandlerRef={submitHandlerRef}
                    onSuccess={handleSendSuccess}
                  />
                )}
              </AbsoluteTooltip>
            </ToolbarWrapper>
          </FooterRow>
        </EditorAreaWrapper>
      )}
    </SentryErrorBoundary>
  );
}
