import { css } from "@emotion/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { mdiClose } from "@mdi/js";
import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";
import { FC, useContext, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { MaterialIcon } from "src/components";
import { SquareButton } from "src/components/Buttons";
import { dialogWidths } from "src/components/Dialogs";
import { DelayNavContext } from "src/hooks/useWindowEvents";
import zIndices from "src/styling/tokens/z-indices.json";
import { csx } from "src/util/csx";

// still needed in other parts of the code
export const MODAL_Z_INDEX = zIndices.high.value;

export const overlay = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  z-index: var(--z-higher);
  background-color: var(--overlayBackground);
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ChildContainer = csx(
  [
    css`
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      > * {
        box-sizing: border-box;
        padding: 3rem;
        @media (max-width: ${dialogWidths.large}px) {
          padding: var(--space-2-rem);
        }
      }
    `
  ],
  {
    alignTop: css`
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    `
  }
);

type ModalProps = {
  isOpen: boolean;
  onDismiss(): void;
  alignTop?: boolean;
  preventEscape?: boolean;
  ["data-testid"]?: string;
};

const transition = { duration: 0.2, ease: "easeInOut" };

export const modalAnimation = {
  visible: {
    opacity: 1,
    transition: { ...transition, when: "beforeChildren" }
  },
  hidden: {
    opacity: 0,
    transition: transition
  }
};

const contentAnimation = {
  visible: {
    y: 0,
    transition: transition
  },
  hidden: {
    y: -20,
    transition: transition
  }
};

/**
 *  portal to display a message
 */
export const Modal: FC<ModalProps> = props => {
  const { shouldDelayNav } = useContext(DelayNavContext);
  const onDismiss = () => {
    if (!props.onDismiss) return;
    if (!shouldDelayNav) {
      props.onDismiss();
    } else if (window.confirm("Changes you made may not be saved. Are you sure you want to close?")) {
      props.onDismiss();
    }
  };

  const overlayRef = useRef<HTMLDivElement>(null);
  const portalTarget: Element | null = document.querySelector("#modal");

  const handleEscape = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      onDismiss();
    }
  };
  const { isOpen } = props;
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      !props.preventEscape && document.addEventListener("keydown", handleEscape, false);
    }

    if (isOpen)
      return () => {
        document.removeEventListener("keydown", handleEscape, false);
        document.body.style.overflow = "auto";
      };
  }, [isOpen]);

  if (!portalTarget) return null;

  return createPortal(
    <AnimatePresence>
      {props.isOpen && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={modalAnimation}
          ref={overlayRef}
          css={overlay}
          data-testid={props["data-testid"]}
        >
          <ClickAwayListener
            mouseEvent="onMouseDown"
            onClickAway={e => {
              // dismiss when click bubbles from overlay
              if (e.target === overlayRef.current) {
                onDismiss();
              }
            }}
          >
            <ChildContainer alignTop={props.alignTop}>
              <motion.div
                variants={contentAnimation}
                css={css`
                  max-width: 100%;
                `}
              >
                {props.children}
              </motion.div>
            </ChildContainer>
          </ClickAwayListener>
        </motion.div>
      )}
    </AnimatePresence>,
    portalTarget
  );
};

export const CloseButton: FC<{ onClick(): void }> = props => (
  <SquareButton
    css={css`
      position: absolute;
      top: 0;
      right: 0;
    `}
    onClick={props.onClick}
    data-testid="close-modal"
  >
    <MaterialIcon path={mdiClose} size={1.1} />
  </SquareButton>
);
