import omit from "lodash/omit";
import uniqBy from "lodash/uniqBy";
import * as React from "react";
import { createContext, FC, useContext } from "react";
import { useQuery } from "react-apollo";
import { useErrorHandler } from "react-error-boundary";
import { TEAM_WORKFLOW_GET } from "src/App/Settings/Workflows/index";
import {
  TeamWorkflowGet,
  TeamWorkflowGetVariables,
  TeamWorkflowGet_teamWorkflow,
  TeamWorkflowGet_teamWorkflow_steps_stepMaps_action,
  TeamWorkflowGet_teamWorkflow_triggers_publishedVariables
} from "src/App/Settings/Workflows/typings/TeamWorkflowGet";
import {
  ActionInput,
  ActionInputMap,
  ActionKey,
  TriggerInput,
  TriggerKey,
  WorkflowPublishedVariableType,
  WorkflowSaveParams,
  WorkflowStepMapInput
} from "src/globalTypes";
import { reportDevError } from "src/util";

export interface PublishedVariable {
  name: string;
  templateString: string;
  description: string;
  type: WorkflowPublishedVariableType;
  index: StepActionIndex;
  colorCode: number;
}

interface WorkflowContextState {
  workflow: WorkflowPayload | null;
  isWorkflowEnabled: boolean;
  requiresCustomization: boolean;
  typeformId?: string;
  getWorkflowVariables(index?: StepActionIndex): PublishedVariable[] | null;
  getColorCodeForVar(variableName: string): number;
  getSubflowNames(index?: StepActionIndex): string[] | null;
}

const WorkflowContext = createContext<WorkflowContextState>({
  workflow: null,
  isWorkflowEnabled: false,
  requiresCustomization: false,
  typeformId: undefined,
  getWorkflowVariables: () => null,
  getColorCodeForVar: () => 0,
  getSubflowNames: () => null
});

export const generateWorkflowVariablesFromTriggersAndSteps = (payload: WorkflowPayload): PublishedVariable[] => {
  let variableIndex = 0;

  const triggerVariables: PublishedVariable[] = payload.triggers.flatMap(trigger =>
    trigger.publishedVariables.map(v => ({
      ...v,
      templateString: "${" + v.name + "}",
      colorCode: variableIndex++ % 7,
      index: {
        stepIndex: -1,
        actionIndex: -1
      }
    }))
  );

  const stepVariables: PublishedVariable[] = payload.steps.flatMap((step, stepIndex) =>
    step.actions.flatMap((action, actionIndex) =>
      action.publishedVariables.map(v => ({
        ...v,
        templateString: "${" + v.name + "}",
        colorCode: variableIndex++ % 7,
        index: {
          stepIndex,
          actionIndex
        }
      }))
    )
  );

  return [...triggerVariables, ...stepVariables];
};

export type SubworkflowIdtypename = TeamWorkflowGet_teamWorkflow_steps_stepMaps_action["__typename"];

interface TriggerInputPayload extends TriggerInput {
  id: string;
  publishedVariables: TeamWorkflowGet_teamWorkflow_triggers_publishedVariables[];
}

export interface StepActionIndex {
  stepIndex: number;
  actionIndex: number;
}

interface ActionInputPayload extends ActionInput {
  id: string;
  index: StepActionIndex;
  publishedVariables: TeamWorkflowGet_teamWorkflow_triggers_publishedVariables[];
}

interface StepMapInputPayload extends WorkflowStepMapInput {
  actions: ActionInputPayload[];
}

export interface WorkflowPayload extends WorkflowSaveParams {
  triggers: TriggerInputPayload[];
  steps: StepMapInputPayload[];
}

export interface DeepObject {
  [key: string]: DeepObject | string | number | null | undefined;
}

/**
 * goes through procedurally and deletes __typename property
 * should pass new object
 */
function omitTypenamesInPlace(obj: DeepObject) {
  delete obj.__typename;
  for (const k in obj) {
    const o = obj[k];
    if (o && typeof o === "object") omitTypenamesInPlace(o);
  }
  return obj;
}

export const omitTypenames = <O extends object>(obj: O): O => omitTypenamesInPlace({ ...obj } as DeepObject) as O;

const actionKeys: Record<TeamWorkflowGet_teamWorkflow_steps_stepMaps_action["__typename"], ActionKey> = {
  ApprovalRequestAction: ActionKey.ApprovalRequest,
  CreateConversationAction: ActionKey.CreateConversation,
  CreateConversationRequestAction: ActionKey.CreateConversationRequest,
  CreateNoteAction: ActionKey.CreateNote,
  CreateProjectAction: ActionKey.CreateProject,
  CreateReplyAction: ActionKey.CreateReply,
  CreateRequestAction: ActionKey.CreateRequest,
  CreateTaskAction: ActionKey.CreateTask,
  FetchUserProfileAction: ActionKey.FetchUserProfile,
  JumpIfAction: ActionKey.JumpIf,
  MapValueAction: ActionKey.MapValue,
  LaunchAction: ActionKey.Launch,
  LinkJiraRequestAction: ActionKey.LinkJiraRequest,
  SendFormAction: ActionKey.SendForm,
  SetRequestAssigneeAction: ActionKey.SetRequestAssignee,
  SetRequestCategoryAction: ActionKey.SetRequestCategory,
  SetRequestDueDateAction: ActionKey.SetRequestDueDate,
  SetRequestPriorityAction: ActionKey.SetRequestPriority,
  SetRequestProjectAction: ActionKey.SetRequestProject,
  SetRequestRequesterAction: ActionKey.SetRequestRequester,
  SetRequestStatusAction: ActionKey.SetRequestStatus,
  SetRequestTeamAction: ActionKey.SetRequestTeam,
  SetRequestTitleAction: ActionKey.SetRequestTitle
};

/**
 * Sort subworkflows depth first
 */
export function sortSubWorkflows(
  subworkflowId: string,
  queued: StepMapInputPayload[],
  sorted: StepMapInputPayload[] = []
): StepMapInputPayload[] {
  sorted = sorted.concat(queued.filter(s => s.id === subworkflowId));
  queued = queued.filter(s => s.id !== subworkflowId);
  if (queued.length === 0) return sorted;

  const unsortedIds = queued.map(s => s.id);

  // i.e. any id referenced in the last subworfklow sorted, else previous sorted
  let lastIdReferenced: string | undefined;
  for (const actionInput of sorted.flatMap(s => s.actions)) {
    // within a step check leftmost first
    const action = actionInput.map[actionInput.key];
    if (!action) {
      reportDevError("Action object undefined");
      return [...sorted, ...queued];
    }
    for (const value of Object.values(action).reverse()) {
      if (unsortedIds.includes(value)) {
        lastIdReferenced = value;
      }
    }
    // check nested flow subwokflow references
    if ("flowsList" in action) {
      for (const flow of [...action.flowsList].reverse()) {
        if (unsortedIds.includes(flow.subworkflowName)) {
          lastIdReferenced = flow.subworkflowName;
        }
      }
    }
  }

  if (!lastIdReferenced) {
    // none of the unsorted ids was found referenced in sorted
    reportDevError("The following sub-workflows are orphans: " + unsortedIds.join(", "));
    return [...sorted, ...queued];
  }

  return sortSubWorkflows(lastIdReferenced, queued, sorted);
}

const mapWorkflowPayload = (teamId: string, workflow?: TeamWorkflowGet_teamWorkflow): WorkflowPayload | null => {
  if (!workflow) return null;
  return {
    name: workflow.name,
    description: workflow.description,
    icon: workflow.icon,
    workflowId: workflow.id,
    teamId,
    triggers: workflow.triggers.map((t, i) => {
      const key = TriggerKey[t.trigger.__typename];
      const id = `trigger[${i}]:${key}`;
      if ("requestTriggerBase" in t.trigger && t.trigger.requestTriggerBase && t.trigger.requestTriggerBase.onlyIf) {
        return {
          id,
          publishedVariables: t.publishedVariables,
          key,
          map: {
            [key]: {
              requestTriggerBase: omitTypenames(t.trigger.requestTriggerBase.onlyIf)
            }
          }
        };
      }
      return {
        id,
        publishedVariables: t.publishedVariables,
        key,
        map: {
          [key]: omit(omitTypenames(t.trigger), "id")
        }
      };
    }),
    steps: sortSubWorkflows(
      "main",
      [...(workflow.steps ?? [])].map((steps, stepsIndex) => {
        const stepInput: StepMapInputPayload = {
          id: steps.subWorkflowId,
          actions: []
        };
        if (!workflow.steps) throw new Error();
        steps.stepMaps.forEach((stepMap, stepMapIndex) => {
          const key = actionKeys[stepMap.action.__typename];
          // uniqByue id (includes indices in case of debugging)
          const id = `step[${stepsIndex}][${stepMapIndex}]:${steps.subWorkflowId}:${key}`;
          const obj = omit(omitTypenames(stepMap.action), "id");
          if (obj) {
            const map: ActionInputMap = { [key]: obj };
            stepInput.actions.push({
              key,
              map,
              id,
              // initialize index (needs to be properly set after sorting)
              index: {
                stepIndex: 0,
                actionIndex: 0
              },
              publishedVariables: stepMap.publishedVariables
            });
          }
        });
        return stepInput;
      })
    ).map((step, stepIndex) => ({
      ...step,
      actions: step.actions.map((action, actionIndex) => ({
        ...action,
        index: {
          stepIndex,
          actionIndex
        }
      }))
    }))
  };
};

export const WorkflowProvider: FC<{
  team: {
    id: string;
    name: string;
  };
  workflowId: string;
}> = props => {
  const teamWorkflowRes = useQuery<TeamWorkflowGet, TeamWorkflowGetVariables>(TEAM_WORKFLOW_GET, {
    variables: {
      teamId: props.team.id,
      workflowId: props.workflowId
    }
  });

  const teamId = props.team.id;
  const _workflow = teamWorkflowRes.data?.teamWorkflow;
  const handleError = useErrorHandler();

  // Throw if the wf couldn't be loaded & the page is inactionable without the gql response
  if (!teamWorkflowRes.loading && !_workflow) handleError(new Error(`Error loading workflow "${props.workflowId}"`));

  const isWorkflowEnabled = !!_workflow?.enabled;
  const requiresCustomization = !!_workflow?.requiresCustomization;
  const typeformId = _workflow?.typeformId;
  const workflow = React.useMemo(() => mapWorkflowPayload(teamId, _workflow), [teamId, _workflow]);

  const variables = workflow ? generateWorkflowVariablesFromTriggersAndSteps(workflow) : [];

  const getWorkflowVariables = (index?: StepActionIndex): PublishedVariable[] => {
    let variablesForIndex = [...variables];
    if (index && variables.length > 0) {
      variablesForIndex = variables.filter(
        v =>
          v.index.stepIndex < index.stepIndex ||
          (v.index.stepIndex === index.stepIndex && v.index.actionIndex < index.actionIndex)
      );
    }
    return uniqBy(variablesForIndex, v => v.name);
  };

  const getColorCodeForVar = (variableName: string) => {
    if (variables.length > 0) {
      return variables.find(variable => variable.name === variableName)?.colorCode ?? 0;
    } else return 0;
  };

  const getSubflowNames = (index: StepActionIndex) => {
    return workflow?.steps.filter((step, i) => i > index.stepIndex).map(step => step.id) ?? null;
  };

  return (
    <WorkflowContext.Provider
      value={{
        workflow,
        isWorkflowEnabled,
        requiresCustomization,
        getWorkflowVariables,
        getColorCodeForVar,
        getSubflowNames,
        typeformId
      }}
    >
      {props.children}
    </WorkflowContext.Provider>
  );
};

export const useWorkflow = () => useContext(WorkflowContext);
