import { css } from "@emotion/core";
import gql from "graphql-tag";
import * as React from "react";
import { useMutation } from "react-apollo";
import Lottie from "react-lottie";
import { REQUEST_GET } from "src/App/Requests/DetailView/REQUEST_GET";
import { RequestGetVariables, RequestGet_request_tasks } from "src/App/Requests/DetailView/typings/RequestGet";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { ContentAnimationPortal } from "src/App/Root/Wrapper";
import { SetManualTaskCompleted, SetManualTaskCompletedVariables } from "src/App/Tasks/typings/SetManualTaskCompleted";
import { Button, InfoBanner } from "src/components";
import { Expandable } from "src/components/Expandable";
import { Icon, IconFromString } from "src/components/Icon";
import { Typo } from "src/styling/primitives/typography";
import { Flash } from "src/svg/icons/Flash";
import animationData from "./confetti-animation.json";

export const ManualTaskBanner: React.FC<{
  task: RequestGet_request_tasks;
  tasksShown: number;
  onSuccess: () => void;
}> = props => {
  const { emitSnack } = useSnack();

  const [setCompleted, setCompletedResponse] = useMutation<SetManualTaskCompleted, SetManualTaskCompletedVariables>(
    gql`
      mutation SetManualTaskCompleted($requestId: ID!, $taskId: ID!) {
        manualTaskComplete(requestId: $requestId, taskId: $taskId) {
          success
          code
          message
        }
      }
    `,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: REQUEST_GET,
          variables: {
            id: props.task.requestId
          } as RequestGetVariables
        }
      ]
    }
  );
  return (
    <InfoBanner blue smallIcon>
      {props.task.icon ? (
        <IconFromString
          svg={props.task.icon}
          sizeS
          css={css`
            width: 0.75rem;
            height: 1.125rem;
          `}
        />
      ) : (
        <Icon sizeS>
          <Flash />
        </Icon>
      )}
      <div
        css={css`
          display: flex;
          float: right;
          // center button container vertically when banner collapsed
          padding: var(--space-1-rem) 0px;
          & > * {
            margin-left: var(--space-4-rem);
          }
        `}
      >
        {props.task.actionLabel && props.task.actionUrl && (
          <Button
            variant="primary"
            onClick={() => {
              if (props.task.actionUrl) {
                // open new tab safely
                const newWindow = window.open(props.task.actionUrl, "_blank", "noopener,noreferrer");
                if (newWindow) newWindow.opener = null;
              }
            }}
          >
            {props.task.actionLabel}
          </Button>
        )}
        <Button
          variant="primary"
          disabled={setCompletedResponse.loading}
          onClick={async () => {
            const res = await setCompleted({
              variables: {
                requestId: props.task.requestId,
                taskId: props.task.id
              }
            });
            if (res.data?.manualTaskComplete.success) {
              emitSnack({
                type: "info",
                message: "Task completed"
              });
              props.onSuccess();
            } else if (res.data?.manualTaskComplete.success === false) {
              emitSnack({
                type: "info",
                message: res.data.manualTaskComplete.message
              });
            }
          }}
        >
          {props.task.completionLabel ?? "Done"}
        </Button>
      </div>
      <Typo.Body medium ellipsis>
        {props.task.workflow ? (
          <>
            Complete this task to resume workflow <i>{props.task.workflow.name}</i>
          </>
        ) : (
          "There is an outstanding task in this request:"
        )}
      </Typo.Body>
      <Expandable lines={props.tasksShown > 1 ? 1 : 2} text={props.task.instruction} />
    </InfoBanner>
  );
};

export const TaskCompletedSuccessAnimation: React.FC<{
  children: (trigger: () => void) => React.ReactNode;
}> = props => {
  const [showSuccessAnimation, setShowSuccessAnimation] = React.useState(false);
  return (
    <>
      {showSuccessAnimation && (
        <ContentAnimationPortal>
          <Lottie
            options={{
              autoplay: true,
              loop: false,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
            eventListeners={[
              {
                eventName: "complete",
                callback: () => setShowSuccessAnimation(false)
              }
            ]}
          />
        </ContentAnimationPortal>
      )}
      {props.children(() => setShowSuccessAnimation(true))}
    </>
  );
};
