import gql from "graphql-tag";
import { useCallback, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "react-apollo";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { searchParam } from "src/util";
import { navigate } from "src/util/router";
import { CustomerPortal } from "./typings/CustomerPortal";
import { SubscriptionCheckout } from "./typings/SubscriptionCheckout";
import { SubscriptionSync } from "./typings/SubscriptionSync";

/** As of Nov 19, no Chargebee types available so add here as needed */
declare global {
  interface Window {
    Chargebee: Chargebee;
  }
}

interface Chargebee {
  init(params: { site?: string }): void;
  getInstance(): ChargebeeInstance;
}

interface ChargebeeInstance {
  openCheckout(callbacks: { hostedPage(): Promise<{ url: string }>; close(): void }): void;
  createChargebeePortal(): {
    open(callbacks: { close(): void }): void;
  };
  setPortalSession(callbacks: { (): Promise<{ token: string }> }): void;
}

function useChargebee(): [ChargebeeInstance | null, boolean] {
  const [cbInstance, setCbInstance] = useState<ChargebeeInstance | null>(null);
  useEffect(() => {
    if (window.Chargebee) {
      setCbInstance(window.Chargebee.getInstance());
    } else {
      const el = document.createElement("script");
      el.setAttribute("src", "https://js.chargebee.com/v2/chargebee.js");
      document.body.appendChild(el);
      el.onload = () => {
        window.Chargebee.init({
          site: process.env.REACT_APP_CHARGEBEE_SITE
        });
        setCbInstance(window.Chargebee.getInstance());
      };
    }
  }, []);
  const isLoading = cbInstance === null;
  return [cbInstance, isLoading];
}

const SUBSCRIPTION_SYNC = gql`
  mutation SubscriptionSync {
    subscriptionSync {
      code
      message
      success
    }
  }
`;

function useSubscriptionSync() {
  const { emitSnack } = useSnack();

  const [syncSubscription] = useMutation<SubscriptionSync>(SUBSCRIPTION_SYNC);
  return (redirect = false) =>
    syncSubscription({
      update: (cache, response) => {
        if (response.data && !response.data.subscriptionSync.success) {
          emitSnack({
            type: "mutationError",
            message: response.data.subscriptionSync.message
          });
        } else if (response.data && response.data.subscriptionSync.success && redirect) {
          navigate(searchParam("redirect") || process.env.REACT_APP_LOGIN_REDIRECT || "/");
        }
      }
    });
}

function openCheckout(props: { cbInstance: ChargebeeInstance; data: SubscriptionCheckout; onClose: () => void }): void {
  props.cbInstance.openCheckout({
    hostedPage: async () => {
      return {
        url: props.data.subscriptionCheckout.url
      };
    },
    close() {
      props.onClose();
    }
  });
}

function openPortal(props: { cbInstance: ChargebeeInstance; data: CustomerPortal; onClose: () => void }): void {
  props.cbInstance.setPortalSession(async () => ({
    token: props.data.customerPortal.token
  }));
  const cbPortal = props.cbInstance.createChargebeePortal();
  cbPortal.open({
    close() {
      props.onClose();
    }
  });
}

const SUBSCRIPTION_CHECKOUT = gql`
  query SubscriptionCheckout {
    subscriptionCheckout {
      url
    }
  }
`;

const CUSTOMER_PORTAL = gql`
  query CustomerPortal {
    customerPortal {
      token
    }
  }
`;

export function useCheckoutOpener(redirect?: boolean): [() => void, boolean] {
  const [cbInstance, isLoading] = useChargebee();
  const subscriptionSync = useSubscriptionSync();
  const [getCheckout, checkoutResponse] = useLazyQuery<SubscriptionCheckout>(SUBSCRIPTION_CHECKOUT, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (cbInstance && data.subscriptionCheckout) {
        openCheckout({ cbInstance: cbInstance, data: data, onClose: () => subscriptionSync(!!redirect) });
      }
    }
  });
  const opener = useCallback(() => {
    if (cbInstance && checkoutResponse.data && checkoutResponse.data.subscriptionCheckout) {
      openCheckout({
        cbInstance: cbInstance,
        data: checkoutResponse.data,
        onClose: () => subscriptionSync(!!redirect)
      });
    } else {
      getCheckout();
    }
  }, [cbInstance, checkoutResponse, subscriptionSync, getCheckout, redirect]);
  return [opener, isLoading || checkoutResponse.loading];
}

export function usePortalOpener(): [() => void, boolean] {
  const [cbInstance, isLoading] = useChargebee();
  const subscriptionSync = useSubscriptionSync();
  const [getPortal, portalResponse] = useLazyQuery<CustomerPortal>(CUSTOMER_PORTAL, {
    onCompleted: data => {
      if (cbInstance && data.customerPortal) {
        openPortal({ cbInstance: cbInstance, data: data, onClose: subscriptionSync });
      }
    }
  });
  const opener = useCallback(() => {
    if (cbInstance && portalResponse.data && portalResponse.data.customerPortal) {
      openPortal({ cbInstance: cbInstance, data: portalResponse.data, onClose: subscriptionSync });
    } else {
      getPortal();
    }
  }, [cbInstance, portalResponse, subscriptionSync, getPortal]);
  return [opener, isLoading || portalResponse.loading];
}
