import { WindowLocation } from "@reach/router";
import { PureQueryOptions } from "apollo-client";
import gql from "graphql-tag";
import { REQUEST_TEAM_FRAGMENT } from "src/fragments/TeamInfoFragment";
import { USER_INFO_FRAGMENT } from "src/fragments/UserInfoFragment";
import { REQUEST_LIST_FRAGMENT } from "./RequestListFragment";
import { IRequestUpdateMap } from "./RequestListUpdates";

export const REQUEST_LIST_GET = gql`
  query RequestListGet($view: RequestListView!, $query: RequestListQuery!) {
    requestListPage(view: $view, query: $query) {
      requests {
        ...RequestListFragment
      }
      pageNumber
      count
    }
    teamList {
      ...RequestTeamInfo
    }
    currentUser {
      ...UserInfo
    }
  }
  ${REQUEST_LIST_FRAGMENT}
  ${REQUEST_TEAM_FRAGMENT}
  ${USER_INFO_FRAGMENT}
`;

export interface ListComponentProps {
  listDimensionsRef: React.MutableRefObject<HTMLElement | null>;
  location?: WindowLocation;
  queriesToRefetch?: Array<string | PureQueryOptions>;
  requestUpdateMap: IRequestUpdateMap;
  emptyState?: React.ReactNode;
}
