import gql from "graphql-tag";
import React, { useEffect } from "react";
import { useMutation } from "react-apollo";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { INTEGRATIONS_LIST_GET } from "src/App/Settings/Integrations/Overview";
import { Dialog, LoadingBar } from "src/components";
import { Modal } from "src/portals/Modal";
import { Typo } from "src/styling/primitives/typography";
import { IntegrationAddGChat, IntegrationAddGChatVariables } from "./typings/IntegrationAddGChat";

export const GChatInstallModal = (props: { onDismiss: () => void; onSuccess: () => void; isOpen: boolean }) => {
  const { emitSnack } = useSnack();

  // The association_state URL query parameter is filled by Google Chat during the
  // association flow: Google Chat opens Back inside a popup window, passing the
  // association state in the URL. This modal then calls back the backend,
  // confirms the association with Google Chat, and redirects to a link that tells
  // Google that bot configuration is finished.
  const associationState = new URLSearchParams(window.location.search).get("association_state") || "";
  const [addGChat, response] = useMutation<IntegrationAddGChat, IntegrationAddGChatVariables>(
    gql`
      mutation IntegrationAddGChat($integration: CreateIntegrationGChat!) {
        integrationAddGChat(integration: $integration) {
          code
          message
          success
          redirect {
            url
          }
        }
      }
    `,
    {
      onCompleted: data => {
        if (data.integrationAddGChat.success) {
          props.onSuccess();
          if (!data.integrationAddGChat.redirect) {
            throw new Error("Redirect URL was not defined");
          }
          window.location.href = data.integrationAddGChat.redirect.url;
        } else if (!data.integrationAddGChat.success) {
          emitSnack({
            type: "mutationError",
            message: data.integrationAddGChat.message
          });
        }
      },
      refetchQueries: [{ query: INTEGRATIONS_LIST_GET }]
    }
  );

  useEffect(() => {
    if (associationState && !response.called) {
      addGChat({
        variables: { integration: { associationState } }
      });
    }
  }, [associationState, response, addGChat]);

  return (
    <Modal isOpen={props.isOpen} onDismiss={props.onDismiss}>
      <Dialog title="Connect Google Chat" onClose={props.onDismiss} medium>
        {response.loading && (
          <>
            <LoadingBar />
            <div>Adding Google Chat...</div>
          </>
        )}
        {!associationState && (
          <div>No association state in URL. Check the documentation to see how to link Back to Google Chat.</div>
        )}
        {response.data && !response.loading && (
          <div>
            All set! This window should close automatically, but if it doesn't feel free to close it and return to
            Google Chat.
          </div>
        )}
      </Dialog>
    </Modal>
  );
};

interface GChatInfoModalProps {
  onDismiss: () => void;
  isOpen: boolean;
}

export const GChatInfoModal = (props: GChatInfoModalProps) => {
  return (
    <Modal isOpen={props.isOpen} onDismiss={props.onDismiss}>
      <Dialog title="Connect Google Chat" onClose={props.onDismiss} medium>
        <Typo.Body>
          To connect Google Chat you need to first install the Back application to one of your teams there to trigger
          the association process to ensure we can create a connection between Google Chat and Back.
        </Typo.Body>
        <Typo.Body medium>
          For more information check out the documentation or get in touch through the support button in the lower right
          corner!
        </Typo.Body>
      </Dialog>
    </Modal>
  );
};
