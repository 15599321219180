import styled from "@emotion/styled";
import gql from "graphql-tag";
import * as React from "react";
import { Query } from "react-apollo";
import { LoadingBar } from "src/components";
import { Toast } from "src/portals/Toast";
import { UserUpdateSubmission } from "./Submission";
import { GSettingsUserGet, GSettingsUserGetVariables } from "./typings/GSettingsUserGet";

const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 28rem;
  background: var(--white);
`;

export const SETTINGS_USER_GET = gql`
  query GSettingsUserGet($id: ID!) {
    user(id: $id) {
      id
      name
      email
    }
  }
`;

export interface IUserUpdateProps {
  /**
   * User.id uuid
   */
  userId: string;
  /**
   * path to redirect after succesful update
   */
  onFinish(): void;
}

/**
 * Container for editing user details
 * Queries user fields to edit
 */
export const UserUpdateContainer: React.ComponentType<IUserUpdateProps> = props => (
  <Wrapper>
    <Query<GSettingsUserGet, GSettingsUserGetVariables> query={SETTINGS_USER_GET} variables={{ id: props.userId }}>
      {({ data, error, loading }) => {
        if (loading) {
          return <LoadingBar />;
        }
        if (error) {
          return <Toast message={error.message} kind="error" />;
        }
        return (!loading && data && data.user && <UserUpdateSubmission {...props} user={data.user} />) || null;
      }}
    </Query>
  </Wrapper>
);
