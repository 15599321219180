import css from "@emotion/css";
import { mdiInformation } from "@mdi/js";
import { format } from "date-fns";
import { motion } from "framer-motion";
import gql from "graphql-tag";
import * as React from "react";
import { useQuery } from "react-apollo";
import { ExportAnswersButton, Submission } from "src/App/Forms/Manager/ExportAnswers";
import { GetFormSubmissions } from "src/App/Forms/Manager/typings/GetFormSubmissions";
import { UserName } from "src/App/User/UserName";
import { Col, EmptyState, InfoBanner, LinkTypeButton, LoadingBar, MaterialIcon, Row } from "src/components";
import { Dialog } from "src/components/Dialogs";
import { USER_INFO_FRAGMENT } from "src/fragments/UserInfoFragment";
import { Modal } from "src/portals/Modal";
import { align, body, justify, row } from "src/styling/primitives";
import { Typo } from "src/styling/primitives/typography";
import { formatLongDate, pluralize } from "src/util/formatters";

const SUBMISSION_LIST_QUERY = gql`
  query GetFormSubmissions($formId: ID!) {
    schema(id: $formId) {
      displayName
    }
    formSubmissionList(formId: $formId) {
      submissions {
        id
        submitTime
        submitter {
          ...UserInfo
        }
        schema {
          id
          pk
          revision
          displayName
          fieldsList {
            ... on TextField {
              __typename
              textFieldType
              displayName
              value
              required
            }
            ... on DropdownField {
              __typename
              displayName
              optionsList
              selected
              required
            }
            ... on AttachmentsField {
              __typename
              displayName
              required
              attachment {
                id
                displayName
                size
                receiveTime
              }
            }
          }
        }
      }
    }
  }
  ${USER_INFO_FRAGMENT}
`;

type SubmissionWithSubmitDate = Submission & { submitTimeAsDate: Date };

export const ExportAnswersModal: React.FC<{ formId: string; isOpen: boolean; onDismiss(): void }> = props => {
  const submissionListResponse = useQuery<GetFormSubmissions>(SUBMISSION_LIST_QUERY, {
    variables: {
      formId: props.formId
    }
  });

  const revisionMap = new Map<number, SubmissionWithSubmitDate[]>();

  if (submissionListResponse.data?.formSubmissionList.submissions) {
    for (const s of submissionListResponse.data.formSubmissionList.submissions) {
      const submission: SubmissionWithSubmitDate = {
        ...s,
        submitTimeAsDate: s.submitTime ? new Date(s.submitTime) : new Date()
      };
      const current = revisionMap.get(s.schema.revision) || [];
      revisionMap.set(s.schema.revision, [...current, submission]);
    }
  }

  const sortedRevisions = Array.from(revisionMap.keys()).sort((a, b) => b - a);

  return (
    <>
      {submissionListResponse.loading && <LoadingBar />}
      <Modal isOpen={props.isOpen} onDismiss={props.onDismiss} alignTop>
        <Dialog large onClose={props.onDismiss}>
          <Col flex="1 1 auto">
            <div
              css={css`
                margin-bottom: var(--space-5-rem);

                & > * + * {
                  margin-top: var(--space-3-rem);
                }
              `}
            >
              <Typo.Body sizeXXL>Answers for {submissionListResponse.data?.schema?.displayName}</Typo.Body>
              <Typo.Body>
                Download answers to this form either individually or in bulk:
                <ul>
                  <li>
                    <span css={[body.bold]}>Per version</span> - all submitted answers to a specific version of the form
                  </li>
                  <li>
                    <span css={[body.bold]}>Per month</span> - all submitted answers to a specific version of the form
                    submitted within one month
                  </li>
                </ul>
              </Typo.Body>
            </div>
            <div
              css={css`
                & > div + div {
                  margin-top: var(--space-6-rem);
                }
              `}
            >
              {!submissionListResponse.loading && revisionMap.size === 0 && (
                <EmptyState title="No answers were submitted for this form yet" variant="info" />
              )}
              {!submissionListResponse.loading &&
                sortedRevisions.map(rev => {
                  const submissions = revisionMap.get(rev) || [];
                  const monthMap = new Map<string, SubmissionWithSubmitDate[]>();

                  for (const s of submissions) {
                    const month = format(s.submitTimeAsDate, "yyyy-MM");
                    const current = monthMap.get(month) || [];
                    monthMap.set(month, [...current, s]);
                  }

                  const months = Array.from(monthMap.keys()).sort().reverse();

                  return (
                    <div
                      css={css`
                        border-top: var(--border-lightGrey);
                        border-bottom: var(--border-lightGrey);
                      `}
                    >
                      <Row
                        css={css`
                          padding: var(--space-1-rem) 0;
                          align-items: center;
                          justify-content: space-between;
                        `}
                      >
                        <Typo.Body sizeXL medium>
                          Version {rev}
                        </Typo.Body>
                        <ExportAnswersButton
                          submissions={submissions}
                          label="Download all"
                          buttonProps={{ size: "small" }}
                        />
                      </Row>
                      {months.map(m => (
                        <MonthlySubmissions submissions={monthMap.get(m) || []} />
                      ))}
                    </div>
                  );
                })}
              <InfoBanner>
                <MaterialIcon path={mdiInformation} size={1} />
                Whenever you update a form, a new version is created. As different versions can have different
                questions, you can only download answers per version.
              </InfoBanner>
            </div>
          </Col>
        </Dialog>
      </Modal>
    </>
  );
};

const MonthlySubmissions: React.FC<{ submissions: SubmissionWithSubmitDate[] }> = props => {
  const [isOpen, setIsOpen] = React.useState(false);
  const monthLabel = format(props.submissions[0].submitTimeAsDate, "MMMM yyyy");
  return (
    <div>
      <div
        css={[
          css`
            border-top: var(--border-lightGrey);
            padding: var(--space-1-rem) 0;
          `,
          row,
          align.center,
          justify.spaceBetween
        ]}
      >
        <div css={[row, align.center]}>
          <Typo.Body medium>{monthLabel}</Typo.Body>&nbsp;&bull;&nbsp;
          <LinkTypeButton
            onClick={() => setIsOpen(!isOpen)}
            css={css`
              color: var(--text-3); ;
            `}
          >
            {isOpen ? "Hide" : "Show"}&nbsp;{pluralize(props.submissions.length, "answer", "answers")}
          </LinkTypeButton>
        </div>
        <ExportAnswersButton submissions={props.submissions} fileSuffix={monthLabel} />
      </div>
      <motion.div
        css={css`
          overflow: hidden;
        `}
        initial={{ height: 0 }}
        animate={{
          height: isOpen ? "auto" : 0,
          transition: {
            ease: "easeInOut"
          }
        }}
      >
        {props.submissions.map(s => (
          <div
            css={[
              css`
                padding: var(--space-1-rem) 0 var(--space-1-rem) var(--space-2-rem);
                border-top: var(--border-lightGrey);

                & > button {
                  visibility: hidden;
                }

                &:hover > button {
                  visibility: visible;
                }
              `,
              row,
              align.center,
              justify.spaceBetween
            ]}
          >
            <div css={[row, align.center]}>
              <Typo.Body medium sizeS>
                {s.submitter && <UserName user={s.submitter} />}&nbsp;&bull;&nbsp;
              </Typo.Body>
              <Typo.Body light sizeS>
                {formatLongDate(s.submitTimeAsDate)}
              </Typo.Body>
            </div>
            <ExportAnswersButton
              submissions={[s]}
              label="Download answer"
              buttonProps={{ size: "small", fontSize: "var(--font-size-helper)" }}
            />
          </div>
        ))}
      </motion.div>
    </div>
  );
};
