import { css } from "@emotion/core";
import { mdiClose } from "@mdi/js";
import { Formik } from "formik";
import { some } from "lodash";
import * as React from "react";
import { MutationFunction } from "react-apollo";
import { MultiValueProps } from "react-select/src/components/MultiValue";
import { ValueType } from "react-select/src/types";
import { useFeatureFlags, FeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import { getUserList } from "src/App/User/getUserList";
import { BigButton, CancelButton, Col, LoadingBar, MaterialIcon, Row, SubmitButton } from "src/components";
import { Form } from "src/components/Fields/FormWrappers";
import { AsyncCreateableMultiSelect, SmallSelectText } from "src/components/Fields/Select";
import { trackTeamAdd } from "src/util/analytics";
import { csx } from "src/util/csx";
import { navigate } from "src/util/router";
import * as yup from "yup";
import { ITeamAddProps } from "./Container";
import { GSettingsTeamAddGet, GSettingsTeamAddGet_userList2_users } from "./typings/GSettingsTeamAddGet";
import { GSettingsTeamAddSend, GSettingsTeamAddSendVariables } from "./typings/GSettingsTeamAddSend";

/**
 * Validation rules
 */
const FormSchema = yup.object().shape({
  invitees: yup.array(
    yup.object().shape({
      email: yup.string().email(),
      id: yup.string(),
      new: yup.boolean()
    })
  )
});

/**
 * Multi select invitee shape
 * Existing users have an id
 * New users have field new: true
 */
interface IOptionValue {
  label: string;
  value:
    | (GSettingsTeamAddGet_userList2_users & { new?: boolean })
    | {
        new: true;
        email: string;
        id: undefined;
        name?: string;
      };
}

/**
 * Map invitee form values to mutation input
 * @param invitees Form emails / user ids
 */
function mapInvitees(invitees: IOptionValue["value"][]) {
  return {
    emails: invitees.map(invitee => invitee.email)
  };
}

/**
 * Submission handler
 * @param teamAdd apollo mutation fn
 * @param redirect url path to redirect after submission
 * @param params mutation variable object
 */
function submitTeamAddMutation(
  teamAdd: MutationFunction<GSettingsTeamAddSend, GSettingsTeamAddSendVariables>,
  redirect: string,
  params: GSettingsTeamAddSendVariables["params"],
  teamName: string
) {
  teamAdd({
    update: async (cache, response) => {
      if (response.data && response.data.teamAdd.success) {
        navigate(redirect);
        trackTeamAdd(params.teamId, teamName, params.emails as string[]);
      }
    },
    variables: {
      params
    }
  });
}
const ValueWrapper = csx(
  [
    css`
      border-radius: 0;
      flex: 1 1 auto;
    `
  ],
  {}
);

/**
 * Overrides default react-select selected label
 */
export const MultiValueLabel = (commonProps: MultiValueProps<IOptionValue>) => (
  <ValueWrapper {...commonProps.innerProps} new={commonProps.data.value.new}>
    {commonProps.data.value.name ? (
      <>
        {commonProps.data.value.name}{" "}
        <span
          style={{
            color: "var(--text-3)"
          }}
        >
          ({commonProps.data.value.email})
        </span>
      </>
    ) : (
      commonProps.data.value.email
    )}
  </ValueWrapper>
);

/**
 * Overrides default react-select selected remove button
 */
export const MultiValueRemove = (commonProps: MultiValueProps<IOptionValue>) => (
  <ValueWrapper {...commonProps.innerProps} new={commonProps.data.value.new} cursor="default">
    <MaterialIcon path={mdiClose} size={1} />
  </ValueWrapper>
);

/**
 * Form component for editing team details
 */
export const TeamAddForm: React.ComponentType<
  GSettingsTeamAddGet &
    ITeamAddProps & {
      teamAdd: MutationFunction<GSettingsTeamAddSend, GSettingsTeamAddSendVariables>;
    }
> = props => {
  const { hasFeatureFlags } = useFeatureFlags();
  const listInternalOnly = hasFeatureFlags(FeatureFlags.LISTINTERNALONLY);

  return (
    <Formik<{ invitees: IOptionValue["value"][] }>
      enableReinitialize={true}
      onSubmit={payload => {
        submitTeamAddMutation(
          props.teamAdd,
          props.redirect,
          {
            ...mapInvitees(payload.invitees),
            teamId: props.team_id
          },
          props.team.name
        );
      }}
      initialValues={{
        // Automatically preselect the current user, if specified.
        invitees: props.preselectCurrentUser
          ? [
              {
                email: props.currentUser?.email ?? "",
                id: props.currentUser?.id ?? "",
                name: props.currentUser?.name ?? ""
              } as GSettingsTeamAddGet_userList2_users
            ]
          : []
      }}
      validationSchema={FormSchema}
      render={form => {
        function isValidNewOption(value: string) {
          return (
            value.length > 0 &&
            !some(props.userList2, ["email", value]) &&
            !some(form.values.invitees, ["email", value]) &&
            yup.string().email().isValidSync(value)
          );
        }

        return (
          <Form onSubmit={form.handleSubmit} padding="0">
            <Col style={{ width: "35rem" }}>
              <AsyncCreateableMultiSelect<IOptionValue>
                noLabel={true}
                isMulti={true}
                defaultOptions={true}
                loadOptions={(value: string) =>
                  getUserList(value, false, listInternalOnly).then(userList =>
                    Promise.resolve(
                      userList.users
                        .filter(user => !some(form.values.invitees, ["email", user.email]))
                        .map(user => ({
                          label: user.name ? (
                            <>
                              {user.name} <SmallSelectText>({user.email})</SmallSelectText>
                            </>
                          ) : (
                            user.email
                          ),
                          value: {
                            email: user.email,
                            id: user.id,
                            name: user.name
                          }
                        }))
                    )
                  )
                }
                // At first, we tell the component that every new option is valid, because we want to be
                // able to show the "Invite by email: ..." option while the user is typing an address.
                // The validity of the new option will instead be checked when the user actually tries
                // to create the new option (see below).
                isValidNewOption={value => true}
                getNewOptionData={value => ({
                  display: value,
                  label: isValidNewOption(value)
                    ? `Invite by email: ${value}`
                    : "Enter an email address or select a user...",
                  value: {
                    email: value,
                    id: undefined,
                    new: true
                  }
                })}
                createOptionPosition="first"
                noOptionsMessage={() => "Please enter a valid email to invite"}
                components={{
                  MultiValueLabel,
                  MultiValueRemove
                }}
                value={form.values.invitees.map(invitee => ({
                  display: invitee.name || invitee.email,
                  key: invitee.email,
                  label: invitee.name || invitee.email,
                  value: invitee
                }))}
                onCreateOption={value => {
                  if (isValidNewOption(value)) {
                    form.setFieldValue("invitees", [
                      ...form.values.invitees,
                      {
                        email: value,
                        id: undefined,
                        new: true
                      }
                    ]);
                  }
                }}
                onChange={(data: ValueType<IOptionValue>) => {
                  if (Array.isArray(data)) {
                    form.setFieldValue(
                      "invitees",
                      data.map(option => option.value)
                    );
                  }
                }}
              />
              <Row justify="flex-end" margin="2rem 0 0 0">
                {!form.isSubmitting && (
                  <>
                    {props.skippable && (
                      <CancelButton onClick={() => navigate(props.redirect)}>{props.skipText || "Cancel"}</CancelButton>
                    )}
                    {props.bigButton && (
                      <BigButton type="submit" disabled={form.values.invitees.length === 0}>
                        {props.submitText || "Invite members"}
                      </BigButton>
                    )}
                    {!props.bigButton && (
                      <SubmitButton disabled={form.values.invitees.length === 0}>
                        {props.submitText || "Invite members"}
                      </SubmitButton>
                    )}
                  </>
                )}
                {form.isSubmitting && <LoadingBar />}
              </Row>
            </Col>
          </Form>
        );
      }}
    />
  );
};
