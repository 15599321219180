import { css } from "@emotion/core";
import { PureQueryOptions } from "apollo-client";
import React from "react";
import { RequestStatus } from "src/globalTypes";
import { dimensions } from "src/styling/layout";
import { align, col, justify, row } from "src/styling/primitives";
import { Card } from "src/styling/primitives/Card";
import { Typo } from "src/styling/primitives/typography";
import { navigate } from "src/util/router";
import { Project } from "./ListPage";
import { SelectProjectStatus } from "./Status";

export const ProjectCard: React.FC<{
  project: Project;
  className?: string;
  queriesToRefetch?: Array<string | PureQueryOptions>;
}> = props => {
  const totalRequests = props.project.projectRequestCounts.reduce((acc, { count }) => acc + count, 0);
  const completedRequests = props.project.projectRequestCounts
    .filter(({ status }) => status === RequestStatus.RESOLVED)
    .reduce((acc, { count }) => acc + count, 0);
  const percentCompleted = totalRequests === 0 ? 0 : completedRequests / totalRequests;
  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <Card
        hasInteractions
        css={css`
          display: flex;
          flex: 1 1 auto;
          min-width: ${dimensions.card.minWidth}rem;
          max-width: ${dimensions.card.maxWidth}rem;
          margin: 0 auto;
        `}
        className={props.className}
        onClick={() => navigate(`/projects/${props.project.id}`)}
      >
        <div
          css={[
            col,
            css`
              & > * + * {
                margin-top: var(--space-2-rem);
              }
            `
          ]}
        >
          <Typo.Body sizeL medium ellipsis title={props.project.title}>
            {props.project.title}
          </Typo.Body>
          <div css={[row, align.center]}>
            <CompletedPie
              percent={percentCompleted}
              css={css`
                flex: 0 0 auto;
                width: 1.125rem;
                height: 1.125rem;
                margin-right: 0.5rem;
              `}
            />
            <Typo.Body ellipsis>
              {completedRequests}/{totalRequests} Resolved • {props.project.team.name}
            </Typo.Body>
          </div>
        </div>
        <div
          css={[
            row,
            justify.end,
            css`
              padding-top: var(--space-1-rem);
            `
          ]}
        >
          <SelectProjectStatus
            projectId={props.project.id}
            status={props.project.status}
            queriesToRefetch={props.queriesToRefetch}
          />
        </div>
      </Card>
    </div>
  );
};

/**
 * Draw a percent completed (pie) svg
 * @param percent [0, 1.0]
 */
export function CompletedPie(props: { className?: string; percent: number }) {
  const largeArcFlag = props.percent > 0.5 ? 1 : 0;
  const arcX = Math.cos(2 * Math.PI * props.percent);
  const arcY = Math.sin(2 * Math.PI * props.percent);
  /* A (arc cmd): rx ry x-axis-rotation large-arc-flag sweep-flag x y */
  const arcPath = `M 1 0 A 1 1 0 ${largeArcFlag} 1 ${arcX} ${arcY} L 0 0 Z`;
  return (
    <svg
      /* set svg coords to [-1, 1] around 0, 0 */
      viewBox="-1 -1 2 2"
      className={props.className}
      css={css`
        width: 1rem;
        height: 1rem;
        transform: rotate(-0.25turn);
      `}
    >
      <circle
        r={1}
        css={css`
          fill: var(--green-1);
        `}
      />
      <path
        d={arcPath}
        css={css`
          fill: var(--green-7);
        `}
      ></path>
    </svg>
  );
}
