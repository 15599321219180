import { format, formatISO } from "date-fns";
import { RequestChannels, RequestStatus } from "src/globalTypes";
import timeago from "timeago.js";

export * from "./mrkdwn";

/**
 * Formats a name as two initials
 */
export const initialsOf = (name: string) =>
  name
    .split(" ")
    // filter out prefixes / suffixes
    .filter(n => n[n.length - 1] !== ".")
    .filter((n, i, a) => i === 0 || i === a.length - 1)
    .map(n => {
      const firstCodePoint = n.codePointAt(0);
      if (!firstCodePoint) {
        return "";
      }

      return String.fromCodePoint(firstCodePoint).toUpperCase();
    })
    .join("");

/**
 * Formats RequestStatus enum as readable strings
 */
export const statusText = (status: RequestStatus) => {
  switch (status) {
    case RequestStatus.UNSETREQUESTSTATUS:
      return "No status";
    case RequestStatus.NOTSTARTED:
      return "To do";
    case RequestStatus.INPROGRESS:
      return "In progress";
    case RequestStatus.WAITING:
      return "Waiting";
    case RequestStatus.RESOLVED:
      return "Resolved";
  }
};

/**
 * order status keys (apollo sorts them for diffs)
 * omits unset status
 */
export const RequestStatusKeys = [
  RequestStatus.NOTSTARTED,
  RequestStatus.INPROGRESS,
  RequestStatus.WAITING,
  RequestStatus.RESOLVED
];

/**
 * Formats time in/ago w/ custom text
 * See: https://timeago.org/
 */
export const formatTime = (datetime: Date | string) => {
  const localeFunc = (num: number, index: number, totalSec: number) => {
    return [
      ["just now", "right now"],
      ["just now", "right now"],
      ["1 minute ago", "in 1 minute"],
      ["%s minutes ago", "in %s minutes"],
      ["1 hour ago", "in 1 hour"],
      ["%s hours ago", "in %s hours"],
      ["1 day ago", "in 1 day"],
      ["%s days ago", "in %s days"],
      ["1 week ago", "in 1 week"],
      ["%s weeks ago", "in %s weeks"],
      ["1 month ago", "in 1 month"],
      ["%s months ago", "in %s months"],
      ["1 year ago", "in 1 year"],
      ["%s years ago", "in %s years"]
    ][index];
  };
  timeago.register("my-locale-time", localeFunc);

  return timeago().format(new Date(datetime), "my-locale-time");
};

/**
 * Formats time in/ago with custom text for due dates
 */
export const formatDueDate = (datetime: Date | string) => {
  const localeFunc = (num: number, index: number, totalSec: number) => {
    return [
      ["just now", "today"],
      ["just now", "today"],
      ["just now", "today"],
      ["today", "today"],
      ["today", "today"],
      ["today", "today"],
      ["yesterday", "tomorrow"],
      ["%s days ago", "in %s days"],
      ["1 week ago", "in 1 week"],
      ["%s weeks ago", "in %s weeks"],
      ["1 month ago", "in 1 month"],
      ["%s months ago", "in %s months"],
      ["1 year ago", "in 1 year"],
      ["%s years ago", "in %s years"]
    ][index];
  };
  timeago.register("my-locale-date", localeFunc);

  const d = new Date(datetime);
  const now = new Date();
  if (d.getTime() > now.getTime()) {
    d.setDate(d.getDate() + 1);
  }
  return timeago().format(d, "my-locale-date");
};

/**
 * Formats time in/ago with custom text for due dates
 */
export const formatLongDate = (datetime: Date | string) => {
  return format(new Date(datetime), "MMMM do, 'at' HH:mm");
};

/**
 * Formats time in/ago with custom text for due dates
 */
export const formatShortDate = (datetime: Date | string) => {
  return format(new Date(datetime), "dd.MM.yyyy");
};

/**
 * Formats a string as alphanumeric kebab case.
 *
 * @param str The source string to format.
 * @param charMap Optional object map to localize characters.
 * Each fromChar must be a single character.
 * By default transforms ß -> ss.
 * @returns Returns a url friendly slug.
 */
export const kebab = (str: string, charMap: { [fromChar: string]: string } = { ß: "ss" }) =>
  str
    .split("")
    .map(c => charMap[c] || c)
    .join("")
    .normalize("NFD")
    // remove non- alphanumeric, space or hyphen
    .replace(/[^a-zA-Z0-9\s-]/g, "")
    .replace(/\s+/g, "-")
    .replace(/^-+|-+$/g, "")
    .replace(/-+/g, "-")
    .toLowerCase();

export const capitalizeFirst = (str: string) => `${str.substr(0, 1).toUpperCase()}${str.substr(1).toLowerCase()}`;

/**
 * Format file size in metric (base 10)
 * @param size number of bytes
 */
export const fileSize = (size: number) => {
  if (size / 1000 >= 1000) {
    return `${Number(size / (1000 * 1000)).toFixed(1)} MB`;
  } else if (size >= 1000) {
    return `${Math.round(size / 1000)} kB`;
  } else if (size === 1) {
    return "1 byte";
  } else {
    return `${size} bytes`;
  }
};

/**
 * Show a suitable icon for each request channel
 */
export const channelText = (channel: RequestChannels) => {
  switch (channel) {
    case RequestChannels.UNSETREQUESTCHANNELS:
      return "unknown";
    case RequestChannels.WEB:
      return "web";
    case RequestChannels.SLACK:
      return "Slack";
    case RequestChannels.EMAIL:
      return "email";
    case RequestChannels.JIRA:
      return "Jira";
    case RequestChannels.CONVERSATIONS:
      return "Outreach";
    case RequestChannels.REQUEST_CHANNEL_UNKNOWN:
    default:
      return "unknown";
  }
};

/* format Date obj as ISO 8601 full-date string for graphql  */
export const formatDateString = (date: Date): ScalarDate =>
  formatISO(date, {
    representation: "date"
  });

/* format Date obj as ISO 8601 date-time string for graphql  */
export const formatDateTimeString = (date: Date): ScalarDateTime => formatISO(date);

const pluralRules = new Intl.PluralRules("en-US");

export const isPlural = (count: number) => pluralRules.select(count) === "other";

export const pluralize = (count: number, singular: string, plural: string, zero?: string) => {
  const grammaticalNumber = pluralRules.select(count);
  switch (grammaticalNumber) {
    case "one":
      return count + " " + singular;
    case "other":
      if (count === 0 && zero) {
        return zero;
      }

      return count + " " + plural;
    default:
      throw new Error("Unknown: " + grammaticalNumber);
  }
};
