import gql from "graphql-tag";
export const FORM_SENT_DETAILS = gql`
  fragment FormSentDetails on Form {
    id
    submitTime
    schema {
      id
      displayName
    }
  }
`;
