import styled from "@emotion/styled";
import { FieldArray } from "formik";
import { indexOf } from "lodash";
import * as React from "react";
import { categoryList } from "src/App/Settings/defaultCategories";
import { Checkbox, Col, Row } from "src/components";
import { FormLabel } from "src/components/Fields/FormWrappers";
import { fontSizes } from "src/styling/typography";

const Categories = styled.div`
  padding: 0.5rem;
  height: 18rem;
  overflow-y: scroll;
  border: 1px solid var(--border);
  border-radius: var(--border-radius-s);
  font-size: ${fontSizes.default}rem;
  line-height: 1.5;

  & label {
    cursor: pointer;
  }
`;

export const TeamCategoriesComponent: React.FC<{ selectedCategories: string[] }> = props => (
  <Col>
    <FormLabel>Team categories</FormLabel>
    <Categories>
      <FieldArray
        name="categories"
        render={arrayHelpers => (
          <Col>
            {categoryList.map((category, i) => {
              const index = indexOf(props.selectedCategories, category.name);
              const isCategorySelected = index > -1;
              return (
                <Row key={i} margin="0.375rem">
                  <Checkbox
                    id={category.name}
                    checked={isCategorySelected}
                    onChange={() => {
                      if (isCategorySelected) {
                        arrayHelpers.remove(index);
                      } else {
                        arrayHelpers.push(category);
                      }
                    }}
                  />
                  <label htmlFor={category.name}>{category.name}</label>
                </Row>
              );
            })}
          </Col>
        )}
      />
    </Categories>
  </Col>
);
