import React from "react";
export const Human = () => (
  <svg id="Layer_1" version="1.1" viewBox="0 0 11 16" x="0" y="0" xmlSpace="preserve">
    <path className="st0" d="M5.3 3.7c.7 0 1.3-.6 1.3-1.3C6.6 1.6 6 1 5.3 1c-.8 0-1.4.6-1.4 1.3 0 .8.6 1.4 1.4 1.4z" />
    <path
      className="st0"
      d="M7.9 5.1c-.3-.3-.7-.8-1.6-.8H4.6c-1.6 0-3-1.2-3.3-2.8 0-.3-.3-.5-.6-.5-.4 0-.8.4-.7.8C.3 3.5 1.6 5 3.3 5.5v8.2c0 .4.3.7.7.7s.7-.3.7-.7v-3.3H6v3.3c0 .4.3.7.7.7.4 0 .7-.3.7-.7V6.4l2.2 2.2c.3.3.7.3.9 0 .3-.3.3-.7 0-.9L7.9 5.1z"
    />
  </svg>
);
