import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { mdiHelpCircle, mdiInformation } from "@mdi/js";
import { Field, FieldProps, Formik } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { useReducer, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import { filesReducer, FileStatus } from "src/App/Attachments/reducer";
import { DOCUMENTS_LIST_GET, getSourceName } from "src/App/KB";
import { AttachmentList } from "src/components/Attachments";
import {
  DocumentCreateUpdate,
  DocumentCreateUpdateVariables
} from "src/App/KB/CreateUpdate/typings/DocumentCreateUpdate";
import {
  DocumentSetTargetingAndWorkflow,
  DocumentSetTargetingAndWorkflowVariables
} from "src/App/KB/CreateUpdate/typings/DocumentSetTargetingAndWorkflow";
import { GetHrisFilterValues } from "src/App/KB/CreateUpdate/typings/GetHrisFilterValues";
import { FeatureFlags, useFeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import {
  AbsoluteTooltip,
  CancelButton,
  Col,
  FormikFieldGroup,
  FormikFieldInputs,
  InfoBanner,
  Input,
  LoadingBar,
  MaterialIcon,
  Row,
  SubmitButton
} from "src/components";
import { FormikMultiSelect } from "src/components/Fields/MultiSelect";
import { SingleSelect } from "src/components/Fields/Select";
import { Icon } from "src/components/Icon";
import { RichEditor, richEditorInputStyles } from "src/components/RichEditor/Editor";
import { Tabs } from "src/components/Tabs";
import { ExternalDocumentSourceKind } from "src/globalTypes";
import { WarnBeforeUnload } from "src/hooks/useWindowEvents";
import { Typo } from "src/styling/primitives/typography";
import zIndices from "src/styling/tokens/z-indices.json";
import { HelpCircle } from "src/svg/icons/HelpCircle";
import { backMarkdownToHtml } from "src/util/formatters";
import { htmlToBackMarkdown } from "src/util/formatters/mrkdwn";
import * as yup from "yup";
import { IDocumentCreateUpdateProps } from "./Container";
import { IntegrationHRIS } from "./typings/IntegrationHRIS";
import { KBWorkflows, KBWorkflows_teamList_workflows } from "./typings/KBWorkflows";

const Form = styled.form`
  & .heading {
    border: 1px solid var(--border);
  }

  & .close {
    color: var(--border);
  }
`;

/**
 * Validation rules
 */
const FormSchema = yup.object().shape({
  title: yup.string().trim().required("Title is required"),
  content: yup.string().trim().required("Content is required")
});
const ExternalDocFormSchema = yup.object().shape({
  title: yup.string(),
  content: yup.string()
});

/**
 * Form component for creating and editing documents
 */
export const DocumentCreateUpdateForm: React.ComponentType<
  IDocumentCreateUpdateProps & {
    submit: string;
  }
> = props => {
  const { emitSnack } = useSnack();

  const { hasFeatureFlags } = useFeatureFlags();
  const [filesState, filesDispatch] = useReducer(filesReducer, { files: [] });
  const [removedDocAttachmentIds, setRemovedDocAttachmentIds] = useState<string[]>([]);
  const integrationHRIS = useQuery<IntegrationHRIS>(gql`
    query IntegrationHRIS {
      integrationHRIS {
        kind
      }
    }
  `);
  const hasIntegrationHRIS = !!integrationHRIS.data?.integrationHRIS?.kind;
  const hrisFilterValuesResponse = useQuery<GetHrisFilterValues>(
    gql`
      query GetHrisFilterValues {
        hrisFilterValues {
          officesList
          departmentsList
        }
      }
    `,
    { skip: !hasIntegrationHRIS }
  );

  const offices = (hrisFilterValuesResponse.data?.hrisFilterValues?.officesList as string[]) ?? [];
  const departments = (hrisFilterValuesResponse.data?.hrisFilterValues?.departmentsList as string[]) ?? [];

  const [documentCreateUpdate] = useMutation<DocumentCreateUpdate, DocumentCreateUpdateVariables>(
    gql`
      mutation DocumentCreateUpdate($documentCreate: InDocument, $documentUpdate: UpDocument) {
        documentCreateUpdate(documentUpdate: $documentUpdate, documentCreate: $documentCreate) {
          code
          success
          message
        }
      }
    `,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: DOCUMENTS_LIST_GET
        }
      ]
    }
  );
  const [documentSetTargetingAndWorkflow] = useMutation<
    DocumentSetTargetingAndWorkflow,
    DocumentSetTargetingAndWorkflowVariables
  >(
    gql`
      mutation DocumentSetTargetingAndWorkflow(
        $targetingParams: DocumentSetTargetingParams!
        $workflowParams: DocumentSetWorkflowParams!
      ) {
        documentSetTargeting(params: $targetingParams) {
          code
          success
          message
        }
        documentSetWorkflow(params: $workflowParams) {
          code
          success
          message
        }
      }
    `,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: DOCUMENTS_LIST_GET
        }
      ]
    }
  );

  const document = props.document;
  const isExternalDoc = props.document && !!props.document.external;
  const sourceName = getSourceName(
    props.document?.external?.source.kind ?? ExternalDocumentSourceKind.EXTERNAL_SOURCE_KIND_UNSPECIFIED
  );
  const tooltipText = `This document can be edited in ${!!sourceName ? sourceName : "your external source"}.`;

  const currentDocAttachments = document?.attachments.filter(d => !removedDocAttachmentIds.includes(d.id));
  const isFilesUploading = filesState.files.some(f => f.status !== FileStatus.UPLOADED);
  const didAttachmentsChange = filesState.files.length > 0 || removedDocAttachmentIds.length > 0;
  return (
    <Formik
      enableReinitialize
      isInitialValid={
        (isExternalDoc ? ExternalDocFormSchema : FormSchema).isValidSync(document) && didAttachmentsChange
      }
      onSubmit={async payload => {
        const documentCreateUpdateResponse =
          !isExternalDoc &&
          (await documentCreateUpdate({
            variables: {
              documentCreate: !document?.id
                ? {
                    title: payload.title,
                    content: payload.content,
                    commentId: props.commentId,
                    attachmentIds: filesState.files.filter(f => !!f.attachmentId).map(f => f.attachmentId as string)
                  }
                : null,
              documentUpdate:
                document && document.id
                  ? {
                      id: document.id,
                      title: payload.title,
                      content: payload.content,
                      revision: document.revision,
                      attachmentIds: [
                        ...(currentDocAttachments?.map(a => a.id) ?? []),
                        ...filesState.files.filter(f => !!f.attachmentId).map(f => f.attachmentId as string)
                      ]
                    }
                  : null
            }
          }));
        const targetingAndWorkflowResponse =
          document &&
          (await documentSetTargetingAndWorkflow({
            variables: {
              targetingParams: {
                documentId: document.id,
                documentSource: document.source,
                targeting: {
                  officesList: (payload.office ?? []).map(o => o?.value) as string[],
                  departmentsList: (payload.department ?? []).map(d => d?.value) as string[],
                  isManager: payload.isManager ?? false,
                  allowExternals: payload.allowExternals ?? false
                }
              },
              workflowParams: {
                documentId: document.id,
                documentSource: document.source,
                workflowId: payload.workflowId
              }
            }
          }));
        if (
          targetingAndWorkflowResponse &&
          (!targetingAndWorkflowResponse.data?.documentSetTargeting.success ||
            !targetingAndWorkflowResponse.data?.documentSetWorkflow.success)
        ) {
          // doc setTargetingAndWorflow err
          const errorMessage = !targetingAndWorkflowResponse.data?.documentSetTargeting.success
            ? targetingAndWorkflowResponse.data?.documentSetTargeting.message
            : targetingAndWorkflowResponse.data?.documentSetWorkflow.message;
          emitSnack({
            message: errorMessage ?? `There was a problem updating answer "${payload.title}".`,
            type: "mutationError"
          });
        } else if (documentCreateUpdateResponse && !documentCreateUpdateResponse.data?.documentCreateUpdate.success) {
          // doc create/update err
          emitSnack({
            message:
              documentCreateUpdateResponse.data?.documentCreateUpdate.message ??
              `There was a problem updating answer "${payload.title}".`,
            type: "mutationError"
          });
        } else {
          // Success!
          emitSnack({ message: `Answer "${payload.title}" saved.`, type: "info" });
          props.onSuccess();
        }
      }}
      initialValues={
        hasIntegrationHRIS
          ? {
              title: !props.document ? "" : props.document.title || "Untitled document",
              content: props.document?.content ?? props.text ?? "",
              workflowId: props.document?.workflowId,
              office:
                props.document?.hrisFilter?.officesList.map(o => ({
                  value: o,
                  label: o
                })) ?? [],
              department:
                props.document?.hrisFilter?.departmentsList.map(d => ({
                  value: d,
                  label: d
                })) ?? [],
              isManager: props.document?.hrisFilter?.isManager ?? false,
              allowExternals: props.document?.hrisFilter?.allowExternals ?? false
            }
          : {
              title: props.document?.title ?? "",
              content: props.document?.content ?? props.text ?? "",
              workflowId: props.document?.workflowId
            }
      }
      validationSchema={isExternalDoc ? ExternalDocFormSchema : FormSchema}
      render={form => (
        <Form onSubmit={form.handleSubmit}>
          <WarnBeforeUnload hasChanges={Object.values(form.touched).some(v => v)} />
          <Col
            css={css`
              & > * + * {
                margin-top: var(--space-2-px);
              }
            `}
          >
            <FormikFieldGroup.Container legend="Title">
              <Field name="title">
                {(formikFieldProps: FieldProps) => {
                  const input = (
                    <Input
                      fullWidth
                      hasError={!!form.touched.title && !!form.errors.title}
                      {...formikFieldProps.field}
                      disabled={isExternalDoc}
                    />
                  );
                  return isExternalDoc ? (
                    <div
                      css={css`
                        & > * {
                          /* I hate this  */
                          display: block !important;
                        }
                      `}
                    >
                      <AbsoluteTooltip content={tooltipText}>{input}</AbsoluteTooltip>
                    </div>
                  ) : (
                    input
                  );
                }}
              </Field>
              <FormikFieldGroup.Errors>{form.touched.title ? form.errors.title ?? "" : ""}</FormikFieldGroup.Errors>
              <FormikFieldGroup.HelpText
                css={css`
                  margin-bottom: var(--space-4-rem);
                `}
              >
                This answer will be suggested to employees when they create ask a question containing similar words with
                the title and the content. We need some time to index answers, please wait a couple of minutes before
                testing the suggestion.
              </FormikFieldGroup.HelpText>
            </FormikFieldGroup.Container>
            <Tabs
              css={[
                css`
                  z-index: ${zIndices.low.value};
                `
              ]}
              transitionHeight
              items={[
                {
                  headline: "Content",
                  dataIntercomTarget: "kb-content",
                  content: (
                    <>
                      {!isExternalDoc ? (
                        <div css={richEditorInputStyles}>
                          <RichEditor
                            data-intercom-target="knowledge-rich-editor"
                            initialValue={backMarkdownToHtml(form.initialValues.content)}
                            onChange={html => {
                              form.setFieldValue("content", htmlToBackMarkdown(html));
                              form.setFieldTouched("content");
                            }}
                            hasError={form.touched.content && !!form.errors.content}
                            minLines={4}
                          />
                        </div>
                      ) : (
                        props.document &&
                        props.document.url && (
                          <AbsoluteTooltip content={tooltipText}>
                            <Typo.ExternalLink href={props.document.url} rel="noopener noreferrer" target="_blank">
                              {props.document.url}
                            </Typo.ExternalLink>
                          </AbsoluteTooltip>
                        )
                      )}

                      <FormikFieldGroup.Errors>
                        {form.touched.content && !!form.errors.content && form.errors.content}
                      </FormikFieldGroup.Errors>
                    </>
                  )
                },
                {
                  shouldNotDisplay: isExternalDoc,
                  headline: "Attachments",
                  dataIntercomTarget: "kb-attachments",
                  content: (
                    <div
                      css={css`
                        border: 1px solid #d9deea;
                        padding: var(--space-3-rem) var(--space-4-rem);
                        border-radius: var(--border-radius-s);
                      `}
                    >
                      <AttachmentList
                        filesState={filesState}
                        filesDispatch={filesDispatch}
                        attachments={currentDocAttachments ?? []}
                        handleAttachmentRemove={id => {
                          setRemovedDocAttachmentIds(a => [...a, id]);
                        }}
                      />
                    </div>
                  )
                },
                {
                  headline: "Targeting",
                  dataIntercomTarget: "kb-targeting",
                  content: (
                    <>
                      {!hasIntegrationHRIS && (
                        <InfoBanner
                          blue
                          css={css`
                            margin-bottom: var(--space-3-rem);
                          `}
                        >
                          <MaterialIcon path={mdiInformation} size={1.125} />
                          <Typo.Body>
                            You need to connect an HR system on the Integrations page in order to be able to set up
                            targeting for answers.
                          </Typo.Body>
                        </InfoBanner>
                      )}
                      <FormikFieldGroup.Container legend="Office">
                        <Field name="office">
                          {(formikFieldProps: FieldProps) => (
                            <>
                              <FormikMultiSelect
                                {...formikFieldProps}
                                placeHolder="This article will be suggested to employees in all offices"
                                options={offices.map(o => ({
                                  value: o,
                                  label: o
                                }))}
                                isCreatable={false}
                                isClearable={true}
                                disabled={!hasIntegrationHRIS}
                              />
                            </>
                          )}
                        </Field>
                      </FormikFieldGroup.Container>
                      <FormikFieldGroup.Container legend="Department">
                        <Field name="department">
                          {(formikFieldProps: FieldProps) => (
                            <>
                              <FormikMultiSelect
                                {...formikFieldProps}
                                placeHolder="This article will be suggested to employees in all departments"
                                options={departments.map(d => ({
                                  value: d,
                                  label: d
                                }))}
                                isCreatable={false}
                                isClearable={true}
                                disabled={!hasIntegrationHRIS}
                              />
                            </>
                          )}
                        </Field>
                      </FormikFieldGroup.Container>
                      <div>
                        <div
                          css={[
                            css`
                              display: flex;
                              justify-content: space-between;
                              margin-bottom: var(--space-2-rem);
                            `
                          ]}
                        >
                          <Typo.Body bold>User properties</Typo.Body>
                          <AbsoluteTooltip
                            content={
                              <>
                                Managers are any employee that is listed as a supervisor to one or more employees in
                                your HR system. <br />
                                External users are all users who do not have a profile in your HR system.
                              </>
                            }
                          >
                            <Typo.Body sizeS>
                              <Typo.TextLink
                                light
                                css={css`
                                  display: flex;
                                  cursor: default;
                                `}
                              >
                                <Icon
                                  sizeS
                                  css={css`
                                    margin-right: var(--space-1-rem);
                                  `}
                                >
                                  <HelpCircle />
                                </Icon>
                                Identifying managers and external users
                              </Typo.TextLink>
                            </Typo.Body>
                          </AbsoluteTooltip>
                        </div>
                        <FormikFieldInputs.Checkbox
                          name="isManager"
                          disabled={!hasIntegrationHRIS}
                          label={
                            <>
                              Only suggest this article to <b>managers</b>
                            </>
                          }
                        />
                        <FormikFieldInputs.Checkbox
                          name="allowExternals"
                          disabled={!hasIntegrationHRIS}
                          label={
                            <>
                              Allow suggesting this article to <b>external users</b>
                            </>
                          }
                        />
                      </div>
                    </>
                  )
                },
                {
                  shouldNotDisplay: !hasFeatureFlags(FeatureFlags.WORKFLOWS),
                  headline: "Workflows",
                  dataIntercomTarget: "workflows",
                  content: (
                    <SelectKBWorkflow
                      mode="edit"
                      value={form.values.workflowId ?? null}
                      onChange={id => form.setFieldValue("workflowId", id)}
                    />
                  )
                }
              ]}
            />

            <Row
              css={[
                css`
                  margin-top: var(--space-4-rem);
                  justify-content: flex-end;
                  z-index: ${zIndices.lower.value};
                `
              ]}
            >
              {!form.isSubmitting && (
                <>
                  <CancelButton onClick={props.onCancel} />
                  <SubmitButton disabled={!form.isValid || form.isSubmitting || isFilesUploading}>
                    {props.submit}
                  </SubmitButton>
                </>
              )}
              {form.isSubmitting && <LoadingBar />}
            </Row>
          </Col>
        </Form>
      )}
    />
  );
};

interface SelectKBViewProps {
  value: string | null;
  mode: "view";
}

interface SelectKBEditProps {
  value: SelectKBViewProps["value"];
  onChange: (id: SelectKBViewProps["value"]) => void;
  mode: "edit";
}

// Select kb workflow
// Separate component to load data
export function SelectKBWorkflow(props: SelectKBViewProps | SelectKBEditProps) {
  const workflowsResponse = useQuery<KBWorkflows>(
    gql`
      query KBWorkflows {
        teamList {
          id
          workflows {
            id
            name
            triggers {
              trigger {
                ... on WorkflowTriggerKnowledgeAnswer {
                  __typename
                }
              }
            }
          }
        }
      }
    `
  );
  const workflows =
    workflowsResponse.data?.teamList.reduce(
      (a, c) =>
        a.concat(c.workflows ?? []).filter(w => {
          const hasWorkflowTrigger = w.triggers.some(
            triggerMap => triggerMap.trigger.__typename === "WorkflowTriggerKnowledgeAnswer"
          );
          return hasWorkflowTrigger;
        }),
      [] as KBWorkflows_teamList_workflows[]
    ) ?? [];
  const options = workflows.map(workflow => ({
    label: workflow.name,
    value: workflow.id
  }));
  const selectedOption = options.find(w => w.value === props.value) ?? undefined;
  return (
    <FormikFieldGroup.Container
      legend={
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <span>Connected workflow</span>
          <AbsoluteTooltip
            css={css`
              color: var(--text-4);
            `}
            children={
              <Typo.Body
                light
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <MaterialIcon path={mdiHelpCircle} size={1} zIndex="highest" />
                &nbsp;Associating workflows to answers
              </Typo.Body>
            }
            content={
              <>
                <p>
                  This workflow will be available to employees in Slack, MS Teams, or Google Chat whenever this answer
                  is automatically suggested by Back. Employees will be able to start it directly from there.
                </p>
                <p
                  css={css`
                    margin-top: var(--space-2-rem);
                  `}
                >
                  It will not be available after manually attaching this answer to a reply.
                </p>
              </>
            }
          />
        </div>
      }
    >
      {props.mode === "view" && (
        <Typo.Body>
          {selectedOption?.label ?? `No workflow connected. Click "Edit answer" to connect a workflow to this document`}
        </Typo.Body>
      )}
      {props.mode === "edit" && (
        <SingleSelect
          isClearable
          value={selectedOption ?? null}
          name="workflows"
          placeholder="Select..."
          noOptionsMessage={() => "No suitable workflows - let us know which workflows we should enable for you"}
          isSearchable={false}
          options={options}
          onChange={data => {
            if (data && !("length" in data)) {
              props.onChange(data.value);
            }
          }}
          onRemove={() => props.onChange(null)}
        />
      )}
    </FormikFieldGroup.Container>
  );
}
