import { css } from "@emotion/core";
import { mdiClose } from "@mdi/js";
import * as msteams from "@microsoft/teams-js";
import gql from "graphql-tag";
import * as React from "react";
import { useEffect } from "react";
import { useMutation } from "react-apollo";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import {
  IntegrationMSTeamsDeleteTeamAssociation,
  IntegrationMSTeamsDeleteTeamAssociationVariables
} from "src/App/Settings/Integrations/MSTeams/typings/IntegrationMSTeamsDeleteTeamAssociation";
import { INTEGRATIONS_LIST_GET } from "src/App/Settings/Integrations/Overview";
import { IntegrationsListGet_integrationMSTeams } from "src/App/Settings/Integrations/typings/IntegrationsListGet";
import { Button, Col, Dialog, LoadingBar, MaterialIcon, Row } from "src/components";
import { grpc } from "src/grpc";
import { Modal } from "src/portals/Modal";
import { Typo } from "src/styling/primitives/typography";
import { IntegrationAddMSTeams, IntegrationAddMSTeamsVariables } from "./typings/IntegrationAddMSTeams";

// This needs to be called once before any msteams function is called.
msteams.initialize();

const INTEGRATION_ADD_MSTEAMS = gql`
  mutation IntegrationAddMSTeams($integration: CreateIntegrationMSTeams!) {
    integrationAddMSTeams(integration: $integration) {
      code
      message
      success
    }
  }
`;

const INTEGRATION_MSTEAMS_DELETE_TEAM_ASSOCATION = gql`
  mutation IntegrationMSTeamsDeleteTeamAssociation($params: MSTeamsDeleteTeamAssociation!) {
    integrationMSTeamsDeleteTeamAssociation(params: $params) {
      code
      message
      success
    }
  }
`;

interface MSTeamsInstallModalProps {
  onDismiss: () => void;
  onSuccess: () => void;
  isOpen: boolean;
}

export const MSTeamsInstallModal = (props: MSTeamsInstallModalProps) => {
  const { emitSnack } = useSnack();

  // The association_state URL query parameter is filled by MS Teams during the
  // association flow: MS Teams opens Back inside a popup window, passing the
  // association state in the URL. This modal then calls back the backend,
  // confirms the association with Teams, and closes the window.
  const associationState = new URLSearchParams(window.location.search).get("association_state") || "";
  const [addMSTeams, response] = useMutation<IntegrationAddMSTeams, IntegrationAddMSTeamsVariables>(
    INTEGRATION_ADD_MSTEAMS,
    {
      onCompleted: data => {
        // if unauthenticated allow normal redirect to login
        if (data.integrationAddMSTeams.code === grpc.status.UNAUTHENTICATED) return;
        if (data.integrationAddMSTeams.success) {
          msteams.authentication.notifySuccess();
          props.onSuccess();

          // This is a horrible hack, because for some reason the code in the
          // MS Teams SDK does not manage to close the window after 200ms as it
          // should.
          //
          // You can check the code in node_modules/@microsoft/teams-js/dist/MicrosoftTeams.js
          // to see what notifySuccess tries to do.
          setTimeout(() => window.close(), 200);
        } else if (data.integrationAddMSTeams.success === false) {
          msteams.authentication.notifyFailure();
          emitSnack({
            type: "mutationError",
            message: data.integrationAddMSTeams.message
          });
        }
      },
      refetchQueries: [{ query: INTEGRATIONS_LIST_GET }]
    }
  );

  useEffect(() => {
    if (associationState && !response.called) {
      addMSTeams({
        variables: { integration: { associationState } }
      });
    }
  }, [associationState, response, addMSTeams]);

  return (
    <Modal isOpen={props.isOpen} onDismiss={props.onDismiss}>
      <Dialog title="Connect MS Teams" onClose={props.onDismiss} medium>
        {response.loading && (
          <>
            <LoadingBar />
            <div>Adding MS teams...</div>
          </>
        )}
        {!associationState && (
          <div>No association state in URL. Check the documentation to see how to link Back to MS Teams.</div>
        )}
        {response.data && !response.loading && (
          <div>
            All set! This window should close automatically, but if it doesn't feel free to close it and return to MS
            Teams.
          </div>
        )}
      </Dialog>
    </Modal>
  );
};

interface MSTeamsInfoModalProps {
  onDismiss: () => void;
  isOpen: boolean;
  integration: IntegrationsListGet_integrationMSTeams | null;
}

export const MSTeamsInfoModal = (props: MSTeamsInfoModalProps) => {
  const { emitSnack } = useSnack();
  const [deleteAssociation, deleteAssociationResponse] = useMutation<
    IntegrationMSTeamsDeleteTeamAssociation,
    IntegrationMSTeamsDeleteTeamAssociationVariables
  >(INTEGRATION_MSTEAMS_DELETE_TEAM_ASSOCATION, {
    onCompleted: data => {
      if (data.integrationMSTeamsDeleteTeamAssociation.success) {
        emitSnack({
          type: "info",
          message: "Team successfully unlinked from Back"
        });
      } else if (data.integrationMSTeamsDeleteTeamAssociation.success === false) {
        emitSnack({
          type: "mutationError",
          message: data.integrationMSTeamsDeleteTeamAssociation.message
        });
      }
    },
    awaitRefetchQueries: true,
    refetchQueries: [{ query: INTEGRATIONS_LIST_GET }]
  });

  const disassociationState = new URLSearchParams(window.location.search).get("disassociation_state") || "";

  useEffect(() => {
    if (disassociationState && !deleteAssociationResponse.called) {
      deleteAssociation({
        variables: {
          params: {
            disassociationState: disassociationState
          }
        }
      });
    }
  }, [disassociationState, deleteAssociationResponse, deleteAssociation]);

  return (
    <Modal isOpen={props.isOpen} onDismiss={props.onDismiss}>
      <Dialog title="Connect MS Teams" onClose={props.onDismiss} medium>
        {!!props.integration?.teamsList?.length ? (
          <Col>
            <Typo.Body
              css={css`
                margin-bottom: var(--space-2-rem);
              `}
            >
              You have currently {props.integration?.teamsList?.length} teams connected to Back. Users in any of the
              teams can submit requests to Back on one of the channels or by sending a message to Back directly.
            </Typo.Body>
            <Typo.Body
              css={css`
                margin-bottom: var(--space-6-rem);
              `}
            >
              When you unlink a team from Back users will not be able to submit requests through that team until it is
              linked again.
            </Typo.Body>
            {props.integration.teamsList.map(t => (
              <Row
                css={css`
                  margin-bottom: var(--space-2-rem);
                `}
              >
                <Typo.Body medium italic={!t.name}>
                  {t.name || "Unknown"}
                </Typo.Body>
                <Row flex="1 0 auto" align="center" justify="flex-end">
                  <Button
                    variant="red"
                    size="medium"
                    onClick={() => {
                      if (
                        window.confirm(
                          `Are you sure you want to unlink the team ${t.name}? Employees will not be able to interact with Back in this teams's channels until it is reassociated to Back.`
                        )
                      ) {
                        deleteAssociation({
                          variables: {
                            params: {
                              teamId: t.teamId
                            }
                          }
                        });
                      }
                    }}
                  >
                    <MaterialIcon path={mdiClose} size={1} />
                    Unlink
                  </Button>
                </Row>
              </Row>
            ))}
          </Col>
        ) : (
          <>
            <Typo.Body>
              You have currently no teams connected to Back. To connect a team in MS Teams you need to first add the
              Back application to the teams and complete the association process to ensure we can create a connection
              between Teams and Back.
            </Typo.Body>
            <Typo.Body
              css={css`
                margin-top: var(--space-2-rem);
              `}
            >
              For more information check out{" "}
              <Typo.ExternalLink href="https://support.back.ee/en/articles/4176643-installing-the-back-app-for-ms-teams">
                the documentation
              </Typo.ExternalLink>{" "}
              or get in touch through the support button in the lower right corner!
            </Typo.Body>
          </>
        )}
      </Dialog>
    </Modal>
  );
};
