import { SavedReplyList_savedReplyList } from "src/App/SavedReplies/typings/SavedReplyList";
import { trackSavedReplyUsed } from "src/util/analytics";

export interface SavedReplyState {
  showSavedReplyModal: boolean;
  savedReply: SavedReplyList_savedReplyList | null;
}

export const initialSavedReplyState: SavedReplyState = {
  savedReply: null,
  showSavedReplyModal: false
};

export type SavedReplyAction =
  | {
      type: "SELECT_SAVED_REPLY";
      savedReply: SavedReplyList_savedReplyList;
      channel: "request" | "outreach";
      requestId?: string;
    }
  | {
      type: "CLEAR_SAVED_REPLY";
    }
  | {
      type: "TOGGLE_SAVED_REPLY_MODAL";
      showSavedReplyModal: boolean;
    };

export function savedRepliesReducer(state: SavedReplyState, action: SavedReplyAction): SavedReplyState {
  switch (action.type) {
    case "SELECT_SAVED_REPLY":
      trackSavedReplyUsed(action.savedReply, action.channel, action.requestId);
      return {
        ...state,
        savedReply: action.savedReply
      };
    case "CLEAR_SAVED_REPLY":
      return {
        ...state,
        savedReply: null
      };
    case "TOGGLE_SAVED_REPLY_MODAL":
      return {
        ...state,
        showSavedReplyModal: action.showSavedReplyModal
      };
  }
}
