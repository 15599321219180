import * as React from "react";

import { FC, ReactNode } from "react";

import { EditorState } from "prosemirror-state";
import Loadable from "react-loadable";
import { css } from "@emotion/core";

export type toolbarRenderer = (appendText: (text: string) => void) => React.ReactNode;

export interface RichEditorProps {
  onChange: (html: string, state: EditorState) => void;
  /** html */
  initialValue?: string;
  topBanner?: ReactNode;
  leftToolbar?: toolbarRenderer;
  rightToolbar?: toolbarRenderer;
  hasMentions?: boolean;
  minLines?: number;
  maxLines?: number;
  appendTextFnRef?: React.MutableRefObject<((text: string) => void) | undefined>;
}

/**
 * Rich editor loading element
 */
const EditorLoading: FC = () => (
  <div
    css={[
      css`
        cursor: not-allowed;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        min-height: 100%;
        border-radius: var(--border-radius-s);
        animation-duration: 3s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: linear-gradient(to right, var(--white) 25%, var(--lightGrey-1) 50%, var(--white) 75%);

        @keyframes placeHolderShimmer {
          0% {
            background-position: -1000px 0;
          }
          100% {
            background-position: 1000px 0;
          }
        }
      `
    ]}
  />
);

// load tiptap deps as separate bundle
const AsyncRichEditor = Loadable({
  loader: () =>
    import("src/components/RichEditor/Tiptap").then(m => Promise.resolve(m.Tiptap)) as Promise<
      React.FC<RichEditorProps>
    >,
  loading: () => <EditorLoading />
});

export const richEditorInputStyles = css`
  background-color: var(--white);
  border-radius: var(--border-radius-s);
  box-sizing: border-box;
  border: 1px solid var(--text-1);
  &:focus-within {
    border: 1px solid var(--text-6);
  }
`;

/**
 * Rich md editor
 */
export const RichEditor: FC<
  RichEditorProps & {
    hasError?: boolean;
  }
> = props => {
  return (
    <div
      css={[
        css`
          position: relative;
          height: auto;
          color: var(--text-6);
        `,
        props.hasError &&
          css`
            &:focus-within {
              border: 1px solid var(--red-7);
            }
          `,
        props.minLines &&
          css`
            /* 48px TOOLBAR HEIGHT + 16px PADDING TOP & BOTTOM */
            min-height: calc(64px + (var(--line-height-normal) * ${props.minLines}rem));
          `
      ]}
    >
      <AsyncRichEditor
        initialValue={props.initialValue}
        topBanner={props.topBanner}
        leftToolbar={props.leftToolbar}
        rightToolbar={props.rightToolbar}
        hasMentions={props.hasMentions}
        minLines={props.minLines}
        maxLines={props.maxLines}
        onChange={props.onChange}
        appendTextFnRef={props.appendTextFnRef}
      />
    </div>
  );
};
