import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { mdiAccountMultiple } from "@mdi/js";
import { PureQueryOptions } from "apollo-client";
import { Field, Formik } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { useMutation } from "react-apollo";
import { ValueType } from "react-select/src/types";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { FeatureFlags, FEATURE_FLAGS, useFeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { UserAvatar } from "src/App/User";
import { Button, CancelButton, FormikInput, Row, TextAreaField } from "src/components";
import { AsyncSingleSelectWithIcon, SingleSelectWithIcon, SmallSelectText } from "src/components/Fields/Select";
import * as yup from "yup";
import { getUserList } from "../../User/getUserList";
import { RequestCreate, RequestCreateVariables } from "./typings/RequestCreate";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
`;

const CustomField = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: ${p => (p.fullWidth ? "100%" : "unset")};
  width: ${p => (p.fullWidth ? "100%" : "50%")};
  align-items: flex-start;
  box-sizing: border-box;

  & .select-container {
    width: 100%;
  }
`;

export interface ISelectOption {
  label?: string;
  value?: string;
}

const validationSchema = yup.object().shape({
  description: yup.string(),
  requesterId: yup.string().required("Requester is a required field"),
  teamId: yup.string().required("Team is a required field"),
  projectId: yup.string(),
  title: yup.string().required("Title is a required field")
});

export const RequestForm: React.FC<{
  onCreate(): void;
  onCancel(): void;
  projectId?: string;
  queriesToRefetch?: Array<string | PureQueryOptions>;
}> = props => {
  const { emitSnack } = useSnack();
  const { hasFeatureFlags } = useFeatureFlags();

  const { currentUser } = useCurrentUser();
  const [placeholder, setPlaceholder] = React.useState("");
  const [requestCreate] = useMutation<RequestCreate, RequestCreateVariables>(gql`
    mutation RequestCreate($params: RequestParameters!) {
      requestCreate(params: $params) {
        code
        success
        message
      }
    }
  `);

  const listInternalOnly = hasFeatureFlags(FeatureFlags.LISTINTERNALONLY);

  return (
    <Wrapper data-testid="new-request-form">
      <Formik
        initialValues={{
          description: "",
          title: "",
          requesterId: "",
          teamId: "",
          projectId: props.projectId
        }}
        validationSchema={validationSchema}
        onSubmit={async (form, bag) => {
          const res = await requestCreate({
            variables: {
              params: form
            }
          });
          if (!res.data?.requestCreate.success) {
            emitSnack({
              type: "mutationError",
              message: "Sorry, there was an error creating your request"
            });
            bag.setSubmitting(false);
          } else if (res.data.requestCreate.success) {
            emitSnack({
              type: "info",
              message: `Request created "${form.title}"`
            });
            props.onCreate();
          }
        }}
      >
        {form => (
          <Form>
            <CustomField style={{ paddingRight: "1rem" }}>
              <AsyncSingleSelectWithIcon<ISelectOption>
                name="requesterId"
                label="Choose the requester"
                loadOptions={(value: string) =>
                  getUserList(value, false, listInternalOnly).then(userList =>
                    Promise.resolve(
                      userList.users.map(user => ({
                        label: user.name ? (
                          <>
                            {user.name} <SmallSelectText>({user.email})</SmallSelectText>
                          </>
                        ) : (
                          user.email
                        ),
                        value: user.id
                      }))
                    )
                  )
                }
                onChange={(data: ValueType<ISelectOption>) => {
                  if (data && !("length" in data)) {
                    form.setFieldValue("requesterId", data.value);
                  }
                }}
                onFocus={() => setPlaceholder("Start typing to search")}
                onBlur={() => setPlaceholder("")}
                placeholder={placeholder}
                errorMessage={form.submitCount || form.touched.requesterId ? form.errors.requesterId : undefined}
                getIcon={value => (
                  <UserAvatar
                    sizeXS
                    userId={value}
                    css={css`
                      margin: 0 0.5rem;
                    `}
                  />
                )}
                data-testid="input-requester"
                className="input-requester"
              />
            </CustomField>
            <CustomField style={{ paddingLeft: "1rem" }}>
              <SingleSelectWithIcon<ISelectOption>
                name="teamId"
                label="Assign a team"
                onChange={(data: ValueType<ISelectOption>) => {
                  if (data && !("length" in data)) {
                    form.setFieldValue("teamId", data.value);
                  }
                }}
                errorMessage={form.submitCount || form.touched.teamId ? form.errors.teamId : undefined}
                placeholder=""
                icon={mdiAccountMultiple}
                data-testid="input-team"
                className="input-team"
                options={currentUser?.teams.map(team => ({
                  label: team.name,
                  value: team.id
                }))}
              />
            </CustomField>
            <CustomField fullWidth={true}>
              <Field
                name="title"
                component={FormikInput}
                label="Title"
                placeholder="Enter the request title"
                data-testid="input-title"
                fullWidth={true}
              />
            </CustomField>
            <CustomField fullWidth={true}>
              <Field
                name="description"
                component={TextAreaField}
                label="Description"
                placeholder="Enter the request description"
                data-testid="input-description"
                fullWidth={true}
                height="10rem"
              />
            </CustomField>
            <Row fullWidth={true} justify="flex-end">
              <CancelButton onClick={props.onCancel} />
              <Button
                size="large"
                variant="primary"
                disabled={!form.isValid || form.isSubmitting}
                onClick={e => {
                  // submit manually so we can test w jsdom
                  e.preventDefault();
                  form.handleSubmit();
                }}
              >
                Create request
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};
