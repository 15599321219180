import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { EditorState } from "draft-js";
import createMentionPlugin from "draft-js-mention-plugin";
import "draft-js-mention-plugin/lib/plugin.css";
import "draft-js/dist/Draft.css";
import React, { MouseEventHandler, useEffect, useMemo, useRef, useState } from "react";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { getUserList } from "src/App/User/getUserList";
import {
  GAutocompleteUserList,
  GAutocompleteUserList_userList2_users
} from "src/App/User/typings/GAutocompleteUserList";
import { UserAvatar } from "src/App/User/UserAvatar";
import { ConfirmDialogModal, EllipsisText, Row, Text, TooltipLabel } from "src/components";
import { PopOver } from "src/components/PopOver";
import { TeamInfo } from "src/fragments/typings/TeamInfo";
import { TeamPrivacy } from "src/globalTypes";
import { Typo } from "src/styling/primitives/typography";
import { trackIllegalPrivateTeamMention } from "src/util/analytics";
import { csx } from "src/util/csx";
import { initialsOf } from "src/util/formatters";
import { RequestGet_request } from "../typings/RequestGet";

const entryItem = css`
  display: flex;
  align-items: center;
  padding: var(--space-1-rem) var(--space-2-rem);
  color: var(--text-6);
  background: var(--white);
`;

export const EntryItem = csx([entryItem], {
  invalid: css`
    color: var(--text-4);
  `,
  focused: css`
    background: var(--hover);
  `
});

const TeamSquare = styled.div`
  border-radius: 0.125rem;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  font-size: 0.625rem;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 0.125rem;
  color: var(--white);
  background: var(--text-6);
`;

export function useMentionTriggerPlugin(options: {
  mentionTrigger: string;
  mentionPrefix: string;
  mentionClassName?: string;
  suggestionRegex?: string;
  entityMutability?: "IMMUTABLE" | "SEGMENTED" | "MUTABLE";
}) {
  return useMemo(
    () =>
      createMentionPlugin({
        entityMutability: options.entityMutability || "MUTABLE",
        mentionTrigger: options.mentionTrigger,
        mentionPrefix: options.mentionPrefix,
        mentionRegExp: options.suggestionRegex,
        theme: {
          mention: options.mentionClassName || "draftJsMentionPlugin__mention__29BEd",
          mentionSuggestions: "draftJsMentionPlugin__mentionSuggestions__2DWjA",
          mentionSuggestionsEntry: "draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm",
          mentionSuggestionsEntryFocused: "draftJsMentionPlugin__mentionSuggestionsEntryFocused__3LcTd",
          mentionSuggestionsEntryText: "draftJsMentionPlugin__mentionSuggestionsEntryText__3Jobq",
          mentionSuggestionsEntryAvatar: "draftJsMentionPlugin__mentionSuggestionsEntryAvatar__1xgA9"
        },
        positionSuggestions: (settings: unknown) => {
          // dynamically position suggestions on top
          return {
            display: "block",
            left: 0,
            maxHeight: "8rem",
            maxWidth: "100%",
            minWidth: "100%",
            overflowY: "auto",
            position: "absolute",
            transform: "scale(1) translateY(-100%) translateY(-1.5rem)",
            transformOrigin: "1em 0% 0px"
          };
        }
      }),
    [
      options.entityMutability,
      options.mentionClassName,
      options.mentionPrefix,
      options.mentionTrigger,
      options.suggestionRegex
    ]
  );
}

export type TMentionData = GAutocompleteUserList_userList2_users & {
  /* dynamically generated for pos in list calc */
  index: number;
  colons?: never;
};

/**
 * Only allow mentioning other team members for requests assigned to private teams
 */
function isValidMention(mention: TMentionData, request: RequestGet_request) {
  return request.team.privacy === TeamPrivacy.PUBLIC || request.team.userIds.indexOf(mention.id) > -1;
}

/**
 * Sort alphabetically
 * Do not display experts without a joinTime
 * Fall back to e-mail if we cannot display name
 * Add index for calculating scroll position
 * @param experts list of suggested experts
 */
function suggestionsFilter(experts: GAutocompleteUserList["userList2"]) {
  return experts.users
    .filter(expert => expert.joinTime)
    .map(expert => (expert.name === "" ? { ...expert, name: expert.email } : expert))
    .sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      return nameA.localeCompare(nameB);
    })
    .map((expert, index) => ({ ...expert, index }));
}

/**
 * Mention suggestions popover
 * Loads cached sidebar data to check team membership
 */
export function Mentions(props: {
  request: RequestGet_request;
  editorState: EditorState;
  mentionPlugin: ReturnType<typeof createMentionPlugin>;
  setEditorState(state: EditorState): void;
}) {
  const { currentUser } = useCurrentUser();
  const [suggestions, setSuggestions] = useState<TMentionData[]>([]);
  const [showDialog, setShowDialog] = useState(false);
  /* mouse events can interfere w/ focused entry from key/up down
    (e.g. when user 1. hovers to focus entry 2. changes focus w/ keys) */
  const [blockMouseEvents, setBlockMouse] = useState(false);
  if (!currentUser) return null;
  return (
    <div onMouseMove={() => setBlockMouse(false)}>
      <props.mentionPlugin.MentionSuggestions
        onSearchChange={async (change: { value: string }) => {
          const experts = await getUserList(change.value, true);
          setSuggestions(suggestionsFilter(experts));
        }}
        onAddMention={(mention: TMentionData) => {
          if (!isValidMention(mention, props.request)) {
            // hack to prevent adding mention to editorstate
            setTimeout(() => props.setEditorState(props.editorState), 1);
            setShowDialog(true);
            trackIllegalPrivateTeamMention(props.request.id, props.request.team.id, props.request.team.slug);
          }
        }}
        suggestions={suggestions}
        entryComponent={({ onMouseEnter, onMouseDown, onMouseUp, ...p }: EntryProps & EntryMouseEventHandlers) => (
          <EntryComponent
            isFocused={p.isFocused}
            mention={p.mention}
            mouseEventHandlers={blockMouseEvents ? undefined : { onMouseEnter, onMouseDown, onMouseUp }}
            onKeySelect={() => setBlockMouse(true)}
            request={props.request}
            teamList={currentUser.teams}
          />
        )}
      />
      <ConfirmDialogModal
        isOpen={showDialog}
        text={{
          confirm: "Ok got it",
          heading: "Private team mentions"
        }}
        handleCancel={() => setShowDialog(false)}
        handleConfirm={() => setShowDialog(false)}
      >
        <Typo.Body>
          In requests assigned to private teams, you can currently only mention experts that are members of that team.
        </Typo.Body>
      </ConfirmDialogModal>
    </div>
  );
}

type EntryProps = { isFocused?: boolean; mention: TMentionData };
type EntryMouseEventHandlers<T = HTMLDivElement> = {
  onMouseEnter?: MouseEventHandler<T>;
  onMouseDown?: MouseEventHandler<T>;
  onMouseUp?: MouseEventHandler<T>;
};

/**
 * TODO: refactor for use w/ emojis, externals
 * Renders individual mention entry
 * acts as callback for "isFocused"
 * dynamically calcs position to stick entry at top/bottom while scrolling
 */
function EntryComponent(
  props: EntryProps & {
    request: RequestGet_request;
    teamList?: TeamInfo[];
    /* callback fired on entry focused w/ key up/down */
    onKeySelect(): void;
    mouseEventHandlers?: EntryMouseEventHandlers;
  }
) {
  const ref = useRef<HTMLDivElement>(null);
  // stick select to top or bottom when selecting w/ arrow keys
  useEffect(() => {
    const listContainer = ref.current?.parentElement;
    if (props.isFocused && ref.current && listContainer) {
      /* parent list container */
      const listContainerRect = listContainer.getBoundingClientRect();
      const entryRect = ref.current.getBoundingClientRect();
      /* need to adjust for padding at top/bottom of list container */
      const paddingTop = parseFloat(window.getComputedStyle(listContainer).getPropertyValue("padding-top"));
      /* user is scrolling down */
      if ((listContainerRect?.bottom ?? 0) < entryRect.bottom) {
        props.onKeySelect();
        listContainer.scrollTop =
          (props.mention.index + 1) * entryRect.height - (listContainerRect?.height ?? 0) + paddingTop;
      }
      /* user is scrolling up */
      if (listContainer && (listContainerRect?.top ?? 0) > entryRect.top) {
        props.onKeySelect();
        ref.current.scrollIntoView(true);
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [props.isFocused, props.mention.index, props.onKeySelect]);
  return (
    <EntryItem
      {...props.mouseEventHandlers}
      ref={ref}
      focused={!!props.isFocused}
      invalid={!isValidMention(props.mention, props.request)}
    >
      <UserAvatar
        user={props.mention}
        sizeXS
        css={css`
          margin-left: var(--space-2-rem);
        `}
      />
      <Row align="center" flex="1 1 auto" overflow="hidden" margin="0 1rem">
        <EllipsisText>
          {props.mention.name}{" "}
          <Text
            css={css`
              color: var(--text-3);
            `}
          >
            {props.mention.email}
          </Text>
        </EllipsisText>
      </Row>
      {props.teamList &&
        props.teamList
          .filter(team => team.userIds.indexOf(props.mention.id) > -1)
          .map((team, i) => (
            <PopOver.Blank
              key={i}
              placement="left"
              hover={true}
              button={<TeamSquare>{initialsOf(team.name)}</TeamSquare>}
            >
              <TooltipLabel opacity="0.9" margin="0 0.25rem 0 0">
                {team.name}
              </TooltipLabel>
            </PopOver.Blank>
          ))}
    </EntryItem>
  );
}
