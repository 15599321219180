import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { format } from "date-fns";
import * as React from "react";
import { useQuery } from "react-apollo";
import { createImageListFromAttachments, ImageViewer } from "src/App/Attachments/ImageViewer";
import { AttachmentItem } from "src/App/Attachments/Item";
import { FORM_GET } from "src/App/Forms/Employee/Form";
import { FormGet, FormGetVariables } from "src/App/Forms/Employee/typings/FormGet";
import { Dialog, LoadingBar, Row } from "src/components";
import { TextFieldType } from "src/globalTypes";
import { Modal } from "src/portals/Modal";
import { Typo } from "src/styling/primitives/typography";
import AnswersSquiggleSvg from "./AnswersSquiggle.svg";

const Background = styled.div<{
  url: string;
  x: string;
  y: string;
  margin: string;
  size?: string;
  position?: React.CSSProperties["position"];
}>`
  position: ${p => p.position || "fixed"};
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: ${p => p.margin};
  background: url("${p => p.url}");
  background-repeat: no-repeat;
  background-position-x: ${p => p.x};
  background-position-y: ${p => p.y};
  background-size: ${p => p.size || "auto"};
`;

export function FormAnswersModal(props: { formId: string; isOpen: boolean; dismiss(): void }) {
  const formResponse = useQuery<FormGet, FormGetVariables>(FORM_GET, {
    fetchPolicy: "network-only",
    variables: {
      id: props.formId
    }
  });
  return (
    <Modal isOpen={props.isOpen} onDismiss={props.dismiss}>
      <Dialog large onClose={props.dismiss}>
        <Background position="absolute" url={AnswersSquiggleSvg} x="right" y="top" margin="0" />
        {formResponse.loading && <LoadingBar />}
        {formResponse.data && formResponse.data.form && (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              & > * + * {
                margin-top: var(--space-4-rem);
              }
            `}
          >
            <div>
              <Typo.Body sizeXXL>{formResponse.data.form.schema.displayName}</Typo.Body>
            </div>
            {formResponse.data.form.schema.fieldsList.map((field, i) => (
              <div
                key={i}
                css={css`
                  z-index: var(--z-highest);
                `}
              >
                <Row margin="0.25rem 0">
                  <Typo.Body light sizeS>
                    QUESTION {i + 1} out of {formResponse.data?.form.schema.fieldsList.length ?? 0}
                  </Typo.Body>
                </Row>
                <Row margin="0.5rem 0">
                  <Typo.Body
                    css={css`
                      color: var(--text-5);
                      max-width: 100%;
                      word-break: break-word;
                    `}
                  >
                    {field.displayName}
                  </Typo.Body>
                </Row>
                <Row margin="0.75rem 0">
                  <Typo.Body
                    sizeXL
                    css={[
                      css`
                        word-break: break-word;
                        white-space: pre-wrap;
                      `
                    ]}
                  >
                    {field.__typename === "TextField" && field.textFieldType === TextFieldType.DATE && field.value && (
                      <>{format(new Date(field.value), "d MMM yyyy")}</>
                    )}

                    <>{field.__typename === "TextField" && field.textFieldType !== TextFieldType.DATE && field.value}</>

                    <>
                      {field.__typename === "DropdownField" &&
                        field.selected !== null &&
                        field.optionsList[field.selected]}
                    </>
                  </Typo.Body>
                  {field.__typename === "AttachmentsField" && (
                    <ImageViewer
                      imagesList={field.attachment ? createImageListFromAttachments([field.attachment], null) : []}
                    >
                      {dispatch =>
                        field.attachment && (
                          <AttachmentItem
                            attachment={field.attachment}
                            imageViewerDispatch={dispatch}
                            authToken={null}
                          />
                        )
                      }
                    </ImageViewer>
                  )}
                </Row>
              </div>
            ))}
          </div>
        )}
      </Dialog>
    </Modal>
  );
}
