import { css } from "@emotion/core";
import gql from "graphql-tag";
import React, { FC, useState } from "react";
import { useMutation } from "react-apollo";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { Button, ButtonBar, Dialog, LoadingBar } from "src/components";
import { RichEditor, richEditorInputStyles } from "src/components/RichEditor/Editor";
import { Modal } from "src/portals/Modal";
import { Typo } from "src/styling/primitives/typography";
import { htmlToBackMarkdown } from "src/util/formatters";
import { REQUEST_GET } from "../REQUEST_GET";
import { ManualTaskCreate, ManualTaskCreateVariables } from "./typings/ManualTaskCreate";

export const ManualTaskModal: FC<{
  isOpen: boolean;
  requestId: string;
  onCloseManualTaskModal(): void;
}> = props => {
  const { emitSnack } = useSnack();

  const [taskHtmlContent, setTaskHtmlContent] = useState("");
  const [createManualTask, createManualTaskResponse] = useMutation<ManualTaskCreate, ManualTaskCreateVariables>(
    gql`
      mutation ManualTaskCreate($params: ManualTaskCreateParameters!) {
        manualTaskCreate(params: $params) {
          success
          code
          message
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: REQUEST_GET,
          variables: {
            id: props.requestId
          }
        }
      ],
      onCompleted: data => {
        const { message } = data.manualTaskCreate;
        if (data.manualTaskCreate.success) {
          emitSnack({
            type: "info",
            message
          });
          props.onCloseManualTaskModal();
        } else {
          emitSnack({
            type: "mutationError",
            message
          });
        }
      }
    }
  );

  const { loading } = createManualTaskResponse;
  // parsed on every change so we can enable/disable the create button properly
  const instruction = htmlToBackMarkdown(taskHtmlContent);

  return (
    <Modal isOpen={props.isOpen} onDismiss={() => props.onCloseManualTaskModal()}>
      {loading && <LoadingBar />}
      <Dialog medium title="Add new task" onClose={() => props.onCloseManualTaskModal()}>
        <div
          css={css`
            & > * + * {
              margin-top: var(--space-4-rem);
            }
          `}
        >
          <p>
            Add a new task to this request. This task will be visible to all experts in this team and anyone will be
            able to mark it as done.
          </p>
          <div>
            <Typo.Body bold>Instructions</Typo.Body>
            <div css={richEditorInputStyles}>
              <RichEditor
                initialValue=""
                onChange={html => {
                  setTaskHtmlContent(html);
                }}
              />
            </div>
          </div>
          <ButtonBar>
            <Button size="large" onClick={() => props.onCloseManualTaskModal()}>
              Cancel
            </Button>
            <Button
              disabled={loading || instruction.length === 0}
              variant="primary"
              size="large"
              onClick={() => {
                createManualTask({
                  variables: {
                    params: {
                      requestId: props.requestId,
                      instruction
                    }
                  }
                });
              }}
            >
              Add
            </Button>
          </ButtonBar>
        </div>
      </Dialog>
    </Modal>
  );
};
