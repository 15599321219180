import { css } from "@emotion/core";
import styled from "@emotion/styled";
import {
  mdiAirplane,
  mdiCellphoneLink,
  mdiClock,
  mdiClockOutline,
  mdiEmoticonOutline,
  mdiFlag,
  mdiFlower,
  mdiGamepadVariant,
  mdiHeartOutline,
  mdiPizza,
  mdiSlack
} from "@mdi/js";
import Icon from "@mdi/react";
import { EditorState } from "draft-js";
import createMentionPlugin from "draft-js-mention-plugin";
import "draft-js-mention-plugin/lib/plugin.css";
import "draft-js/dist/Draft.css";
import { BaseEmoji, CustomEmoji, CustomIcons, emojiIndex, Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import { pick } from "lodash";
import * as React from "react";
import { useState } from "react";
import { AbsoluteTooltip, EllipsisText, MaterialIcon, SquareButton, Text } from "src/components";
import { PopOver } from "src/components/PopOver";
import { useClickaway } from "src/hooks/useClickaway";
import { popMenu } from "src/styling/effects";
import zIndices from "src/styling/tokens/z-indices.json";
import { __rawColorValues } from "src/styling/tokens/__colors";
import { EntryItem } from "./Mentions";

const EmojiPickerWrapper = styled.div`
  position: absolute;
  bottom: 2rem;
  right: 0;

  & .emoji-mart {
    border: none;
    border-radius: var(--border-radius-m);
    ${popMenu.shadow}
    font-size: 0.875rem;
    font-family: var(--font-family-body);
  }

  & .emoji-mart-anchors {
    padding: 0 1rem;
  }

  & .emoji-mart-anchor {
    padding: 0.5rem 0.25rem;
  }

  & .emoji-mart-anchor-icon svg {
    fill: var(--text-1);
  }

  & .emoji-mart-search-icon svg {
    fill: var(--text-1);
    opacity: 1 !important;
  }

  & .emoji-mart-anchor-icon:hover svg {
    fill: var(--text-6);
  }

  & .emoji-mart-anchor-selected {
    background-color: var(--hover);
  }

  & .emoji-mart-anchor-selected .emoji-mart-anchor-icon svg {
    fill: var(--text-6);
  }

  & .emoji-mart-anchor-bar {
    display: none;
  }

  & .emoji-mart-bar {
    background-color: var(--lightGrey-1);
    border-bottom-color: var(--border);
  }

  & .emoji-mart-search {
    margin: 0.75rem 0;
    padding: 0 1rem;
  }

  & .emoji-mart-search input {
    border-color: var(--border);
    border-radius: 0.25rem;
    font-size: 0.875rem;
  }

  & .emoji-mart-search input::placeholder {
    color: var(--text-2);
  }

  & .emoji-mart-search-icon {
    right: 1.5rem;
    top: 0.375rem;
  }

  & .emoji-mart-scroll {
    padding: 0 1rem 0.5rem;
  }

  & .emoji-mart-category {
    margin-bottom: 1rem;
  }

  & .emoji-mart-category-label span {
    font-weight: 600;
    padding: 0 0 0.5rem;
  }

  & .emoji-mart-emoji:hover:before {
    background-color: var(--overlayBackground);
  }

  & .emoji-mart-preview-emoji {
    display: none;
  }

  & .emoji-mart-preview {
    height: 2rem;
  }

  & .emoji-mart-preview-skins {
    right: 0.5rem;
  }

  & .emoji-mart-skin-swatches {
    border-color: var(--border);
  }

  & .emoji-mart-no-results-label {
    color: var(--text-1);
  }

  & button,
  & span[role="button"],
  & button * {
    cursor: pointer !important;
    outline: none;
  }
`;

/** emoji-mart based picker */
export function EmojiPicker(props: { handleSelect(emoji: BaseEmoji): void }) {
  const [showPicker, setShowPicker] = useState(false);
  const [containerRef] = useClickaway<HTMLDivElement>(() => {
    setShowPicker(false);
  });
  return (
    <div style={{ position: "relative" }}>
      <AbsoluteTooltip placement="top" content="Add an emoji">
        <SquareButton
          // no idea why this is necessarry (seems to memoize onClick)
          key={showPicker.toString()}
          onClick={() => {
            setShowPicker(!showPicker);
          }}
        >
          <MaterialIcon path={mdiEmoticonOutline} size={1.125} />
        </SquareButton>
      </AbsoluteTooltip>
      {showPicker && (
        <EmojiPickerWrapper ref={containerRef} onClick={e => e.stopPropagation()}>
          <Picker
            autoFocus
            showPreview={false}
            title={""}
            color={__rawColorValues.yellow_5}
            onSelect={(emoji: BaseEmoji) => {
              props.handleSelect(emoji);
              setShowPicker(false);
            }}
            icons={
              // Related Github issue
              // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/40734
              ({
                categories: {
                  search: () => <Icon path={mdiClock} />,
                  recent: () => <Icon path={mdiClockOutline} />,
                  people: () => <Icon path={mdiEmoticonOutline} />,
                  nature: () => <Icon path={mdiFlower} />,
                  foods: () => <Icon path={mdiPizza} />,
                  activity: () => <Icon path={mdiGamepadVariant} />,
                  places: () => <Icon path={mdiAirplane} />,
                  objects: () => <Icon path={mdiCellphoneLink} />,
                  symbols: () => <Icon path={mdiHeartOutline} />,
                  flags: () => <Icon path={mdiFlag} />,
                  custom: () => <Icon path={mdiSlack} />
                }
              } as unknown) as CustomIcons
            }
          />
        </EmojiPickerWrapper>
      )}
    </div>
  );
}

/** Adapts EmojiPicker to use popover and specific styles for md editor */
export function EmojiPickerPop(props: { handleSelect(emoji: BaseEmoji): void }) {
  const [showPicker, setShowPicker] = useState(false);
  const [containerRef] = useClickaway<HTMLDivElement>(() => {
    setShowPicker(false);
  });
  return (
    <PopOver.Blank
      zIndex={zIndices.highest.value}
      isOpen={showPicker}
      placement="top-start"
      button={
        <AbsoluteTooltip placement="top" content="Add an emoji">
          <SquareButton
            // no idea why this is necessarry (seems to memoize onClick)
            key={showPicker.toString()}
            onClick={() => {
              setShowPicker(!showPicker);
            }}
            css={css`
              color: var(--text-1);
              &:hover {
                color: var(--text-6);
              }
            `}
          >
            <MaterialIcon path={mdiEmoticonOutline} size={1.125} />
          </SquareButton>
        </AbsoluteTooltip>
      }
    >
      <EmojiPickerWrapper
        css={css`
          bottom: 0;
          left: 0;
        `}
        ref={containerRef}
        onClick={e => e.stopPropagation()}
      >
        <Picker
          autoFocus
          showPreview={false}
          title={""}
          color={__rawColorValues.yellow_5}
          onSelect={(emoji: BaseEmoji) => {
            props.handleSelect(emoji);
            setShowPicker(false);
          }}
          icons={
            // Related Github issue
            // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/40734
            ({
              categories: {
                search: () => <Icon path={mdiClock} />,
                recent: () => <Icon path={mdiClockOutline} />,
                people: () => <Icon path={mdiEmoticonOutline} />,
                nature: () => <Icon path={mdiFlower} />,
                foods: () => <Icon path={mdiPizza} />,
                activity: () => <Icon path={mdiGamepadVariant} />,
                places: () => <Icon path={mdiAirplane} />,
                objects: () => <Icon path={mdiCellphoneLink} />,
                symbols: () => <Icon path={mdiHeartOutline} />,
                flags: () => <Icon path={mdiFlag} />,
                custom: () => <Icon path={mdiSlack} />
              }
            } as unknown) as CustomIcons
          }
        />
      </EmojiPickerWrapper>
    </PopOver.Blank>
  );
}

/** Emoji suggestions popover */
export function EmojiSuggestions(props: {
  editorState: EditorState;
  plugin: ReturnType<typeof createMentionPlugin>;
  setEditorState(state: EditorState): void;
}) {
  const [suggestions, setSuggestions] = useState<BaseEmoji[]>([]);
  const [searchKey, setSearchKey] = useState("");
  return (
    <props.plugin.MentionSuggestions
      onSearchChange={(change: { value: string }) => {
        setSearchKey(change.value);
        const results = ((emojiIndex.search(change.value) || []) as unknown) as BaseEmoji[];
        setSuggestions(results.map(r => ({ ...r, name: r.native })));
      }}
      suggestions={suggestions}
      entryComponent={(entryProps: { isFocused?: boolean; mention: BaseEmoji & CustomEmoji }) => {
        return (
          <EntryItem {...pick(entryProps, ["onMouseEnter", "onMouseDown", "onMouseUp"])} focused={entryProps.isFocused}>
            <EllipsisText>
              {entryProps.mention.native}{" "}
              <Text>
                :{/* attempt to match short code with the keyword user is typing */}
                {(entryProps.mention.short_names &&
                  entryProps.mention.short_names.filter(s => s.includes(searchKey))[0]) ||
                  entryProps.mention.id}
                :
              </Text>
            </EllipsisText>
          </EntryItem>
        );
      }}
    />
  );
}
