import styled from "@emotion/styled";
import * as React from "react";
import { Bold, Col, Row } from "src/components";
import { RouteComponentProps } from "src/util/router";
import ErrorSvg from "./Error.svg";
import NotificationSlackSvg from "./NotificationSlack.svg";

const Card = styled.div`
  background: var(--white);
  box-sizing: border-box;
  border-radius: 0.5rem;
  box-shadow: 0 0 1.375rem rgba(0, 0, 0, 0.04);
  border: 1px solid var(--border);
  font-size: 1.25rem;
  padding: 3.5rem;
  width: 27rem;

  & p {
    text-align: center;
    margin: 2rem 0 0 0;
  }
`;

const BackgroundImage = styled.div<{
  width: string;
  height: string;
  src: string;
}>`
  width: ${p => p.width};
  height: ${p => p.height};
  background-image: url("${p => p.src}");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;

export const NotificationSetSlack: React.ComponentType<RouteComponentProps> = () => (
  <Row flex="1 0 auto" justify="center">
    <Col padding="4rem 0 0 0">
      <Card>
        <BackgroundImage width="20rem" height="6rem" src={NotificationSlackSvg} />
        <p>
          You have now set to receceive notifications through <Bold>slack</Bold>.
        </p>
      </Card>
    </Col>
  </Row>
);

export const NotificationSetError: React.ComponentType<RouteComponentProps> = () => (
  <Row flex="1 0 auto" justify="center">
    <Col padding="4rem 0 0 0">
      <Card>
        <BackgroundImage width="20rem" height="6rem" src={ErrorSvg} />
        <p>There was an error changing your notification settings.</p>
      </Card>
    </Col>
  </Row>
);
