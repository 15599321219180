import { css } from "@emotion/core";
import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";

function assertsReactElement(node: React.ReactNode): asserts node is React.ReactElement {
  if (!node || !(typeof node === "object") || !("key" in node)) {
    throw new Error(`${node} not valid ReactElement`);
  }
}

/**
 * Animated stack of e.g. banners mounting/unmounting
 * *children must use stabile key*
 */
export const AnimatedStack: React.FC<{}> = props => {
  return (
    <motion.div
      variants={{
        show: {
          transition: {
            staggerChildren: 0.2
          }
        }
      }}
      initial="hidden"
      animate="show"
      exit="hidden"
      css={css`
        overflow: hidden;
        width: 100%;
        z-index: var(--z-lowest);
        & > * + * {
          margin-top: var(--space-1-rem);
        }
      `}
    >
      <AnimatePresence>
        {React.Children.map(props.children, child => {
          // ensure has key etc.
          assertsReactElement(child);
          return (
            <motion.div
              key={child.key ?? undefined}
              exit="hidden"
              variants={{
                hidden: {
                  opacity: [1, 0, 0],
                  transform: ["translateX(0%) scaleX(1)", "translateX(-100%) scaleX(1)", "translateX(-100%) scaleX(0)"],
                  transition: {
                    ease: "easeIn",
                    duration: 0.4
                  }
                },
                show: {
                  opacity: 1,
                  transform: "translateX(0%) scaleX(1)",
                  transition: {
                    ease: "easeOut",
                    duration: 0.2
                  }
                }
              }}
            >
              {child}
            </motion.div>
          );
        })}
      </AnimatePresence>
    </motion.div>
  );
};
