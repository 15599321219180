import css from "@emotion/css";
import styled from "@emotion/styled";
import { AnimatePresence } from "framer-motion";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { stackOrder } from "src/styling/layout";
import { fontFamily, fontWeights, lineHeights } from "src/styling/typography";
import { csx } from "src/util/csx";

// export barrel
// TODO: move everything out of index.tsx
export * from "./Avatar";
export * from "./Badges";
export * from "./BaseLayout";
export * from "./Buttons";
export * from "./ConditionalTooltip";
export * from "./DateTime";
export * from "./Dialogs";
export * from "./EmptyState";
export * from "./Fields";
export * from "./Icon";
export * from "./IconAndText";
export * from "./Icons";
export * from "./InfoBanner";
export * from "./Menu";
export * from "./MessageText";
export * from "./Notes";
export * from "./Table";
export * from "./TableWrapper";
export * from "./Tooltip";
export * from "./Typeform";

export interface ICommonStyleProps {
  display?: string;
  position?: React.CSSProperties["position"];
  flex?: string;
  direction?: string;
  align?: string;
  width?: string;
  height?: string;
  maxHeight?: string;
  justify?: string;
  alignContent?: string;
  alignSelf?: string;
  wrap?: string;
  fontSize?: string;
  lineHeight?: string;
  background?: string;
  margin?: string;
  padding?: string;
  fullWidth?: boolean;
  overflow?: string;
  opacity?: string;
  zIndex?: keyof typeof stackOrder;
}

export interface ICommonContainerProps {
  gap?: string;
}

export const Row = styled.div<ICommonStyleProps & ICommonContainerProps>`
  box-sizing: border-box;
  position: ${p => p.position || "static"};
  display: flex;
  flex: ${p => p.flex || "0 0 auto"};
  flex-wrap: ${p => p.wrap || "nowrap"};
  font-size: ${p => p.fontSize || "inherit"};
  gap: ${p => p.gap || "normal"};
  justify-content: ${p => p.justify || "normal"};
  align-content: ${p => p.alignContent || "normal"};
  align-items: ${p => p.align || "normal"};
  align-self: ${p => p.alignSelf || "normal"};
  width: ${p => (p.fullWidth ? "100%" : "auto")};
  height: ${p => p.height || "auto"};
  padding: ${p => p.padding || "0"};
  margin: ${p => p.margin || "0"};
  overflow: ${p => p.overflow || "visible"};
  z-index: ${p => p.zIndex && stackOrder[p.zIndex]};
`;

export const Col = styled.div<ICommonStyleProps & ICommonContainerProps>`
  box-sizing: border-box;
  position: ${p => p.position || "static"};
  display: flex;
  flex-direction: ${p => (p.direction ? p.direction : "column")};
  flex: ${p => p.flex || "0 0 auto"};
  font-size: ${p => p.fontSize || "inherit"};
  gap: ${p => p.gap || "normal"};
  justify-content: ${p => p.justify || "normal"};
  align-items: ${p => p.align || "normal"};
  align-self: ${p => p.alignSelf || "normal"};
  width: ${p => (p.fullWidth ? "100%" : p.width || "auto")};
  height: ${p => p.height || "auto"};
  padding: ${p => p.padding || "0"};
  margin: ${p => p.margin || "0"};
  overflow: ${p => p.overflow || "visible"};
  z-index: ${p => p.zIndex && stackOrder[p.zIndex]};
  word-break: break-word;
`;

export const Text = styled.span<
  ICommonStyleProps & {
    fontFamily?: keyof typeof fontFamily;
    fontWeight?: string;
    lineHeight?: string;
    letterSpacing?: string;
  }
>`
  margin: ${p => p.margin || "0"};
  font-family: ${p => fontFamily[p.fontFamily || "body"]}, sans-serif;
  font-size: ${p => p.fontSize || "0.875rem"};
  font-weight: ${p => p.fontWeight || fontWeights.regular};
  line-height: ${p => p.lineHeight || "120%"};
  letter-spacing: ${p => p.letterSpacing || "0"};
  color: var(--text-6);
  opacity: ${p => p.opacity || "1.0"};
`;

export const Hr = styled.div`
  margin: 0.375rem 1rem;
  height: 1px;
  background-color: var(--border);
`;

export const EllipsisText = styled.span<{ margin?: string }>`
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: ${p => p.margin || "0"};
`;

/**
 * TextEllipsis was replaced with 'Typo.' classes
 * @deprecated
 */
export const TextEllipsis = csx(
  [
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `
  ],
  {},
  "span"
);

/**
 * Bold span
 * Reads font weight from theme (so we can change font family / weights in the future)
 */
export const Bold = styled.span`
  font-weight: ${() => fontWeights.bold};
`;

export const Subtitle = styled.div`
  color: var(--text-6);
  line-height: ${lineHeights.body};
`;

const progressStyles = css`
  content: "";
  background-color: var(--blue-1);
  top: 0;
  left: 0;
  bottom: 0;
  width: auto;
  position: absolute;
  transition: transform 0.2s linear;
  transform-origin: left;
`;
const ProgressIndicator = csx(
  [
    css`
      top: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      position: fixed;
      height: var(--space-2-rem);
      background-color: var(--lightBlue-3);
      z-index: var(--z-highest);
      /*  copy of @material-ui/core/LinearProgress/LinearProgress.js */
      @keyframes mui-indeterminate1 {
        0% {
          left: -35%;
          right: 100%;
        }
        60% {
          left: 100%;
          right: -90%;
        }
        100% {
          left: 100%;
          right: -90%;
        }
      }
      @keyframes mui-indeterminate2 {
        0% {
          left: -200%;
          right: 100%;
        }
        60% {
          left: 107%;
          right: -8%;
        }
        100% {
          left: 107%;
          right: -8%;
        }
      }
      &:before {
        ${progressStyles}
        animation: mui-indeterminate1 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
      }
      &:after {
        ${progressStyles}
        animation: mui-indeterminate2 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        animation-delay: 1.15s;
      }
    `
  ],
  {}
);
export const LoadingBar: FC = () => {
  const portalTarget: Element | null = document.querySelector("#loadingBar");
  const [isShown, setIsShown] = useState<boolean>(false);
  // Render Loading component only after 150ms delay
  useEffect(() => {
    const timeout = window.setTimeout(() => {
      setIsShown(true);
    }, 150);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [setIsShown]);

  if (!portalTarget) return null;
  return createPortal(<AnimatePresence>{isShown && <ProgressIndicator />}</AnimatePresence>, portalTarget);
};

export const TooltipLabel = styled.div<ICommonStyleProps>`
  padding: 2px;
  margin: ${p => p.margin || "0.25rem"};
  background: var(--white);
  color: var(--text-6);
  font-size: 0.75rem;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  opacity: ${p => p.opacity || "0.6"};
`;

export const Hide = csx(
  [
    css`
      display: inline-block;
    `
  ],
  {
    when: css`
      width: 0;
      height: 0;
      visibility: hidden;
    `
  }
);

export const UnderlineText = styled.span<ICommonStyleProps>`
  border-bottom: 1px solid currentColor;
`;

/** insert br tags for newlines and adds the asterisk for required fields */
export function LineBreak(props: { text: string; asterisk?: boolean }) {
  const lines = props.text.split("\n");
  return (
    <>
      {lines.map((line, i) => (
        <React.Fragment key={i}>
          {line}
          {i < lines.length - 1 && <br />}
        </React.Fragment>
      ))}
      {props.asterisk && " *"}
    </>
  );
}

/* block click events for open menus etc. */
export const ClickBlock = csx([
  css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: all;
    z-index: ${stackOrder.high};
  `
]);
