import { css } from "@emotion/core";
import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";
import { useEffect, useReducer, useRef } from "react";
import { createPortal } from "react-dom";
import { Col, Icon, Row } from "src/components";
import { modalAnimation, overlay } from "src/portals/Modal";
import { CloseX } from "src/svg/icons/CloseX";
import { Download } from "src/svg/icons/Download";
import { PlainArrowLeft } from "src/svg/icons/PlainArrowLeft";
import { PlainArrowRight } from "src/svg/icons/PlainArrowRight";
import { csx } from "src/util/csx";
import { useHotKeys } from "src/util/services/hotkeys";
import { attachmentUrl } from "./Item";

export const isFilenameImage = (filename: string) => /^.*\.(gif|jpg|jpeg|tiff|png|webp)$/i.test(filename);
export interface IImageState {
  imagesList: {
    id: string;
    url: string;
    displayName: string;
  }[];
  index: number | null;
}

export type TImageAction =
  | {
      type: "LOAD_IMAGES";
      imagesList: IImageState["imagesList"];
    }
  | {
      type: "DISPLAY_IMAGE";
      id: string;
    }
  | {
      type: "NEXT";
    }
  | {
      type: "PREV";
    }
  | {
      type: "DISMISS";
    };

const emptyState: IImageState = {
  imagesList: [],
  index: null
};

function imageViewReducer(state: IImageState, action: TImageAction): IImageState {
  switch (action.type) {
    case "LOAD_IMAGES": {
      return {
        ...state,
        imagesList: action.imagesList
      };
    }
    case "DISPLAY_IMAGE": {
      const image = state.imagesList.find(image => image.id === action.id);
      return {
        ...state,
        index: image ? state.imagesList.indexOf(image) : 0
      };
    }
    case "DISMISS": {
      return {
        ...state,
        index: null
      };
    }
    case "NEXT": {
      return {
        ...state,
        index: ((state.index ?? 0) + 1) % state.imagesList.length
      };
    }
    case "PREV": {
      return {
        ...state,
        index: ((state.index ?? 0) - 1 + state.imagesList.length) % state.imagesList.length
      };
    }
    default: {
      return state;
    }
  }
}

const Img = csx(
  [
    css`
      flex: 0 0 auto;
      max-height: 90vh;
      max-width: 90vw;
    `
  ],
  {},
  "img"
);

const TopControls = csx([
  css`
    position: fixed;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    padding: var(--space-5-rem);
    box-sizing: border-box;

    & > * + * {
      margin-left: var(--space-5-rem);
    }
  `
]);

const opacityOnHover = css`
  opacity: 0.7;
  transition: all ease 0.3s;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;
const ControlArea = csx(
  [
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: fixed;
      height: 100vh;
      padding: var(--space-5-rem);
      transition: all ease 0.3s;
    `
  ],
  {
    shouldHover: css`
      ${opacityOnHover}
      /* right */
      &:first-of-type:hover {
        background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.15) 100%);
      }
      /* left */
      &:last-of-type:hover {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.15) 100%);
      }
    `,
    left: css`
      left: 0;
    `,
    right: css`
      right: 0;
    `
  }
);

const controlIcon = css`
  color: var(--white);

  & > svg {
    height: 1.5rem;
    width: 1.5rem;
    max-width: 1.5rem;
  }
`;

export const createImageListFromAttachments = (
  attachmentsList: {
    id: string;
    displayName: string;
  }[],
  authToken: string | null
) =>
  attachmentsList
    .filter(attachment => isFilenameImage(attachment.displayName))
    .map(attachment => ({
      id: attachment.id,
      url: attachmentUrl(attachment, authToken),
      displayName: attachment.displayName
    }));

const ImageViewerPortal: React.FC<{}> = props => {
  const target = useRef(document.getElementById("modal") ?? document.body.appendChild(document.createElement("div")));
  return createPortal(props.children, target.current);
};

/**
 * Provides a lightbox image viewer for a list of images
 * Use dispatch to a display an image
 * */
export function ImageViewer(props: {
  imagesList: IImageState["imagesList"];
  children: (dispath: React.Dispatch<TImageAction>) => React.ReactNode;
}) {
  const [state, dispatch] = useReducer(imageViewReducer, { ...emptyState, imagesList: props.imagesList });
  useEffect(() => {
    dispatch({
      type: "LOAD_IMAGES",
      imagesList: props.imagesList
    });
  }, [dispatch, props.imagesList]);

  useHotKeys({
    name: "Navigate images in image viewer",
    group: "request detail",
    keys: "ArrowLeft,ArrowRight",
    handler: (e: KeyboardEvent) => {
      if (e.key === "ArrowLeft" && state.index !== null) {
        dispatch({ type: "PREV" });
      } else if (e.key === "ArrowRight" && state.index !== null) {
        dispatch({ type: "NEXT" });
      }
    },
    deps: [state.index]
  });

  const selectedImage =
    state.index !== null && state.index < state.imagesList.length ? state.imagesList[state.index] : null;

  return (
    <>
      {props.children(dispatch)}
      <ImageViewerPortal>
        <AnimatePresence>
          {selectedImage && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={modalAnimation}
              css={[
                overlay,
                css`
                  background-color: rgba(var(--black-raw), 0.7);
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                `
              ]}
              onClick={e => {
                dispatch({ type: "DISMISS" });
              }}
            >
              <Col flex="1 0 auto" justify="space-between">
                <Row flex="1 0 auto" justify="center" align="center" onClick={() => dispatch({ type: "DISMISS" })}>
                  <ControlArea
                    left
                    shouldHover={state.imagesList.length > 1}
                    onClick={e => {
                      e.stopPropagation();
                      dispatch({ type: "PREV" });
                    }}
                  >
                    {state.imagesList.length > 1 && (
                      <Icon narrow css={[controlIcon]}>
                        <PlainArrowLeft />
                      </Icon>
                    )}
                  </ControlArea>
                  <Img src={selectedImage.url} alt={selectedImage.displayName} onClick={e => e.stopPropagation()} />
                  <ControlArea
                    right
                    shouldHover={state.imagesList.length > 1}
                    onClick={e => {
                      e.stopPropagation();
                      dispatch({ type: "NEXT" });
                    }}
                  >
                    {state.imagesList.length > 1 && (
                      <Icon narrow css={[controlIcon]}>
                        <PlainArrowRight />
                      </Icon>
                    )}
                  </ControlArea>
                </Row>
                {/* render TopControls in higher stacking context than RightControls so as to show pointer cursor */}
                <TopControls>
                  <a
                    href={selectedImage.url}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  >
                    <Icon narrow css={[controlIcon, opacityOnHover]}>
                      <Download />
                    </Icon>
                  </a>
                  <Icon
                    onClick={e => {
                      e.stopPropagation();
                      dispatch({ type: "DISMISS" });
                    }}
                    narrow
                    css={[controlIcon, opacityOnHover]}
                  >
                    <CloseX />
                  </Icon>
                </TopControls>
              </Col>
            </motion.div>
          )}
        </AnimatePresence>
      </ImageViewerPortal>
    </>
  );
}
