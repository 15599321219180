import styled from "@emotion/styled";
import * as React from "react";
import { fontWeights } from "src/styling/typography";

const NoteWrapper = styled.div`
  background: var(--lightGrey-1);
  border-radius: var(--border-radius-xxl);
  color: var(--text-6);
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 1rem;

  & a {
    color: var(--text-6);
  }

  & a:hover {
    color: var(--text-4);
  }
`;

const Title = styled.div`
  font-weight: ${fontWeights.bold};
  margin-bottom: 0.25rem;
`;

interface NoteProps {
  children: React.ReactNode;
  title: string;
}

export const Note = (props: NoteProps) => (
  <NoteWrapper>
    <Title>{props.title}</Title>
    {props.children}
  </NoteWrapper>
);
