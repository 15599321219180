import { css } from "@emotion/core";
import * as React from "react";
import { FC } from "react";
import { createImageListFromAttachments, ImageViewer } from "src/App/Attachments/ImageViewer";
import { AttachmentItem } from "src/App/Attachments/Item";
import { DocumentsList } from "src/App/KB/Detail/DocumentsList";
import { DocumentFragment } from "src/App/KB/typings/DocumentFragment";
import { Col } from "src/components";
import { Typo } from "src/styling/primitives/typography";
import { screenDimensions } from "src/styling/tokens/jsTokens";
import { csx } from "src/util/csx";

/**
 * This file contains the TimelineEvent, which replaces the AvatarEvent, component
 * and all scx components rendered inside of it. Different variations of eg the alignment
 * (left | right) or message colors are being set by using scx modifiers on the
 * TimelineEvent itself. Sadly I could not eliminate the usage of css classes
 * entirely, because it would have been an even bigger refactoring. Still I
 * centralised most of the styling here, which makes the components a bit easier
 * to undrstand and side effects of future changes can be better anticipated.
 */

export const TimelineItem = csx(
  [
    css`
      padding: 0 0 var(--space-6-rem) 5rem;
      max-width: 100%;
      display: flex;
      flex-direction: row-reverse;
      @media (max-width: ${screenDimensions.mobileMax}px) {
        padding: 0 0 var(--space-4-rem) 0;
      }
      @media print {
        break-inside: avoid;
      }
    `
  ],
  {
    alignLeft: css`
      padding: 0 5rem var(--space-6-rem) 0;
      flex-direction: row;
    `
  }
);

export const MessageContentWrapper = csx([
  css`
    flex: 0 1 auto;
    max-width: 100%;
    position: relative;

    /* could not get rid of all the nesting bt at least move it out of the components nlogic */
    > .event-context-menu {
      opacity: 0;
    }
    &:hover,
    &:focus-within {
      > .event-context-menu {
        opacity: 1;
      }
    }
  `
]);
export const MessageAvatarWrapper = csx(
  [
    css`
      position: absolute;
      bottom: 0;
      transform: translate(50%, 50%);
      right: 0;
    `
  ],
  {
    alignLeft: css`
      transform: translate(-50%, 50%);
      left: 0;
      right: initial;
    `
  }
);
export const MessageContextMenuWrapper = csx(
  [
    css`
      display: block;
      position: absolute;
      top: var(--space-3-rem);
      transform: translateX(calc(-100% - var(--space-1-rem)));
    `
  ],
  {
    alignLeft: css`
      right: 0;
      transform: translateX(calc(100% + var(--space-1-rem)));
    `
  }
);

export const MessageBox = csx(
  [
    css`
      max-width: 100%;
      padding: var(--space-4-rem);
      border-radius: var(--border-radius-xl);
      word-break: break-word;

      background-color: var(--lightGrey-1);
      /* borders are only relevant for pointer-events, when background colors are disabled */
      border: 1px solid var(--lightGrey-1);

      & > * + * {
        margin-top: var(--space-2-rem);
      }

      & .mention,
      & .mention:hover {
        text-decoration: none;
        padding: 0 2px;
        border-radius: var(--border-radius-s);
        pointer-events: none;

        color: var(--text-6);
        background-color: var(--lightGrey-3);
      }

      @media (max-width: ${screenDimensions.mobileMax}px) {
        padding: var(--space-3-rem);
      }
    `
  ],
  {
    grey: css`
      background-color: var(--lightGrey-1);
      border: 1px solid var(--lightGrey-1);
      & .mention,
      & .mention:hover {
        color: var(--text-6);
        background-color: var(--lightGrey-3);
      }
    `,
    darkGrey: css`
      background-color: var(--lightGrey-2);
      border: 1px solid var(--lightGrey-2);
      border-radius: var(--border-radius-l);
      padding: var(--space-3-rem);
      & .mention,
      & .mention:hover {
        color: var(--text-6);
        background-color: var(--lightGrey-3);
      }
    `,
    blue: css`
      background-color: var(--lightBlue-1);
      border: 1px solid var(--lightBlue-1);
      & .mention,
      & .mention:hover {
        color: var(--blue-4);
        background-color: var(--lightBlue-3);
      }
    `,
    darkBlue: css`
      background-color: var(--lightBlue-2);
      border: 1px solid var(--lightBlue-2);
      border-radius: var(--border-radius-l);
      padding: var(--space-3-rem);
      & .mention,
      & .mention:hover {
        color: var(--blue-4);
        background-color: var(--lightBlue-3);
      }
    `,
    yellow: css`
      background-color: var(--yellow-1);
      border: 1px solid var(--yellow-1);
      & .mention,
      & .mention:hover {
        color: var(--text-6);
        background-color: var(--yellow-3);
      }
    `
  }
);

export const BannerWrapper = csx([
  css`
    padding: var(--space-4-px);
    color: var(--blue-3);
    background: var(--lightBlue-1);
    border-bottom-left-radius: var(--border-radius-l);
    border-bottom-right-radius: var(--border-radius-l);
    margin: var(--space-4-rem) -1rem -1rem -1rem;
  `
]);

export const MessageFooterWrapper = csx(
  [
    css`
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: right;
      margin: var(--space-2-rem) var(--space-5-rem) 0 var(--space-1-rem);
      color: var(--text-4);

      .icon {
        margin-left: var(--space-1-rem);
        color: var(--text-3);
      }

      @media (max-width: ${screenDimensions.mobileMax}px) {
        flex-direction: column;
        align-items: flex-end;
      }
    `
  ],
  {
    alignLeft: css`
      justify-content: flex-start;
      margin: var(--space-2-rem) var(--space-1-rem) 0 var(--space-5-rem);
      text-align: left;

      @media (max-width: ${screenDimensions.mobileMax}px) {
        align-items: flex-start;
      }
    `
  }
);

export const TimelineEvent: React.FC<{
  avatar: React.ReactNode;
  footer: React.ReactNode;
  contextMenu?: React.ReactNode;
  alignLeft?: boolean;
  ["data-testid"]?: string;
}> = props => {
  // Do not delete wrapping div! It prevents a layout bug in safari
  return (
    <div>
      <TimelineItem alignLeft={props.alignLeft} data-testid={props["data-testid"]}>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            max-width: 100%;
          `}
        >
          <MessageContentWrapper>
            {props.children}
            <MessageAvatarWrapper alignLeft={props.alignLeft}>{props.avatar}</MessageAvatarWrapper>
            {props.contextMenu && (
              <MessageContextMenuWrapper alignLeft={props.alignLeft} className="event-context-menu">
                {props.contextMenu}
              </MessageContextMenuWrapper>
            )}
          </MessageContentWrapper>
          <MessageFooterWrapper alignLeft={props.alignLeft}>{props.footer}</MessageFooterWrapper>
        </div>
      </TimelineItem>
    </div>
  );
};

/* 

  EXAMPLE USAGE

  <TimelineEvent
      alignLeft={true}
      data-testid="string" 
      avatar={<UserAvatar sizeL user={props.author} />} footer={jsx} contextMenu={jsx}>
    <MessageBox yellow>Text / images</MessageBox>
  </TimelineEvent>

 */

export const EventHighlight = csx(
  [
    css`
      color: var(--text-6);
      font-size: 0.875rem;
      line-height: 1;
      padding: 0.25rem 0.375rem;
      margin: 0 0.125rem;
      background: var(--lightGrey-1);
      border-radius: var(--border-radius-m);
      display: inline-block;
      width: auto;
      min-width: var(--space-5-rem);
    `
  ],
  {}
);
interface Attachment {
  __typename: "Attachment";
  id: string;
  displayName: string;
  size: number;
  deleteTime: ScalarDateTime | null;
}

interface MessageAttachmentListProps {
  attachmentsList: Attachment[];
  documentsList: DocumentFragment[];
  onAttachmentDelete?: (attachmentId: string) => void;
}
/**
 * Shows the a list of attachments and kb docs in a message box (usage inside timeline)
 * @param attachmentsList a list of attachments
 * @param documentsList a list of kb docs
 */
export const MessageAttachmentList: FC<MessageAttachmentListProps> = ({
  attachmentsList,
  documentsList,
  onAttachmentDelete
}) => {
  const attachmentsVisible = !!attachmentsList.length || !!documentsList.some(doc => doc.attachments.length);
  const documentsVisible = !!documentsList.length;
  const numberOfAttachments =
    (attachmentsVisible ? attachmentsList.length : 0) +
    (documentsVisible ? documentsList.length : 0) +
    (documentsVisible ? documentsList.flatMap(doc => doc.attachments).length : 0);

  if (numberOfAttachments === 0) {
    return null;
  }

  return (
    <div
      css={css`
        &:not(:first-child) {
          margin-top: var(--space-2-rem);
        }
      `}
    >
      {(attachmentsVisible || documentsVisible) && numberOfAttachments > 1 && (
        <Typo.Body bold>{numberOfAttachments} Attachments</Typo.Body>
      )}
      {attachmentsVisible && (
        <RequestAttachmentsList
          authToken={null}
          attachmentsList={[
            ...attachmentsList,
            ...documentsList.flatMap(doc =>
              doc.attachments.map(attachment => ({ ...attachment, isKbAttachment: true }))
            )
          ]}
          onAttachmentDelete={onAttachmentDelete}
        />
      )}
      {attachmentsVisible && documentsVisible && (
        <div
          css={css`
            margin: var(--space-2-rem) var(--space-4-rem);
            height: 1px;
            background-color: var(--lightGrey-3);
          `}
        />
      )}
      {documentsVisible && <DocumentsList documentsList={documentsList} />}
    </div>
  );
};

/**
 * Component to show a list of attachments for a comment / note
 * Only renders with non-empty array
 */
export function RequestAttachmentsList(props: {
  attachmentsList: {
    id: string;
    displayName: string;
    size: number;
    isKbAttachment?: boolean;
  }[];
  authToken: string | null;
  isNote?: boolean;
  onAttachmentDelete?: (attachmentId: string) => void;
}) {
  return (
    <ImageViewer imagesList={createImageListFromAttachments(props.attachmentsList, props.authToken)}>
      {dispatch => (
        <MessageBox
          darkGrey={!props.isNote}
          yellow={props.isNote}
          css={[
            css`
              min-width: 10rem;
            `,
            props.isNote &&
              css`
                padding: 0;
              `
          ]}
        >
          <Col
            data-testid="attachments-list"
            css={css`
              & > * + * {
                margin-top: var(--space-2-rem);
              }
            `}
          >
            {props.attachmentsList.map(attachment => (
              <AttachmentItem
                key={attachment.id}
                attachment={attachment}
                authToken={props.authToken}
                imageViewerDispatch={dispatch}
                handleRemove={props.onAttachmentDelete}
              />
            ))}
          </Col>
        </MessageBox>
      )}
    </ImageViewer>
  );
}
