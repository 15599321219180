import { format } from "date-fns";
import * as React from "react";
import { useQuery } from "react-apollo";
import { Button, Col, LoadingBar, Row } from "src/components";
import { Typo } from "src/styling/primitives/typography";
import { useCheckoutOpener } from "./hooks";
import { SUBSCRIPTION } from "./Page";
import { Subscription } from "./typings/Subscription";

export const subscriptionExpiredPath = "/subscription/expired";
export const subscriptionExpiredRedirect = (redirectPath = process.env.REACT_APP_LOGIN_REDIRECT) =>
  redirectPath !== subscriptionExpiredPath
    ? `${subscriptionExpiredPath}?redirect=${redirectPath}`
    : subscriptionExpiredPath;

export function SubscriptionExpired() {
  const [openCheckout, isLoading] = useCheckoutOpener(true);
  const subscriptionResponse = useQuery<Subscription>(SUBSCRIPTION);
  const loading = isLoading || subscriptionResponse.loading;
  return (
    <Row flex="1 0 auto" justify="center">
      <Col margin="4rem 6.25rem">
        {loading && <LoadingBar />}
        {subscriptionResponse.data && subscriptionResponse.data.subscription && (
          <Col width="24rem">
            <Typo.Body sizeXXL>Trial ended</Typo.Body>
            <p>
              Your trial period ended
              {subscriptionResponse.data.subscription.trialEnd &&
                ` on ${format(new Date(subscriptionResponse.data.subscription.trialEnd), "MMMM do")} `}{" "}
              and with no payment method on file we were unable to extend your subscription.
            </p>
            <p>To continue using Back please complete your sign-up and provide your billing details below.</p>
            <Row padding="1.5rem 0" justify="flex-end">
              {!isLoading && (
                <Button
                  variant="primary"
                  onClick={() => {
                    openCheckout();
                  }}
                >
                  Continue using Back
                </Button>
              )}
            </Row>
          </Col>
        )}
      </Col>
    </Row>
  );
}
