import React from "react";
import { slaIs } from "src/App/Requests/DetailView/SLA/utils";
import { formatSlaTime } from "src/App/Settings/SLAs/Overview";
import { Badge } from "src/components/Badges";
import { RequestListFragment_sla, RequestListFragment_slaStatus } from "./typings/RequestListFragment";

export const SlaStatusBadge: React.FC<{
  sla: RequestListFragment_sla;
  slaStatus: RequestListFragment_slaStatus;
}> = props => {
  const remainingTTResponse = props.slaStatus.remainingTimeToFirstResponse?.remainingTimeMinutes ?? Infinity;
  const remainingTTResolution = props.slaStatus.remainingTimeToResolution?.remainingTimeMinutes ?? Infinity;
  // should be false if not defined
  const closeToBreachResponse =
    props.sla.minutesToFirstResponse !== null &&
    slaIs.closeToBreach(
      remainingTTResponse,
      props.slaStatus.remainingTimeToFirstResponse?.ticking ?? false,
      props.sla.minutesToFirstResponse
    );
  const closeToBreachResolution =
    props.sla.minutesToResolution !== null &&
    slaIs.closeToBreach(
      remainingTTResolution,
      props.slaStatus.remainingTimeToResolution?.ticking ?? false,
      props.sla.minutesToResolution
    );
  if (
    slaIs.breachedAndIncomplete(remainingTTResponse, props.slaStatus.remainingTimeToFirstResponse?.ticking ?? false) ||
    slaIs.breachedAndIncomplete(remainingTTResolution, props.slaStatus.remainingTimeToResolution?.ticking ?? false)
  ) {
    return (
      <Badge data-intercom-target="SLA badge - breach" data-testid="sla-status-badge" warning>
        BREACHED SLA
      </Badge>
    );
  }
  // need to check close to breach and it's the closest (unless the other is undefined or not close to breach)
  if (closeToBreachResponse && (!closeToBreachResolution || remainingTTResponse <= remainingTTResolution)) {
    if (remainingTTResponse === 0) {
      return (
        <Badge data-intercom-target="SLA badge - warning" data-testid="sla-status-badge" neutral>
          Reply now
        </Badge>
      );
    }
    return (
      <Badge data-intercom-target="SLA badge - warning" data-testid="sla-status-badge" neutral>
        Reply within {formatSlaTime(remainingTTResponse)}
      </Badge>
    );
  }
  if (closeToBreachResolution && (!closeToBreachResponse || remainingTTResolution <= remainingTTResponse)) {
    if (remainingTTResolution === 0) {
      return (
        <Badge data-intercom-target="SLA badge - warning" data-testid="sla-status-badge" neutral>
          Resolve now
        </Badge>
      );
    }
    return (
      <Badge data-intercom-target="SLA badge - warning" data-testid="sla-status-badge" neutral>
        Resolve within {formatSlaTime(remainingTTResolution)}
      </Badge>
    );
  }
  return null;
};
