import { css } from "@emotion/core";
import * as Sentry from "@sentry/browser";
import { EmojiConvertor } from "emoji-js";
import React, { useEffect, useState } from "react";
import { FeatureFlags, useFeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import { csx } from "src/util/csx";
import { backMarkdownToHtml, formatMessage } from "src/util/formatters";

const Text = csx(
  [
    css`
      & a {
        border-radius: var(--border-radius-s);
        color: var(--text-6);
        text-decoration: underline;
      }

      & a:hover {
        background-color: rgba(var(--text-6-raw), 0.075);
      }

      & .code,
      & pre {
        background-color: var(--hover);
        border: 1px solid var(--border);
        border-radius: 2px;
        font-family: Consolas, Monaco, Courier, monospace !important;
        font-size: 0.75rem;
        white-space: pre-wrap;
        word-break: break-all;
        padding: var(--space-2-rem);
      }

      & pre {
        margin: 0;
      }
    `
  ],
  {},
  "span"
);

export const MessageText = (props: { text: string }) => {
  const { hasFeatureFlags } = useFeatureFlags();
  return hasFeatureFlags(FeatureFlags.RICHTEXTREPLIES) ? (
    <RichMessageText text={props.text} />
  ) : (
    <LegacyMessageText text={props.text} />
  );
};

// Render legacy back-flavor-slack-markdown
const LegacyMessageText = (props: { text: string }) => {
  const [dangerousHTML, setHTML] = useState<string | undefined>();
  useEffect(() => {
    formatMessage(props.text).then(setHTML).catch(Sentry.captureException);
  }, [props.text, setHTML]);
  return !dangerousHTML ? null : (
    <Text
      data-testid="formatted-message-text"
      dangerouslySetInnerHTML={{
        /* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
         * !! ATTENTION: Make sure that whatever you set as the __html value of this object is properly
         * !! ========== sanitized, i.e. that there is no way to inject <script> tags or other potentially
         * !!            dangerous HTML into the DOM.
         * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
        __html: dangerousHTML
      }}
    />
  );
};

// render e.g [@bob] as a "nice" mention ui element
const replaceMentions = (html: string) => {
  return html
    .replace(/\[@(.+)\]/g, (match, name) => `<a href="#mention" class="mention">@${name}</a>`)
    .replace(/\[\+(.+)\]/g, (match, name) => `<a href="#mention" class="mention">+${name}</a>`);
};

const emojiConvertor = new EmojiConvertor();
emojiConvertor.init_env();
emojiConvertor.replace_mode = "unified";
emojiConvertor.allow_native = true;

// Render back-flavor-markdown message
export const RichMessageText = (props: { text: string }) => {
  return !props.text ? null : (
    <Text
      data-testid="formatted-message-text"
      dangerouslySetInnerHTML={{
        /* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
         * !! ATTENTION: Make sure that whatever you set as the __html value of this object is properly
         * !! ========== sanitized, i.e. that there is no way to inject <script> tags or other potentially
         * !!            dangerous HTML into the DOM.
         * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
        __html: emojiConvertor.replace_colons(replaceMentions(backMarkdownToHtml(props.text)))
      }}
    />
  );
};
