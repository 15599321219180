import styled from "@emotion/styled";
import { Col } from "src/components";
import { paddings } from "src/styling/layout";

export const AuthWrapper = styled(Col)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  padding: ${paddings.dialog.combined};
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  background: rgba(var(--lightGrey-3-raw), 0.8);
`;
