import { css } from "@emotion/core";
import { mdiPlus } from "@mdi/js";
import { compareDesc, format } from "date-fns";
import gql from "graphql-tag";
import React, { PropsWithChildren } from "react";
import { useQuery } from "react-apollo";
import { Button, Col, EmptyState, LoadingBar, Row } from "src/components";
import { MaterialIcon } from "src/components/Icons";
import { justify, row, spacing } from "src/styling/primitives";
import { Card } from "src/styling/primitives/Card";
import { Typo } from "src/styling/primitives/typography";
import { csx } from "src/util/csx";
import { FormAction, FormState } from "./reducer";
import { SchemaList, SchemaListVariables, SchemaList_schemaList } from "./typings/SchemaList";

export const formCardButton = css`
  display: flex;
  align-items: center;
  margin-bottom: var(--space-4-rem);
`;

const selectFormSchemaButton = css`
  /* for old Edge TODO: remove when possible*/
  &:not(:hover),
  &:not(:focus),
  &:not(:focus-within) {
    & button {
      visibility: hidden;
    }
  }

  &:hover,
  &:focus,
  &:focus-within {
    & button {
      visibility: visible;
    }
  }

  & > ${Col} {
    text-align: left;
  }
`;

const FormTitle = csx(
  [
    css`
      color: var(--text-6);
      font-weight: var(--font-weight-body-medium);
      line-height: var(--line-height-normal);
    `
  ],
  {
    large: css`
      font-size: var(--font-size-heading);
    `
  }
);

/* pass actions as children */
export function SelectFormSchema(
  props: PropsWithChildren<{
    schema: SchemaList_schemaList;
    large?: boolean;
    selected?: boolean;
    onClick?(e: React.MouseEvent): void;
  }>
) {
  return (
    <Card
      sizeM
      sizeL={props.large}
      onClick={props.onClick}
      hasInteractions={!!props.onClick}
      data-testid="form-item-loaded"
      css={[
        formCardButton,
        selectFormSchemaButton,
        props.selected &&
          css`
            &,
            &:hover {
              border-color: var(--blue-2);
            }
          `
      ]}
    >
      <Col flex="1 1 auto">
        <Row
          css={css`
            margin: 0 0 var(--space-1-rem);
          `}
        >
          <FormTitle large={props.large}>{props.schema.displayName}</FormTitle>
        </Row>
        <Typo.Body light>
          {props.large && (
            <>
              <span
                css={css`
                  font-weight: var(--font-weight-body-medium);
                `}
              >
                Last updated:
              </span>{" "}
              {format(new Date(props.schema.updateTime), "d MMM yyyy")}&nbsp;&nbsp;•&nbsp;&nbsp;
            </>
          )}
          {props.schema.fieldsList.length} questions
        </Typo.Body>
      </Col>
      <div css={[row, justify.end, spacing.ml1]}>{props.children}</div>
    </Card>
  );
}

export const SCHEMA_LIST = gql`
  query SchemaList($teamId: ID) {
    schemaList(teamId: $teamId) {
      id
      displayName
      description
      createTime
      updateTime
      deleteTime
      revision
      fieldsList {
        ... on TextField {
          __typename
          textFieldType
          displayName
          required
        }
        ... on DropdownField {
          __typename
          displayName
          optionsList
          required
        }
        ... on AttachmentsField {
          __typename
          displayName
          required
        }
      }
    }
  }
`;

export const AddFormButton: React.FC<{ onClick(): void }> = props => (
  <Button variant="secondary" onClick={props.onClick}>
    <MaterialIcon path={mdiPlus} size={1.125} margin="0 0.25rem 0 0" />
    <span>Add form</span>
  </Button>
);

export function ChooseForm(props: {
  teamId: string;
  teamName: string;
  formState: FormState;
  formDispatch: React.Dispatch<FormAction>;
  setModal(modal: "create" | "edit" | null): void;
}) {
  const response = useQuery<SchemaList, SchemaListVariables>(SCHEMA_LIST, {
    variables: {
      teamId: props.teamId
    }
  });
  return (
    <>
      {response.loading && <LoadingBar />}
      {response.data && response.data.schemaList.length === 0 && (
        <EmptyState
          title="No forms for this team"
          subtitle="Forms allow you to send a set of predefined questions to requesters."
          css={css`
            padding: 0;
          `}
        >
          <AddFormButton onClick={() => props.setModal("create")} />
        </EmptyState>
      )}
      {response.data && response.data.schemaList.length > 0 && (
        <div>
          <Col
            css={css`
              margin-bottom: var(--space-7-rem);
            `}
          >
            <Typo.Body sizeXXL>Select a form</Typo.Body>
          </Col>
          {response.data.schemaList
            .sort((a, b) => compareDesc(new Date(a.createTime), new Date(b.createTime)))
            .map((schema, i) => {
              const selected = props.formState.formSchemaToSend?.id === schema.id;
              return (
                <SelectFormSchema key={i} selected={selected} schema={schema}>
                  <Button
                    onClick={e => {
                      e.stopPropagation();
                      props.formDispatch({
                        type: "PREVIEW_SCHEMA",
                        formSchemaToPreview: schema
                      });
                    }}
                  >
                    Preview
                  </Button>
                  {!selected && (
                    <Button
                      onClick={e => {
                        e.stopPropagation();
                        props.formDispatch({
                          type: "CHOOSE_SCHEMA",
                          formSchemaToSend: schema
                        });
                      }}
                    >
                      Use
                    </Button>
                  )}
                </SelectFormSchema>
              );
            })}
          <div>
            <AddFormButton onClick={() => props.setModal("create")} />
          </div>
        </div>
      )}
    </>
  );
}
