import { OrderByKey, RequestListView } from "src/globalTypes";
import { trackFilterApplied, trackFiltersCleared, trackSortingApplied } from "src/util/analytics";
import { navigate, WindowLocation } from "src/util/router";

type ParamAction =
  | {
      type: "navigate_to_page";
      pageNumber: number;
    }
  | {
      type: "sort";
      orderByKey: OrderByKey;
      analytics: {
        listViewType: RequestListView;
      };
    }
  | {
      type: "set_filter";
      filter: keyof Filters;
      values: string[];
      analytics: {
        listViewType: RequestListView;
      };
    }
  | {
      type: "clear_filter";
      filter: keyof Filters;
      analytics: {
        listViewType: RequestListView;
      };
    }
  | {
      type: "clear_all_filters";
      analytics: {
        listViewType: RequestListView;
      };
    };

/**
 * Centralized request list url actions
 * @param action
 */
export function setRequestListURLParam(action: ParamAction): true {
  switch (action.type) {
    case "navigate_to_page": {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("page", String(action.pageNumber));
      navigate(`${window.location.pathname}?${searchParams.toString()}`);
      return true;
    }
    case "sort": {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("sort", action.orderByKey);
      // reset page
      searchParams.delete("page");
      navigate(`${window.location.pathname}?${searchParams.toString()}`, { replace: true });
      trackSortingApplied(action.analytics.listViewType, action.orderByKey);
      return true;
    }
    case "set_filter": {
      const searchParams = new URLSearchParams(window.location.search);
      const filters = searchParams.get("filters");
      const filterParams = new URLSearchParams(filters ?? "");
      filterParams.delete(action.filter);
      for (const value of action.values) filterParams.append(action.filter, value);
      if (filterParams.toString() === "") {
        searchParams.delete("filters");
      } else {
        searchParams.set("filters", filterParams.toString());
      }
      navigate(`${window.location.pathname}?${searchParams.toString()}`, { replace: true });
      trackFilterApplied(action.analytics.listViewType, action.filter, action.values);
      return true;
    }
    case "clear_filter": {
      const searchParams = new URLSearchParams(window.location.search);
      const filters = searchParams.get("filters");
      const filterParams = new URLSearchParams(filters ?? "");
      filterParams.delete(action.filter);
      if (filterParams.toString() === "") {
        searchParams.delete("filters");
      } else {
        searchParams.set("filters", filterParams.toString());
      }
      navigate(`${window.location.pathname}?${searchParams.toString()}`, { replace: true });
      trackFilterApplied(action.analytics.listViewType, action.filter, []);
      return true;
    }
    case "clear_all_filters": {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete("filters");
      navigate(`${window.location.pathname}?${searchParams.toString()}`, { replace: true });
      trackFiltersCleared(action.analytics.listViewType);
      return true;
    }
  }
}

type UUID = string;
export interface Filters {
  type: "request" | "project";
  team: "-" | UUID;
  assignee: "_unset" | "me" | UUID;
  status: "0" | "1" | "2" | "3" | "4";
  customStatus: UUID;
  category: "_unset" | UUID;
  requester: "me" | UUID;
  priority: "_unset" | "0" | "1" | "2" | "3";
  fts: string;
  topic: UUID;
}

export type FilterMap = {
  [k in keyof Filters]?: Array<Filters[k]>;
};

const filterKeys: (keyof Filters)[] = [
  "type",
  "team",
  "assignee",
  "status",
  "category",
  "customStatus",
  "requester",
  "priority",
  "fts"
];

export const emptyFilterMap: Required<FilterMap> = {
  type: [],
  team: [],
  assignee: [],
  status: [],
  category: [],
  customStatus: [],
  requester: [],
  priority: [],
  fts: [],
  topic: []
};

export function deserializeFilters(filters: string): FilterMap {
  const params = new URLSearchParams(filters);
  let filterMap: FilterMap = {};
  for (const filter of filterKeys) {
    // for some reason this passes the typechecker (filterMap[filter] = ... doesn't)
    filterMap = {
      ...filterMap,
      [filter]: params.getAll(filter)
    };
  }
  return filterMap;
}

export function deserializeUrlParams(
  location: WindowLocation,
  defaultParams?: {
    filters: string;
    orderBy: OrderByKey;
  }
) {
  const params = new URLSearchParams(location.search);
  const page = params.get("page");
  let pageNumber = 1;
  if (page && !Number.isNaN(parseInt(page, 10))) {
    pageNumber = parseInt(page, 10);
  }
  const sort = params.get("sort");
  let orderByKey = defaultParams?.orderBy ?? OrderByKey.isUnread;
  if (sort && Object.keys(OrderByKey).includes(sort)) {
    orderByKey = sort as OrderByKey;
  }

  const filters = params.get("filters");
  const filtersFromView = deserializeFilters(defaultParams?.filters ?? "");
  const filtersFromUrl = deserializeFilters(filters ?? "");

  return {
    filtersFromUrl,
    filtersFromView,
    pageNumber,
    orderByKey
  };
}
