import { css } from "@emotion/core";
import gql from "graphql-tag";
import React from "react";
import { useMutation, useQuery } from "react-apollo";
import { USER_GET } from "src/App/User";
import { GUserGet, GUserGetVariables } from "src/App/User/typings/GUserGet";
import { ConfirmDialogModal, LoadingBar } from "src/components";
import { Toast } from "src/portals/Toast";
import { Typo } from "src/styling/primitives/typography";
import { EXPERT_LIST_GET } from "./Overview";
import { UserUnregister, UserUnregisterVariables } from "./typings/UserUnregister";

const USER_UNREGISTER = gql`
  mutation UserUnregister($params: UserUnregisterParams!) {
    userUnregister(params: $params) {
      code
      success
      message
    }
  }
`;

export function RemoveUser(props: { userId: string; onFinish(): void }) {
  const userResponse = useQuery<GUserGet, GUserGetVariables>(USER_GET, { variables: { id: props.userId } });
  const [unregisterUser, unregisterUserResponse] = useMutation<UserUnregister, UserUnregisterVariables>(
    USER_UNREGISTER,
    {
      refetchQueries: [
        {
          query: EXPERT_LIST_GET
        }
      ]
    }
  );
  return (
    <>
      {unregisterUserResponse.data && unregisterUserResponse.data.userUnregister.success === false && (
        <Toast kind="mutationError" message={unregisterUserResponse.data.userUnregister.message} />
      )}
      {(userResponse.loading || unregisterUserResponse.loading) && <LoadingBar />}
      <ConfirmDialogModal
        isOpen={!!userResponse.data}
        text={{ heading: "Remove user", confirm: "Remove", cancel: "Cancel" }}
        handleCancel={props.onFinish}
        handleConfirm={() => {
          unregisterUser({
            variables: {
              params: {
                userId: props.userId
              }
            },
            update: (cache, response) => {
              if (response.data && response.data.userUnregister.success) {
                props.onFinish();
              }
            }
          });
        }}
        submittingConfirm={unregisterUserResponse.loading}
      >
        <div
          css={css`
            max-width: 28rem;

            & p {
              word-wrap: break-word;
            }
          `}
          data-testid="remove-user-modal-loaded"
        >
          {userResponse.data && (
            <Typo.Body>
              Are you sure you want to permanently remove
              <b>
                {userResponse.data.user.name !== ""
                  ? ` ${userResponse.data.user.name} (${userResponse.data.user.email})`
                  : userResponse.data.user.email}
              </b>{" "}
              from Back?
            </Typo.Body>
          )}
          <Typo.Body>
            This user will be <b>immediately</b> logged out from all of their sessions and will need to be invited again
            to get access to Back.
          </Typo.Body>
        </div>
      </ConfirmDialogModal>
    </>
  );
}
