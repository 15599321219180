import * as React from "react";

import { FC, Fragment, ReactElement, useState } from "react";
import { FeatureFlags, useFeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import {
  Location,
  LocationContext,
  Redirect,
  RedirectProps,
  RouteComponentProps,
  Router,
  navigate
} from "src/util/router";
import { NotificationSetError, NotificationSetSlack } from "src/App/Confirmation/Notification/Pages";
import { SubscriptionExpired, subscriptionExpiredPath } from "src/App/Settings/Subscription/Expired";
import { WorkflowsOverviewPage, WorkflowsRedirectRoutingComponent } from "src/App//Settings/Workflows/Overview";

import { BestPracticeDetail } from "src/App/BestPractices/DetailView";
import { BestPracticeGallery } from "src/App//BestPractices/BestPracticeGallery";
import { DelayNavContext } from "src/hooks/useWindowEvents";
import { ExternalFormPage } from "src/App/Forms/Employee/ExternalPage";
import { FinishPasswordRecoveryContainer } from "src/App/Auth/PasswordReset/Complete/Container";
import { HelmetComponent } from "src/App/Root/HelmetComponent";
import { HotKeysOverview } from "src/App/Settings/Hotkeys/Overview";
import { IntegrationOverview } from "src/App/Settings/Integrations/Overview";
import { KBSection } from "src/App/KB/Section";
import { default as Loadable } from "react-loadable";
import { LoadingBar } from "src/components";
import { Modal } from "src/portals/Modal";
import { NotificationSettingsPage } from "src/App/Settings/Notifications/Page";
import { OutreachConversationsList } from "src/App/Outreach/Overview";
import { PageNotFound } from "src/components/PageNotFound";
import { ProcessApprovalPage } from "src/App/Approvals/ProcessApprovalPage";
import { ProjectsDetailPage } from "src/App/Projects/DetailPage";
import { ProjectsListPage } from "src/App/Projects/ListPage";
import { RequestStatusesOverview } from "src/App/Settings/RequestStatuses/Overview";
import { SLAsOverview } from "src/App/Settings/SLAs/Overview";
import { SavedRepliesPage } from "src/App/SavedReplies/Page";
import { SchemaListPage } from "src/App/Forms/Manager/List";
import { SearchRequestsContainer } from "src/App/Requests/Search/Container";
import { SidebarWithHelpBubble } from "src/App/Root/SidebarWithHelpBubble";
import { StartPasswordRecoveryContainer } from "src/App/Auth/PasswordReset/Request/Container";
import { SubscriptionPage } from "src/App/Settings/Subscription/Page";
import { TeamOverview } from "src/App/Settings/Teams/Overview";
import { UserInvite } from "src/App/Settings/Users/UserInvite";
import { UserLoginPage } from "src/App/Auth/Login/Page";
import { UserOverview } from "src/App/Settings/Users/Overview";
import { ViewContainer } from "src/App/Requests/ListView/ViewContainer";
import { WorkflowDetailPage } from "src/App/Settings/Workflows/DetailView/WorkflowDetail";
import { routeData } from "src/App/Root/RouteData";

/**
 * Load separate code bundles for these components
 */
const AsyncRegistration = Loadable({
  loader: () => import("src/App/Onboarding/Registration"),
  render: ({ Registration }, { step }: { step: "org" | "team" }) => <Registration step={step} />,
  loading: () => <LoadingBar />
});

const AsyncUserRegister = Loadable({
  loader: () =>
    import("src/App/Auth/Register/Container").then(m => Promise.resolve(m.UserRegisterContainer)) as Promise<
      React.ComponentType<RouteComponentProps>
    >,
  loading: () => <LoadingBar />
});

const AsyncUserUpdatePassword = Loadable({
  loader: () =>
    import("src/App/Auth/UpdatePassword/Container").then(m =>
      Promise.resolve(m.UserUpdatePasswordContainer)
    ) as Promise<React.ComponentType<RouteComponentProps>>,
  loading: () => <LoadingBar />
});

const AsyncDetailView = Loadable({
  loader: () =>
    import("src/App/Requests/DetailView/Container").then(m => Promise.resolve(m.RequestDetailPage)) as Promise<
      React.ComponentType<RouteComponentProps>
    >,
  loading: () => <LoadingBar />
});

const AsyncRequestCreateDialog = Loadable({
  loader: () =>
    import("src/App/Requests/CreateView/RequestCreateDialog").then(m =>
      Promise.resolve(m.RequestCreateDialog)
    ) as Promise<React.ComponentType<RouteComponentProps & { context: LocationContext; project?: boolean }>>,
  loading: () => <LoadingBar />
});

const AsyncDHLView = Loadable({
  loader: () =>
    import("src/App/Requests/DHLView/Container").then(m => Promise.resolve(m.DHLPage)) as Promise<
      React.ComponentType<RouteComponentProps>
    >,
  loading: () => <LoadingBar />
});

const AsyncAnalytics = Loadable({
  loader: () =>
    import("src/App/Analytics/Container").then(m => Promise.resolve(m.AnalyticsPage)) as Promise<
      React.ComponentType<RouteComponentProps>
    >,
  loading: () => <LoadingBar />
});

const AsyncTeamInsights = Loadable({
  loader: () =>
    import("src/App/Dashboard/Container").then(m => Promise.resolve(m.DashboardContainer)) as Promise<
      React.ComponentType<RouteComponentProps>
    >,
  loading: () => <LoadingBar />
});

const UserInviteModal = (props: { isOpen: boolean }) => (
  <Modal isOpen={props.isOpen} onDismiss={() => navigate("/settings/users")}>
    <UserInvite onClose={() => navigate("/settings/users")} />
  </Modal>
);

const RedirectKeepingQueryParams: FC<RedirectProps<{}>> = props => {
  const fromQueryParams = new URLSearchParams(window.location.search);
  // Handle the case where the "to" of the redirection also has some query parameters
  const [toPath, toQueryParamsStr] = props.to.split("?");
  const toQueryParams = new URLSearchParams(toQueryParamsStr);
  fromQueryParams.forEach((value, key) => {
    toQueryParams.append(key, value);
  });

  return <Redirect {...props} to={toPath + "?" + toQueryParams.toString()} />;
};

export const Routes = () => {
  /* delay nav using useWarnBeforeUnload in case of pending requests */
  const [shouldDelayNav, setShouldDelayNav] = useState(false);
  return (
    <DelayNavContext.Provider value={{ setShouldDelayNav, shouldDelayNav }}>
      <Location>{context => <CustomRouter context={context} shouldDelayNav={shouldDelayNav} />}</Location>
    </DelayNavContext.Provider>
  );
};

const RedirectWithoutFF: FC<{ ff: FeatureFlags; path: string; node: ReactElement; to?: string }> = ({
  ff,
  path,
  node,
  to
}) => {
  const { hasFeatureFlags, isContextInitialised } = useFeatureFlags();
  if (!isContextInitialised) return <LoadingBar />;
  else if (isContextInitialised && !hasFeatureFlags(ff)) return <Redirect from={path} to={to ?? "/"} noThrow={true} />;
  else return node;
};

export const CustomRouter = React.memo(
  (props: { context: LocationContext; shouldDelayNav: boolean }) => {
    const { context } = props;

    return (
      <>
        <Router location={context.location}>
          {Object.entries(routeData).map(([name, route]) => (
            <Fragment key={name}>
              <HelmetComponent path={`/${route.pathname}/*`} title={route.title} description={route.description} />
              {route.subpaths &&
                Object.entries(route.subpaths).map(([subrouteName, subroute]) => (
                  <HelmetComponent
                    key={subrouteName}
                    path={`/${route.pathname}/${subroute.pathname}/*`}
                    title={subroute.title}
                    description={subroute.description}
                  />
                ))}
            </Fragment>
          ))}
        </Router>

        <Router id="sidebar" location={context.location} className="print-hidden">
          <SidebarWithHelpBubble path={`/${routeData.requests.pathname}/*`} />
          <SidebarWithHelpBubble path={`/${routeData.teamInsights.pathname}/*`} />
          <SidebarWithHelpBubble path={`/${routeData.request.pathname}/*`} />
          <SidebarWithHelpBubble path={`/${routeData.projects.pathname}/*`} />
          <SidebarWithHelpBubble path={`/${routeData.outreach.pathname}/*`} />
          <SidebarWithHelpBubble path={`/${routeData.knowledge.pathname}/*`} />
          <SidebarWithHelpBubble path={`/${routeData.search.pathname}/*`} />
          <SidebarWithHelpBubble path={`/${routeData.settings.pathname}/*`} />
          <SidebarWithHelpBubble path={`/${routeData.analytics.pathname}/*`} />
          <SidebarWithHelpBubble path={`/${routeData.integrations.pathname}/*`} />
          <SidebarWithHelpBubble path={`/${routeData.forms.pathname}/*`} />
          <SidebarWithHelpBubble path={`/${routeData.bestPractices.pathname}/*`} />
          <SidebarWithHelpBubble path={`/${routeData.workflows.pathname}/*`} />
          <SidebarWithHelpBubble path={`/${routeData.workflow.pathname}/*`} />
          <SidebarWithHelpBubble path="/slack/*" />
          <SidebarWithHelpBubble path="/subscription/*" />
        </Router>
        <Router id="content" location={context.location}>
          <Redirect from="/" to={`/${routeData.requests.pathname}/me`} noThrow={true} />
          <RedirectKeepingQueryParams path="/register/step/*" to="/register" noThrow={true} />
          <AsyncRegistration path="/register" step="org" />
          <AsyncRegistration path="/register/team" step="team" />
          <UserLoginPage path="/login" />
          {/* TODO: coordinate with backend to remove */}
          <Redirect from="/invited/:invitation_token" to="/invitation/:invitation_token" />
          <AsyncUserRegister path="/invitation/:invitation_token" />
          <AsyncUserUpdatePassword path="/account/update" />
          <StartPasswordRecoveryContainer path="/password/reset" />
          <FinishPasswordRecoveryContainer path="/password/new" />
          <RedirectWithoutFF
            ff={FeatureFlags.CUSTOMREQUESTSTATUS}
            path={`/${routeData.settings.pathname}/statuses`}
            node={<RequestStatusesOverview />}
          />
          <WorkflowsOverviewPage path={`/${routeData.workflows.pathname}/*`} />
          {/*  Handles the legacy routing from WF gallery with eg '/workflow/:id'  */}
          <WorkflowsRedirectRoutingComponent path={`/${routeData.workflow.pathname}/:workflow_id`} />

          <RedirectWithoutFF
            ff={FeatureFlags.WORKFLOWBUILDER}
            /* Redirect to wf gallery instead */
            to={`/${routeData.workflow.pathname}/:workflow_id`}
            /*  This route uses "workflowS" as the redirect uses the "workflow" 😒 */
            path={`/${routeData.workflows.pathname}/:team_slug/:workflow_id`}
            node={<WorkflowDetailPage />}
          />

          <RedirectWithoutFF
            ff={FeatureFlags.SLA}
            path={`/${routeData.settings.pathname}/slas`}
            node={<SLAsOverview />}
          />
          <TeamOverview path={`/${routeData.settings.pathname}/teams`} />
          <TeamOverview path={`/${routeData.settings.pathname}/teams/create/step/:step`} modal="create" />
          <TeamOverview path={`/${routeData.settings.pathname}/teams/create/step/:step/:team_id`} modal="create" />
          <TeamOverview path={`/${routeData.settings.pathname}/teams/:team_id/edit`} modal="edit" />
          <TeamOverview path={`/${routeData.settings.pathname}/teams/:team_id/manage`} modal="manage" />
          <UserOverview path={`/${routeData.settings.pathname}/users`} />
          <HotKeysOverview path={`/${routeData.settings.pathname}/keyboard-shortcuts`} />
          <NotificationSettingsPage path={`/${routeData.settings.pathname}/notifications`} />
          <IntegrationOverview path={`/${routeData.settings.pathname}/integrations`} />
          <IntegrationOverview
            path={`/${routeData.settings.pathname}/integrations/msteams/install`}
            integration="msteams"
            action="install"
          />
          <IntegrationOverview
            path={`/${routeData.settings.pathname}/integrations/msteams/unlink`}
            integration="msteams"
            action="manage"
          />
          <IntegrationOverview
            path={`/${routeData.settings.pathname}/integrations/msteams/gchat`}
            integration="googlechat"
            action="install"
          />
          <IntegrationOverview
            path={`/${routeData.settings.pathname}/integrations/sharepoint/authorized`}
            integration="sharepoint"
            action="confirmation"
          />
          <SavedRepliesPage path={`/${routeData.settings.pathname}/saved-replies/*`} />
          <IntegrationOverview path="/slack/install" integration="slack" action="install" />
          <IntegrationOverview path="/slack/confirmation" integration="slack" action="confirmation" />
          <IntegrationOverview
            path={`/${routeData.settings.pathname}/integrations/bamboohr/install`}
            integration="bamboohr"
            action="install"
          />
          <IntegrationOverview
            path={`/${routeData.settings.pathname}/integrations/bamboohr/confirmation`}
            integration="bamboohr"
            action="confirmation"
          />
          <IntegrationOverview
            path={`/${routeData.settings.pathname}/integrations/notion`}
            integration="notion"
            action="install"
          />
          <IntegrationOverview
            path={`/${routeData.settings.pathname}/integrations/notion/authorized`}
            integration="notion"
            action="confirmation"
          />
          <KBSection path={`/${routeData.knowledge.pathname}/*`} />
          <Redirect
            from={`/${routeData.insights.pathname}`}
            to={`/${routeData.analytics.pathname}/me`}
            noThrow={true}
          />
          <AsyncAnalytics path={`/${routeData.analytics.pathname}/*`} />
          <AsyncTeamInsights path={`/${routeData.teamInsights.pathname}`} />
          <Redirect from={`/${routeData.requests.pathname}/*`} to="/view/me" noThrow={true} />
          <ViewContainer path={`/${routeData.requests.pathname}/:view_id`} />
          <SearchRequestsContainer path={`/${routeData.search.pathname}`} />
          <AsyncDetailView path={`/${routeData.request.pathname}/:request_id`} />
          <AsyncDHLView path={`/${routeData.dhl.pathname}/:request_id`} />
          <ProjectsListPage path={`/${routeData.projects.pathname}`} />
          <ProjectsDetailPage path={`/${routeData.projects.pathname}/:project_id`} context={context} />
          <OutreachConversationsList path={`/${routeData.outreach.pathname}/*`} />
          <SchemaListPage path={`/${routeData.forms.pathname}/*`} />
          <BestPracticeGallery path={`/${routeData.bestPractices.pathname}`} />
          <BestPracticeDetail path={`/${routeData.bestPractices.pathname}/:best_practice_id`} />
          <ExternalFormPage path={`/${routeData.formResponse.pathname}/:form_id`} />
          <ProcessApprovalPage path={`/${routeData.approval.pathname}/:approval_id/accept`} />
          <ProcessApprovalPage path={`/${routeData.approval.pathname}/:approval_id/reject`} />
          <ProcessApprovalPage path={`/${routeData.approval.pathname}/:approval_id`} />
          <NotificationSetSlack path="/confirmation/notification-setting-slack" />
          <NotificationSetError path="/confirmation/notification-setting-error" />
          <SubscriptionPage path={`/${routeData.settings.pathname}/subscription`} />
          <SubscriptionExpired path={subscriptionExpiredPath} />
          <PageNotFound default={true} />

          {/* LEGACY REDIRECTS - for old bookmarks */}
          <Redirect
            from={`/${routeData.settings.pathname}`}
            to={`/${routeData.settings.pathname}/teams`}
            noThrow={true}
          />
          <Redirect
            from={`/${routeData.settings.pathname}/teams/create`}
            to={`/${routeData.settings.pathname}/teams/create/step/1`}
            noThrow={true}
          />
          <Redirect
            from="/requests/me/:request_id/detail"
            to={`/${routeData.request.pathname}/:request_id`}
            noThrow={true}
          />
          <Redirect
            from="/requests/team/:request_id/detail"
            to={`/${routeData.request.pathname}/:request_id`}
            noThrow={true}
          />
          <Redirect
            from="/requests/*"
            to={`/${routeData.requests.pathname}/${routeData.requests.subpaths?.me.pathname}`}
            noThrow={true}
          />
        </Router>
        {context.location.hash.toLowerCase().includes("#/new-request") && (
          <AsyncRequestCreateDialog context={context} />
        )}
        {context.location.hash.toLowerCase().includes("#/project-new-request") && (
          <AsyncRequestCreateDialog context={context} />
        )}
        <UserInviteModal isOpen={context.location.hash.toLowerCase().includes("#/user-invite")} />
      </>
    );
  },
  (_prev, props) => {
    // don't render new location if shouldDelayNav
    return props.shouldDelayNav;
  }
);

/** Legacy helper fns for routing --> */

/**
 * Show a modal by appending the /#/new-request hash to the current URL.
 *
 * @param context The current LocationContext
 */
export function showModal(context: LocationContext, hash = "#/new-request") {
  const url = window.location.href.split("#")[0];
  // According to RFC 3986, Section 4.2 (https://tools.ietf.org/html/rfc3986#section-4.2),
  // URI hashes (fragments) should be placed after query parameters. Therefore, we only add
  // a trailing slash to the end of the original URL, if it doesn't contain any question
  // mark, i.e. query params.
  //
  // The trailing slash before the hash character is just there for aesthetic reasons. It
  // shouldn't be appended to query parameters, as that would most likely break the last
  // parameter value.
  if (url.indexOf("?") === -1) {
    const urlWithTrailingSlash = url + (url.substr(-1) === "/" ? "" : "/");
    context.navigate(`${urlWithTrailingSlash}${hash}`);
  } else {
    context.navigate(`${url}${hash}`);
  }
}

/**
 * Hide a modal dialog (if it's open) by navigating to the current URL, but
 * without the URL hash that triggers the display of the modal.
 *
 * @param context The router's current LocationContext object
 */
export function hideModal(context: LocationContext) {
  context.navigate(context.location.href.split("#")[0].replace(/\/$/, ""));
}

/** <-- Legacy helper fns for routing */
