import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { popMenu } from "src/styling/effects";
import { body } from "src/styling/primitives";
import { csx } from "src/util/csx";
import { ICommonStyleProps } from ".";

export const Menu = styled.div<ICommonStyleProps>`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  height: ${p => p.height || "auto"};
  max-height: ${p => p.maxHeight || "95vh"};
  margin: ${p => p.margin || "0.25rem 0 0 0"};
  padding: ${p => p.padding || "0.625rem 0"};
  background-color: var(--white);
  ${popMenu.shadow}
  border-radius: var(--border-radius-m);
  overflow-x: hidden;
  overflow-y: auto;
`;

const menuItem = css`
  display: flex;
  flex: 0 0 auto;
  justify-content: normal;
  align-content: normal;
  align-items: center;
  align-self: normal;
  margin: 0;
  line-height: 1.5;
  padding: 0.375rem 1rem;
  color: var(--text-6);
  cursor: pointer;
  font-size: 0.875rem;

  &:hover {
    background: var(--lightGrey-2);
  }
`;

export const MenuItem = csx([menuItem], {
  heading: css`
    ${body.bold}
    cursor: default;

    &:hover {
      background: unset;
    }
  `,
  last: css`
    padding-bottom: 0.625rem;
  `
});

export const MenuItemAnchor = csx(
  [
    menuItem,
    css`
      text-decoration: none;
    `
  ],
  {},
  "a"
);

export const MenuHint = styled.div`
  max-width: 20rem;
  color: var(--text-4);
  font-size: 0.875rem;
  font-style: italic;
  padding: 0 1rem;
`;
