import { css } from "@emotion/core";
import { mdiAccount, mdiAccountMultiple, mdiCircle, mdiPlus } from "@mdi/js";
import React, { FC, useMemo, useState } from "react";
import { FilterButton, MaterialIcon, SimpleTooltip, SquareButton } from "src/components";
import { FilterBy } from "src/components/Filter";
import { OptionMap, PopOver } from "src/components/PopOver";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { navigate, useLocation } from "src/util/router";
import { OutreachConversationList_conversationList_conversations } from "./typings/OutreachConversationList";

interface FilterValues {
  team: string;
  author: string;
  status: "Sent" | "Replied";
  recipient: string;
}

type FilterId = keyof FilterValues;

interface Filter {
  id: FilterId;
  name: string;
  icon: React.ReactNode;
}

const filters: Filter[] = [
  {
    id: "author",
    name: "Author",
    icon: <MaterialIcon path={mdiAccount} size={1} />
  },
  {
    id: "recipient",
    name: "Recipients and CCs",
    icon: <MaterialIcon path={mdiAccount} size={1} />
  },
  {
    id: "team",
    name: "Team",
    icon: <MaterialIcon path={mdiAccountMultiple} size={1} />
  },
  {
    id: "status",
    name: "Status",
    icon: <MaterialIcon path={mdiCircle} size={1} />
  }
];

const filtersById = Object.fromEntries(filters.map(o => [o.id, o])) as Record<FilterId, Filter>;

function filterValuesFromURLParams(params: URLSearchParams): Record<FilterId, string[]> {
  return Object.fromEntries(filters.map(f => [f.id, params.getAll(f.id)])) as Record<FilterId, string[]>; // fromEntries's typing does not consider the key type
}

// Type User is used to unify the GraphQL types for outreach senders and receivers.
type User = { id: string; email: string; name: string };

function addUserToListIfUnknown(list: User[], knownIds: Set<string>, user: User) {
  if (!knownIds.has(user.id)) {
    knownIds.add(user.id);
    list.push(user);
  }
}

function userOptionMap(currentUserId: string | undefined): (u: User) => OptionMap {
  return u => ({
    id: u.id,
    name: currentUserId === u.id ? "You" : u.name === "" ? u.email : u.name
  });
}

function* conversationRecipients(c: OutreachConversationList_conversationList_conversations): Generator<User> {
  yield c.author;

  for (const user of c.toUsers) {
    yield user;
  }

  for (const user of c.ccUsers) {
    yield user;
  }
}

function someUserWithId(users: Iterable<User>, ids: string[]): boolean {
  for (const user of users) {
    if (ids.indexOf(user.id) !== -1) return true;
  }

  return false;
}

function conversationHasStatus(c: OutreachConversationList_conversationList_conversations, status: string): boolean {
  return (status === "Sent" && c.requests.length === 0) || (status === "Replied" && c.requests.length > 0);
}

// useFilterValues returns:
// - the options for each filter given a list of outreaches
// - a function to translate a filter value (eg. team or user ID) to its user friendly version
function useFilterValues(conversationList: OutreachConversationList_conversationList_conversations[]) {
  const { currentUser } = useCurrentUser();

  const [authorList, recipientList] = React.useMemo(() => {
    const knownAuthorIDs = new Set<string>();
    const authorList: User[] = [];

    const knownRecipientIDs = new Set<string>();
    const recipientList: User[] = [];

    for (const conversation of conversationList) {
      addUserToListIfUnknown(authorList, knownAuthorIDs, conversation.author);

      for (const user of conversation.toUsers) {
        addUserToListIfUnknown(recipientList, knownRecipientIDs, user);
      }

      for (const user of conversation.ccUsers) {
        addUserToListIfUnknown(recipientList, knownRecipientIDs, user);
      }
    }

    authorList.sort((a, b) => a.name.localeCompare(b.name));
    recipientList.sort((a, b) => a.name.localeCompare(b.name));

    return [authorList, recipientList];
  }, [conversationList]);

  const filterOptionsMap: {
    [key in FilterId]: {
      headline: string;
      options: OptionMap[];
    }[];
  } = useMemo(
    () => ({
      team: [
        {
          headline: "Filter by team",
          options: currentUser?.teams.map(team => ({ id: team.id, name: team.name })) ?? []
        }
      ],
      status: [
        {
          headline: "Filter by status",
          options: ["Sent", "Replied"].map(status => ({
            id: status,
            name: status
          }))
        }
      ],
      author: [
        {
          headline: "Filter by author",
          options: authorList.map(userOptionMap(currentUser?.id))
        }
      ],
      recipient: [
        {
          headline: "Filter by recipient",
          options: recipientList.map(userOptionMap(currentUser?.id))
        }
      ]
    }),
    [currentUser, authorList, recipientList]
  );

  const getFilterNameById = (key: FilterId, id: string): string => {
    if (id === "me") return "You";

    return filterOptionsMap[key][0].options.find(o => o.id === id)?.name ?? "Unknown";
  };

  return { filterOptionsMap, getFilterNameById };
}

function someFilterDefined(filterValues: Record<FilterId, string[]>): boolean {
  for (const filter of filters) {
    if (filterValues[filter.id].length > 0) {
      return true;
    }
  }

  return false;
}

const useFilterValuesFromUrlParams = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  return filterValuesFromURLParams(params);
};

export const OutreachFilter: FC<{
  conversationlist: OutreachConversationList_conversationList_conversations[];
}> = ({ conversationlist }) => {
  const { filterOptionsMap, getFilterNameById } = useFilterValues(conversationlist);
  const [addedFilter, setAddedFilter] = useState<FilterId>();
  const filterValues = useFilterValuesFromUrlParams();
  const availableFilters = filters.filter(f => filterValues[f.id].length === 0);
  const filterByOptions = [
    {
      headline: "Filter by",
      options: availableFilters.map(
        filter =>
          ({
            ...filter,
            onClick: () => {
              setAddedFilter(filter.id);
            }
          } as OptionMap)
      )
    }
  ];
  const location = useLocation();
  const onFilterChange = (name: FilterId, values: string[] | null) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(name);

    for (const value of values ?? []) searchParams.append(name, value);

    navigate(`${window.location.pathname}?${searchParams.toString()}`, { replace: true });
  };
  const FilterPill = ({ name, unit }: { name: FilterId; unit: string }) => (
    <FilterBy
      selected={filterValues[name]}
      onSelect={(selected: string[] | null) => onFilterChange(name, selected)}
      onClickAway={() => {
        setAddedFilter(undefined);
      }}
      onRemove={() => onFilterChange(name, null)}
      showFilter={name === addedFilter}
      filterLabel={filterValues[name].map(val => getFilterNameById(name, val)).join(", ")}
      options={filterOptionsMap[name]}
      icon={filtersById[name].icon}
      unit={unit}
      filterType={name}
      validValues={filterValues[name]}
      isOpen={name === addedFilter}
    />
  );

  return (
    <>
      <FilterPill name="team" unit="teams" />
      <FilterPill name="author" unit="authors" />
      <FilterPill name="recipient" unit="recipients" />
      <FilterPill name="status" unit="statuses" />

      {filterByOptions[0].options.length > 0 && (
        <PopOver.Menu
          trigger={
            !someFilterDefined(filterValues) ? (
              <FilterButton
                secondary
                data-testid="add-filter-button"
                icon={<MaterialIcon path={mdiPlus} size={1} />}
                label="Filter"
                css={css`
                  margin-left: var(--space-1-rem);
                `}
              />
            ) : (
              <SimpleTooltip placement="top" label="Add filter">
                <SquareButton
                  data-testid="add-filter-button"
                  variant="secondary"
                  size="small"
                  css={css`
                    margin-left: var(--space-1-rem);
                  `}
                >
                  <MaterialIcon path={mdiPlus} size={1} />
                </SquareButton>
              </SimpleTooltip>
            )
          }
          options={filterByOptions}
        />
      )}
    </>
  );
};

export const useConversationListFilter = () => {
  const filterValues = useFilterValuesFromUrlParams();
  const isFiltered = someFilterDefined(filterValues);
  const filterConversations = (
    conversations: OutreachConversationList_conversationList_conversations[]
  ): OutreachConversationList_conversationList_conversations[] => {
    if (!isFiltered) {
      return conversations;
    }

    const res: OutreachConversationList_conversationList_conversations[] = [];

    for (const conv of conversations) {
      if (filterValues.team.length > 0 && filterValues.team.indexOf(conv.team.id) === -1) continue;
      if (filterValues.author.length > 0 && filterValues.author.indexOf(conv.author.id) === -1) continue;
      if (filterValues.recipient.length > 0 && !someUserWithId(conversationRecipients(conv), filterValues.recipient))
        continue;
      if (filterValues.status.length === 1 && !conversationHasStatus(conv, filterValues.status[0])) continue;

      res.push(conv);
    }

    return res;
  };

  return [filterConversations, isFiltered] as const;
};
