import { css } from "@emotion/core";
import { differenceInMinutes } from "date-fns";
import { uniq } from "lodash";
import * as React from "react";
import {
  RequestGet_request_author,
  RequestGet_request_CCs,
  RequestGet_request_CCs_CCs,
  RequestGet_request_events,
  RequestGet_request_events_ccsAddedEvent_ccUsersList,
  RequestGet_request_requester
} from "src/App/Requests/DetailView/typings/RequestGet";
import { UserName } from "src/App/User";
import { Col, Row } from "src/components";
import { Typo } from "src/styling/primitives/typography";
import { formatLongDate } from "src/util/formatters";
import { UserEmailLabel } from "./Comment";
import { UpdateComponent } from "./Update";

export function CCEditEventComponent(props: { event: CCEditEvent; requester: RequestGet_request_requester }) {
  return (
    <UpdateComponent
      timestamp={props.event.timestamp}
      authorId={props.event.author?.id ?? ""}
      requesterId={props.requester.id}
      tooltip={
        <Col
          css={css`
            p {
              color: var(--lioghtGrey-2);
            }
            > * + * {
              margin-top: var(--space-2-rem);
            }
          `}
        >
          {props.event.added.length > 0 && (
            <div>
              <Typo.Body bold>Added</Typo.Body>
              <Col>
                {props.event.added.map(user => (
                  <div key={user.id}>
                    • <UserEmailLabel user={user} />
                  </div>
                ))}
              </Col>
            </div>
          )}
          {props.event.removed.length > 0 && (
            <div>
              <Typo.Body
                bold
                css={css`
                  margin: 0.25rem 0;
                `}
              >
                Removed
              </Typo.Body>
              <Col>
                {props.event.removed.map(user => (
                  <div key={user.id}>
                    • <UserEmailLabel user={user} />
                  </div>
                ))}
              </Col>
            </div>
          )}
          <Row>{formatLongDate(props.event.timestamp)}</Row>
        </Col>
      }
    >
      <UserName user={props.event.author ?? undefined} /> edited the CCs
    </UpdateComponent>
  );
}

export interface CCEditEvent {
  eventName: "CCEdit";
  author: RequestGet_request_author | null;
  added: RequestGet_request_events_ccsAddedEvent_ccUsersList[];
  removed: RequestGet_request_events_ccsAddedEvent_ccUsersList[];
  firstTimestamp: ScalarDateTime;
  timestamp: ScalarDateTime;
  revision: number;
}

/**
 * Aggregate cc add / remove into single edit events in between comments
 * @param resolutionInMins max time between events to aggregate
 */
export function aggregateCCChanges(events: RequestGet_request_events[], resolutionInMins = 10): CCEditEvent[] {
  return (
    events
      .reduce(
        (a, c) => {
          const [head, ...tail] = a;
          if (c.commentAddedEvent && head.length > 0) {
            // create new block if head not empty
            return [[], head, ...tail];
          } else if (c.ccsAddedEvent || c.ccsRemovedEvent) {
            return [[...head, c], ...tail];
          } else {
            return [head, ...tail];
          }
        },
        [[] as RequestGet_request_events[]]
      )
      .map(aggregateBlock(resolutionInMins))
      // reverse concat sub blocks
      .reduce((a, c) => [...c, ...a], [])
  );
}

/**
 * Aggregate (sub) block of cc events
 */
function aggregateBlock(resolutionInMins: number) {
  return (block: RequestGet_request_events[]) =>
    block.reduce((a, c) => {
      const lastIndex = a.length - 1;
      const author =
        (c.ccsAddedEvent && c.ccsAddedEvent.author) || (c.ccsRemovedEvent && c.ccsRemovedEvent.author) || null;
      /**
       * Base case:
       * first event
       * or difference in time > resolution
       * or different author
       */
      if (
        lastIndex === -1 ||
        differenceInMinutes(new Date(c.timestamp), new Date(a[lastIndex].firstTimestamp)) > resolutionInMins ||
        a[lastIndex].author?.id !== author?.id
      ) {
        return a.concat({
          eventName: "CCEdit",
          author,
          firstTimestamp: c.timestamp,
          timestamp: c.timestamp,
          revision: c.revision,
          added: c.ccsAddedEvent ? c.ccsAddedEvent.ccUsersList : [],
          removed: c.ccsRemovedEvent ? c.ccsRemovedEvent.ccUsersList : []
        });
      } else {
        return a.map((e, i) => {
          if (i !== lastIndex) {
            return e;
          } else if (c.ccsAddedEvent) {
            return {
              ...a[lastIndex],
              timestamp: c.timestamp,
              added: uniq(a[lastIndex].added.concat(c.ccsAddedEvent.ccUsersList)).filter(
                added => !a[lastIndex].removed.find(removed => removed.id === added.id)
              ),
              removed: a[lastIndex].removed.filter(
                removed => !c.ccsAddedEvent?.ccUsersList.find(added => added.id === removed.id)
              )
            };
          } else if (c.ccsRemovedEvent) {
            return {
              ...a[lastIndex],
              timestamp: c.timestamp,
              removed: uniq(a[lastIndex].removed.concat(c.ccsRemovedEvent.ccUsersList)).filter(
                removed => !a[lastIndex].added.find(added => added.id === removed.id)
              ),
              added: a[lastIndex].added.filter(
                added => !c.ccsRemovedEvent?.ccUsersList.find(removed => removed.id === added.id)
              )
            };
          } else return null;
        }) as CCEditEvent[];
      }
    }, [] as CCEditEvent[]);
}

export function ccsForEvent(ccs: RequestGet_request_CCs[], revision: number | null, authorId: string) {
  if (revision === null) {
    return undefined;
  }
  return ccs.reduce(
    (a, c) => (c.revision <= revision ? c.CCs.filter(cc => cc.id !== authorId) : a),
    undefined as RequestGet_request_CCs_CCs[] | undefined
  );
}
