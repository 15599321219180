import * as React from "react";

import { Col, DateTime, MessageText, Row, SimpleTooltip } from "src/components";
import { MessageAttachmentList, MessageBox, TimelineEvent } from "src/App/Requests/components";
import {
  RequestGet_request,
  RequestGet_request_CCs_CCs,
  RequestGet_request_events_data_CommentAddedEvent,
  RequestGet_request_events_data_RequestCreatedEvent
} from "src/App/Requests/DetailView/typings/RequestGet";
import { UserAvatar, UserComponentFields, UserName } from "src/App/User";
import { channelText, formatLongDate } from "src/util/formatters";

import { Badge } from "src/components/Badges";
import { EventContextPopMenu } from "src/App/Requests/DetailView/EventContextPopMenu";
import { EventProps } from "src/App/Timeline/Universal";
import { FormSubmitted } from "src/App/Forms/Message";
import { GSettingsUserGet_user } from "src/App/Settings/Users/Update/typings/GSettingsUserGet";
import { RequestChannels } from "src/globalTypes";
import { Typo } from "src/styling/primitives/typography";
import { ccsForEvent } from "src/App/Requests/DetailView/Timeline/CC";
import { css } from "@emotion/core";
import styled from "@emotion/styled";

const UserEmailLabelWrapper = styled.span`
  color: var(--lightGrey-2);

  .email {
    opacity: 0.75;
  }
`;

export const UserEmailLabel = (props: { user: GSettingsUserGet_user }) => {
  return (
    <UserEmailLabelWrapper>
      {props.user.name === "" ? (
        props.user.email
      ) : (
        <>
          {props.user.name} <span className="email">{props.user.email}</span>
        </>
      )}
    </UserEmailLabelWrapper>
  );
};

export const receivedLabel = (props: {
  author: UserComponentFields;
  timestamp: ScalarDateTime;
  channel?: RequestChannels;
  CCs?: RequestGet_request_CCs_CCs[];
}): JSX.Element => (
  <>
    Received on {formatLongDate(props.timestamp)} from <UserName user={props.author} />
    {props.channel === RequestChannels.SLACK && " via a direct message on Slack."}
    {props.channel === RequestChannels.EMAIL && " via an e-mail reply."}
    {props.channel === RequestChannels.JIRA && " via a Jira reply."}
    {props.channel === RequestChannels.CONVERSATIONS && " via an outreach reply."}
    {props.CCs && props.CCs.length > 0 && (
      <>
        <span>
          {" "}
          and {props.CCs.length} other{props.CCs.length > 1 && "s"} in CC:
        </span>
        {props.CCs.map(user => (
          <div key={user.id}>
            • <UserEmailLabel user={user} />
          </div>
        ))}
      </>
    )}
  </>
);

export const sentLabel = (props: {
  author: UserComponentFields;
  timestamp: ScalarDateTime;
  request: RequestGet_request;
  channel?: RequestChannels;
  mentionsOnly?: boolean;
  publicComment?: boolean;
  CCs?: RequestGet_request_CCs_CCs[];
}): JSX.Element => {
  const requesterName =
    props.request.requester.name.length > 0
      ? props.request.requester.name
      : props.request.requester.email.length > 0
      ? props.request.requester.email
      : "the requester";
  const mentionsOnlyMessage = `Sent on ${formatLongDate(props.timestamp)} ${
    props.request.origin &&
    props.publicComment &&
    ` to a thread in  #${props.request.origin.channel.name}. It was not sent to ${requesterName}`
  }`;
  const slackMessage = `Sent on ${formatLongDate(props.timestamp)}${
    (props.request.origin && props.publicComment && ` to a thread in  #${props.request.origin.channel.name} and`) || ""
  } as a direct message on Slack to ${requesterName}`;
  const emailMessage = `Sent on ${formatLongDate(props.timestamp)} as an email to ${requesterName}`;
  const webMessage = `Sent on ${formatLongDate(props.timestamp)}`;
  const unknownMessage = `Sent on ${formatLongDate(props.timestamp)} to ${requesterName}`;

  return (
    <>
      {props.mentionsOnly && mentionsOnlyMessage}
      {!props.mentionsOnly && props.channel === RequestChannels.SLACK && slackMessage}
      {!props.mentionsOnly && props.channel === RequestChannels.EMAIL && emailMessage}
      {!props.mentionsOnly && props.channel === RequestChannels.WEB && webMessage}
      {!props.mentionsOnly &&
        (props.channel === RequestChannels.REQUEST_CHANNEL_UNKNOWN ||
          props.channel === RequestChannels.UNSETREQUESTCHANNELS) &&
        unknownMessage}
      {props.CCs && props.CCs.length > 0 && (
        <>
          <span>
            {" "}
            and {props.CCs.length} other{props.CCs.length > 1 && "s"} in CC:
          </span>
          {props.CCs.map(user => (
            <div key={user.id}>
              • <UserEmailLabel user={user} />
            </div>
          ))}
        </>
      )}
    </>
  );
};

export const formatMeta = (props: {
  author: UserComponentFields;
  request: RequestGet_request;
  timestamp: ScalarDateTime;
  channel?: RequestChannels;
  mentionsOnly?: boolean;
  publicComment?: boolean;
  CCs?: RequestGet_request_CCs_CCs[];
}): JSX.Element => {
  return (
    <>
      {props.author.id === props.request.requester.id && receivedLabel(props)}
      {props.author.id !== props.request.requester.id && sentLabel(props)}
    </>
  );
};

export const CommentComponent: React.FC<
  EventProps & {
    data: RequestGet_request_events_data_CommentAddedEvent;
    onAttachmentDelete?: (commentId: string, attachmentId: string) => void;
  }
> = props => {
  const emptyComment = props.data.text === "\u200B" || !props.data.text || !props.data.text.length;
  const showCommentText = !emptyComment || props.data.deleted;
  const showComment = showCommentText || !!props.data.attachmentsList?.length || !!props.data.kbDocumentsList?.length;
  const isRequester = props.data.author.id === props.request.requester.id;
  const CCs = ccsForEvent(props.request.CCs, props.data.ccsRevision, props.data.author.id);
  const channel = channelText(
    "channel" in props.event.data && props.event.data.channel
      ? props.event.data.channel
      : RequestChannels.REQUEST_CHANNEL_UNKNOWN
  );
  return !showComment ? null : (
    <TimelineEvent
      alignLeft={isRequester}
      data-testid="request-created-message"
      avatar={<UserAvatar sizeL user={props.data.author} />}
      footer={
        <div data-testid="request-comment-info">
          <SimpleTooltip
            label={formatMeta({
              author: props.data.author,
              request: props.request,
              timestamp: props.event.timestamp,
              channel: props.data.channel,
              mentionsOnly: props.data.mentionsOnly,
              publicComment: props.data.publicComment,
              CCs
            })}
          >
            <Row
              css={css`
                align-items: center;
                > * {
                  // used for ellipses of long emails
                  flex: 0 0 auto;
                }
              `}
            >
              <Typo.Body
                medium
                ellipsis
                css={css`
                  // used for ellipses of long emails
                  flex: 0 1 auto;
                `}
              >
                <UserName user={props.data.author} />
              </Typo.Body>
              {","}&nbsp;
              <DateTime timestamp={props.event.timestamp} />
              <span>{channel !== "unknown" && <>&nbsp;via {channel}&nbsp;</>}</span>
              {CCs?.length ? (
                <>
                  &nbsp;
                  <Badge neutral>
                    {CCs.length} CC{CCs.length > 1 && "s"}
                  </Badge>
                </>
              ) : (
                ""
              )}
            </Row>
          </SimpleTooltip>
        </div>
      }
      contextMenu={
        !!props.data.commentId &&
        !props.data.deleted && (
          <EventContextPopMenu
            requestId={props.request.id}
            commentId={props.data.commentId}
            eventText={props.data.text}
            eventType="comment"
            hasHtml={!!props.data.hasHtml}
            team={props.request.team}
          />
        )
      }
    >
      <MessageBox>
        <div data-intercom-target="Request timeline: Comment message">
          {!emptyComment && showCommentText && (
            <>
              <Typo.Body>
                <MessageText text={props.data.text} />
              </Typo.Body>
              {props.event.formSubmittedEvent && <FormSubmitted formSubmittedEvent={props.event.formSubmittedEvent} />}
            </>
          )}

          {props.data.deleted && (
            <Typo.Body light>
              <i>This message was deleted</i>
            </Typo.Body>
          )}
          {!props.data.deleted && (
            <MessageAttachmentList
              attachmentsList={props.data.attachmentsList || []}
              documentsList={props.data.kbDocumentsList || []}
              onAttachmentDelete={
                props.onAttachmentDelete &&
                (attachmentId => {
                  props.onAttachmentDelete?.(props.data.commentId!, attachmentId);
                })
              }
            />
          )}
        </div>
      </MessageBox>
    </TimelineEvent>
  );
};

// Because we merge both the RequestCreatedEvent and the first CommentAddedEvent we need to also create a merged type.
// With this we can have the typename from the first event and the content from the second (merged in mergeRequestCreatedEvents)
export const RequestCreatedMessage: React.FC<
  EventProps & {
    data: Pick<RequestGet_request_events_data_RequestCreatedEvent, "__typename"> &
      Partial<Omit<RequestGet_request_events_data_CommentAddedEvent, "__typename">>;
    onAttachmentDelete?: (commentId: string, attachmentId: string) => void;
  }
> = props => {
  // rendered for the first and second event that are being merged as for
  // https://github.com/BackHQ/ohm/commit/d056fa82421cf0ed7ab620a8e9438c67e19263d2
  return (
    <div>
      <TimelineEvent
        alignLeft={!props.data.author?.id || props.data.author.id === props.initialRequest.requester.id}
        data-testid="request-created-message"
        avatar={<UserAvatar sizeL user={props.data.author || props.initialRequest.requester} />}
        footer={
          <div data-testid="request-note-info">
            <SimpleTooltip
              label={formatMeta({
                author: props.data.author || props.initialRequest.requester,
                request: props.request,
                timestamp: props.event.timestamp,
                channel: props.request.channel
              })}
            >
              <Row align="center">
                <Typo.Body medium>
                  <UserName user={props.data.author || props.initialRequest.requester} />
                </Typo.Body>
                {","}&nbsp;
                <DateTime timestamp={props.event.timestamp} />
              </Row>
            </SimpleTooltip>
          </div>
        }
        contextMenu={
          !!props.data.commentId &&
          !props.data.deleted && (
            <EventContextPopMenu
              requestId={props.request.id}
              commentId={props.data.commentId}
              eventText={props.data.text}
              eventType="comment"
              team={props.request.team}
              hasHtml={!!props.data.hasHtml}
            />
          )
        }
      >
        <MessageBox>
          <div data-intercom-target="Request timeline: Comment message">
            {!!props.initialRequest.title && (
              <Col className="text" flex="1 1 auto">
                <Typo.Body data-testid="heading" medium>
                  <MessageText text={props.initialRequest.title} />
                </Typo.Body>
              </Col>
            )}
            {props.data.text && (
              <Typo.Body>
                <MessageText text={props.data.text} />
              </Typo.Body>
            )}
            {!!props.data.attachmentsList?.length && (
              <MessageAttachmentList
                attachmentsList={props.data.attachmentsList || []}
                documentsList={props.data.kbDocumentsList || []}
                onAttachmentDelete={
                  props.onAttachmentDelete &&
                  (attachmentId => {
                    props.onAttachmentDelete?.(props.data.commentId!, attachmentId);
                  })
                }
              />
            )}
          </div>
        </MessageBox>
      </TimelineEvent>
    </div>
  );
};
