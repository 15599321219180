import * as React from "react";
import * as yup from "yup";

import { Button, Dialog, FormikInput, LoadingBar } from "src/components";
import { Field, Form, Formik } from "formik";
import { IntegrationEnableSSO, IntegrationEnableSSOVariables } from "./typings/IntegrationEnableSSO";

import AzureADSVG from "src/assets/logos/integrations/AzureAD.svg";
import GoogleSVG from "src/assets/logos/integrations/Google.svg";
import { INTEGRATIONS_LIST_GET } from "../Overview";
import { IntegrationSSOOAuthProvider } from "src/globalTypes";
import MicrosoftSVG from "src/assets/logos/integrations/Microsoft.svg";
import { Modal } from "src/portals/Modal";
import OktaSVG from "src/assets/logos/integrations/Okta.svg";
import { Typo } from "src/styling/primitives/typography";
import { css } from "@emotion/core";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { useState } from "react";

export const domainRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;

export const EnableSSOModal: React.FC<{ onDismiss: () => void; onSuccess: () => void; isOpen: boolean }> = props => {
  const { emitSnack } = useSnack();
  const [showSAMLModal, setShowSAMLModal] = useState(false); // shows the next step in the modal

  const onDismiss = () => {
    setShowSAMLModal(false);
    props.onDismiss();
  };

  const [enableSSO, enableSSOResponse] = useMutation<IntegrationEnableSSO, IntegrationEnableSSOVariables>(
    gql`
      mutation IntegrationEnableSSO($integration: CreateIntegrationSSO!) {
        integrationEnableSSO(integration: $integration) {
          code
          message
          success
          redirect {
            url
          }
        }
      }
    `,
    { refetchQueries: [{ query: INTEGRATIONS_LIST_GET }] }
  );

  const onSubmit = async (params: { domain?: string; provider?: IntegrationSSOOAuthProvider }) => {
    const { data } = await enableSSO({
      variables: {
        integration: {
          organizationDomain: params.domain,
          oauthProvider: params.provider
        }
      }
    });
    if (data?.integrationEnableSSO.redirect?.url) {
      window.location.href = data?.integrationEnableSSO.redirect?.url;
    } else if (data?.integrationEnableSSO.success) {
      props.onSuccess();
    } else {
      emitSnack({
        type: "mutationError",
        message: `Sorry, we encountered an error: "${data?.integrationEnableSSO.message}". Please check if you entered a valid domain.`
      });
    }
  };

  return (
    <Modal isOpen={props.isOpen} onDismiss={onDismiss}>
      <Dialog medium title={showSAMLModal ? "Provide your SSO domain" : "Choose your SSO provider"} onClose={onDismiss}>
        {enableSSOResponse.loading && <LoadingBar />}
        {!showSAMLModal && (
          <SelectProviderModal
            onSelected={provider => {
              if (provider) {
                // user chose an OAuth provider, submit the modal and exit
                onSubmit({ provider: provider });
              } else {
                // user chose a SAML provider, go to the next step
                setShowSAMLModal(true);
              }
            }}
          />
        )}
        {showSAMLModal && <SAMLConfigModal onSubmit={domain => onSubmit({ domain: domain })} />}
      </Dialog>
    </Modal>
  );
};

const SelectProviderModal: React.FC<{ onSelected: (provider: IntegrationSSOOAuthProvider | null) => void }> = props => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-evenly;

        & > button {
          display: flex;
          flex-direction: column;
          line-height: 1;
          padding: var(--space-3-rem);

          img {
            height: var(--space-6-rem);
          }

          > * + * {
            margin-top: var(--space-3-rem);
          }
        }
      `}
    >
      {/* we fix the line height of the button labels below to 2em so that their top
          lines are aligned. */}
      <Button variant="ghost" onClick={() => props.onSelected(IntegrationSSOOAuthProvider.GOOGLE)}>
        <img src={GoogleSVG} alt="Google OAuth" />
        <span
          css={css`
            height: 2em;
          `}
        >
          Google OAuth
        </span>
      </Button>
      <Button variant="ghost" onClick={() => props.onSelected(IntegrationSSOOAuthProvider.MICROSOFT)}>
        <img src={MicrosoftSVG} alt="Microsoft OAuth" />
        <span
          css={css`
            height: 2em;
          `}
        >
          Microsoft OAuth
        </span>
      </Button>
      <Button variant="ghost" onClick={() => props.onSelected(null)}>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            gap: 0 1rem;
          `}
        >
          <img src={OktaSVG} alt="Okta SAML" />
          <img src={AzureADSVG} alt="Azure AD" />
        </div>
        <span
          css={css`
            height: 2em;
          `}
        >
          Other SAML provider
          <br />
          (Okta, Azure AD…)
        </span>
      </Button>
    </div>
  );
};

const SAMLConfigModal: React.FC<{ onSubmit: (domain: string) => void }> = props => {
  return (
    <>
      <Formik
        initialValues={{
          organizationDomain: ""
        }}
        validationSchema={yup.object().shape({
          organizationDomain: yup.string().matches(domainRegex, "Must be a valid domain").required("Domain is required")
        })}
        onSubmit={async (values, actions) => {
          props.onSubmit(values.organizationDomain);
          actions.setSubmitting(false);
        }}
      >
        {form => (
          <>
            <Form>
              <div
                css={css`
                  & > * + * {
                    margin-top: var(--space-4-px);
                  }
                `}
              >
                <Typo.Body>
                  Enter the email domain, e.g. "example.com", you would like to configure as a single sign-on provider.
                  We will redirect you to configure your provider. Please contact us if you need any support setting
                  this up.
                </Typo.Body>
                <Field name="organizationDomain" component={FormikInput} label="Domain" autoComplete="off" autoFocus />
              </div>
              <div
                css={css`
                  display: flex;
                  justify-content: flex-end;
                `}
              >
                <Button disabled={!form.isValid || form.isSubmitting} size="large" type="submit" variant="primary">
                  Enable
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
