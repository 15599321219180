import css from "@emotion/css";
import { mdiDelete, mdiDownload } from "@mdi/js";
import { captureException } from "@sentry/browser";
import { compareDesc } from "date-fns";
import gql from "graphql-tag";
import * as React from "react";
import { useReducer, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import { AddFormButton, SCHEMA_LIST, SelectFormSchema } from "src/App/Forms/Manager/Choose";
import { ExportAnswersModal } from "src/App/Forms/Manager/ExportAnswersModal";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { CurrentUser_currentUser_teams } from "src/App/Root/providers/typings/CurrentUser";
import {
  BaseLayout,
  Button,
  CleanUnderlineButton,
  Col,
  ConfirmDialogModal,
  EmptyState,
  LoadingBar,
  MaterialIcon,
  Row,
  TypeformModal
} from "src/components";
import { PopOver } from "src/components/PopOver";
import { navigate as _navigate, useMatch } from "src/util/router";
import { FormMgmtPageModal } from "./Modal";
import { formReducer, initialFormState } from "./reducer";
import { SchemaDelete, SchemaDeleteVariables } from "./typings/SchemaDelete";
import { SchemaList, SchemaListVariables } from "./typings/SchemaList";

export const SchemaListPage: React.FC<{}> = () => {
  const { currentUser } = useCurrentUser();
  const team_slug = useMatch<{ team_slug: string }>("/forms/:team_slug/*")?.team_slug;
  if (!currentUser) return <LoadingBar />;
  const teamSlug = team_slug ?? currentUser.teams[0].slug;
  const selectedTeam = currentUser.teams.find(t => t.slug === teamSlug);
  if (!selectedTeam) {
    captureException(new Error("Invalid team slug"));
    return <div>Invalid team slug</div>;
  }
  return <SchemaListContainer selectedTeam={selectedTeam} teamList={currentUser.teams} />;
};

export const SchemaListContainer: React.FC<{
  teamList: CurrentUser_currentUser_teams[];
  selectedTeam: CurrentUser_currentUser_teams;
}> = props => {
  const { currentUser } = useCurrentUser();
  const { emitSnack } = useSnack();

  // url params
  const modal = useMatch<{ modal: "create" | "edit" }>("/forms/:team_id/:modal/*")?.modal;
  const schema_id = useMatch<{ schema_id: string }>("/forms/:team_id/:modal/:schema_id")?.schema_id;

  // keep _navigate calls private to here to keep consistent with url scheme
  const navigateToClose = () => _navigate(`/forms/${props.selectedTeam.slug}`);
  const navigateToTeam = (teamSlug: string) => _navigate(`/forms/${teamSlug}`);
  const navigateToCreate = () => _navigate(`/forms/${props.selectedTeam.slug}/create`);
  const navigateToEdit = (formId: string) => _navigate(`/forms/${props.selectedTeam.slug}/edit/${formId}`);

  const [deleteFormId, setDeleteFormId] = useState<string | undefined>();
  const [viewSubmissionsFormId, setViewSubmissionsFormId] = useState<string | undefined>();
  const [formState, formDispatch] = useReducer(formReducer, initialFormState);

  const schemaListResponse = useQuery<SchemaList, SchemaListVariables>(SCHEMA_LIST, {
    variables: {
      teamId: props.selectedTeam.id
    }
  });
  const selectedSchema = schemaListResponse.data?.schemaList.find(s => s.id === schema_id);
  const [deleteForm, deleteResponse] = useMutation<SchemaDelete, SchemaDeleteVariables>(
    gql`
      mutation SchemaDelete($id: ID!) {
        schemaDelete(id: $id) {
          code
          success
          message
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: SCHEMA_LIST,
          variables: {
            teamId: props.selectedTeam.id
          }
        }
      ]
    }
  );

  return (
    <BaseLayout
      fullHeight
      headline={"Forms"}
      subline={
        <Row align="center" fontSize="0.875rem">
          <span>Showing forms for&nbsp;</span>
          <PopOver.Menu
            options={
              props.teamList.map(team => ({
                id: team.slug,
                name: team.name
              })) ?? []
            }
            onSelect={(teamSlug: string) => navigateToTeam(teamSlug)}
            selected={props.selectedTeam.slug}
            trigger={<CleanUnderlineButton>{props.selectedTeam.name}</CleanUnderlineButton>}
          />
        </Row>
      }
      button={
        <AddFormButton
          onClick={() => {
            navigateToCreate();
          }}
        />
      }
      isLoading={schemaListResponse.loading}
    >
      <FormMgmtPageModal
        modal={modal ?? null}
        selectedSchema={selectedSchema}
        teamId={props.selectedTeam.id}
        state={formState}
        dispatch={formDispatch}
        dismiss={() => {
          formDispatch({
            type: "SET_MODAL",
            modal: null
          });
          navigateToClose();
        }}
      />
      {schema_id && (
        <TypeformModal
          isShown={!!formState.modal && formState.modal === "share"}
          onClose={() => {
            formDispatch({
              type: "SET_MODAL",
              modal: null
            });
          }}
          title={`Share the form "${selectedSchema?.displayName}"`}
          hiddenFields={{
            type: "form",
            organization_name: currentUser?.organization.name ?? "",
            form_id: schema_id,
            user_id: currentUser?.id ?? ""
          }}
          formId="l99KV5ff"
        />
      )}
      {schemaListResponse.data?.schemaList.length === 0 && (
        <EmptyState title="No forms available for this team yet" subtitle="Add forms to get started" />
      )}
      {schemaListResponse.data && (
        <Col padding="0 0 2.5rem 0">
          {schemaListResponse.data.schemaList
            .sort((a, b) => compareDesc(new Date(a.createTime), new Date(b.createTime)))
            .map((schema, i) => (
              <SelectFormSchema
                large
                key={i}
                schema={schema}
                onClick={e => {
                  e.preventDefault();
                  navigateToEdit(schema.id);
                }}
              >
                <Button
                  variant="secondary"
                  onClick={e => {
                    e.stopPropagation();
                    setViewSubmissionsFormId(schema.id);
                  }}
                >
                  <MaterialIcon
                    path={mdiDownload}
                    size={1.125}
                    css={css`
                      margin-right: var(--space-1-rem);
                    `}
                  />{" "}
                  Download answers
                </Button>
                <Button
                  variant="red"
                  onClick={e => {
                    e.stopPropagation();
                    setDeleteFormId(schema.id);
                  }}
                >
                  <MaterialIcon
                    path={mdiDelete}
                    size={1.125}
                    css={css`
                      margin-right: var(--space-1-rem);
                    `}
                  />
                  Delete
                </Button>
              </SelectFormSchema>
            ))}
          <ConfirmDialogModal
            isOpen={!!deleteFormId}
            text={{
              cancel: "No thanks, cancel",
              confirm: "Yes, delete it!",
              heading: "Deletion warning"
            }}
            loading={deleteResponse.loading}
            handleCancel={() => setDeleteFormId(undefined)}
            handleConfirm={() =>
              deleteFormId &&
              deleteForm({
                variables: {
                  id: deleteFormId
                },
                update: (cache, { data, errors }) => {
                  if (errors) {
                    emitSnack({ message: "Sorry, there was an error deleting your form", type: "mutationError" });
                  } else if (data && data.schemaDelete.success) {
                    setDeleteFormId(undefined);
                  }
                }
              })
            }
          >
            <p>Are you sure you want to delete this form?</p>
          </ConfirmDialogModal>
          {viewSubmissionsFormId && (
            <ExportAnswersModal
              formId={viewSubmissionsFormId}
              isOpen={!!viewSubmissionsFormId}
              onDismiss={() => setViewSubmissionsFormId(undefined)}
            />
          )}
        </Col>
      )}
    </BaseLayout>
  );
};
