import { css } from "@emotion/core";
import React, { FC, useState } from "react";
import { JiraProjects_integrationJira_linkedProjects } from "src/App/Requests/DetailView/typings/JiraProjects";
import { RequestGet_request_jiraLinkedIssue } from "src/App/Requests/DetailView/typings/RequestGet";
import { ReactComponent as Jira } from "src/assets/logos/Jira.svg";
import { Button, Dialog, IconAndText, InfoBanner, SimpleTooltip } from "src/components";
import { Icon } from "src/components/Icon";
import { Modal } from "src/portals/Modal";
import { Typo } from "src/styling/primitives/typography";
import { Circle } from "src/svg/icons/Circle";
import { Human } from "src/svg/icons/Human";
import { Lock } from "src/svg/icons/Lock";
import { User } from "src/svg/icons/User";
import { Users } from "src/svg/icons/Users";
import { trackJiraLinkClick } from "src/util/analytics";
import { Folder } from "src/svg/icons/Folder";

export const JiraNote: FC<{
  linkedJiraIssue: RequestGet_request_jiraLinkedIssue;
  requestId: string;
  onUnlink(): void;
}> = props => {
  const [unLinkJiraModalIsOpen, setUnLinkJiraModalIsOpen] = useState<boolean>(false);
  return (
    <>
      <InfoBanner blue>
        <Jira
          css={css`
            width: var(--space-3-rem);
            height: var(--space-4-rem);
            flex-shrink: 0;
          `}
        />
        <span>
          This request is linked to{" "}
          <Typo.ExternalLink
            href={props.linkedJiraIssue.url}
            onClick={() => trackJiraLinkClick({ requestId: props.requestId, ticketNumber: props.linkedJiraIssue.key })}
            target="_blank"
            rel="noopener noreferrer"
            medium
          >
            {props.linkedJiraIssue.key}
          </Typo.ExternalLink>{" "}
          in Jira. Comments and replies are synchronized, and some properties are controlled by Jira and thus can’t be
          edited in Back.{" "}
          <Typo.ExternalLink onClick={() => setUnLinkJiraModalIsOpen(true)}>Unlink request</Typo.ExternalLink>
        </span>
      </InfoBanner>
      <Modal isOpen={unLinkJiraModalIsOpen} onDismiss={() => setUnLinkJiraModalIsOpen(false)}>
        <Dialog medium title="Unlink request from Jira issue" onClose={() => setUnLinkJiraModalIsOpen(false)}>
          <Typo.Body>
            Information in this request will no longer be synchronized with <b>{props.linkedJiraIssue.key}</b> in Jira.
            Already synchronized information will not be deleted.
          </Typo.Body>

          <div
            css={css`
              display: flex;
              justify-content: flex-end;
              margin-top: var(--space-6-rem);
              & > * + * {
                margin-left: var(--space-2-rem);
              }
            `}
          >
            <Button size="large" onClick={() => setUnLinkJiraModalIsOpen(false)}>
              Cancel
            </Button>
            <Button
              variant="primary"
              size="large"
              onClick={() => {
                props.onUnlink();
                setUnLinkJiraModalIsOpen(false);
              }}
            >
              Unlink
            </Button>
          </div>
        </Dialog>
      </Modal>
    </>
  );
};

export const JiraModal: FC<{
  isOpen: boolean;
  onDismiss(): void;
  onConfirm(): void;
  linkedJiraProject: JiraProjects_integrationJira_linkedProjects;
}> = props => {
  return (
    <Modal isOpen={props.isOpen} onDismiss={props.onDismiss}>
      <Dialog medium title="Link request to Jira" onClose={props.onDismiss}>
        <Typo.Body>
          It will be created in the project <b>{props.linkedJiraProject.project.name}</b>. You can continue adding
          comments and replies in Back and they will be synchronized to Jira and vice versa. The following properties
          are not editable in Back anymore, but controlled by Jira:
        </Typo.Body>
        <div
          css={css`
            margin-top: var(--space-4-rem);
            & > * + * {
              margin-top: var(--space-2-rem);
            }
          `}
        >
          <IconAndText icon={<Human />} label="Requester" />
          <IconAndText icon={<Circle />} label="Status" />
          <IconAndText icon={<User />} label="Assignee" />
          <IconAndText icon={<Folder />} label="Category" />
          <IconAndText icon={<Users />} label="Team" />
        </div>
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            margin-top: var(--space-6-rem);
            & > * + * {
              margin-left: var(--space-2-rem);
            }
          `}
        >
          <Button size="large" onClick={props.onDismiss}>
            Cancel
          </Button>
          <Button variant="primary" size="large" onClick={props.onConfirm}>
            Link to Jira
          </Button>
        </div>
      </Dialog>
    </Modal>
  );
};

export const DisabledInteractionWrapper: FC<{ disabled: boolean }> = ({ disabled, children }) => {
  return (
    <>
      {disabled ? (
        <div>
          <SimpleTooltip alignment="top" label="This property is controlled by Jira">
            <div
              css={css`
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: flex-start;
              `}
            >
              {children}
              <Icon
                css={css`
                  color: var(--text-2);
                  cursor: default;
                  margin-left: 2px;
                `}
              >
                <Lock />
              </Icon>
            </div>
          </SimpleTooltip>
        </div>
      ) : (
        children
      )}
    </>
  );
};
