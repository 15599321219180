import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { mdiContentSave, mdiPlus, mdiSwapVertical } from "@mdi/js";
import React from "react";
import { useRequestList } from "src/App/Requests/ListView/Provider";
import { useSearchDialog } from "src/App/Requests/Search/Dialog";
import { ReactComponent as ViewClassic } from "src/assets/viewClassic.svg";
import { ReactComponent as ViewCondensed } from "src/assets/viewCondensed.svg";
import { ReactComponent as ViewKanban } from "src/assets/viewKanban.svg";
import {
  CleanButton,
  CleanUnderlineButton,
  FilterButton,
  Hide,
  MaterialIcon,
  SimpleTooltip,
  SquareButton
} from "src/components";
import { FilterBy } from "src/components/Filter";
import { OptionMap, PopOver } from "src/components/PopOver";
import { OrderByKey, RequestListView, ViewType } from "src/globalTypes";
import { stackOrder } from "src/styling/layout";
import { align, justify, row } from "src/styling/primitives";
import { keys } from "src/util";
import { filterBarWrapperStyles, sortList, useFilterOptions, WILDCARD_FILTER } from ".";
import { filterIcons, SaveViewModal } from "./SaveViewModal";
import { Filters, setRequestListURLParam } from "./urlParamHelpers";

const fixedStyles = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  width: auto;
  z-index: ${stackOrder.higher};
  background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, white 1.5rem, white 100%);
`;

const Fixed: React.FC<
  React.PropsWithChildren<{
    width?: string;
    height?: string;
    left?: string;
    className?: string;
  }>
> = props => (
  <div
    css={[
      fixedStyles,
      props.width &&
        css`
          width: ${props.width};
        `
    ]}
    className={props.className}
  >
    {props.children}
  </div>
);

const ClickBlockerOverlay = styled.div`
  position: fixed;
  display: none;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .menu.open ~ & {
    display: block;
  }
`;

const FilterBarWrapper = React.forwardRef(
  (
    props: {
      width?: string;
      className?: string;
      children: React.ReactNode;
    },
    ref?: React.Ref<HTMLDivElement>
  ) => (
    <div
      css={[
        filterBarWrapperStyles,
        css`
          width: 100%;
        `
      ]}
      ref={ref || null}
      className={props.className}
    >
      {props.children}
    </div>
  )
);

const FilterWrapper = styled.div`
  & button {
    margin: var(--space-1-rem) var(--space-1-rem) var(--space-1-rem) 0;
  }

  & ${CleanButton} {
    margin-right: 0;
  }
`;

const svgSize = css`
  width: 18px;
  height: 18px;
`;

export const viewTypeSpecification = {
  [ViewType.VIEW_TYPE_UNSPECIFIED]: {
    icon: <ViewClassic css={svgSize} />,
    label: "Unknown"
  },
  [ViewType.CLASSIC]: {
    icon: <ViewClassic css={svgSize} />,
    label: "Classic"
  },
  [ViewType.CONDENSED]: {
    icon: <ViewCondensed css={svgSize} />,
    label: "Condensed"
  },
  [ViewType.KANBAN]: {
    icon: <ViewKanban css={svgSize} />,
    label: "Board"
  }
} as const;

interface FilterViewState {
  showFilter: { [f in keyof Filters]?: boolean };
  modal: "save" | null;
}

/**
 * Container for toggling filters
 */
export const FilterRequestsBar: React.FC<{
  className?: string;
}> = props => {
  const [state, set] = React.useState<FilterViewState>({
    showFilter: {},
    modal: null
  });

  const [requestList, requestListDispatch] = useRequestList();
  const searchDialog = useSearchDialog();
  const { filterOptionsMap, getFilterNameById } = useFilterOptions();

  // TODO: fallback for pages w o pagination?
  const count = requestList.response.data?.requestListPage.count;
  /* hide status and write "n requests" as "n resolved requests" */
  const isArchiveView = requestList.listViewType === RequestListView.ARCHIVE;
  const singular = count === 1;
  /* show when there are no filters visible */
  const showExpandedAddFilterButton = keys(requestList.filters).every(
    filterType => !state.showFilter[filterType] && !requestList.filtersFromUrl[filterType]?.length
  );

  const statusFilterOption = {
    id: "customStatus",
    name: "Status"
  };

  const showSaveButton = requestList.haveFiltersDivergedForView && !requestList.isSearchView;

  const filterByOptions = [
    {
      headline: "Filter by",
      options: (
        [
          {
            id: "team",
            name: "Team"
          },
          {
            id: "category",
            name: "Category"
          },
          {
            id: "requester",
            name: "Requester"
          },
          {
            id: "priority",
            name: "Priority"
          },
          {
            id: "assignee",
            name: "Assignee"
          },
          isArchiveView ? undefined : statusFilterOption
        ] as { id: keyof Filters; name: string }[]
      )
        // only show unset filters
        .filter(o => o && requestList.filters[o.id]?.length === 0)
        // add click handlers
        .map(
          filter =>
            ({
              ...filter,
              onClick: () => {
                set({
                  ...state,
                  showFilter: {
                    ...state.showFilter,
                    [filter.id]: true
                  }
                });
                const filterButton = document.querySelector(
                  `button[data-filtertype="${filter.id}"]`
                ) as HTMLButtonElement;
                // open dropdown
                filterButton?.click();
              }
            } as OptionMap)
        )
    }
  ];

  const handleClickAway = (filterarg: keyof Filters) => {
    set(state => ({
      ...state,
      showFilter: {
        ...state.showFilter,
        [filterarg]: false
      }
    }));
  };

  const selectFilter = (filter: keyof Filters) => requestList.filters[filter];

  const removeFilter = (filter: keyof Filters) => {
    setRequestListURLParam({
      type: "clear_filter",
      filter,
      analytics: {
        listViewType: requestList.listViewType
      }
    });
  };

  const handleSelect = (selected: string[] | null, filter: keyof Filters) => {
    selected == null
      ? setRequestListURLParam({
          type: "clear_filter",
          filter,
          analytics: {
            listViewType: requestList.listViewType
          }
        })
      : setRequestListURLParam({
          type: "set_filter",
          filter,
          values: selected,
          analytics: {
            listViewType: requestList.listViewType
          }
        });
  };

  return (
    <Fixed className={props.className} width="100%" height={`${requestList.filterBarHeight}px`}>
      <FilterBarWrapper>
        <FilterWrapper className="filters">
          {/* show the search results bar if we are showing search results*/}
          {requestList.isSearchView && (
            <span
              css={css`
                margin-right: var(--space-1-px);
              `}
            >
              Found {count ?? ".."} {singular ? "request" : "requests"} for {`"`}
              <CleanUnderlineButton onClick={() => searchDialog.open()}>
                {requestList.filters.fts?.join(" ")}
              </CleanUnderlineButton>
              {`"`}
              &nbsp;
            </span>
          )}
          {/* show the normal "bar" when not in search view. this bar is shared by all "/view/:id" routes */}
          {!requestList.isSearchView && (
            <div
              css={[
                css`
                  margin-right: var(--space-2-rem);
                `
              ]}
            >
              {count ?? ".."} {isArchiveView && "resolved "}
              {singular ? "request" : "requests"}
            </div>
          )}
          {
            <>
              {/* TODO: We need some inline loading states to use here */}
              <FilterBy
                selected={selectFilter("team")}
                onSelect={(selected: string[] | null) => handleSelect(selected, "team")}
                onClickAway={() => handleClickAway("team")}
                onRemove={() => removeFilter("team")}
                showFilter={state.showFilter["team"]}
                filterLabel={getFilterNameById("team", requestList.filters["team"]?.[0] ?? "")}
                options={filterOptionsMap["team"]}
                icon={filterIcons.team}
                unit="teams"
                filterType="team"
                validValues={requestList.filters.team ?? []}
              />
              <FilterBy
                icon={filterIcons.category}
                unit="categories"
                filterType="category"
                validValues={requestList.filters.category ?? []}
                selected={selectFilter("category")}
                onSelect={(selected: string[] | null) => handleSelect(selected, "category")}
                onClickAway={() => handleClickAway("category")}
                onRemove={() => removeFilter("category")}
                showFilter={state.showFilter["category"]}
                filterLabel={getFilterNameById("category", requestList.filters["category"]?.[0] ?? "")}
                options={filterOptionsMap["category"]}
              />
              {!isArchiveView && (
                <FilterBy
                  icon={filterIcons.customStatus}
                  filterType="customStatus"
                  unit="statuses"
                  tooltip="Filtered by status"
                  validValues={requestList.filters.customStatus ?? []}
                  selected={selectFilter("customStatus")}
                  onSelect={(selected: string[] | null) => handleSelect(selected, "customStatus")}
                  onClickAway={() => handleClickAway("customStatus")}
                  onRemove={() => removeFilter("customStatus")}
                  showFilter={state.showFilter["customStatus"]}
                  filterLabel={getFilterNameById("customStatus", requestList.filters["customStatus"]?.[0] ?? "")}
                  options={filterOptionsMap["customStatus"]}
                />
              )}
              <FilterBy
                icon={filterIcons.requester}
                unit="requesters"
                filterType="requester"
                validValues={requestList.filters.requester ?? []}
                selected={selectFilter("requester")}
                onSelect={(selected: string[] | null) => handleSelect(selected, "requester")}
                onClickAway={() => handleClickAway("requester")}
                onRemove={() => removeFilter("requester")}
                showFilter={state.showFilter["requester"]}
                filterLabel={getFilterNameById("requester", requestList.filters["requester"]?.[0] ?? "")}
                options={filterOptionsMap["requester"]}
              />
              <FilterBy
                icon={filterIcons.priority}
                unit="priorities"
                filterType="priority"
                validValues={requestList.filters.priority ?? []}
                selected={selectFilter("priority")}
                onSelect={(selected: string[] | null) => handleSelect(selected, "priority")}
                onClickAway={() => handleClickAway("priority")}
                onRemove={() => removeFilter("priority")}
                showFilter={state.showFilter["priority"]}
                filterLabel={getFilterNameById("priority", requestList.filters["priority"]?.[0] ?? "")}
                options={filterOptionsMap["priority"]}
              />
              <FilterBy
                icon={filterIcons.assignee}
                unit="assignees"
                filterType="assignee"
                validValues={requestList.filters.assignee ?? []}
                selected={selectFilter("assignee")}
                onSelect={(selected: string[] | null) => handleSelect(selected, "assignee")}
                onClickAway={() => handleClickAway("assignee")}
                onRemove={() => removeFilter("assignee")}
                showFilter={state.showFilter["assignee"]}
                filterLabel={getFilterNameById("assignee", requestList.filters["assignee"]?.[0] ?? "")}
                options={filterOptionsMap["assignee"]}
              />
              {filterByOptions[0].options.length > 0 && (
                <PopOver.Menu
                  trigger={
                    showExpandedAddFilterButton ? (
                      <FilterButton
                        secondary
                        data-testid="add-filter-button"
                        icon={<MaterialIcon path={mdiPlus} size={1} />}
                        label="Filter"
                        css={css`
                          margin-left: var(--space-1-rem);
                        `}
                      />
                    ) : (
                      <SimpleTooltip placement="top" label="Add filter">
                        <SquareButton
                          data-testid="add-filter-button"
                          variant="secondary"
                          size="small"
                          css={css`
                            margin-left: var(--space-1-rem);
                          `}
                        >
                          <MaterialIcon path={mdiPlus} size={1} />
                        </SquareButton>
                      </SimpleTooltip>
                    )
                  }
                  options={filterByOptions}
                />
              )}
            </>
          }
        </FilterWrapper>
        <div css={[row, justify.end, align.start]}>
          <SortBy />
          <ClickBlockerOverlay />
          <PopOver.Menu
            onSelect={(viewType: ViewType) => {
              requestListDispatch({
                type: "SET_VIEW_TYPE",
                viewId: requestList.viewId,
                viewType: viewType
              });
            }}
            options={[
              {
                headline: "Switch view",
                options: Object.values(ViewType)
                  .filter(
                    e =>
                      (e === ViewType.KANBAN && !isArchiveView) ||
                      (e !== ViewType.VIEW_TYPE_UNSPECIFIED && e !== ViewType.KANBAN)
                  )
                  .map(viewType => ({
                    id: viewType,
                    name: viewTypeSpecification[viewType].label
                  }))
              }
            ]}
            selected={requestList.state.viewType}
            trigger={
              <SimpleTooltip placement="top" label="Change view">
                <SquareButton
                  data-testid="view-type-switch"
                  data-intercom-target="Change view button"
                  variant="secondary"
                  size="small"
                  css={css`
                    margin-left: var(--space-1-rem);
                    padding: 3px;
                  `}
                >
                  {viewTypeSpecification[requestList.state.viewType].icon}
                </SquareButton>
              </SimpleTooltip>
            }
          />
          {showSaveButton && (
            <SimpleTooltip placement="top" label="Save as view">
              <SquareButton
                data-intercom-target="Save view button"
                onClick={() =>
                  set({
                    ...state,
                    modal: "save"
                  })
                }
                variant="secondary"
                size="small"
                css={css`
                  margin-left: var(--space-1-rem);
                `}
              >
                <MaterialIcon path={mdiContentSave} size={1} />
              </SquareButton>
            </SimpleTooltip>
          )}
          <SaveViewModal
            isOpen={state.modal === "save"}
            dismiss={() => set({ ...state, modal: null })}
            orderByKey={requestList.orderByKey}
          />
        </div>
      </FilterBarWrapper>
    </Fixed>
  );
};

export function SortBy() {
  const [requestList] = useRequestList();
  return (
    <PopOver.Menu
      trigger={
        <SimpleTooltip placement="top" label="Sort by">
          <FilterButton
            secondary
            icon={<MaterialIcon path={mdiSwapVertical} size={1} />}
            label={sortList.find(sort => sort.key === requestList.orderByKey)?.label ?? ""}
          />
        </SimpleTooltip>
      }
      onSelect={(orderByKey: OrderByKey) => {
        setRequestListURLParam({
          type: "sort",
          orderByKey,
          analytics: {
            listViewType: requestList.listViewType
          }
        });
      }}
      selected={requestList.orderByKey}
      options={[
        {
          headline: "Sort by",
          options: sortList.map((sort, _i) => ({
            id: sort.key,
            name: sort.key === "isUnread" ? "Unread first (Last updated)" : sort.label
          }))
        }
      ]}
    />
  );
}
