import { css } from "@emotion/core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import React from "react";
import { useMutation } from "react-apollo";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { INTEGRATIONS_LIST_GET } from "src/App/Settings/Integrations/Overview";
import { Dialog, FormikFieldGroup, FormikInput, LoadingBar, SubmitButton } from "src/components";
import { Modal } from "src/portals/Modal";
import { justify, row } from "src/styling/primitives";
import { Typo } from "src/styling/primitives/typography";
import * as yup from "yup";
import { IntegrationAddHiBob, IntegrationAddHiBobVariables } from "./typings/IntegrationAddHiBob";

const INTEGRATION_ADD_HIBOB = gql`
  mutation IntegrationAddHiBob($config: IntegrationHRISConfig!) {
    integrationAddHRIS(config: $config) {
      code
      message
      success
    }
  }
`;

const validationSchema = yup.object().shape({
  token: yup.string().required()
});

export const HiBobInstallModal = (props: { onDismiss(): void; onSuccess(): void; isOpen: boolean }) => {
  const { emitSnack } = useSnack();

  const [addHiBob, response] = useMutation<IntegrationAddHiBob, IntegrationAddHiBobVariables>(INTEGRATION_ADD_HIBOB, {
    refetchQueries: [{ query: INTEGRATIONS_LIST_GET }]
  });
  return (
    <Modal isOpen={props.isOpen} onDismiss={props.onDismiss}>
      <Dialog title="Connect HiBob" onClose={props.onDismiss} medium>
        {response.loading && <LoadingBar />}
        <Formik
          initialValues={{
            token: ""
          }}
          validationSchema={validationSchema}
          onSubmit={async (payload, actions) => {
            const { data } = await addHiBob({
              variables: {
                config: { hibob: payload }
              }
            });

            actions.setSubmitting(false);

            if (data?.integrationAddHRIS.success) {
              props.onSuccess();
            } else if (data?.integrationAddHRIS.success === false) {
              emitSnack({
                type: "mutationError",
                message: data.integrationAddHRIS.message
              });
            }
          }}
          render={form => (
            <Form>
              <FormikFieldGroup.Container legend="API token">
                <Field
                  name="token"
                  component={FormikInput}
                  hideErrorLabel
                  placeholder="e.g. pBHmJRS2wQf5wUpdFH3JLmF25VjfiwpkHo5qcxXP"
                  autoFocus
                />
                <FormikFieldGroup.HelpText
                  css={css`
                    margin-top: var(--space-2-rem);
                    margin-bottom: var(--space-2-rem);
                  `}
                >
                  You can find your API token in your hibob account by clicking on your profile &rarr; API access. Find
                  more information about connecting hibob in{" "}
                  <Typo.TextLink
                    light
                    onClick={() => window.open("http://support.back.ee/en/articles/4622106-hibob-integration")}
                  >
                    our documentation
                  </Typo.TextLink>
                  .
                </FormikFieldGroup.HelpText>
                <FormikFieldGroup.Errors>
                  <ErrorMessage render={() => "You must enter an API token to connect hibob"} name="token" />
                </FormikFieldGroup.Errors>
                <div css={[row, justify.end]}>
                  <SubmitButton disabled={response.loading || !form.isValid}>Submit</SubmitButton>
                </div>
              </FormikFieldGroup.Container>
            </Form>
          )}
        />
      </Dialog>
    </Modal>
  );
};
