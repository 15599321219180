import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-apollo";
import { useMergeLink } from "@mergeapi/react-merge-link";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { LoadingBar } from "src/components";
import { ConfigureIntegration, ConfigureIntegrationVariables } from "./typings/ConfigureIntegration";
import { INTEGRATIONS_LIST_GET } from "../Overview";

const CONFIGURE_INTEGRATION = gql`
  mutation ConfigureIntegration($config: IntegrationHRISConfig!) {
    integrationAddHRIS(config: $config) {
      code
      message
      success
      redirect {
        url
      }
    }
  }
`;

export const MergeInstallModal = (props: {
  onDismiss(): void;
  onSuccess(): void;
  isOpen: boolean;
  provider: "workday";
}) => {
  const { emitSnack } = useSnack();

  // The state machine here is the following:
  //
  // 1. On open, we request a login token from the backend
  // 2. Once the login link comes in, we can initialize the Merge SDK (calling
  //    useMergeLink with an undefined token doesn't seem to be a problem)
  // 3. Once the Merge SDK is ready (has injected its script tag into the DOM
  //    and got the token), and if we're still open, we ask the Merge SDK to show
  //    the authentication iFrame
  // 4. Once the iFrame comes back with a success callback, we need to call back
  //    the backend with the token we got
  //
  // This is implemented in the useEffect below.

  const [requestLoginToken, requestLoginTokenResponse] = useMutation<
    ConfigureIntegration,
    ConfigureIntegrationVariables
  >(CONFIGURE_INTEGRATION, {
    variables: { config: { [props.provider]: {} } },
    onCompleted: data => {
      if (!data.integrationAddHRIS.success) {
        emitSnack({
          type: "mutationError",
          message: `Error initializing authentication flow: ${data.integrationAddHRIS.message}`
        });
      }
    }
  });

  const [configureIntegration, configureIntegrationResponse] = useMutation<
    ConfigureIntegration,
    ConfigureIntegrationVariables
  >(CONFIGURE_INTEGRATION, {
    onCompleted: data => {
      if (data.integrationAddHRIS.success) {
        props.onSuccess();
      } else {
        emitSnack({
          type: "mutationError",
          message: `Error finalizing installation: ${data.integrationAddHRIS.message}`
        });
      }
    },
    refetchQueries: [{ query: INTEGRATIONS_LIST_GET }]
  });

  const [publicToken, setPublicToken] = useState<string | undefined>();

  const { open, isReady } = useMergeLink({
    linkToken: requestLoginTokenResponse.data?.integrationAddHRIS.redirect?.url ?? "",
    onSuccess: setPublicToken
  });

  useEffect(() => {
    if (!props.isOpen) {
      // We never want to advance the state machine if we're not open
      return;
    }

    if (!requestLoginTokenResponse.called) {
      // State 1: we are open but do not have a login token yet
      requestLoginToken();
      return;
    }

    // State 2 is handled with the useMergeLink above

    if (isReady && !publicToken && requestLoginTokenResponse.data?.integrationAddHRIS.success) {
      // State 3: we are open and have a login token, and the Merge SDK is ready
      open();
      return;
    }

    if (!!publicToken && !configureIntegrationResponse.called) {
      // State 4: Merge handed us a token which we need to forward to the backend.
      configureIntegration({
        variables: { config: { [props.provider]: { token: publicToken } } }
      });
    }
  }, [
    props.isOpen,
    requestLoginTokenResponse.called,
    requestLoginToken,
    isReady,
    requestLoginTokenResponse.data,
    open,
    publicToken,
    configureIntegrationResponse.called,
    configureIntegration,
    props.provider
  ]);

  return <>{(requestLoginTokenResponse.loading || configureIntegrationResponse.loading) && <LoadingBar />}</>;
};
