import React from "react";
import { useState } from "react";
import { css } from "@emotion/core";
import * as yup from "yup";
import copy from "copy-to-clipboard";
import { ErrorMessage, Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import {
  Button,
  Col,
  Dialog,
  FormikFieldGroup,
  FormikFieldInputs,
  FormikInput,
  LoadingBar,
  Row,
  SubmitButton
} from "src/components";
import { Input } from "src/components/Fields";
import { Modal } from "src/portals/Modal";
import { INTEGRATIONS_LIST_GET } from "../Overview";
import { ExternalSourceCreate, ExternalSourceCreateVariables } from "./typings/ExternalSourceCreate";
import { Typo } from "src/styling/primitives/typography";

const ADD_INTEGRATION = gql`
  mutation ExternalSourceCreate($params: ExternalDocumentSourceCreateParameters!) {
    externalDocumentSourceCreate(params: $params) {
      code
      message
      success
      redirect {
        url
      }
    }
  }
`;

const validationSchema = yup.object().shape({
  siteName: yup.string().required("The name of the Sharepoint site to link is required"),
  approvalMethod: yup.string().required("Please select whether you have an admin account or not")
});

interface FormValues {
  siteName: string;
  approvalMethod: "redirect" | "copy-link" | undefined;
}

const SiteNameForm = (props: { onSubmit(values: FormValues): void; loading: boolean }) => {
  return (
    <>
      <Typo.Body>
        Connect your Microsoft 365 account to import Sharepoint pages in the Knowledge base and to enable additional
        features with Microsoft Teams.
      </Typo.Body>
      <Formik<FormValues>
        initialValues={{
          siteName: "",
          approvalMethod: undefined
        }}
        validationSchema={validationSchema}
        onSubmit={payload => {
          props.onSubmit(payload);
        }}
      >
        <Form>
          <FormikFieldGroup.Container legend="Sharepoint site identifier">
            <Field autoFocus name="siteName" component={FormikInput} hideErrorLabel />
            <FormikFieldGroup.HelpText
              css={css`
                margin-top: var(--space-2-rem);
              `}
            >
              The identifier of your Sharepoint site, eg. <strong>MySite</strong> if the URL of your site is
              https://mycompany.sharepoint.com/sites/<strong>MySite</strong>.
            </FormikFieldGroup.HelpText>
            <FormikFieldGroup.Errors>
              <ErrorMessage name="siteName" />
            </FormikFieldGroup.Errors>
          </FormikFieldGroup.Container>

          <FormikFieldGroup.Container legend="Approval method">
            <Typo.Body>
              You will need a Microsoft account with administrator rights to grant privileges to Back on behalf of your
              organization. If you do not have an administrator account yourself, you can instead send the link to
              approve the Back application to your administrator.
            </Typo.Body>

            <FormikFieldInputs.Radio name="approvalMethod" value={"redirect"}>
              I have an administrator account, send me to the app approval page
            </FormikFieldInputs.Radio>

            <FormikFieldInputs.Radio name="approvalMethod" value={"copy-link"}>
              I do not have an administrator account, give me the link I can share with my administrator
            </FormikFieldInputs.Radio>

            <FormikFieldGroup.Errors>
              <ErrorMessage name="approvalMethod" />
            </FormikFieldGroup.Errors>
          </FormikFieldGroup.Container>

          <Row justify="end" margin="var(--space-4-rem) 0 0 0">
            <SubmitButton disabled={props.loading}>Next</SubmitButton>
          </Row>
        </Form>
      </Formik>
    </>
  );
};

const CopyApprovalURL: React.FC<{
  redirectURL: string;
  onCopy: () => void;
}> = props => {
  return (
    <>
      <Typo.Body>You're now ready to connect the Back application to your Microsoft 365 account!</Typo.Body>

      <Typo.Body>
        Share the following link with your Microsoft account administrator so he can approve the Back application.
      </Typo.Body>

      <Row justify="center" gap="var(--space-4-rem)" margin="var(--space-4-rem) 0 0 0">
        <Input
          value={props.redirectURL}
          onChange={ev => {
            ev.preventDefault();
          }}
        />
        <Button variant="secondary" onClick={props.onCopy}>
          Copy link to approval page
        </Button>
      </Row>
    </>
  );
};

export const InstallModal = (props: { onDismiss(): void; isOpen: boolean }) => {
  const { emitSnack } = useSnack();
  const [redirectURL, setRedirectURL] = useState<string | undefined>();
  const [approvalMethod, setApprovalMethod] = useState<FormValues["approvalMethod"]>();
  const [addIntegration, response] = useMutation<ExternalSourceCreate, ExternalSourceCreateVariables>(ADD_INTEGRATION, {
    onCompleted: data => {
      if (!data.externalDocumentSourceCreate.success) {
        emitSnack({
          type: "mutationError",
          message: `Failed to configure Sharepoint: ${data.externalDocumentSourceCreate.message}`
        });
        return;
      }

      const url = data.externalDocumentSourceCreate.redirect?.url ?? "";

      if (approvalMethod === "redirect") {
        window.location.assign(url);
      } else {
        setRedirectURL(url);
      }
    },
    refetchQueries: [{ query: INTEGRATIONS_LIST_GET }]
  });

  const onCopy = () => {
    copy(redirectURL ?? "");
    emitSnack({ message: "Link copied to clipboard", type: "info" });
  };

  return (
    <Modal isOpen={props.isOpen} onDismiss={props.onDismiss}>
      <Dialog title="Connect your Microsoft 365 account" onClose={props.onDismiss} large>
        {response.loading && <LoadingBar />}

        <Col gap="var(--space-4-rem)">
          {redirectURL ? (
            <CopyApprovalURL redirectURL={redirectURL} onCopy={onCopy} />
          ) : (
            <SiteNameForm
              onSubmit={payload => {
                setApprovalMethod(payload.approvalMethod);
                addIntegration({ variables: { params: { sharepoint: { siteName: payload.siteName } } } });
              }}
              loading={response.loading}
            />
          )}
        </Col>
      </Dialog>
    </Modal>
  );
};

export const InstalledModal = (props: { isOpen: boolean; onDismiss: () => void }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const error = searchParams.get("error");

  return (
    <Modal isOpen={props.isOpen} onDismiss={props.onDismiss}>
      <Dialog title="Connect your Microsoft 365 account" onClose={props.onDismiss} large>
        <Col gap="var(--space-4-rem)">
          {error ? (
            <>
              <Typo.Body>An error occured while linked your Back account to your Microsoft 365 account.</Typo.Body>
              <Typo.Body>Details: {error}</Typo.Body>
              <Typo.Body>Please contact us for further assistance.</Typo.Body>
            </>
          ) : (
            <>
              <Typo.Body>You Back account is now linked to your Microsoft 365 account!</Typo.Body>
              <Typo.Body>
                You can now import pages from your Sharepoint site in the knowledge section, and use all the features of
                MS Teams.
              </Typo.Body>
            </>
          )}
          <Row justify="center">
            <Button variant="primary" onClick={props.onDismiss}>
              Close
            </Button>
          </Row>
        </Col>
      </Dialog>
    </Modal>
  );
};
