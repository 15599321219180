import { css } from "@emotion/core";
import gql from "graphql-tag";
import * as React from "react";
import { useMutation } from "react-apollo";
import { ValueType } from "react-select/src/types";
import { REQUEST_GET } from "src/App/Requests/DetailView/REQUEST_GET";
import { UserAvatar, UserComponentFields } from "src/App/User";
import { getUserList } from "src/App/User/getUserList";
import { Button, LoadingBar } from "src/components";
import { AsyncCreatableSingleSelectWithIcon } from "src/components/Fields/Select";
import { Toast } from "src/portals/Toast";
import { stackOrder } from "src/styling/layout";
import { csx } from "src/util/csx";
import * as yup from "yup";
import { GRequestAddApprovalVariables } from "./typings/GRequestAddApproval";

export const REQUEST_ADD_APPROVAL = gql`
  mutation GRequestAddApproval($params: RequestAddApprovalParameters!) {
    requestAddApproval(params: $params) {
      code
      success
      message
    }
  }
`;

/**
 * Button to add an approval request
 * Contains mutation, loading, error handling logic
 */
export function SendApprovalButton(props: {
  params: GRequestAddApprovalVariables["params"];
  disabled?: boolean;
  submitHandlerRef: React.MutableRefObject<() => void>;
  /**
   * Callback after comment submitted successfully
   */
  onSuccess?(): void;
}) {
  const hasApprover = !!props.params.approverId || !!props.params.approverEmail;
  const [sendApproval, sendApprovalResponse] = useMutation(REQUEST_ADD_APPROVAL, {
    refetchQueries: [
      {
        query: REQUEST_GET,
        variables: {
          id: props.params.requestId
        }
      }
    ]
  });
  const submitHandler = () =>
    sendApproval({
      update: (cache, response) => {
        if (response.data && response.data.requestAddApproval.success && props.onSuccess) {
          props.onSuccess();
        }
      },
      variables: {
        params: props.params
      }
    });
  props.submitHandlerRef.current = submitHandler;

  return (
    <>
      <Button
        variant="primary"
        disabled={props.disabled || sendApprovalResponse.loading || !hasApprover}
        margin="0 0 0 .25rem"
        data-intercom-target="Send approval request"
        onClick={() => submitHandler()}
      >
        Ask for approval
      </Button>
      {sendApprovalResponse.loading && <LoadingBar />}
      {sendApprovalResponse.data && !sendApprovalResponse.data.requestAddApproval.success && (
        <Toast kind="mutationError" message={sendApprovalResponse.data.requestAddApproval.message} />
      )}
    </>
  );
}

const ApproverWrapper = csx(
  [
    css`
      flex: 50%;
      flex-grow: 1;
      border: 0.0625rem solid var(--text-2);
      border-radius: 0.25rem;
      padding: 0.25rem 0.75rem;

      & .input-approver {
        &:focus {
          color: red;
        }
        & .select__control {
          background: none;
          border: none;
          cursor: pointer;
          min-height: unset;

          & * {
            cursor: pointer;
          }
        }

        & .icon,
        & .select__placeholder {
          color: var(--text-3);
        }

        & .select__clear-indicator {
          padding: 0.25rem;
        }

        & .select__menu {
          z-index: ${stackOrder.higher};

          & .select__menu-list {
            position: static;
            margin-bottom: 57px;
            border-bottom: 1px solid var(--border);
          }

          & .select__option:first-child {
            position: absolute;
            bottom: 0;
            padding-top: 16px;
            padding-bottom: 16px;
            border-radius: 4px;
            background: var(--white);
          }
        }
      }
    `
  ],
  {
    focused: css`
      border: 0.0625rem solid var(--text-6);
    `
  }
);

interface ApproverSelectOption {
  label?: string;
  value?: UserComponentFields;
}

function isValidNewOption(value: string) {
  return yup.string().email().isValidSync(value);
}

export function SelectApprover(props: {
  approver?: UserComponentFields;
  onSelect(approver: UserComponentFields): void;
}) {
  const [isFocused, setIsFocused] = React.useState(false);

  return (
    <ApproverWrapper onClick={e => e.stopPropagation()} focused={isFocused}>
      <AsyncCreatableSingleSelectWithIcon<ApproverSelectOption>
        blurInputOnSelect
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        isClearable
        name="approver"
        menuPlacement="bottom"
        createOptionPosition="first"
        loadOptions={(value: string) =>
          getUserList(value, false, true).then(userList =>
            Promise.resolve(
              userList.users.map(user => ({
                label: user.name ? `${user.name} (${user.email})` : user.email,
                value: user
              }))
            )
          )
        }
        onChange={(data: ValueType<ApproverSelectOption>) => {
          if (data && !("length" in data) && data.value) {
            props.onSelect({
              email: data.value.email,
              id: data.value.id,
              name: data.value.name || ""
            });
          }
        }}
        placeholder="Enter the approver's email address"
        getIcon={(value?: UserComponentFields) =>
          value && (
            <UserAvatar
              sizeXS
              user={value}
              css={css`
                margin: 0 0.5rem 0 0;
              `}
            />
          )
        }
        data-testid="input-approver"
        data-intercom-target="Select approver"
        className="input-approver"
        components={{
          ClearIndicator: null,
          DropdownIndicator: null
        }}
        value={
          !!props.approver && (props.approver.name || props.approver.email)
            ? {
                label: !!props.approver.name ? props.approver.name : props.approver.email,
                value: props.approver
              }
            : null
        }
        isValidNewOption={value => true}
        noOptionsMessage={() => "Please enter a valid email to invite"}
        getNewOptionData={value => ({
          display: value,
          label: isValidNewOption(value)
            ? `Ask for approval via email from ${value}`
            : "Enter an email address or select a user...",
          value: {
            name: "",
            email: value,
            id: "",
            new: true
          }
        })}
        onCreateOption={value => {
          if (isValidNewOption(value)) {
            props.onSelect({ id: "", email: value, name: value });
          } else {
            props.onSelect({ id: "", name: "", email: "" });
          }
        }}
      />
    </ApproverWrapper>
  );
}

export type ApprovalState = {
  showModal: boolean;
  approver: UserComponentFields;
  currentlySelectedApprover: UserComponentFields;
};

export const INIT_STATE: ApprovalState = {
  approver: { name: "", email: "", id: "" },
  currentlySelectedApprover: {
    name: "",
    email: "",
    id: ""
  },
  showModal: false
} as const;

export type ApprovalAction =
  | {
      type: "SHOW_MODAL";
    }
  | {
      type: "HIDE_MODAL";
    }
  | {
      type: "SELECT_APPROVER";
      currentlySelectedApprover: typeof INIT_STATE.currentlySelectedApprover;
    }
  | {
      type: "SET_APPROVER";
      approver: typeof INIT_STATE.approver;
    }
  | {
      type: "CLEAR_APPROVAL";
    };

export const reducer = (state: ApprovalState, action: ApprovalAction) => {
  switch (action.type) {
    case "SHOW_MODAL":
      return {
        ...state,
        showModal: true
      };
    case "HIDE_MODAL":
      return {
        ...state,
        currentlySelectedApprover: state.approver, // so that when we re-open the modal the correct option is selected
        showModal: false
      };
    case "SELECT_APPROVER":
      return {
        ...state,
        currentlySelectedApprover: action.currentlySelectedApprover
      };
    case "SET_APPROVER":
      return {
        ...state,
        approver: action.approver
      };
    case "CLEAR_APPROVAL":
      return INIT_STATE;
  }
};
