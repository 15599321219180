import {
  defaultDataIdFromObject,
  IdGetterObj,
  InMemoryCache,
  IntrospectionFragmentMatcher
} from "apollo-cache-inmemory";
import introspectionQueryResultData from "src/fragmentTypes.json";

const dataIdFromObject = (
  obj: IdGetterObj & {
    pk?: string;
  }
) => {
  // use pk when there can multiple object w same id (e.g. see Document revisions, workflows)
  if ("pk" in obj) {
    return `${obj.__typename}:${obj.pk}`;
  }
  // TODO: remove by February
  if (obj.__typename === "TeamAnalyticsBreakdownEntry") return null;
  return defaultDataIdFromObject(obj);
};

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

export const createCache = () => new InMemoryCache({ dataIdFromObject, fragmentMatcher });
