import { css } from "@emotion/core";
import { csx } from "src/util/csx";

const cardStyles = css`
  box-sizing: border-box;
  background-color: var(--white);
  border: var(--border-lightGrey);
  box-shadow: var(--box-shadow-kanbanCard);
  width: 100%;
  /* default sizeL */
  border-radius: var(--border-radius-xxl);
  padding: var(--space-4-rem) var(--space-5-rem);
`;

export const Card = csx([cardStyles], {
  sizeS: css`
    border-radius: var(--border-radius-s);
    padding: var(--space-2-rem) var(--space-3-rem);
  `,
  sizeM: css`
    border-radius: var(--border-radius-l);
    padding: var(--space-3-rem) var(--space-4-rem);
  `,
  sizeL: css`
    border-radius: var(--border-radius-xl);
    padding: var(--space-4-rem) var(--space-5-rem);
  `,
  hasExtraPadding: css`
    /* use only for sizeL */
    padding: var(--space-5-rem) var(--space-5-rem);
  `,
  selected: css`
    border-color: var(--text-3);
  `,
  hasInteractions: css`
    transition: box-shadow ease-in-out 0.2s;
    cursor: pointer;
    &:hover,
    &:focus-within {
      box-shadow: var(--box-shadow-kanbanCardHover);
    }
  `
});
