import { css } from "@emotion/core";
import styled from "@emotion/styled";
import chroma from "chroma-js";
import { ErrorMessage, Field, FieldProps } from "formik";
import * as React from "react";
import { ReactNode } from "react";
import { ICommonStyleProps, MaterialIcon } from "src/components";
import { transitions } from "src/styling/effects";
import { __rawColorValues } from "src/styling/tokens/__colors";
import { csx } from "src/util/csx";
import { FormLabel } from "./FormWrappers";

const Wrapper = styled.div<{ fullWidth?: boolean; error?: boolean }>`
  width: ${p => (p.fullWidth ? "100%" : "auto")};
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-s);
  border: 1px solid var(--text-1);

  & .datepicker-arrow {
    color: hsl(0, 0%, 80%);
    background: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &:hover {
    border: 1px solid var(--text-1);

    & .datepicker-arrow {
      color: hsl(0, 0%, 70%);
    }
  }

  &:focus-within {
    border: 1px solid var(--text-3);

    & .datepicker-arrow {
      color: var(--text-4);
    }
  }

  & .icon {
    margin: 0.38rem 0.6rem;
    color: var(--border);

    &:hover {
      cursor: default;
    }
  }
`;

export const Input = styled.input<
  ICommonStyleProps & {
    fullWidth?: boolean;
    hasError?: boolean;
  }
>`
  flex: ${p => p.flex || "auto"};
  width: ${p => (p.fullWidth ? "100%" : "auto")};
  min-height: 2.625rem;
  box-sizing: border-box;
  font-size: 0.875rem;
  font-family: inherit;
  outline: none;
  padding: 0.5rem 1rem;
  background-color: var(--white);
  color: var(--text-6);
  border-radius: var(--border-radius-s);
  border: 1px solid ${p => (p.hasError ? "var(--red-7)" : "var(--text-1)")};

  &[disabled] {
    cursor: not-allowed;
  }

  &:not([disabled]):hover {
    border: 1px solid ${p => (p.hasError ? "var(--red-7)" : "var(--text-6)")};
  }

  &:focus-within {
    border: 1px solid ${p => (p.hasError ? "var(--red-7)" : "var(--text-6)")};
  }

  &[type="date"] {
    padding: 0 1rem 0 0.5rem;
    height: 2.25rem;
  }
  &[type="date"]::-webkit-inner-spin-button {
    display: none;
  }
  &[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }
`;

const InputBorderless = styled(Input)`
  border: none;

  &:hover {
    border: none;
  }

  &:focus-within {
    border: none;
  }
`;

export const ErrorLabel = styled.span<{ align?: string; margin?: string }>`
  font-size: 0.75rem;
  font-style: italic;
  color: var(--red-7);
  padding: 0;
  height: 1rem;
  margin: ${p => p.margin || "0.25rem 0 0.75rem"};
  width: 100%;
  text-align: ${p => p.align || "unset"};
`;

/**
 * Helper text to display below inputs
 */
export const HelperText = styled.span`
  font-size: 0.75rem;
  font-style: italic;
  padding: 0;
  height: 1rem;
  margin: 0.5rem 0;
  width: 100%;
  color: var(--text-4);
`;

/**
 * This overlay div is used for (optional) custom formatting of the input field's
 * current value. It is placed over the actual input element, if the InputField's
 * props contain a custom formatter() function. If present, the field passes the
 * current value to the formatter() and displays the return value in the overlay
 * div.
 */
const CustomValueFormat = styled.div<{ hasIcon?: boolean }>`
  position: absolute;
  top: 1px;
  left: ${p => (p.hasIcon ? "2.7" : "0")}rem;
  bottom: 1px;
  right: 1px;
  padding: 0.5rem;
  box-sizing: border-box;
  font-size: 0.875rem;
  pointer-events: none;
  outline: none;
  background-color: var(--white);
  color: var(--text-6);

  &.placeholder {
    color: var(--text-1);
  }
`;

const ArrowIndicator = styled.div`
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  margin: 0.5rem 0;
  padding: 0 0.5rem;
  pointer-events: none;

  & svg {
    stroke: currentColor;
    fill: currentColor;
    stroke-width: 0;
  }
`;

// input component with labeling and error messaging / highlight
// theoretically works for type email, date etc.
// autocomplete off by default
export const InputField = (props: {
  type?: string;
  label?: string;
  icon?: string;
  name: string;
  placeholder?: string;
  autoComplete?: string;
  fullWidth?: boolean;
  noBorder?: boolean;
  errorMessage?: string;
  value?: string;
  "data-testid"?: string;
  formatter?(value?: string): string;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  onBlur(e: React.ChangeEvent<HTMLInputElement>): void;
}) => (
  <React.Fragment>
    {props.label && <FormLabel htmlFor={props.name}>{props.label}</FormLabel>}
    <Wrapper fullWidth={props.fullWidth} error={!!props.errorMessage}>
      {props.icon && <MaterialIcon path={props.icon} size={1.5} />}
      <InputBorderless
        id={props.name}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder || ""}
        autoComplete={props.autoComplete || "off"}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        fullWidth={props.fullWidth}
        data-testid={props["data-testid"]}
        spellCheck={true}
      />
      {props.formatter && props.value && (
        <CustomValueFormat hasIcon={!!props.icon}>{props.formatter(props.value)}</CustomValueFormat>
      )}
      {props.formatter && !props.value && (
        <CustomValueFormat hasIcon={!!props.icon} className="placeholder">
          {props.placeholder}
        </CustomValueFormat>
      )}
      {/* TODO: The datepicker markup and styling is pretty messy, but we want to replace it soon anyway,
       * so it's okay for now. */}
      {props.type?.toLowerCase() === "date" && (
        <ArrowIndicator className="datepicker-arrow">
          <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
          </svg>
        </ArrowIndicator>
      )}
    </Wrapper>
    {props.errorMessage && <ErrorLabel>{props.errorMessage}</ErrorLabel>}
  </React.Fragment>
);

export const TextArea = styled.textarea<
  ICommonStyleProps & {
    fullWidth?: boolean;
    height?: string;
    hasError?: boolean;
  }
>`
  flex: ${p => p.flex || "1 0 auto"};
  padding: 0.5rem 1rem;
  width: ${p => (p.fullWidth ? "100%" : "auto")};
  height: ${p => (p.height ? p.height : "auto")};
  box-sizing: border-box;
  font-size: var(--font-size-body);
  font-family: inherit;
  resize: none;
  border: 1px solid ${p => (p.hasError ? "var(--red-7)" : "var(--text-1)")};
  border-radius: var(--border-radius-s);
  outline: none;
  background-color: var(--white);
  color: var(--text-6);

  &:hover {
    border: 1px solid ${p => (p.hasError ? "var(--red-7)" : "var(--text-6)")};
  }

  &:focus-within,
  &:focus {
    border: 1px solid ${p => (p.hasError ? "var(--red-7)" : "var(--text-6)")};
  }

  &::placeholder {
    color: var(--text-1);
  }
`;

// textarea component with labeling and error messaging / highlight
export const TextAreaField = (
  props: FieldProps<string> & {
    type?: string;
    label?: string;
    name: string;
    placeholder?: string;
    autoComplete?: string;
    "data-testid"?: string;
    fullWidth?: boolean;
    height?: string;
    errorMessage?: string;
    handleChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    hideErrorLabel: boolean;
    autoFocus?: boolean;
  }
) => {
  const error =
    props.form.touched[props.field.name as keyof typeof props.form.touched] &&
    props.form.errors[props.field.name as keyof typeof props.form.errors];
  return (
    <FormikField error={!!error} fullWidth={props.fullWidth}>
      {props.label && <FormLabel htmlFor={props.field.name}>{props.label}</FormLabel>}
      <TextArea
        {...props.field}
        id={props.field.name}
        name={props.field.name}
        placeholder={props.placeholder || ""}
        autoComplete={props.autoComplete || "off"}
        fullWidth={props.fullWidth}
        height={props.height}
        hasError={!!error}
        data-testid={props["data-testid"]}
        onChange={props.handleChange || props.field.onChange}
        autoFocus={props.autoFocus}
        spellCheck={true}
      />
      {!props.hideErrorLabel && (
        <ErrorLabel>
          <ErrorMessage name={props.field.name} />
        </ErrorLabel>
      )}
    </FormikField>
  );
};

/**
 * Styles field labels in active, error states
 * NB: focus-within https://caniuse.com/#search=focus-within
 */
export const FormikField = csx(
  [
    css`
      display: flex;
      flex-direction: column;
      padding: 0;
      width: auto;

      & label {
        font-size: var(--font-size-body);
        font-weight: var(--font-weight-body-medium);
        margin-bottom: var(--space-1-rem);
      }

      & span {
        font-size: 0.75rem;
        color: var(--text-4);
      }

      &:focus-within {
        & span {
          color: var(--text-6);
        }

        & label {
          color: var(--text-6);
        }
      }

      & input::placeholder {
        color: var(--border);
      }

      & label.radio {
        display: flex;
        align-items: center;
        margin: var(--space-1-rem) 0;
        font-size: var(--font-size-body);
        font-weight: var(--font-weight-body-regular);
        color: var(--text-6);
        cursor: pointer;

        & input {
          appearance: none;
          outline: none;
          width: 0.75rem;
          height: 0.75rem;
          margin: 0 0.5rem 0 0;
          border-radius: var(--border-radius-round);
          border-style: solid;
          border-width: 0.375rem;
          border-color: var(--white);
          background: var(--blue-1);
          box-shadow: 0 0 0 1px var(--text-1);
          transition: background ${transitions.colors}, border-width ${transitions.colors};

          &:hover,
          &:checked {
            border-width: 0.125rem;
          }

          &:hover {
            background: rgba(var(--blue-1-raw), 0.3);
          }

          &:checked {
            background: var(--blue-1);
          }
        }
      }

      & input[type="checkbox"] {
        flex: 0 0 auto;
        appearance: none;
        outline: none;
        width: 0.75rem;
        height: 0.75rem;
        margin-right: 0.75rem;
        border-radius: 0.125rem;
        box-shadow: 0 0 0 1px var(--yellow-2);
      }
    `
  ],
  {
    fullWidth: css`
      width: 100%;
    `,
    error: css`
      & span,
      &:focus-within span {
        color: var(--red-7);
      }
    `
  }
);

/* NB deprecated in React */
FormikField.defaultProps = {
  className: "field"
};

/**
 * Formik input component
 */
export const FormikInput = (
  props: FieldProps<string> & {
    label?: string;
    type?: string;
    placeholder?: string;
    hideErrorLabel?: boolean;
    "data-testid"?: string;
    autoComplete?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    autoFocus?: boolean;
    handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  }
) => {
  const error =
    props.form.touched[props.field.name as keyof typeof props.form.touched] &&
    props.form.errors[props.field.name as keyof typeof props.form.errors];
  return (
    <FormikField error={!!error} fullWidth={props.fullWidth}>
      {props.label && <FormLabel htmlFor={props.field.name}>{props.label}</FormLabel>}
      <Input
        {...props.field}
        hasError={!!error}
        type={props.type}
        id={props.field.name}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete || "off"}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        onChange={props.handleChange || props.field.onChange}
        data-testid={props["data-testid"]}
        spellCheck={true}
      />
      {!props.hideErrorLabel && (
        <ErrorLabel>
          <ErrorMessage name={props.field.name} />
        </ErrorLabel>
      )}
    </FormikField>
  );
};

const helperText = css`
  font-size: var(--font-size-helper);
  font-style: italic;
  color: var(--text-3);
`;

export const FormikFieldGroup = {
  Container: (
    props: React.PropsWithChildren<{ legend: React.ReactNode; helperLegend?: string; className?: string }>
  ) => {
    return (
      <div
        className={props.className}
        css={css`
          & input::placeholder {
            color: var(--border);
          }
        `}
      >
        <div
          css={css`
            font-family: var(--font-family-body);
            line-height: var(--line-height-normal);
            color: var(--text-6);
            font-weight: var(--font-weight-body-medium);
          `}
        >
          {props.legend}
        </div>
        {props.helperLegend && <div css={helperText}>{props.helperLegend}</div>}
        <div
          css={css`
            margin-bottom: var(--space-2-rem);
          `}
        />
        {props.children}
      </div>
    );
  },
  HelpText: csx([helperText]),
  Errors: (
    props: React.PropsWithChildren<{
      className?: string;
    }>
  ) => {
    return (
      <div
        className={props.className}
        css={css`
          min-height: 1.875rem;

          & > :first-of-type::first-letter {
            text-transform: capitalize;
          }
        `}
      >
        <ErrorLabel margin="var(--space-1-rem) 0 var(--space-1-rem)">{props.children}</ErrorLabel>
      </div>
    );
  }
};

export const FormikFieldInputs = {
  Checkbox: (
    props: React.PropsWithChildren<{ name: string; label: ReactNode; helperLabel?: ReactNode; disabled?: boolean }>
  ) => (
    <Field name={props.name}>
      {(fieldProps: FieldProps) => (
        <div
          css={css`
            display: flex;
          `}
        >
          <Checkbox
            {...fieldProps.field}
            disabled={props.disabled}
            id={props.name}
            checked={fieldProps.field.value}
            css={css`
              margin-right: var(--space-2-rem);
            `}
          />
          <div>
            <label
              htmlFor={props.name}
              css={[
                css`
                  font-size: var(--font-size-body);
                  line-height: 150%;
                  user-select: none;
                  cursor: pointer;
                `,
                props.disabled &&
                  css`
                    color: var(--text-2);
                    cursor: unset;
                  `
              ]}
            >
              {props.label}
            </label>
            {props.helperLabel && <div css={helperText}>{props.helperLabel}</div>}
          </div>
        </div>
      )}
    </Field>
  ),
  Radio: (props: React.PropsWithChildren<{ name: string; value: string }>) => (
    <Field name={props.name}>
      {(fieldProps: FieldProps) => (
        <label
          css={css`
            display: flex;
            align-items: center;
            margin-top: var(--space-3-px);
            font-family: var(--font-family-body);
            font-size: var(--font-size-body);
            line-height: var(--line-height-normal);
            color: var(--text-6);
            cursor: pointer;

            & * + * {
              margin-left: var(--space-2-px);
            }
          `}
        >
          <input
            {...fieldProps.field}
            type="radio"
            value={props.value}
            checked={fieldProps.field.value === props.value}
          />
          <span>{props.children}</span>
        </label>
      )}
    </Field>
  )
};

const checkboxSvgHover = encodeURIComponent(chroma(__rawColorValues.blue_1).alpha(0.3).css());
const checkboxSvgCheckedHover = encodeURIComponent(__rawColorValues.blue_1);

export const Checkbox = styled.input`
  flex: 0 0 auto;
  appearance: none;
  outline: none;
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
  border-radius: 2px;
  border: 1px solid var(--text-1);
  transition: background ${transitions.colors}, border-width ${transitions.colors}, background-image ${transitions.colors};

  &:checked,
  &:checked:hover {
    /* material check icon */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='${checkboxSvgCheckedHover}' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z'/%3E%3C/svg%3E");
  }

  /* stylelint-disable */
  &:hover:not([disabled]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='${checkboxSvgHover}' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z'/%3E%3C/svg%3E");
  }
  /* stylelint-enable */
`;

/* NB deprecated in React */
Checkbox.defaultProps = {
  type: "checkbox"
};

/**
 * Component to create a Checkbox with a Children props next to it. It was created to be able to display the error message
 * in a line below the overall component and not only under the checkbox.
 */
export const FormikRequiredCheckbox: React.FC<{ name: string }> = props => {
  return (
    <Field name={props.name}>
      {(fieldProps: FieldProps) => (
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <label
            css={css`
              display: flex;
              cursor: pointer;
            `}
          >
            <Checkbox {...fieldProps.field} />
            {props.children}
          </label>
          <ErrorLabel>
            <ErrorMessage name={fieldProps.field.name} />
          </ErrorLabel>
        </div>
      )}
    </Field>
  );
};
