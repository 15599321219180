import { FormGet_form_schema_fieldsList } from "src/App/Forms/Employee/typings/FormGet";
import { FieldType, TextFieldType } from "src/globalTypes";
import { reportDevError } from "src/util";

/** unique id across field type & text sub type */
export const fieldId = (fieldType: FieldType, textFieldType?: TextFieldType) =>
  textFieldType ? `${fieldType}:${textFieldType}` : fieldType;

export const fieldName = (fieldType: FieldType, textFieldType?: TextFieldType): string => {
  switch (fieldType) {
    case FieldType.ATTACHMENT_FIELD:
      return "Attachment";
    case FieldType.DROPDOWN_FIELD:
      return "Dropdown";
    case FieldType.TEXT_FIELD:
      switch (textFieldType) {
        case TextFieldType.LONG_TEXT:
          return "Long text";
        case TextFieldType.SHORT_TEXT:
          return "Short text";
        case TextFieldType.EMAIL:
          return "Email";
        case TextFieldType.URL:
          return "URL";
        case TextFieldType.DATE:
          return "Date";
        case TextFieldType.NUMBER:
          return "Number";
        default:
          reportDevError("missing text field");
          return "Unknown field";
      }
  }
};

export function resolveFieldType(typename: FormGet_form_schema_fieldsList["__typename"]): FieldType {
  switch (typename) {
    case "AttachmentsField":
      return FieldType.ATTACHMENT_FIELD;
    case "DropdownField":
      return FieldType.DROPDOWN_FIELD;
    case "TextField":
      return FieldType.TEXT_FIELD;
  }
}

export const getAuthToken: () => string | null = () =>
  new URL(window.location.href).searchParams.get("auth_token") || null;
