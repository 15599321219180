import { css } from "@emotion/core";
import { PureQueryOptions } from "apollo-client";
import gql from "graphql-tag";
import React from "react";
import { useMutation } from "react-apollo";
import { LoadingBar, PillButton } from "src/components";
import { Icon } from "src/components/Icon";
import { PopOver } from "src/components/PopOver";
import { RequestStatus } from "src/globalTypes";
import { Toast } from "src/portals/Toast";
import { ProjectClosed } from "src/svg/icons/ProjectClosed";
import { ProjectOpen } from "src/svg/icons/ProjectOpen";
import { ProjectChangeStatus, ProjectChangeStatusVariables } from "./typings/ProjectChangeStatus";

/* For now, here is the canonical place to define project status  */
export type ProjectStatus = RequestStatus.NOTSTARTED | RequestStatus.RESOLVED;
export const projectStatusMap: {
  [key in "open" | "closed"]: ProjectStatus;
} = {
  open: RequestStatus.NOTSTARTED,
  closed: RequestStatus.RESOLVED
};

function assertProjectStatus(status: RequestStatus): asserts status is ProjectStatus {
  if (!Object.values(projectStatusMap).includes(status as ProjectStatus)) {
    throw new Error(`Invalid status type for project: ${status}`);
  }
}

function statusText(status: ProjectStatus) {
  switch (status) {
    case RequestStatus.NOTSTARTED:
      return "Open";
    case RequestStatus.RESOLVED:
      return "Closed";
  }
}

function ProjectStatusIcon(props: { status: ProjectStatus }) {
  switch (props.status) {
    case RequestStatus.NOTSTARTED:
      return (
        <Icon sizeL>
          <ProjectOpen />
        </Icon>
      );
    case RequestStatus.RESOLVED:
      return (
        <Icon sizeL>
          <ProjectClosed />
        </Icon>
      );
  }
}

const PROJECT_CHANGE_STATUS = gql`
  mutation ProjectChangeStatus($projectId: ID!, $oldStatus: RequestStatus, $newStatus: RequestStatus!) {
    requestChangeStatus(requestId: $projectId, oldStatus: $oldStatus, newStatus: $newStatus) {
      code
      success
      message
    }
  }
`;

export const SelectProjectStatus: React.FC<{
  projectId: string;
  status: RequestStatus;
  className?: string;
  queriesToRefetch?: Array<string | PureQueryOptions>;
}> = props => {
  assertProjectStatus(props.status);

  const [projectChangeStatus, projectChangeStatusResponse] = useMutation<
    ProjectChangeStatus,
    ProjectChangeStatusVariables
  >(PROJECT_CHANGE_STATUS, {
    awaitRefetchQueries: true,
    refetchQueries: props.queriesToRefetch
  });

  const selectedStatus = props.status;
  return (
    <>
      {projectChangeStatusResponse.data?.requestChangeStatus.success === false && (
        <Toast kind="mutationError" message={projectChangeStatusResponse.data?.requestChangeStatus.message} />
      )}
      {projectChangeStatusResponse.loading && <LoadingBar />}

      <PopOver.Menu
        options={[
          {
            headline: "Change status",
            options: Object.values(projectStatusMap).map(status => ({
              id: status,
              name: statusText(status)
            }))
          }
        ]}
        selected={selectedStatus}
        onSelect={(status: RequestStatus) =>
          projectChangeStatus({
            variables: {
              projectId: props.projectId,
              newStatus: status,
              oldStatus: selectedStatus
            }
          })
        }
        trigger={
          <PillButton
            disabled={projectChangeStatusResponse.loading}
            className={props.className}
            css={css`
              align-self: unset;
            `}
          >
            <div
              css={css`
                display: flex;

                & > * + * {
                  margin-left: 5px;
                }
              `}
            >
              <ProjectStatusIcon status={selectedStatus} />
              <span>{statusText(selectedStatus)}</span>
            </div>
          </PillButton>
        }
      />
    </>
  );
};
