import { ApolloLink, FetchResult, Operation } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { GraphQLError } from "graphql";
import { subscriptionExpiredRedirect } from "src/App/Settings/Subscription/Expired";
import { apolloEmitter } from "src/util/apollo/emitter";
import { navigate } from "src/util/router";

export enum ErrorCode {
  UNAUTHENTICATED = "16 UNAUTHENTICATED",
  UNSUBSCRIBED = "7 PERMISSION_DENIED: subscription inactive"
}

/**
 * Link to intercept graphql responses
 */
export function responseHandlerLink() {
  return new ApolloLink((operation, forward) =>
    !forward
      ? null
      : forward(operation).map(response => {
          handleResponse(operation, response);
          return response;
        })
  );
}

/**
 * Handler for graphql responses
 */
function handleResponse(operation: Operation, response: FetchResult) {
  apolloEmitter.emit({ type: "network", offline: false });
  // Server-side exceptions caught through apollo
  if (response.errors) {
    if (shouldRedirectLogin(response.errors)) {
      // set redirect parameter to go back to original page
      const queryParameters = new URLSearchParams();
      queryParameters.append("redirect", window.location.pathname + window.location.search);
      navigate(`/login?${queryParameters}`);
    } else if (shouldRedirectPayUs(response.errors)) {
      navigate(subscriptionExpiredRedirect(window.location.pathname));
    }
    response.errors
      .filter(
        error =>
          error.message.indexOf(ErrorCode.UNAUTHENTICATED) === -1 &&
          error.message.indexOf(ErrorCode.UNSUBSCRIBED) === -1
      )
      .forEach(error => {
        // eslint-disable-next-line no-console
        console.log(`[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`);
        const queryDefinition = operation.query.definitions[0];
        const queryDefinitionOperation = "operation" in queryDefinition && queryDefinition.operation;
        if (queryDefinitionOperation === "mutation") {
          apolloEmitter.emit({ type: "error", message: error.message });
        } else if (process.env.NODE_ENV !== "production") {
          apolloEmitter.emit({ type: "error", message: `DEV GRAPHQL ERROR: ${error.message}` });
        }
      });
  }
}

/**
 * Link to intercept network errors
 */
export function errorHandlerLink() {
  return onError(({ networkError }) => {
    if (process.env.NODE_ENV !== "production") {
      apolloEmitter.emit({ type: "error", message: `DEV HTTP ERROR: ${networkError}` });
    }
    if (networkError) {
      // eslint-disable-next-line no-console
      console.log(`[Network error]: ${networkError}`);
      apolloEmitter.emit({ type: "network", offline: true });
    }
  });
}

export const setHeaders = setContext((_request, previousContext) => ({
  headers: { ...previousContext.headers }
}));

/**
 * Paths that won't redirect to login
 */
const externalPaths = [
  "/confirmation",
  "/invitation",
  "/invited",
  "/login",
  // TODO: remove
  "/auth",
  "/register",
  "/status",
  "/approval",
  "/response",
  "/password"
];

type GraphQLErrorArray = readonly GraphQLError[];

function shouldRedirectLogin(errors: GraphQLErrorArray) {
  const onExternalPage = externalPaths.some(path => window.location.pathname.indexOf(path) === 0);
  return !onExternalPage && errors.some(error => error.message.indexOf(ErrorCode.UNAUTHENTICATED) > -1);
}

function shouldRedirectPayUs(errors: GraphQLErrorArray) {
  const externalSubPaths = externalPaths.filter(p => !p.includes("/login"));
  const onExternalPage = externalSubPaths.some(path => window.location.pathname.indexOf(path) === 0);
  return !onExternalPage && errors.some(error => error.message.indexOf(ErrorCode.UNSUBSCRIBED) > -1);
}
