import * as React from "react";

import { FC } from "react";
import { Icon } from "src/components";
import { PlainArrowRight } from "src/svg/icons/PlainArrowRight";
import { css } from "@emotion/core";
import { csx } from "src/util/csx";
import { motion } from "framer-motion";

// 1rem + 2px border
const minHeight = "34px";
export const CollapsableWorkflowSection: FC<{ collapsed: boolean }> = props => (
  <motion.div
    css={css`
      box-sizing: border-box;
      padding: var(--space-2-rem) var(--space-5-rem) var(--space-2-rem) var(--space-2-rem);
      margin-left: var(--minus-2-rem);
      margin-top: var(--minus-2-rem);
      border-radius: var(--border-radius-s);
    `}
    initial={{ height: "auto" }}
    animate={{
      overflow: props.collapsed ? "hidden" : "visible",
      height: props.collapsed ? minHeight : "auto"
    }}
    exit={{ height: "auto" }}
    transition={{ ease: "easeInOut", duration: 0.3 }}
  >
    {props.children}
  </motion.div>
);

export const CollapsableWorkflowSectionHeading = csx(
  [
    css`
      display: flex;
      cursor: pointer;
      & > * + * {
        margin-left: var(--space-2-rem);
        transition: 0.3s transform ease;
      }
      & + * {
        /* first elements arrow */
        position: relative;
        &:before {
          content: "";
          box-sizing: border-box;
          position: absolute;

          width: var(--space-4-rem);
          top: var(--minus-2-rem);
          height: var(--space-6-rem);

          left: 0;
          right: 0;

          border-left: 1px solid var(--lightGrey-3);
          border-bottom: 1px solid var(--lightGrey-3);
          border-bottom-left-radius: var(--space-4-rem);

          opacity: 1;
          transition: opacity 0.3s ease-in-out;
        }
      }
      &:hover {
        & > * + * {
          transform: translateX(var(--space-1-rem));
        }
      }
    `
  ],
  {
    disabled: css`
      cursor: not-allowed;
      &:hover {
        & > * + * {
          transform: translateX(0);
        }
      }
    `,
    collapsed: css`
      & + * {
        /* first elements arrow */
        &:before {
          opacity: 0;
        }
      }
    `
  }
);

export const SectionIcon: FC<{ collapsed: boolean }> = props => (
  <div
    css={[
      css`
        width: 1rem;
        height: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--lightGrey-3);
        border-radius: var(--border-radius-s);
        /* overlay svg line */
        background-color: var(--lightGrey-1);
        z-index: var(--z-low);

        transform: rotate(90deg);
        transition: 0.3s transform ease;
      `,
      props.collapsed &&
        css`
          transform: rotate(0);
        `
    ]}
  >
    <Icon
      css={[
        css`
          & > svg {
            height: 0.5rem;
          }
        `
      ]}
    >
      <PlainArrowRight />
    </Icon>
  </div>
);
