import { css } from "@emotion/core";
import * as React from "react";
import { FormAction } from "src/App/Forms/Manager/reducer";
import { Button, Menu, MenuItem } from "src/components";
import { Indicator } from "src/components/Fields/Select";
import { PopOver } from "src/components/PopOver";
import { ApprovalAction } from "./Approvals";
import { IInputState, TInputAction } from "./reducer";

const inputTypeLabel = {
  COMMENTS: "Reply",
  NOTES: "Internal note",
  APPROVALS: "Approval",
  FORMS: "Form"
};

/**
 * Pop up menu to switch been comments / notes
 */
export const InputMenu: React.ComponentType<{
  inputType: IInputState["inputType"];
  inputDispatch: React.Dispatch<TInputAction>;
  formDispatch: React.Dispatch<FormAction>;
  approvalDispatch: React.Dispatch<ApprovalAction>;
}> = props => {
  return (
    <PopOver.Blank
      button={
        <Button type="button" data-testid="input-type-button">
          {inputTypeLabel[props.inputType]}
          <Indicator
            css={css`
              margin: 0 0 0 0.5rem;
            `}
          />
        </Button>
      }
    >
      <Menu>
        <MenuItem
          data-testid="input-type-item"
          onClick={() => {
            props.inputDispatch({
              type: "SET_INPUT_TYPE",
              inputType: "COMMENTS"
            });
          }}
        >
          Reply
        </MenuItem>
        <MenuItem
          data-testid="input-type-item"
          onClick={() => {
            props.inputDispatch({
              type: "SET_INPUT_TYPE",
              inputType: "NOTES"
            });
          }}
        >
          Internal note
        </MenuItem>
        <MenuItem
          data-testid="input-type-item"
          onClick={() => {
            props.inputDispatch({
              type: "SET_INPUT_TYPE",
              inputType: "APPROVALS"
            });
            props.approvalDispatch({ type: "SHOW_MODAL" });
          }}
        >
          Approval
        </MenuItem>
        <MenuItem
          data-testid="input-type-item"
          onClick={() => {
            props.inputDispatch({
              type: "SET_INPUT_TYPE",
              inputType: "FORMS"
            });
            props.formDispatch({
              type: "SET_MODAL",
              modal: "choose"
            });
          }}
        >
          Form
        </MenuItem>
      </Menu>
    </PopOver.Blank>
  );
};
