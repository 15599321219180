import { css } from "@emotion/core";
import { mdiChevronLeft } from "@mdi/js";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AssignExpertButton } from "src/App/Requests/Actions/AssignExpertButton";
import { ChangeDueDatePicker } from "src/App/Requests/Actions/ChangeDueDatePicker";
import { ChangeProjectButton } from "src/App/Requests/Actions/ChangeProjectButton";
import { ActionsContext } from "src/App/Requests/Actions/Provider";
import { priorityLabels, RequestPriorityButton } from "src/App/Requests/Actions/RequestPriorityButton";
import { ActionEnum, actionMenuLabels, notificationMessage, requestDetailPath } from "src/App/Requests/ListView";
import { RequestListItemProps, RequestRowWrapper, RequestUpdates } from "src/App/Requests/ListView/RequestCard";
import { SlaStatusBadge } from "src/App/Requests/ListView/SlaStatusBadge";
import { useCurrentUser } from "src/App/Root/providers/CurrentUserProvider";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { UserName } from "src/App/User";
import { AbsoluteTooltip, Button, ConditionalTooltip, Hide, MaterialIcon, Tooltip } from "src/components";
import { Badge } from "src/components/Badges";
import { Pill } from "src/components/PillButton";
import { OptionMap, PopOver } from "src/components/PopOver";
import { ColorVariantsUnion, Status } from "src/components/StatusIndicator";
import { RequestPriority, RequestStatus, TeamPrivacy } from "src/globalTypes";
import { Toast } from "src/portals/Toast";
import { dimensions } from "src/styling/layout";
import { Typo } from "src/styling/primitives/typography";
import { formatDueDate, formatTitle } from "src/util/formatters";
import { Link } from "src/util/router";
import { useRequestList } from "./Provider";

const requestRow = css`
  display: block;
  height: 100%;
  margin: 0 auto;
  padding: var(--space-4-px) 0;
  box-sizing: border-box;
  border-bottom: var(--border-lightGrey);
  min-width: ${dimensions.card.minWidth}rem;
  max-width: ${dimensions.card.maxWidth}rem;
  flex-grow: 1;
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  &:hover {
    box-shadow: var(--box-shadow-condensedItem);
  }

  /* hack to hide slight grey vertical box shadow */
  &:before,
  &:after {
    content: "";
    background-color: white;
    height: 100%;
    width: 1px;
    position: absolute;
    top: 0;
  }
  &:before {
    left: -1px;
  }
  &:after {
    right: -1px;
  }

  > div > div {
    flex-wrap: wrap;
    height: 100%;
    display: flex;
    flex: 1 1 auto;
  }
`;

const badges = css`
  display: flex;
  flex-wrap: nowrap;
  & > * + * {
    margin-left: var(--space-2-rem);
  }
`;

export const CondensedRequestListItem: React.FC<
  RequestListItemProps & {
    isExpanded: boolean;
  }
> = props => {
  const { currentUser } = useCurrentUser();
  const { isExpanded, request, requestUpdates, updateVisiblePills } = props;
  const { handlers, options, optionsByTeam } = useContext(ActionsContext);
  const { emitSnack } = useSnack();

  const [requestList, dispatch] = useRequestList();
  const pillRefs = {
    [ActionEnum.ASSIGNEE]: useRef<HTMLButtonElement>(null),
    [ActionEnum.CATEGORY]: useRef<HTMLButtonElement>(null),
    [ActionEnum.DUEDATE]: useRef<HTMLButtonElement>(null),
    [ActionEnum.PROJECT]: useRef<HTMLButtonElement>(null),
    [ActionEnum.PRIORITY]: useRef<HTMLButtonElement>(null)
  };
  const [lastVisiblePills, setLastVisiblePills] = useState<{ [pillId: string]: boolean }>({});

  const visiblePills = requestList.state.visiblePills[request.id];
  useEffect(() => {
    // diff list of visible pills and trigger a click if needed
    const visiblePillIds = visiblePills ? Object.keys(visiblePills).filter(pillId => visiblePills[pillId]) : [];
    const lastVisiblePillIds =
      lastVisiblePills && Object.keys(lastVisiblePills).filter(pillId => lastVisiblePills[pillId]);
    if (visiblePills && Object.keys(visiblePills).length && visiblePillIds.join("-") !== lastVisiblePillIds.join("-")) {
      // quick check if the selection changed
      const pillToTrigger =
        visiblePillIds &&
        !!visiblePillIds.length &&
        (visiblePillIds.find(pill => lastVisiblePillIds && !lastVisiblePillIds.includes(pill)) as ActionEnum);

      if (pillToTrigger && pillRefs[pillToTrigger] && pillRefs[pillToTrigger].current) {
        pillRefs[pillToTrigger].current?.click();
      }

      setLastVisiblePills(visiblePills);
    }
  }, [visiblePills, lastVisiblePills, setLastVisiblePills, pillRefs]);

  const removePillFromVisiblePills = (pillId: ActionEnum) => {
    visiblePills && !!visiblePills[pillId] && updateVisiblePills && updateVisiblePills(request.id, pillId);
  };

  const requestCurrentCustomStatus = props.request.customStatus;

  const requestTeam = requestList.response.data?.teamList.find(t => t.id === request.team.id);
  if (!requestTeam) {
    return <Toast kind="mutationError" message="Team is not defined" />;
  }
  const noAccess = requestTeam.privacy === TeamPrivacy.PRIVATE && !requestTeam.userIds.includes(currentUser?.id ?? "");

  const hideAssigneePill =
    !(visiblePills && visiblePills[ActionEnum.ASSIGNEE]) && !(request.assignee && request.assignee.id);
  const hideCategoryPill =
    !(visiblePills && visiblePills[ActionEnum.CATEGORY]) && !(request.category && request.category.id);
  const hideDueDatePill = !(visiblePills && visiblePills[ActionEnum.DUEDATE]) && !request.dueAt;
  const hideProjectPill = !(visiblePills && visiblePills[ActionEnum.PROJECT]) && !request.project;
  const hidePriorityPill =
    !(visiblePills && visiblePills[ActionEnum.PRIORITY]) && request.priority === RequestPriority.PRIORITY_UNSPECIFIED;

  const formattedDueDate = request.dueAt ? formatDueDate(request.dueAt) : "";
  const showActions =
    !noAccess && (hideAssigneePill || hideCategoryPill || hideDueDatePill || hideProjectPill || hidePriorityPill);

  const isUrgent =
    (formattedDueDate === "today" || formattedDueDate.includes("ago") || formattedDueDate.includes("yesterday")) &&
    request &&
    request.customStatus.step !== RequestStatus.RESOLVED;

  const unassigned = props.request.assignee === null;
  const assignedToMe = !!(currentUser && request.assignee && currentUser.id === request.assignee.id);
  const unreadMessage = requestUpdates ? notificationMessage({ requestUpdates, assignedToMe, unassigned }) : null;

  return (
    <RequestRowWrapper
      style={props.style}
      data-testid="condensed-request-loaded"
      css={css`
        &:first-child {
          padding-top: ${requestList.filterBarHeight}px;
        }
      `}
    >
      <Link
        to={!noAccess ? requestDetailPath(props.request.id) : ""}
        state={{
          requestListViewId: requestList.viewId
        }}
        css={requestRow}
      >
        <ConditionalTooltip
          tooltipText={
            noAccess
              ? "You can't access this request because it's assigned to a private team you're not a member of."
              : undefined
          }
        >
          <div>
            <div
              css={[
                css`
                  width: 50%;
                  display: flex;
                  height: var(--space-5-rem);

                  & > * + * {
                    margin-left: var(--space-5-px);
                  }
                `,
                isExpanded &&
                  css`
                    margin-bottom: var(--space-4-px);
                  `
              ]}
            >
              {/* TOP LEFT HALF */}
              <Tooltip
                placement="top"
                target={
                  <PopOver.Menu
                    options={optionsByTeam.changeCustomStatus?.(requestTeam) ?? []}
                    selected={requestCurrentCustomStatus.id}
                    onSelect={(customStatusId: string) => handlers.changeCustomStatus(props.request.id, customStatusId)}
                    trigger={
                      <Status.Indicator
                        condensed
                        requestStatus={requestCurrentCustomStatus.step}
                        customColor={requestCurrentCustomStatus.color as ColorVariantsUnion}
                        data-intercom-target="Request custom status button"
                      />
                    }
                  />
                }
              >
                <span>{`Status: ${request.customStatus?.name}`}</span>
              </Tooltip>

              <div
                css={css`
                  flex: 0 1 auto;
                  margin-left: var(--space-5-px);
                  overflow: hidden;
                `}
              >
                <Tooltip
                  placement="top"
                  target={
                    <Typo.Body sizeL bold={!!props.requestUpdates?.unread} ellipsis>
                      {formatTitle(request.title)}
                    </Typo.Body>
                  }
                >
                  <span>{formatTitle(request.title)}</span>
                </Tooltip>
              </div>
            </div>
            <div
              css={[
                css`
                  width: 50%;
                  display: flex;
                  height: var(--space-5-rem);
                `,
                isExpanded &&
                  css`
                    margin-bottom: var(--space-4-px);
                  `
              ]}
            >
              {/* TOP RIGHT HALF */}
              <div
                css={css`
                  flex: 0 1 auto;
                  margin-left: var(--space-5-px);
                  overflow: hidden;
                `}
              >
                <Tooltip
                  placement="top"
                  target={
                    <Typo.Body ellipsis light>
                      By&nbsp;
                      <UserName user={request.requester} />
                    </Typo.Body>
                  }
                >
                  <span>{`By: ${request.requester.name || request.requester.email}`}</span>
                </Tooltip>
              </div>

              <div
                css={css`
                  flex: 1 0 auto;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  margin-left: var(--space-4-px);
                `}
              >
                {/* Badges and Pills */}
                <div css={badges}>
                  {noAccess && <Badge warning>no access</Badge>}
                  {isUrgent && <Badge warning>Due {formattedDueDate}</Badge>}
                  {props.request.sla && props.request.slaStatus && (
                    <SlaStatusBadge sla={props.request.sla} slaStatus={props.request.slaStatus} />
                  )}
                  {props.requestUpdates && (
                    <div
                      css={css`
                        margin-left: var(--space-2-rem);
                      `}
                    >
                      <RequestUpdates
                        requestUpdates={props.requestUpdates}
                        assignedToMe={assignedToMe}
                        unassigned={unassigned}
                      />
                    </div>
                  )}
                </div>

                <div
                  css={css`
                    flex: 0 0 auto;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    min-width: 134px;
                    margin-left: 1rem;
                    & > * + * {
                      /* Pills top right */
                      margin-left: var(--space-3-px);
                    }
                  `}
                >
                  <Hide when={hideAssigneePill} style={hideAssigneePill ? { marginLeft: 0 } : {}}>
                    <AbsoluteTooltip
                      placement="top"
                      content={<span>{`Assignee: ${request.assignee?.name || "Unassigned"}`}</span>}
                    >
                      <AssignExpertButton
                        ref={pillRefs[ActionEnum.ASSIGNEE]}
                        {...props}
                        team={requestTeam}
                        condensed={true}
                        showText={false}
                        onAssign={() => {
                          removePillFromVisiblePills(ActionEnum.ASSIGNEE);
                        }}
                        onClickAway={e => {
                          e.stopPropagation();
                          removePillFromVisiblePills(ActionEnum.ASSIGNEE);
                        }}
                      />
                    </AbsoluteTooltip>
                  </Hide>

                  <Hide when={hidePriorityPill} style={hidePriorityPill ? { marginLeft: 0 } : {}}>
                    <AbsoluteTooltip
                      placement="top"
                      content={<span>{`Priority: ${priorityLabels[request.priority]}`}</span>}
                    >
                      <RequestPriorityButton
                        requestId={request.id}
                        priority={request.priority}
                        buttonRef={pillRefs[ActionEnum.PRIORITY]}
                        condensed={true}
                        showText={false}
                        onUpdate={() => {
                          removePillFromVisiblePills(ActionEnum.PRIORITY);
                        }}
                      />
                    </AbsoluteTooltip>
                  </Hide>

                  {showActions && (
                    <AbsoluteTooltip placement="top" content={<span>More actions</span>}>
                      <PopOver.Menu
                        options={[
                          {
                            headline: "Actions",
                            options: [
                              !(request.assignee && request.assignee.id) && {
                                id: ActionEnum.ASSIGNEE,
                                name: actionMenuLabels[ActionEnum.ASSIGNEE],
                                onClick: () => {
                                  updateVisiblePills(request.id, ActionEnum.ASSIGNEE);
                                }
                              },
                              !(request.category && request.category.id) && {
                                id: ActionEnum.CATEGORY,
                                name: actionMenuLabels[ActionEnum.CATEGORY],
                                onClick: () => {
                                  updateVisiblePills(request.id, ActionEnum.CATEGORY);
                                  !isExpanded &&
                                    dispatch({
                                      type: "TOGGLE_EXPANDED_REQUEST",
                                      requestId: props.request.id,
                                      userInitiated: false
                                    });
                                }
                              },
                              request.priority === RequestPriority.PRIORITY_UNSPECIFIED && {
                                id: ActionEnum.PRIORITY,
                                name: actionMenuLabels[ActionEnum.PRIORITY],
                                onClick: () => {
                                  updateVisiblePills(request.id, ActionEnum.PRIORITY);
                                }
                              },
                              !request.dueAt && {
                                id: ActionEnum.DUEDATE,
                                name: actionMenuLabels[ActionEnum.DUEDATE],
                                onClick: () => {
                                  updateVisiblePills(request.id, ActionEnum.DUEDATE);
                                  !isExpanded &&
                                    dispatch({
                                      type: "TOGGLE_EXPANDED_REQUEST",
                                      requestId: props.request.id,
                                      userInitiated: false
                                    });
                                }
                              },
                              !request.project && {
                                id: ActionEnum.PROJECT,
                                name: actionMenuLabels[ActionEnum.PROJECT],
                                onClick: () => {
                                  updateVisiblePills(request.id, ActionEnum.PROJECT);
                                  !isExpanded &&
                                    dispatch({
                                      type: "TOGGLE_EXPANDED_REQUEST",
                                      requestId: props.request.id,
                                      userInitiated: false
                                    });
                                }
                              }
                            ].filter(Boolean) as Array<OptionMap>
                          }
                        ]}
                        trigger={<Pill.ContextMenu condensed />}
                      />
                    </AbsoluteTooltip>
                  )}
                  <AbsoluteTooltip placement="top" content={<span>Expand request</span>}>
                    <Button
                      onClick={e => {
                        // prevent request card link from opening
                        e.preventDefault();
                        dispatch({
                          type: "TOGGLE_EXPANDED_REQUEST",
                          requestId: props.request.id,
                          userInitiated: true
                        });
                      }}
                      css={css`
                        width: var(--space-5-px);
                        height: var(--space-5-px);
                      `}
                      data-testid="expand-row-trigger"
                    >
                      <MaterialIcon
                        path={mdiChevronLeft}
                        size={1}
                        css={css`
                          transform: ${isExpanded ? `rotate(0.25turn)` : `rotate(-0.25turn)`};
                        `}
                      />
                    </Button>
                  </AbsoluteTooltip>
                </div>
              </div>
            </div>
            {isExpanded && (
              <>
                <div
                  css={css`
                    box-sizing: border-box;
                    flex: 1 1 auto;
                    display: flex;
                    justify-content: flex-end;
                    height: var(--space-5-rem);
                    /* align vertically with first pill on the right */
                    padding-right: 2.25rem;
                    & > * + * {
                      margin-left: var(--space-2-rem);
                    }
                  `}
                >
                  {/* BOTTOM RIGHT HALF */}
                  <AbsoluteTooltip placement="top" content={<span>{`Team: ${requestTeam.name}`}</span>}>
                    <PopOver.Menu
                      options={options.changeTeam ?? []}
                      selected={request.team.id}
                      onSelect={(teamId: string) => {
                        handlers.changeTeam(request.id, teamId, requestTeam);
                      }}
                      trigger={<Pill.Team condensed data-testid="request-team" teamName={requestTeam.name} />}
                    />
                  </AbsoluteTooltip>
                  <Hide when={hideCategoryPill} style={hideCategoryPill ? { marginLeft: 0 } : {}}>
                    <AbsoluteTooltip placement="top" content={<span>{`Category: ${request.category?.name}`}</span>}>
                      <PopOver.Menu
                        options={optionsByTeam.assignCategory?.(requestTeam) ?? []}
                        selected={request.category?.id}
                        onSelect={(categoryId: string | null) =>
                          handlers.assignCategory(
                            props.request.id,
                            categoryId,
                            props.request.category?.id ?? null,
                            () => {
                              removePillFromVisiblePills(ActionEnum.CATEGORY);
                            }
                          )
                        }
                        onRemove={() =>
                          handlers.assignCategory(request.id, null, request.category?.id ?? null, () => void 0)
                        }
                        labelForRemoveOption="Remove category"
                        onClickAway={e => {
                          e?.stopPropagation();
                          removePillFromVisiblePills(ActionEnum.CATEGORY);
                        }}
                        trigger={
                          <Pill.Category
                            condensed
                            categoryName={request.category?.name}
                            data-testid="request-category"
                            ref={pillRefs[ActionEnum.CATEGORY]}
                          />
                        }
                      />
                    </AbsoluteTooltip>
                  </Hide>
                  <Hide when={hideProjectPill} style={hideProjectPill ? { marginLeft: 0 } : {}}>
                    <AbsoluteTooltip placement="top" content={<span>{`Project: ${request.project?.title}`}</span>}>
                      <ChangeProjectButton
                        onClickAway={e => {
                          e.stopPropagation();
                          removePillFromVisiblePills(ActionEnum.PROJECT);
                        }}
                        onAssign={() => {
                          removePillFromVisiblePills(ActionEnum.PROJECT);
                        }}
                        menuPlacement="bottom-start"
                        requestId={request.id}
                        project={request.project}
                        trigger={
                          <Pill.Project
                            ref={pillRefs[ActionEnum.PROJECT]}
                            condensed
                            projectName={request.project?.title}
                          />
                        }
                      />
                    </AbsoluteTooltip>
                  </Hide>
                  <Hide when={hideDueDatePill} style={hideDueDatePill ? { marginLeft: 0 } : {}}>
                    <AbsoluteTooltip placement="top" content={<span>{`Due date: ${formattedDueDate}`}</span>}>
                      <ChangeDueDatePicker
                        button={
                          <Pill.DueDate
                            ref={pillRefs[ActionEnum.DUEDATE]}
                            condensed
                            dueDate={request.dueAt}
                            isUrgent={isUrgent}
                          />
                        }
                        dueAt={request.dueAt}
                        requestId={request.id}
                        onClickAway={e => {
                          e.stopPropagation();
                          removePillFromVisiblePills(ActionEnum.DUEDATE);
                        }}
                      />
                    </AbsoluteTooltip>
                  </Hide>
                </div>
              </>
            )}
          </div>
        </ConditionalTooltip>
      </Link>
    </RequestRowWrapper>
  );
};
