import { RequestStatus } from "src/globalTypes";

export enum SLAType {
  FirstResponse = "firstResponse",
  RequestResolution = "requestResolution"
}

export const slaIs = {
  breachedAndComplete(timeLeftInMin: number, ticking: boolean) {
    return !ticking && timeLeftInMin < 0;
  },
  successfullyMet(timeLeftInMin: number, ticking: boolean) {
    return !ticking && timeLeftInMin >= 0;
  },
  breachedAndIncomplete(timeLeftInMin: number, ticking: boolean) {
    return ticking && timeLeftInMin <= 0;
  },
  closeToBreach(timeLeftInMin: number, ticking: boolean, goalInMin: number): boolean {
    // this controls at what percent of time left for a goal do we
    // mark the SLA as "close to breaching". Currently defaults to 80%
    const SLA_CLOSE_TO_BREACH_THRESHOLD = 0.2;

    return ticking && timeLeftInMin < Math.round(SLA_CLOSE_TO_BREACH_THRESHOLD * goalInMin);
  },
  paused(requestStatus: RequestStatus, slaType: SLAType): boolean {
    return slaType === SLAType.RequestResolution && requestStatus === RequestStatus.WAITING;
  }
};
