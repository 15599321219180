import { css } from "@emotion/core";
import { csx } from "src/util/csx";

/**
 * LEGACY
 * Use tokens & primitives from design system when possible
 * https://back-storybook.now.sh/
 */

export const SIDEBAR_WIDTH = "20rem";

export const row = css`
  display: flex;
  flex: 1 0 auto;
`;

export const col = css`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;

export const flex = {
  grow0: css`
    flex: 0 0 auto;
  `,
  grow1: css`
    flex: 1 0 auto;
  `,
  grow1Shrink1: css`
    flex: 1 1 auto;
  `
};

export const align = {
  start: css`
    align-items: start;
  `,
  center: css`
    align-items: center;
  `
};

export const justify = {
  start: css`
    justify-content: flex-start;
  `,
  end: css`
    justify-content: flex-end;
  `,
  center: css`
    justify-content: center;
  `,
  spaceBetween: css`
    justify-content: space-between;
  `
};

/**
 * Placeholder spacing children until we have a stronger concept of layouting
 * base unit = 8px / 0.5rem, so e.g. 05 <-> 0.25rem, 1 <-> 0.5rem, 2 <-> 1rem
 */
export const spacing = {
  ml1: css`
    & > * {
      margin-left: 0.5rem;
    }
  `,
  mr1: css`
    & > * {
      margin-right: 0.5rem;
    }
  `,
  mt2: css`
    & > * {
      margin-top: 1rem;
    }
  `
};

export const body = {
  regular: css`
    line-height: 1.5;
    font-family: var(--font-family-body);
    font-weight: 400;
    color: var(--text-6);
  `,
  bold: css`
    line-height: 1.5;
    font-family: var(--font-family-body);
    font-weight: 600;
    color: var(--text-6);
  `,
  italic: css`
    line-height: 1.5;
    font-style: italic;
    font-family: var(--font-family-body);
    font-weight: 400;
    color: var(--text-6);
  `
};

export const fontSize = {
  S: css`
    font-size: 0.875rem;
  `,
  L: css`
    font-size: 1.625rem;
  `,
  XL: css`
    font-size: 2rem;
  `,
  XXL: css`
    font-size: 2.5rem;
  `
};

export const icon = {
  M: css`
    width: 1.125rem;
    height: 1.125rem;
    flex-shrink: 0;

    & path:not([fill]) {
      fill: currentColor;
    }
  `
};

export const focus = {
  button: css`
    &:focus {
      outline: none;
      box-shadow: var(--box-shadow-focus);
    }
  `
};

const textLink = css`
  color: var(--text-6);
  text-decoration: underline;
  cursor: pointer;
  padding: 0 0.125rem;
  border-radius: var(--border-radius-s);

  &:hover {
    background-color: var(--lightGrey-2);
  }
`;

/**
 * DEPRECATED
 * use Typo.TextLink for internal or Typo.ExternalLink for anchor tags instead
 * app/src/styling/primitives/typography.ts
 */
export const TextLink = csx(
  [textLink],
  {
    light: css`
      color: var(--text-4);
    `
  },
  "span"
);

export const layout = {
  page: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    min-width: var(--dimension-general-minWidth);
    max-width: var(--dimension-general-maxWidth);
    padding: 0 6.25rem;
    margin: 0 auto;
    overflow: auto;
  `
};

export const HR = csx(
  [
    css`
      width: 100%;
      height: 1px;
      border-bottom: 1px solid var(--lightGrey-2);
    `
  ],
  {},
  "div"
);
