import styled from "@emotion/styled";
import * as React from "react";
import { Button, Dialog, Row } from "src/components";
import { Note } from "src/components/Notes";
import { Modal } from "src/portals/Modal";
import { navigate } from "src/util/router";

interface Intercom {
  (action: string, message: string): void;
}

declare global {
  interface Window {
    Intercom?: Intercom;
  }
}

const Paragraph = styled(Row)<{ secondary?: boolean }>`
  color: var(--text-6);
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;
`;

export function initiateSlackInstall(organizationId: string, userId: string): void {
  if (!process.env.REACT_APP_SLACK_INSTALL_URL) {
    throw new Error("Missing env var");
  }

  const params = new URLSearchParams();
  params.append("o", organizationId);
  params.append("u", userId);

  window.location.assign(`${process.env.REACT_APP_SLACK_INSTALL_URL}?${params}`);
}

export const SlackInstallModal = (props: {
  onDismiss: () => void;
  organizationId: string;
  userId: string;
  isOpen: boolean;
}) => (
  <Modal isOpen={props.isOpen} onDismiss={props.onDismiss}>
    <Dialog title="Connect Slack" onClose={props.onDismiss} medium>
      <Paragraph margin="0 0 1rem 0">
        Connect your Slack workspace with Back so that employees can submit requests directly from Slack and receive
        updates on the progress of their requests.
      </Paragraph>
      <Row margin="0 0 2rem 0">
        <Note title="💁What's going to happen?">
          We'll install a bot, which serves as the connection between your employees and Back. We'll also enable a set
          of actions to make it easier for you to create a request from any Slack message.
        </Note>
      </Row>
      <Row justify="center">
        <Button
          variant="primary"
          size="large"
          onClick={() => {
            initiateSlackInstall(props.organizationId, props.userId);
          }}
        >
          Connect Slack
        </Button>
      </Row>
    </Dialog>
  </Modal>
);

export const SlackConfirmationModal = (props: { onDismiss(): void; error: boolean; isOpen: boolean }) => (
  <Modal isOpen={props.isOpen} onDismiss={props.onDismiss}>
    <Dialog
      title={props.error ? "Something went wrong" : "Slack connected successfully"}
      onClose={props.onDismiss}
      medium
    >
      {props.error ? (
        <>
          <Paragraph margin="0 0 2rem 0">
            Unfortunately we could not finish connecting Back to your Slack workspace. Please get in touch with us so
            that we can help you sort it out.
          </Paragraph>
          <Row justify="center">
            <Button
              variant="primary"
              size="large"
              onClick={() => {
                // Intercom inserted by segment at runtime
                if (window.Intercom) {
                  window.Intercom("showNewMessage", "I'm having problems with the Slack installation");
                } else {
                  window.open("http://support.back.ee/");
                }
              }}
            >
              Contact us
            </Button>
          </Row>
        </>
      ) : (
        <>
          <Paragraph margin="0 0 1rem 0">
            Anyone can now submit requests by simply messaging <em>@Back</em>.
          </Paragraph>
          <Paragraph>
            You can link your teams with certain Slack channels to automatically suggest the right team to your
            employees. To do so go to <em>Team Management &rarr; Edit details</em> and select one or more channels.
          </Paragraph>
          <Row justify="center" margin="2rem 0 0">
            <Button variant="primary" size="large" margin="0 0.5rem 0 0" onClick={() => navigate("/settings/teams")}>
              Go to team management
            </Button>
            <Button size="large" onClick={props.onDismiss}>
              Close
            </Button>
          </Row>
        </>
      )}
    </Dialog>
  </Modal>
);
