import { css } from "@emotion/core";
import { mdiPlus } from "@mdi/js";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { CreateProjectModal } from "src/App/Projects/CreateModal";
import { ListViewWrapper } from "src/App/Requests/ListView/Component";
import { filterBarWrapperStyles } from "src/App/Requests/ListView/Filters";
import { CleanUnderlineButton, EmptyState, LoadingBar, MaterialIcon, SquareButton, SubmitButton } from "src/components";
import { PopOver } from "src/components/PopOver";
import { dimensions } from "src/styling/layout";
import { flex, fontSize, TextLink } from "src/styling/primitives";
import { csx } from "src/util/csx";
import { ProjectCard } from "./Card";
import { projectStatusMap } from "./Status";
import { ProjectsList, ProjectsListVariables, ProjectsList_projects } from "./typings/ProjectsList";
import { serialize as serializeFilters } from "src/App/Requests/ListView/Provider";
import { RequestStatus } from "src/globalTypes";

/* define options to filter project list by status */
const filterStatusMap = {
  openOrClosed: {
    filterLabel: "open or closed",
    menuLabel: "Show all",
    status: [projectStatusMap.open, projectStatusMap.closed]
  },
  open: { filterLabel: "open", menuLabel: "Open", status: [projectStatusMap.open] },
  closed: { filterLabel: "closed", menuLabel: "Closed", status: [projectStatusMap.closed] }
};

type FilterStatusKey = keyof typeof filterStatusMap;

function ProjectStatusFilter(props: {
  filterStatusKey: FilterStatusKey;
  setFilterStatus: (filterStatus: FilterStatusKey) => void;
}) {
  return (
    <PopOver.Menu
      options={[
        {
          headline: "Filter by status",
          options: Object.entries(filterStatusMap).map(([key, entry]) => ({
            id: key,
            name: entry.menuLabel
          }))
        }
      ]}
      onSelect={props.setFilterStatus}
      selected={props.filterStatusKey}
      trigger={<CleanUnderlineButton>{filterStatusMap[props.filterStatusKey].filterLabel}</CleanUnderlineButton>}
    />
  );
}

export type Project = ProjectsList_projects;

const PROJECT_LIST = gql`
  query ProjectsList($query: RequestListQuery!) {
    projects: requestList(query: $query) {
      id
      title
      status
      requestedAt
      team {
        name
      }
      projectRequestCounts {
        status
        count
      }
    }

    allProjects: requestList(query: { filters: "type=project" }) {
      id
    }
  }
`;

const listWrapper = css`
  width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  padding: 0 var(--space-4-rem);
  overflow: auto;

  & > * + * {
    margin-top: 0;
    margin-bottom: var(--space-4-rem);
  }

  & > *:last-child {
    margin-bottom: 1.5rem;
  }
`;

const Filterbar = csx(
  [
    filterBarWrapperStyles,
    css`
      min-width: ${dimensions.card.minWidth}rem;
      max-width: ${dimensions.card.maxWidth}rem;
      margin: 0 auto;
      padding-right: 0;
      padding-left: 0;
    `
  ],
  {}
);

export function ProjectsListPage() {
  const [createProjectModalIsShown, showCreateProjectModal] = useState(false);
  const [filterStatusKey, setFilterStatus] = useState<FilterStatusKey>("open");

  const projectsData = {
    query: PROJECT_LIST,
    options: {
      fetchPolicy: "network-only" as const,
      variables: {
        query: {
          filters: serializeFilters({
            team: ["-"],
            type: ["project"],
            status: filterStatusMap[filterStatusKey].status.map(s => {
              switch (s) {
                case RequestStatus.NOTSTARTED:
                  return "1";
                case RequestStatus.RESOLVED:
                  return "4";
              }

              return "0"; // just to make the linter happy
            })
          })
        }
      }
    }
  };

  const queriesToRefetch = [{ query: PROJECT_LIST, variables: projectsData.options.variables }];

  const response = useQuery<ProjectsList, ProjectsListVariables>(projectsData.query, projectsData.options);
  const totalProjectCount = response?.data?.allProjects.length;
  const loading = response.loading;

  /* sort projects desc by creation date */
  const sortDir = -1;
  const sortedProjectsList = (response.data?.projects ?? [])
    .slice()
    .sort((a, b) => sortDir * ((Date.parse(a.requestedAt) ?? Infinity) - (Date.parse(b.requestedAt) ?? Infinity)));

  return (
    <ListViewWrapper
      css={css`
        height: 100%;
      `}
    >
      <CreateProjectModal
        isShown={createProjectModalIsShown}
        onClose={() => {
          showCreateProjectModal(false);
        }}
        queriesToRefetch={queriesToRefetch}
      />
      {loading ? (
        <LoadingBar />
      ) : (
        <>
          {!!totalProjectCount && (
            <div css={[listWrapper]}>
              <Filterbar>
                <div css={[flex.grow1, fontSize.S]}>
                  Showing {sortedProjectsList.length} projects that are{" "}
                  <ProjectStatusFilter filterStatusKey={filterStatusKey} setFilterStatus={setFilterStatus} /> sorted by
                  last updated
                </div>
                <SquareButton size="small" variant="secondary" onClick={() => showCreateProjectModal(true)}>
                  <MaterialIcon path={mdiPlus} size={1.125} />
                </SquareButton>
              </Filterbar>
              <>
                {sortedProjectsList.map(project => (
                  <ProjectCard key={project.id} project={project} queriesToRefetch={queriesToRefetch} />
                ))}
              </>
            </div>
          )}
          {!sortedProjectsList.length && !!totalProjectCount && (
            <EmptyState
              variant="error"
              title="Oh no"
              subtitle={
                <div>
                  There are no projects that match your selection.
                  <br />
                  Try{" "}
                  <TextLink
                    onClick={() => {
                      setFilterStatus("openOrClosed");
                    }}
                  >
                    show all
                  </TextLink>
                </div>
              }
            />
          )}
          {!totalProjectCount && (
            <EmptyState
              title="No projects, yet"
              subtitle="Use projects to group requests, track their progress, and share them across different teams."
              variant="info"
            >
              <SubmitButton
                onClick={() => {
                  showCreateProjectModal(true);
                }}
              >
                <MaterialIcon path={mdiPlus} size={1.125} margin="0 0.25rem 0 0" />
                Create project
              </SubmitButton>
            </EmptyState>
          )}
        </>
      )}
    </ListViewWrapper>
  );
}
