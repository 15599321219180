import { css } from "@emotion/core";
import * as React from "react";
import { FC, useState } from "react";
import { MessageBox } from "src/App/Requests/components";
import { RequestGet_request_events_commentAddedEvent_kbDocumentsList } from "src/App/Requests/DetailView/typings/RequestGet";
import { Col, Dialog } from "src/components";
import { Modal } from "src/portals/Modal";
import { Typo } from "src/styling/primitives/typography";
import { openNewTab } from "src/util";
import { backMarkdownToText } from "src/util/formatters";
import { KBMarkdownView } from "./MarkdownView";
import { DocumentLogo } from "src/App/KB/DocumentLogos";

export interface IDocumentsList {
  documentsList: RequestGet_request_events_commentAddedEvent_kbDocumentsList[];
}

/**
 * Component to show a list of attachments for a comment / note
 * Only renders with non-empty array
 */
export const DocumentsList: FC<IDocumentsList> = props => {
  const [preview, setPreview] = useState<RequestGet_request_events_commentAddedEvent_kbDocumentsList | null>(null);
  return (
    <>
      <MessageBox
        darkGrey
        css={css`
          min-width: 10rem;
        `}
      >
        <Col
          data-testid="documents-list"
          css={css`
            align-items: flex-start;
          `}
        >
          {props.documentsList.map(doc => (
            <div
              key={doc.id}
              css={css`
                max-width: 100%;
                & > * + * {
                  margin-top: var(--space-2-rem);
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  max-width: 100%;
                  & * + * {
                    margin-left: var(--space-2-px);
                  }
                `}
              >
                <DocumentLogo doc={doc} />
                <Typo.Body
                  ellipsis
                  css={css`
                    max-width: 100%;
                    cursor: pointer;
                    text-decoration: underline;
                    font-weight: var(--font-weight-body-regular);
                    /* Center horizontally but still show short length charachters */
                    line-height: 1;
                    padding: var(--space-1-rem) 0;
                  `}
                  onClick={() => {
                    if (doc.url) {
                      openNewTab(doc.url);
                    } else {
                      setPreview(doc);
                    }
                  }}
                >
                  {doc.title}
                </Typo.Body>
              </div>
              <Typo.Body
                ellipsis
                css={css`
                  max-width: 100%;
                `}
              >
                {backMarkdownToText(doc.content)}
              </Typo.Body>
            </div>
          ))}
        </Col>
      </MessageBox>
      <Modal isOpen={!!preview} onDismiss={() => setPreview(null)}>
        <Dialog medium title={preview?.title} onClose={() => setPreview(null)}>
          <Typo.Body>
            <KBMarkdownView md={preview?.content ?? ""} />
          </Typo.Body>
        </Dialog>
      </Modal>
    </>
  );
};
