import { css } from "@emotion/core";
import { KeyBindingUtil } from "draft-js";
import { Form, Formik } from "formik";
import * as React from "react";
import { FC } from "react";
import { FeatureFlags, useFeatureFlags } from "src/App/Root/providers/FeatureFlagProvider";
import {
  ApprovalRequestStepCard,
  CreateConversationRequestStepCard,
  CreateConversationStepCard,
  CreateNoteStepCard,
  CreateProjectStepCard,
  CreateReplyStepCard,
  CreateRequestStepCard,
  CreateTaskStepCard,
  FetchUserProfileStepCard,
  JumpIfStepCard,
  LaunchActionStepCard,
  LinkJiraRequestStepCard,
  MapValueStepCard,
  SendFormStepCard,
  SetRequestAssigneeStepCard,
  SetRequestRequesterStepCard,
  SetRequestCategoryStepCard,
  SetRequestDueDateStepCard,
  SetRequestPriorityStepCard,
  SetRequestProjectStepCard,
  SetRequestStatusStepCard,
  SetRequestTeamStepCard,
  SetRequestTitleStepCard
} from "src/App/Settings/Workflows/DetailView/Steps";
import { TeamWorkflowGet_teamWorkflow_steps_stepMaps_publishedVariables } from "src/App/Settings/Workflows/typings/TeamWorkflowGet";
import { Button, ButtonBar } from "src/components";
import { ActionInput, ActionInputMap, ActionKey } from "src/globalTypes";
import { Card } from "src/styling/primitives/Card";
import { Typo } from "src/styling/primitives/typography";
import { CardState, JumpLink, JumpLinkContainer, PublishedVariables } from ".";
import { StepActionIndex, useWorkflow } from "./WorkflowProvider";

export const StepArrow: React.FC<{ last: boolean }> = ({ last }) => {
  return (
    <div
      css={css`
        position: relative;
        width: var(--space-4-rem);
        flex-shrink: 0;
      `}
    >
      {!last && (
        <div
          css={css`
            position: absolute;
            box-sizing: border-box;

            bottom: calc(0 - var(--space-6-rem));
            height: calc(100% + var(--space-6-rem));

            left: 0;
            right: 0;

            border-left: 1px solid var(--lightGrey-3);
            border-bottom: 1px solid var(--lightGrey-3);
            border-bottom-left-radius: var(--space-4-rem);
          `}
        />
      )}
    </div>
  );
};

/**
 * Style used for TriggerCard & StepCard
 */
export const wfCardStyle = css`
  /* Arbitrary value still tbd */
  min-width: 20rem;
  width: auto;
  transition: all 0.3s ease;
  & > * + * {
    margin-top: var(--space-2-rem);
  }
  /* extra space for helper text */
  & > table:not(:last-child) {
    margin-bottom: var(--space-6-rem);
  }
`;

export const ActionCard: FC<{
  index: StepActionIndex;
  action: ActionInput;
  toggleExpanded(): void;
  cardState: CardState;
  submitParent(payload: ActionInput): void;
  publishedVariables: TeamWorkflowGet_teamWorkflow_steps_stepMaps_publishedVariables[];
  last: boolean; // Used to control css of arrow in the last element, because css selector was not possible
}> = ({ index, action, cardState, toggleExpanded, submitParent, publishedVariables, last }) => {
  const { hasFeatureFlags } = useFeatureFlags();
  const { requiresCustomization } = useWorkflow();

  return (
    <Formik
      enableReinitialize
      initialValues={action}
      onSubmit={payload => submitParent(payload)}
      render={actionForm => (
        <Form>
          <div
            // Need taIndex for onKeyDown to fir on div
            tabIndex={0}
            onKeyDown={e => {
              if (e.key === "Enter" && KeyBindingUtil.hasCommandModifier(e)) {
                actionForm.dirty && actionForm.submitForm();
              } else if (e.key === "Escape") {
                toggleExpanded();
                actionForm.resetForm();
              }
            }}
            css={[
              css`
                outline: none;
                & > * + * {
                  /* space the jumplinks to align them vertically with the left edge of the card */
                  margin-left: 15px;
                }
              `
            ]}
          >
            <div
              css={[
                css`
                  display: flex;
                `
              ]}
            >
              <StepArrow last={last} />
              <Card
                sizeM
                hasInteractions={
                  cardState === "collapsed" &&
                  hasFeatureFlags(FeatureFlags.WORKFLOWBUILDEREDITSTEPS) &&
                  !requiresCustomization
                }
                hasExtraPadding={cardState === "expanded"}
                onClick={() => {
                  cardState === "collapsed" && toggleExpanded();
                }}
                css={[
                  wfCardStyle,
                  cardState === "disabled" &&
                    css`
                      opacity: 0.4;
                    `
                ]}
              >
                <ActionFieldComponents
                  index={index}
                  action={actionForm.values}
                  onChange={({ map }) => {
                    actionForm.setFieldValue("map", map);
                  }}
                  expanded={cardState === "expanded"}
                />
                <PublishedVariables variables={publishedVariables} expanded={cardState === "expanded"} />
                {cardState === "expanded" && (
                  <ButtonBar>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => {
                        toggleExpanded();
                        actionForm.resetForm();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      variant="primary"
                      disabled={!actionForm.dirty}
                      onClick={actionForm.submitForm}
                    >
                      Apply changes
                    </Button>
                  </ButtonBar>
                )}
              </Card>
            </div>

            <ActionFieldJumpLinks action={action} disabled={cardState === "disabled"} />
          </div>
        </Form>
      )}
    />
  );
};

const ActionFieldComponents: React.FC<{
  index: StepActionIndex;
  action: ActionInput;
  onChange(action: ActionInput): void;
  expanded: boolean;
}> = ({ index, action: { key, map }, onChange, expanded }) => {
  const handleChange = (actionObj: Partial<NonNullable<ActionInputMap[ActionKey]>>) => {
    onChange({
      key,
      map: {
        [key]: {
          ...map[key],
          ...actionObj
        }
      }
    });
  };
  switch (key) {
    case ActionKey.ApprovalRequest: {
      const action = map[key];
      if (!action) return null;
      return <ApprovalRequestStepCard index={index} action={action} onChange={handleChange} expanded={expanded} />;
    }
    case ActionKey.CreateConversation: {
      const action = map[key];
      if (!action) return null;
      return <CreateConversationStepCard index={index} action={action} onChange={handleChange} expanded={expanded} />;
    }
    case ActionKey.CreateConversationRequest: {
      const action = map[key];
      if (!action) return null;
      return (
        <CreateConversationRequestStepCard index={index} action={action} onChange={handleChange} expanded={expanded} />
      );
    }
    case ActionKey.CreateRequest: {
      const action = map[key];
      if (!action) return null;
      return <CreateRequestStepCard index={index} action={action} onChange={handleChange} expanded={expanded} />;
    }
    case ActionKey.CreateNote: {
      const action = map[key];
      if (!action) return null;
      return <CreateNoteStepCard index={index} action={action} onChange={handleChange} expanded={expanded} />;
    }
    case ActionKey.CreateProject: {
      const action = map[key];
      if (!action) return null;
      return <CreateProjectStepCard index={index} action={action} onChange={handleChange} expanded={expanded} />;
    }
    case ActionKey.CreateReply: {
      const action = map[key];
      if (!action) return null;
      return <CreateReplyStepCard index={index} action={action} onChange={handleChange} expanded={expanded} />;
    }
    case ActionKey.CreateTask: {
      const action = map[key];
      if (!action) return null;
      return <CreateTaskStepCard index={index} action={action} onChange={handleChange} expanded={expanded} />;
    }
    case ActionKey.FetchUserProfile: {
      const action = map[key];
      if (!action) return null;
      return <FetchUserProfileStepCard index={index} action={action} onChange={handleChange} expanded={expanded} />;
    }
    case ActionKey.JumpIf: {
      const action = map[key];
      if (!action) return null;
      return (
        <JumpIfStepCard
          index={index}
          action={action}
          onChange={actionObj =>
            onChange({
              key,
              map: {
                [key]: {
                  onFalse: action.onFalse,
                  onTrue: action.onTrue,
                  ...actionObj
                }
              }
            })
          }
          expanded={expanded}
        />
      );
    }
    case ActionKey.Launch: {
      const action = map[key];
      if (!action) return null;
      return <LaunchActionStepCard index={index} action={action} onChange={handleChange} expanded={expanded} />;
    }
    case ActionKey.LinkJiraRequest: {
      const action = map[key];
      if (!action) return null;
      return <LinkJiraRequestStepCard index={index} action={action} onChange={handleChange} expanded={expanded} />;
    }
    case ActionKey.MapValue: {
      const action = map[key];
      if (!action) return null;
      return <MapValueStepCard index={index} action={action} onChange={handleChange} expanded={expanded} />;
    }
    case ActionKey.SendForm: {
      const action = map[key];
      if (!action) return null;
      return <SendFormStepCard index={index} action={action} onChange={handleChange} expanded={expanded} />;
    }
    case ActionKey.SetRequestAssignee: {
      const action = map[key];
      if (!action) return null;
      return <SetRequestAssigneeStepCard index={index} action={action} onChange={handleChange} expanded={expanded} />;
    }
    case ActionKey.SetRequestCategory: {
      const action = map[key];
      if (!action) return null;
      return <SetRequestCategoryStepCard index={index} action={action} onChange={handleChange} expanded={expanded} />;
    }
    case ActionKey.SetRequestDueDate: {
      const action = map[key];
      if (!action) return null;
      return <SetRequestDueDateStepCard index={index} action={action} onChange={handleChange} expanded={expanded} />;
    }
    case ActionKey.SetRequestPriority: {
      const action = map[key];
      if (!action) return null;
      return <SetRequestPriorityStepCard index={index} action={action} onChange={handleChange} expanded={expanded} />;
    }
    case ActionKey.SetRequestProject: {
      const action = map[key];
      if (!action) return null;
      return <SetRequestProjectStepCard index={index} action={action} onChange={handleChange} expanded={expanded} />;
    }
    case ActionKey.SetRequestStatus: {
      const action = map[key];
      if (!action) return null;
      return <SetRequestStatusStepCard index={index} action={action} onChange={handleChange} expanded={expanded} />;
    }
    case ActionKey.SetRequestTeam: {
      const action = map[key];
      if (!action) return null;
      return <SetRequestTeamStepCard index={index} action={action} onChange={handleChange} expanded={expanded} />;
    }
    case ActionKey.SetRequestTitle: {
      const action = map[key];
      if (!action) return null;
      return <SetRequestTitleStepCard index={index} action={action} onChange={handleChange} expanded={expanded} />;
    }
    case ActionKey.SetRequestRequester: {
      const action = map[key];
      if (!action) return null;
      return <SetRequestRequesterStepCard index={index} action={action} onChange={handleChange} expanded={expanded} />;
    }
  }
};

const ActionFieldJumpLinks: React.FC<{
  action: ActionInput;
  disabled: boolean;
}> = ({ action: { key, map }, disabled }) => {
  switch (key) {
    case ActionKey.ApprovalRequest: {
      const action = map[key];
      if (!action) return null;
      return (
        <JumpLinkContainer disabled={disabled}>
          {action.onApproved && (
            <Typo.Body light sizeS>
              Once approved → <JumpLink subWorkflowId={action.onApproved} disabled={disabled} />
            </Typo.Body>
          )}
          {action.onRejected && (
            <Typo.Body light sizeS>
              If rejected → <JumpLink subWorkflowId={action.onRejected} disabled={disabled} />
            </Typo.Body>
          )}
        </JumpLinkContainer>
      );
    }
    case ActionKey.CreateConversation:
    case ActionKey.CreateConversationRequest:
    case ActionKey.CreateRequest:
    case ActionKey.CreateNote:
    case ActionKey.CreateProject:
    case ActionKey.CreateReply:
      return null;
    case ActionKey.CreateTask: {
      const action = map[key];
      if (!action) return null;
      return (
        <JumpLinkContainer disabled={disabled}>
          {action.onCompleted && (
            <Typo.Body light sizeS>
              If completed → <JumpLink subWorkflowId={action.onCompleted} disabled={disabled} />
            </Typo.Body>
          )}
        </JumpLinkContainer>
      );
    }
    case ActionKey.FetchUserProfile:
      return null;
    case ActionKey.JumpIf: {
      const action = map[key];
      if (!action) return null;
      return (
        <JumpLinkContainer disabled={disabled}>
          <Typo.Body light sizeS>
            If it applies → <JumpLink subWorkflowId={action.onTrue} disabled={disabled} />
          </Typo.Body>
          <Typo.Body light sizeS>
            If it does not apply → <JumpLink subWorkflowId={action.onFalse} disabled={disabled} />
          </Typo.Body>
        </JumpLinkContainer>
      );
    }
    case ActionKey.Launch:
    case ActionKey.LinkJiraRequest:
    case ActionKey.MapValue:
      return null;
    case ActionKey.SendForm: {
      const action = map[key];
      if (!action) return null;
      return (
        <JumpLinkContainer disabled={disabled}>
          <Typo.Body light sizeS>
            Once replied → <JumpLink subWorkflowId={action.onReplied} disabled={disabled} />
          </Typo.Body>
        </JumpLinkContainer>
      );
    }
    case ActionKey.SetRequestAssignee:
    case ActionKey.SetRequestRequester:
    case ActionKey.SetRequestCategory:
    case ActionKey.SetRequestDueDate:
    case ActionKey.SetRequestPriority:
    case ActionKey.SetRequestProject:
    case ActionKey.SetRequestStatus:
    case ActionKey.SetRequestTeam:
    case ActionKey.SetRequestTitle:
      return null;
  }
};
