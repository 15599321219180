import { useQuery } from "@apollo/react-hooks";
import { css, SerializedStyles } from "@emotion/core";
import { mdiCheck, mdiChevronDown, mdiClose } from "@mdi/js";
import gql from "graphql-tag";
import * as React from "react";
import { FC, useState } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import { ValueType } from "react-select/src/types";
import { DOCUMENT_FRAGMENT } from "src/App/KB";
import { priorityLabels } from "src/App/Requests/Actions/RequestPriorityButton";
import { UserAvatar, UserComponentFields } from "src/App/User";
import { getUserList } from "src/App/User/getUserList";
import { LoadingBar, MaterialIcon, SquareButton } from "src/components";
import { PopOver } from "src/components/PopOver";
import { PopOverMenuItemInner } from "src/components/PopOver/PopOverMenu";
import { colorVariantMappings, ColorVariantsUnion } from "src/components/StatusIndicator";
import {
  CategoryMatcherInput,
  DateMatcherInput,
  DocumentMatcherInput,
  FormMatcherInput,
  PriorityMatcherInput,
  RequestPriority,
  StatusMatcherInput,
  TeamMatcherInput,
  UserMatcherInput,
  UserMatcherUserRole,
  UserTypeSelection,
  WorkflowPublishedVariableType
} from "src/globalTypes";
import { Typo } from "src/styling/primitives/typography";
import zIndices from "src/styling/tokens/z-indices.json";
import { reportDevError } from "src/util";
import { csx } from "src/util/csx";
import { isPlural } from "src/util/formatters";
import * as yup from "yup";
import { StepActionIndex, useWorkflow, variableFillColors } from "./index";
import { DocumentList } from "./typings/DocumentList";
import { SchemaListResponse, SchemaListResponseVariables } from "./typings/SchemaListResponse";
import { TeamCategories, TeamCategoriesVariables } from "./typings/TeamCategories";
import { TeamCustomStatuses } from "./typings/TeamCustomStatuses";
import { TeamList } from "./typings/TeamList";
import { InlineVariable } from "./Variables";
import { PublishedVariable } from "./WorkflowProvider";

const sharedMatcherValueContainerStyles = css`
  box-sizing: border-box;
  position: relative;
  display: inline-block;
`;
export const MatcherValueContainer = csx([
  sharedMatcherValueContainerStyles,
  css`
    border-bottom: 1px solid var(--text-6);
  `
]);

export const editableMatcherValueContainerStyles = [
  sharedMatcherValueContainerStyles,
  css`
    border-bottom: 1px solid var(--lightGrey-4);
    &:hover,
    &:focus,
    &:focus-within,
    &:focus-visible {
      outline: none;
      border-bottom: 1px solid var(--text-6);
    }
  `
];

export const EditableMatcherValueContainer = csx(editableMatcherValueContainerStyles);

export const SelectTrigger: FC = ({ children }) => (
  <button
    css={[
      css`
        /* reset button element user agent */
        appearance: none;
        background-color: transparent;
        border: none;
        /* reset button element user agent */
        box-sizing: border-box;
        position: relative;
        width: 100%;
        border-bottom: 1px solid var(--lightGrey-4);
        padding: 0 var(--space-5-rem) 2px var(--space-1-rem);
      `,
      editableMatcherValueContainerStyles
    ]}
  >
    {children}
    <MaterialIcon
      path={mdiChevronDown}
      size={1}
      css={[
        css`
          position: absolute;
          right: var(--space-1-rem);
          top: 50%;
          transform: translateY(-50%);
        `
      ]}
    />
  </button>
);

const Icon: FC<{ fill: SerializedStyles }> = ({ fill }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    css={[
      css`
        vertical-align: text-bottom;
      `
    ]}
  >
    <circle css={fill} cx="9" cy="9" r="6" />
  </svg>
);

interface SharedMatcherProps {
  emptyLabel?: string;
}

export const CategoryMatcherComponent: FC<
  SharedMatcherProps & {
    matcher: CategoryMatcherInput | null;
    teamId: string;
    onChange?(value: CategoryMatcherInput): void;
  }
> = ({ matcher, onChange, teamId, emptyLabel }) => {
  const editable = !!onChange;
  const { data } = useQuery<TeamCategories, TeamCategoriesVariables>(
    gql`
      query TeamCategories($teamId: ID!) {
        teamCategories(teamId: $teamId) {
          id
          name
        }
      }
    `,
    {
      variables: {
        teamId: teamId
      }
    }
  );

  if (!data) return <LoadingBar />;

  const currentlySelectedCategory = {
    id: matcher?.categoryId
      ? matcher.categoryId
      : data.teamCategories.find(category => category.name === matcher?.name)?.id ?? "",
    name: matcher?.categoryId
      ? data.teamCategories.find(category => category.id === matcher.categoryId)?.name
      : matcher?.name
  };

  return (
    <>
      {editable ? (
        <PopOver.Menu
          trigger={<SelectTrigger>{currentlySelectedCategory.name ?? emptyLabel ?? "Select category"}</SelectTrigger>}
          onSelect={(val: string) => {
            const category = data.teamCategories.find(category => category?.id === val);
            category && onChange && onChange({ categoryId: category.id });
          }}
          selected={currentlySelectedCategory.id}
          options={[
            {
              headline: "Select category",
              options: data.teamCategories
            }
          ]}
        />
      ) : (
        <MatcherValueContainer>{currentlySelectedCategory.name ?? emptyLabel ?? "undefined"}</MatcherValueContainer>
      )}
    </>
  );
};

export const StatusMatcherComponent: FC<
  SharedMatcherProps & {
    matcher: StatusMatcherInput | null;
    onChange?(value: StatusMatcherInput): void;
    teamId: string;
  }
> = ({ matcher, onChange, teamId, emptyLabel }) => {
  const editable = !!onChange;
  const { data } = useQuery<TeamCustomStatuses>(
    gql`
      query TeamCustomStatuses($teamId: ID!) {
        teamCustomStatuses(teamId: $teamId) {
          id
          name
          color
        }
      }
    `,
    {
      variables: {
        teamId: teamId
      }
    }
  );

  if (!data) return <LoadingBar />;

  const currentlySelectedStatus = {
    id: matcher?.statusId
      ? matcher.statusId
      : data.teamCustomStatuses.find(status => status.name === matcher?.name)?.id,
    name: matcher?.statusId
      ? data.teamCustomStatuses.find(status => status.id === matcher.statusId)?.name
      : matcher?.name
  };

  const options = data.teamCustomStatuses.map(status => ({
    id: status.id,
    name: status.name,
    icon: (
      <Icon
        fill={css`
          fill: ${colorVariantMappings[status.color as ColorVariantsUnion].icon};
        `}
      />
    )
  }));

  return (
    <>
      {editable ? (
        <PopOver.Menu
          trigger={<SelectTrigger>{currentlySelectedStatus.name ?? emptyLabel ?? "Select status"}</SelectTrigger>}
          onSelect={(statusId: string) => {
            const selectedStatus = data.teamCustomStatuses.find(status => status.id === statusId);
            selectedStatus && onChange && onChange({ statusId: selectedStatus.id });
          }}
          selected={currentlySelectedStatus.id}
          options={[
            {
              headline: "Select status",
              options: options
            }
          ]}
        />
      ) : (
        <MatcherValueContainer>{currentlySelectedStatus?.name ?? emptyLabel ?? "undefined"}</MatcherValueContainer>
      )}
    </>
  );
};

export const TeamMatcherComponent: FC<
  SharedMatcherProps & {
    matcher: TeamMatcherInput | null;
    onChange?(value: TeamMatcherInput): void;
    actionId?: StepActionIndex;
  }
> = ({ matcher, onChange, emptyLabel, actionId }) => {
  const editable = !!onChange;
  const { getWorkflowVariables } = useWorkflow();
  const teamVars = (getWorkflowVariables(actionId) ?? []).filter(
    variable => variable.type === WorkflowPublishedVariableType.TYPE_TEAM_ID
  );
  const variables =
    teamVars.length &&
    teamVars.map(variable => ({
      id: variable.templateString,
      name: variable.templateString,
      icon: <Icon fill={variableFillColors[variable.colorCode]} />
    }));

  const { data } = useQuery<TeamList>(
    gql`
      query TeamList {
        teamList {
          id
          name
          slug
        }
      }
    `
  );

  if (!data) return <LoadingBar />;

  const currentlySelectedTeam = {
    id: matcher?.teamId ? matcher.teamId : data.teamList.find(team => team.slug === matcher?.slug)?.id,
    name: matcher?.teamId
      ? data.teamList.find(team => team.id === matcher.teamId)?.name
      : data.teamList.find(team => team.slug === matcher?.slug)?.name
  };
  const options = data.teamList.map(team => ({ id: team.id, name: team.name }));

  return (
    <>
      {editable ? (
        <PopOver.Menu
          trigger={<SelectTrigger>{currentlySelectedTeam.name ?? emptyLabel ?? "Select team"}</SelectTrigger>}
          onSelect={(teamId: string) => {
            const selectedTeam = data.teamList.find(t => t.id === teamId);
            selectedTeam && onChange && onChange({ teamId: selectedTeam.id });
          }}
          selected={currentlySelectedTeam.id}
          options={
            !!variables
              ? [
                  {
                    headline: "Team variables",
                    options: variables
                  },
                  {
                    headline: "Select team",
                    options: options
                  }
                ]
              : [
                  {
                    headline: "Select team",
                    options: options
                  }
                ]
          }
        />
      ) : (
        <MatcherValueContainer>{currentlySelectedTeam.name ?? emptyLabel ?? "undefined"}</MatcherValueContainer>
      )}
    </>
  );
};

const timeUnits = ["hour", "day", "week"];
const dateMatcherPattern = /^(now|[\dTZ+:-]+)( ([+-]) (\d+) (hour|day|week)s?)?$/;

interface DateMatcherObj {
  base: string;
  beforeAfter: "before" | "after" | "on";
  number: number;
  unit: string;
}

const deserialize = (dateStr: string): DateMatcherObj | null => {
  // e.g. "now + 1 day" -> ["now + 1 day", "now", " + 1 day", "+", "1", "day"]
  const match = dateStr.match(dateMatcherPattern);
  if (!match) return null;
  const number = Number(match[4] ?? "0");
  if (number === 0) {
    return {
      base: match[1],
      beforeAfter: "on",
      number: 0,
      unit: "days"
    };
  }
  return {
    base: match[1],
    beforeAfter: match[3] === "-" ? "before" : "after",
    number: Number(match[4] ?? "0"),
    unit: match[5]?.replace("s", "") ?? "day"
  };
};

const serialize = (d: DateMatcherObj): string => {
  if (d.beforeAfter === "on") return d.base;
  return `${d.base} ${d.beforeAfter === "before" ? "-" : "+"} ${d.number} ${d.unit}`;
};

export const DateMatcherComponent: FC<
  SharedMatcherProps & {
    index: StepActionIndex;
    matcher: DateMatcherInput;
    onChange?(value: DateMatcherInput): void;
  }
> = ({ matcher, onChange, emptyLabel, index }) => {
  const { getWorkflowVariables } = useWorkflow();
  const variableOptions =
    getWorkflowVariables(index)
      ?.filter(v => v.type === WorkflowPublishedVariableType.TYPE_DATE)
      .map(v => ({ id: v.templateString, name: v.name })) ?? [];

  const dateObj = deserialize(matcher.date);
  const isPluralUnit = isPlural(dateObj?.number ?? 0);

  return (
    <>
      {!!onChange && !!dateObj ? (
        <>
          <div
            css={css`
              display: flex;
              align-items: flex-end;
              & > * + * {
                margin-left: var(--space-1-rem);
              }
            `}
          >
            {dateObj.beforeAfter !== "on" && (
              <input
                type="number"
                min={0}
                css={css`
                  line-height: var(--line-height-normal);
                  outline: none;
                  border: none;
                  border-bottom: 1px solid var(--lightGrey-4);
                  width: var(--space-7-rem);
                `}
                value={dateObj.number}
                onChange={e => {
                  onChange &&
                    onChange({
                      date: serialize({
                        ...dateObj,
                        number: Number(e.target.value)
                      })
                    });
                }}
              />
            )}
            {dateObj.beforeAfter !== "on" && (
              <PopOver.Menu
                trigger={<SelectTrigger>{isPluralUnit ? dateObj.unit + "s" : dateObj.unit}</SelectTrigger>}
                selected={dateObj.unit}
                options={[
                  ...timeUnits.map(unit => ({
                    id: unit,
                    name: isPluralUnit ? unit + "s" : unit
                  }))
                ]}
                onSelect={(unit: string) =>
                  onChange({
                    date: serialize({
                      ...dateObj,
                      unit
                    })
                  })
                }
              />
            )}
            <PopOver.Menu
              trigger={<SelectTrigger>{dateObj.beforeAfter}</SelectTrigger>}
              selected={dateObj.beforeAfter}
              options={[
                {
                  id: "on",
                  name: "on"
                },
                {
                  id: "before",
                  name: "before"
                },
                {
                  id: "after",
                  name: "after"
                }
              ]}
              onSelect={(beforeAfter: DateMatcherObj["beforeAfter"]) => {
                if (dateObj.beforeAfter === "on" && beforeAfter !== "on") {
                  // initialize when selecting before/after from on
                  return onChange({
                    date: serialize({
                      ...dateObj,
                      number: 1,
                      unit: "day",
                      beforeAfter
                    })
                  });
                }
                onChange({
                  date: serialize({
                    ...dateObj,
                    beforeAfter
                  })
                });
              }}
            />
            <PopOver.Menu
              trigger={<SelectTrigger>{dateObj.base === "now" ? "workflow runtime" : dateObj.base}</SelectTrigger>}
              selected={dateObj.base}
              options={[
                {
                  id: "now",
                  name: "workflow runtime"
                },
                ...variableOptions
              ]}
              onSelect={(base: string) =>
                onChange({
                  date: `${base}`
                })
              }
            />
          </div>
        </>
      ) : (
        <MatcherValueContainer>{matcher.date ?? emptyLabel}</MatcherValueContainer>
      )}
    </>
  );
};

export const DocumentMatcherComponent: FC<
  SharedMatcherProps & { matcher: DocumentMatcherInput[]; onChange?(value: DocumentMatcherInput[]): void }
> = ({ matcher, onChange, emptyLabel }) => {
  const editable = !!onChange;
  const { data } = useQuery<DocumentList>(
    gql`
      query DocumentList {
        documentList {
          ...DocumentFragment
        }
      }
      ${DOCUMENT_FRAGMENT}
    `
  );

  if (!data) return <LoadingBar />;

  const options = data.documentList.map(doc => ({ id: doc.pk, name: doc.title }));

  return (
    <>
      {editable ? (
        <PopOver.Menu
          isMulti
          trigger={
            <SelectTrigger>
              {matcher
                .map(docMatcher =>
                  !!docMatcher.title
                    ? docMatcher.title
                    : data.documentList.find(d => d.id === docMatcher.docPk?.split(":")[0])?.title
                )
                .join(", ") ??
                emptyLabel ??
                "Select documents"}
            </SelectTrigger>
          }
          onSelect={(documentPks: string[]) => {
            onChange && onChange(documentPks.map(pk => ({ docPk: pk })));
          }}
          selected={matcher.map(docMatcher =>
            !!docMatcher.docPk ? docMatcher.docPk : data.documentList.find(d => d.title === docMatcher.title)?.pk ?? ""
          )}
          options={[
            {
              headline: "Select documents",
              options: options
            }
          ]}
        />
      ) : (
        <MatcherValueContainer>
          {matcher
            .map(docMatcher =>
              !!docMatcher.title
                ? docMatcher.title
                : data.documentList.find(d => d.id === docMatcher.docPk?.split(":")[0])?.title
            )
            .join(", ") ??
            emptyLabel ??
            "undefined"}
        </MatcherValueContainer>
      )}
    </>
  );
};

export const FormMatcherComponent: FC<
  SharedMatcherProps & { matcher: FormMatcherInput; teamId: string; onChange?(value: FormMatcherInput): void }
> = ({ matcher, teamId, onChange }) => {
  const editable = !!onChange;

  const formListResponse = useQuery<SchemaListResponse, SchemaListResponseVariables>(
    gql`
      query SchemaListResponse($teamId: ID!) {
        schemaList(teamId: $teamId) {
          id
          displayName
        }
      }
    `,
    {
      variables: {
        teamId: teamId ?? ""
      },
      skip: !teamId
    }
  );

  const selectedForm = formListResponse?.data?.schemaList.find(schema => schema.id === matcher.formId);
  if (formListResponse.loading) return <LoadingBar />;
  return (
    <>
      {editable ? (
        <PopOver.Menu
          trigger={
            <SelectTrigger>{!!matcher.name ? matcher.name : selectedForm?.displayName ?? "Select form"}</SelectTrigger>
          }
          onSelect={(formId: string) => {
            onChange && onChange({ formId: formId });
          }}
          selected={
            !!matcher.formId
              ? matcher.formId
              : formListResponse?.data?.schemaList.find(schema => schema.displayName === matcher.name)?.id
          }
          options={[
            {
              headline: "Select form",
              options: formListResponse?.data?.schemaList.map(form => ({
                id: form.id,
                name: form.displayName
              })) ?? [""]
            }
          ]}
        />
      ) : (
        <MatcherValueContainer>{!!matcher.name ? matcher.name : selectedForm?.displayName}</MatcherValueContainer>
      )}
    </>
  );
};

export const PriorityMatcherComponent: FC<
  SharedMatcherProps & { matcher: PriorityMatcherInput; onChange?(value: PriorityMatcherInput): void }
> = ({ matcher, onChange }) => {
  const editable = !!onChange;
  const options = Object.entries(priorityLabels)
    .filter(([id]) => id !== RequestPriority.PRIORITY_UNSPECIFIED)
    .map(([id, label]) => {
      return {
        id: id,
        name: label
      };
    });

  return (
    <>
      {editable ? (
        <PopOver.Menu
          trigger={
            <SelectTrigger>
              {priorityLabels[matcher.priority] ?? priorityLabels[RequestPriority.PRIORITY_UNSPECIFIED]}
            </SelectTrigger>
          }
          onSelect={(priority: RequestPriority) => {
            onChange && onChange({ priority });
          }}
          selected={matcher.priority}
          options={[
            {
              headline: "Select priority",
              options: options
            }
          ]}
          onRemove={() => {
            onChange && onChange({ priority: RequestPriority.PRIORITY_UNSPECIFIED });
          }}
          labelForRemoveOption="Remove priority"
        />
      ) : (
        <MatcherValueContainer>
          {priorityLabels[matcher.priority] ?? priorityLabels[RequestPriority.PRIORITY_UNSPECIFIED]}
        </MatcherValueContainer>
      )}
    </>
  );
};

const getPlaceholderByUserType = (userTypeSelection: UserTypeSelection) => {
  switch (userTypeSelection) {
    case UserTypeSelection.USER_TYPE_SELECTION_INTERNAL_USERS:
      return "internal users";
    case UserTypeSelection.USER_TYPE_SELECTION_EXPERTS:
      return "experts";
    case UserTypeSelection.USER_TYPE_SELECTION_UNSPECIFIED:
    case UserTypeSelection.USER_TYPE_SELECTION_INTERNAL_AND_EXTERNAL_USERS:
    default:
      return "internal and external users";
  }
};

const isValidNewOption = (value: string) => yup.string().email().isValidSync(value);

interface UserSelectOption {
  label?: string;
  value?: {
    key: string;
    id: string;
    name: string;
    email: string;
    role: UserMatcherUserRole;
    variable?: PublishedVariable;
  };
}

export const userMatcherLabel = (matcher: UserMatcherInput) => {
  if (matcher.email) return matcher.email;
  if (matcher.userId) return matcher.userId;
  if (matcher.role === UserMatcherUserRole.ROLE_CURRENT_USER) return "Current workflow user";
  if (matcher.role === UserMatcherUserRole.ROLE_BACK_USER) return "Back bot";
  return "";
};

// unique key for a user matcher (since userId can be '')
const userMatcherKey = (matcher: UserMatcherInput) => {
  if (matcher.userId) return matcher.userId;
  if (matcher.email) return matcher.email;
  if (matcher.role && matcher.role !== UserMatcherUserRole.ROLE_UNSPECIFIED) return matcher.role;
  throw new Error("No unique key");
};

const mapMatcherAsOption = (matcher: UserMatcherInput & { variable?: PublishedVariable }): UserSelectOption => {
  return {
    label: userMatcherLabel(matcher),
    value: {
      key: userMatcherKey(matcher),
      id: matcher.userId ?? "",
      name: "",
      email: matcher.email ?? "",
      role: matcher.role ?? UserMatcherUserRole.ROLE_UNSPECIFIED,
      variable: matcher.variable
    }
  };
};

export const UserMatcherComponent: FC<
  (SharedMatcherProps & { actionId?: StepActionIndex }) &
    (
      | {
          isMulti?: undefined;
          matcher: UserMatcherInput;
          onChange(value: UserMatcherInput): void;
        }
      | {
          isMulti: true;
          matcherList: UserMatcherInput[];
          onChangeList(value: UserMatcherInput[]): void;
        }
    )
> = props => {
  // store initial userTypeSelection for e.g. state when list is empty
  const [initialUserTypeSelection] = useState(() =>
    props.isMulti ? props.matcherList[0].userTypeSelection : props.matcher.userTypeSelection
  );
  const inputValue = !props.isMulti
    ? // single-select condition ->>
      userMatcherLabel(props.matcher)
      ? mapMatcherAsOption(props.matcher)
      : undefined
    : // multi-select condition ->>
      props.matcherList.map(mapMatcherAsOption);

  const [isMoreResults, setIsMoreResults] = useState(false);

  const { getWorkflowVariables } = useWorkflow();
  const userVars = (getWorkflowVariables(props.actionId) ?? []).filter(
    variable =>
      variable.type === WorkflowPublishedVariableType.TYPE_USER_ID ||
      variable.type === WorkflowPublishedVariableType.TYPE_EMAIL
  );

  return (
    <AsyncCreatableSelect
      isMulti={props.isMulti}
      styles={{
        container: styles => ({
          ...styles,
          flex: "1 1 auto",
          maxWidth: "100%"
        }),
        control: (styles, state) => ({
          ...styles,
          border: "none",
          borderRadius: 0,
          borderWidth: 0,
          borderBottom: state.isFocused ? "1px solid var(--text-6)" : "1px solid var(--lightGrey-4)",
          boxShadow: "none",
          flexWrap: "nowrap",
          fontSize: "0.875rem",
          overflow: "hidden",
          minHeight: 0,
          "&:hover": {
            cursor: "pointer"
          }
        }),
        indicatorSeparator: styles => ({
          display: "none"
        }),
        input: styles => ({
          ...styles,
          margin: 0,
          padding: 0,
          // keeps cursor above e.g. InlineVariable
          zIndex: zIndices.lowest.value
        }),
        menu: styles => ({
          ...styles,
          margin: "var(--space-1-px) 0",
          zIndex: zIndices.high.value
        }),
        menuList: styles => ({
          ...styles,
          overflowX: "hidden"
        }),
        valueContainer: styles => ({
          ...styles,
          padding: "0 2rem 0 0"
        })
      }}
      className="select-container"
      css={css`
        min-width: 24rem;
        & input#react-select-2-input {
          /* make input align w value component */
          padding-bottom: 4px !important;
        }
      `}
      classNamePrefix="select"
      isClearable
      components={{
        ClearIndicator: props => <MaterialIcon {...props.innerProps} path={mdiClose} size={1} />,
        DropdownIndicator: () => <MaterialIcon path={mdiChevronDown} size={1} />,
        Option: p => {
          const { isFocused } = p;
          const selectValue: UserSelectOption[] | null = (Array.isArray(p.selectProps.value)
            ? p.selectProps.value
            : // normalize non-multi value to list, empty list for null
              [p.selectProps.value].filter(v => v !== null)) as any;
          const optionData: UserSelectOption = p.data;
          // exclude options that are "actions" e.g. adding a new value rather than selecting
          const isSelected = !p.data.action && selectValue?.some(v => v.value?.key === optionData.value?.key);
          const isDisabled = !!p.data.disabled;
          let onClick: React.DOMAttributes<HTMLDivElement>["onClick"] = undefined;
          if (!isDisabled && !isSelected) onClick = p.innerProps.onClick;
          if (isSelected && props.isMulti)
            // multi-select condition ->>
            // remove item
            onClick = () => {
              props.onChangeList(props.matcherList.filter(m => userMatcherKey(m) !== optionData.value?.key));
            };
          return (
            <PopOverMenuItemInner selected={isSelected} focused={isFocused} onClick={onClick} disabled={isDisabled}>
              {optionData.value?.variable && <InlineVariable name={optionData.value?.variable.name} />}
              {!optionData.value?.variable && (
                <Typo.Body light={isDisabled} ellipsis>
                  {p.data.label}
                </Typo.Body>
              )}
              {isSelected && <MaterialIcon path={mdiCheck} size={1.5} />}
            </PopOverMenuItemInner>
          );
        },
        // multi-select only ->>
        MultiValueContainer: props => {
          return (
            <div
              css={css`
                display: flex;
                & + & {
                  margin-left: var(--space-1-rem);
                }
              `}
            >
              {props.children}
            </div>
          );
        },
        // multi-select only ->>
        MultiValueLabel: props => {
          let value = "";
          if (props.data.value?.email) value = props.data.value?.email;
          if (props.data.value?.id) value = props.data.value?.id;
          const isVariable = /\$\{.+\}/.test(value);
          return (
            <span>
              {isVariable && <InlineVariable name={value.replace(/[${}]/g, "")} />}
              {!isVariable && props.data.label}
            </span>
          );
        },
        // multi-select only ->>
        MultiValueRemove: props => {
          return (
            <SquareButton
              {...props.innerProps}
              size="small"
              type="button"
              css={css`
                padding-left: 2px;
              `}
            >
              <MaterialIcon path={mdiClose} size={1} />
            </SquareButton>
          );
        },
        // single-select only ->>
        SingleValue: props => {
          let value = "";
          if (props.data.value?.email) value = props.data.value?.email;
          if (props.data.value?.id) value = props.data.value?.id;
          const isVariable = /\$\{.+\}/.test(value);
          return (
            <div css={css(props.getStyles("singleValue", props))}>
              {isVariable && <InlineVariable name={value.replace(/[${}]/g, "")} />}
              {!isVariable && props.data.label}
            </div>
          );
        }
      }}
      defaultOptions
      hideSelectedOptions
      blurInputOnSelect
      cacheOptions={false}
      loadOptions={(value: string) =>
        getUserList(value, initialUserTypeSelection === UserTypeSelection.USER_TYPE_SELECTION_EXPERTS).then(
          userList => {
            setIsMoreResults(userList.pageInfo.hasNextPage);
            const options: UserSelectOption[] = [
              mapMatcherAsOption({
                userTypeSelection: initialUserTypeSelection,
                role: UserMatcherUserRole.ROLE_CURRENT_USER
              }),
              mapMatcherAsOption({
                userTypeSelection: initialUserTypeSelection,
                role: UserMatcherUserRole.ROLE_BACK_USER
              }),
              ...userVars.map(variable => {
                if (variable.type === WorkflowPublishedVariableType.TYPE_EMAIL)
                  return mapMatcherAsOption({
                    email: variable.templateString,
                    userTypeSelection: initialUserTypeSelection,
                    variable
                  });
                if (variable.type === WorkflowPublishedVariableType.TYPE_USER_ID)
                  return mapMatcherAsOption({
                    userId: variable.templateString,
                    userTypeSelection: initialUserTypeSelection,
                    variable
                  });
                throw new Error("Missing variable type");
              }),
              ...userList.users.map(user => ({
                label: user.name ? `${user.name} (${user.email})` : user.email,
                value: {
                  key: user.id,
                  name: user.name,
                  id: user.id,
                  email: user.email,
                  role: UserMatcherUserRole.ROLE_UNSPECIFIED
                }
              }))
            ];
            return Promise.resolve(options);
          }
        )
      }
      onChange={(data: ValueType<UserSelectOption>) => {
        // multi-select condition ->>
        if (props.isMulti) {
          if (data && "length" in data) {
            return props.onChangeList(
              data.flatMap(d =>
                d.value
                  ? [
                      {
                        email: d.value.email,
                        userId: d.value.id,
                        role: d.value.role ?? UserMatcherUserRole.ROLE_UNSPECIFIED,
                        userTypeSelection: initialUserTypeSelection
                      }
                    ]
                  : []
              )
            );
          }
          if (data === null) {
            return props.onChangeList([]);
          }
        }
        // single-select condition ->>
        if (!props.isMulti) {
          if (data && !("length" in data) && data.value) {
            return props.onChange({
              email: data.value.email,
              userId: data.value.id,
              role: data.value.role,
              userTypeSelection: initialUserTypeSelection
            });
          }
          if (data === null) {
            return props.onChange({
              email: "",
              userId: "",
              role: UserMatcherUserRole.ROLE_UNSPECIFIED,
              userTypeSelection: initialUserTypeSelection
            });
          }
        }
        reportDevError("Missing onChange");
      }}
      placeholder={props.emptyLabel ?? `Search ${getPlaceholderByUserType(initialUserTypeSelection)}`}
      getIcon={(value?: UserComponentFields) =>
        value && (
          <UserAvatar
            sizeXS
            user={value}
            css={css`
              margin: 0 0.5rem 0 0;
            `}
          />
        )
      }
      value={inputValue}
      isValidNewOption={value =>
        initialUserTypeSelection === UserTypeSelection.USER_TYPE_SELECTION_INTERNAL_AND_EXTERNAL_USERS
          ? isValidNewOption(value)
          : false
      }
      getNewOptionData={value => {
        if (!value)
          return {
            label: `Type to search existing ${getPlaceholderByUserType(initialUserTypeSelection)}`,
            disabled: true,
            value: {
              key: "",
              id: "",
              email: "",
              name: "",
              role: UserMatcherUserRole.ROLE_UNSPECIFIED
            }
          };
        if (isMoreResults)
          return {
            label: "...",
            disabled: true,
            value: {
              key: "",
              id: "",
              email: "",
              name: "",
              role: UserMatcherUserRole.ROLE_UNSPECIFIED
            }
          };
        if (initialUserTypeSelection !== UserTypeSelection.USER_TYPE_SELECTION_INTERNAL_AND_EXTERNAL_USERS)
          return {
            label: `"${value}" does not match any users`,
            disabled: true,
            value: {
              key: "",
              id: "",
              email: "",
              name: "",
              role: UserMatcherUserRole.ROLE_UNSPECIFIED
            }
          };
        // Only show create message if UserSelectType is Users + Experts
        return {
          label: `Add "${value}"`,
          action: true,
          value: {
            key: "",
            id: "",
            email: value,
            name: "",
            role: UserMatcherUserRole.ROLE_UNSPECIFIED
          }
        };
      }}
      onCreateOption={value => {
        // multi-select condition ->>
        if (props.isMulti) {
          props.onChangeList([
            ...props.matcherList,
            {
              email: value,
              userId: "",
              userTypeSelection: initialUserTypeSelection
            }
          ]);
        }
        // single-select condition ->>
        if (!props.isMulti) {
          props.onChange({
            email: value,
            userId: "",
            userTypeSelection: initialUserTypeSelection
          });
        }
      }}
    />
  );
};
