import { createContext, useContext, useEffect } from "react";

export const DelayNavContext = createContext({
  shouldDelayNav: false,
  setShouldDelayNav: (shouldDelay: boolean) => {
    void 0;
  }
});

export function useWarnBeforeUnload(shouldWarn: boolean) {
  const { setShouldDelayNav } = useContext(DelayNavContext);
  useEffect(() => {
    setShouldDelayNav(shouldWarn);
    const callback = (e: BeforeUnloadEvent) => {
      if (shouldWarn) {
        e.preventDefault();
        // note, browsers don't allow displaying a custom msg these days
        e.returnValue = "Close or reload without saving?";
      }
    };
    window.addEventListener("beforeunload", callback);
    return () => {
      // when hook unmounts reset delay context value to false
      // this ensures that .e.g. when purposefully unloading an unsaved form we don't block navigate
      setShouldDelayNav(false);
      window.removeEventListener("beforeunload", callback);
    };
  }, [shouldWarn, setShouldDelayNav]);
}

export const WarnBeforeUnload: React.FC<{ hasChanges: boolean }> = props => {
  useWarnBeforeUnload(props.hasChanges);
  return null;
};
