import { css } from "@emotion/core";
import { csx } from "src/util/csx";

/**
 * If there's an icon, pass it as the first child.
 */
export const InfoBanner = csx(
  [
    css`
      width: 100%;
      background: var(--lightGrey-1);
      padding: var(--space-2-rem);
      border-radius: var(--border-radius-s);
      /* guarantee 38px min height  */
      min-height: 2.375rem;
      box-sizing: border-box;
      & > :first-child {
        float: left;
        padding-top: 2px;
        margin: 0;
        margin-right: var(--space-1-rem);
      }
    `
  ],
  {
    clickable: css`
      &:hover {
        cursor: pointer;
        background: var(--lightGrey-2);
      }
      & button {
        pointer-events: none;
      }
    `,
    blue: css`
      background: var(--lightBlue-1);
      & a {
        &:hover {
          background: var(--lightBlue-2);
        }
      }
    `,
    smallIcon: css`
      & > :first-child {
        padding-top: 5px;
      }
    `
  }
);
