import { css } from "@emotion/core";
import { mdiClose, mdiFile, mdiImage } from "@mdi/js";
import * as React from "react";
import { FC } from "react";
import { isFilenameImage, TImageAction } from "src/App/Attachments/ImageViewer";
import { MaterialIcon, SquareButton } from "src/components";
import { Typo } from "src/styling/primitives/typography";
import { csx } from "src/util/csx";
import { fileSize } from "src/util/formatters";

export const attachmentUrl = (
  attachment: {
    id: string;
  },
  authToken: string | null
) => `${process.env.REACT_APP_ATTACHMENTS_URL}/${attachment.id}${!authToken ? "" : `?auth_token=${authToken}`}`;

const fileNameStyle = css`
  flex: 0 1 auto;
  margin-right: var(--space-2-rem);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const AttachmentLink: FC<{
  attachment: {
    id: string;
    displayName: string;
    size: number;
    deleteTime: ScalarDateTime | null;
  };
  dispatch: React.Dispatch<TImageAction>;
  authToken: string | null;
}> = props => {
  return (
    <div
      css={css`
        cursor: pointer;
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        overflow: hidden;
        a {
          text-decoration: underline;
          font-weight: var(--font-weight-body-regular);
          /* Center horizontally but still show short length charachters */
          line-height: 1;
          padding: var(--space-1-rem) 0;
        }
        & > * + * {
          margin-left: var(--space-2-rem);
        }
      `}
    >
      {isFilenameImage(props.attachment.displayName) ? (
        <ImageIcon attachment={props.attachment} authToken={props.authToken} />
      ) : (
        <MaterialIcon path={mdiFile} size={1.125} />
      )}
      {!props.attachment.deleteTime && (
        <>
          {isFilenameImage(props.attachment.displayName) ? (
            <Typo.ExternalLink
              ellipsis
              onClick={() => props.dispatch({ type: "DISPLAY_IMAGE", id: props.attachment.id })}
              title={props.attachment.displayName}
              css={fileNameStyle}
            >
              {props.attachment.displayName}
            </Typo.ExternalLink>
          ) : (
            <Typo.ExternalLink
              ellipsis
              css={fileNameStyle}
              href={attachmentUrl(props.attachment, props.authToken)}
              title={props.attachment.displayName}
            >
              {props.attachment.displayName}
            </Typo.ExternalLink>
          )}
        </>
      )}
      {props.attachment.deleteTime && (
        <Typo.Body light italic>
          This attachment was deleted
        </Typo.Body>
      )}
    </div>
  );
};

const MAX_THUMBNAIL_SIZE = Math.pow(10, 6);

const ImageIcon: React.FC<{
  attachment: {
    id: string;
    displayName: string;
    size: number;
  };
  authToken: string | null;
}> = props => {
  return props.attachment.size < MAX_THUMBNAIL_SIZE ? (
    <div
      css={css`
        width: 1.125rem;
        height: 1.125rem;
        background-position: center center;
        background-size: cover;
      `}
      style={{ backgroundImage: `url("${attachmentUrl(props.attachment, props.authToken)}")` }}
    />
  ) : (
    <MaterialIcon path={mdiImage} size={1.125} />
  );
};

export const AttachmentItemWrapper = csx([
  css`
    background-color: var(--lightGrey-1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius-s);
    width: 100%;
    height: 40px;
    padding: 0 var(--space-2-rem);
    box-sizing: border-box;
    &:not(:first-child) {
      margin-top: var(--space-2-rem);
    }
  `
]);

export const AttachmentItem: FC<{
  attachment: {
    id: string;
    displayName: string;
    size: number;
  };
  imageViewerDispatch: React.Dispatch<TImageAction>;
  authToken: string | null;
  handleRemove?(id: string): void;
  extraSpacing?: boolean;
}> = ({ attachment, imageViewerDispatch: dispatch, handleRemove, extraSpacing, authToken }) => {
  return (
    <AttachmentItemWrapper
      css={css`
        & .delete-attachment {
          opacity: 0;
        }

        &:hover .delete-attachment {
          opacity: 1;
        }
      `}
    >
      <div
        css={[
          css`
            width: 100%;
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          `,
          extraSpacing &&
            css`
              flex: 0 1 auto;
              width: calc(100% - var(--space-7-rem));
            `
        ]}
      >
        <AttachmentLink attachment={{ ...attachment, deleteTime: null }} dispatch={dispatch} authToken={authToken} />
        <Typo.Body
          light
          css={css`
            flex: 0 0 auto;
          `}
        >
          &nbsp;({fileSize(attachment.size)})
        </Typo.Body>
      </div>
      {handleRemove && (
        <SquareButton
          css={css`
            flex: 0 0 auto;
            margin-left: var(--space-2-rem);
          `}
          data-testid="attachment-delete"
          onClick={() => handleRemove(attachment.id)}
        >
          <MaterialIcon path={mdiClose} size={1.125} />
        </SquareButton>
      )}
    </AttachmentItemWrapper>
  );
};
