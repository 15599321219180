import { css } from "@emotion/core";
import React from "react";
import { ConditionalTooltip } from "src/components";
import { RequestStatus } from "src/globalTypes";
import { Typo } from "src/styling/primitives/typography";
import { __rawColorValues } from "src/styling/tokens/__colors";
import { csx } from "src/util/csx";

export const colorVariantMappings = {
  lightGrey: {
    icon: __rawColorValues.text_2,
    bg: __rawColorValues.lightGrey_1,
    bgHover: __rawColorValues.lightGrey_2,
    bgActive: __rawColorValues.lightGrey_3
  },
  grey: {
    icon: __rawColorValues.text_4,
    bg: __rawColorValues.lightGrey_2,
    bgHover: __rawColorValues.lightGrey_3,
    bgActive: __rawColorValues.lightGrey_4
  },
  pink: {
    icon: __rawColorValues.pink_4,
    bg: __rawColorValues.pink_1,
    bgHover: __rawColorValues.pink_2,
    bgActive: __rawColorValues.pink_3
  },
  violet: {
    icon: __rawColorValues.violet_5,
    bg: __rawColorValues.violet_1,
    bgHover: __rawColorValues.violet_2,
    bgActive: __rawColorValues.violet_3
  },
  blue: {
    icon: __rawColorValues.blue_1,
    bg: __rawColorValues.lightBlue_1,
    bgHover: __rawColorValues.lightBlue_2,
    bgActive: __rawColorValues.lightBlue_3
  },
  green: {
    icon: __rawColorValues.green_6,
    bg: __rawColorValues.green_1,
    bgHover: __rawColorValues.green_2,
    bgActive: __rawColorValues.green_3
  },
  yellow: {
    icon: __rawColorValues.yellow_6,
    bg: __rawColorValues.yellow_1,
    bgHover: __rawColorValues.yellow_2,
    bgActive: __rawColorValues.yellow_3
  },
  flesh: {
    icon: __rawColorValues.flesh_6,
    bg: __rawColorValues.flesh_2,
    bgHover: __rawColorValues.flesh_3,
    bgActive: __rawColorValues.flesh_4
  },
  red: {
    icon: __rawColorValues.red_4,
    bg: __rawColorValues.red_1,
    bgHover: __rawColorValues.red_2,
    bgActive: __rawColorValues.red_3
  }
  // add new coolor variants here and TypeScript shoulld pick them up
};

export type ColorVariantsUnion = keyof typeof colorVariantMappings;
const mapColorToStatus = (requestStatus: RequestStatus): ColorVariantsUnion => {
  switch (requestStatus) {
    case RequestStatus.WAITING:
      return "red";
    default:
    case RequestStatus.NOTSTARTED:
      return "lightGrey";
    case RequestStatus.INPROGRESS:
      return "yellow";
    case RequestStatus.RESOLVED:
      return "green";
  }
};

const statusIcon = (step: RequestStatus, color: ColorVariantsUnion) => {
  switch (step) {
    default:
    case RequestStatus.NOTSTARTED:
      return (
        <>
          <circle
            css={css`
              fill: ${colorVariantMappings[color].bg};
            `}
            cx="9"
            cy="9"
            r="5"
          />
          <path
            css={css`
              fill: ${colorVariantMappings[color].icon};
            `}
            d="M9,5c2.21,0,4,1.79,4,4s-1.79,4-4,4s-4-1.79-4-4S6.79,5,9,5 M9,3C5.69,3,3,5.69,3,9s2.69,6,6,6s6-2.69,6-6S12.31,3,9,3L9,3z
        "
          />
        </>
      );
    case RequestStatus.INPROGRESS:
      return (
        <>
          <circle
            css={css`
              fill: ${colorVariantMappings[color].bg};
            `}
            cx="9"
            cy="9"
            r="5"
          />
          <path
            css={css`
              fill: ${colorVariantMappings[color].icon};
            `}
            d="M9,3C5.69,3,3,5.69,3,9s2.69,6,6,6s6-2.69,6-6S12.31,3,9,3z M9,13V5c2.21,0,4,1.79,4,4C13,11.21,11.21,13,9,13z"
          />
        </>
      );
    case RequestStatus.WAITING:
      return (
        <>
          <circle
            css={css`
              fill: ${colorVariantMappings[color].bg};
            `}
            cx="9"
            cy="9"
            r="5"
          />
          <path
            css={css`
              fill: ${colorVariantMappings[color].icon};
            `}
            d="M9,3C5.69,3,3,5.69,3,9s2.69,6,6,6s6-2.69,6-6S12.31,3,9,3z M9,5c1.86,0,3.41,1.28,3.86,3H5.14C5.59,6.28,7.14,5,9,5z
         M9,13c-1.86,0-3.41-1.28-3.86-3h7.72C12.41,11.72,10.86,13,9,13z"
          />
        </>
      );
    case RequestStatus.RESOLVED:
      return (
        <circle
          css={css`
            fill: ${colorVariantMappings[color].icon};
          `}
          cx="9"
          cy="9"
          r="6"
        />
      );
  }
};

export const Status = {
  _BubbleButton: csx(
    [
      css`
        max-width: 11.25rem;
        padding: 0;
        border: none;
        outline: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        & > * + * {
          margin-left: var(--space-2-rem);
        }
        p {
          white-space: nowrap;
          color: var(--text-6);
        }
      `
    ],
    {
      hasInteractions: css`
        &:not([disabled]) {
          cursor: pointer;
        }
        &[disabled] {
          opacity: 0.6;
          cursor: not-allowed;
        }
      `
    },
    "button"
  ),
  _SvgWrapper: csx(
    [
      css`
        box-sizing: border-box;
        min-width: var(--space-6-rem);
        height: var(--space-6-rem);
        border-radius: var(--border-radius-round);
        padding: 0 7px;
        display: flex;
        align-items: center;
        justify-content: center;

        transition: 0.3s background-color ease;

        & > p {
          line-height: 1;
          padding: var(--space-1-rem) 5px var(--space-1-rem) 3px;
        }

        & > svg {
          min-width: 18px;
          width: 18px;
          height: 18px;
          & > * {
            transition: 0.3s fill ease;
          }
        }
      `
    ],
    {
      condensed: css`
        min-width: var(--space-5-rem);
        height: var(--space-5-rem);
        padding: 3px;
        & > p {
          padding-right: 5px;
          padding-left: 2px;
        }
        & > svg {
          min-width: 16px;
          width: 16px;
          height: 16px;
        }
      `,
      plain: css`
        padding: 0;
        min-width: 18px;
        height: 18px;
      `,
      wide: css`
        padding-left: var(--space-3-px);
        padding-right: var(--space-3-px);
      `
    }
  ),
  _Bubble: ({
    step,
    color,
    statusText,
    condensed,
    disabled,
    hasInteractions,
    plain,
    wide,
    ...rest
  }: {
    step: RequestStatus;
    color: ColorVariantsUnion;
    statusText?: string;
    condensed: boolean;
    disabled: boolean;
    hasInteractions: boolean;
    plain: boolean;
    wide: boolean;
  }) => {
    if (step === RequestStatus.UNSETREQUESTSTATUS) return null;
    return !plain ? (
      <Status._BubbleButton
        disabled={disabled}
        hasInteractions={hasInteractions}
        css={[
          css`
            & > div:first-of-type {
              background-color: ${colorVariantMappings[color].bg};
            }
          `,
          hasInteractions &&
            css`
              & > div:first-of-type {
                background-color: ${colorVariantMappings[color].bg};
              }
              &:not([disabled]):hover {
                & > div:first-of-type {
                  background-color: ${colorVariantMappings[color].bgHover};
                }
              }
              &:not([disabled]):active {
                & > div:first-of-type {
                  background-color: ${colorVariantMappings[color].bgActive};
                }
              }
            `
        ]}
        {...rest}
      >
        <Status._SvgWrapper condensed={condensed} wide={wide}>
          <svg width="18" height="18" viewBox="0 0 18 18">
            {statusIcon(step, color)}
          </svg>
          {statusText && (
            <Typo.Body sizeS={condensed} ellipsis>
              {statusText}
            </Typo.Body>
          )}
        </Status._SvgWrapper>
      </Status._BubbleButton>
    ) : (
      <Status._SvgWrapper condensed={condensed} plain>
        <svg width="18" height="18" viewBox="0 0 18 18">
          {statusIcon(step, color)}
        </svg>
      </Status._SvgWrapper>
    );
  },
  Indicator: ({
    requestStatus,
    statusText,
    condensed = false,
    tooltipText,
    disabled = false,
    hasInteractions = true,
    plain = false,
    wide = false,
    customColor,
    ...rest
  }: {
    requestStatus: RequestStatus;
    statusText?: string;
    condensed?: boolean;
    tooltipText?: string;
    disabled?: boolean;
    hasInteractions?: boolean;
    customColor?: ColorVariantsUnion;
    plain?: boolean;
    wide?: boolean;
  }) => {
    if (plain && condensed) {
      // eslint-disable-next-line no-console
      console.info("Status.Indicator: the plain version cannot be condensed.");
    }
    if (plain && statusText) {
      // eslint-disable-next-line no-console
      console.info("Status.Indicator: the plain version has icon only. A text will not be displayed.");
    }
    return (
      <ConditionalTooltip tooltipText={tooltipText}>
        <Status._Bubble
          step={requestStatus}
          color={customColor || mapColorToStatus(requestStatus)}
          statusText={statusText}
          condensed={condensed}
          disabled={disabled}
          hasInteractions={plain ? false : hasInteractions}
          plain={plain}
          wide={wide}
          {...rest}
        />
      </ConditionalTooltip>
    );
  }
};
