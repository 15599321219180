import gql from "graphql-tag";
export const CUSTOM_STATUS_FRAGMENT = gql`
  fragment CustomStatusFragment on CustomStatus {
    id
    teamId
    step
    name
    color
    isDefaultStatus
    sendNotification
  }
`;
