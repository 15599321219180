import * as React from "react";
import { useState } from "react";
import { useQuery } from "react-apollo";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { SETTINGS_TEAM_LIST_GET } from "src/App/Settings/Teams/Overview";
import { GSettingsTeamListGet } from "src/App/Settings/Teams/typings/GSettingsTeamListGet";
import { Button, Col, Dialog, LoadingBar } from "src/components";
import { useWarnBeforeUnload } from "src/hooks/useWindowEvents";
import { Modal } from "src/portals/Modal";
import { justify, row, spacing } from "src/styling/primitives";
import { trackBestPracticeSharingIntent } from "src/util/analytics";
import { useUrlState } from "src/util/router";
import { ChooseForm } from "./Choose";
import { CreateEditForm } from "./CreateEdit";
import { FormPreview } from "./PreviewModal";
import { FormAction, FormState } from "./reducer";
import { SchemaList_schemaList } from "./typings/SchemaList";

export function InlineFormModal(props: {
  teamId: string;
  state: FormState;
  dispatch: React.Dispatch<FormAction>;
  dismiss(): void;
}) {
  const [modalUrlState, setModalUrlState] = useUrlState<"create" | "edit" | null>("modal", null);
  const modal = modalUrlState ?? props.state.modal;

  const { emitSnack } = useSnack();
  const teamListResponse = useQuery<GSettingsTeamListGet>(SETTINGS_TEAM_LIST_GET);

  return (
    <Modal isOpen={!!modal} onDismiss={props.dismiss} alignTop>
      <Dialog large onClose={props.dismiss}>
        <Col flex="1 1 auto">
          {teamListResponse.loading && <LoadingBar />}
          {modal === "choose" && teamListResponse.data && (
            <ChooseForm
              key={modal}
              teamId={props.teamId}
              teamName={(teamListResponse.data.teamList.find(t => t.id === props.teamId) || { name: "" }).name}
              formState={props.state}
              formDispatch={props.dispatch}
              setModal={setModalUrlState}
            />
          )}
          {modal === "create" && teamListResponse.data && (
            <CreateEditForm
              teamId={props.teamId}
              teamList={teamListResponse.data.teamList}
              onSuccess={(id: string) => {
                props.dismiss();
                props.dispatch({ type: "SELECT_SCHEMA_ID", selectedFormSchemaId: id });
                props.dispatch({ type: "SET_MODAL", modal: "choose" });
                emitSnack({
                  type: "info",
                  message: `Form created`
                });
              }}
              onCancel={() => {
                props.dispatch({ type: "SET_MODAL", modal: "choose" });
              }}
            />
          )}
          {modal === "preview" && props.state.formSchemaToPreview && (
            <>
              <FormPreview schema={props.state.formSchemaToPreview} />
              <div css={[row, justify.end, spacing.ml1, spacing.mt2]}>
                <Button
                  size="large"
                  onClick={() =>
                    props.dispatch({
                      type: "SET_MODAL",
                      modal: "choose"
                    })
                  }
                >
                  Back
                </Button>
                <Button
                  autoFocus
                  variant="primary"
                  size="large"
                  onClick={() =>
                    props.state.formSchemaToPreview &&
                    props.dispatch({
                      type: "CHOOSE_SCHEMA",
                      formSchemaToSend: props.state.formSchemaToPreview
                    })
                  }
                >
                  Use this form
                </Button>
              </div>
            </>
          )}
          {modal === "previewSelected" && (
            <>
              <FormPreview schema={props.state.formSchemaToSend} />
              <div css={[row, justify.end, spacing.ml1, spacing.mt2]}>
                <Button
                  size="large"
                  onClick={() =>
                    props.dispatch({
                      type: "SET_MODAL",
                      modal: "choose"
                    })
                  }
                >
                  Use different form
                </Button>
              </div>
            </>
          )}
        </Col>
      </Dialog>
    </Modal>
  );
}

export function FormMgmtPageModal(props: {
  teamId: string;
  modal: "create" | "edit" | null;
  selectedSchema?: SchemaList_schemaList;
  state: FormState;
  dispatch: React.Dispatch<FormAction>;
  dismiss(): void;
}) {
  const { emitSnack } = useSnack();
  const modal = props.modal ?? props.state.modal;
  const [hasChanges, setHasChanges] = useState(false);
  useWarnBeforeUnload(hasChanges);
  const dismiss = () => {
    if (hasChanges && (modal === "create" || modal === "edit")) {
      if (window.confirm("Close without saving?")) {
        props.dismiss();
        setHasChanges(false);
      }
    } else {
      props.dismiss();
    }
  };

  const teamListResponse = useQuery<GSettingsTeamListGet>(SETTINGS_TEAM_LIST_GET);
  return (
    <Modal isOpen={!!modal && modal !== "share"} onDismiss={dismiss} alignTop>
      <Dialog large onClose={dismiss}>
        <Col flex="1 1 auto">
          {teamListResponse.loading && <LoadingBar />}
          {modal === "create" && teamListResponse.data && (
            <CreateEditForm
              teamId={props.teamId}
              teamList={teamListResponse.data.teamList}
              onSuccess={(id: string) => {
                setHasChanges(false);
                props.dispatch({ type: "SELECT_SCHEMA_ID", selectedFormSchemaId: id });
                dismiss();
                emitSnack({
                  type: "info",
                  message: `Form created`
                });
              }}
              onCancel={dismiss}
            />
          )}
          {modal === "edit" && teamListResponse.data && props.selectedSchema && (
            <CreateEditForm
              teamId={props.teamId}
              teamList={teamListResponse.data.teamList}
              existingForm={props.selectedSchema}
              onSuccess={(id: string) => {
                setHasChanges(false);
                props.dispatch({ type: "SELECT_SCHEMA_ID", selectedFormSchemaId: id });
                dismiss();
                emitSnack({
                  type: "info",
                  message: `Form ${props.selectedSchema?.displayName} saved`
                });
              }}
              onCancel={dismiss}
              onShareClick={() => {
                props.selectedSchema?.id && trackBestPracticeSharingIntent({ form_id: props.selectedSchema.id });
                props.dispatch({ type: "SET_MODAL", modal: "share" });
              }}
            />
          )}
        </Col>
      </Dialog>
    </Modal>
  );
}
