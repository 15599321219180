import { mdiDotsHorizontal } from "@mdi/js";
import * as React from "react";
import { FC } from "react";
import { MaterialIcon, SquareButton } from "src/components";
import { OptionsUnion, PopOver } from "./index";

export const ShowMorePopMenu: FC<{
  options: OptionsUnion;
}> = props => (
  <PopOver.Menu
    options={props.options}
    trigger={
      <SquareButton>
        <MaterialIcon path={mdiDotsHorizontal} size={1.125} />
      </SquareButton>
    }
  />
);
