import { css } from "@emotion/core";
import styled from "@emotion/styled";
import React from "react";
import DoodleSvg from "src/assets/Doodle.svg";
import ScribbleSvg from "src/assets/Scribble.svg";
import { Col } from "src/components";
import { csx } from "src/util/csx";

export const EmptyFallback = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;
`;

const Title = styled.h1<{ variant?: "info" | "error" | "none" }>`
  font-family: var(--font-family-body);
  color: var(--text-6);
  font-size: var(--font-size-display-small);
  margin: 0 0 0.25rem;
  text-align: center;
  background-repeat: no-repeat;

  ${p =>
    (p.variant === "info" || !p.variant) &&
    `
      background-image: url("${DoodleSvg}");
      background-size: contain;
      background-position: left center;
      padding: 0.125rem 0.625rem 0.25rem;
    `}

  ${p =>
    p.variant === "error" &&
    `
      background-image: url("${ScribbleSvg}");
      background-position: 4px 7px;
      padding-bottom: 0.25rem;
    `}
`;

const Subtitle = styled.div`
  font-size: 1rem;
  text-align: center;
  line-height: 1.5;
  max-width: 32.5rem;
  margin-top: 0.5rem;
`;

const spacing = {
  tight: "6rem 3rem",
  loose: "12rem 3rem"
};

const Body = csx([
  css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  `
]);

export const EmptyState: React.FC<{
  title?: string;
  subtitle?: React.ReactNode | string;
  variant?: "info" | "error" | "none";
  spacing?: "tight" | "loose";
  className?: string;
}> = props => {
  const padding = (props.spacing && spacing[props.spacing]) || spacing["tight"];
  return (
    <Col flex="1 0 auto" justify="center" padding={padding} className={props.className}>
      <EmptyFallback>
        <Title variant={props.variant}>{props.title || "Nothing to see here"}</Title>
        {props.subtitle && <Subtitle>{props.subtitle}</Subtitle>}
        {props.children && <Body>{props.children}</Body>}
      </EmptyFallback>
    </Col>
  );
};
