import { css } from "@emotion/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Manager, Popper, PopperProps, RefHandler } from "react-popper";
import { CSSTransition } from "react-transition-group";
import { ClickBlock } from "src/components";

interface IPopProps {
  /**
   * button to activate popper
   */
  button: JSX.Element;
  /**
   * activate on mouse over button
   * (default false)
   */
  hover?: boolean;
  /**
   * open menu by default on render
   */
  isOpen?: boolean;
  /**
   * keep open
   */
  persist?: boolean;
  disabled?: boolean;
  placement?: PopperProps["placement"];
  cursor?: string;
  style?: object;
  menuStyle?: object;
  propagateButtonClick?: boolean;
  updatePositionKey?: string;
  zIndex?: number;
  className?: string;
  onClose?(): void;
  onClickAway?(e: React.ChangeEvent<{}>): void;
}

function getOrCreatePopper(): Element {
  let popperElement = document.querySelector("#popper");
  if (!popperElement) {
    popperElement = document.createElement("div");
    popperElement.id = "popper";
    document.body.appendChild(popperElement);
  }
  return popperElement;
}

const popper = getOrCreatePopper();

/**
 * Renders a "popper" when acitvated w/ button
 */
export const PopMenu: React.FunctionComponent<IPopProps> = props => {
  const [open, setOpen] = useState(false);
  const target = useRef<HTMLDivElement>(null);
  const handleClose = useCallback(() => {
    setOpen(false);
    props.onClose?.();
  }, [props]);

  // this is a hack in place of lifting the "open" state out of this component
  // as the latter involves changes at all callsites of PopMenu and PopOverMenu
  // and unfortunately, as yet, there isn't enough time available to do so
  React.useEffect(() => setOpen(props.isOpen ?? false), [props.isOpen]);

  return popper ? (
    <Manager>
      <div
        ref={target}
        onClick={e => {
          if (props.hover || props.disabled) {
            return;
          }
          if (!props.propagateButtonClick) {
            e.stopPropagation();
            e.preventDefault();
          }
          if (open) {
            handleClose();
          } else {
            setOpen(true);
          }
        }}
        onMouseOver={() => props.hover && setOpen(true)}
        onMouseLeave={() => props.hover && handleClose()}
        style={{ display: "inline-block", cursor: props.cursor || "pointer", maxWidth: "100%", ...props.style }}
        className={open || props.isOpen ? "menu open" : "menu closed"}
      >
        {props.button}
      </div>
      {(open || props.persist) &&
        ReactDOM.createPortal(
          <CSSTransition appear timeout={0} classNames="popper-transition">
            {target.current && (
              <Popper
                placement={props.placement || "bottom-start"}
                referenceElement={target.current}
                modifiers={{
                  preventOverflow: {
                    boundariesElement: document.body
                  }
                }}
              >
                {({ ref, style, placement, scheduleUpdate }) => (
                  <>
                    {!(props.hover || props.persist) && <ClickBlock onClick={e => e.stopPropagation()} />}
                    <PopperElement
                      {...props}
                      refHandler={ref}
                      style={style}
                      placement={placement}
                      target={target}
                      handleClose={handleClose}
                      scheduleUpdate={scheduleUpdate}
                      updatePositionKey={props.updatePositionKey}
                    >
                      {props.children}
                    </PopperElement>
                  </>
                )}
              </Popper>
            )}
          </CSSTransition>,
          popper
        )}
    </Manager>
  ) : null;
};

function PopperElement(
  props: IPopProps & {
    refHandler: RefHandler;
    children: React.ReactNode;
    target: React.RefObject<HTMLElement>;
    updatePositionKey?: string;
    handleClose(): void;
    scheduleUpdate(): void;
    zIndex?: number;
    className?: string;
  }
) {
  useEffect(() => {
    props.scheduleUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.scheduleUpdate, props.updatePositionKey]);
  return (
    <ClickAwayListener
      onClickAway={e => {
        // ensures you can close by clicking on button
        // not sure what the typechecker thinks is going on here
        if (props.target && !props.target?.current?.contains(e.target as Node)) {
          props.handleClose();
        }
        if (props.onClickAway) {
          props.onClickAway(e);
        }
      }}
    >
      <div
        css={[
          props.zIndex
            ? css`
                z-index: ${props.zIndex};
              `
            : css`
                z-index: var(--z-low);
              `
        ]}
        className={props.className}
        ref={props.refHandler}
        style={{
          ...props.style,
          maxHeight: "98vh",
          ...props.menuStyle
        }}
        data-placement={props.placement}
        onClick={e => {
          if (!props.propagateButtonClick) {
            e.stopPropagation();
          }
          props.handleClose();
        }}
      >
        {props.children}
      </div>
    </ClickAwayListener>
  );
}
