import { css } from "@emotion/core";
import { ErrorMessage, Field, Form, Formik, FieldProps } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { useState } from "react";
import { useQuery, useMutation } from "react-apollo";
import Select from "react-select";
import { useSnack } from "src/App/Root/providers/SnackProvider";
import { Button, Dialog, FormikFieldGroup, FormikInput, LoadingBar, SubmitButton } from "src/components";
import { Modal } from "src/portals/Modal";
import { justify, row } from "src/styling/primitives";
import { Typo } from "src/styling/primitives/typography";
import * as yup from "yup";
import { INTEGRATIONS_LIST_GET } from "../Overview";
import { Teams } from "./typings/Teams";
import { IntegrationAddOkta, IntegrationAddOktaVariables } from "./typings/IntegrationAddOkta";

const ADD_INTEGRATION = gql`
  mutation IntegrationAddOkta($params: IntegrationAddOktaParams!) {
    integrationAddOkta(params: $params) {
      code
      message
      success
    }
  }
`;

const TEAM_LIST = gql`
  query Teams {
    teamList {
      id
      name
    }
  }
`;

const validationSchema = yup.object().shape({
  domain: yup.string().required("A domain is required to configure Okta"),
  apiToken: yup.string().required("A valid API token is required to configure Okta"),
  teams: yup.array(yup.object()).required("One or more teams must be specified")
});

export type Team = {
  id: string;
  name: string;
};

const OktaConfigureDialog = (props: { onDismiss(): void; onSuccess(): void }) => {
  const { emitSnack } = useSnack();

  const teamList = useQuery<Teams>(TEAM_LIST);
  const [addIntegration, addIntegrationResponse] = useMutation<IntegrationAddOkta, IntegrationAddOktaVariables>(
    ADD_INTEGRATION,
    {
      onCompleted: data => {
        if (data.integrationAddOkta.success) {
          props.onSuccess();
        } else {
          emitSnack({
            type: "mutationError",
            message: `Failed to configure Okta: ${data.integrationAddOkta.message}`
          });
        }
      },
      refetchQueries: [{ query: INTEGRATIONS_LIST_GET }]
    }
  );

  return (
    <Dialog title="Connect Okta" onClose={props.onDismiss} medium>
      {addIntegrationResponse.loading && <LoadingBar />}
      <Formik
        initialValues={{
          domain: "",
          apiToken: "",
          teams: [] as Team[]
        }}
        validationSchema={validationSchema}
        onSubmit={payload => {
          addIntegration({
            variables: {
              params: {
                domain: payload.domain,
                apiToken: payload.apiToken,
                teamIds: payload.teams.map(x => x.id)
              }
            }
          });
        }}
        render={form => (
          <Form>
            <FormikFieldGroup.Container legend="Domain">
              <Field autoFocus name="domain" component={FormikInput} hideErrorLabel />
              <FormikFieldGroup.HelpText
                css={css`
                  margin-top: var(--space-2-rem);
                `}
              >
                Your Okta domain, eg. <strong>testing</strong> if your Okta URL is https://<strong>testing</strong>
                .okta.com.
              </FormikFieldGroup.HelpText>
              <FormikFieldGroup.Errors>
                <ErrorMessage name="domain" />
              </FormikFieldGroup.Errors>
            </FormikFieldGroup.Container>

            <FormikFieldGroup.Container legend="API token">
              <Field name="apiToken" component={FormikInput} hideErrorLabel type="password" />
              <FormikFieldGroup.HelpText
                css={css`
                  margin-top: var(--space-2-rem);
                `}
              >
                The API token that Back will use when calling Okta APIs. Check the{" "}
                <a
                  href="https://developer.okta.com/docs/guides/create-an-api-token/main/#create-the-token"
                  target="_blank"
                >
                  Okta documentation on API tokens
                </a>{" "}
                for further instructions.
              </FormikFieldGroup.HelpText>
              <FormikFieldGroup.Errors>
                <ErrorMessage name="apiToken" />
              </FormikFieldGroup.Errors>
            </FormikFieldGroup.Container>

            <FormikFieldGroup.Container legend="Teams for which Okta actions are enabled">
              <Field
                name="teams"
                component={FormikInput}
                hideErrorLabel
                render={({ field, form }: FieldProps) => (
                  <Select<Team>
                    options={teamList.data?.teamList ?? []}
                    getOptionLabel={(o: Team) => o.name}
                    getOptionValue={(o: Team) => o.id}
                    value={field.value}
                    onChange={value => form.setFieldValue(field.name, value)}
                    isMulti
                  />
                )}
              />
              <FormikFieldGroup.HelpText
                css={css`
                  margin-top: var(--space-2-rem);
                `}
              >
                Okta actions will only be available in requests assigned to the selected teams.
              </FormikFieldGroup.HelpText>
              <FormikFieldGroup.Errors>
                <ErrorMessage name="teams" />
              </FormikFieldGroup.Errors>
            </FormikFieldGroup.Container>

            <div
              css={[
                row,
                justify.end,
                css`
                  margin-top: var(--space-4-rem);
                `
              ]}
            >
              <SubmitButton disabled={addIntegrationResponse.loading || !form.isValid}>Configure Okta</SubmitButton>
            </div>
          </Form>
        )}
      />
    </Dialog>
  );
};

const OktaSuccessDialog = (props: { onDismiss(): void; onClose(): void }) => {
  return (
    <Dialog title="Okta connection successful" onClose={props.onDismiss} medium>
      <Typo.Body>
        The Okta integration was successfully configured. Your Okta account is now connected to Back.
      </Typo.Body>

      <div
        css={[
          row,
          justify.center,
          css`
            margin-top: var(--space-6-rem);
          `
        ]}
      >
        <Button
          variant="primary"
          onClick={() => {
            props.onClose();
          }}
        >
          Close
        </Button>
      </div>
    </Dialog>
  );
};
export const OktaInstallModal = (props: { onDismiss(): void; onSuccess(): void; isOpen: boolean }) => {
  const [configured, setConfigured] = useState(false);
  const dialog = configured ? (
    <OktaSuccessDialog onDismiss={props.onDismiss} onClose={props.onSuccess} />
  ) : (
    <OktaConfigureDialog
      onDismiss={props.onDismiss}
      onSuccess={() => {
        setConfigured(true);
      }}
    />
  );

  return (
    <Modal isOpen={props.isOpen} onDismiss={props.onDismiss}>
      {dialog}
    </Modal>
  );
};
