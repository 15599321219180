import { v4 as uuidv4 } from "uuid";

const categoryNameMap = {
  ACCESS_TO_SYSTEMS: "Access to Systems",
  BUSINESS_CARD_REQUEST: "Business Card Request",
  CONTRACT: "Contract",
  GENERAL: "General",
  HARDWARE_ISSUES: "Hardware Issues",
  HRIS: "HRIS",
  KEYCARD: "Keycard",
  OFFBOARDING: "Offboarding",
  OFFICE_SUPPLIES: "Office Supplies",
  ONBOARDING: "Onboarding",
  ORDERS: "Orders",
  PAYROLL: "Payroll",
  REFERENCE_LETTER: "Reference Letter",
  SOFTWARE_ISSUES: "Software Issues",
  VISA: "Visa"
};

const categoryNamesList = Object.values(categoryNameMap);

/* canonical list of categories including dynamic ids */
export const categoryList = categoryNamesList.map(name => ({
  id: uuidv4(),
  name
}));

export const defaultTeamCategories = [
  { teamType: "Finance", categories: categoryNamesList },
  {
    teamType: "Human Resources",
    categories: [
      categoryNameMap.ONBOARDING,
      categoryNameMap.CONTRACT,
      categoryNameMap.HRIS,
      categoryNameMap.VISA,
      categoryNameMap.REFERENCE_LETTER,
      categoryNameMap.GENERAL,
      categoryNameMap.OFFBOARDING,
      categoryNameMap.PAYROLL
    ]
  },
  {
    teamType: "Office IT",
    categories: [
      categoryNameMap.ONBOARDING,
      categoryNameMap.GENERAL,
      categoryNameMap.ORDERS,
      categoryNameMap.SOFTWARE_ISSUES,
      categoryNameMap.ACCESS_TO_SYSTEMS,
      categoryNameMap.OFFBOARDING,
      categoryNameMap.HARDWARE_ISSUES
    ]
  },
  {
    teamType: "Office Management",
    categories: [
      categoryNameMap.OFFICE_SUPPLIES,
      categoryNameMap.BUSINESS_CARD_REQUEST,
      categoryNameMap.GENERAL,
      categoryNameMap.ORDERS,
      categoryNameMap.KEYCARD,
      categoryNameMap.OFFBOARDING
    ]
  },
  { teamType: "Other", categories: categoryNamesList }
];
