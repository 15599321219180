import React, { RefObject, useContext } from "react";
import { Pill } from "src/components/PillButton";
import { PopOver } from "src/components/PopOver";
import { RequestPriority } from "src/globalTypes";
import { RequestGet_request } from "../DetailView/typings/RequestGet";
import { ActionsContext } from "./Provider";

export const priorityLabels: { [key in keyof typeof RequestPriority]: string } = {
  [RequestPriority.PRIORITY_UNSPECIFIED]: "No priority",
  [RequestPriority.PRIORITY_HIGH]: "High",
  [RequestPriority.PRIORITY_MEDIUM]: "Medium",
  [RequestPriority.PRIORITY_LOW]: "Low"
} as const;

export const RequestPriorityButton: React.FC<{
  requestId: RequestGet_request["id"];
  priority: RequestPriority;
  isMenuOpen?: boolean;
  enableOptionHotKeys?: boolean;
  buttonRef?: RefObject<HTMLButtonElement>;
  onUpdate?(): void;
  condensed?: boolean;
  showText?: boolean;
}> = ({
  requestId,
  priority,
  buttonRef,
  isMenuOpen,
  enableOptionHotKeys,
  onUpdate,
  condensed = false,
  showText = true
}) => {
  const { handlers, options } = useContext(ActionsContext);

  return (
    <PopOver.Menu
      isOpen={isMenuOpen}
      enableOptionHotKeys={enableOptionHotKeys}
      options={options.setPriority ?? []}
      onClose={onUpdate}
      onSelect={(newPriority: RequestPriority) => handlers.setPriority(requestId, newPriority, priority)}
      selected={priority}
      trigger={
        <Pill.Priority
          priority={priority}
          condensed={condensed}
          showText={showText}
          data-intercom-target="Request priority"
          ref={buttonRef}
        />
      }
      onRemove={() => handlers.setPriority(requestId, RequestPriority.PRIORITY_UNSPECIFIED, priority)}
      labelForRemoveOption={"Remove priority"}
    />
  );
};
