import styled from "@emotion/styled";
import { mdiMagnify } from "@mdi/js";
import * as React from "react";
import { FC } from "react";
import { MaterialIcon } from "src/components";
import { Modal } from "src/portals/Modal";
import { dialog } from "src/styling/effects";
import { handleSubmit } from "src/util";

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  background: var(--white);
  width: 35rem;
  padding: 0 1rem;
  color: var(--text-6);
  ${dialog.shadow}
  border: 1px solid var(--border);
  border-radius: 0.25rem;

  & .icon {
    color: var(--text-2);
  }

  & ::placeholder {
    color: var(--text-2);
  }
`;

const Input = styled.input`
  flex: 1 0 auto;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 1rem 0.5rem;
  outline: none;
  border: none;
  color: var(--text-6);
`;

export const SearchDialog: FC<{
  onDismiss(): void;
  onSubmit(query: string): void;
  placeholder: string;
  queryString: string;
  isOpen: boolean;
}> = ({ onDismiss, onSubmit, placeholder, queryString, isOpen }) => (
  <Modal isOpen={isOpen} alignTop onDismiss={onDismiss}>
    <form onSubmit={handleSubmit<{ query: string }>(data => onSubmit(data.query))}>
      <InputWrapper>
        <MaterialIcon path={mdiMagnify} size={1.25} />
        <Input
          type="text"
          name="query"
          defaultValue={queryString}
          placeholder={placeholder}
          autoComplete="off"
          autoFocus
        />
      </InputWrapper>
    </form>
  </Modal>
);
