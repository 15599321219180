import { css } from "@emotion/core";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import * as React from "react";
import { FC } from "react";
import { FilterButton, MaterialIcon } from "src/components";
import { navigate } from "src/util/router";
import { setRequestListURLParam } from "./Filters/urlParamHelpers";
import { useRequestList } from "./Provider";

export const setUrlPageNumber = (newPageNumber: number) => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set("page", String(newPageNumber));
  navigate(`${window.location.pathname}?${searchParams.toString()}`);
};

export const Pagination: FC<{
  shouldDisplayPagination: boolean;
  totalPages: number;
  pageNumber: number;
  onPageNumberChange(newPageNumber: number): void;
}> = ({ shouldDisplayPagination, totalPages, pageNumber, onPageNumberChange }) => {
  if (!shouldDisplayPagination) return null;
  return (
    <>
      <div
        css={css`
          position: absolute;
          display: flex;
          justify-content: center;
          bottom: 0;
          left: 0;
          right: 0;
          box-sizing: border-box;
          padding: var(--space-6-rem) var(--space-4-rem) var(--space-4-rem) var(--space-4-rem);
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 1.5rem, white 100%);
          & > * + * {
            margin-left: var(--space-4-rem);
          }
        `}
      >
        <FilterButton
          secondary
          icon={<MaterialIcon path={mdiChevronLeft} size={1} />}
          disabled={pageNumber <= 1}
          onClick={() => onPageNumberChange(pageNumber - 1)}
          label="Previous"
        />
        <input
          css={css`
            width: var(--space-5-px);
            border: var(--border-light);
            border-radius: var(--border-radius-xs);
            text-align: center;
          `}
          type="text"
          key={pageNumber}
          defaultValue={String(pageNumber)}
          onBlur={e => {
            let value = parseInt(e.target.value, 10);
            if (Number.isNaN(value)) {
              value = 1;
            }
            value = Math.max(Math.min(value, totalPages), 1);
            onPageNumberChange(value);
          }}
        />
        &nbsp;/ {totalPages}
        <FilterButton
          secondary
          css={css`
            flex-direction: row-reverse;
          `}
          icon={<MaterialIcon path={mdiChevronRight} size={1} />}
          disabled={pageNumber >= totalPages}
          onClick={() => onPageNumberChange(pageNumber + 1)}
          label="Next"
        />
      </div>
    </>
  );
};

export const RequestlistPagination: FC = () => {
  const [{ shouldDisplayPagination, response }] = useRequestList();
  const totalCount = response.data?.requestListPage.count ?? 0;
  const totalPages = Math.ceil(totalCount / (response.variables?.query.pageSize ?? Infinity));
  const pageNumber = (response.variables?.query.pageNumber ?? 0) + 1;

  return (
    <Pagination
      totalPages={totalPages}
      pageNumber={pageNumber}
      shouldDisplayPagination={shouldDisplayPagination}
      onPageNumberChange={(newPageNumber: number) =>
        setRequestListURLParam({
          type: "navigate_to_page",
          pageNumber: newPageNumber
        })
      }
    />
  );
};
