import { css } from "@emotion/core";
import { mdiPlus } from "@mdi/js";
import gql from "graphql-tag";
import React from "react";
import { useQuery } from "react-apollo";
import { showModal } from "src/App/Root/Routes";
import { GSettingsTeamListGet } from "src/App/Settings/Teams/typings/GSettingsTeamListGet";
import { GAutocompleteUserList } from "src/App/User/typings/GAutocompleteUserList";
import { BaseLayout, Button, Dialog, LoadingBar, MaterialIcon } from "src/components";
import { Modal } from "src/portals/Modal";
import { Toast } from "src/portals/Toast";
import { Location, useUrlState } from "src/util/router";
import { RemoveUser } from "./Remove";
import { UserTable } from "./Table";
import { UserUpdateContainer } from "./Update/Container";

/**
 * List of all experts
 */
export const EXPERT_LIST_GET = gql`
  query GExpertListGet {
    userList2(query: { expertsOnly: true }) {
      users {
        id
        name
        email
        joinTime
      }
    }
    teamList {
      userIds
    }
    currentUser {
      id
    }
  }
`;

export const UserOverview = () => {
  const { data, loading, error } = useQuery<GSettingsTeamListGet & GAutocompleteUserList>(EXPERT_LIST_GET, {
    fetchPolicy: "network-only"
  });
  const [editUserId, setEditUserId] = useUrlState("editUser", null);
  const [removeUserId, setRemoveUserId] = useUrlState("removeUser", null);
  if (!data) return null;
  const ownTeams = (data.teamList || []).filter(team => team.userIds.includes(data.currentUser?.id || ""));
  return (
    <>
      {loading && <LoadingBar />}
      {error && <Toast message={error.message} kind="error" />}
      {data.userList2 && (
        <BaseLayout
          headline={"Users"}
          subline={
            <>
              There {data.userList2.users.length === 1 ? "is" : "are"}&nbsp;
              <b>{data.userList2.users.length === 1 ? " 1 user" : data.userList2.users.length + " users"}</b>&nbsp;in
              your company.
            </>
          }
          isLoading={loading}
          button={
            // hide button if user isn't a member of any team
            !!ownTeams.length && (
              <Location>
                {locationContext => (
                  <Button
                    variant="secondary"
                    onClick={e => {
                      e.stopPropagation();
                      showModal(locationContext, "#/user-invite");
                    }}
                  >
                    <MaterialIcon
                      path={mdiPlus}
                      size={1.125}
                      css={css`
                        margin: 0 var(--space-1-px) 0;
                      `}
                    />
                    Invite users
                  </Button>
                )}
              </Location>
            )
          }
        >
          <UserTable userList={data.userList2.users} setEditUser={setEditUserId} setRemoveUser={setRemoveUserId} />
        </BaseLayout>
      )}
      <Modal isOpen={!!editUserId} onDismiss={() => setEditUserId(null)}>
        <Dialog title="Edit user" onClose={() => setEditUserId(null)}>
          {editUserId !== null && <UserUpdateContainer userId={editUserId} onFinish={() => setEditUserId(null)} />}
        </Dialog>
      </Modal>
      {!!removeUserId && <RemoveUser userId={removeUserId} onFinish={() => setRemoveUserId(null)} />}
    </>
  );
};
