import { css } from "@emotion/core";
import { csx } from "src/util/csx";

const displayStyles = css`
  font-family: var(--font-family-display);
  line-height: var(--line-height-dense);
  color: var(--text-6);

  b,
  strong {
    font-weight: var(--font-weight-display-bold);
  }
`;

const bodyStyles = css`
  font-family: var(--font-family-body);
  line-height: var(--line-height-normal);
  color: var(--text-6);

  b,
  strong {
    font-weight: var(--font-weight-body-semiBold);
  }
`;

export const subHeadingStyles = css`
  font-size: var(--font-size-subheading);
  text-transform: uppercase;
  letter-spacing: 0.12em;
`;

export const textLink = css`
  color: var(--text-6);
  text-decoration: underline;
  cursor: pointer;
  padding: 0 0.125rem;
  border-radius: var(--border-radius-m);
  &:hover {
    background-color: var(--lightGrey-2);
  }
`;

export const Typo = {
  Heading: csx(
    [
      displayStyles,
      css`
        font-size: var(--font-size-display-xLarge);
      `
    ],
    {
      sizeXL: css`
        font-size: var(--font-size-display-xLarge);
      `,
      sizeL: css`
        font-size: var(--font-size-display-large);
      `,
      sizeM: css`
        font-size: var(--font-size-display-medium);
      `,
      sizeS: css`
        font-size: var(--font-size-display-small);
      `,
      italic: css`
        font-style: italic;
      `,
      bold: css`
        font-weight: var(--font-weight-display-bold);
      `,
      ellipsis: css`
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      `
    },
    "p"
  ),
  Body: csx(
    [
      bodyStyles,
      css`
        font-size: var(--font-size-body);
      `
    ],
    {
      sizeXXL: css`
        font-size: var(--font-size-display-small);
        line-height: var(--line-height-dense);
        font-weight: var(--font-weight-body-medium);
      `,
      sizeXL: css`
        font-size: var(--font-size-heading-large);
      `,
      sizeL: css`
        font-size: var(--font-size-heading);
      `,
      sizeM: css`
        font-size: var(--font-size-body);
      `,
      sizeS: css`
        font-size: var(--font-size-helper);
      `,
      italic: css`
        font-style: italic;
      `,
      bold: css`
        font-weight: var(--font-weight-body-semiBold);
      `,
      medium: css`
        font-weight: var(--font-weight-body-medium);
      `,
      light: css`
        color: var(--text-3);
      `,
      lighter: css`
        color: var(--text-2);
      `,
      strikethrough: css`
        text-decoration: line-through;
      `,
      ellipsis: css`
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      `
    },
    "p"
  ),
  TextLink: csx(
    [textLink],
    {
      light: css`
        color: var(--text-4);
      `,
      medium: css`
        font-weight: var(--font-weight-body-medium);
      `,
      ellipsis: css`
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      `
    },
    "span"
  ),
  ExternalLink: csx(
    [textLink],
    {
      light: css`
        color: var(--text-4);
      `,
      medium: css`
        font-weight: var(--font-weight-body-medium);
      `,
      ellipsis: css`
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      `
    },
    "a"
  ),
  SectionHeading: csx(
    [
      bodyStyles,
      css`
        font-size: var(--font-size-label);
        text-transform: uppercase;
        letter-spacing: 3%;
        color: var(--text-6);
      `
    ],
    {
      light: css`
        color: var(--text-3);
      `,
      bold: css`
        font-weight: var(--font-weight-body-semiBold);
      `
    },
    "p"
  ),
  Subheading: csx(
    [
      bodyStyles,
      subHeadingStyles,
      css`
        font-weight: var(--font-weight-body-semiBold);
      `
    ],
    {
      light: css`
        color: var(--text-3);
      `,
      bold: css`
        font-weight: var(--font-weight-body-semiBold);
      `
    },
    "p"
  )
} as const;
