import { ApprovalAction, ApprovalState, SelectApprover } from "./Approvals";
import {
  Button,
  Col,
  Dialog,
  EllipsisText,
  MaterialIcon,
  Row,
  SimpleTooltip,
  SquareButton,
  TextEllipsis
} from "src/components";
import { DocumentAction, DocumentState } from "src/App/KB/Detail/reducer";
import { FormAction, FormState } from "src/App/Forms/Manager/reducer";
import { IInputState, TInputAction } from "./reducer";
import { TextLink, icon, row } from "src/styling/primitives";
import { mdiClose, mdiEye, mdiInformation, mdiLibraryBooks, mdiPencil, mdiPlus } from "@mdi/js";

import { AbsoluteTooltip } from "src/components/Tooltip";
import { DocumentSource } from "src/globalTypes";
import { InfoBanner } from "src/components/InfoBanner";
import { ReactComponent as LiveHelp } from "src/assets/material/live_help-24px.svg";
import { Modal } from "src/portals/Modal";
import React from "react";
import { Typo } from "src/styling/primitives/typography";
import { UserAvatar } from "src/App/User";
import { backMarkdownToText } from "src/util/formatters";
import css from "@emotion/css";

interface InputBannerProps {
  inputState: IInputState;
  inputDispatch: React.Dispatch<TInputAction>;
  formState: FormState;
  formDispatch: React.Dispatch<FormAction>;
}

export const IsRequesterBanner: React.FC = () => (
  <InfoBanner>
    <MaterialIcon size={1.125} path={mdiInformation} />
    <span>As the requester you will not receive e-mail or Slack notifications for your own replies</span>
  </InfoBanner>
);

export const ContextForExternalsToggle: React.FC<{
  inputState: IInputState;
  inputDispatch: React.Dispatch<TInputAction>;
}> = ({ inputState, inputDispatch }) => (
  <InfoBanner>
    <MaterialIcon size={1.125} path={mdiInformation} />
    {inputState.externalsIncludePreviousComments
      ? "The full message history will be included "
      : "Only the request decription will be included "}
    <TextLink
      light
      onClick={() => {
        inputDispatch({
          type: "SET_EXTERNALS_INCLUDE_PREVIOUS_COMMENTS",
          externalsIncludePreviousComments: !inputState.externalsIncludePreviousComments
        });
      }}
    >
      {inputState.externalsIncludePreviousComments
        ? "Only include request description"
        : "Include full message history"}
    </TextLink>
  </InfoBanner>
);

export const ApprovalBanner: React.FC<{
  approvalState: ApprovalState;
  approvalDispatch: React.Dispatch<ApprovalAction>;
}> = ({ approvalState, approvalDispatch }) => {
  return (
    <InfoBanner
      clickable
      title={!approvalState.approver.email ? "Select an approver" : undefined}
      onClick={() => {
        approvalDispatch({
          type: "SHOW_MODAL"
        });
      }}
      // css is needed to level out the difference in
      // height of avatar and usual icon component
      css={css`
        & > :first-child {
          padding-top: 0;
          margin-top: -1px;
        }
      `}
    >
      {!approvalState.approver.email && (
        <>
          <MaterialIcon size={1.125} path={mdiPlus} />
          <span>Select an approver</span>
        </>
      )}

      {approvalState.approver.email && (
        <>
          <UserAvatar
            user={approvalState.approver}
            css={css`
              margin-right: 0.375rem;
            `}
          />
          <EllipsisText>{approvalState.approver.email}</EllipsisText>
        </>
      )}

      <Modal
        isOpen={approvalState.showModal}
        onDismiss={() =>
          approvalDispatch({
            type: "HIDE_MODAL"
          })
        }
      >
        <Dialog
          large
          onClose={() =>
            approvalDispatch({
              type: "HIDE_MODAL"
            })
          }
        >
          <Col flex="1 1 auto">
            <Row margin="0 0 2.5rem 0">
              <Typo.Body sizeXXL>{approvalState.approver.email ? "Change approver" : "Select approver"}</Typo.Body>
            </Row>
            <Row
              css={css`
                & > * + * {
                  margin-left: 0.75rem;
                }
              `}
            >
              <SelectApprover
                approver={approvalState.currentlySelectedApprover}
                onSelect={selectedApprover => {
                  approvalDispatch({
                    type: "SELECT_APPROVER",
                    currentlySelectedApprover: selectedApprover
                  });
                }}
              />
              <Button
                variant="primary"
                onClick={() => {
                  approvalDispatch({
                    type: "SET_APPROVER",
                    approver: approvalState.currentlySelectedApprover
                  });
                  approvalDispatch({
                    type: "HIDE_MODAL"
                  });
                }}
              >
                Done
              </Button>
            </Row>
          </Col>
        </Dialog>
      </Modal>
    </InfoBanner>
  );
};

export const FormBanner: React.FC<InputBannerProps> = props => {
  return (
    <InfoBanner
      clickable={!props.formState.formSchemaToSend}
      title={!props.formState.formSchemaToSend ? "Select form" : undefined}
      onClick={
        props.formState.formSchemaToSend
          ? undefined
          : () =>
              props.formDispatch({
                type: "SET_MODAL",
                modal: "choose"
              })
      }
    >
      {props.formState.formSchemaToSend ? <LiveHelp css={[icon.M]} /> : <MaterialIcon size={1.125} path={mdiPlus} />}{" "}
      <div
        css={[
          row,
          css`
            justify-content: space-between;
          `
        ]}
      >
        <div>
          <span>{props.formState.formSchemaToSend?.displayName ?? "Select form"}</span>
          {props.formState.formSchemaToSend && (
            <span
              css={css`
                color: var(--text-3);
              `}
            >
              &nbsp;({props.formState.formSchemaToSend.fieldsList.length} questions)
            </span>
          )}
        </div>
        {props.formState.formSchemaToSend && (
          <Row>
            <AbsoluteTooltip content="Preview form">
              <SquareButton
                size="small"
                onClick={() =>
                  props.formDispatch({
                    type: "SET_MODAL",
                    modal: "previewSelected"
                  })
                }
                // correct height of surrounding square button
                css={css`
                  margin: -1px 0;
                `}
              >
                <MaterialIcon size={1.125} path={mdiEye} />
              </SquareButton>
            </AbsoluteTooltip>
            <AbsoluteTooltip content={props.formState.formSchemaToSend && "Change form"}>
              <SquareButton
                size="small"
                onClick={() =>
                  props.formDispatch({
                    type: "SET_MODAL",
                    modal: "choose"
                  })
                }
                // correct height of surrounding square button
                css={css`
                  margin: -1px 0;
                `}
              >
                <MaterialIcon size={1.125} path={mdiPencil} />
              </SquareButton>
            </AbsoluteTooltip>
          </Row>
        )}
      </div>
    </InfoBanner>
  );
};

export const DocumentBanner: React.FC<{
  documentState: DocumentState;
  documentDispatch: React.Dispatch<DocumentAction>;
}> = props => {
  if (!props.documentState.document) {
    return <InfoBanner>No document</InfoBanner>;
  }
  const content = backMarkdownToText(props.documentState.document.content);
  return (
    <InfoBanner>
      <MaterialIcon size={1.125} path={mdiLibraryBooks} />
      <div css={[row]}>
        <TextEllipsis
          css={css`
            flex: 1 1 auto;
          `}
        >
          <span>{props.documentState.document.title}&nbsp;</span>
          {props.documentState.document.source !== DocumentSource.confluence && (
            <span
              title={content}
              css={css`
                color: var(--text-3);
              `}
            >
              {content}
            </span>
          )}
          {props.documentState.document.source === DocumentSource.confluence && props.documentState.document.url && (
            <Typo.ExternalLink href={props.documentState.document.url} target="_blank" rel="noreferrer noopener">
              View page in Confluence
            </Typo.ExternalLink>
          )}
        </TextEllipsis>
        {!!props.documentState.document.attachments.length && (
          <SimpleTooltip
            label={
              <ul
                css={css`
                  margin: 0;
                  padding-left: var(--space-4-rem);
                `}
              >
                {props.documentState.document.attachments.map(attachment => (
                  <li>{attachment.displayName}</li>
                ))}
              </ul>
            }
            css={css`
              flex: 0 0 auto;
            `}
          >
            <Typo.Body light>
              ({props.documentState.document.attachments.length} attachment
              {props.documentState.document.attachments.length > 1 && "s"})
            </Typo.Body>
          </SimpleTooltip>
        )}
        <SimpleTooltip
          label="Remove document"
          css={css`
            flex: 0 0 auto;
            margin-left: 0.5rem;
          `}
        >
          <SquareButton
            size="small"
            onClick={e => {
              e.stopPropagation();
              props.documentDispatch({
                type: "CLEAR_DOCUMENT"
              });
            }}
          >
            <MaterialIcon path={mdiClose} size={1.125} />
          </SquareButton>
        </SimpleTooltip>
      </div>
    </InfoBanner>
  );
};
