import { FileStatus, TFileAction, LocalFileInfo } from "./reducer";
import { GAttachmentGenerate, GAttachmentGenerateVariables } from "./typings/GAttachmentGenerate";

import { apolloClient } from "src/util/apollo/client";
import gql from "graphql-tag";

export const ATTACHMENT_GENERATE = gql`
  mutation GAttachmentGenerate($params: AttachmentParameters!) {
    attachmentGenerate(params: $params) {
      code
      success
      message
      attachmentRequest {
        id
        uploadRequest {
          method
          url
          headers {
            key
            values
          }
        }
      }
    }
  }
`;

/**
 * Upload file convenience function
 * @param file
 * @param url
 * @param headers
 * @param onUpload percent uploaded
 */
export async function uploadFile(
  file: File,
  url: string,
  headers: { [key: string]: string },
  onUpload: (percent: number) => void
) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.upload.onprogress = progressEvent => {
      onUpload((progressEvent.loaded * 100) / progressEvent.total);
    };
    xhr.open("PUT", url, true);
    for (const name in headers) {
      xhr.setRequestHeader(name, headers[name]);
    }
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        resolve();
      }
      xhr.onerror = reject;
    };
    xhr.send(file);
  });
}

let fileId = 0;

function nextFileId(): string {
  fileId++;
  return "" + fileId;
}

function makeLocalFileInfo(f: File): LocalFileInfo {
  return {
    id: nextFileId(),
    name: f.name,
    sizeBytes: f.size,
    mimeType: f.type,
    status: FileStatus.UNSET,
    uploadPercent: 0,
    isLocal: true
  };
}

/**
 * Process files for uploading / attaching from event handler
 * @param files
 * @param filesDispatch reducer dispatch
 * @param authToken used to authorize external users to upload files
 */
export function handleFilesEvent(files: File[], filesDispatch: React.Dispatch<TFileAction>, authToken: string | null) {
  const fileInfos = files.map(makeLocalFileInfo);

  filesDispatch({
    type: "ADD_FILES",
    files: fileInfos
  });

  files.forEach(async (file, i) => {
    const id = fileInfos[i].id;

    try {
      filesDispatch({
        type: "SET_FILE_STATE",
        id,
        status: FileStatus.PROCESSING
      });
      if (file.size > Number(process.env.REACT_APP_MAX_ATTACHMENT_SIZE)) {
        filesDispatch({
          type: "SET_FILE_STATE",
          id,
          status: FileStatus.MAX_SIZE_EXCEEDED
        });
      } else {
        const variables = {
          params: {
            displayName: file.name,
            size: file.size,
            authToken: authToken
          }
        };
        const response = await apolloClient.mutate<GAttachmentGenerate, GAttachmentGenerateVariables>({
          mutation: ATTACHMENT_GENERATE,
          variables
        });
        const data = response.data as GAttachmentGenerate;
        if (data && data.attachmentGenerate.success && data.attachmentGenerate.attachmentRequest) {
          filesDispatch({
            type: "SET_ATTACHMENT_ID",
            attachmentId: data.attachmentGenerate.attachmentRequest.id,
            id
          });
          const uploadRequest = data.attachmentGenerate.attachmentRequest.uploadRequest;
          // convert headers array to map
          const headers = uploadRequest.headers
            // set by browser
            .filter(({ key }) => key !== "Content-Length")
            .reduce((acc, { key, values }) => ({ ...acc, [key]: values.join() }), {});
          filesDispatch({
            type: "SET_FILE_STATE",
            id,
            status: FileStatus.UPLOADING
          });
          await uploadFile(file, uploadRequest.url, headers, uploadPercent => {
            filesDispatch({
              type: "SET_FILE_UPLOAD_PERCENT",
              id,
              uploadPercent
            });
          });
          filesDispatch({
            type: "SET_FILE_STATE",
            id,
            status: FileStatus.UPLOADED
          });
        } else {
          throw new Error(data.attachmentGenerate.message);
        }
      }
    } catch (e) {
      filesDispatch({
        type: "SET_FILE_STATE",
        id,
        message: e.toString(),
        status: FileStatus.FAILED
      });
    }
  });
}
