import { css } from "@emotion/core";
import * as React from "react";
import { GDocumentsListGet_documentList_hrisFilter } from "src/App/KB/typings/GDocumentsListGet";
import { Typo } from "src/styling/primitives/typography";
import { formatAsSemanticList } from "src/util";

export const Targeting: React.FC<{
  hrisFilter?: GDocumentsListGet_documentList_hrisFilter;
  className?: string;
}> = props => {
  return (
    <div className={props.className}>
      <div>
        <Typo.Body bold>
          Office
          {(!props.hrisFilter?.officesList || props.hrisFilter.officesList.length > 1) && "s"}:
        </Typo.Body>
        {!!props.hrisFilter?.officesList.length ? (
          <Typo.Body
            css={[
              css`
                white-space: pre-wrap;
              `
            ]}
          >
            {formatAsSemanticList({
              array: props.hrisFilter.officesList as string[],
              asBlock: true
            })}
          </Typo.Body>
        ) : (
          <Typo.Body>All offices</Typo.Body>
        )}
      </div>
      <div>
        <Typo.Body bold>
          Department
          {(!props.hrisFilter?.departmentsList || props.hrisFilter.departmentsList.length > 1) && "s"}:
        </Typo.Body>
        {!!props.hrisFilter?.departmentsList.length ? (
          <Typo.Body>
            {formatAsSemanticList({
              array: props.hrisFilter.departmentsList as string[]
            })}
          </Typo.Body>
        ) : (
          <Typo.Body>All departments</Typo.Body>
        )}
      </div>
      <div>
        <Typo.Body bold>Suggested to:</Typo.Body>
        {props.hrisFilter?.isManager ? (
          <Typo.Body>This article will be suggested to managers only</Typo.Body>
        ) : (
          <Typo.Body>This article will be suggested to both managers and reports</Typo.Body>
        )}
        {props.hrisFilter?.allowExternals ? (
          <Typo.Body>This article will also be suggested to external users</Typo.Body>
        ) : (
          <Typo.Body>This article will only be suggested to employees</Typo.Body>
        )}
      </div>
    </div>
  );
};
